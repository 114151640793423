import { FC, useEffect, useState } from "react";
import { Alert, Form, Modal } from "react-bootstrap";
import { Column, HorizontalLine, PrimaryButton, Row, Spacer, RadioButtonGroup } from "primitives";
import styled from "styled-components";
import { DateRange } from "types/DateRange";
import { dateRangeValidator } from "hooks";
import { addDays, addMonths, getEndOfToday } from "utils";
import { SubjectTagBadges, TagSearch } from "components";
import { Tag } from "types";
import DatePicker from "react-datepicker";

interface TagSelection {
  tag: Tag;
  selected: boolean;
}

export type DateRangeType = "Last Week" | "Last Month" | "Custom";
const DateRangeTypeStrings = ["Last Week", "Last Month", "Custom"];

interface Props {
  visible: boolean;
  onHidden: () => void;
  selectedDateRange: DateRange;
  selectedDateRangeType: DateRangeType;
  setDateChanged: (newDateRange: DateRange) => void;
  setDateRangeType: (newDateRangeType: DateRangeType) => void;
  selectedSubjectTags: TagSelection[];
  setSelectedSubjectTags: (selectedTags: TagSelection[]) => void;
}

export const ReviewClassSearchModal: FC<Props> = ({
  visible,
  onHidden,
  selectedDateRange,
  selectedDateRangeType,
  setDateChanged,
  setDateRangeType,
  selectedSubjectTags,
  setSelectedSubjectTags,
}) => {
  const [closing, setClosing] = useState(false);
  const [newDateRange, setNewDateRange] = useState(selectedDateRange);
  const [dateValidationMessage, setDateValidationMessage] = useState<string | undefined>();
  const [newDateRangeType, setNewDateRangeType] = useState<DateRangeType>(selectedDateRangeType);
  const [subjectTags, setSubjectTags] = useState<TagSelection[]>(selectedSubjectTags);
  const [dateRangeIndex, setDateRangeIndex] = useState(
    DateRangeTypeStrings.findIndex((value) => value === newDateRangeType)
  );

  useEffect(() => {
    setSubjectTags(selectedSubjectTags);
  }, [selectedSubjectTags]);

  useEffect(() => {
    const endDate = getEndOfToday();
    switch (dateRangeIndex) {
      case 0: //Last Week
        setNewDateRange({
          startDate: addDays(new Date(), -7),
          endDate: endDate,
        });
        setNewDateRangeType("Last Week");
        break;
      case 1: //Last Month
        setNewDateRange({
          startDate: addMonths(new Date(), -1),
          endDate: endDate,
        });
        setNewDateRangeType("Last Month");
        break;
      case 2: //Custom
        setNewDateRangeType("Custom");
        break;
    }
  }, [dateRangeIndex]);

  useEffect(() => {
    setDateRangeIndex(DateRangeTypeStrings.findIndex((value) => value === newDateRangeType));
  }, [newDateRangeType]);

  const onModalClosed = () => {
    setClosing(false);
    onHidden();
  };

  const RevertToDefault = () => {
    setNewDateRange(selectedDateRange);
    setNewDateRangeType(selectedDateRangeType);
    setSubjectTags(selectedSubjectTags);
  };

  const onCancel = () => {
    RevertToDefault();
    onModalClosed();
  };

  const onConfirm = () => {
    setDateChanged(newDateRange);
    setDateRangeType(newDateRangeType);
    setSelectedSubjectTags(subjectTags);
    onModalClosed();
  };

  const handleStartDateChanged = (date: Date) => {
    const dateRange = { startDate: date, endDate: newDateRange.endDate };
    validateNewDateRange(dateRange);
  };

  const handleEndDateChanged = (date: Date) => {
    const dateRange = { startDate: newDateRange.startDate, endDate: date };
    validateNewDateRange(dateRange);
  };

  const validateNewDateRange = (dateRange: DateRange) => {
    const validationMessage = dateRangeValidator(dateRange, undefined, true);
    setDateValidationMessage(validationMessage);
    setNewDateRange(dateRange);
    setNewDateRangeType("Custom");
  };

  return (
    <Modal
      show={visible && !closing}
      onHide={() => setClosing(true)}
      onExited={onModalClosed}
      dialogClassName="width-1000px-modal"
    >
      <Modal.Header>
        <Modal.Title>Edit Progress Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormContainer>
          <Form.Group>
            <Column>
              <Form.Label>Date Range</Form.Label>
              <Row>
                <RadioButtonGroup
                  buttonNames={DateRangeTypeStrings}
                  defaultIndex={2}
                  selectedIndex={dateRangeIndex}
                  onChange={setDateRangeIndex}
                  style={{ width: "100%" }}
                ></RadioButtonGroup>
              </Row>
              <CenterAlignedRow gap={"30px"} style={{ height: "6em", alignItems: "center" }}>
                <CenterAlignedRow style={{ gap: "0.5em" }}>
                  <p>Start</p>
                  <DatePicker
                    selectsStart
                    selected={newDateRange.startDate}
                    startDate={newDateRange.startDate}
                    endDate={newDateRange.endDate}
                    onChange={handleStartDateChanged}
                    dateFormat="MMMM d, yyyy"
                  />
                </CenterAlignedRow>
                <HorizontalLine width="50px" />
                <CenterAlignedRow style={{ gap: "0.5em" }}>
                  <p>End</p>
                  <DatePicker
                    selectsEnd
                    selected={newDateRange.endDate}
                    minDate={newDateRange.startDate}
                    startDate={newDateRange.startDate}
                    endDate={newDateRange.endDate}
                    onChange={handleEndDateChanged}
                    dateFormat="MMMM d, yyyy"
                  />
                </CenterAlignedRow>
              </CenterAlignedRow>
            </Column>
            {dateValidationMessage && (
              <>
                <Spacer /> <Alert variant="danger">{dateValidationMessage}</Alert>
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Filter by Subject</Form.Label>
            <Column style={{ gap: "0.5em" }}>
              <TagSearch tags={subjectTags} setTags={setSubjectTags} highlightChildren={true} />
              <SubjectTagBadges subjectTags={subjectTags} setSubjectTags={setSubjectTags} />
            </Column>
          </Form.Group>
        </FormContainer>
      </Modal.Body>
      <Footer>
        <PrimaryButton onClick={onCancel} variant="secondary">
          Cancel
        </PrimaryButton>
        <PrimaryButton disabled={Boolean(dateValidationMessage)} onClick={onConfirm}>
          Confirm
        </PrimaryButton>
      </Footer>
    </Modal>
  );
};

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CenterAlignedRow = styled(Row)`
  align-items: center;
`;

const Footer = styled(Modal.Footer)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
