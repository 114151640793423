export const PlatformTitle = "playEd";

export const Names = {
  QuestionSet: "Question Set",
  questionset: "question set",
  questionSets: "question sets",

  Session: "Game Set",
  session: "game set",
  Sessions: "Game Sets",
  sessions: "game sets",

  CustomGame: "Game",
  customGame: "game",
  CustomGames: "Games",
  customGames: "games",

  gameTemplate: "game",
  GameTemplate: "Game",

  GameRules: "Rules",
};
