import { restoreToken } from "slices";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const useRestoreToken = (skipRestore: boolean) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!skipRestore) {
      dispatch(restoreToken());
    }
  }, [dispatch, skipRestore]);
};

export { useRestoreToken };
