import classNames from "classnames";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";

interface Props {
  isEdited: boolean;
  className?: string;
}

export const EditableComponent: FC<Props> = ({ isEdited, children, className }) => (
  <OuterContainer className={classNames(className)} isEdited={isEdited}>
    {children}
  </OuterContainer>
);

const OuterContainer = styled.div<Props>`
  padding: var(--sp-1);
  border-radius: 0.5rem;
  background-color: ${(props) => (props.isEdited ? colors.changesMade : "transparent")};
`;
