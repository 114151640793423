import { GameTemplate } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

const fetchGameTemplates = async (token: string): Promise<Array<GameTemplate>> => {
  const response = await api.get(`/gametemplates`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response.data) {
    const gameTemplates: Array<GameTemplate> = response.data;
    return gameTemplates;
  } else {
    throw new NetworkError();
  }
};

export { fetchGameTemplates };
