import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ArticleActions } from "./articleSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { Article } from "../../types";
import { getArticles } from "../../api";

const dispatchFetchAllPreviewArticles = (actions: ArticleActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);

    let articleTotalCount = -1;
    let articles: Partial<Article>[] = [];

    const articleBatch = 50;
    let skip = 0;
    let take = articleBatch;

    while (articleTotalCount === -1 || articles.length !== articleTotalCount) {
      const data = await getArticles(true, token, { take: take, skip: skip, orderBy: "createdAt" });

      articles = [...articles, ...data.articles];
      articleTotalCount = data.articleCount;

      skip += articleBatch;
    }

    dispatch(actions.addMany(articles));
    dispatch(actions.setFetchedPreviews(true));
  }, dispatch);

export { dispatchFetchAllPreviewArticles };
