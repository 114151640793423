import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { layoutConstants } from "styles";
import { FC } from "types";

interface Props {
  toggleText: string;
  disabled?: boolean;
  expand?: boolean;
  className?: string;
}

const PrimaryDropdown: FC<Props> = ({ toggleText, disabled, expand, children, className }) => {
  return (
    <Dropdown className={className}>
      <StyledDropdownToggle className={className} variant="outline" $expand={expand} disabled={disabled}>
        <DropdownText>{toggleText}</DropdownText>
      </StyledDropdownToggle>
      {children && (
        <StyledDropdownMenu className={className} $expand={expand}>
          {children}
        </StyledDropdownMenu>
      )}
    </Dropdown>
  );
};

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  ${(props) =>
    props.$expand
      ? "width:100%;"
      : `
      min-width:${layoutConstants.classSelectorDefaultWidth}; 
      max-width:${layoutConstants.classSelectorDefaultWidth};
      `}
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const DropdownText = styled.p`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  overflow: hidden;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  ${(props) =>
    props.$expand
      ? "width:100%;"
      : `min-width:${layoutConstants.classSelectorDefaultWidth}; 
      max-width:${layoutConstants.classSelectorDefaultWidth};
      `}
`;

export { PrimaryDropdown, DropdownText, StyledDropdownItem };
