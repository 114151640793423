import { ProcessSession } from "api/deserialisation";
import { Session } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  sessionId: string;
}

const fetchSession = async ({ sessionId }: Params, token: string): Promise<Session> => {
  const url = `/sessions/${sessionId}`;
  const response = await api.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const session: Session = ProcessSession(response.data);

  if (session) {
    return session;
  } else {
    throw new NetworkError();
  }
};

export { fetchSession };
