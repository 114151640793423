import { FC } from "types";
import { useAppSelector } from "app/hooks";
import { DropdownText, StyledDropdownItem, PrimaryDropdown } from "primitives";
import styled from "styled-components";
import { useClassId } from "hooks";
import { useState } from "react";

interface Props {
  onClassChanged?: (classId: string) => void;
  onClassesLoaded?: (classId: string) => void;
  expand?: boolean;
  disabled?: boolean;
  maintainSelectedClass?: boolean;
}

const ClassSelector: FC<Props> = ({ onClassChanged, onClassesLoaded, expand, disabled, maintainSelectedClass }) => {
  const classList = useAppSelector((state) => (state.classList.data ? Object.values(state.classList.data) : []));

  const classSelected = (newSelectedClassId: string | undefined) => {
    if (newSelectedClassId && selectedClassId !== newSelectedClassId) {
      setSelectedClassId(newSelectedClassId || "");
      if (!maintainSelectedClass) {
        setCurrentClassId(newSelectedClassId || "");
      }
      if (onClassChanged) {
        onClassChanged(newSelectedClassId);
      }
    }
  };

  const [currentClassId, setCurrentClassId] = useClassId();
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>(currentClassId);
  const selectedClass = selectedClassId ? classList?.find((c) => c.id === selectedClassId) : undefined;

  const defaultClassName = "Select Class...";

  return (
    <PrimaryDropdown
      className="borderless-dropdown class-dropdown"
      toggleText={classList ? selectedClass?.name || defaultClassName : "Loading..."}
      expand={expand}
      disabled={classList?.length === 0 || disabled}
    >
      <Scrollable>
        {classList && (
          <>
            {classList.map((item, index) => (
              <StyledDropdownItem
                className="borderless-dropdown"
                onClick={() => classSelected(item.id)}
                key={index}
                active={item.id === selectedClassId}
              >
                <DropdownText>{item.name}</DropdownText>
              </StyledDropdownItem>
            ))}
          </>
        )}
      </Scrollable>
    </PrimaryDropdown>
  );
};

const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

export { ClassSelector };
