import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Student } from "types";

export const getStudentByToken = async (token: string): Promise<Student> => {
  const response = await api.get("/students/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data) {
    return response?.data as Student;
  } else {
    throw new NetworkError();
  }
};
