import { useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import classNames from "classnames";
import { ListCardTitle, MiniGameQuestionSetBox } from "components";
import { useGameTemplates } from "hooks";
import { GameIcons } from "icons/Games";
import { LessonPlanInfo } from "pages/my-games/lessonPlans/LessonPlanListItem";
import { Column, Row, Spinner } from "primitives";
import { SmallIcon } from "primitives/icons";
import { FC, ReactNode } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { breadcrumbConstants, Names } from "textConstants";
import { GameTemplate, QuestionSet } from "types";
import { getQuestionsCount } from "utils";
import { CurrentCustomGameData } from "../CustomGameEditor";

export const MiniGameSessionInfo: FC = () => {
  const { sessionId } = useParams<{ sessionId?: string }>();

  const session = useAppSelector(
    (state: RootState) => state.sessionList.data && sessionId && state.sessionList.data[sessionId]
  );
  return session ? (
    <LessonPlanInfo session={session} />
  ) : (
    <ListCardTitle name={`Quick ${Names.CustomGame}`} icon={breadcrumbConstants.myGames.icon} />
  );
};

interface MiniGameInfoProps {
  currentCustomGame: CurrentCustomGameData;
}

export const MiniGameInfo: FC<MiniGameInfoProps> = ({ currentCustomGame }) => {
  const questionSets = useAppSelector((state: RootState) =>
    currentCustomGame.questionSetIds
      .map((qid) => (state.questionSets?.data ? state.questionSets.data[qid] : undefined))
      .filter((q): q is QuestionSet => !!q)
  );
  return (
    <>
      <MiniGameTemplateInfo gameTemplateId={currentCustomGame.gameTemplateId} isSelected={true} />
      <MiniGameStats questionSets={questionSets}>
        {questionSets.map((questionSet) => (
          <MiniGameQuestionSetBox key={questionSet.id} questionSet={questionSet} />
        ))}
      </MiniGameStats>
    </>
  );
};

interface MiniGameTemplateInfoProps {
  gameTemplate?: GameTemplate;
  gameTemplateId?: string;
  children?: ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
}

export const MiniGameTemplateInfo: FC<MiniGameTemplateInfoProps> = ({
  isSelected,
  children,
  onClick,
  gameTemplateId,
  gameTemplate,
}) => {
  const { loading, data: gameTemplates } = useGameTemplates(true);

  if (!gameTemplate && loading) {
    <EmptyTemplateContainer>
      <Spinner />
    </EmptyTemplateContainer>;
  }

  gameTemplate = gameTemplate || gameTemplates?.find((t) => t.id === gameTemplateId);

  if (gameTemplate) {
    return (
      <InnerContainer $isSelected={isSelected || false} onClick={onClick}>
        <CustomGamePreview src={gameTemplate.previewImage} />
        <RightContent>
          <CustomGameName>
            {GameIcons[gameTemplate.name] && <SmallIcon icon={GameIcons[gameTemplate.name]} className="mr-2 mb-1" />}
            {gameTemplate.name}
          </CustomGameName>
          {children}
        </RightContent>
      </InnerContainer>
    );
  } else {
    return <EmptyTemplateContainer>New {Names.CustomGame}</EmptyTemplateContainer>;
  }
};

interface Selectable {
  $isSelected: boolean;
}

const InnerContainer = styled(Row)<Selectable>`
  background-color: ${(props) => (props.$isSelected ? colors.primary : "white")};
  height: 75px;
  flex: 1 1 75px;
  color: ${(props) => (props.$isSelected ? "white" : "black")};
  & h4 {
    color: ${(props) => (props.$isSelected ? "white" : colors.primary)};
  }
`;

const CustomGamePreview = styled.img`
  height: 100%;
  width: auto;
`;

const RightContent = styled(Column).attrs(({ className }) => ({
  className: classNames(className, "p-2"),
}))`
  justify-content: space-between;
  font-size: 0.9em;
  flex-grow: 1;
`;

const CustomGameName = styled.h4`
  font-weight: 600;
  font-size: 1.2em;
`;

interface MiniGameStatsProps {
  questionSets: QuestionSet[];
  children?: ReactNode;
}

export const MiniGameStats: FC<MiniGameStatsProps> = ({ questionSets, children }) => {
  return (
    <MiniGameStatsContainer>
      <MiniGameStatsRow>
        <MiniGameStat>
          <strong>Question Sets:</strong> {questionSets.length}
        </MiniGameStat>
        <MiniGameStat>
          <strong>Questions:</strong> {getQuestionsCount(questionSets)}
        </MiniGameStat>
      </MiniGameStatsRow>
      {children}
    </MiniGameStatsContainer>
  );
};

const EmptyTemplateContainer = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  --border-size: 3px;
  --border-spacing: 10px;

  &:before {
    content: "";
    position: absolute;
    border: var(--border-spacing) dashed ${colors.primaryDivider};
    top: calc(var(--border-size) - var(--border-spacing));
    bottom: calc(var(--border-size) - var(--border-spacing));
    left: calc(var(--border-size) - var(--border-spacing));
    right: calc(var(--border-size) - var(--border-spacing));
  }
`;

const MiniGameStatsContainer = styled.div`
  background-color: ${colors.light};
  padding: var(--sp-2) var(--sp-3);
  margin: var(--sp-3) 0;
`;

const MiniGameStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.primaryDivider};
  padding-bottom: var(--sp-2);

  &:last-child {
    border-bottom: 0;
  }
`;

const MiniGameStat = styled.div`
  padding: 0 var(--sp-3);
  border-right: 1px solid ${colors.primaryDivider};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
    padding-right: 0;
  }
`;
