import { useAppDispatch, useAppSelector } from "app/hooks";
import { setArticleMenuPortalNode, setArticleMenuState, setArticleToggleMenuState } from "slices";

interface ArticleMenuData {
  portalNode: HTMLElement | null;
  portalReady: boolean;
  portalOpen: boolean;
  showToggle: boolean;
  setPortalReady: () => void;
  setPortalOpen: (state: boolean) => void;
  setShowToggle: (state: boolean) => void;
}

export const useArticleMenu = (): ArticleMenuData => {
  const dispatch = useAppDispatch();
  const portalNode = document.getElementById("articlePortal");

  const portalReady = useAppSelector((state) => state.articleMenuPortal.portalReady);
  const portalOpen = useAppSelector((state) => state.articleMenuPortal.portalOpen);
  const showToggle = useAppSelector((state) => state.articleMenuPortal.showToggle);

  const setPortalReady = () => dispatch(setArticleMenuPortalNode(true));
  const setPortalOpen = (state: boolean) => dispatch(setArticleMenuState(state));
  const setShowToggle = (state: boolean) => dispatch(setArticleToggleMenuState(state));

  return { portalNode, portalReady, portalOpen, showToggle, setPortalReady, setPortalOpen, setShowToggle };
};
