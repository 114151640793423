import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useStudent } from "hooks/redux/useStudent";
import { LoadingContent, InputControlWithError } from "components";
import { Column, ActionButton } from "primitives";
import { colors } from "styles";
import { FC } from "types/FunctionalComponentWithStandardProps";
import { Validator, useValidatableState, characterLimitedNonEmptyValidator } from "hooks/useValidatableState";
import { ApiError, StudentName, UserAction } from "types";
import { Cached, Delete, Edit } from "@styled-icons/material";
import { useParams } from "react-router-dom";
import { useConfirmModal } from "hooks";
import { helpMessages } from "textConstants";

const requiredNamesValidator: Validator = [characterLimitedNonEmptyValidator];
const blankValidator: Validator = [() => undefined];

interface Props {
  onDeleteStudent?: (id: string) => void;
}

const StudentInfo: FC<Props> = ({ onDeleteStudent }) => {
  const { student, loading, error, fetchStudent, updateStudent, regenerateStudentPassword } = useStudent();
  const [isEditing, setIsEditing] = useState(false);
  const { studentId } = useParams<{ studentId: string }>();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const [firstNameInput, setFirstNameInput, firstNameInputError] = useValidatableState(
    student?.firstName,
    requiredNamesValidator,
    "First Name"
  );
  const [lastNameInput, setLastNameInput, lastNameInputError] = useValidatableState(
    student?.lastName,
    blankValidator,
    "Last Name"
  );

  const hasError = Boolean(firstNameInputError || lastNameInputError);

  const handleRevert = useCallback(async () => {
    setFirstNameInput(student?.firstName);
    setLastNameInput(student?.lastName);
    setIsEditing(false);
  }, [student?.firstName, student?.lastName, setFirstNameInput, setLastNameInput]);

  useEffect(() => {
    if (studentId) {
      fetchStudent(studentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  useEffect(() => {
    handleRevert();
  }, [student?.firstName, student?.lastName, handleRevert]);

  const handleApply = async () => {
    const newStudentName: StudentName = {
      firstName: firstNameInput!.trim(),
      lastName: lastNameInput!.trim(),
    };
    setFirstNameInput(newStudentName.firstName);
    setLastNameInput(newStudentName.lastName);
    if (!hasError) {
      updateStudent(student!.id, newStudentName);
      setIsEditing(false);
    }
  };

  const handleEdit = async () => {
    if (!isEditing) {
      setIsEditing(!isEditing);
    } else {
      handleRevert();
    }
  };

  const handleDelete = async () => {
    if (onDeleteStudent) {
      setModalInfo({
        body: [helpMessages.deleteObject("student")],
        onModalConfirm: async () => await onDeleteStudent(student!.id),
        confirmButtonVariant: "danger",
        confirmTitle: "Delete",
      });
      setModalVisible(true);
    }
  };

  const handleRefreshPassword = async () => {
    setModalInfo({
      body: [helpMessages.class.regeneratePassword],
      onModalConfirm: async () => await regenerateStudentPassword(student!.id),
    });
    setModalVisible(true);
  };

  const applyAction: UserAction = {
    id: "apply",
    name: "apply",
    action: handleApply,
  };

  const revertAction: UserAction = {
    id: "revert",
    name: "revert",
    action: handleRevert,
  };

  const editAction: UserAction = {
    id: "edit",
    name: "edit",
    action: handleEdit,
    icon: Edit,
    iconColor: "white",
  };

  const resetAction: UserAction = {
    id: "reset",
    name: "reset",
    action: handleRefreshPassword,
    iconColor: "white",
    icon: Cached,
  };

  const deleteAction: UserAction = {
    id: "delete",
    name: "delete",
    action: handleDelete,
    iconColor: "white",
    icon: Delete,
  };

  return (
    <Column className="student-info">
      <LoadingContent loading={loading} error={error ? ApiError[error] : null}>
        <InnerContainer>
          <DefinitionText>First Name</DefinitionText>
          {isEditing ? (
            <InputControlWithError
              placeholder="First Name"
              initialInput={firstNameInput}
              onInputUpdated={setFirstNameInput}
              errorFeedback={firstNameInputError}
            />
          ) : (
            <DescriptionText>{student?.firstName}</DescriptionText>
          )}
        </InnerContainer>
        <InnerContainer>
          <DefinitionText>Last Name</DefinitionText>
          {isEditing ? (
            <InputControlWithError
              placeholder="Last Name"
              initialInput={lastNameInput}
              onInputUpdated={setLastNameInput}
              errorFeedback={lastNameInputError}
            />
          ) : (
            <DescriptionText>{student?.lastName || " "}</DescriptionText>
          )}
        </InnerContainer>
        <InnerContainer>
          <DefinitionText>Password</DefinitionText>
          <DescriptionText>{student?.textPassword}</DescriptionText>
        </InnerContainer>

        {isEditing ? (
          <ApplyRevertButtonsContainer>
            <ActionButton action={applyAction} variant="primary" disabled={hasError} onClick={handleApply}>
              Save
            </ActionButton>
            <ActionButton action={revertAction} variant="secondary" onClick={handleRevert}>
              Undo
            </ActionButton>
          </ApplyRevertButtonsContainer>
        ) : (
          <FooterButtonsContainer>
            <ActionButton action={editAction} variant="secondary">
              Edit Details
            </ActionButton>
            <ActionButton action={resetAction} variant="secondary" style={{ padding: "0px" }}>
              Reset Password
            </ActionButton>
            <ActionButton
              action={deleteAction}
              variant="danger"
              disabled={!Boolean(onDeleteStudent)}
              style={{ padding: "0px" }}
            >
              Delete Student
            </ActionButton>
          </FooterButtonsContainer>
        )}
      </LoadingContent>
      <Modal />
    </Column>
  );
};

const InnerContainer = styled(Column)`
  margin-bottom: 20px;
`;

const ApplyRevertButtonsContainer = styled(Column)`
  width: 100%;
  justify-content: space-evenly;
  gap: 0.5em;
`;

const FooterButtonsContainer = styled(Column)`
  width: 100%;
  gap: 1em;
`;

const DefinitionText = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
  color: ${colors.darkText};
  font-size: 14px;
`;

const DescriptionText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

export { StudentInfo };
