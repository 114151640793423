import { api } from "./api";

import { UserInfo } from "./teacher/login";
import { NetworkError } from "./networkError";

const refreshToken = async (oldToken: string): Promise<{ token: string; userInfo: UserInfo }> => {
  const response = await api.put(
    "/auth/refresh",
    {},
    {
      headers: {
        Authorization: `Bearer ${oldToken}`,
      },
    }
  );
  if (response?.data?.token) {
    return { token: response.data.token, userInfo: response.data.teacher };
  } else {
    throw new NetworkError();
  }
};

export { refreshToken };
