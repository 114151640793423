const colors: { [colorName: string]: string } = {
  primary: "#5A7DFD",
  primaryHover: "#4A47E0",
  primaryActive: "#2A30C0",
  darkPalePrimary: "#A0B5FF",
  palePrimary: "#D6E1FF",
  lightPalePrimary: "#F1F4FF",
  transparentPrimary: "#EFEFFF",
  success: "#19B83D",
  transparentSuccess: "#d2efd9",
  paleSuccess: "#5EBE74",
  veryPaleSuccess: "#B8E9C3",
  successNameLabel: "#72d289",
  secondary: "#6C757D",
  secondaryHover: "#5A6268",
  secondaryPale: "#e4e5e5",
  secondaryNameLabel: "#7a7a7a",
  transparentSecondary: "#EFEFEF",
  transparentTertiary: "#EBEBEB",
  danger: "#DC3545",
  dangerHover: "#C82333",
  dangerActive: "#BD2130",
  dangerText: "#F4727F",
  paleDanger: "#EF465A",
  veryPaleDanger: "#FFD2D7",
  transparentDanger: "#fce2e5",
  warning: "#F8CA2A",
  paleWarning: "#FFEBAF",
  warningActive: "#D39E00",
  transparentWarning: "#fcf1cd",
  warningNameLabel: "#f3dc84",
  warningText: "#CDB15E",
  info: "#17A2B8",
  light: "#F8F9FA",
  lightHover: "#E2E6EA",
  lightActive: "#DAE0E5",
  dark: "#343A40",
  darkActive: "#1D2124",
  white: "#FFFFFF",
  copyLink: "#C6FFD3",
  copyLinkText: "#106A08",
  darkText: "#363636",
  icons: "#000000",
  nameLabel: "#839EFD",
  primaryDivider: "#C4C8CC",
  studentFirstName: "#888888",
  innerBorder: "#C4C8CC",
  sessionSelect: "#E9EAFA",
  changesMade: "#FFD597",
  paleGreyPurple: "#D0D1E1",
  inactiveButton: "#B4B4B4",
  checkboxBgGrey: "#E9ECEF",
  black: "#000000",

  // Brand colours
  brandPurple: "#8455A2",
  brandGreen: "#58C1A9",
  brandDarkGreen: "#00B09C",
  brandBlue: " #4AC4D7",
  brandOrange: "#EF8E35",
  brandPink: "#ED579F",
  darkBrandPink: "#DA468D",
  brandDarkPurple: "#754793",

  // Tab colours
  tabHoverOrange: "#EF8E35",
  tabSelectedOrange: "#FFD1A7",
  tabHoverPurple: "#B16EDB",
  tabSelectedPurple: "#DCA5FF",
  tabHoverPink: "#FF78B9",
  tabSelectedPink: "#FFC4E0",
  tabSelectedGreen: "#93F1DC",
  tabSelectedBlue: "#80EEFF",
  tabSelectedGrey: "#A3ADB6",

  navbarSubitle: "rgba(255,255,255,0.6)",

  dropShadow: "rgba(0,0,0,0.4)",
};

export { colors };
