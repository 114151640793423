import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ClassActions } from "./classListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchClass } from "api";

export interface FetchClassParams {
  classId: string;
}

const dispatchFetchClass = ({ classId }: FetchClassParams, actions: ClassActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const fetchedClass = await fetchClass({ token, classId });
    dispatch(actions.addSingle({ classId: fetchedClass.id, class: fetchedClass }));
    return fetchedClass;
  }, dispatch);

export { dispatchFetchClass };
