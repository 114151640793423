import { useAppDispatch } from "app/hooks";
import { SessionPlayIcon } from "icons";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { blurApp, duplicateSession, selectDefaultClass, unBlurApp } from "slices";
import { colors } from "styles";
import { actionConstants, breadcrumbConstants, paths, slugs } from "textConstants";
import { Session } from "types";
import { dispatchAsync } from "utils";
import { SessionActionHook } from "./useSessionActions";

export const quickGameActionsHooks: SessionActionHook[] = [
  (session: Session) => {
    return [
      {
        id: "play",
        name: "Play Now",
        icon: SessionPlayIcon,
        iconColor: colors.white,
        backgroundColor: breadcrumbConstants.myGames.accentColor,
        hoverBackground: colors.tabSelectedPurple,
        color: colors.white,
        hoverColor: colors.light,
        action: async () => {
          if (session.customGames?.length) {
            window.open(session.customGames[0].gameLink, "_blank");
          }
        },
        requiresLogin: true,
      },
    ];
  },
  (session: Session) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { search } = useLocation();
    return [
      {
        id: "preview",
        name: "Preview",
        icon: actionConstants.preview.icon,
        action: async () => {
          history.push({ pathname: `${url}/${slugs.session}/${session.id}`, search });
        },
      },
    ];
  },
  (session: Session) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const classId = useSelector(selectDefaultClass);
    return [
      {
        id: "assign",
        name: "Assign",
        icon: breadcrumbConstants.class.icon,
        iconColor: colors.brandBlue,
        action: async () => {
          if (classId) {
            dispatch(blurApp());
            const newSession = await dispatchAsync(
              dispatch(
                duplicateSession(session.id, {
                  startTime: null,
                  endTime: null,
                  classId,
                  name: session.name,
                  publicAccess: session.publicAccess,
                })
              )
            );
            dispatch(unBlurApp());

            if (newSession.success && newSession.result) {
              history.push(paths.teacher.myGames.session.edit.generate(newSession.result));
            }
          } else {
            history.push(`/${slugs.teacher}/${slugs.class}`);
          }
        },
        requiresLogin: true,
      },
    ];
  },
];
