import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchAllPreviewArticles, setArticleMenuState, setArticleToggleMenuState } from "../../slices";
import { Article, defaultRequestState } from "../../types";
import { dispatchAsync } from "../../utils";
import { ArticleMenuItem } from "../breadcrumb";

export const ArticleProvider: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const articles = useAppSelector((state) => state.articleList.data);
  const articleList = useMemo(() => (articles ? Object.values(articles) : []), [articles]);
  const hasFetchedArticles = useAppSelector((state) => state.articleList.fetchedPreviews);

  const closeArticleMenu = () => {
    dispatch(setArticleMenuState(false));
  };

  const setToggleShowing = (state: boolean) => {
    dispatch(setArticleToggleMenuState(state));
  };

  const directToArticle = (article: Partial<Article>) => {
    if (article.id) {
      const newUrl = generatePath(`/teacher/academy/:articleID`, { articleID: article.id });
      history.push(newUrl);
    }
  };

  useEffect(() => {
    if (!hasFetchedArticles) {
      dispatchAsync(dispatch(fetchAllPreviewArticles()), setRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: This will need to be improved for better specificity of articles
  const sortedArticles = articleList
    ? articleList.filter((article) => {
        return article.routeAddress && history.location.pathname.includes(article.routeAddress);
      })
    : null;

  useEffect(() => {
    setToggleShowing(Boolean(!loading && sortedArticles && sortedArticles.length > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, sortedArticles]);

  return (
    <>
      {!loading &&
        sortedArticles &&
        sortedArticles.map((article, index) => {
          return (
            <ArticleMenuItem
              key={article.id}
              title={article.title || ""}
              onClick={() => {
                closeArticleMenu();
                directToArticle(article);
              }}
            />
          );
        })}
    </>
  );
};
