import { Class } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  teacherId: string;
}

const fetchClasses = async ({ teacherId }: Params, token: string): Promise<Array<Class>> => {
  const maxTake = 30;
  let take = maxTake;
  let skip = 0;

  let returnClasses: Class[] = [];

  let takenMaximum = false;
  while (!takenMaximum) {
    const url = `/teachers/${teacherId}/classes?take=${take}&skip=${skip}`;
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data) {
      returnClasses = returnClasses.concat(response.data);
      takenMaximum = response.data.length !== maxTake;
      if (!takenMaximum) {
        skip += maxTake;
      }
    } else {
      throw new NetworkError();
    }
  }

  return returnClasses;
};

export { fetchClasses };
