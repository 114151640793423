import { useAppSelector } from "app/hooks";
import { Center, PrimaryButton } from "primitives";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { Names } from "textConstants";
import { FC, Grouping } from "types";

interface ModalProps {
  onModalHidden: () => void;
  onConfirmDelete: () => void;
}

interface Props {
  grouping: Grouping;
  beginHide: () => void;
  onConfirmDelete: () => void;
}

const DeleteGrouping: FC<Props> = ({ grouping, beginHide, onConfirmDelete }) => {
  // Prevents issues with the grouping being deleted
  const groupingCache = { ...grouping };

  const deletePressed = () => {
    beginHide();
    onConfirmDelete();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Delete <span style={{ color: colors.primary }}>{groupingCache.name}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BodyText>
          Are you sure you want to delete this partition? <br />
          You will not be able to use it to assign {Names.sessions} to your class or to sort results in Progress.
        </BodyText>
      </Modal.Body>
      <Modal.Footer>
        <Center>
          <PrimaryButton className="m-3" style={{ width: 150 }} variant="secondary" onClick={beginHide}>
            Cancel
          </PrimaryButton>
          <PrimaryButton className="m-3" style={{ width: 150 }} variant="danger" onClick={deletePressed}>
            Delete
          </PrimaryButton>
        </Center>
      </Modal.Footer>
    </>
  );
};

export const DeleteGroupingModal: FC<ModalProps> = ({ onModalHidden, onConfirmDelete }) => {
  const [isClosing, setIsClosing] = useState(false);
  const { groupingId } = useParams<{ groupingId: string }>();
  const grouping = useAppSelector((state) => state.groupings.data && state.groupings.data[groupingId]);

  const beginHide = () => {
    setIsClosing(true);
  };

  return (
    <Modal show={true && !isClosing} onHide={beginHide} onExited={onModalHidden} centered={true}>
      <DeleteGrouping grouping={grouping!} beginHide={beginHide} onConfirmDelete={onConfirmDelete} />
    </Modal>
  );
};

const BodyText = styled.p`
  text-align: center;
  margin: 40px;
`;
