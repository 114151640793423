import styled from "styled-components";
import { colors } from "styles";
import { FC, QuestionSet, UserAction } from "types";
import { useQuestionSetActions, QuestionSetActionHook } from "hooks";
import { DragItemHandle, ItemAlignment, MoveDirection, QuestionSetCardHeader } from "components";
import { ActionButtonBar } from "primitives";
import { filter, sortBy } from "lodash";

interface Props {
  questionSet: QuestionSet;
  index: number;
  alignment: ItemAlignment;
  actionHooks?: Array<QuestionSetActionHook>;
  onMovePressed: (index: number, direction: MoveDirection) => void;
  onClick?: () => void;
}

interface WidthAmount {
  width: number;
}

const QuestionSetSelectorSidebarItem: FC<Props> = ({
  questionSet,
  index,
  alignment,
  onMovePressed,
  onClick,
  actionHooks,
}) => {
  const actions = useQuestionSetActions(questionSet, actionHooks);
  const sortedActions = sortBy(
    filter(actions, (action: UserAction) => action.isAvailable === undefined || action.isAvailable()),
    "priority"
  );

  return (
    <OuterContainer>
      <DragContainer width={8}>
        <DragItemHandle index={index} alignment={alignment} onMovePressed={onMovePressed} />
      </DragContainer>
      <FlexContainer width={92} onClick={onClick}>
        <QuestionSetCardHeader questionSet={questionSet} />
        {Boolean(sortedActions?.length) && (
          <ActionButtonBar className="mt-1" actions={Object.values(sortedActions).map((action) => ({ action }))} />
        )}
      </FlexContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  border: 1px solid ${colors.primaryDivider};
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: white;
  width: calc(100% - 5px);
  position: relative;
`;

const FlexContainer = styled.div<WidthAmount>`
  width: ${(props) => props.width}%;
  padding: var(--sp-2);
`;

const DragContainer = styled(FlexContainer)`
  display: flex;
  justify-content: center;
  cursor: grab;
  gap: var(--sp-2);
  border-right: solid 1px ${colors.primaryDivider};
`;

export { QuestionSetSelectorSidebarItem };
