import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types";
import { isValidResponse } from "utils/isValidResponse";

export const updateTwoFactorAuth = async (token: string, teacherId: string, enabled: boolean): Promise<Teacher> => {
  const response = await api.post(
    `/teachers/${teacherId}/enabletwofactorauth`,
    { enabled },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response?.data && isValidResponse(response)) {
    return response?.data;
  } else {
    throw new NetworkError();
  }
};
