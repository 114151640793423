import { StyledIcon, StyledIconBase, StyledIconProps } from "@styled-icons/styled-icon";
import { useState, useEffect, forwardRef, useRef } from "react";
import { omit } from "lodash";

type ReactSVGComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;

type Attrs = { [key: string]: string | null | undefined };

// Generic handling
export function createCustomIcon(Svg: ReactSVGComponent, displayName: string, padding?: string): StyledIcon {
  const icon = forwardRef<SVGSVGElement, StyledIconProps>(({ size = 24, className, style, ...props }, ref) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const test = <Svg ref={svgRef} />;
    const [allAttrs, setAllAttrs] = useState<Attrs>({});
    useEffect(() => {
      if (svgRef.current) {
        setAllAttrs(
          svgRef.current?.getAttributeNames().reduce<Attrs>((curr, name) => {
            curr[name] = svgRef.current?.getAttribute(name);
            return curr;
          }, {})
        );
      }
    }, [svgRef]);
    const attrs = omit(allAttrs, "viewBox");

    return (
      <StyledIconBase
        ref={ref}
        width={size}
        height={size}
        iconVerticalAlign="middle"
        iconAttrs={attrs}
        iconViewBox={(allAttrs && allAttrs["viewBox"]) || `0 0 ${size} ${size}`}
        {...props}
        {...allAttrs}
        {...(className ? { className } : {})}
        style={{ ...style, padding: `${padding || "0px"}` }}
      >
        {test}
      </StyledIconBase>
    );
  });

  icon.displayName = displayName;
  return icon;
}
