import { QuestionSetListDetails } from "types";
import { OrderBy } from "types";
import { ProcessQuestionSet, UnprocessedQuestionSet } from "../deserialisation";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface FetchQuestionSetParams {
  orderBy: OrderBy;
  order: "modifiedAt";
  resultsPerPage: number;
  requestedPage: number;
  teacherId?: string;
  filterText?: string;
  archived?: boolean;
  isFavourite?: boolean;
  featured?: boolean;
  visibility?: "public" | "private" | "draft";
  gradeTags?: string;
  subjectTags?: string;
  total?: number;
  onlyMyOwn: boolean;
}

const fetchQuestionSets = async (
  {
    orderBy,
    order,
    resultsPerPage,
    requestedPage,
    teacherId,
    filterText,
    archived,
    isFavourite,
    visibility,
    featured,
    gradeTags,
    subjectTags,
    onlyMyOwn,
  }: FetchQuestionSetParams,
  token: string | null
): Promise<QuestionSetListDetails> => {
  const url = onlyMyOwn ? `/teachers/${teacherId}/questionsets` : `/questionsets`;
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const response = await api.get(url, {
    headers: headers,
    params: {
      take: resultsPerPage,
      skip: (requestedPage - 1) * resultsPerPage,
      orderby: orderBy === OrderBy.Newest ? "dec" : "asc",
      order,
      filterText,
      visibility,
      archived: archived,
      featured: featured,
      gradeTags,
      subjectTags,
      isFavourite: isFavourite,
    },
  });

  if (response.data) {
    const { data, total } = response.data;
    const numPages = Math.ceil(total / resultsPerPage);
    const unprocessedQuestionSets: Array<UnprocessedQuestionSet> = data;
    return {
      order,
      orderedBy: orderBy,
      resultsPerPage,
      numPages,
      currentPage: requestedPage,
      archived,
      featured,
      gradeTags,
      subjectTags,
      questionSets:
        unprocessedQuestionSets &&
        unprocessedQuestionSets
          .filter((questionSet) => {
            return Boolean(questionSet);
          })
          .map((unprocessedQuestionSet) => ProcessQuestionSet(unprocessedQuestionSet)),
      total,
      onlyMyOwn,
    };
  } else {
    throw new NetworkError();
  }
};

export { fetchQuestionSets };
