import { EditableComponent, PartitionSelector } from "components";
import { inactiveStudentCardColourScheme, StudentCard } from "components/studentCard";
import { StudentOrdering, studentOrderings } from "pages/classes/classesOverview/studentOrderings";
import { Column, Row, SimpleSelect, VerticalLine } from "primitives";
import { Dispatch, SetStateAction, useState } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { breadcrumbConstants, Names } from "textConstants";
import { CustomGame, FC, Session } from "types";
import { getTotalCustomGameQuestionCount } from "utils";
import { MediaConstants } from "utils/mediaConstants";
import {
  GroupedStudentGrid,
  StudentCardProps,
  StudentGridKey,
  StudentGridKeyColour,
  StudentGridKeyContainer,
  StudentGridKeyItem,
} from "components/studentCard/GroupedStudentGrid";
import { MedIcon } from "primitives/icons";
import { GameIcons } from "icons/Games";
import { union, map, difference, intersection } from "lodash";
import { PartitionToolProps } from "components/studentCard/StudentGroupingPartition";
import { useClass } from "hooks";

interface CommonDataProps {
  studentAssignmentCounts: { [studentId: string]: number };
  studentQuestionCounts: { [studentId: string]: number };
  session: Session;
  state: ComponentState;
  initialState: ComponentState;
  customGame?: CustomGame;
}

interface Props extends CommonDataProps {
  setState: Dispatch<SetStateAction<ComponentState>>;
}

interface ComponentState {
  selectedStudentIds: string[];
  deselectedStudentIds: string[];
}

export type { ComponentState as StudentAssignmentsState };

interface CardDataProps extends CommonDataProps {
  onStudentPressed?: (studentId: string) => void;
}

interface AssignmentStudentCardProps extends StudentCardProps {
  data?: CardDataProps;
}

const AssignmentStudentCard: React.FC<AssignmentStudentCardProps> = ({ student, data }) => {
  if (!data) {
    return <></>;
  }
  const {
    customGame,
    state,
    initialState,
    studentAssignmentCounts,
    studentQuestionCounts,
    session,
    onStudentPressed,
  } = data;
  const studentAssignmentCount =
    student.id in studentAssignmentCounts && Boolean(studentAssignmentCounts[student.id])
      ? studentAssignmentCounts[student.id]
      : 0;
  const studentQuestions =
    student.id in studentQuestionCounts && Boolean(studentQuestionCounts[student.id])
      ? studentQuestionCounts[student.id]
      : 0;

  const isSelected = state.selectedStudentIds.includes(student.id);
  const isDeselected = state.deselectedStudentIds.includes(student.id);
  const hasChanged = () => {
    //if it's selection status is different, return true
    if (state.selectedStudentIds.includes(student.id) !== initialState.selectedStudentIds.includes(student.id))
      return true;
    //if we're in multi-select mode and its deselection status is different, return true
    if (
      !customGame &&
      state.deselectedStudentIds.includes(student.id) !== initialState.deselectedStudentIds.includes(student.id)
    )
      return true;
    return false;
  };

  return (
    <EditableStudentCardContainer isEdited={hasChanged()}>
      <StudentCard
        {...student}
        onStudentPressed={onStudentPressed}
        isSelected={isSelected}
        cardOptions={isDeselected || studentAssignmentCount === 0 ? inactiveStudentCardColourScheme : undefined}
        topRowElement={{
          icon: breadcrumbConstants.myGames.icon,
          text: `${studentAssignmentCount}/${session.customGames?.length}`,
        }}
        bottomRowElement={{
          icon: breadcrumbConstants.questions.icon,
          text: `${studentQuestions}`,
        }}
      />
    </EditableStudentCardContainer>
  );
};

export const StudentAssignments: FC<Props> = ({
  session,
  customGame,
  studentAssignmentCounts,
  studentQuestionCounts,
  state,
  initialState,
  setState,
}) => {
  const sessionClass = useClass();
  const { selectedStudentIds, deselectedStudentIds } = state;
  const hasCustomGames = (session.customGames?.length || 0) > 0;
  const sessionHasOneGame = (session?.customGames?.length || 0) === 1;

  const [studentOrdering, setStudentOrdering] = useState<StudentOrdering>();

  const onStudentPressed = (studentId: string) => {
    if (customGame) {
      const newStudentIds = [...selectedStudentIds];
      if (selectedStudentIds.includes(studentId)) {
        newStudentIds.splice(selectedStudentIds.indexOf(studentId), 1);
      } else {
        newStudentIds.push(studentId);
      }
      setState((prev) => ({ ...prev, selectedStudentIds: newStudentIds }));
    } else {
      const newSelectedIds = [...selectedStudentIds];
      const newDeselectedIds = [...deselectedStudentIds];
      if (selectedStudentIds.includes(studentId)) {
        newSelectedIds.splice(selectedStudentIds.indexOf(studentId), 1);
        newDeselectedIds.push(studentId);
      } else {
        if (deselectedStudentIds.includes(studentId)) {
          newDeselectedIds.splice(deselectedStudentIds.indexOf(studentId), 1);
        }
        newSelectedIds.push(studentId);
      }
      setState({ deselectedStudentIds: newDeselectedIds, selectedStudentIds: newSelectedIds });
    }
  };

  const onSelectAllPressed = () => {
    if (sessionClass) {
      setState({
        deselectedStudentIds: [],
        selectedStudentIds: sessionClass.students.map((student) => student.id),
      });
    }
  };

  const onSelectNonePressed = () => {
    if (sessionClass) {
      setState({
        deselectedStudentIds: customGame ? [] : sessionClass.students.map((student) => student.id),
        selectedStudentIds: [],
      });
    }
  };

  const onSelectAllInPartitionPressed = (studentIds: string[]) => {
    setState((prev) => ({
      deselectedStudentIds: difference(prev.deselectedStudentIds, studentIds),
      selectedStudentIds: union(prev.selectedStudentIds, studentIds),
    }));
  };

  const onSelectNoneInPartitionPressed = (studentIds: string[]) => {
    setState((prev) => ({
      deselectedStudentIds: union(prev.deselectedStudentIds, studentIds),
      selectedStudentIds: difference(prev.selectedStudentIds, studentIds),
    }));
  };

  const cardData: CardDataProps = {
    studentAssignmentCounts,
    studentQuestionCounts,
    session,
    onStudentPressed: hasCustomGames ? onStudentPressed : undefined,
    state,
    initialState,
    customGame,
  };

  const AssignmentPartitionTools: FC<PartitionToolProps> = ({ students }) => {
    const partitionStudentIds: string[] = map(students, "id");
    const selectedInPartition = intersection(partitionStudentIds, selectedStudentIds).length;
    const deselectedInPartition = intersection(partitionStudentIds, deselectedStudentIds).length;
    return (
      <ToolsContainer>
        <ToolsLink
          disabled={selectedInPartition >= students.length}
          onClick={() => onSelectAllInPartitionPressed(partitionStudentIds)}
        >
          Assign All
        </ToolsLink>
        <ToolsLink
          disabled={(selectedInPartition === 0 && customGame) || deselectedInPartition >= students.length}
          onClick={() => onSelectNoneInPartitionPressed(partitionStudentIds)}
        >
          Unassign All
        </ToolsLink>
      </ToolsContainer>
    );
  };

  const questionCount = customGame
    ? getTotalCustomGameQuestionCount(customGame)
    : (session?.customGames || []).reduce((acc, game) => acc + getTotalCustomGameQuestionCount(game), 0);

  const gridFooter = (
    <StudentGridKeyContainer>
      <StudentGridKey>
        <StudentGridKeyItem>
          <MedIcon icon={breadcrumbConstants.myGames.icon} color={colors.primary} />
          <p>Number of {Names.CustomGames}</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <MedIcon icon={breadcrumbConstants.questions.icon} color={colors.primary} />
          <p>Total Questions</p>
        </StudentGridKeyItem>
      </StudentGridKey>

      {customGame ? (
        <StudentGridKey className="py-1">
          <StudentGridKeyItem>
            <StudentGridKeyColour fill={colors.primary} />
            <p>Assigned to this {Names.CustomGame}</p>
          </StudentGridKeyItem>
          <StudentGridKeyItem>
            <StudentGridKeyColour fill={colors.darkPalePrimary} />
            <p>Not assigned to this {Names.CustomGame}</p>
          </StudentGridKeyItem>
          <StudentGridKeyItem>
            <StudentGridKeyColour fill={colors.inactiveButton} />
            <p>Not assigned to any {Names.CustomGames}</p>
          </StudentGridKeyItem>
        </StudentGridKey>
      ) : (
        <StudentGridKey className="py-1">
          <StudentGridKeyItem>
            <StudentGridKeyColour fill={colors.primary} />
            <p>Assigned to all {Names.CustomGames}</p>
          </StudentGridKeyItem>
          {!sessionHasOneGame && (
            <StudentGridKeyItem>
              <StudentGridKeyColour fill={colors.darkPalePrimary} />
              <p>Assigned to some {Names.CustomGames}</p>
            </StudentGridKeyItem>
          )}
          <StudentGridKeyItem>
            <StudentGridKeyColour fill={colors.inactiveButton} />
            <p>Not assigned to any {Names.CustomGames}</p>
          </StudentGridKeyItem>
        </StudentGridKey>
      )}
    </StudentGridKeyContainer>
  );

  const headerCustomGame = customGame || sessionHasOneGame ? (session?.customGames || [])[0] : undefined;

  return (
    <MainContentContainer>
      <ControlsContainer>
        <FilterContainer>
          <Filter>
            <Form.Label>Sort By:</Form.Label>
            <SimpleSelect
              options={studentOrderings}
              getName={(ordering) => ordering?.id}
              onChange={setStudentOrdering}
              value={studentOrdering?.id || ""}
            />
          </Filter>
          <Filter>
            <Form.Label>Partition:</Form.Label>
            <PartitionSelector />
          </Filter>
        </FilterContainer>
      </ControlsContainer>
      <GameSummary>
        <h4>
          {headerCustomGame && GameIcons[headerCustomGame.gameTemplate.name] && (
            <MedIcon icon={GameIcons[headerCustomGame.gameTemplate.name]} className="mr-1 pt-1 pb-2" />
          )}
          {headerCustomGame
            ? headerCustomGame.gameTemplate.name
            : `${session?.customGames?.length} ${Names.CustomGames}`}
        </h4>
        {sessionClass && (
          <>
            {hasCustomGames && (
              <div>
                Students Assigned:{" "}
                <span>
                  {selectedStudentIds?.length || 0}/{sessionClass?.students?.length || 0}
                </span>
                <StyledVerticalLine thickness={1} />
                Questions: <span>{questionCount}</span>
              </div>
            )}
            <ToolsContainer>
              <ToolsLink
                onClick={onSelectAllPressed}
                disabled={selectedStudentIds.length >= (sessionClass?.students?.length || 0)}
              >
                Assign All
              </ToolsLink>
              <ToolsLink
                onClick={onSelectNonePressed}
                disabled={
                  (selectedStudentIds.length === 0 && customGame) ||
                  deselectedStudentIds.length >= (sessionClass?.students?.length || 0)
                }
              >
                Unassign All
              </ToolsLink>
            </ToolsContainer>
          </>
        )}
      </GameSummary>
      <GroupedStudentGrid
        sortStudents={studentOrdering?.handleSort}
        StudentCard={AssignmentStudentCard}
        footer={gridFooter}
        PartitionTools={AssignmentPartitionTools}
        cardData={cardData}
      />
    </MainContentContainer>
  );
};

const EditableStudentCardContainer = styled(EditableComponent)`
  margin: calc(-1 * var(--sp-1));
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--sp-2) var(--sp-3);
`;

const FilterContainer = styled(Form)`
  display: flex;
  flex-direction: row;
  gap: var(--sp-4);
`;

const Filter = styled(Form.Group)`
  max-width: 20em;
`;

const MainContentContainer = styled(Column)`
  min-width: ${layoutConstants.questionSets.detailsWidth};
  @media ${MediaConstants.laptopL} {
    min-width: ${layoutConstants.questionSets.smallDetailsWidth};
  }
  max-width: 100%;
  height: 100%;
  padding-bottom: var(--sp-2);
  flex: 1;
  --student-grid-extra-padding: var(--sp-1);
`;

const GameSummary = styled.div`
  text-align: center;
  margin: var(--sp-2) 0;
  & h4 {
    color: ${colors.darkText};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 auto;
    font-size: 1.3rem;
  }
  & p {
    color: ${colors.darkText};
    & span {
      color: ${colors.secondary};
    }
  }
`;

const ToolsContainer = styled(Row)`
  justify-content: center;
`;

export const ToolsLink = styled(Button).attrs({ variant: "link" })`
  cursor: pointer;
  font-size: 0.9em;
  margin: var(--sp-1) var(--sp-2);
  padding: 0;
  color: ${colors.primary};
  user-select: none;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    text-decoration: none;
  }
`;

const StyledVerticalLine = styled(VerticalLine)`
  height: 1.5em;
  display: inline;
`;
