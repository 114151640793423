import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLogin } from "hooks";
import { Column, LoginBackgroundContainer, OnboardingLocation, PlayEdLogo, Spacer } from "primitives";
import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { clearPromo, getValidCoupon } from "slices";
import { colors } from "styles";
import { FC } from "types";
import { getMonthlyPrice, hasActiveSubscription } from "utils";
import { getSubscriptionInfo as getSubscriptionSlice } from "slices";
import { Default, TabletOrSmaller } from "primitives/MediaQueries";
import { SelectSubscriptionMobile } from "./SelectSubscription/SelectSubscriptionMobile";
import { SelectSubscriptionDesktop } from "./SelectSubscription/SelectSubscriptionDesktop";
import styled from "styled-components";

interface Props {
  isCreate: boolean;
  onSubscriptionSelected: (id: string, promoCode?: string) => void;
}

const productImages = ["/characters/Pack_1.png", "/characters/Pack_2.png", "/characters/Pack_3.png"];

export const SelectSubscription: FC<Props> = ({ isCreate, onSubscriptionSelected }) => {
  const { product, promo } = useAppSelector((state) => state.product);
  const { info: subscriptionInfo } = useAppSelector((state) => state.subscription);
  const { userInfo, refreshTeacherData } = useLogin();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [couponInvalid, setCouponInvalid] = useState<boolean>();
  const [couponLoading, setCouponLoading] = useState<boolean>(false);
  const [couponInput, setCouponInput] = useState<string>("");

  const sortedPrices = product?.prices
    ? [...product.prices].sort((a, b) => getMonthlyPrice(b) - getMonthlyPrice(a))
    : null;

  const getSubscriptionInfo = useCallback(async () => {
    dispatch(getSubscriptionSlice());
  }, [dispatch]);

  useEffect(() => {
    refreshTeacherData();
    dispatch(clearPromo());
    if (isCreate && hasActiveSubscription(userInfo)) {
      history.push("/teacher");
    }
    if (!isCreate) {
      getSubscriptionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Used for both adding and removing a promo code.
  // The Submit button will display as "Remove" when
  // a coupon has been loaded.
  const onSubmitRevert = async () => {
    if (promo) {
      setCouponInvalid(undefined);
      dispatch(clearPromo());
      setCouponInput("");
    } else {
      setCouponLoading(true);
      try {
        await dispatch(getValidCoupon(couponInput));
        setCouponInvalid(undefined);
      } catch (error) {
        setCouponInvalid(true);
      }
      setCouponLoading(false);
    }
  };

  const couponInputChanged = (event: React.ChangeEvent<any>) => {
    const newInput: string = event.target.value;
    setCouponInput(newInput.toLocaleUpperCase());

    if (!Boolean(event.target.value)) {
      setCouponInvalid(undefined);
    }
  };

  const subscriptionSelected = (id: string) => {
    onSubscriptionSelected(id, promo?.id);
  };

  const getHeaderCopy = () => {
    if (!Boolean(product)) {
      return "";
    } else if (userInfo?.subscriptionInfo?.trialAllowed) {
      return `Your first subscription starts with a ${product?.metadata["trial_period_days"] || 0} day free-trial`;
    } else if (subscriptionInfo?.trialRollover && Math.ceil(Number(subscriptionInfo.trialRollover)) > 0) {
      return `Your subscription will resume with a ${Math.ceil(Number(subscriptionInfo.trialRollover))} day free-trial`;
    } else {
      return `Select a new subscription`;
    }
  };

  return (
    <LoginBackgroundContainer
      backgroundColour={colors.brandPurple}
      location={OnboardingLocation.register}
      includeLogo={false}
    >
      <OuterContainer>
        {userInfo?.subscriptionInfo?.trialAllowed && (
          <p>
            <b>Step 2 of 3</b>
          </p>
        )}
        <Spacer />
        <PlayEdLogo />
        <Spacer />
        <CenteredHeader>
          <b>{getHeaderCopy()}</b>
        </CenteredHeader>
        <Spacer size={30} />
        <TabletOrSmaller>
          <SelectSubscriptionMobile
            userInfo={userInfo}
            productImages={productImages}
            sortedPrices={sortedPrices}
            promo={promo}
            subscriptionInfo={subscriptionInfo}
            subscriptionSelected={subscriptionSelected}
            isCreate={isCreate}
            couponLoading={couponLoading}
            couponInput={couponInput}
            couponInvalid={couponInvalid}
            couponInputChanged={couponInputChanged}
            onSubmitRevert={onSubmitRevert}
          />
        </TabletOrSmaller>
        <Default>
          <SelectSubscriptionDesktop
            userInfo={userInfo}
            productImages={productImages}
            sortedPrices={sortedPrices}
            promo={promo}
            subscriptionInfo={subscriptionInfo}
            subscriptionSelected={subscriptionSelected}
            isCreate={isCreate}
            couponLoading={couponLoading}
            couponInput={couponInput}
            couponInvalid={couponInvalid}
            couponInputChanged={couponInputChanged}
            onSubmitRevert={onSubmitRevert}
          />
        </Default>
      </OuterContainer>
    </LoginBackgroundContainer>
  );
};

const OuterContainer = styled(Column)`
  align-items: center;
  color: white;
  width: 100%;
`;

const CenteredHeader = styled.h3`
  text-align: center;
`;
