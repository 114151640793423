import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { CustomGameActions } from "./customGameSlice";
import { fetchCustomGame as fetchCustomGameApi } from "api";

export const dispatchFetchCustomGame = (customGameId: string, actions: CustomGameActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const customGame = await fetchCustomGameApi({ customGameId, token });
    dispatch(actions.addSingle({ id: customGame.id, customGame }));
    return customGame;
  }, dispatch);
