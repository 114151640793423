import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { createSession as createSessionApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";

export interface CreateSessionParams {
  name: string;
  publicAccess: boolean;
  classId?: string | null;
  startTime: Date | null;
  endTime: Date | null;
  maxAttempts: number | null;
}

const dispatchCreateSession = (sessionParams: CreateSessionParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const newSession = await createSessionApi(token, sessionParams);

    dispatch(actions.addSingle({ id: newSession.id, session: newSession }));
    return newSession;
  }, dispatch);

export { dispatchCreateSession };
