import { TagRow } from "components";
import pluralize from "pluralize";
import { NoOverflowText, Row, SecondaryText, Spacer } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Session } from "types";
import { MedIcon } from "primitives/icons";
import { breadcrumbConstants, Names } from "textConstants";
import { getReviewTimeString } from "./reviewDateUtils";
import classNames from "classnames";
import { useEffect, useRef } from "react";

interface Props {
  session: Session;
  isSelected: boolean;
  onSessionPressed?: (sessionId: string) => void;
  isFocused?: boolean;
}

interface Selectable {
  $isSelected: boolean;
}

const ReviewSessionListItem: FC<Props> = ({ session, isSelected, isFocused, onSessionPressed }) => {
  const gameCount = session.customGames?.length || 0;
  const questionCount = session.totalQuestions || 0;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFocused, ref]);

  const sessionPressed = () => {
    if (onSessionPressed) {
      onSessionPressed(session.id);
    }
  };

  return (
    <OuterContainer ref={ref} $isSelected={isSelected} onClick={sessionPressed}>
      <Header>
        <Title>
          <MedIcon className="mr-1" icon={breadcrumbConstants.myGames.icon}></MedIcon>
          {session.name}
        </Title>
        <input type="checkbox" checked={isSelected} readOnly={true} />
      </Header>

      <Row>
        <SecondaryText>
          {gameCount} {pluralize(Names.CustomGame, gameCount)}
        </SecondaryText>
        <Spacer size={5} />
        <SecondaryText>
          {questionCount} {pluralize("Questions", questionCount)}
        </SecondaryText>
      </Row>
      <TagRow tags={session.tags || []} />
      <Footer>
        <SecondaryText>{getReviewTimeString(session, false)}</SecondaryText>
      </Footer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<Selectable>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.$isSelected ? `2px solid  ${colors.primary}` : `1px solid  ${colors.primaryDivider}`)};
  min-height: 100px;
  justify-content: space-between;
  padding: 5px;
  background-color: ${(props) => (props.$isSelected ? colors.sessionSelect : "white")};
  cursor: pointer;
`;

const Title = styled(NoOverflowText)`
  max-width: 300px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.primary};
`;

const Header = styled(Row).attrs(({ className }) => ({
  className: classNames(className, "mr-1"),
}))`
  justify-content: space-between;
`;

const Footer = styled(Row)`
  p {
    font-size: 10px;
    font-weight: 400;
    color: ${colors.secondary};
  }
  justify-content: space-between;
`;

export { ReviewSessionListItem };
