import { useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isEqual, each, omitBy } from "lodash";
import { parse } from "query-string";

function useQueryParams<T extends { [key: string]: string }>(
  defaultParams?: Partial<T>
): {
  params: T;
  setParams: (newParams: T) => void;
  updateParams: (newParams: Partial<T>) => void;
} {
  defaultParams = defaultParams || {};
  const history = useHistory();
  const location = useLocation();

  const params = parse(location.search) as T;

  const setParams = useCallback(
    (params) => {
      history.push({ search: getSearchForParams(params) });
    },
    [history]
  );

  const cleanParams = useCallback(
    (uncleanParams: Partial<T>): Partial<T> => omitBy(uncleanParams, (param) => !param || param === "[]"),
    []
  );

  useEffect(() => {
    const fullParams = cleanParams({ ...defaultParams, ...params });
    if (!isEqual(params, fullParams)) {
      setParams(fullParams);
    }
  }, [params, defaultParams, setParams, cleanParams]);

  const updateParams = (newParams: Partial<T>) => {
    const fullParams = cleanParams({ ...params, ...newParams });
    if (!isEqual(fullParams, params)) {
      setParams(fullParams);
    }
  };

  return { params, setParams, updateParams };
}

export const getSearchForParams = (params: { [key: string]: string }) => {
  const urlParams = new URLSearchParams();
  each(params, (value, key) => urlParams.append(key, value));
  return urlParams.toString();
};

export { useQueryParams };
