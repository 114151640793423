import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HelpMenuPortalState {
  portalReady: boolean;
  portalOpen: boolean;
}

const initialState: HelpMenuPortalState = { portalReady: false, portalOpen: false };

export const helpMenuPortalSlice = createSlice({
  name: "helpMenuPortal",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<boolean>) => {
      state.portalReady = action.payload;
    },
    setPortalOpen: (state, action: PayloadAction<boolean>) => {
      state.portalOpen = action.payload;
    },
  },
});

export const { set: setHelpMenuPortalNode, setPortalOpen: setHelpMenuState } = helpMenuPortalSlice.actions;
export const helpMenuPortalReducer = helpMenuPortalSlice.reducer;
