import { Class, StudentName } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  classId: string;
  studentNames: StudentName[];
}

const createStudentsForClass = async ({ token, classId, studentNames }: Params): Promise<Class> => {
  const response = await api.post(
    `/classes/${classId}/createStudents`,
    {
      studentNames,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const newClass = response.data;

  if (newClass) {
    return newClass;
  } else {
    throw new NetworkError();
  }
};

export { createStudentsForClass };
