import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubjectTags } from "slices";

interface UseSubjectTagsParams {
  fetch?: boolean;
}

const useSubjectTags = ({ fetch }: UseSubjectTagsParams) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.subjectTags);

  useEffect(() => {
    if (fetch && !state.loading && !state.success) {
      dispatch(fetchSubjectTags());
    }
  }, [state, fetch, dispatch]);

  const functions = {
    fetchSubjectTags: () => dispatch(fetchSubjectTags()),
  };

  return { ...state, ...functions };
};

export { useSubjectTags };
