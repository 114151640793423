import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types/Teacher";
import { isValidResponse } from "utils/isValidResponse";

const register = async (firstName: string, lastName: string, email: string, password: string): Promise<Teacher> => {
  const response = await api.post("/auth/register", { firstName, lastName, email, password });
  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};

export { register };
