import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ClassActions } from "./classListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchClasses as fetchClassListApi } from "api";

const dispatchFetchClassList = (actions: ClassActions) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token, userInfo } = getTokenFromState(getState);
    const data = await fetchClassListApi({ teacherId: userInfo!.id }, token);

    dispatch(actions.addMany(data));

    return data;
  }, dispatch);

export { dispatchFetchClassList };
