import { QuestionSet, Tag } from "types";
import { ReviewSubmission } from "types/ReviewSubmission";
import { ProcessQuestion, UnprocessedQuestion } from "./question";

export interface UnprocessedQuestionSet {
  id: string;
  createdAt: Date;
  title: string;
  createdBy?: {
    firstName: string;
    lastName: string;
    id: string;
  };
  estimatedLength: number;
  questionsCount: number;
  description: string;
  questions?: Array<UnprocessedQuestion>;
  modifiedAt: Date;
  archivedAt: Date | null;
  isFavourite?: boolean;
  isFeatured?: boolean;
  tags: Array<Tag>;
  parentQuestionSet?: QuestionSet | null;
  publicQuestionSet?: QuestionSet | null;
  reviewSubmissions?: ReviewSubmission[] | null;
}

export const ProcessQuestionSet = (unprocessedQuestionSet: UnprocessedQuestionSet): QuestionSet => {
  const {
    id,
    createdAt,
    title,
    createdBy,
    description,
    questionsCount,
    modifiedAt,
    archivedAt,
    tags,
    parentQuestionSet,
    publicQuestionSet,
    isFavourite,
    questions,
    estimatedLength,
    isFeatured,
    reviewSubmissions,
  } = unprocessedQuestionSet;

  return {
    title,
    author: createdBy && `${createdBy.firstName} ${createdBy.lastName}`,
    authorId: createdBy?.id,
    description,
    numQuestions: questionsCount || questions?.length || 0,
    id,
    createdAt,
    modifiedAt,
    archivedAt,
    questions: questions && questions.length > 0 ? questions.map((question) => ProcessQuestion(question)) : undefined,
    tags,
    parentQuestionSet,
    publicQuestionSet,
    isFavourite: isFavourite ? isFavourite : false,
    estimatedLength,
    isFeatured,
    reviewSubmissions,
  };
};
