import { isFunction } from "lodash";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Location = ReturnType<typeof useLocation>;
export type PromptMessageProp = string | ((location: Location, isRefresh?: boolean) => string | true);

export const useExitPrompt = (when: boolean, message?: PromptMessageProp) => {
  const location = useLocation();

  const beforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (when) {
        const myMessage = isFunction(message) ? message(location, true) : message;

        if (myMessage !== true) {
          event.preventDefault();
          event.returnValue = myMessage || "";
        }
      }
    },
    [when, location, message]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [beforeUnload]);
};
