import { Class } from "./Class";
import { Teacher } from "./Teacher";
import dayjs, { Dayjs } from "dayjs";
import { CustomGame } from "./CustomGame";
import { DateRange, Tag } from "types";

export enum SessionType {
  Current,
  Upcoming,
  Past,
  Unscheduled,
  Started,
  Ended,
  NotStarted,
  NotEnded,
}

export type Session = {
  id: string;
  name: string;
  sessionCode: string;
  startTime: string | null;
  endTime: string | null;
  publicAccess: boolean;
  maxAttempts: number | null;
  createdBy: Teacher;
  class: Class;
  isPublic: boolean;
  customGames: CustomGame[] | null;
  totalQuestions?: number;
  totalScore?: number;
  participationCount?: number;
  tags?: Tag[];
};

export type PaginatedSessionReport = {
  sessions: Session[];
  total: number;
  summary?: {
    correctAnswers: number;
    totalAnswers: number;
  };
};

export const getFirstSessionGameLink = (session: Session) => {
  if (session.customGames && session.customGames.length > 0) {
    return session.customGames[0].gameLink;
  }
  return undefined;
};

export const getSessionType = (session: Session) => {
  return getSessionTypeForDayjs(
    session.startTime ? dayjs(session.startTime) : undefined,
    session.endTime ? dayjs(session.endTime) : undefined
  );
};

export const getSessionTypeForDateRange = (dateRange: DateRange) => {
  return getSessionTypeForDayjs(
    dateRange.startDate ? dayjs(dateRange.startDate) : undefined,
    dateRange.endDate ? dayjs(dateRange.endDate) : undefined
  );
};

const getSessionTypeForDayjs = (startDay?: Dayjs, endDay?: Dayjs) => {
  if (!startDay || !endDay) {
    return SessionType.Unscheduled;
  }

  const now = dayjs();
  if (startDay < now && now < endDay) {
    return SessionType.Current;
  }

  if (startDay > now) {
    return SessionType.Upcoming;
  }

  return SessionType.Past;
};
