import { ArrowBack } from "@styled-icons/material";
import { VersionNumber } from "components";
import { ActionButton, Row, Spacer } from "primitives";
import { Default, TabletOrSmaller } from "primitives/MediaQueries";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { FC, UserAction } from "types";
import { Size } from "types/Size";

interface Props {
  title: string;
  size?: Size;
  backAction?: UserAction;
}

export const OnboardingElementContainer: FC<Props> = ({ title, children, size = "S", backAction }) => (
  <OuterContainer>
    <OnboardingElementMobile title={title} children={children} size={size} backAction={backAction} />
    <OnboardingElement title={title} children={children} size={size} backAction={backAction} />
  </OuterContainer>
);

const OnboardingElementMobile: FC<Props> = ({ title, children, backAction }) => (
  <TabletOrSmaller>
    <ContentContainer size={"90%"}>
      <Header>
        {backAction && <BackButton icon={ArrowBack} action={backAction} iconSize={"L"} />}
        <Title>{title}</Title>
      </Header>
      <MobileFormContainer>{children}</MobileFormContainer>
      <Spacer />
      <VersionContainer>
        <VersionNumber />
      </VersionContainer>
    </ContentContainer>
  </TabletOrSmaller>
);

const OnboardingElement: FC<Props> = ({ title, children, size = "S", backAction }) => (
  <Default>
    <ContentContainer size={getWidth(size)}>
      <Header>
        {backAction && <BackButton icon={ArrowBack} action={backAction} iconSize={"L"} />}
        <Title>{title}</Title>
      </Header>
      <FormContainer>{children}</FormContainer>
      <Spacer />
      <VersionContainer>
        <VersionNumber />
      </VersionContainer>
    </ContentContainer>
  </Default>
);

const getWidth = (size: Size) => {
  if (size === "S") {
    return layoutConstants.onboarding.smallContainerWidth;
  }
  if (size === "M") {
    return layoutConstants.onboarding.medContainerWidth;
  }
  if (size === "L") {
    return layoutConstants.onboarding.largeContainerWidth;
  }
};

const OuterContainer = styled(Row)`
  justify-content: center;
  width: 100%;
`;

const ContentContainer = styled.div<{ size: string | undefined }>`
  width: ${(props) => props.size};
`;

const Header = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${colors.darkText};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  font-weight: 600;
  border-radius: 30px 30px 0px 0px;
  position: relative;
`;

const Title = styled.p`
  flex: 1;
  text-align: center;
`;

const BackButton = styled(ActionButton)`
  position: absolute;
  left: 5%;
  background-color: transparent;
  border-color: transparent;
`;

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 3em 4em 2em 4em;
  border: 2px solid ${colors.darkText};
  border-radius: 0px 0px 30px 30px;
`;

const MobileFormContainer = styled(FormContainer)`
  padding: 1.5em;
`;

const VersionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
`;
