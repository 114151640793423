import { FC } from "react";
import { OverlayTrigger, Tooltip as BootstrapTooltip,  } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";

interface Props {
  tooltip: string;
  placement?: Placement;
  enabled?: boolean;
}

const ToolTip: FC<Props> = ({ tooltip, placement = "top", enabled = true, children }) => {
  if (enabled) {
    return (
      <OverlayTrigger placement={placement} overlay={<BootstrapTooltip id={tooltip}>{tooltip}</BootstrapTooltip>}>
        {children as JSX.Element}
      </OverlayTrigger>
    );
  } else {
    return children as JSX.Element;
  }
};

export { ToolTip };
