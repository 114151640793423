import dayjs from "dayjs";
import { Session } from "types";
import { isoDateWithoutTime } from "utils";

const isSameDate = (startDay: string | null, endDay: string | null) => {
  if (!startDay || !endDay) return false;
  return dayjs(isoDateWithoutTime(startDay)).isSame(dayjs(isoDateWithoutTime(endDay)));
};

const getTimeStringFromDate = (date: string) => {
  return dayjs(date).format("hh:mmA");
};

const getDateStringFromDate = (date: string) => {
  return dayjs(date).format("DD MMM");
};

const formatTimeString = (date: string | null, includeDate: boolean): String => {
  if (date) {
    if (includeDate) {
      return `${getDateStringFromDate(date)}  ${getTimeStringFromDate(date)}`;
    } else {
      return `${getTimeStringFromDate(date)}`;
    }
  } else {
    return "None Set";
  }
};

export const getReviewTimeString = (session: Session, includeDatePrefix: boolean): string => {
  if (session.startTime && session.endTime) {
    return `${formatTimeString(session.startTime, includeDatePrefix)} - ${formatTimeString(
      session.endTime,
      !isSameDate(session.startTime, session.endTime)
    )}`;
  } else {
    return "No start time set";
  }
};
