import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ClassActions } from "./classListSlice";
import { createClass as createClassApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";

export interface CreateClassParams {
  name: string;
}

const dispatchCreateClass = ({ name }: CreateClassParams, actions: ClassActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const newClass = await createClassApi({ token, name });

    dispatch(actions.addSingle({ classId: newClass.id, class: newClass }));
    return newClass;
  }, dispatch);

export { dispatchCreateClass };
