import { Row } from "primitives";
import { CreateButtonLarge } from "primitives/button";
import { LargeIcon } from "primitives/icons";
import styled from "styled-components";
import { breadcrumbConstants, Names } from "textConstants";
import { SessionType } from "types";
import { getSessionTypeForTab } from "utils";

export const EmptySessionMessage = (tab: string, onCreateSessionPressed?: () => void) => {
  const sessionType = getSessionTypeForTab(tab);
  switch (sessionType) {
    case SessionType.Current:
      return (
        <RowWithGap>
          You have no Current {Names.Sessions}. Press
          <LargeIcon icon={breadcrumbConstants.myGames.icon} color={breadcrumbConstants.myGames.accentColor} />
          <p style={{ color: breadcrumbConstants.myGames.accentColor }}> Start {Names.Session}</p>
          on an Upcoming {Names.Session} to start one.
        </RowWithGap>
      );
    case SessionType.Unscheduled:
      return (
        <RowWithGap>
          <> You have no Unscheduled {Names.Sessions}.</>
          {onCreateSessionPressed && (
            <RowWithGap>
              Press
              <CreateButtonLarge onClick={onCreateSessionPressed} tooltip={`Create ${Names.Session}`} />
              to create one.
            </RowWithGap>
          )}
        </RowWithGap>
      );
    case SessionType.Upcoming:
      return (
        <RowWithGap>
          <> You have no Upcoming {Names.Sessions}.</>
          {onCreateSessionPressed && (
            <RowWithGap>
              Press
              <CreateButtonLarge
                className="create-lesson-button"
                onClick={onCreateSessionPressed}
                tooltip={`Create ${Names.Session}`}
              />
              to create one.
            </RowWithGap>
          )}
        </RowWithGap>
      );
    case SessionType.Past:
      return <p>You haven't finished any {Names.Sessions}. When you do, you'll see them here.</p>;
  }
};

const RowWithGap = styled(Row)`
  gap: 10px;
  align-items: center;
`;
