import { Row, Badge, Spacer } from "primitives";
import { FC } from "react";
import { Tag } from "types";
import { DeleteTagButton } from "./DeleteTagButton";

interface Props {
  subjectTags: Array<{ tag: Tag; selected: boolean }>;
  setSubjectTags: (tags: Array<{ tag: Tag; selected: boolean }>) => void;
}

const SubjectTagBadges: FC<Props> = ({
  subjectTags,
  setSubjectTags,
  ...rest
}) => {
  const deleteTag = (tagId: string) =>
    setSubjectTags(
      subjectTags.map(({ tag, selected }) =>
        tag.id === tagId ? { tag, selected: false } : { tag, selected }
      )
    );

  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
      {...rest}
    >
      {subjectTags
        .filter(({ selected }) => selected)
        .map(({ tag }) => (
          <Row
            key={tag.id}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Badge className="m-1">{tag.name}</Badge>
            <DeleteTagButton
              onClick={() => (deleteTag ? deleteTag(tag.id) : null)}
            />
            <Spacer size={5} />
          </Row>
        ))}
    </Row>
  );
};

export { SubjectTagBadges };
