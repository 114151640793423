import { CustomGame, CustomGameSettings, PartialNullable } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface EditCustomGameParams {
  token: string;
  customGameId: string;
  gameTemplateId: string;
  questionSetIds: string[];
  settings: PartialNullable<CustomGameSettings>;
}

const editCustomGame = async ({
  token,
  customGameId,
  gameTemplateId,
  questionSetIds,
  settings,
}: EditCustomGameParams): Promise<CustomGame> => {
  const response = await api.put(
    `/customgames/${customGameId}`,
    {
      gameTemplateId,
      questionSetIds,
      settings,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const customGame = response.data;

  if (customGame) {
    return customGame;
  } else {
    throw new NetworkError();
  }
};

export { editCustomGame };
