import { api } from "api/api";
import { NetworkError } from "api/networkError";

export const getPaymentHistory = async (token: string) => {
  const result = await api.get("/payments/history", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (result.data) {
    return result.data;
  } else {
    throw new NetworkError();
  }
};
