import React, { FC, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ModalButtonFooter, useCharacterLimitedNonEmptyInput } from "components";
import { Class, defaultRequestState } from "types";
import { dispatchAsync } from "utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { createClass, updateClass } from "slices";

interface Props {
  visible: boolean;
  hide: () => void;
  onComplete: (newClassId: string) => void;
  classId?: string;
}

const EditClassModal: FC<Props> = ({ visible, hide, onComplete, classId }) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const [isClosing, setIsClosing] = useState(false);
  const isEditing = Boolean(classId);

  const class_ = useAppSelector((state) =>
    state.classList.data && classId ? state.classList.data[classId] : undefined
  );

  const beginHide = () => {
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    hide();
  };

  const [NameInput, nameInputState, setName] = useCharacterLimitedNonEmptyInput({
    label: "Name of Class",
    warningMessage: "You must give your class a name",
    initialTextValue: class_?.name,
  });

  const dispatch = useAppDispatch();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (nameInputState.valid) {
      if (isEditing && class_) {
        const { result, success } = await dispatchAsync(
          dispatch(updateClass(class_.id, nameInputState.text)),
          setRequestState
        );
        handleResult(success, result);
      } else {
        const { result, success } = await dispatchAsync(dispatch(createClass(nameInputState.text)), setRequestState);
        handleResult(success, result);
      }
    }
  };

  const handleResult = (success: boolean, result?: Class | null) => {
    if (success) {
      if (result) {
        onComplete(result.id);
      }
    } else {
      alert("Sorry, there was an error creating this class.");
    }
  };

  return (
    <Modal show={visible && !isClosing} onHide={beginHide} onExited={onModalHidden}>
      <Modal.Header>
        <Modal.Title>{isEditing ? "Edit" : "Create"} Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="create-class-modal" onSubmit={handleSubmit}>
          <NameInput {...nameInputState} {...setName} />
          <ModalButtonFooter
            isEditing={isEditing}
            confirmType={"submit"}
            confirmDisabled={!nameInputState.valid}
            confirmLoading={loading}
            onCancel={onModalHidden}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { EditClassModal };
