import { Row } from "primitives";
import styled from "styled-components";
import { FC } from "types";
import { layoutConstants } from "styles";
import classNames from "classnames";

interface Props {
  flexHeight?: string;
  margin?: string;
  className?: string;
  scroll?: boolean;
}

export const StudentGrid: FC<Props> = ({
  className,
  flexHeight,
  margin = "1rem auto",
  scroll = true,
  children,
  ...props
}) => {
  return (
    <OuterContainer
      className={classNames(className, "student-grid")}
      flexHeight={flexHeight}
      margin={margin}
      scroll={scroll}
      {...props}
    >
      <CardsContainer className="student-grid-row">{children}</CardsContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<Props>`
  --row-size: calc(
    var(--student-card-full-size) * var(--student-grid-row-count) - var(--student-card-gap) + 2 *
      var(--student-grid-extra-padding)
  );

  display: flex;
  flex: ${(props) => props.flexHeight || "100%"};
  flex-direction: column;
  max-width: var(--row-size);
  margin: ${(props) => props.margin};
  text-align: center;
  padding: var(--student-grid-extra-padding);
  overflow-y: ${(props) => (props.scroll ? "auto" : "visible")};
`;

export const CardsContainer = styled(Row)`
  display: flex;
  width: 100%;
  transition: ${layoutConstants.defaultTransition};
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  align-self: center;
  justify-self: center;
  justify-content: flex-start;
  gap: var(--student-card-gap);
`;
