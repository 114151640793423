import { PrimaryButton } from "primitives/button";
import { Center, Spacer, LoginBackgroundContainer, OnboardingLocation, Row } from "primitives";
import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import styled from "styled-components";
import { ToolTip } from "primitives";
import { colors } from "styles";
import { defaultRequestState } from "types";
import { Link, useHistory } from "react-router-dom";
import { useQueryParams } from "hooks";
import { dispatchAsync, isTokenInLocalStorage } from "utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { loginStudent, clearUserData } from "slices";
import { useCallback } from "react";
import { StringMap } from "types";
import { OnboardingElementContainer } from "pages";
import { paths } from "textConstants";

interface QueryParams extends StringMap {
  password: string;
  postLoginDestination: string;
  isConstruct: string;
}

const StudentLoginPage = () => {
  const [{ loading, error }, setRequestState] = useState(defaultRequestState);
  const { token, student } = useAppSelector((state) => state.studentLogin);
  const [passwordComponents, setPasswordComponents] = useState<string[]>(["", "", ""]);

  const { params: queryParams, updateParams } = useQueryParams<QueryParams>({
    postLoginDestination: "",
    password: "",
    isConstruct: "",
  });

  // Used by construct to ensure logins are not saved when multiple students log in
  const isConstructFlag =
    queryParams.isConstruct !== undefined && queryParams.isConstruct !== ""
      ? queryParams.isConstruct === "true"
      : false;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const getPassword = () => passwordComponents.join("");

  useEffect(() => {
    if (queryParams.password) {
      setPasswordComponents(queryParams.password.split(" "));
      login(queryParams.password.replaceAll(" ", ""));
      updateParams({ password: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && student && !isConstructFlag && isTokenInLocalStorage(token) && !queryParams.password) {
      if (queryParams.postLoginDestination) {
        history.push(queryParams.postLoginDestination);
      } else {
        history.push("/student");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, student, history, queryParams.postLoginDestination]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    login(getPassword());
  };

  const login = useCallback(
    async (studentPassword: string) => {
      if (!isConstructFlag && (token || student)) {
        await clearUserData();
      }

      if (studentPassword) {
        await dispatchAsync(dispatch(loginStudent(studentPassword, isConstructFlag)), setRequestState, false);
      }
    },
    [dispatch, student, token, isConstructFlag]
  );

  const passwordInputChanged = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let newPassword = [...passwordComponents];

    newPassword[index] = e.target.value;

    setPasswordComponents(newPassword);
  };

  return (
    <LoginBackgroundContainer backgroundColour={colors.warning} location={OnboardingLocation.studentlogin}>
      <OnboardingElementContainer title={"Student Login"}>
        <Form noValidate onSubmit={handleSubmit}>
          <PasswordRow>
            <Form.Group controlId="firstWord">
              <Form.Control
                type="text"
                placeholder="Word 1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => passwordInputChanged(e, 0)}
                value={passwordComponents[0]}
                autoFocus={true}
              />
            </Form.Group>
            <Form.Group controlId="firstWord">
              <Form.Control
                type="text"
                placeholder="Word 2"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => passwordInputChanged(e, 1)}
                value={passwordComponents[1]}
              />
            </Form.Group>
            <Form.Group controlId="firstWord">
              <Form.Control
                type="text"
                placeholder="Number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => passwordInputChanged(e, 2)}
                value={passwordComponents[2]}
              />
            </Form.Group>
          </PasswordRow>
          {error && (
            <Alert variant="danger" style={{ color: "black" }}>
              No student found with that password!
            </Alert>
          )}
          <AlignRight>
            <ToolTip
              tooltip="Your teacher can see your password on the class screen, and also reset it there!"
              placement="right"
            >
              <Link to="#">Forgot Password?</Link>
            </ToolTip>
          </AlignRight>
          <Spacer size={10} />
          <Spacer size={33} />
          <VerticalCenter>
            <PrimaryButton disabled={loading || !getPassword()} type={"submit"} loading={loading}>
              Login
            </PrimaryButton>
            <Link to={paths.login.teacher.path}>Switch to Teacher Log In</Link>
          </VerticalCenter>
        </Form>
      </OnboardingElementContainer>
    </LoginBackgroundContainer>
  );
};

const VerticalCenter = styled(Center)`
  flex-direction: column;
  gap: 10px;
`;

const AlignRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PasswordRow = styled(Row)`
  gap: 5px;
`;

export { StudentLoginPage };
