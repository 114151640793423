import { useAppDispatch, useAppSelector } from "app/hooks";
import { setPortalNode, updatePortalColour } from "slices";

interface BreadcrumbData {
  portalNode: HTMLElement | null;
  portalReady: boolean;
  setPortalReady: () => void;
  accentColor: string;
  highlightColor: string;
  setBreadcrumbColor: (accentColor: string, highlightColor: string) => void;
}

export const useBreadcrumbs = (): BreadcrumbData => {
  const dispatch = useAppDispatch();

  const portalNode = document.getElementById("breadcrumbPortal");
  const portalReady = useAppSelector((state) => state.breadcrumbPortal.portalReady);
  const setPortalReady = () => dispatch(setPortalNode(true));

  const accentColor = useAppSelector((state) => state.breadcrumbPortal.portalAccentColor);
  const highlightColor = useAppSelector((state) => state.breadcrumbPortal.portalHighlightColor);
  const setAccentColor = (accentColor: string, highlightColor: string) =>
    dispatch(updatePortalColour({ accentColor, highlightColor }));

  return { portalNode, portalReady, setPortalReady, accentColor, highlightColor, setBreadcrumbColor: setAccentColor };
};
