import { useAppDispatch, useAppSelector } from "app/hooks";
import { HelpMenuHeader, HelpMenuItem } from "components/breadcrumb";
import { useConfirmModal, useLogin, useTutorialState } from "hooks";
import { useEffect, useState, useCallback } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, StoreHelpers, Styles } from "react-joyride";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { FC, Tour, TourStep, resolveTourCallback, DefaultTourEmptyStepAutoProgressTimeout } from "types";
import { slugs } from "textConstants";
import { HelpTour } from "textConstants/tours";
import { colors } from "styles";
import { RootState } from "app/rootReducer";
import { Location } from "history";
import { match } from "react-router";
import { helpMessages } from "textConstants";
import { setHelpMenuState } from "slices";

const SHORT_DELAY = 30;
const LONG_DELAY = 50;
const EXTRA_LONG_DELAY = 100;
const SHORT_AUTO_TIMEOUT_CHECK = 2000;

interface SectionProgress {
  [key: string]: boolean;
}
interface Progress {
  [key: string]: SectionProgress | boolean;
}

interface Props {
  section: string;
  tours: Tour[];
}
let autoCheckInterval = 0;
let shortTimeoutAutoCheck = 0;
let timeoutAutoCheck = 0;

const TutorialsProvider: FC<Props> = ({ tours, section }) => {
  const { userInfo, updateOnboardingProgress } = useLogin();

  const [currentStep, setCurrentStep] = useState(-1);
  const [disableClickthrough, setDisableclickthrough] = useState(false);
  const [helpers, setHelpers] = useState<StoreHelpers | null>();
  const location = useLocation();
  const state = useAppSelector((state) => state);
  const { setIsPlayingTutorialState } = useTutorialState();
  const [runOnlyOne, setRunOnlyOne] = useState(false);
  const [currentStyle, setCurrentStyle] = useState<Styles>({});
  const [isRunningHelp, setRunningHelp] = useState(false);
  const [[waitingForTourIndex, waitingForTourPath], setWaitingForTour] = useState<[number, string]>([-1, ""]);
  const [onboardingProgress, setOnboardingProgress] = useState<Progress>();
  const dispatch = useAppDispatch();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  useEffect(() => {
    if (userInfo) setOnboardingProgress(JSON.parse(userInfo?.onboardingProgress || "{}"));
  }, [userInfo]);

  const findNextAutomaticTour = useCallback(
    (startAt = 0): number => {
      if (!onboardingProgress || onboardingProgress.skipAll) {
        return -1;
      }
      for (let i = startAt; i < tours.length; ++i) {
        const onboardingProgressSection = onboardingProgress[section] as SectionProgress;
        if (
          resolveTourCallback(tours[i].runAutomatically, location, state) &&
          !(onboardingProgressSection && onboardingProgressSection[tours[i].id])
        ) {
          return i;
        }
      }
      return -1;
    },
    [location, onboardingProgress, section, state, tours]
  );

  const [currentTourIndex, setCurrentTourIndex] = useState(-1);
  const currentTour = currentTourIndex >= 0 ? tours[currentTourIndex] : null;
  const history = useHistory();
  let route = "";
  if (currentTour && currentStep >= 0 && currentTour.steps[currentStep]) {
    route = currentTour.steps[currentStep].route || "";
  }
  const match = useRouteMatch(route);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        setCurrentTourIndex(-1);
      }
    });
  }, [history]);

  //efect to check if we should automatically go to the next step
  useEffect(() => {
    function goToNextStep(step: TourStep) {
      setCurrentStep(currentStep + 1);
      let doNext = () => helpers?.go(currentStep + 1);
      if (step.waitToProceed) {
        setTimeout(doNext, step.waitToProceed);
      } else {
        doNext();
      }
      if (autoCheckInterval) {
        clearInterval(autoCheckInterval);
        autoCheckInterval = 0;
      }
      if (timeoutAutoCheck) {
        clearTimeout(timeoutAutoCheck);
        timeoutAutoCheck = 0;
      }
      if (shortTimeoutAutoCheck) {
        clearTimeout(shortTimeoutAutoCheck);
        shortTimeoutAutoCheck = 0;
      }
    }

    if (currentTour && currentStep >= 0) {
      const step = currentTour.steps[currentStep];
      if (step && step.autoProgressCheck) {
        if (!step.isEmpty && step.autoProgressCheck(location, state, match)) {
          goToNextStep(step);
        } else if (step.isEmpty && !autoCheckInterval) {
          const interval = window.setInterval(() => {
            if (step && step.autoProgressCheck && step.autoProgressCheck(location, state, match)) {
              goToNextStep(step);
            }
          }, 500);
          autoCheckInterval = interval;
          if (step.autoProgressTimeout || step.isEmpty) {
            timeoutAutoCheck = window.setTimeout(() => {
              goToNextStep(step);
            }, (step.autoProgressTimeout || DefaultTourEmptyStepAutoProgressTimeout) * 1000);
            shortTimeoutAutoCheck = window.setInterval(() => {
              if (!document.body.contains(document.querySelector(".active-spinner"))) {
                goToNextStep(step);
              }
            }, SHORT_AUTO_TIMEOUT_CHECK);
          }
        }
      }
    }
  }, [location, currentTour, currentStep, helpers, state, match]);

  useEffect(() => {
    if (!currentTour && !isRunningHelp && !runOnlyOne) {
      setCurrentTourIndex(() => {
        let nextAutomaticTour = findNextAutomaticTour();
        if (nextAutomaticTour >= 0) {
          setIsPlayingTutorialState(true);
        }

        return nextAutomaticTour;
      });
    }
  }, [
    location,
    currentTour,
    state,
    match,
    findNextAutomaticTour,
    setIsPlayingTutorialState,
    isRunningHelp,
    runOnlyOne,
  ]);

  //effect to reset the tour if we've moved to a different tutorial
  useEffect(() => {
    if ((isRunningHelp || currentTourIndex >= 0) && helpers) {
      setCurrentStep(0);
      helpers.reset(true);
    }
  }, [currentTourIndex, helpers, isRunningHelp]);

  useEffect(() => {
    if (waitingForTourIndex >= 0 && location.pathname.startsWith(waitingForTourPath)) {
      setCurrentTourIndex(waitingForTourIndex);
      helpers?.reset(true);
      setWaitingForTour([-1, ""]);
    }
  }, [waitingForTourIndex, waitingForTourPath, helpers, location.pathname]);

  const onClickRegistered = useCallback(() => {
    if (
      currentStep >= 0 &&
      currentTour &&
      currentTour.steps[currentStep] &&
      currentTour.steps[currentStep].hideNext instanceof Function
    ) {
      setTimeout(
        () => setCurrentStyle(CreateStyles(currentTour.steps[currentStep], location, state, match)),
        SHORT_DELAY
      );
    }
  }, [currentStep, currentTour, state, match, location]);

  useEffect(() => {
    document.onclick = onClickRegistered;
  }, [onClickRegistered]);

  const markTourAsDone = () => {
    //set the tour as completed
    setIsPlayingTutorialState(false);

    if (currentTour && userInfo && onboardingProgress) {
      const onboardingProgressSection = onboardingProgress[section] as SectionProgress;
      if (!(onboardingProgressSection && onboardingProgressSection[currentTour.id])) {
        let newOnboarding = { ...onboardingProgress };
        if (!onboardingProgress[section]) {
          newOnboarding[section] = {};
        }
        (newOnboarding[section] as SectionProgress)[currentTour.id] = true;
        setOnboardingProgress(newOnboarding);
        updateOnboardingProgress(userInfo.id, JSON.stringify(newOnboarding));
      }
    }
  };

  const progressStepOnClick = (e: Event) => {
    if (e.currentTarget) {
      e.currentTarget.removeEventListener("click", progressStepOnClick);
      helpers?.go(currentStep + 1);
    }
  };

  //tour callbacks
  const joyrideCallback = (data: CallBackProps) => {
    const { type, index, action } = data;

    if (type === EVENTS.STEP_BEFORE || action === ACTIONS.RESET) {
      //update settings based on the current step
      setCurrentStep(index);

      const tour = isRunningHelp ? HelpTour : currentTour;
      if (tour) {
        const step = tour.steps[index];
        setDisableclickthrough(tour.steps[index].disableClickthrough === true);
        setTimeout(() => setCurrentStyle(CreateStyles(tour.steps[index], location, state, match)), LONG_DELAY);

        if (!isRunningHelp && currentTour?.steps[index].isEnd) {
          markTourAsDone();
        }

        if (step.onStart) step.onStart();

        //fixing layout issues the weird way
        setTimeout(() => window.dispatchEvent(new Event("resize")), LONG_DELAY);
        setTimeout(() => window.dispatchEvent(new Event("resize")), EXTRA_LONG_DELAY);
      }
    } else if (action === ACTIONS.SKIP) {
      setIsPlayingTutorialState(false);

      if (isRunningHelp) {
        setRunningHelp(false);
        return;
      }
      //set the skip all flag, and run the 'find tutorials here' tutorial
      if (onboardingProgress && !onboardingProgress.skipAll && userInfo) {
        const newOnboardingProgress = { ...onboardingProgress, skipAll: true };
        setOnboardingProgress(newOnboardingProgress);
        updateOnboardingProgress(userInfo.id, JSON.stringify(newOnboardingProgress));
        setCurrentTourIndex(-1);
        setRunningHelp(true);
      }
    } else if (type === EVENTS.TOUR_END) {
      setIsPlayingTutorialState(false);

      if (isRunningHelp) {
        setRunningHelp(false);
        return;
      }
      setCurrentTourIndex(-1);

      markTourAsDone();

      //find the next tour if we're running through all of them
      if (!runOnlyOne) setCurrentTourIndex(findNextAutomaticTour(currentTourIndex + 1));
    } else if (type === EVENTS.TOOLTIP) {
      const tour = isRunningHelp ? HelpTour : currentTour;
      if (tour) {
        const step = tour.steps[index];
        if (step.proceedWithButton) {
          const targetElement = document.querySelector(data.step.target as string);
          if (targetElement?.getAttribute("type") === "button") {
            targetElement.addEventListener("click", progressStepOnClick);
          }
        }
      }
    }
  };

  function closeHelpMenu() {
    dispatch(setHelpMenuState(false));
  }

  //start a tour manually
  function startTour(index: number) {
    if (index >= 0 && tours[index]) {
      setIsPlayingTutorialState(true);

      const startUrl = tours[index].startUrl;
      setRunOnlyOne(true);
      if (startUrl) {
        history.push(startUrl);
        setCurrentTourIndex(-1);
        setWaitingForTour([index, startUrl]);
      } else {
        setCurrentTourIndex(index);
        helpers?.reset(true);
      }
    }
  }

  const resetOnboarding = () => {
    closeHelpMenu();
    setModalInfo({
      body: [helpMessages.onboarding.reset],
      onModalConfirm: async () => {
        setOnboardingProgress({});
        updateOnboardingProgress(userInfo!.id, JSON.stringify({}));

        if (history.location.pathname === `/${slugs.teacher}`) {
          // If we're already on the home page, we force
          // the welcome tutorial to play
          startTour(0);
        } else {
          history.push(`/${slugs.teacher}`);
        }
      },
    });
    setModalVisible(true);
  };

  if (!userInfo) {
    return <></>;
  }

  return (
    <>
      {tours.length > 0 && <HelpMenuHeader title={"Tutorials"} />}
      {tours
        .map((t, index) => ({ ...t, index }))
        .filter((tour) => !resolveTourCallback(tour.hideFromHelpMenu, location, state))
        .map((tour) => (
          <HelpMenuItem
            key={tour.id}
            onClick={() => {
              closeHelpMenu();
              startTour(tour.index);
            }}
            title={tour.name}
            disabled={resolveTourCallback(tour.disableInHelpMenu, location, state)}
          />
        ))}
      <HelpMenuItem key={"Reset"} onClick={resetOnboarding} title="Reset Tutorials" disabled={false} />
      <EmptyTourTarget id="empty-tour-target" />
      <Joyride
        {...currentTour}
        run={currentTour !== null}
        continuous={true}
        steps={(isRunningHelp ? HelpTour : currentTour)?.steps?.map((step) => ({ ...step, disableBeacon: true })) || []}
        disableOverlayClose={true}
        styles={currentStyle}
        disableCloseOnEsc={true}
        showSkipButton={true}
        callback={joyrideCallback}
        spotlightClicks={!disableClickthrough}
        getHelpers={(helpers) => setHelpers(helpers)}
        floaterProps={{ disableAnimation: true }}
        debug={true}
        locale={{ last: "Finish", skip: "Skip Tutorial" }}
      />
      <Modal />
    </>
  );
};

const EmptyTourTarget = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  left: -1000px;
  top: -1000px;
`;

const EmptyStyle: Styles = {
  tooltip: {
    display: "none",
  },
  options: {
    arrowColor: "transparent",
  },
};

const DefaultStyle: Styles = {
  tooltip: {
    borderRadius: "0px",
    maxWidth: "500px",
    minWidth: "380px",
    width: "initial",
  },
  tooltipTitle: {
    color: colors.primary,
    marginTop: "0.25em",
  },
  buttonNext: {
    color: colors.white,
    backgroundColor: colors.primary,
    fontSize: "12px",
    borderRadius: "25px",
    border: "0px",
    minWidth: "110px",
    maxWidth: "110px",
  },
  buttonBack: {
    color: colors.white,
    backgroundColor: colors.secondary,
    fontSize: "12px",
    borderRadius: "25px",
    border: "0px",
    minWidth: "110px",
    maxWidth: "110px",
  },
  buttonClose: {
    display: "none",
    color: "black",
    width: "10px",
    height: "10px",
  },
  buttonSkip: {
    color: "black",
    fontSize: "12px",
    fontWeight: 600,
    textDecoration: " underline",
  },

  options: {
    zIndex: 5000,
  },
};

function CreateStyles(
  tourStep: TourStep,
  location: Location<unknown>,
  state: RootState,
  match?: match<any> | null
): Styles {
  if (tourStep.isEmpty) return EmptyStyle;

  let style: Styles = { ...DefaultStyle };

  if (resolveTourCallback(tourStep.hideNext, location, state, match)) {
    style.buttonNext = { display: "none" };
  }

  if (resolveTourCallback(tourStep.hideBack, location, state, match)) {
    style.buttonBack = { display: "none" };
  }

  style.spotlight = {};
  if (tourStep.left) {
    style.spotlight.marginLeft = `${tourStep.left}px`;
  }
  if (tourStep.top) {
    style.spotlight.marginTop = `${tourStep.top}px`;
  }

  return style;
}

export { TutorialsProvider };
