import { StyledIcon } from "@styled-icons/styled-icon";
import { Column, HorizontalLine, Row, Spacer } from "primitives";
import { LargeIcon } from "primitives/icons";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Student } from "types";
import { getStudentShortName } from "utils";
import { CardStyle, defaultStudentCardColourScheme } from ".";

interface Selectable {
  isSelected?: boolean;
}

function isSelected(props: Selectable) {
  return props.isSelected === true;
}

function isNotSelected(props: Selectable) {
  return props.isSelected === false;
}

export interface StudentCardRowContent {
  icon: StyledIcon;
  text: string;
}

const StudentCard: FC<
  Student & {
    cardOptions?: CardStyle;
    onStudentPressed?: (studentId: string) => void;
    isSelected?: boolean;
    badge?: JSX.Element;
    topRowElement?: StudentCardRowContent;
    bottomRowElement?: StudentCardRowContent;
  }
> = ({
  firstName,
  lastName,
  id: studentId,
  onStudentPressed,
  isSelected,
  badge,
  cardOptions = defaultStudentCardColourScheme,
  topRowElement,
  bottomRowElement,
  ...rest
}) => {
  const onClickComponent = () => {
    if (onStudentPressed) {
      onStudentPressed(studentId);
    }
  };

  return (
    <OuterContainer
      isSelected={isSelected}
      onClick={onStudentPressed ? onClickComponent : undefined}
      cardOptions={cardOptions}
      {...rest}
    >
      <UpperContainer isSelected={isSelected} cardOptions={cardOptions}>
        <p>{getStudentShortName({ firstName, lastName })}</p>
      </UpperContainer>
      <LowerContainer isSelected={isSelected} className="clip-text px-2" cardOptions={cardOptions}>
        {topRowElement && (
          <ContentRow>
            <LargeIcon icon={topRowElement.icon} />
            <Spacer size={2.5} />
            <p>{topRowElement.text}</p>
          </ContentRow>
        )}
        <HorizontalLine color={cardOptions.textColor} margin={"5px 0px"} />
        {bottomRowElement && (
          <ContentRow>
            <LargeIcon icon={bottomRowElement.icon} />
            <Spacer size={2.5} />
            <p>{bottomRowElement.text}</p>
          </ContentRow>
        )}
      </LowerContainer>
      <BadgeContent>{badge}</BadgeContent>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<Selectable & { cardOptions: CardStyle }>`
  border-radius: 0.5rem;
  width: var(--student-card-width);
  height: var(--student-card-height);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    isNotSelected(props) ? props.cardOptions.inactivePrimaryColor : props.cardOptions.activePrimaryColor};
  position: relative;
  user-select: none;
  font-weight: 600;
  border-width: ${(props) => (isSelected(props) ? "2px" : 0)};
  border-style: solid;
  border-color: ${(props) => props.cardOptions.activePrimaryColor};
  padding: ${(props) => (isSelected(props) ? 0 : "2px")};

  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
    --pop: var(--student-card-pop);
    --nPop: calc(-1 * var(--pop));
  
    &:hover {
      margin: var(--nPop) var(--nPop) var(--pop) var(--pop);
      box-shadow: var(--nPop) var(--pop) ${colors.dropShadow};
    }
  
    &:active {
      margin: var(--pop) var(--pop) var(--nPop) var(--nPop);
      box-shadow: inset var(--nPop) var(--pop) ${colors.dropShadow};
    }`}
`;

const UpperContainer = styled.div<Selectable & { cardOptions: CardStyle }>`
  font-size: 14px;
  display: flex;
  color: white;
  width: 100%;
  height: 35%;
  align-items: center;
  justify-content: center;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const LowerContainer = styled(Column)<Selectable & { cardOptions: CardStyle }>`
  flex: 1;
  background-color: ${(props) => props.cardOptions.bgColor};
  color: ${(props) =>
    isNotSelected(props)
      ? props.cardOptions.inactiveTextColor || props.cardOptions.textColor
      : props.cardOptions.textColor};
  width: 100%;
  text-align: center;
  border-radius: 0px 0px 7px 7px;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 2px;
`;

const ContentRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const BadgeContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) translateX(-10px);
`;

export { StudentCard };
