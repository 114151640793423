import styled from "styled-components";
import { colors } from "styles";

interface HeaderUnderlineProps {
  color?: string;
}

export const SidebarHeaderUnderline = styled.div<HeaderUnderlineProps>`
  border-radius: 1em;
  width: 6.5em;
  border-bottom: 0.4em solid ${(props) => props.color || colors.primary};
  margin: 0.1em 0em 1.25em 0em;
`;
