import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BreadcrumbPortalState {
  portalReady: boolean;
  portalAccentColor: string;
  portalHighlightColor: string;
}

const initialState: BreadcrumbPortalState = { portalReady: false, portalAccentColor: "", portalHighlightColor: "" };

export const breadcrumbPortalSlice = createSlice({
  name: "breadcrumbPortal",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<boolean>) => {
      state.portalReady = action.payload;
    },
    update: (state, action: PayloadAction<{ accentColor: string; highlightColor: string }>) => {
      state.portalAccentColor = action.payload.accentColor;
      state.portalHighlightColor = action.payload.highlightColor;
    },
  },
});

export const { set: setPortalNode, update: updatePortalColour } = breadcrumbPortalSlice.actions;
export const breadcrumbPortalReducer = breadcrumbPortalSlice.reducer;
