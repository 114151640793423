import { Column, Row, Spacer, Spinner } from "primitives";
import { FC, useEffect, useState, MouseEvent } from "react";
import { Form, Modal } from "react-bootstrap";
import { APIFormErrorFeedback } from "../FormErrorFeedback";
import { useGradeTags } from "hooks";
import { useGradeTagsSelector } from "./gradeTagsSelector";
import { arraysHaveSameElements, dispatchAsync } from "utils";
import { useCharacterLimitedNonEmptyInput } from "../Validators/CharacterLimitedNonEmptyInput";
import { useSubjectTagsSelector } from "../subjectTagsSelector";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { createQuestionSetNew, updateQuestionSetNew } from "slices";
import { RootState } from "app/rootReducer";
import { ApiError, defaultRequestState, QuestionSet } from "types";
import { useAppDispatch } from "app/hooks";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { TagSearch, SubjectTagBadges, ModalButtonFooter } from "components";
import { slugs } from "textConstants";

const Default_Question_Set_Length = 5;

interface Props {
  visible: boolean;
  hide: () => void;
  onComplete?: (updatedSet: Partial<QuestionSet>) => void;
}

const EditQuestionSetModal: FC<Props> = ({ onComplete, visible, hide }) => {
  const history = useHistory();
  const { questionSetId } = useParams<{ questionSetId?: string }>();
  const isEditing = Boolean(questionSetId);
  const match = useRouteMatch();

  useEffect(() => {
    const analyticsPageUrl = isEditing
      ? `/teacher/question-sets/${questionSetId}/edit/modal`
      : `/teacher/question-sets/create`;
    ReactGA.send({ hitType: "pageview", page: analyticsPageUrl });
  }, [visible, questionSetId, isEditing]);

  const [{ loading: questionSetLoading, error: questionSetError }, setRequestState] = useState(defaultRequestState);
  const questionSet = useSelector((state: RootState) =>
    state.questionSets.data && questionSetId ? state.questionSets.data[questionSetId] : undefined
  );

  const [TitleInput, titleState, setTitleState] = useCharacterLimitedNonEmptyInput({
    label: "Question Set Name",
    warningMessage: "You must enter a title",
    initialTextValue: questionSet?.title || "",
  });

  const { loading: gradeTagsLoading, error: gradeTagsError, data: gradeTags } = useGradeTags({ fetch: true });

  const { selectedSubjectTags, setSelectedSubjectTags, selectedSubjectTagIds } = useSubjectTagsSelector({
    questionSet,
  });

  const { GradeTagsSelector, gradeSelectorProps, selectedGradeTagIds } = useGradeTagsSelector({
    questionSet,
  });

  const selectedTagIds = [...selectedSubjectTagIds, ...selectedGradeTagIds];

  const [DescriptionInput, descriptionState, setDescriptionState] = useCharacterLimitedNonEmptyInput({
    label: "Description",
    initialTextValue: questionSet?.description || "",
    isTextArea: true,
  });

  const [isClosing, setIsClosing] = useState(false);

  const resetStateAndHide = (e?: MouseEvent) => {
    e?.preventDefault();

    if ((e?.currentTarget as HTMLSelectElement)?.disabled) return;
    if (questionSetLoading) return;

    setTitleState.resetState();
    setDescriptionState.resetState();
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    hide();
  };

  const nothingHasBeenChanged =
    isEditing &&
    questionSet?.title === titleState.text &&
    questionSet?.description === descriptionState.text &&
    arraysHaveSameElements(
      questionSet?.tags.map((tag) => tag.id),
      selectedTagIds
    );

  const inputIsInvalid = nothingHasBeenChanged || !titleState.valid;

  const dispatch = useAppDispatch();

  const handleConfirm = async (e?: MouseEvent) => {
    e?.preventDefault();
    const questionSetParams = {
      title: titleState.text,
      description: descriptionState.text,
      estimatedLength: Default_Question_Set_Length,
      tagIds: selectedTagIds,
      isFavourite: questionSet?.isFavourite || false,
    };
    if (isEditing) {
      const { result } = await dispatchAsync(
        dispatch(
          updateQuestionSetNew(questionSet!.id, {
            ...questionSetParams,
          })
        ),
        setRequestState
      );
      onComplete && result && onComplete(result);
      resetStateAndHide();
    } else {
      dispatchAsync(dispatch(createQuestionSetNew(questionSetParams)), setRequestState).then(({ result }) => {
        const path = match.url.substring(0, match.url.lastIndexOf(slugs.new)) + `${result?.id}/${slugs.edit}`;
        history.push(path);
      });
    }
  };

  const modalBody = (
    <Modal.Body className="create-question-set-modal">
      <Form>
        <TitleInput {...titleState} {...setTitleState} />
        <DescriptionInput {...descriptionState} {...setDescriptionState} />
        <Column className="form-column-space">
          <div>
            <Form.Label className="text-secondary">Year Level </Form.Label>
            <GradeTagsSelector {...gradeSelectorProps} className="mb-3 mt-1" />
          </div>
          <div>
            <Form.Label className="text-secondary">Add Subject(s)</Form.Label>
            <TagSearch tags={selectedSubjectTags} setTags={setSelectedSubjectTags} />
            <Row>
              <SubjectTagBadges subjectTags={selectedSubjectTags} setSubjectTags={setSelectedSubjectTags} />
            </Row>
          </div>
        </Column>
        <Spacer size={10} />
        <APIFormErrorFeedback
          error={questionSetError ? ApiError.networkError : null}
          defaultErrorMessage="There was a problem saving this question set"
        />
        <ModalButtonFooter
          isEditing={isEditing}
          onCancel={resetStateAndHide}
          onConfirm={handleConfirm}
          cancelDisabled={questionSetLoading !== false}
          confirmDisabled={inputIsInvalid}
          cancelClassName={"btn-cancel"}
          confirmType={"submit"}
          confirmLoading={questionSetLoading !== false}
        />
      </Form>
    </Modal.Body>
  );

  return (
    <Modal
      show={visible && !isClosing}
      onHide={resetStateAndHide}
      dialogClassName="full-width-modal"
      onExited={onModalHidden}
    >
      <Modal.Header>
        <Modal.Title>{isEditing ? "Edit" : "Create"} Question Set</Modal.Title>
      </Modal.Header>
      {gradeTagsLoading ? <Spinner /> : gradeTagsError ? <p>Something went wrong</p> : gradeTags ? modalBody : null}
    </Modal>
  );
};

export { EditQuestionSetModal };
