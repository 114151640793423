import { QuestionSetActionRoutes } from "components";
import styled from "styled-components";
import { QuestionSetList } from "./questionSetList";
import { QuestionSetActionHook, allQuestionSetActionHooks, usePreviewAction } from "hooks";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { resetQuestionSets } from "slices";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { CustomGameEditor } from "pages/customGames";
import { slugs } from "textConstants";
import { QuestionSetEditor } from "./questionSetEditor";

const QuestionSetsOverview = () => {
  const { search } = useLocation();

  const { url, path } = useRouteMatch();
  const history = useHistory();

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(resetQuestionSets());
  }, [dispatch]);

  const actionHooks: Array<QuestionSetActionHook> = [...allQuestionSetActionHooks];

  const listActions: QuestionSetActionHook[] = [usePreviewAction];

  return (
    <Switch>
      <Route path={`${path}/${slugs.questionSetId}/${slugs.edit}`}>
        <QuestionSetEditor canPlay={true} />
      </Route>
      <Route
        path={[
          `${path}/${slugs.questionSetId}/${slugs.miniGame}/${slugs.new}`,
          `${path}/${slugs.miniGame}/${slugs.miniGameId}`,
        ]}
      >
        <CustomGameEditor />
      </Route>
      <Route path={`${path}`}>
        <OuterContainer>
          <QuestionSetList
            selectedQuestionSetIds={[]}
            onCreateQuestionSet={() => history.push({ pathname: `${url}/${slugs.new}`, search })}
            onQuestionSetSelected={() => {}}
            actionHooks={[...actionHooks, ...listActions]}
          />

          <QuestionSetActionRoutes actionHooks={actionHooks} />
        </OuterContainer>
      </Route>
    </Switch>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding: var(--sp-3) var(--sp-3) 0 var(--sp-3);
`;

export { QuestionSetsOverview };
