import { StyledIcon } from "@styled-icons/styled-icon";
import { Center } from "primitives";
import { SmallIcon } from "primitives/icons";
import { FC } from "react";
import { Button as BootstrapButton, ButtonProps, Spinner } from "react-bootstrap";
import styled from "styled-components";

interface Props extends ButtonProps {
  loading?: boolean;
  icon?: StyledIcon;
}

const Button: FC<Props> = ({ loading, disabled, children, icon, ...rest }) => (
  <BootstrapButton disabled={loading || disabled} {...rest}>
    {loading ? (
      <Spinner animation="border" style={{ height: 22, width: 22 }} />
    ) : (
      <Center>
        {children}
        {icon && <SmallIcon icon={icon} />}
      </Center>
    )}
  </BootstrapButton>
);

const PrimaryButton = styled(Button)<{ height?: string; fontSize?: string; $minWidth?: string }>`
  min-width: ${(props) => props.$minWidth || "160px"};
  height: ${(props) => props.height || "45px"};
  border-radius: 50px;
  font-weight: 600;
  font-size: ${(props) => props.fontSize || "16px"};
`;

export { Button, PrimaryButton };
