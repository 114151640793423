import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { NewsItem, Teacher } from "types";
import { isValidResponse } from "utils/isValidResponse";

export const updateTeacher = async (
  token: string,
  teacherId: string,
  firstName: string,
  lastName: string
): Promise<Teacher> => {
  const response = await api.put(
    `/teachers/${teacherId}`,
    { firstName: firstName, lastName: lastName },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};

export const updateOnboardingProgress = async (
  token: string,
  teacherId: string,
  onboardingProgress: string
): Promise<Teacher> => {
  const response = await api.put(
    `/teachers/${teacherId}`,
    { onboardingProgress },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};

export const updateLastNewsItemRead = async (
  token: string,
  teacherId: string,
  lastReadNews: NewsItem
): Promise<Teacher> => {
  const response = await api.put(
    `/teachers/${teacherId}`,
    { lastReadNews },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};
