import { RootBreadcrumb } from "components/breadcrumb";
import { breadcrumbConstants, paths } from "textConstants";
import { Route, Switch } from "react-router-dom";
import { ArticleListOverview } from "./articleListOverview";
import { ArticleOverview } from "./articleOverview";
import { ArticleProvider } from "components/articles";

export interface AcademyParams {
  articleId: string;
}

const AcademyRoutes = () => {
  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.academy.title}
        description={breadcrumbConstants.academy.breadcrumbDescription}
        icon={breadcrumbConstants.academy.icon}
      />
      <ArticleProvider />
      <Switch>
        <Route path={paths.teacher.academy.article.path} component={ArticleOverview} />
        <Route path={paths.teacher.academy.home.path} component={ArticleListOverview} />
      </Switch>
    </>
  );
};

export { AcademyRoutes as Academy };
