import { Names } from "textConstants";
import { CustomGame, CustomGameSettings, PartialNullable } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface CreateCustomGameParams {
  token: string;
  gameTemplateId: string;
  questionSetIds: string[];
  sessionId?: string;
  settings?: PartialNullable<CustomGameSettings>;
}

const defaultParams = {
  name: Names.CustomGame,
  description: `An automatically generated ${Names.CustomGame}`,
};

const defaultSettings = {
  gameTimeLimit: null,
  questionOrder: null,
  soundEnabled: true,
};

const createCustomGame = async ({
  token,
  gameTemplateId,
  questionSetIds,
  sessionId,
  settings,
}: CreateCustomGameParams): Promise<CustomGame> => {
  const response = await api.post(
    `/customgames`,
    {
      ...defaultParams,
      gameTemplateId,
      questionSetIds,
      sessionId,
      settings: { ...defaultSettings, ...settings },
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const customGame = response.data;

  if (customGame) {
    return customGame;
  } else {
    throw new NetworkError();
  }
};

export { createCustomGame };
