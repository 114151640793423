import { Column } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Session } from "types";
import { isoDateWithoutTime } from "utils";
import { ReviewSessionDateContainer, ReviewSessionDateContainerStyle } from "./ReviewSessionDateContainer";

interface Props {
  sessions: Session[];
  selectedSessionIds: string[];
  onSessionPressed?: (sessionId: string) => void;
  focusedSessionId?: string;
}

interface SplitSessions {
  [date: string]: Array<Session>;
}

const SplitSessionsByDate = (sessions: Session[]): Array<Array<Session>> => {
  let splitSessions: SplitSessions = {};

  sessions.forEach((session) => {
    // remove any time associated
    if (session.startTime) {
      let date = isoDateWithoutTime(session.startTime);

      if (splitSessions[date]) {
        splitSessions[date].push(session);
      } else {
        splitSessions[date] = [session];
      }
    }
  });

  let sessionsArray: Array<Array<Session>> = [];
  for (let sessionDateKey in splitSessions) {
    sessionsArray.push(splitSessions[sessionDateKey]);
  }

  return sessionsArray;
};

const ReviewSessionList: FC<Props> = ({ sessions, selectedSessionIds, onSessionPressed, focusedSessionId }) => {
  return (
    <OuterContainer className="game-selector">
      <SessionDateListContainer>
        {Boolean(sessions?.length) ? (
          SplitSessionsByDate(sessions).map((sessionList, index) => (
            <ReviewSessionDateContainer
              sessions={sessionList}
              key={index}
              selectedSessionIds={selectedSessionIds}
              onSessionPressed={onSessionPressed}
              focusedSessionId={focusedSessionId}
            />
          ))
        ) : (
          <EmptyContainer>
            <b>No completed game match your filters</b>
            <span>
              Try editing your filters to see more games.
              <br />
              Games must be complete to appear in this list.
            </span>
          </EmptyContainer>
        )}
      </SessionDateListContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  overflow-y: auto;
`;

const SessionDateListContainer = styled(Column)`
  flex: 1;
  width: 100%;
  gap: 20px;
  padding: 20px 20px 10px 10px;

  ${ReviewSessionDateContainerStyle}:not(:last-child) {
    &::after {
      content: "";
      margin-top: 12px;
      width: 100%;
      height: 1px;
      background-color: ${colors.primaryDivider};
    }
  }
`;

const EmptyContainer = styled(Column)`
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: var(--sp-3);
  span {
    margin-top: var(--sp-1);
    color: ${colors.secondary};
  }
`;

export { ReviewSessionList };
