import { APIFormErrorFeedback, DescriptionText } from "components";
import { PrimaryButton } from "primitives/button";
import { Center, Column, Spacer } from "primitives";
import AuthCode from "react-auth-code-input";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useLogin, useQueryParams } from "hooks";
import { ApiError, FC, StringMap } from "types";
import { Form } from "react-bootstrap";
import { getTeacherRedirectOnLogin } from "utils";
import { paths } from "textConstants";

interface QueryParams extends StringMap {
  postLoginDestination: string;
}

interface Props {
  rememberMe: boolean;
}

const codeLength = 6;

export const TwoFactorAuthElement: FC<Props> = ({ rememberMe }) => {
  const [authCode, setAuthCode] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const { token, userInfo, twoFactorLogin, logout, loading, error } = useLogin();
  const { params: queryParams } = useQueryParams<QueryParams>({ postLoginDestination: "" });
  const history = useHistory();

  useEffect(() => {
    if (!userInfo) {
      history.push(paths.login.teacher.path);
    }
    if (token) {
      history.push(getTeacherRedirectOnLogin(queryParams.postLoginDestination));
    }
  }, [userInfo, token, history, queryParams.postLoginDestination]);

  const onAuthCodeUpdated = (code: string) => {
    setAuthCode(code);
    setCanSubmit(code.length === codeLength);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    twoFactorLogin(userInfo!.id, authCode, rememberMe);
  };

  const handleReturnToLogin = (event: React.SyntheticEvent) => {
    event.preventDefault();
    logout();
  };

  return (
    <Form>
      <Column>
        <DescriptionText>
          A code has been sent to your email address. Copy the code in the email and enter it below.
        </DescriptionText>
        <Spacer size={5} />
        <AuthCodeContainer>
          <AuthCode
            characters={codeLength}
            onChange={onAuthCodeUpdated}
            containerClassName="container"
            inputClassName="input"
          />
        </AuthCodeContainer>
        <APIFormErrorFeedback
          error={error ? ApiError.authError : null}
          errorMessages={{
            [ApiError.authError]: "The code you have entered is incorrect.",
          }}
        />
        <Spacer size={5} />
        <Center>
          <PrimaryButton disabled={!canSubmit || loading} onClick={handleSubmit} type={"submit"} loading={loading}>
            Log In
          </PrimaryButton>
        </Center>
        <Spacer size={5} />
        <Center>
          <Link to="/login/teacher" onClick={handleReturnToLogin}>
            Return to Log In
          </Link>
        </Center>
      </Column>
    </Form>
  );
};

const AuthCodeContainer = styled.div`
  .container {
    padding: 16px;
    display: flex;
    justify-content: center;
    gap: 1em;
  }

  .input {
    min-width: 2em;
    min-height: 2em;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid black;
    border-radius: 4px;
  }
`;
