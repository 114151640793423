import { api } from "api/api";
import { NetworkError } from "api/networkError";

export const createSubscription = async (token: string, selectedPriceId: string, promoCodeId?: string) => {
  const response = await api.post(
    `/payments/subscription`,
    {
      selectedPriceId,
      promoCodeId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response?.data) {
    throw new NetworkError();
  }
};
