import { useAppDispatch, useAppSelector } from "app/hooks";
import { dispatchAsync, prepPrintContent, triggerPDFDownload } from "utils";
import { LoadingContent } from "components";
import { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { blurApp, fetchClass, unBlurApp } from "slices";
import styled from "styled-components";
import { Class, defaultRequestState, FC } from "types";
import { StudentPasswordCard } from ".";
import { EmptyStudentGrid } from "../classesOverview/studentGrid/EmptyStudentGrid";
import { PrimaryButton } from "primitives/button";
import { Column, HeaderText, HorizontalLine, Row, SecondaryText, Spacer } from "primitives";
import { MedIcon } from "primitives/icons";
import { Breadcrumb } from "components/breadcrumb";
import { Print } from "@styled-icons/material";
import { createPrintableCards } from "api/class/createPrintablecards";
import { useLogin } from "hooks";

interface Props {
  class_: Class | null;
}

const PrintableComponent: FC<Props> = ({ class_ }) => (
  <MainContents>
    <StudentCardContainer className="print-password-sheet">
      {class_?.students?.length === 0 ? (
        <EmptyStudentGrid />
      ) : (
        class_?.students?.map((student) => <StudentPasswordCard student={student} key={student.id} />)
      )}
    </StudentCardContainer>
  </MainContents>
);

const StudentPasswordSheet: FC = () => {
  const [{ loading, error, success }, setRequestState] = useState(defaultRequestState);
  const dispatch = useAppDispatch();

  const { classId } = useParams<{ classId: string }>();
  const class_ = useAppSelector((state) => (classId && state.classList.data ? state.classList.data[classId] : null));
  const { url } = useRouteMatch();
  const { token } = useLogin();

  useEffect(() => {
    dispatchAsync(dispatch(fetchClass(classId)), setRequestState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const print = async () => {
    dispatch(blurApp());

    const html = prepPrintContent(<PrintableComponent class_={class_} />);

    const base64PDF = await createPrintableCards({ token: token!, classId, html }).catch((error) => {
      console.error(error);
      dispatch(unBlurApp());
    });

    if (base64PDF) {
      triggerPDFDownload(base64PDF, "studentCards");
    } else {
      console.error("No content returned when trying to print student cards");
    }

    dispatch(unBlurApp());
  };

  return (
    <LoadingContent loading={loading} error={error} success={success}>
      <Breadcrumb className="class-name-breadcrumb" to={url.replace("/print", "")}>
        <p>{class_?.name}</p>
      </Breadcrumb>
      <Breadcrumb to={url}>
        <b>Student Cards</b>
      </Breadcrumb>
      <OuterContainer>
        <Header className="d-print-none">
          <HeaderContents>
            <Column>
              <HeaderText>Student Card Sheet</HeaderText>
              <Spacer size={5} />
              <SecondaryText>Print the below list to cut out and distribute to your students</SecondaryText>
            </Column>
            <StyledButton className="print-button" onClick={print}>
              <MedIcon icon={Print} />
              <Spacer size={5} />
              Print
            </StyledButton>
          </HeaderContents>
          <HorizontalLine />
        </Header>
        <PrintableComponent class_={class_} />
      </OuterContainer>
    </LoadingContent>
  );
};

export { StudentPasswordSheet };

const OuterContainer = styled(Column)`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  padding: 2em;
`;

const Header = styled(Column)`
  width: 900px;
  justify-content: space-between;
`;

const HeaderContents = styled(Row)`
  justify-content: space-between;
`;

const StyledButton = styled(PrimaryButton)`
  align-self: center;
  width: 125px;
`;

const MainContents = styled(Row)`
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;

  @media print {
    overflow: visible;
  }
`;

const StudentCardContainer = styled(Row)`
  width: 900px;
  flex-wrap: wrap;

  @media print {
    display: table !important;
  }
`;
