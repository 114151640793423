import { HorizontalCenter, LoginBackgroundContainer, OnboardingLocation } from "primitives";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { colors } from "styles";
import { ResetPasswordElement } from "./ResetPasswordElement";
import { TeacherLoginElement } from "./TeacherLoginElement";
import { RequestResetPasswordElement } from "./RequestResetPasswordElement";
import { RequestValidateEmailElement } from "./RequestValidateEmailElement";
import { TwoFactorAuthElement } from "./TwoFactorAuthElement";
import { useState } from "react";
import { OnboardingElementContainer } from "pages";

export const TeacherLoginPage = () => {
  const { url } = useRouteMatch();
  const [rememberMe, setRememberMe] = useState(false);

  return (
    <LoginBackgroundContainer backgroundColour={colors.brandBlue} location={OnboardingLocation.teacherlogin}>
      <Switch>
        <Route path={`${url}/validate`}>
          <HorizontalCenter>
            <OnboardingElementContainer title={"Email Verification"}>
              <RequestValidateEmailElement />
            </OnboardingElementContainer>
          </HorizontalCenter>
        </Route>
        <Route path={`${url}/requestResetPassword`}>
          <OnboardingElementContainer title={"Reset Password"}>
            <RequestResetPasswordElement />
          </OnboardingElementContainer>
        </Route>
        <Route path={`${url}/resetPassword`}>
          <OnboardingElementContainer title={"Reset Password"}>
            <ResetPasswordElement />
          </OnboardingElementContainer>
        </Route>
        <Route path={`${url}/auth`}>
          <OnboardingElementContainer title={"Two-Factor Authentication"}>
            <TwoFactorAuthElement rememberMe={rememberMe} />
          </OnboardingElementContainer>
        </Route>
        <Route path="/">
          <OnboardingElementContainer title={"Teacher Login"}>
            <TeacherLoginElement rememberMe={rememberMe} setRememberMe={setRememberMe} />
          </OnboardingElementContainer>
        </Route>
      </Switch>
    </LoginBackgroundContainer>
  );
};
