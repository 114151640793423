export const customGameConstants = {
  minGameTimeLimitInMinutes: 1,
  maxGameTimeLimitInMinutes: 30,

  minQuestionTimeLimitInSeconds: 5,
  maxQuestionTimeLimitInSeconds: 120,

  minNumPlayers: 1,
  maxNumPlayers: 6,

  minNumAnswers: 2,
  maxNumAnswers: 4,
};
