import { api } from "api/api";
import { ProcessGrouping, UnprocessedGrouping } from "api/deserialisation";
import { NetworkError } from "api/networkError";
import { omit } from "lodash";
import { Grouping, Partition } from "types";

interface CreateGroupingParams {
  token: string;
  classId: string;
  name: string;
  partitions: Partial<Partition>[];
}

export const createGrouping = async ({ token, classId, name, partitions }: CreateGroupingParams): Promise<Grouping> => {
  const sanitisedData = {
    name: name,
    partitions: partitions
      ? partitions
          .filter((partition) => !partition.isDeleted)
          .map((partition) => (partition.isAdded ? omit(partition, "id") : partition))
          .map((partition) => omit(partition, "isDeleted", "isAdded", "isUpdated"))
      : [],
  };

  const response = await api.post(
    `/classes/${classId}/groupings`,
    {
      ...sanitisedData,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  const grouping: UnprocessedGrouping = response.data;

  if (grouping) {
    return ProcessGrouping(grouping);
  } else {
    throw new NetworkError();
  }
};
