import { FC, useEffect, useState, useRef, forwardRef } from "react";
import { Dropdown, Form, Button, InputGroup } from "react-bootstrap";
import { Tag } from "types";
import { filter, includes, sortBy, lowerCase } from "lodash";
import styled from "styled-components";
import { Center, Row, Spacer, ToolTip } from "primitives";
import { Close, ChevronRight, LocalOffer, Search } from "@styled-icons/material";
import { SmallIcon } from "primitives/icons";
import { PrimaryButton } from "primitives/button";
import { colors } from "styles";

interface TagStatus {
  tag: Tag;
  selected: boolean;
}

interface Props {
  tags: Array<TagStatus>;
  setTags: (tags: Array<TagStatus>) => void;
  className?: string;
  highlightChildren?: boolean;
}

interface LinkableDropdownItemProps {
  hasExtraLink?: boolean;
}

const TagSearch: FC<Props> = ({ tags, setTags, className, highlightChildren }) => {
  const [searchText, setSearchText] = useState("");
  const [availableTags, setAvailableTags] = useState<TagStatus[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [parentTag, setParentTag] = useState<Tag | null>(null);

  useEffect(() => {
    if (searchText.trim() === "") {
      const tagsWithSetParent = filter(tags, (t) => t.tag.parent?.id === parentTag?.id);
      setAvailableTags(tagsWithSetParent);
    } else {
      const descendantTagsWhichMatchSearch = filter(
        tags,
        (t) => hasTagAncestor(t.tag, parentTag) && includes(lowerCase(t.tag.name), lowerCase(searchText))
      );
      const sortedTags = sortBy(descendantTagsWhichMatchSearch, [
        (t) => lowerCase(t.tag.name).indexOf(lowerCase(searchText)),
      ]);
      setAvailableTags(sortedTags);
    }
  }, [searchText, tags, parentTag]);

  const setTagSelectionStatus = (tagId: string, newSelected: boolean) => {
    setTags(
      tags.map(({ tag, selected }) => {
        if (tag.id === tagId) {
          return { tag, selected: newSelected };
        }
        return { tag, selected };
      })
    );
  };

  const onTagClicked = (tagId: string | null, isSelected: boolean) => {
    if (tagId) {
      setShowDropdown(true);
      setTagSelectionStatus(tagId, !isSelected);
    }
  };

  const onMakeTagParent = (tagId: string) => {
    setParentTag(tags.find((t) => t.tag.id === tagId)?.tag || null);
  };

  const getTagAncestors = (tag: Tag | null): Array<Tag> => {
    let result: Array<Tag> = [];
    while (tag) {
      result = [tag, ...result];
      tag = tag.parent;
    }
    return result;
  };

  const hasTagAncestor = (tag: Tag, targetAncestor: Tag | null): boolean => {
    if (!targetAncestor) return true;
    while (tag.parent) {
      if (tag.parent.id === targetAncestor.id) return true;
      tag = tag.parent;
    }
    return false;
  };

  const CustomToggle: FC<{ onClick: (e: any) => void }> = forwardRef(({ onClick }, ref) => (
    <div ref={ref as any} onClick={onClick} style={{ height: "100%", cursor: "pointer" }}>
      <PrimaryButton style={{ height: "100%", minWidth: "120px" }} onClick={() => setShowDropdown(!showDropdown)}>
        <SmallIcon icon={LocalOffer} />
        <Spacer size={5} />
        <ButtonText>Add Subject</ButtonText>
      </PrimaryButton>
    </div>
  ));

  const isTagParentSelected = (tagId: string) => {
    if (highlightChildren) {
      let curTagStatus = tags.find((tagStatus) => tagStatus.tag.id === tagId);

      while (curTagStatus && curTagStatus.tag.parent) {
        const curTagStatusParent = curTagStatus.tag.parent;
        curTagStatus = tags.find((tagStatus) => tagStatus.tag.id === curTagStatusParent.id);

        if (curTagStatus?.selected) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <Dropdown
      className={className}
      onToggle={(show) => {
        if (!show && inputRef.current === document.activeElement) return;
        setShowDropdown(show);
        show && inputRef.current?.focus();
      }}
      show={showDropdown}
      style={{ height: "35px", borderColor: colors.darkText }}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

      <StyledDropdownMenu>
        <InputGroup>
          <Form.Control
            ref={inputRef}
            type="text"
            size="sm"
            placeholder="Search for tag"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            style={{ borderColor: colors.darkText, borderRight: "0px" }}
          />
          <InputGroup.Append>
            <InputGroup.Text
              style={{
                backgroundColor: colors.white,
                borderColor: colors.darkText,
                borderLeft: "0px",
              }}
            >
              <SmallIcon icon={Search} color={colors.primary} />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Scrollable>
          {parentTag && (
            <>
              <Spacer size={5} />
              <LinkableDropdownItemText hasExtraLink>
                {getTagAncestors(parentTag)
                  .map((t) => t.name)
                  .join(" > ")}
              </LinkableDropdownItemText>
              <DropdownItemLink variant="outline-primary" size="sm" onClick={() => setParentTag(null)}>
                <Center>
                  <SmallIcon icon={Close} />
                </Center>
              </DropdownItemLink>
              <Dropdown.Divider />
            </>
          )}
          {availableTags.map(({ tag, selected }) => (
            <TagSearchTagRow
              key={tag.id}
              tag={tag}
              selected={selected}
              highlightChildren={highlightChildren}
              onTagClicked={onTagClicked}
              onMakeTagParent={onMakeTagParent}
              isTagParentSelected={isTagParentSelected}
            />
          ))}
        </Scrollable>
      </StyledDropdownMenu>
    </Dropdown>
  );
};

interface TagSearchTagRowProps {
  tag: Tag;
  selected: boolean;
  highlightChildren?: boolean;
  onTagClicked: (tagId: string | null, isSelected: boolean) => void;
  onMakeTagParent: (tagId: string) => void;
  isTagParentSelected: (tagId: string) => boolean;
}

const TagSearchTagRow: FC<TagSearchTagRowProps> = ({
  tag,
  selected,
  highlightChildren,
  onTagClicked,
  onMakeTagParent,
  isTagParentSelected,
}) => {
  const [isSelected, setSelected] = useState(selected);

  const parentTagSelected = highlightChildren && isTagParentSelected(tag.id);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  return (
    <>
      <ToolTip enabled={parentTagSelected} tooltip="Tag already included from parent tag">
        <LinkableDropdownItem
          size="sm"
          value={tag.id}
          onSelect={() => {
            if (!parentTagSelected) {
              onTagClicked(tag.id, isSelected);
            }
          }}
          hasExtraLink={tag.children.length > 0}
        >
          <Row>
            <StyledFormCheck
              readOnly
              type="checkbox"
              checked={parentTagSelected || isSelected}
              disabled={parentTagSelected}
            />
            {tag.name}
          </Row>
        </LinkableDropdownItem>
      </ToolTip>
      {tag.children.length > 0 && (
        <DropdownItemLink
          onClick={(e: Event) => {
            e.stopPropagation();
            e.preventDefault();
            onMakeTagParent(tag.id);
          }}
          variant="outline-primary"
          size="sm"
        >
          <Center>
            <SmallIcon icon={ChevronRight} />
          </Center>
        </DropdownItemLink>
      )}
    </>
  );
};

const ButtonText = styled.p`
  font-size: 14px;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 350px;
  border-color: ${colors.darkText};
  padding: 20px;
`;

const StyledFormCheck = styled(Form.Check)`
  font-size: 14px;
  pointer-events: none;
  :active {
    color: ${colors.darkText};
  }
`;

const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

const LinkableDropdownItem = styled(({ hasExtraLink, ...props }) => (
  <Dropdown.Item {...props} />
))<LinkableDropdownItemProps>`
  font-size: 0.8em;
  padding-left: 1rem;
  cursor: pointer;
  :active,
  :hover {
    color: ${colors.darkText};
    background-color: white;
  }
  ${({ hasExtraLink }) =>
    hasExtraLink &&
    `
    padding-right: 0.25rem;
    width: 90%;
    display: inline-block;
  `}
`;

const LinkableDropdownItemText = styled(({ hasExtraLink, ...props }) => (
  <Dropdown.ItemText {...props} />
))<LinkableDropdownItemProps>`
  font-size: 0.9em;
  padding-left: 1rem;
  ${({ hasExtraLink }) =>
    hasExtraLink &&
    `
    padding-right: 0.25rem;
    width: 90%;
    display: inline-block;
  `};
`;

const DropdownItemLink = styled(Button)`
  padding: 0.25rem;
  border-width: 0;
  border-left-width: 0;
  border-radius: 0;
  margin-top: -2px;
`;

export { TagSearch };
