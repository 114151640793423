import { useEffect, useState } from "react";
import { QuestionSetSelectorSidebar } from "./QuestionSetSelectorSidebar";
import { FC } from "types";
import { changeItemPositionInArray } from "utils";
import {
  useToggleFavouriteQuestionSetActions,
  QuestionSetActionHook,
  useEditQuestionSetAction,
  useDuplicateQuestionSetAction,
  usePreviewAction,
  useQuestionSetShareAction,
} from "hooks";
import { without } from "lodash";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { QuestionSetActionRoutes } from "components";
import { QuestionSetList } from "pages/questions/questionSetList";
import { actionConstants, Names, slugs } from "textConstants";
import {
  CurrentCustomGameData,
  MiniGameStepMainContainer,
  MiniGameStepOuterContainer,
  MiniGameStepSidebarContainer,
  MiniGameStepSidebarSection,
} from "./CustomGameEditor";
import { MiniGameSessionInfo } from "./Components";
import { colors } from "styles";

interface Props {
  initialSelectedQuestionSetIds: string[];
  onSelectedSetsChanged: (selectedQuestionSetIds: string[]) => void;
  onFinishedStage: () => void;
  currentCustomGame: CurrentCustomGameData;
}

const QuestionSetMultiSelector: FC<Props> = ({
  initialSelectedQuestionSetIds,
  onSelectedSetsChanged,
  onFinishedStage,
  currentCustomGame,
}) => {
  const history = useHistory();
  const { url, params } = useRouteMatch<{ sessionId?: string }>();
  const { search } = useLocation();
  const hasSession = Boolean(params.sessionId);
  const [selectedQuestionSetIds, setSelectedQuestionSetIds] = useState<string[]>(
    hasSession ? initialSelectedQuestionSetIds : []
  );

  useEffect(() => {
    onSelectedSetsChanged(selectedQuestionSetIds);
  }, [selectedQuestionSetIds, onSelectedSetsChanged]);

  const onItemsReordered = (sourceIndex: number, destinationIndex: number) => {
    const newOrder = changeItemPositionInArray(selectedQuestionSetIds, sourceIndex, destinationIndex);
    setSelectedQuestionSetIds(newOrder);
  };

  const onConfirmPressed = () => {
    // TODO: validation?
    onFinishedStage();
  };

  const useSelectAction: QuestionSetActionHook = (questionSet) => [
    {
      id: "select",
      ...actionConstants.addToMinigame,
      isAvailable: () => !selectedQuestionSetIds.includes(questionSet.id),
      isEnabled: () => questionSet.numQuestions > 0,
      action: async () => setSelectedQuestionSetIds([...selectedQuestionSetIds, questionSet.id]),
    },
    {
      id: "deselect",
      ...actionConstants.removeFromMinigame,
      isAvailable: () => selectedQuestionSetIds.includes(questionSet.id),
      action: async () => setSelectedQuestionSetIds(without(selectedQuestionSetIds, questionSet.id)),
    },
  ];

  const listActionHooks: Array<QuestionSetActionHook> = hasSession
    ? [
        useSelectAction,
        useToggleFavouriteQuestionSetActions,
        useDuplicateQuestionSetAction,
        useEditQuestionSetAction,
        useQuestionSetShareAction,
      ]
    : [
        (questionSet) => [
          {
            id: "select",
            ...actionConstants.play,
            color: colors.white,
            iconColor: colors.white,
            backgroundColor: colors.primary,
            hoverColor: colors.white,
            hoverBackground: colors.primaryHover,
            name: "Play",
            isEnabled: () => questionSet.numQuestions > 0,
            action: async () => {
              onSelectedSetsChanged([questionSet.id]);
              onFinishedStage();
            },
          },
        ],
      ];

  return (
    <>
      <MiniGameStepOuterContainer className="question-set-multi-selector">
        {hasSession && (
          <MiniGameStepSidebarContainer>
            <MiniGameStepSidebarSection>
              <MiniGameSessionInfo />
            </MiniGameStepSidebarSection>
            <MiniGameStepSidebarSection>
              <QuestionSetSelectorSidebar
                currentCustomGame={currentCustomGame}
                selectedQuestionSetIds={selectedQuestionSetIds}
                onItemsReordered={onItemsReordered}
                onConfirmPressed={onConfirmPressed}
                actionHooks={[useSelectAction, usePreviewAction]}
              />
            </MiniGameStepSidebarSection>
          </MiniGameStepSidebarContainer>
        )}
        <MiniGameStepMainContainer>
          <h5 className="mb-5">Add Question Sets to Your {Names.CustomGame}</h5>
          <QuestionSetList
            selectedQuestionSetIds={selectedQuestionSetIds}
            onQuestionSetSelected={() => {}}
            actionHooks={[...listActionHooks, usePreviewAction]}
            onCreateQuestionSet={
              hasSession ? () => history.push({ pathname: `${url}/${slugs.new}`, search }) : undefined
            }
          />
        </MiniGameStepMainContainer>

        <QuestionSetActionRoutes actionHooks={listActionHooks} />
      </MiniGameStepOuterContainer>
    </>
  );
};

export { QuestionSetMultiSelector };
