import { api } from "../api";
import { NetworkError } from "../networkError";

const twoFactorAuthLogin = async (id: string, emailToken: string): Promise<{ token: string }> => {
  const response = await api.post("/auth/twofactorlogin", { teacherId: id, token: emailToken });
  if (response?.data?.token) {
    return { token: response.data.token };
  } else {
    throw new NetworkError();
  }
};

export { twoFactorAuthLogin };
