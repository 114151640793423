import pluralize from "pluralize";
import { VerticalLine } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { actionConstants, breadcrumbConstants, Names } from "textConstants";
import { FC, Session, SessionType, UserAction } from "types";
import {
  isValidSession,
  formatLongDate,
  formatLongDateRange,
  getTotalCustomGameQuestionCount,
  dispatchAsync,
  getPlayerCountRange,
} from "utils";
import { ListCard, ListCardStatus, ListCardTitle } from "components";
import dayjs from "dayjs";
import { keyBy, omit, uniq } from "lodash";
import { paths, slugs, urlUntil } from "textConstants/paths";
import { useHistory } from "react-router";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { updateSession, deleteSession } from "slices";
import { SessionActionHook, useConfirmModal, useSessionActions } from "hooks";
import classnames from "classnames";

type TimeDisplayOption = "float" | "inline";

interface Props {
  session: Session;
  sessionType: SessionType;
  omitActions?: string[];
  includeActions?: SessionActionHook[];
  onLessonDeleted?: (session: Session) => void;
  onLessonStarted?: (session: Session) => void;
  onLessonEnded?: (session: Session) => void;
  className?: string;
  timeDisplay?: TimeDisplayOption;
  sessionResultsSummary?: JSX.Element;
  parentElementId?: string;
  isPublicView?: boolean;
}

export const LessonPlanListItem: FC<Props> = ({
  timeDisplay = "float",
  className,
  sessionType,
  session,
  omitActions,
  includeActions,
  sessionResultsSummary,
  parentElementId,
  isPublicView = false,
  ...props
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { url, path, params } = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const isUnscheduled = sessionType === SessionType.Unscheduled;
  const isUpcoming = sessionType === SessionType.Upcoming;
  const isPast = sessionType === SessionType.Past;
  const isCurrent = sessionType === SessionType.Current;

  const includeSessionActions = useSessionActions(session, includeActions);

  const allActions: UserAction[] = [
    {
      ...actionConstants.qr,
      id: "showQR",
      action: async () => history.push(paths.teacher.myGames.session.showQR.generate(session)),
      isAvailable: () => isCurrent,
      priority: 0,
    },
    {
      ...actionConstants.startGame,
      id: "start",
      action: async () => {
        if (path.endsWith(`${slugs.sessionId}/${slugs.edit}`)) {
          history.push({
            pathname: `${url}/${slugs.start}`,
          });
        } else if (path.includes(slugs.myGames)) {
          history.push({
            pathname: `${urlUntil(path, slugs.classId, params)}/${slugs.session}/${session.id}/${slugs.start}`,
            search,
          });
        } else {
          history.push({
            pathname: paths.teacher.myGames.session.start.generate(session),
          });
        }
      },
      isAvailable: () => isUnscheduled || isUpcoming,
      isEnabled: () => isValidSession(session),
      priority: 0,
    },
    {
      ...actionConstants.viewResults,
      id: "viewResults",
      action: async () => history.push(paths.teacher.progress.session.generate(session)),
      isAvailable: () => isPast || isCurrent,
      priority: 0,
    },
    {
      ...actionConstants.endGame,
      id: "endGame",
      action: async () => {
        setModalInfo({
          title: (
            <>
              End <span style={{ color: colors.primary }}>{session.name}</span>?
            </>
          ),
          body: [
            `Are you sure you want to end this ${Names.session}?`,
            "Students will no longer be able to login and attempt it.",
          ],
          confirmButtonVariant: "danger",
          onModalConfirm: async () => {
            const now = new Date();
            const newDates = {
              startTime: dayjs(session.startTime!).toDate(),
              endTime: now,
            };

            await dispatch(updateSession(session.id, newDates));
            if (props.onLessonEnded) {
              props.onLessonEnded(session);
            }
          },
        });

        setModalVisible(true);
      },
      isAvailable: () => isCurrent,
      priority: 2,
    },
    {
      ...actionConstants.changeEndTime,
      id: "changeEndTime",
      action: async () =>
        history.push({
          pathname: paths.teacher.myGames.session.adjust.generate(session),
          search,
        }),
      isAvailable: () => isCurrent,
      priority: 3,
    },
    {
      ...actionConstants.preview,
      id: "preview",
      action: async () =>
        history.push({
          pathname: paths.teacher.myGames.session.preview.generate(session),
          search,
        }),
      priority: 10,
    },
    {
      ...actionConstants.edit,
      id: "edit",
      action: async () => history.push(paths.teacher.myGames.session.edit.generate(session)),
      isAvailable: () => !isPast,
      priority: 20,
    },
    {
      ...actionConstants.duplicate,
      id: "duplicate",
      action: async () => {
        if (pathname.endsWith(`${session.id}/${slugs.edit}`)) {
          history.push({
            pathname: `${pathname}/duplicate`,
            search,
          });
        } else {
          history.push({
            pathname: paths.teacher.myGames.session.duplicate.generate(session),
            search,
          });
        }
      },

      priority: 30,
    },
    {
      ...actionConstants.delete,
      id: "delete",
      action: async () => {
        setModalInfo({
          title: (
            <>
              Delete <span style={{ color: colors.primary }}>{session.name}</span>?
            </>
          ),
          body: [
            `Are you sure you want to delete this ${Names.session}?`,
            `This will delete this ${Names.session} forever and results will no longer be able to be viewed.`,
          ],
          confirmButtonVariant: "danger",
          onModalConfirm: async () => {
            await dispatchAsync(dispatch(deleteSession(session.id)));
            if (props.onLessonDeleted) {
              props.onLessonDeleted(session);
            }
            history.push(paths.teacher.myGames.path);
          },
          confirmTitle: "Delete",
        });
        setModalVisible(true);
      },
      priority: 40,
    },
    ...(Object.values(includeSessionActions) || []),
  ];
  const actions: { [id: string]: UserAction } = isPublicView
    ? {}
    : omit(
        keyBy(allActions, (a) => a.id),
        omitActions || []
      );

  const startTime = dayjs(session.startTime || 0);
  const endTime = dayjs(session.endTime || 0);

  return (
    <ListCard className={classnames(className)} actions={actions} parentElementId={parentElementId}>
      {timeDisplay === "float" && !isPublicView && (
        <SessionStatus className="mr-3">
          {isUpcoming ? (
            <>
              <p>
                <small>This {Names.session} is scheduled for</small>
              </p>
              <p>{formatLongDateRange(startTime, endTime)}</p>
              <p>
                <small>(in {startTime.toNow(true)})</small>
              </p>
            </>
          ) : isPast ? (
            <>
              <p>
                <small>This {Names.session} is finished</small>
              </p>
              <p>{formatLongDateRange(startTime, endTime)}</p>
              <p>
                <small>({endTime.toNow(true)} ago)</small>
              </p>
            </>
          ) : isCurrent ? (
            <>
              <p>
                <small>This {Names.session} is running</small>
              </p>
              <p className="text-danger">{endTime.toNow(true)} left</p>
              <p>
                <small>(ends {formatLongDate(endTime)})</small>
              </p>
            </>
          ) : (
            <>
              <p>
                <small>This {Names.session} has</small>
              </p>
              <p>No start or end time</p>
            </>
          )}
        </SessionStatus>
      )}
      <LessonPlanInfo
        session={session}
        sessionResultsSummary={sessionResultsSummary}
        showStudentCount={!isPublicView}
      />

      {timeDisplay === "inline" && !isPublicView && (
        <SessionInfoRow>
          {isUpcoming ? (
            <>
              <p>Starts in {startTime.toNow(true)}</p>
              <VerticalLine />
              <p>{formatLongDateRange(startTime, endTime, true)}</p>
            </>
          ) : isPast ? (
            <>
              <p>Finished {endTime.toNow(true)} ago</p>
              <VerticalLine />
              <p>{formatLongDateRange(startTime, endTime, true)}</p>
            </>
          ) : isCurrent ? (
            <>
              <p>Ends in {endTime.toNow(true)}</p>
              <VerticalLine />
              <p>{formatLongDateRange(startTime, endTime, true)}</p>
            </>
          ) : (
            <p>No start or end time set</p>
          )}
        </SessionInfoRow>
      )}
      <Modal />
    </ListCard>
  );
};

interface InfoProps {
  session: Session;
  sessionResultsSummary?: JSX.Element;
  showStudentCount?: boolean;
}

export const LessonPlanInfo: FC<InfoProps> = ({ session, sessionResultsSummary, showStudentCount = true }) => {
  const numberOfQuestions = session.customGames?.reduce(
    (prev, game) => prev + getTotalCustomGameQuestionCount(game),
    0
  );

  const studentList = session.customGames?.reduce<Array<string>>((prev, game) => {
    const studentIds: Array<string> = game.assignments?.map((assignment) => assignment.student.id) || [];
    return [...prev, ...studentIds];
  }, []);
  const studentCount = uniq(studentList).length;

  const playerRange = getPlayerCountRange(session);

  return (
    <>
      <ListCardTitle name={session.name} icon={breadcrumbConstants.myGames.icon}>
        {sessionResultsSummary}
      </ListCardTitle>
      <SessionInfoRow>
        <ValidatableStat number={session.customGames?.length || 0} errorValue={0}>
          {session.customGames?.length} {pluralize(Names.CustomGame, session.customGames?.length)}
        </ValidatableStat>
        <VerticalLine />
        <ValidatableStat number={numberOfQuestions || 0} errorValue={0}>
          {numberOfQuestions} {pluralize("Question", numberOfQuestions)}, {playerRange}{" "}
          {pluralize("player", playerRange === "1" ? 1 : 2)}
        </ValidatableStat>
        {showStudentCount && (
          <>
            <VerticalLine />
            <ValidatableStat number={studentCount} errorValue={0}>
              {studentCount} {pluralize("Student", studentCount)}
            </ValidatableStat>
          </>
        )}
      </SessionInfoRow>
    </>
  );
};

const SessionStatus = styled(ListCardStatus)`
  text-align: right;
  line-height: 1.4;
  font-size: 0.8em;
  color: ${colors.secondary};
`;

const SessionInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  color: ${colors.secondary};
`;

const ValidatableStat = styled.p<{ number: number; errorValue: number }>`
  color: ${(props) => (props.number === props.errorValue ? colors.danger : colors.secondary)};
`;
