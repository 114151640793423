import { FC } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { LoadingConstants } from "../textConstants/loadingConstants";

interface Props {
  baseColor: string;
  colors: string[];
  spinTime: number;
}

export const AnimSpinner: FC<Props> = ({ baseColor, colors, spinTime }) => {
  const quarterTurnTime = spinTime / 16.0;
  return (
    <Container spinTime={spinTime}>
      <Spinner colors={colors} spinTime={spinTime} delay={0} />
      <Spinner colors={colors} spinTime={spinTime} delay={-1 * quarterTurnTime} />
      <Spinner colors={colors} spinTime={spinTime} delay={-2 * quarterTurnTime} />
      <Spinner colors={colors} spinTime={spinTime} delay={-3 * quarterTurnTime} />
      <SpinnerBlock color={baseColor} />
    </Container>
  );
};

const spinAnim = keyframes`
0% {  
  transform: rotate(0deg);   
}
25%{
  transform: rotate(360deg); 
}
50%{
  transform: rotate(720deg);   
}
75%{
  transform: rotate(1080deg); 
}
100% {
  transform: rotate(1440deg);
}
`;

const spinColorAnim = (colors: string[]) => keyframes`
0% {  
  transform: rotate(0deg);  
  color:  ${colors[0]};
}
24.9%{
  color:  ${colors[0]};
}
25%{
  transform: rotate(360deg);
  color:  ${colors[1]};  
}
49.9%{
  color:  ${colors[1]};
}
50%{
  transform: rotate(720deg);
  color:  ${colors[2]};  
}
74.9%{
  color:  ${colors[2]};  
}
75%{
  transform: rotate(1080deg);
  color:  ${colors[3]};  
}
99.9%{  
  color:  ${colors[3]};
}
100% {
  transform: rotate(1440deg);
  color:  ${colors[0]};
}
`;

interface SpinnerProps {
  colors: string[];
  delay: number;
  spinTime: number;
}

const Spinner = styled(LoadingConstants.spinnerIcon)<SpinnerProps>`
  position: absolute;
  width: 256px;
  height: 256px;
  animation: ${(props) => spinColorAnim(props.colors)} ${(props) => `${props.spinTime}s`} linear infinite;
  animation-delay: ${(props) => `${props.delay}s`};
`;

interface SpinnerBlockProps {
  color: string;
}

const SpinnerBlock = styled(LoadingConstants.largerSpinnerIcon)<SpinnerBlockProps>`
  position: absolute;
  width: 257px;
  height: 257px;
  color: ${(props) => props.color};
`;

interface ContainerProps {
  spinTime: number;
}

const Container = styled.div<ContainerProps>`
  width: 256px;
  height: 256px;
  animation: ${spinAnim} ${(props) => `${props.spinTime}s`} linear infinite;
`;
