import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { studentDelete, studentSuccess } from "..";
import { Class, Student, StudentName } from "types";
import { dispatchCreateClass } from "./createClass";
import { dispatchCreateStudentsForClass } from "./createStudentsForClass";
import { dispatchDeleteClass } from "./deleteClass";
import { dispatchFetchClass } from "./fetchClass";
import { dispatchFetchClassList } from "./fetchClassList";
import { dispatchUpdateClass } from "./updateClass";
import { dispatchResetAllStudentPasswords } from "./resetAllStudentPasswords";

type ClassListState = {
  data: { [id: string]: Class } | null;
};

const initialState: ClassListState = {
  data: null,
};

// --- Slice Definition

const reducers = {
  reset(state: ClassListState) {
    state.data = null;
  },
  addSingle(state: ClassListState, action: PayloadAction<{ classId: string; class: Class }>) {
    if (state.data) {
      state.data[action.payload.classId] = merge(state.data[action.payload.classId], action.payload.class);
    } else {
      state.data = {
        [action.payload.classId]: action.payload.class,
      };
    }
  },
  addMany(state: ClassListState, action: PayloadAction<Class[]>) {
    if (!state.data) {
      state.data = {};
    }
    for (const session of action.payload) {
      if (!(session.id in state.data)) {
        state.data[session.id] = session;
      }
    }
  },
  remove(state: ClassListState, action: PayloadAction<{ classId: string }>) {
    if (state.data && state.data[action.payload.classId]) {
      delete state.data[action.payload.classId];
    }
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<ClassListState>) => {
  builder.addCase(studentSuccess, (state, action) => {
    if (state.data) {
      for (const class_ of Object.values(state.data)) {
        if (class_.students && action.payload) {
          const studentIndex = class_.students.findIndex((student: Student) => student.id === action.payload.id);
          if (studentIndex >= 0) {
            class_.students[studentIndex] = merge(class_.students[studentIndex], action.payload);
          }
        }
      }
    }
  });
  builder.addCase(studentDelete, (state, action) => {
    if (state.data) {
      for (const class_ of Object.values(state.data)) {
        if (class_.students && action.payload) {
          const studentIndex = class_.students.findIndex((student: Student) => student.id === action.payload!.id);
          if (studentIndex >= 0) {
            class_.students.splice(studentIndex, 1);
            class_.studentCount = class_.students.length;
          }
        }
      }
    }
  });
};

const slice = createSlice<ClassListState, typeof reducers>({
  name: "classList",
  initialState,
  reducers,
  extraReducers,
});

// --- Async Action Wrappers
const fetchClassList = () => dispatchFetchClassList(slice.actions);
const fetchClass = (classId: string) => dispatchFetchClass({ classId }, slice.actions);
const createClass = (name: string) => dispatchCreateClass({ name }, slice.actions);
const deleteClass = (classId: string) => dispatchDeleteClass({ classId }, slice.actions);
const createStudentsForClass = (studentNames: StudentName[], classId: string) =>
  dispatchCreateStudentsForClass({ studentNames, classId }, slice.actions);
const updateClass = (classId: string, className: string) => dispatchUpdateClass({ classId, className }, slice.actions);
const resetAllStudentPasswords = (classId: string) => dispatchResetAllStudentPasswords({ classId }, slice.actions);
// --- Exports
export type ClassActions = typeof slice.actions;

const classListReducer = slice.reducer;
export {
  classListReducer,
  fetchClassList,
  fetchClass,
  createClass,
  deleteClass,
  createStudentsForClass,
  updateClass,
  resetAllStudentPasswords,
};
