import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { Tag } from "types";
import { createAsyncSlice } from "./sliceHelpers";
import { fetchTags } from "api";
import { sortBy } from "lodash";

const { slice, dispatchAsyncFunction } = createAsyncSlice<Array<Tag>, {}>({
  name: "subjectTags",
});

export const {
  loading: subjectTagsLoading,
  success: subjectTagsSuccess,
  error: subjectTagsError,
  reset: subjectTagsReset,
} = slice.actions;

const fetchSubjectTags = () => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const data = await fetchTags({
      type: "Subject",
    });
    const sortedTags = sortBy(data, (tag) => tag.name);

    dispatch(subjectTagsSuccess(sortedTags));
  }, dispatch);

const subjectTagsReducer = slice.reducer;

export { subjectTagsReducer, fetchSubjectTags };
