import styled from "styled-components";
import { Column, HeaderText } from "primitives";
import { colors } from "styles";
import { AnimSpinner } from "../components";
import { LoadingConstants } from "../textConstants/loadingConstants";

const AppLoading = () => (
  <OuterContainer>
    <AnimSpinner
      baseColor={LoadingConstants.spinnerBaseColor}
      colors={LoadingConstants.spinnerColors}
      spinTime={LoadingConstants.spinTime}
    />
    <HeaderText color="white">Loading...</HeaderText>
  </OuterContainer>
);

const OuterContainer = styled(Column)`
  background-color: ${colors.brandPurple};
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export { AppLoading };
