import { useCallback, useEffect, useMemo, useState } from "react";
import { AsyncRequestState, defaultRequestState } from "types";
import { defaultPronunciationSets, dispatchAsync } from "utils";
import { Column, HorizontalLine, PrimaryButton, Row, Spinner } from "primitives";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getPronunciationSets, deletePronunciationSet } from "slices";
import styled from "styled-components";
import { PronunciationSetCard } from "components";
import { allPronunciationActionHooks, PronunciationSetActionHook, useConfirmModal } from "hooks";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { PronunciationSetEditModal } from "./PronunciationSetEditModal";
import { actionConstants, Names, paths, PlatformTitle, slugs } from "textConstants";
import { MedIcon } from "primitives/icons";
import { colors } from "styles";

export const TTSSettings = () => {
  const [{ loading, error }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const { data } = useAppSelector((state) => state.pronunciationSets);
  const sets = useMemo(() => (data ? Object.values(data) : []), [data]);
  const dispatch = useAppDispatch();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const fetchTTSSettings = useCallback(async () => {
    dispatchAsync(dispatch(getPronunciationSets()), setRequestState);
  }, [dispatch]);

  useEffect(() => {
    fetchTTSSettings();
  }, [fetchTTSSettings]);

  const useDeleteAction: PronunciationSetActionHook = (pronunciationSet) => {
    return [
      {
        id: "deletePronunication",
        name: actionConstants.delete.name,
        action: async () => {
          setModalInfo({
            title: (
              <>
                Delete <span style={{ color: colors.primary }}>{pronunciationSet.name}</span>?
              </>
            ),
            body: ["Are you sure you want to delete this Pronunciation Set?"],
            confirmButtonVariant: "danger",
            onModalConfirm: async () => {
              dispatchAsync(dispatch(deletePronunciationSet(pronunciationSet.id)), setRequestState);
            },
          });

          setModalVisible(true);
        },
        icon: actionConstants.delete.icon,
        iconColor: actionConstants.delete.iconColor,
        isAvailable: () => !pronunciationSet.defaultSet,
      },
    ];
  };

  const actionHooks: Array<PronunciationSetActionHook> = [...allPronunciationActionHooks, useDeleteAction];

  const handleAddNewPronunciationSet = () => {
    history.push(paths.teacher.settings.ttsSettings.new.path);
  };

  return (
    <>
      <Column className="mb-5">
        <Column>
          <p>
            Create and edit Pronunciation Sets to use when enabling Text-To-Speech in {Names.customGames}. Pronunciation
            Sets allow you to specify how words or characters should be pronounced by the virtual voice, e.g. “+” is
            pronounced “plus”.
          </p>
          <p>
            You can re-use these Pronunciation Sets between multiple {Names.sessions} instead of adding pronunciations
            to each
            {Names.session}.
          </p>
        </Column>
        <HorizontalLine />
        {loading ? (
          <Spinner />
        ) : error ? (
          <p>Unable to fetch Text-To-Speech Settings.</p>
        ) : (
          <>
            <Column>
              <TTSHeader>{PlatformTitle} Pronunciations</TTSHeader>
              {defaultPronunciationSets.map((set) => {
                return <PronunciationSetCard key={set.id} pronunicationSet={set} actionHooks={actionHooks} />;
              })}
            </Column>
            <Column>
              <TTSHeader>Your Pronunciations</TTSHeader>
              {sets.map((pronunciationSet) => {
                return (
                  <PronunciationSetCard
                    key={pronunciationSet.id}
                    pronunicationSet={pronunciationSet}
                    actionHooks={actionHooks}
                  />
                );
              })}
              <Row className="pt-2">
                <PrimaryButton variant="primary" onClick={handleAddNewPronunciationSet}>
                  <MedIcon icon={actionConstants.add.icon} />
                  New Pronunciation
                </PrimaryButton>
              </Row>
            </Column>
          </>
        )}
      </Column>
      <Switch>
        <Route exact path={[`${path}/${slugs.preview}/${slugs.pronunciationSetId}`]}>
          <PronunciationSetEditModal
            visible={true}
            preview={true}
            isEditing={false}
            hide={() => history.push({ pathname: url, search })}
          />
        </Route>
        <Route exact path={`${path}/${slugs.edit}/${slugs.pronunciationSetId}`}>
          <PronunciationSetEditModal
            visible={true}
            preview={false}
            isEditing={true}
            hide={() => history.push({ pathname: url, search })}
          />
        </Route>
        <Route exact path={[`${path}/${slugs.new}/${slugs.pronunciationSetId}`, `${path}/${slugs.new}`]}>
          <PronunciationSetEditModal
            visible={true}
            preview={false}
            isEditing={false}
            hide={() => history.push({ pathname: url, search })}
          />
        </Route>
      </Switch>
      <Modal />
    </>
  );
};

const TTSHeader = styled.p``;
