import { InputControlWithError } from "components";
import { characterLimitedNonEmptyValidator, useLogin, useValidatableState, Validator } from "hooks";
import { PrimaryButton } from "primitives/button";
import { Center, Row, Spacer } from "primitives";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { isEqual } from "lodash";

const myValidator: Validator = [characterLimitedNonEmptyValidator];

export const AccountSettings = () => {
  const { userInfo, updateTeacher, loading } = useLogin();
  const { firstName, lastName, email } = userInfo!;
  const [firstNameInput, setFirstNameInput, firstNameInputError] = useValidatableState(
    firstName,
    myValidator,
    "First Name"
  );
  const [lastNameInput, setLastNameInput, lastNameInputError] = useValidatableState(lastName, myValidator, "Last Name");
  const hasNameChanged = !isEqual(firstNameInput.trim(), firstName) || !isEqual(lastNameInput.trim(), lastName);
  const canApplyChanges = hasNameChanged && !firstNameInputError && !lastNameInputError;

  const handleApply = () => {
    if (canApplyChanges) {
      if (hasNameChanged) {
        updateTeacher(userInfo!.id, firstNameInput.trim(), lastNameInput.trim());
      }
    }
  };

  const handleRevert = () => {
    setFirstNameInput(firstName);
    setLastNameInput(lastName);
  };

  return (
    <Form style={{ height: "100%", width: "100%" }}>
      <Container>
        <Item>
          <StyledFormLabel>First Name*</StyledFormLabel>
          <InputControlWithError
            placeholder="First Name"
            initialInput={firstNameInput}
            onInputUpdated={setFirstNameInput}
            errorFeedback={firstNameInputError}
          />
          <Spacer />
          <StyledFormLabel>Last Name*</StyledFormLabel>
          <InputControlWithError
            placeholder="Last Name"
            initialInput={lastNameInput}
            onInputUpdated={setLastNameInput}
            errorFeedback={lastNameInputError}
          />
          <Spacer />
          <StyledFormLabel>Email*</StyledFormLabel>
          <Form.Control readOnly defaultValue={email} />
        </Item>
        <Row>
          <Center>
            <PrimaryButton variant="secondary" disabled={loading || !hasNameChanged} onClick={handleRevert}>
              Revert
            </PrimaryButton>
            <Spacer />
            <PrimaryButton disabled={loading || !canApplyChanges} loading={loading} onClick={handleApply} type="submit">
              Apply
            </PrimaryButton>
          </Center>
        </Row>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
`;

const Item = styled.div`
  width: 100%;
`;

const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
`;
