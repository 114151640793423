import { useAppDispatch, useAppSelector } from "app/hooks";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { fetchClassList, selectDefaultClass, setDefaultClass } from "slices";

const key = "lastUsedClassId";

export const useClassId = (
  alterUrl?: boolean,
  overrideValue?: string
): [string | undefined, (value?: string) => void] => {
  const classes = useAppSelector((state) => state.classList.data);
  const classList = useMemo(() => (classes ? Object.values(classes) : []), [classes]);

  const history = useHistory();

  const { url, params } = useRouteMatch<{ classId?: string }>();
  const search = history.location.search;

  const defaultClassId = useSelector(selectDefaultClass);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!classes) {
      dispatch(fetchClassList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultClassId = useCallback(
    (classId?: string): void => {
      dispatch(setDefaultClass(classId));
      if (typeof window !== "undefined") {
        try {
          if (classId) {
            window.localStorage.setItem(key, JSON.stringify(classId));
          } else {
            window.localStorage.removeItem(key);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [dispatch]
  );

  //checks and updates when a class id has been set
  useEffect(() => {
    if (defaultClassId !== undefined) {
      //check if it's a real id, and delete it if not
      if (classes && classList.length > 0 && !classes[defaultClassId]) {
        setDefaultClassId(undefined);
      }

      //update urls if we need to
      if (alterUrl && !params.classId) {
        history.push(`${url}/${defaultClassId}${search}`);
      }
      return;
    }
  }, [setDefaultClassId, defaultClassId, classList, classes, history, search, url, alterUrl, params.classId]);

  //if it's not been set, we should try and set it
  useEffect(() => {
    if (defaultClassId === undefined) {
      if (overrideValue) {
        setDefaultClassId(overrideValue);
        return;
      }

      //next check the params
      if (params.classId) {
        setDefaultClassId(params.classId);
        return;
      }

      //first look at the localStorage
      if (typeof window !== "undefined") {
        try {
          const item = window.localStorage.getItem(key);
          if (item) {
            const newClassId = JSON.parse(item);
            setDefaultClassId(newClassId);
            return;
          }
        } catch (error) {
          console.log(error);
        }
      }

      //now look at the class list
      const newClassId = (classList.length && classList[0].id) || undefined;
      if (newClassId) {
        setDefaultClassId(newClassId);
        return;
      }
    }
  }, [setDefaultClassId, overrideValue, defaultClassId, classList, params.classId]);

  return [defaultClassId, setDefaultClassId];
};

export const useClass = () => {
  const classes = useAppSelector((state) => state.classList.data);
  const [classId] = useClassId();

  if (classes && classId && classes[classId]) {
    return classes[classId];
  }

  return undefined;
};
