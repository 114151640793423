import { Tag, FC } from "types";
import { SubjectTagBadges } from "./subjectTagBadges";
import { SubjectTagsTextSearch } from "./SubjectTagsTextSearch";
import { SubjectTagsDropdownSearch } from "./subjectTagsDropDownSearch";

type SelectSubjectTagsMode = "dropdown" | "search";
interface Props {
  subjectTags: Array<{ tag: Tag; selected: boolean }>;
  setSubjectTags: (tags: Array<{ tag: Tag; selected: boolean }>) => void;
  mode: SelectSubjectTagsMode;
  toggleMode: () => void;
}
const SubjectTagsSelector: FC<Props> = ({ subjectTags, setSubjectTags, mode, toggleMode, ...rest }) => {
  const setTagSelectedStatus = (tagId: string, newSelected: boolean) => {
    setSubjectTags(
      subjectTags.map(({ tag, selected }) => {
        if (tag.id === tagId) {
          return { tag, selected: newSelected };
        }
        return { tag, selected };
      })
    );
  };

  const addTag = (tagId: string) => {
    setTagSelectedStatus(tagId, true);
  };

  return (
    <div {...rest}>
      {mode === "dropdown" ? (
        <SubjectTagsDropdownSearch
          subjectTagsWithSelectedStatus={subjectTags}
          toggleMode={toggleMode}
          addSubjectTag={addTag}
        />
      ) : (
        <SubjectTagsTextSearch
          subjectTags={subjectTags}
          toggleMode={toggleMode}
          mode={mode}
          addSubjectTag={(tagId) => setTagSelectedStatus(tagId, true)}
        />
      )}
      <SubjectTagBadges subjectTags={subjectTags} setSubjectTags={setSubjectTags} />
    </div>
  );
};

export { SubjectTagsSelector };
