import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { isValidResponse } from "utils/isValidResponse";

const mailchimpRegister = async (firstName: string, lastName: string, email: string): Promise<string> => {
  const response = await api.post("/auth/mailchimpregister", { firstName, lastName, email });
  if (response?.data && isValidResponse(response)) {
    return response?.data?.message;
  } else {
    throw new NetworkError();
  }
};

export { mailchimpRegister };
