import { api } from "api/api";
import { ProcessSession, UnprocessedSession } from "api/deserialisation";
import { NetworkError } from "api/networkError";
import { PaginatedSessionReport, Session, SessionType } from "types";

export type CompletionRequirement =
  | "AllComplete"
  | "SomeComplete"
  | "SomeIncomplete"
  | "AllIncomplete"
  | "AllCompleteOrEnded";

interface Params {
  studentId: string;
  sessionType?: SessionType;
  take?: number;
  skip?: number;
  order?: string;
  orderby?: "dec" | "asc";
  completionRequirement?: CompletionRequirement;
}

interface StudentReport {
  data: UnprocessedSession[];
  total: number;
  summary: {
    correctAnswers: number;
    totalAnswers: number;
  };
}

export const fetchSessionsForStudentReport = async (
  { studentId, sessionType, completionRequirement, take, skip, order, orderby }: Params,
  token: string
): Promise<PaginatedSessionReport> => {
  const url = `/students/${studentId}/sessions`;
  const response = await api.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      take,
      skip,
      order,
      orderby,
      sessionType: sessionType !== undefined ? SessionType[sessionType] : undefined,
      completionRequirement,
    },
  });
  if (response.data) {
    const { data: unprocessedSessions, total, summary }: StudentReport = response.data;
    const processedResponse: Session[] = unprocessedSessions.map((session) => ProcessSession(session));
    return {
      sessions: processedResponse,
      total,
      summary,
    };
  } else {
    throw new NetworkError();
  }
};
