import { colors } from "styles";
import { FC, forwardRef, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { PronunciationSet } from "types";
import { Column, Row, SecondaryText, Spacer } from "primitives";
import { KeyboardArrowDown, KeyboardArrowUp } from "@styled-icons/material";
import styled from "styled-components";
import { MedIcon } from "primitives/icons";

interface Props {
  pronunciationSets: PronunciationSet[];
  pronunciationSetIds: string[];
  setPronunciationSetIds: (pronunciationSetIds: string[]) => void;
}

export const PronunciationSetSelect: FC<Props> = ({
  pronunciationSets,
  pronunciationSetIds,
  setPronunciationSetIds,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const CustomToggle: FC<{ onClick: (e: any) => void }> = forwardRef(({ onClick }, ref) => (
    <div ref={ref as any} onClick={onClick} style={{ height: "100%", cursor: "pointer" }}>
      <div onClick={() => setShowDropdown(!showDropdown)}>
        <PronunciationSetDropDown className="px-2">
          <Column className="justify-content-center">
            <SecondaryText color={(pronunciationSetIds.length > 0 && colors.darkText) || undefined}>
              {pronunciationSetIds.length > 0
                ? pronunciationSetIds
                    .map((setId) => pronunciationSets.find((pronunciationSet) => pronunciationSet.id === setId)?.name)
                    .join(", ")
                : "No Pronunciation sets selected"}
            </SecondaryText>
          </Column>
          <Spacer size={5} />
          <Column className="justify-content-center">
            <HoverIcon
              icon={showDropdown ? KeyboardArrowUp : KeyboardArrowDown}
              onClick={(e: any) => {
                e.stopPropagation();
                onClick(e);
              }}
              highlightColor={colors.white}
            />
          </Column>
        </PronunciationSetDropDown>
      </div>
    </div>
  ));

  return (
    <Dropdown
      className="mb-4"
      onToggle={(show: boolean) => {
        if (!show && inputRef.current === document.activeElement) return;
        setShowDropdown(show);
        show && inputRef.current?.focus();
      }}
      show={showDropdown}
      style={{ height: "35px", width: "100%", borderColor: colors.darkText }}
    >
      <Dropdown.Toggle as={CustomToggle} style={{ width: "100%" }} />
      <Dropdown.Menu style={{ padding: "0", margin: "0", width: "100%" }}>
        <Scrollable>
          {pronunciationSets.map((pronunciationSet) => {
            return (
              <PronunciationSetDropDown className="px-2" key={pronunciationSet.id}>
                <Column className="justify-content-center">
                  <p>{pronunciationSet.name}</p>
                </Column>
                <Column className="justify-content-center">
                  <input
                    type="checkbox"
                    checked={pronunciationSetIds?.includes(pronunciationSet.id) || false}
                    onChange={() => {
                      let setIds = [...pronunciationSetIds];
                      const setIndex = setIds.findIndex((value) => value === pronunciationSet.id);
                      if (setIndex >= 0) {
                        setIds.splice(setIndex, 1);
                      } else {
                        setIds.push(pronunciationSet.id);
                      }
                      setPronunciationSetIds(setIds);
                    }}
                    style={{
                      borderRadius: "0px",
                      width: "20px",
                      height: "23px",
                    }}
                  />
                </Column>
              </PronunciationSetDropDown>
            );
          })}
        </Scrollable>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

const HoverIcon = styled(MedIcon)<{ highlightColor: string }>`
  :hover {
    background-color: ${(props) => props.highlightColor};
    border-radius: 3px;
  }
`;

const PronunciationSetDropDown = styled(Row)`
  border: 1px solid;
  width: 100%;
  height: 2.5em;
  justify-content: space-between;
`;
