import { StudentHeader } from "components";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { StudentSession } from "./StudentSession";
import { StudentSessionList } from "./sessionList";
import styled from "styled-components";
import { Center } from "primitives";
import { slugs } from "textConstants";

const StudentHome = () => {
  const { url } = useRouteMatch();
  return (
    <Center style={{ width: "100%", height: "100vh", flexDirection: "column" }}>
      <StudentHeader navbarItems={[]} />
      <ContentContainer>
        <Switch>
          <Route path={`${url}/${slugs.session}/${slugs.sessionKey}`} component={StudentSession} />
          <Route path={`${url}/${slugs.session}`} component={StudentSessionList} />
          <Route path="/">
            <Redirect to={`${url}/${slugs.session}`} />
          </Route>
        </Switch>
      </ContentContainer>
    </Center>
  );
};

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export { StudentHome };
