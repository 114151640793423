import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { CreateSessionParams } from "./createSession";
import { updateSession as updateSessionApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { SessionActions } from "./sessionListSlice";

const dispatchUpdateSession = (
  sessionId: string,
  sessionParams: Partial<CreateSessionParams>,
  actions: SessionActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedSession = await updateSessionApi(token, sessionId, sessionParams);

    dispatch(
      actions.addSingle({
        id: updatedSession.id,
        session: updatedSession,
      })
    );
    return updatedSession;
  }, dispatch);

export { dispatchUpdateSession };
