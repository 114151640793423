import styled from "styled-components";
import { FC, Grouping, Partition, Student } from "types";
import { Center, Column, PrimaryButton, Row, SecondaryText, Spinner } from "primitives";
import { MedIcon } from "primitives/icons";
import { colors } from "styles";
import { Add } from "@styled-icons/material";
import pluralize from "pluralize";
import { MoveDirection } from "../DragItemHandle";
import { changeItemPositionInArray, clamp } from "utils";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { PartitionToolProps, StudentPartition } from "./StudentGroupingPartition";

export const defaultPartitionId = "default";
const rootDropId = "partitionDrop";

interface Props {
  grouping: Grouping;
  students?: Student[];
  studentCards: { [studentId: string]: JSX.Element };
  onGroupingUpdated?: (grouping: Partial<Grouping>) => void;
  editingDisabled?: boolean;
  sortStudents?: (student1: Student, student2: Student) => number;
  hideGroupCount?: boolean;
  PartitionTools?: FC<PartitionToolProps>;
  setUngroupedToBottom?: boolean;
}

export const StudentGroupingGrid: FC<Props> = ({
  grouping,
  students,
  studentCards,
  onGroupingUpdated,
  editingDisabled,
  sortStudents,
  hideGroupCount,
  PartitionTools,
  setUngroupedToBottom,
}) => {
  const ungroupedStudents = grouping.partitions
    ? students?.filter(
        (student) =>
          !grouping.partitions
            ?.filter((partition) => !partition.isDeleted)
            ?.reduce<Student[]>((a, v) => a.concat(v.students), [])
            .some((groupedStudent) => student.id === groupedStudent.id)
      )
    : students;

  const onItemMovePressed = (sourceIndex: number, direction: MoveDirection) => {
    const destinationIndex = clamp(sourceIndex + direction, 0, grouping.partitions!.length - 1);

    onPartitionsReordered(sourceIndex, destinationIndex);
  };

  const onMoveAllStudents = (sourceId: string, destinationId: string, students: Student[]) => {
    if (grouping.partitions) {
      const sourcePartitionIndex =
        Number(sourceId) || grouping.partitions.findIndex((partition) => partition.id === sourceId);
      const destinationPartitionIndex =
        Number(destinationId) || grouping.partitions.findIndex((partition) => partition.id === destinationId);

      onStudentsReordered(sourcePartitionIndex, destinationPartitionIndex, students);
    }
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || (destination?.droppableId === source?.droppableId && destination?.index === source?.index)) {
      return;
    }

    if (source.droppableId === rootDropId) {
      // We remove 1 from the index as "Ungrouped is index 0"
      onPartitionsReordered(source!.index - 1, destination!.index - 1);
    } else if (grouping.partitions && ungroupedStudents) {
      const sourcePartitionIndex =
        Number(source.droppableId) || grouping.partitions.findIndex((partition) => partition.id === source.droppableId);
      const destinationPartitionIndex =
        Number(destination.droppableId) ||
        grouping.partitions.findIndex((partition) => partition.id === destination.droppableId);

      const selectedStudent =
        sourcePartitionIndex >= 0
          ? grouping.partitions[sourcePartitionIndex].students[source.index]
          : ungroupedStudents[source.index];
      onStudentsReordered(sourcePartitionIndex, destinationPartitionIndex, [selectedStudent]);
    }
  };

  const onStudentsReordered = (sourceIndex: number, destinationIndex: number, students: Student[]) => {
    if (!grouping.partitions) {
      return;
    }

    const newPartitions = grouping.partitions.map((partition) => ({ ...partition, students: [...partition.students] }));

    for (let student of students) {
      let sourcePartition: Partition | undefined;

      for (let partition of newPartitions) {
        if (partition.students.find((partitionStudent) => partitionStudent.id === student.id)) {
          sourcePartition = partition;
          break;
        }
      }

      if (sourcePartition) {
        const sourceStudentIndex = sourcePartition.students.findIndex(
          (partitionStudent) => partitionStudent.id === student.id
        );
        if (sourceStudentIndex >= 0) {
          sourcePartition.students.splice(sourceStudentIndex, 1);
        }

        sourcePartition.isUpdated = true;
      }
    }

    if (destinationIndex >= 0) {
      const destinationPartition = newPartitions[destinationIndex];
      for (let student of students) {
        destinationPartition.students.push(student);
      }
      destinationPartition.isUpdated = true;
      newPartitions[destinationIndex] = destinationPartition;
    }

    if (onGroupingUpdated) {
      onGroupingUpdated({ partitions: newPartitions });
    }
  };

  const onPartitionsReordered = (sourceIndex: number, destinationIndex: number) => {
    const newPartitions = changeItemPositionInArray(grouping.partitions!, sourceIndex, destinationIndex);
    const orderedPartitions = newPartitions.map((partition, index) => ({
      ...partition,
      order: index,
    }));
    orderedPartitions[sourceIndex].isUpdated = true;
    orderedPartitions[destinationIndex].isUpdated = true;

    if (onGroupingUpdated) {
      onGroupingUpdated({ partitions: orderedPartitions });
    }
  };

  const getNewGroupName = (): string => {
    let name = `Group ${(grouping.partitions?.length || 0) + 1}`;
    let offset = 0;

    // Looks like a false positive from ESLint here. It's throwing
    // https://eslint.org/docs/latest/rules/no-loop-func but it
    // doesn't appear to be relevant to the code below based on
    // some preliminary searching.
    // eslint-disable-next-line
    while (grouping.partitions?.some((partition) => partition.name === name)) {
      offset += 1;
      name = `Group ${(grouping.partitions?.length || 0) + 1 + offset}`;
    }

    return name;
  };

  const onCreatePartition = () => {
    const newPartition: Partition = {
      name: getNewGroupName(),
      order: grouping.partitions?.length || 0,
      students: [],
      isDeleted: false,
      isAdded: true,
      isUpdated: false,
    };

    if (onGroupingUpdated) {
      const currentPartitions = grouping.partitions ? [...grouping.partitions] : [];
      currentPartitions.push(newPartition);
      onGroupingUpdated({ partitions: currentPartitions });
    }
  };

  const onDeletePartition = (index: number) => {
    if (index >= 0) {
      let newPartitions = [...grouping.partitions!];
      // If this partition was only just added, instantly remove it
      if (newPartitions[index].isAdded) {
        newPartitions.splice(index, 1);
      } else {
        newPartitions[index] = { ...newPartitions[index], isDeleted: true };
      }

      if (onGroupingUpdated) {
        onGroupingUpdated({ partitions: newPartitions });
      }
    }
  };

  const onPartitionNameChanged = (partitionId: string, newName: string) => {
    let newPartitions = [...grouping.partitions!];
    const partitionIndex = Number(partitionId) || newPartitions.findIndex((partition) => partition.id === partitionId);

    if (partitionIndex >= 0) {
      newPartitions[partitionIndex] = { ...newPartitions[partitionIndex], name: newName, isUpdated: true };
    }

    if (onGroupingUpdated) {
      onGroupingUpdated({ partitions: newPartitions });
    }
  };

  const allPartitions = [
    { id: defaultPartitionId, name: "Ungrouped" },
    ...(grouping.partitions?.map((partition, index) => ({
      id: partition.id || index.toString(),
      name: partition.name,
    })) || []),
  ];

  const ungroupedStudentPartition = (
    <StudentPartition
      partitionName="Ungrouped"
      partitionId={defaultPartitionId}
      students={ungroupedStudents!}
      studentCards={studentCards}
      index={0}
      totalCount={0}
      allPartitions={allPartitions}
      onTriggerBulkMove={onMoveAllStudents}
      editingDisabled={editingDisabled}
      sortStudents={sortStudents}
      PartitionTools={PartitionTools}
    />
  );

  return (
    <>
      {!hideGroupCount && (
        <Row className="mb-3">
          <Center>
            <SecondaryText>
              {grouping.partitions?.filter((partition) => !partition.isDeleted).length || 0}{" "}
              {pluralize("group", grouping.partitions?.filter((partition) => !partition.isDeleted).length || 0)} in{" "}
              <b>{grouping.name}</b>
            </SecondaryText>
          </Center>
        </Row>
      )}
      <OuterContainer editingDisabled={editingDisabled}>
        {grouping.partitions ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              {!setUngroupedToBottom && ungroupedStudents && ungroupedStudentPartition}
              <Droppable
                droppableId={rootDropId}
                direction="vertical"
                type="StudentPartition"
                isDropDisabled={editingDisabled}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {grouping.partitions &&
                      grouping.partitions.map((partition, index) => {
                        return partition.isDeleted ? (
                          <DeletedPartition key={index} />
                        ) : (
                          <StudentPartition
                            key={index}
                            partitionName={partition.name}
                            partitionId={partition.id || index.toString()}
                            students={partition.students}
                            studentCards={studentCards}
                            index={index}
                            totalCount={grouping.partitions?.length || 0}
                            onClickDeletePartition={onDeletePartition}
                            onMoveItemPressed={onItemMovePressed}
                            onNameChanged={(id, name) => onPartitionNameChanged(id, name)}
                            allPartitions={allPartitions}
                            onTriggerBulkMove={onMoveAllStudents}
                            editingDisabled={editingDisabled}
                            sortStudents={sortStudents}
                            PartitionTools={PartitionTools}
                          />
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {setUngroupedToBottom && ungroupedStudents && ungroupedStudentPartition}
            </DragDropContext>
            {!editingDisabled && (
              <ActionButtonRow>
                <PrimaryButton onClick={onCreatePartition}>
                  <MedIcon icon={Add} />
                  New Group
                </PrimaryButton>
              </ActionButtonRow>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </OuterContainer>
    </>
  );
};

const OuterContainer = styled(Column)<{ editingDisabled?: boolean }>`
  width: 100%;
  overflow-y: auto;
  align-items: stretch;
  margin: 0 auto;
  --partition-row-size: calc(
    var(--student-partition-header-width) + 2 * var(--student-card-gap) + var(--student-card-full-size) *
      var(--partition-grid-row-count) - var(--student-card-gap) + 2 * var(--student-grid-extra-padding)
      ${(props) => (props.editingDisabled ? `` : `+ var(--student-partition-delete-width) + var(--student-card-gap)`)}
  );
  max-width: var(--partition-row-size);
`;

const DeletedPartition = styled.div`
  background-color: ${colors.changesMade};
  height: 100px;
  border-bottom: 1px solid ${colors.primaryDivider};
`;

const ActionButtonRow = styled(Row)`
  gap: 10px;
  margin: var(--sp-3) 0px;
  padding: 0px var(--sp-3);
  width: 100%;
`;
