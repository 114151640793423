import { Student, StudentName } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  studentId: string;
  names: StudentName;
}

const updateStudent = async ({ token, studentId, names }: Params): Promise<Student> => {
  const response = await api.put(`/students/${studentId}`, names, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const updatedStudent = response.data;

  if (updatedStudent) {
    return updatedStudent;
  } else {
    throw new NetworkError();
  }
};

export { updateStudent };
