import { ProcessCustomGame } from "api/deserialisation";
import { CustomGame } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface FetchCustomGameParams {
  token: string;
  customGameId: string;
}

const fetchCustomGame = async ({ token, customGameId }: FetchCustomGameParams): Promise<CustomGame> => {
  const response = await api.get(`/customgames/${customGameId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const customGame = ProcessCustomGame(response.data);

  if (customGame) {
    return customGame;
  } else {
    throw new NetworkError();
  }
};

export { fetchCustomGame };
