import { FC } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Row } from "primitives";

export type SettingsTabName = "accountDetails" | "membership" | "security" | "paymentHistory";

interface Props {
  selectedTab?: SettingsTabName;
  setSelectedTab: (tab: SettingsTabName) => void;
}

export const SettingsTabRow: FC<Props> = ({ selectedTab, setSelectedTab }) => {
  return (
    <Row style={{ width: "100%" }}>
      <Tabs
        defaultActiveKey=""
        activeKey={selectedTab}
        // @ts-ignore
        onSelect={(k: SettingsTabName) => setSelectedTab(k)}
      >
        <Tab eventKey="accountDetails" title="Account Details" />
        <Tab eventKey="membership" title="Membership" />
        <Tab eventKey="security" title="Security" />
        <Tab eventKey="paymentHistory" title="Payment History" />
        <Tab eventKey="ttsSettings" title="Text-To-Speech" />
      </Tabs>
    </Row>
  );
};
