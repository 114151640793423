import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { createAsyncSlice, getTokenFromState } from "./sliceHelpers";
import { NewsItem } from "types";
import { getNewsItems } from "api/news";

const { slice, dispatchAsyncFunction } = createAsyncSlice<Array<NewsItem>, {}>({
  name: "newsItems",
});

export const {
  loading: newsItemsLoading,
  success: newsItemsSuccess,
  error: newsItemsError,
  reset: newsItemsReset,
} = slice.actions;

const fetchNewsItems = () => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const data = await getNewsItems(token);
    dispatch(newsItemsSuccess(data));
  }, dispatch);

const newsItemsReducer = slice.reducer;
export { newsItemsReducer, fetchNewsItems };
