import { Teacher } from "types/Teacher";
import { api } from "../api";
import { NetworkError } from "../networkError";

export type UserInfo = Teacher;

const login = async (email: string, password: string): Promise<{ token: string; userInfo: UserInfo }> => {
  const response = await api.post("/auth/login", { email, password });
  if (response?.data) {
    return { token: response.data.token, userInfo: response.data.teacher };
  } else {
    throw new NetworkError();
  }
};

export { login };
