import { CustomGameSettings, FC } from "types";
import { Column, Row } from "primitives";
import { InputGroup, FormControl } from "react-bootstrap";
import { FormErrorFeedback, FormWarningFeedback } from "components";
import { colors } from "styles";
import styled from "styled-components";

export type CustomGameSettingsWithEnabledValues = {
  [key in keyof CustomGameSettings]: {
    enabled: boolean;
    value: CustomGameSettings[key];
  };
};

export type CustomGameSettingsWithEnabledAndValidValues = {
  [key in keyof CustomGameSettings]: {
    enabled: boolean;
    error: string | undefined;
    value: CustomGameSettings[key];
    warning: string | undefined;
  };
};

export type UpdateGameSettingsParams = Partial<
  {
    [K in keyof CustomGameSettingsWithEnabledValues]: Partial<CustomGameSettingsWithEnabledValues[K]>;
  }
>;

type UpdateGameSettings = (update: UpdateGameSettingsParams) => void;

interface NumberSettingControllerProps {
  title: string;
  settingName: keyof Omit<
    CustomGameSettingsWithEnabledValues,
    "questionOrder" | "soundEnabled" | "multiplayerSettings"
  >;
  gameSettings: CustomGameSettingsWithEnabledAndValidValues;
  updateGameSettings: UpdateGameSettings;
  unitsLabel?: string;
  disabledLabel?: string;
  required?: boolean;
  minValue?: number;
  maxValue?: number;
  readonly?: boolean;
}

const NumberSettingController: FC<NumberSettingControllerProps> = ({
  title,
  settingName,
  gameSettings,
  updateGameSettings,
  unitsLabel,
  disabledLabel,
  required = false,
  minValue,
  maxValue,
  readonly,
}) => {
  const { value, enabled, error, warning } = gameSettings[settingName];
  const disabled = !enabled && !required;

  return (
    <OuterContainer>
      <InputGroup hasValidation>
        <Column>
          <TopRow style={{ paddingLeft: !required ? "5px" : "0" }}>
            {!required && (
              <input
                type="checkbox"
                checked={enabled}
                onChange={() => {
                  updateGameSettings({
                    [settingName]: {
                      enabled: !enabled,
                    },
                  });
                }}
                style={{
                  borderRadius: "0px",
                  width: "20px",
                  height: "23px",
                }}
                disabled={readonly}
              />
            )}
            <label>{title}</label>
          </TopRow>

          {disabled ? (
            <DisabledMessage
              onClick={() => {
                if (!readonly) {
                  updateGameSettings({
                    [settingName]: {
                      enabled: !enabled,
                    },
                  });
                }
              }}
            >
              {disabledLabel}
            </DisabledMessage>
          ) : (
            <Row>
              <FormControl
                isInvalid={Boolean(error) || Boolean(warning)}
                value={`${value}`}
                type="number"
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue < 0) return;

                  updateGameSettings({
                    [settingName]: { value: Number(e.target.value) },
                  });
                }}
                style={{
                  border: "1px solid black",
                  borderRadius: "0px",
                  backgroundColor: disabled ? colors.checkboxBgGrey : "white",
                  maxWidth: 225,
                }}
                min={minValue}
                max={maxValue}
                disabled={readonly}
              />
              {unitsLabel ? (
                <InputGroup.Append>
                  <InputGroup.Text
                    style={{
                      color: colors.darkText,
                      backgroundColor: colors.checkboxBgGrey,
                      border: "1px solid black",
                    }}
                  >
                    {unitsLabel}
                  </InputGroup.Text>
                </InputGroup.Append>
              ) : null}
            </Row>
          )}
        </Column>
      </InputGroup>
      {error ? <FormErrorFeedback errorMessage={error} /> : <FormWarningFeedback errorMessage={warning} />}
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  min-height: 72px;
  min-width: 290px;
`;

const TopRow = styled(Row)`
  gap: 11px;
`;

const DisabledMessage = styled.div`
  padding-left: 5px;
  max-width: 250px;
  font-size: 0.8em;
  line-height: 1.6;
`;

export { NumberSettingController };
