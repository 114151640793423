import React, { FC } from "react";
import { PrimaryButton, Row } from "primitives";
import styled from "styled-components";
import { ButtonProps } from "react-bootstrap";

interface Props {
  isEditing: boolean;
  className?: string;
  isPreview?: boolean;
  onCancel?: (e?: React.MouseEvent) => void;
  onConfirm?: (e?: React.MouseEvent) => void;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  cancelClassName?: string;
  confirmClassName?: string;
  cancelLoading?: boolean;
  confirmLoading?: boolean;
  cancelType?: string;
  confirmType?: string;
  confirmVariant?: string;
  cancelVariant?: string;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmProps?: Partial<ButtonProps>;
}

export const ModalButtonFooter: FC<Props> = ({
  isEditing,
  className,
  isPreview,
  onCancel,
  onConfirm,
  cancelDisabled,
  confirmDisabled,
  cancelClassName,
  confirmClassName,
  cancelLoading,
  confirmLoading,
  cancelType,
  confirmType,
  confirmVariant,
  cancelVariant,
  confirmTitle,
  cancelTitle,
  confirmProps,
}) => {
  return (
    <StyledRow className={className}>
      <PrimaryButton
        variant={cancelVariant ? cancelVariant : "secondary"}
        onClick={onCancel}
        disabled={cancelDisabled}
        className={cancelClassName}
        loading={cancelLoading}
        type={cancelType}
      >
        {cancelTitle || (isPreview ? "Okay" : isEditing ? "Undo" : "Cancel")}
      </PrimaryButton>
      {!isPreview && (
        <PrimaryButton
          variant={confirmVariant ? confirmVariant : "primary"}
          onClick={onConfirm}
          disabled={confirmDisabled}
          className={confirmClassName}
          loading={confirmLoading}
          type={confirmType}
          {...confirmProps}
        >
          {confirmTitle || (isEditing ? "Save" : "Confirm")}
        </PrimaryButton>
      )}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  gap: 2em;
  justify-content: center;
`;
