import { ChevronLeft } from "@styled-icons/material";
import { useAppSelector } from "app/hooks";
import { GetCardStyleForResult } from "components";
import { MiniGameCard, MiniGameResultsPreview } from "components/miniGame";
import { LessonPlanListItem } from "pages/my-games/lessonPlans/LessonPlanListItem";
import { ResultIndicator } from "pages/progress/reviewClass/ReviewStudentInfoItem";
import { Column, PrimaryButton, Row, SecondaryText, Spacer, VerticalLine } from "primitives";
import { MedIcon } from "primitives/icons";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { FC, getSessionType } from "types";
import { getCorrectCount, getCorrectPercentage, getResultForStudent, getTotalQuestions } from "utils";

interface Props {
  selectedSessionId: string;
  onBackPressed?: () => void;
}

export const StudentRecordSessionReview: FC<Props> = ({ selectedSessionId, onBackPressed }) => {
  // TODO: Do we need to fetch the session if it's not loaded? We currently don't have
  // a fetchSession that runs on a particular student's context
  // For now we have a fallback element to prevent the page crashing
  const session = useAppSelector((state) => state.student.sessions[selectedSessionId]);
  const { studentId } = useParams<{ studentId: string }>();
  const results = getResultForStudent(session, studentId);

  if (!session) {
    return (
      <OuterContainer>
        <Row>
          <PrimaryButton variant="secondary" onClick={onBackPressed}>
            <MedIcon icon={ChevronLeft} />
            Go Back
          </PrimaryButton>
        </Row>
        <p>There was a problem loading the results!</p>
      </OuterContainer>
    );
  }

  return (
    <OuterContainer>
      <Row>
        <PrimaryButton variant="secondary" onClick={onBackPressed}>
          <MedIcon icon={ChevronLeft} />
          Go Back
        </PrimaryButton>
      </Row>
      <Spacer size={5} />
      <LessonPlanListItem
        session={session}
        sessionType={getSessionType(session)}
        omitActions={["preview", "viewResults", "duplicate", "delete"]}
      />
      <Spacer size={5} />
      <MiniGameContainer>
        {session.customGames &&
          session.customGames.map((customGame) => {
            const totalAnswers = getTotalQuestions(results[customGame.id].bestResult);
            const correctAnswers = getCorrectCount(results[customGame.id].bestResult);
            const percentageCorrect = Math.round(getCorrectPercentage(correctAnswers, totalAnswers) * 100);

            return (
              <div key={customGame.id}>
                <SummaryRow>
                  <SecondaryText>
                    <b>Correct Answers:</b>
                  </SecondaryText>
                  <ResultIndicator
                    style={{ margin: "0px var(--sp-2)" }}
                    colour={
                      GetCardStyleForResult(getCorrectPercentage(correctAnswers, totalAnswers), totalAnswers, true)
                        .inactivePrimaryColor
                    }
                  />
                  {correctAnswers}/{totalAnswers} ({percentageCorrect}%)
                  <VerticalLine />
                  Attempts {results[customGame.id].attempts}
                </SummaryRow>
                <Spacer size={10} />
                <div style={{ padding: "var(--sp-2)", backgroundColor: colors.light }}>
                  <MiniGameCard
                    miniGame={customGame}
                    QuestionSetComponent={MiniGameResultsPreview}
                    bestResult={results[customGame.id]}
                  />
                </div>
              </div>
            );
          })}
      </MiniGameContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  padding: var(--sp-3);
  height: 100%;
  overflow-y: auto;
`;

const MiniGameContainer = styled(Column)`
  gap: var(--sp-3);
`;

const SummaryRow = styled(Row)`
  align-items: center;
  color: ${colors.secondary};
`;
