import { QuestionSet } from "types";
import { ReviewSubmission } from "types/ReviewSubmission";

const questionSetIsFeatured = (questionSet: QuestionSet) =>
  (Boolean(questionSet.parentQuestionSet) && Boolean(questionSet.isFeatured) && !questionSetIsArchived(questionSet)) ||
  (Boolean(questionSet.publicQuestionSet) &&
    Boolean(questionSet.publicQuestionSet?.isFeatured) &&
    !questionSetIsArchived(questionSet.publicQuestionSet!));

const questionSetIsPublicCopy = (questionSet: QuestionSet) => Boolean(questionSet.parentQuestionSet);

const questionSetOwnedByLoggedInUser = (questionSet: QuestionSet, userInfo: any): boolean => {
  return questionSet.authorId === userInfo?.id;
};

const questionSetCanBeEdited = (questionSet: QuestionSet, userInfo: any): boolean => {
  const questionSetIsPublicCopy = Boolean(questionSet.parentQuestionSet);
  return !questionSetIsPublicCopy && questionSetOwnedByLoggedInUser(questionSet, userInfo);
};

const questionSetIsArchived = (questionSet: QuestionSet) => Boolean(questionSet.archivedAt);

const questionSetHasSubmissionData = (questionSet: QuestionSet) =>
  questionSet.reviewSubmissions && questionSet.reviewSubmissions.length > 0;

const getQuestionSetLatestReviewSubmission = (questionSet: QuestionSet): ReviewSubmission | null => {
  if (!questionSetHasSubmissionData(questionSet)) {
    return null;
  } else {
    let latestSubmission: ReviewSubmission | null = null;
    questionSet.reviewSubmissions!.forEach((submission) => {
      if (!latestSubmission || latestSubmission.createdAt < submission.createdAt) {
        latestSubmission = submission;
      }
    });

    return latestSubmission;
  }
};

export {
  questionSetIsFeatured,
  questionSetIsPublicCopy,
  questionSetCanBeEdited,
  questionSetIsArchived,
  questionSetOwnedByLoggedInUser,
  getQuestionSetLatestReviewSubmission,
};
