import { PronunciationSet, UserAction, UserActionHook } from "types";
import { actionConstants, paths } from "textConstants";
import { keyBy } from "lodash";
import { useHistory } from "react-router-dom";

export type PronunciationSetAction = UserAction;
export type PronunciationSetActionHook = UserActionHook<PronunciationSet>;

const usePreviewAction: PronunciationSetActionHook = (pronunciationSet) => {
  const history = useHistory();

  return [
    {
      id: "previewPronunication",
      name: actionConstants.preview.name,
      action: async () => {
        history.push(paths.teacher.settings.ttsSettings.preview.generate(pronunciationSet));
      },
      icon: actionConstants.preview.icon,
    },
  ];
};

const useEditAction: PronunciationSetActionHook = (pronunciationSet) => {
  const history = useHistory();

  return [
    {
      id: "editPronunication",
      name: actionConstants.edit.name,
      action: async () => {
        history.push(paths.teacher.settings.ttsSettings.edit.generate(pronunciationSet));
      },
      icon: actionConstants.edit.icon,
      isAvailable: () => !pronunciationSet.defaultSet,
    },
  ];
};

const useDuplicationAction: PronunciationSetActionHook = (pronunciationSet) => {
  const history = useHistory();

  return [
    {
      id: "duplicatePronunication",
      name: actionConstants.duplicate.name,
      action: async () => {
        history.push(paths.teacher.settings.ttsSettings.duplicate.generate(pronunciationSet));
      },
      icon: actionConstants.duplicate.icon,
    },
  ];
};

const allPronunciationActionHooks = [usePreviewAction, useEditAction, useDuplicationAction];

const usePronunciationActions = (
  pronunciationSet: PronunciationSet,
  actionHooks?: Array<PronunciationSetActionHook>
): { [id: string]: PronunciationSetAction } => {
  actionHooks = actionHooks || allPronunciationActionHooks;

  const actions = actionHooks.reduce((curr, actionHook) => {
    let actions = actionHook(pronunciationSet);
    actions = actions.filter((a) => !a.isAvailable || a.isAvailable());
    actions = actions.map((action, i) => ({
      ...action,
      priority: action.priority || i,
    }));

    return [...curr, ...actions];
  }, [] as Array<PronunciationSetAction>);

  return keyBy(actions, "id");
};

export { allPronunciationActionHooks, usePronunciationActions };
