import { FC } from "react";
import styled from "styled-components";
import { Row } from "../row";

type Props = React.ComponentProps<typeof RadioButtonContainer> & {
  state: boolean;
  onChange: (state: boolean) => void;
};

const RadioButton: FC<Props> = ({ state, onChange, style, children, ...rest }) => {
  return (
    <RadioButtonContainer style={style} {...rest}>
      <RadioInput
        type="radio"
        checked={state}
        onChange={(event) => {
          onChange(Boolean(event.target.value));
        }}
      />
      {children}
    </RadioButtonContainer>
  );
};

const RadioInput = styled.input`
  width: 19px;
  height: 19px;
  cursor: pointer;
  margin-right: 10px;
`;

const RadioButtonContainer = styled(Row)`
  align-items: center;
`;

export { RadioButton };
