import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { QuestionImage } from "types";
import { createAsyncSlice, getTokenFromState } from "./sliceHelpers";
import { fetchQuestionImages as fetchQuestionImagesApi } from "api";

const { slice, dispatchAsyncFunction } = createAsyncSlice<
  Array<QuestionImage>,
  {}
>({
  name: "questionImages",
});

export const {
  loading: questionImagesLoading,
  success: questionImagesSuccess,
  error: questionImagesError,
  reset: questionImagesReset,
} = slice.actions;

const fetchQuestionImages = (textQuery?: string) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const data = await fetchQuestionImagesApi(token, textQuery);
    dispatch(questionImagesSuccess(data));
  }, dispatch);

const questionImagesReducer = slice.reducer;
export { questionImagesReducer, fetchQuestionImages };
