import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchSessionCount as fetchSessionListCountApi } from "api";
import { SessionType } from "types";
import { toLower } from "lodash";

export interface FetchSessionListCountParams {
  sessionType: SessionType;
  classId?: string;
  weekBefore?: string;
}

export interface SessionListCount {
  sessionType: string;
  totalCount: number;
}

const dispatchFetchSessionListCount = (
  { classId, sessionType, weekBefore }: FetchSessionListCountParams,
  actions: SessionActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token, userInfo } = getTokenFromState(getState);
    const data = await fetchSessionListCountApi({ teacherId: userInfo!.id, classId, sessionType, weekBefore }, token);
    const returnValue: SessionListCount = { sessionType: toLower(SessionType[sessionType]), totalCount: data };

    dispatch(actions.updateTotal(returnValue));

    return data;
  }, dispatch);

export { dispatchFetchSessionListCount };
