import { useState } from "react";
import { Modal } from "react-bootstrap";
import { UserInfo } from "api";
import { QuestionSetActionHook } from "hooks";
import { Center, PrimaryButton } from "primitives";
import { FC } from "types";
import { QuestionSetDetails } from "./QuestionSetDetails";
import { useParams } from "react-router-dom";

interface Props {
  visible: boolean;
  userInfo?: UserInfo | null;
  actionHooks?: Array<QuestionSetActionHook>;
  onClose: () => void;
}

export const QuestionSetDetailsModal: FC<Props> = ({ visible, userInfo, actionHooks, onClose }) => {
  const { questionSetId } = useParams<{ questionSetId: string }>();
  const [isClosing, setIsClosing] = useState(false);

  const resetStateAndHide = (e?: MouseEvent) => {
    e?.preventDefault();
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    onClose();
  };

  return (
    <Modal
      className="fullscreen-md"
      show={visible && !isClosing}
      onHide={resetStateAndHide}
      onExited={onModalHidden}
      size="lg"
      scrollable={true}
    >
      <Modal.Body id="question-set-preview">
        <QuestionSetDetails
          questionSetId={questionSetId}
          userInfo={userInfo}
          parentElementId={"question-set-preview"}
          actionHooks={actionHooks}
        />
      </Modal.Body>
      <Modal.Footer>
        <Center>
          <PrimaryButton variant="secondary" onClick={() => resetStateAndHide()}>
            Close
          </PrimaryButton>
        </Center>
      </Modal.Footer>
    </Modal>
  );
};
