import { defaultRequestState, Session, Student } from "types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FC, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { dispatchAsync, getPreviewTotalQuestions, getSessionsAgregateInfo, getStudentAgregateInfo } from "utils";
import { fetchSessionListFull } from "slices";
import styled from "styled-components";
import { Row } from "primitives";
import { breadcrumbConstants, Names, ReviewConstants, slugs } from "textConstants";
import { MedIcon } from "primitives/icons";
import {
  GetCardStyleForResult,
  StudentCard,
  StudentGridKeyItem,
  GroupedStudentGrid,
  StudentCardProps,
  StudentGridKey,
  StudentGridKeyContainer,
  StudentRecordModal,
  StudentGridKeyColour,
} from "components";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { LessonPlanPreviewModal } from "pages/my-games";
import { colors } from "styles";

interface ReviewClassGridProps {
  sessionIds: string[];
  sortStudents?: (student1: Student, student2: Student) => number;
  onClickStudent?: (studentId: string) => void;
}

interface CardDataProps {
  sessionInfo: ReturnType<typeof getSessionsAgregateInfo>;
  sessionList: (Session | null)[];
  onStudentPressed?: (studentId: string) => void;
}

interface ReviewStudentCardProps extends StudentCardProps {
  data?: CardDataProps;
}

const ReviewStudentCard: FC<ReviewStudentCardProps> = ({ student, disabled, data }) => {
  if (!data) return <></>;
  const { sessionInfo, sessionList, onStudentPressed } = data;
  const studentInfo = getStudentAgregateInfo(student, sessionList);
  const result = studentInfo.participationCount
    ? sessionInfo.totalPossibleScore
      ? studentInfo.totalCorrect / studentInfo.totalAnswered
      : 0
    : undefined;

  let totalQuestions = studentInfo.totalAnswered;
  if (!Boolean(studentInfo.totalAnswered)) {
    totalQuestions = getPreviewTotalQuestions(sessionList);
  }

  const style = GetCardStyleForResult(result, totalQuestions, studentInfo.isAssigned);

  return (
    <StudentCard
      {...student}
      onStudentPressed={disabled ? undefined : onStudentPressed}
      cardOptions={style}
      topRowElement={{
        icon: breadcrumbConstants.myGames.icon,
        text: `${studentInfo.participationCount}/${sessionInfo.totalPossibleParticipation}`,
      }}
      bottomRowElement={{
        icon: breadcrumbConstants.questions.icon,
        text:
          `${studentInfo.totalCorrect}/${totalQuestions}` +
          (result && sessionInfo.totalPossibleScore ? ` (${Math.floor(result * 100)}%)` : ""),
      }}
    />
  );
};

const ReviewStudentGrid: FC<ReviewClassGridProps> = ({ sessionIds, sortStudents, onClickStudent }) => {
  const [requestState, setRequestState] = useState(defaultRequestState);
  const { path, url } = useRouteMatch();

  const sessionList = useAppSelector((state) =>
    sessionIds.map((sessionId) => {
      return (state.sessionList?.data && state.sessionList.data[sessionId]) || null;
    })
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  const updateSessionsFromIDs = useCallback(async () => {
    //slice out the ids we dont have yet
    const filteredIds = sessionIds.filter((id, index) => !sessionList[index] || sessionList[index]?.id !== id);
    if (filteredIds.length > 1 && !requestState.loading) {
      await dispatchAsync(dispatch(fetchSessionListFull(filteredIds)), setRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sessionIds]);

  useEffect(() => {
    updateSessionsFromIDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIds, updateSessionsFromIDs]);

  const returnToProgressView = () => {
    history.push(url);
  };

  const sessionInfo = getSessionsAgregateInfo(sessionList);

  const cardData: CardDataProps = {
    sessionInfo,
    sessionList,
    onStudentPressed: onClickStudent,
  };

  const header = sessionIds.length === 0 && (
    <HeaderArea className="px-2">{ReviewConstants.noLessonsSelected}</HeaderArea>
  );

  const footer = (
    <StudentGridKeyContainer>
      <StudentGridKey>
        <StudentGridKeyItem>
          <MedIcon color={colors.primary} icon={breadcrumbConstants.myGames.icon} />
          <p>{Names.CustomGames}</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <MedIcon color={colors.primary} icon={breadcrumbConstants.questions.icon} />
          <p>Questions</p>
        </StudentGridKeyItem>
      </StudentGridKey>
      <StudentGridKey className="p-3">
        <StudentGridKeyItem>
          <StudentGridKeyColour fill={ReviewConstants.GetColorForResult(1.0)} />
          <p>100% - 75%</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <StudentGridKeyColour fill={ReviewConstants.GetColorForResult(0.5)} />
          <p>74% - 50%</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <StudentGridKeyColour fill={ReviewConstants.GetColorForResult(0)} />
          <p>49% - 0%</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <StudentGridKeyColour fill={ReviewConstants.noAttemptColor} />
          <p>No results</p>
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <StudentGridKeyColour fill={ReviewConstants.notAssignedColor} />
          <p>Not assigned</p>
        </StudentGridKeyItem>
      </StudentGridKey>
    </StudentGridKeyContainer>
  );

  return (
    <>
      <GroupedStudentGrid
        sortStudents={sortStudents}
        StudentCard={ReviewStudentCard}
        header={header}
        footer={footer}
        isLoading={requestState.loading}
        hideGroupCount={true}
        setUngroupedToBottom={true}
        cardData={cardData}
      />
      <Switch>
        <Route path={`${path}/${slugs.student}/${slugs.studentId}`}>
          <StudentRecordModal
            StudentCard={ReviewStudentCard}
            selectedSessionIds={sessionIds}
            onModalHidden={returnToProgressView}
            cardData={cardData}
          />
        </Route>
        <Route path={`${path}/${slugs.preview}`}>
          <LessonPlanPreviewModal onHidden={returnToProgressView} />
        </Route>
      </Switch>
    </>
  );
};

const HeaderArea = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export { ReviewStudentGrid };
