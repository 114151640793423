import { FC } from "react";
import { ListTabProps, ListTabRow } from "components";
import { actionConstants, Names } from "textConstants";
import { RootState } from "app/rootReducer";
import { PrimaryButton } from "primitives";
import { MedIcon } from "primitives/icons";

interface Props {
  onCreateSession?: () => void;
  onCreateQuickGame?: () => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const lessonTabs: ListTabProps[] = [
  {
    key: "upcoming",
    text: "Upcoming",
  },
  {
    key: "unscheduled",
    text: "Unscheduled",
  },
  {
    key: "current",
    text: "In Progress",
  },
  {
    key: "past",
    text: "Completed",
  },
];

const SessionTabRow: FC<Props> = ({ onCreateQuickGame, onCreateSession, selectedTab, setSelectedTab }) => {
  return (
    <ListTabRow
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={lessonTabs}
      totalsSelector={(state: RootState) => state.sessionList.totals}
    >
      {onCreateQuickGame && (
        <PrimaryButton className="mb-2 play-game-now-button" variant="primary" onClick={onCreateQuickGame}>
          <MedIcon icon={actionConstants.play.icon} />
          Play Quick {Names.CustomGame}
        </PrimaryButton>
      )}
      {onCreateSession && (
        <PrimaryButton className="mb-2 ml-2" variant="primary" onClick={onCreateSession}>
          <MedIcon icon={actionConstants.add.icon} />
          Create {Names.Session}
        </PrimaryButton>
      )}
    </ListTabRow>
  );
};

export { SessionTabRow };
