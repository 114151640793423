import { MenuBook } from "@styled-icons/material";
import { ClassIcon, HomeIcon, PersonIcon, ProgressIcon, QuestionIcon, SessionIcon } from "icons";
import { colors } from "styles";
import { Names, PlatformTitle } from "./main";

const breadcrumbConstants = {
  dashboard: {
    title: "Home",
    icon: HomeIcon,
    breadcrumbDescription: `Your ${PlatformTitle} homepage`,
    startInstruction: `Welcome to ${PlatformTitle}!`,
    accentColor: colors.darkText,
  },

  questions: {
    title: "Questions",
    route: "questions",
    icon: QuestionIcon,
    breadcrumbDescription: "View existing question sets, and create your own",
    dashboardDescription:
      "Create your own question set.<br />View your favourite and previously created question sets.<br />Browse thousands of ready-made question sets and add them to a game of your choice.",
    accentColor: colors.tabHoverOrange,
    highlightColor: colors.tabSelectedOrange,
  },

  myGames: {
    title: `My ${Names.CustomGames}`,
    route: "my-games",
    icon: SessionIcon,
    breadcrumbDescription: `View, manage and play ${Names.CustomGames}`,
    dashboardDescription: `View, manage, and play ${Names.sessions} with your class.<br />Check current, upcoming, past and unscheduled ${Names.sessions}.<br />Create and play a one-off ${Names.customGame}.`,
    accentColor: colors.tabHoverPurple,
    highlightColor: colors.tabSelectedPurple,
  },

  gameLibrary: {
    title: "Game Library",
    route: "game-library",
    icon: MenuBook,
    dashboardDescription: `Choose from a series of pre made ${Names.customGames}, with a large range of different subjects. Have a quick ${Names.customGame}, or assign them straight to your class.`,
    accentColor: colors.brandPink,
    highlightColor: colors.tabHoverPink,
  },

  progress: {
    title: "Results",
    route: "results",
    icon: ProgressIcon,
    breadcrumbDescription: `View your students' ${Names.Sessions} results`,
    dashboardDescription:
      "Review class and individual progress.<br />Get an overview of your whole class progress.<br />View detailed information about individual student progress.",
    accentColor: colors.brandGreen,
    highlightColor: colors.tabSelectedGreen,
  },

  class: {
    title: "Class",
    route: "class",
    icon: ClassIcon,
    breadcrumbDescription: "Manage your class and view student results",
    dashboardDescription:
      "Set up and manage your class.<br />Partition students into groups.<br />View individual student results.<br />Print student login cards.",
    accentColor: colors.brandBlue,
    highlightColor: colors.tabSelectedBlue,
  },

  settings: {
    title: "Settings",
    route: "settings",
    icon: PersonIcon,
    breadcrumbDescription: "View, edit and update your account settings",
    accentColor: colors.secondary,
    highlightColor: colors.tabSelectedGrey,
  },

  academy: {
    title: `${PlatformTitle} Academy`,
    route: "academy",
    breadcrumbDescription: `Learn all about ${PlatformTitle} and how to use it`,
    dashboardDescription: `Learn all about how ${PlatformTitle} works and how best to use it`,
    icon: MenuBook,
    accentColor: colors.brandBlue,
    highlightColor: colors.tabSelectedPurple,
  },
};

export { breadcrumbConstants };
