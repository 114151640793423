import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchClassGroupings } from "api";
import { GroupingActions } from "./groupingsSlice";

export interface FetchClassGroupingsParams {
  classId: string;
}

const dispatchFetchClassGroupings = ({ classId }: FetchClassGroupingsParams, actions: GroupingActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const groupings = await fetchClassGroupings({ token, classId });
    dispatch(actions.addMany(groupings));
    return groupings;
  }, dispatch);

export { dispatchFetchClassGroupings };
