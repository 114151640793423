import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDefaultGrouping, setDefaultGrouping } from "slices";
import { useQueryParams } from "./useQueryParams";

const key = "lastUsedGroupingId";

export const useGroupingId = (): [string | undefined, (newId?: string) => void] => {
  const {
    params: { groupingId },
    setParams,
  } = useQueryParams<{ groupingId?: string }>();
  const groupings = useAppSelector((state: RootState) => state.groupings.data);

  const defaultGroupingId = useSelector(selectDefaultGrouping);
  const dispatch = useAppDispatch();

  const setDefaultGroupingId = useCallback(
    (groupingId: string | undefined): void => {
      dispatch(setDefaultGrouping(groupingId));
      if (typeof window !== "undefined") {
        try {
          if (groupingId) {
            window.localStorage.setItem(key, JSON.stringify(groupingId));
          } else {
            window.localStorage.removeItem(key);
          }
        } catch (error) {
          console.log(error);
        }
      }
      setParams(groupingId ? { groupingId: groupingId } : {});
    },
    [dispatch, setParams]
  );

  //checks and updates when a grouping id has been set
  useEffect(() => {
    const setGroupingId = (newId?: string) => {
      setParams(newId ? { groupingId: newId } : {});
    };

    //check if it's a real id, and delete it if not
    if (defaultGroupingId && groupings !== null && !groupings[defaultGroupingId]) {
      setDefaultGroupingId(undefined);
      return;
    }

    let defaultId = defaultGroupingId;
    //Check if local storage has something new
    if (typeof window !== "undefined" && !groupingId) {
      try {
        const item = window.localStorage.getItem(key);
        if (item) {
          defaultId = JSON.parse(item);
          setDefaultGroupingId(defaultId);
          return;
        }
      } catch (error) {
        defaultId = defaultGroupingId;
      }
    }

    //update urls if we need to
    if (groupingId !== defaultId) {
      setGroupingId(groupingId || defaultId);
      setDefaultGroupingId(groupingId || defaultId);
    }
  }, [defaultGroupingId, groupingId, groupings, setDefaultGroupingId, setParams]);

  return [groupingId, setDefaultGroupingId];
};
