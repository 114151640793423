import { useMediaQuery } from "react-responsive";
import { FC } from "react";

export const TabletOrSmaller: FC = ({ children }) => {
  const isTabletOrSmaller = useMediaQuery({ maxWidth: 1279, orientation: "portrait" });
  return isTabletOrSmaller ? <>{children}</> : null;
};

export const Default: FC = ({ children }) => {
  const isDefault = !useMediaQuery({ maxWidth: 1279, orientation: "portrait" });
  return isDefault ? <>{children}</> : null;
};
