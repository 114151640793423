import { Tour } from "types";
import { TourStepContainer } from "./tourComponents";

const help: Tour = {
  id: "help",
  name: "Help",
  steps: [
    {
      target: ".help-button",
      title: "Tutorial Skipped",
      content: (
        <TourStepContainer>
          <p>Other tutorials will no longer run automatically as you move across the site.</p>
          <p>If you need to find a tutorial again, you can find them in this menu</p>
        </TourStepContainer>
      ),
    },
  ],
};

export const HelpTour: Tour = help;
