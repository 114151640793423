import { OnboardingElementContainer } from "pages";
import { LoginBackgroundContainer, OnboardingLocation, Spacer, VerticalCenter } from "primitives";
import { useRouteMatch } from "react-router";
import { Switch, Route } from "react-router-dom";
import { colors } from "styles";
import { FC } from "types";
import { TeacherRegisterElement } from "./TeacherRegisterElement";
import { DescriptionText } from "components";
import { PrimaryButton } from "primitives/button";
import { Center } from "primitives";
import styled from "styled-components";
import { PlatformTitle } from "textConstants";

const allowRegistration = true;

interface Props {
  onRegisterComplete: () => void;
}

export const TeacherRegisterPage: FC<Props> = ({ onRegisterComplete }) => {
  const { url } = useRouteMatch();
  return (
    <LoginBackgroundContainer
      backgroundColour={colors.brandPurple}
      location={OnboardingLocation.register}
      includeLogo={false}
    >
      <Switch>
        <Route path={`${url}/`}>
          {allowRegistration ? (
            <VerticalCenter>
              <LightText>
                <b>Step 1 of 3</b>
              </LightText>
              <Spacer />
              <OnboardingElementContainer title={"Sign Up"} size={"M"}>
                <TeacherRegisterElement onRegisterComplete={onRegisterComplete} />
              </OnboardingElementContainer>
            </VerticalCenter>
          ) : (
            <OnboardingElementContainer title={"Coming soon..."} size={"M"}>
              <Center>
                <DescriptionText>
                  {PlatformTitle} is coming soon! Join our mailing list to receive the latest {PlatformTitle} updates
                  and receive your special early bird offer
                </DescriptionText>
              </Center>
              <Center className="mt-4">
                <PrimaryButton href="https://played.com.au">Sign me up!</PrimaryButton>
              </Center>
            </OnboardingElementContainer>
          )}
        </Route>
      </Switch>
    </LoginBackgroundContainer>
  );
};

const LightText = styled.p`
  color: white;
`;
