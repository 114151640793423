import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { getPaymentHistory as getPaymentHistoryApi } from "api/payment";
import { getTokenFromState } from "./sliceHelpers";
import { RootState } from "app/rootReducer";
import { PaymentItem } from "types";

type PaymentHistoryState = {
  history: PaymentItem[];
};

const initialState: PaymentHistoryState = {
  history: [],
};

const paymentHistorySlice = createSlice({
  name: "paymentHistory",
  initialState,
  reducers: {
    paymentHistoryLoading(state) {
      state.history = [];
    },
    paymentHistorySuccess(state, action: PayloadAction<PaymentItem[]>) {
      state.history = action.payload;
    },
  },
});

const getPaymentHistory = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(paymentHistoryLoading());

    const { token } = getTokenFromState(getState);
    const history = await getPaymentHistoryApi(token);
    dispatch(paymentHistorySuccess(history));
  };
};

export const { paymentHistoryLoading, paymentHistorySuccess } = paymentHistorySlice.actions;

const paymentHistoryReducer = paymentHistorySlice.reducer;
export { paymentHistoryReducer, getPaymentHistory };
