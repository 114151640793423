import { map } from "lodash";
import { useEffect } from "react";
import { Form } from "react-bootstrap";

interface Props<T extends { id: string }> {
  options: T[];
  value?: string;
  onChange?: (value: T | undefined) => void;
  getName?: (value: T) => string;
}

function SimpleSelect<T extends { id: string }>({ options, value, onChange, getName }: Props<T>) {
  const stringifiedOptions = JSON.stringify(options);

  //Check for options changing
  useEffect(() => {
    if (!onChange) return;
    if (options && value) {
      onChange(options.find((o) => o.id === value));
    } else {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedOptions]);

  //set up at the start, and adapt to changing values
  useEffect(() => {
    if (!onChange) return;
    if (!options) {
      onChange(undefined);
      return;
    }
    if (value === undefined && options.length > 0) {
      onChange(options[0]);
    }
  }, [value, options, onChange]);

  return (
    <Form.Control
      as="select"
      value={value}
      onChange={(evt) => onChange && onChange(options.find((o) => o.id === evt.target.value))}
    >
      {map(options, (value) => (
        <option key={value.id} value={value.id}>
          {getName ? getName(value) : value}
        </option>
      ))}
    </Form.Control>
  );
}

export { SimpleSelect };
