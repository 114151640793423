import { Session } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

export interface CreateSessionParams {
  name: string;
  publicAccess: boolean;
  classId?: string | null;
  startTime: Date | null;
  endTime: Date | null;
  maxAttempts: number | null;
}

const createSession = async (
  token: string,
  { name, publicAccess, classId, startTime, endTime, maxAttempts }: CreateSessionParams
): Promise<Session> => {
  const response = await api.post(
    `/sessions`,
    {
      name,
      publicAccess,
      classId,
      startTime,
      endTime,
      maxAttempts,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const session = response.data;

  if (session) {
    return session;
  } else {
    throw new NetworkError();
  }
};

export { createSession };
