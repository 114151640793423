import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types/Teacher";
import { isValidResponse } from "utils/isValidResponse";

const verify = async (token: string): Promise<Teacher> => {
  const response = await api.post(
    "/teachers/verify",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};

export { verify };
