import { useGameTemplates, useQuestionSets } from "hooks";
import { AsyncRequestState, CustomGameSettings, defaultRequestState, FC, Session, TTSOverridePair } from "types";
import styled from "styled-components";
import { InputGroup, Form } from "react-bootstrap";
import { CustomGameSettingsWithEnabledAndValidValues, UpdateGameSettingsParams } from "components";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { NumberSettingController } from "components";
import { useCallback, useMemo, useState } from "react";
import { mapValues, merge } from "lodash";
import { Column, PrimaryButton, Row, Spacer, TitleText, Spinner } from "primitives";
import { colors } from "styles";
import { useEffect } from "react";
import { helpMessages, Names } from "textConstants";
import { CustomGameTTSOverrideEditor } from "./CustomGameTTSOverideEditor";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { defaultPronunciationSets, dispatchAsync, getQuestionsCount } from "utils";
import { MultiplayerSettingsController } from "./Components/MultiplayerSettingsController";
import { getPronunciationSets } from "slices";
import {
  CurrentCustomGameData,
  MiniGameStepMaxWidthContainer,
  MiniGameStepOuterContainer,
  MiniGameStepSidebarContainer,
  MiniGameStepSidebarSection,
} from "./CustomGameEditor";
import { MiniGameInfo, MiniGameSessionInfo } from "./Components";
import { customGameSettingsUtils as settingsUtils } from "./CustomGameSettingsSelectorUtils";
import pluralize from "pluralize";
import QRCodeOn from "assets/QR Code On.png";
import QRCodeOff from "assets/QR Code Off.png";

interface Props {
  initialGameSettings: Partial<CustomGameSettings> | undefined;
  selectedQuestionSetIds: string[];
  selectedGameTemplate: string;
  isParentLoading: boolean;
  createCustomGameError: string | null;
  isNewCustomGame: boolean;
  onSettingsChanged: (settings: Partial<CustomGameSettings>, settingsValid: boolean) => void;
  onFinishedStage: () => void;
  onCreateAnotherPressed?: () => void;
  currentCustomGame: CurrentCustomGameData;
  session: Session | null;
  customGameId?: string;
}

const CustomGameSettingsSelector: FC<Props> = ({
  initialGameSettings,
  selectedQuestionSetIds,
  selectedGameTemplate,
  createCustomGameError,
  onSettingsChanged,
  currentCustomGame,
  session,
  customGameId,
}) => {
  const questionSets = useQuestionSets(selectedQuestionSetIds);
  useGameTemplates(true);
  const gameTemplate = useAppSelector(
    (state) =>
      state.gameTemplates.data &&
      state.gameTemplates.data.find((gameTemplate) => gameTemplate.id === selectedGameTemplate)!
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { url, path, params } = useRouteMatch<{ sessionId?: string }>();
  const [{ loading: pronunciationsLoading }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);
  const [canCopySettings, setCanCopySettings] = useState<boolean>(true);
  const hasSession = Boolean(params.sessionId);

  const { data: pronunciationData } = useAppSelector((state) => state.pronunciationSets);
  const pronunciationSets = useMemo(() => (pronunciationData ? Object.values(pronunciationData) : []), [
    pronunciationData,
  ]);

  const totalQuestions = questionSets.reduce((a, b) => a + (b ? b.numQuestions : 0), 0);

  const fetchTTSSettings = useCallback(() => {
    dispatchAsync(dispatch(getPronunciationSets()), setRequestState);
  }, [dispatch]);

  const copySettingsFromPrevGameSettings = (prevGameSettings: Partial<CustomGameSettings>) => {
    updateGameSettings(
      settingsUtils.CopyGameSettings(prevGameSettings, totalQuestions, gameSettings.copySettingsFromPrev.value)
    );
  };

  const updateGameSettings = (update: UpdateGameSettingsParams) => {
    const newGameSettings = merge({ ...gameSettings }, { ...update });

    //Doing this to specificallly fix these settings not coming across in the merge above
    //Not sure why this is the case and will very much need looking at when we are available
    if (update.multiplayerSettings?.value) {
      newGameSettings.multiplayerSettings.value = update.multiplayerSettings.value;
    }
    const validation = settingsUtils.ValidateCustomGameSettings(newGameSettings, totalQuestions);

    setGameSettings(merge({ ...newGameSettings }, { ...validation }));
  };

  useEffect(() => {
    updateGameSettings({});
    // eslint-disable-next-line
  }, [totalQuestions]);

  const multiplayerEnabled = () => {
    const maxAllowedQuestionsPerPlayer = settingsUtils.MaxAllowedQuestionsPerPlayer(
      totalQuestions,
      settingsUtils.GetMaxPlayers(true, gameSettings.multiplayerSettings?.value?.maxPlayers || 1)
    );

    if (gameSettings.questionCount.value > maxAllowedQuestionsPerPlayer) {
      updateGameSettings({
        questionCount: {
          value: maxAllowedQuestionsPerPlayer,
        },
      });
    }
  };

  const getPrevGameSettings = () => {
    if (session) {
      if (session?.customGames?.length) {
        if (customGameId) {
          const customGameIndex = session.customGames.findIndex((game) => game.id === customGameId);
          if (customGameIndex > 0) {
            return session.customGames[customGameIndex - 1].settings;
          }
        } else {
          return session.customGames[session.customGames.length - 1].settings;
        }
      }
    }
    return undefined;
  };

  const checkCustomGameSettingsFromPrevious = useCallback(async () => {
    let copySettings = false;
    let canCopySettings = false;
    const prevGameSettings = getPrevGameSettings();

    //editing
    if (customGameId) {
      if (prevGameSettings) {
        //find the customGame before ours
        const curGameSettings = session?.customGames?.find((game) => game.id === customGameId)?.settings;
        copySettings = curGameSettings?.copySettingsFromPrev || false;
        canCopySettings = true;
      }
    }
    //creating a new one
    else {
      //check we have a game to copy
      canCopySettings = session !== null && session.customGames !== null && session.customGames.length > 0;
      copySettings = true;
    }

    setCanCopySettings(canCopySettings);

    if (canCopySettings && copySettings && prevGameSettings) {
      copySettingsFromPrevGameSettings(prevGameSettings);
    } else {
      updateGameSettings({
        copySettingsFromPrev: { value: false },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, customGameId]);

  useEffect(() => {
    fetchTTSSettings();
  }, [fetchTTSSettings]);

  useEffect(() => {
    checkCustomGameSettingsFromPrevious();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const [gameSettings, setGameSettings] = useState<CustomGameSettingsWithEnabledAndValidValues>(
    settingsUtils.InitialCustomGameSettings(initialGameSettings, totalQuestions)
  );

  useEffect(() => {
    // Forces the settings to validate on first load
    updateGameSettings(gameSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSets.length]);

  useEffect(() => {
    // @ts-ignore
    const customGameSettings: Partial<CustomGameSettings> = mapValues(gameSettings, ({ enabled, value }) =>
      enabled ? value : null
    );
    let isValid = true;
    for (const value of Object.values(gameSettings)) {
      if (Boolean(value.error)) {
        isValid = false;
      }
    }

    onSettingsChanged(customGameSettings, isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSettings]);

  useEffect(() => {
    if (pronunciationData && !gameSettings.ttsOverrides.value) {
      let pairs: TTSOverridePair[] = defaultPronunciationSets.map((set) => set.ttsOverridePairs).flat();
      pronunciationSets.forEach((set) => {
        if (set.loadByDefault) {
          pairs = [...pairs, ...set.ttsOverridePairs];
        }
      });

      updateGameSettings({
        ttsOverrides: {
          value: JSON.stringify(pairs),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSettings.ttsMode, pronunciationData]);

  const onPronunciationsPressed = () => {
    history.push({ pathname: url + "/pronunciation", search });
  };

  const getMaxPlayers = () =>
    settingsUtils.GetMaxPlayers(
      gameSettings.multiplayerSettings && gameSettings.multiplayerSettings.enabled,
      gameSettings.multiplayerSettings?.value?.maxPlayers || 1
    );

  const getQuestionsTotal = () => {
    const playerCount = getMaxPlayers();
    return gameSettings.questionCount.enabled
      ? gameSettings.questionCount.value * playerCount
      : settingsUtils.MaxAllowedQuestionsPerPlayer(totalQuestions, playerCount) * playerCount;
  };

  const getOrderWarning = () => {
    return gameSettings.questionOrder.value === "ordered"
      ? `There are more available questions (${getQuestionsCount(
          questionSets
        )}) than questions asked (${getQuestionsTotal()}). Only the first ${getQuestionsTotal()} will be asked.`
      : `There are more available questions (${getQuestionsCount(
          questionSets
        )}) than questions asked (${getQuestionsTotal()}). A random set of ${getQuestionsTotal()} will be asked.`;
  };

  const onCopySettingsPressed = () => {
    if (gameSettings.copySettingsFromPrev.value) {
      updateGameSettings({
        copySettingsFromPrev: { value: false },
      });
    } else {
      const prevGameSettings = getPrevGameSettings();
      if (prevGameSettings) {
        updateGameSettings(settingsUtils.CopyGameSettings(prevGameSettings, totalQuestions, true));
      }
    }
  };

  const copySettings = canCopySettings && gameSettings.copySettingsFromPrev.value;

  if (!gameTemplate) {
    return <p>There was an error loading this page.</p>;
  } else {
    return (
      <MiniGameStepOuterContainer className="custom-game-settings">
        {hasSession && (
          <MiniGameStepSidebarContainer>
            <MiniGameStepSidebarSection>
              <MiniGameSessionInfo />
            </MiniGameStepSidebarSection>
            <MiniGameStepSidebarSection>
              <MiniGameInfo currentCustomGame={currentCustomGame} />
            </MiniGameStepSidebarSection>
          </MiniGameStepSidebarContainer>
        )}
        <MiniGameStepMaxWidthContainer>
          <h5 className="mb-5">Choose the settings for this {Names.customGame}</h5>
          <SettingsColumn>
            {canCopySettings && (
              <SettingsRow>
                <InputGroup>
                  <input
                    type="checkbox"
                    checked={gameSettings.copySettingsFromPrev.value}
                    onChange={() => onCopySettingsPressed()}
                    style={{
                      borderRadius: "0px",
                      width: "20px",
                      height: "23px",
                    }}
                  />
                  <CheckboxMessage
                    onClick={() => {
                      updateGameSettings({
                        copySettingsFromPrev: { value: !gameSettings.copySettingsFromPrev.value },
                      });
                    }}
                  >
                    Copy settings from the previous game
                  </CheckboxMessage>
                </InputGroup>
              </SettingsRow>
            )}

            <TitleText>Time</TitleText>
            <SettingsRow>
              <HalfWidthColumn>
                <NumberSettingController
                  gameSettings={gameSettings}
                  updateGameSettings={updateGameSettings}
                  settingName="gameTimeLimit"
                  {...helpMessages.customGame.settings.gameTimeLimit}
                  readonly={copySettings}
                />
              </HalfWidthColumn>
              <HalfWidthColumn>
                <NumberSettingController
                  gameSettings={gameSettings}
                  updateGameSettings={updateGameSettings}
                  settingName="questionTimeLimit"
                  {...helpMessages.customGame.settings.questionTimeLimit}
                  readonly={copySettings}
                />
              </HalfWidthColumn>
            </SettingsRow>
            <TitleText>Players</TitleText>
            <SettingsRow>
              <MultiplayerSettingsController
                gameSettings={gameSettings}
                updateGameSettings={updateGameSettings}
                multiplayerEnabled={multiplayerEnabled}
                readonly={copySettings}
              />
            </SettingsRow>
            <TitleText>Questions</TitleText>
            <p>
              There are {totalQuestions} {pluralize("question", totalQuestions)} available in {questionSets.length}{" "}
              {pluralize(Names.questionset, questionSets.length)}
            </p>
            <SettingsRow>
              <HalfWidthColumn>
                <Row>
                  <Column>
                    <Form.Label>{helpMessages.customGame.settings.questionDistributionType.title}</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        updateGameSettings({
                          questionDistributionType: {
                            enabled: true,
                            // @ts-ignore
                            value: e.target.value,
                          },
                        });
                      }}
                      value={gameSettings.questionDistributionType.value}
                      style={{
                        borderRadius: "0px",
                        border: "1px solid black",
                        minWidth: "225px",
                      }}
                      disabled={copySettings}
                    >
                      <option value="divideBetweenPlayers">Divide all questions between players</option>
                      <option value="specifyPerPlayer">Specific number of questions per player</option>
                    </Form.Control>
                  </Column>
                </Row>
                {gameSettings.questionDistributionType.value === "specifyPerPlayer" && (
                  <>
                    <br />
                    <Column>
                      <NumberSettingController
                        gameSettings={gameSettings}
                        updateGameSettings={updateGameSettings}
                        settingName="questionCount"
                        required={true}
                        title={helpMessages.customGame.settings.questionsPerPlayer.title}
                        maxValue={totalQuestions}
                        readonly={copySettings}
                      />
                      <SupplementalText>
                        {`This ${Names.customGame} will ask a total of ${getQuestionsTotal()} ${pluralize(
                          "question",
                          getQuestionsTotal()
                        )} across ${getMaxPlayers()} ${pluralize("player", getMaxPlayers())}.`}
                      </SupplementalText>
                    </Column>
                  </>
                )}
              </HalfWidthColumn>
              <HalfWidthColumn>
                <Row>
                  <Column>
                    <Form.Label>{helpMessages.customGame.settings.questionOrder.title}</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        updateGameSettings({
                          questionOrder: {
                            enabled: true,
                            // @ts-ignore
                            value: e.target.value,
                          },
                        });
                      }}
                      value={gameSettings.questionOrder.value}
                      style={{
                        borderRadius: "0px",
                        border: "1px solid black",
                        minWidth: "225px",
                      }}
                      disabled={copySettings}
                    >
                      <option value="ordered">Ordered</option>
                      <option value="random">Randomised</option>
                    </Form.Control>
                  </Column>
                </Row>
                {gameSettings.questionDistributionType.value === "specifyPerPlayer" && (
                  <SupplementalText>
                    {getQuestionsTotal() < getQuestionsCount(questionSets) ? getOrderWarning() : ""}
                  </SupplementalText>
                )}
              </HalfWidthColumn>
            </SettingsRow>
            <TitleText>QR Code</TitleText>
            <SettingsRow>
              <HalfWidthColumn>
                <Form.Label>{helpMessages.customGame.settings.showQR.title}</Form.Label>
                <InputGroup>
                  <input
                    type="checkbox"
                    checked={gameSettings.showQR.value}
                    onChange={() =>
                      updateGameSettings({
                        showQR: { value: !gameSettings.showQR.value },
                      })
                    }
                    style={{
                      borderRadius: "0px",
                      width: "20px",
                      height: "23px",
                    }}
                    disabled={copySettings}
                  />
                  <CheckboxMessage
                    onClick={() => {
                      if (!copySettings) {
                        updateGameSettings({
                          showQR: { value: !gameSettings.showQR.value },
                        });
                      }
                    }}
                  >
                    {helpMessages.customGame.settings.showQR.controlLabel}{" "}
                    <b>{gameSettings.showQR.value ? "ON" : "OFF"}</b>
                  </CheckboxMessage>
                </InputGroup>
              </HalfWidthColumn>
              <HalfWidthColumn>
                <img width={200} height={150} src={gameSettings.showQR.value ? QRCodeOn : QRCodeOff} alt="" />
              </HalfWidthColumn>
            </SettingsRow>
            <TitleText>Sounds</TitleText>
            <SettingsRow>
              <HalfWidthColumn>
                <Form.Label>{helpMessages.customGame.settings.soundEffects}</Form.Label>
                <InputGroup>
                  <input
                    type="checkbox"
                    checked={gameSettings.soundEnabled.value}
                    onChange={() =>
                      updateGameSettings({
                        soundEnabled: { value: !gameSettings.soundEnabled.value },
                      })
                    }
                    style={{
                      borderRadius: "0px",
                      width: "20px",
                      height: "23px",
                    }}
                    disabled={copySettings}
                  />
                  <CheckboxMessage
                    onClick={() => {
                      if (!copySettings) {
                        updateGameSettings({
                          soundEnabled: { value: !gameSettings.soundEnabled.value },
                        });
                      }
                    }}
                  >
                    Sound effects are <b>{gameSettings.soundEnabled.value ? "ON" : "OFF"}</b>
                  </CheckboxMessage>
                </InputGroup>
              </HalfWidthColumn>
            </SettingsRow>
            <TitleText>Text-To-Speech</TitleText>
            {pronunciationsLoading ? (
              <Spinner />
            ) : (
              <SettingsRow>
                <HalfWidthColumn>
                  <InputGroup>
                    <input
                      type="checkbox"
                      checked={gameSettings.ttsMode.enabled}
                      onChange={() =>
                        updateGameSettings({
                          ttsMode: {
                            enabled: !gameSettings.ttsMode.enabled,
                            value: !gameSettings.ttsMode.enabled ? "button_prompted" : "none",
                          },
                        })
                      }
                      style={{
                        borderRadius: "0px",
                        width: "20px",
                        height: "23px",
                      }}
                      disabled={copySettings}
                    />
                    <CheckboxMessage
                      onClick={() => {
                        if (!copySettings) {
                          updateGameSettings({
                            ttsMode: {
                              enabled: !gameSettings.ttsMode.enabled,
                              value: !gameSettings.ttsMode.enabled ? "button_prompted" : "none",
                            },
                          });
                        }
                      }}
                    >
                      {helpMessages.customGame.settings.ttsMode.titleEnable}
                    </CheckboxMessage>
                  </InputGroup>
                  <Spacer />
                  {gameSettings.ttsMode.enabled && (
                    <PrimaryButton style={{ width: "250px" }} onClick={onPronunciationsPressed} disabled={copySettings}>
                      Edit Pronunciations
                    </PrimaryButton>
                  )}
                </HalfWidthColumn>
              </SettingsRow>
            )}
            <Spacer />
          </SettingsColumn>
        </MiniGameStepMaxWidthContainer>

        <Switch>
          <Route path={`${path}/pronunciation`}>
            <CustomGameTTSOverrideEditor
              ttsOverrideJson={gameSettings?.ttsOverrides?.value}
              selectedQuestionSets={questionSets}
              pronunciationSets={pronunciationSets}
              hide={() => {
                history.push({ pathname: url, search });
              }}
              commit={(ttsOverrideJson) => {
                updateGameSettings({
                  ttsOverrides: { value: ttsOverrideJson },
                });
              }}
            />
          </Route>
        </Switch>
      </MiniGameStepOuterContainer>
    );
  }
};

const SupplementalText = styled.p`
  margin-top: var(--sp-2);
  text-align: left;
  max-width: 300px;
}`;

const CheckboxMessage = styled.div`
  margin-left: 11px;
  max-width: 250px;
  line-height: 1.6;
`;

const SettingsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.1em solid ${colors.primaryDivider};
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
`;

const SettingsColumn = styled(Column)`
  overflow-y: auto;
  flex-grow: 1;
  p {
    margin-bottom: 1em;
    overflow: visible;
  }
  ${SettingsRow}:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const HalfWidthColumn = styled(Column)`
  width: 50%;
`;

export { CustomGameSettingsSelector };
