import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ModalButtonFooter, useCharacterLimitedNonEmptyInput } from "components";
import { Spacer } from "primitives";
import { createGrouping, updateGrouping } from "slices";
import { defaultRequestState, FC, Grouping, Partition } from "types";
import { clamp, dispatchAsync } from "utils";
import { colors } from "styles";

interface Props {
  hide: () => void;
  onComplete: (newGroupingId: string) => void;
  classId?: string;
}

const minGroups = 1;
const maxGroups = 10;

const createNewPartition = (i: number): Partition => ({
  id: i.toString(),
  name: `Group ${i + 1}`,
  order: i,
  students: [],
  isDeleted: false,
  isUpdated: false,
  isAdded: true,
});

export const EditGroupingModal: FC<Props> = ({ hide, onComplete, classId }) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const [isClosing, setIsClosing] = useState(false);

  const { groupingId } = useParams<{ groupingId: string }>();
  const isEditing = Boolean(groupingId);

  const dispatch = useAppDispatch();
  const grouping = useAppSelector((state) => state.groupings.data && state.groupings.data[groupingId]);

  const [partitionCount, setPartitionCount] = useState<number>(grouping?.partitions?.length || 1);

  const [NameInput, nameInputState, setName] = useCharacterLimitedNonEmptyInput({
    label: "Name of Partition",
    warningMessage: "You must give your partition a name",
    initialTextValue: grouping?.name,
  });

  const isFormValid = nameInputState.valid && Boolean(partitionCount);

  const beginHide = () => {
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    hide();
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isFormValid) {
      const clampedPartitionCount = clamp(partitionCount, minGroups, maxGroups);
      if (isEditing && grouping) {
        let newPartitions: Partition[] | undefined = [];
        // Adjust number of partitions based on new partitionCount
        if (grouping.partitions) {
          newPartitions = [...grouping.partitions];
          if (newPartitions.length < partitionCount) {
            for (let i = newPartitions.length; i < clampedPartitionCount; i++) {
              newPartitions.push(createNewPartition(i));
            }
          } else if (newPartitions.length > clampedPartitionCount) {
            newPartitions = newPartitions.slice(0, clampedPartitionCount);
          }
        }

        const { result, success } = await dispatchAsync(
          dispatch(updateGrouping(grouping.id, { ...grouping, name: nameInputState.text, partitions: newPartitions })),
          setRequestState
        );
        handleResult(success, result);
      } else if (classId) {
        const partitions: Partition[] = [];
        for (let i = 0; i < clampedPartitionCount; i++) {
          partitions.push(createNewPartition(i));
        }

        const { result, success } = await dispatchAsync(
          dispatch(createGrouping(classId, nameInputState.text, partitions)),
          setRequestState
        );
        handleResult(success, result);
      }
    }
  };

  const handleResult = (success: boolean, result?: Grouping | null) => {
    if (success) {
      if (result) {
        onComplete(result.id);
      }
    } else {
      alert("Sorry, there was an error creating this partition.");
    }
  };

  return (
    <Modal size="lg" show={true && !isClosing} onHide={beginHide} onExited={onModalHidden} centered={true}>
      <Modal.Header>
        <Modal.Title>
          {isEditing ? "Edit" : "Create"} Partition{" "}
          <span style={{ color: colors.primary }}>{isEditing && grouping?.name}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="create-class-modal" onSubmit={handleSubmit}>
          <NameInput {...nameInputState} {...setName} />
          <Form.Group>
            <Form.Label>Number of groups *</Form.Label>
            <Form.Control
              placeholder={"Number of groups"}
              value={partitionCount}
              onChange={(e) => {
                e.preventDefault();
                setPartitionCount(Number(e.target.value));
              }}
              isInvalid={partitionCount > maxGroups}
              type={"number"}
              min={1}
              max={maxGroups}
            />
          </Form.Group>
          <Spacer size={180} />
          <ModalButtonFooter
            isEditing={isEditing}
            cancelLoading={loading}
            onCancel={beginHide}
            confirmLoading={loading}
            confirmDisabled={!isFormValid}
            confirmType={"submit"}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};
