import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { getAPIVersion as apiGetAPIVersion } from "api";
import { ApiError } from "types/ApiError";

type VersionState = {
  loading: boolean;
  error: ApiError | null;
  version: string;
};

const initialState: VersionState = {
  loading: false,
  error: null,
  version: ""
};

const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    versionLoading(state) {
      state.loading = true;
      state.error = null;
      state.version = "";
    },
    versionSuccess(
      state,
      action: PayloadAction<{ version: string }>
    ) {
      state.loading = false;
      state.error = null;
      state.version = action.payload.version
    },
    versionNetworkError(state) {
      state.loading = false;
      state.error = ApiError.networkError;
      state.version = "";
    }
  },
});

const getAPIVersion = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(versionLoading());

    try {
      const { version } = await apiGetAPIVersion();
      dispatch(versionSuccess({ version }));
    } catch (error) {
      return dispatch(versionNetworkError());
    }
  };
};

export const {
  versionLoading,
  versionSuccess,
  versionNetworkError,
} = versionSlice.actions;

const versionReducer = versionSlice.reducer;
export { versionReducer, getAPIVersion };
