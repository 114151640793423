import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { getGrouping } from "api";
import { GroupingActions } from "./groupingsSlice";

export interface GetGroupingParams {
  groupingId: string;
}

const dispatchFetchGrouping = ({ groupingId }: GetGroupingParams, actions: GroupingActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const grouping = await getGrouping({ token, groupingId });
    dispatch(actions.addSingle({ groupingId: grouping.id, grouping }));
    return grouping;
  }, dispatch);

export { dispatchFetchGrouping };
