import { Button } from "./Button";
import { FC } from "types";
import styled from "styled-components";
import { ArrowBack } from "@styled-icons/material";
import { PrimitiveButtonProps } from "./PrimitiveButtonProps";

const BackButton: FC<PrimitiveButtonProps> = ({ children, ...rest }) => (
  <OuterContainer variant="outline-primary" {...rest}>
    <ArrowBack style={{ width: 24, height: 24, marginRight: 3 }} />
    {children ? children : "Back"}
  </OuterContainer>
);

const OuterContainer = styled(Button)`
  margin: 10px;
  height: 40px;
  min-width: 0;
  display: flex;
  align-items: center;
  align-items: center;
  align-self: flex-start;
`;

export { BackButton };
