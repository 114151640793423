import { StyledIcon } from "@styled-icons/styled-icon";
import { SmallIcon } from "primitives/icons";
import { InputGroup } from "react-bootstrap";
import { FC } from "types";

interface Props {
  icon: StyledIcon;
  iconColor?: string;
  backgroundColor?: string;
}

export const InputPrependIcon: FC<Props> = ({ onClick, icon, iconColor, backgroundColor }) => {
  return (
    <InputGroup.Prepend onClick={onClick}>
      <InputGroup.Text style={{ backgroundColor: backgroundColor }}>
        <SmallIcon icon={icon} color={iconColor} />
      </InputGroup.Text>
    </InputGroup.Prepend>
  );
};

export const InputAppendIcon: FC<Props> = ({ onClick, icon, iconColor, backgroundColor }) => {
  return (
    <InputGroup.Append style={{ cursor: "pointer" }} onClick={onClick}>
      <InputGroup.Text style={{ backgroundColor: backgroundColor }}>
        <SmallIcon icon={icon} color={iconColor} />
      </InputGroup.Text>
    </InputGroup.Append>
  );
};
