import { Add, Cached, Print, AddCircle } from "@styled-icons/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ClassSelector, PartitionSelector } from "components";
import { useClassId, useConfirmModal, useGroupingId } from "hooks";
import { ActionButton, Column, Spacer, VerticalLine } from "primitives";
import { DeleteButton } from "primitives/button";
import { SmallIconButton } from "primitives/button/IconButton";
import { Form, Row } from "react-bootstrap";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { deleteClass, resetAllStudentPasswords } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { actionConstants, slugs } from "textConstants";
import { UserAction } from "types";
import { FC } from "types/FunctionalComponentWithStandardProps";
import { StudentOrdering } from "./studentOrderings";

interface Props {
  studentOrdering?: StudentOrdering;
  setStudentOrdering: (newOrdering: StudentOrdering) => void;
  onClassUpdated?: (classId: string | undefined) => void;
  onStudentPasswordsRefreshed?: () => void;
  handleCreateClass?: () => void;
  handleEditClass?: () => void;
  handleCreateGrouping?: () => void;
  handleEditGrouping?: () => void;
  handleDeleteGrouping?: () => void;
}

const IconButtonDivider = "0.1em 0.75em";

const ClassSettings: FC<Props> = ({
  studentOrdering,
  setStudentOrdering,
  onClassUpdated,
  onStudentPasswordsRefreshed,
  handleCreateClass,
  handleEditClass,
  handleCreateGrouping,
  handleEditGrouping,
  handleDeleteGrouping,
}) => {
  const match = useRouteMatch<{ classId?: string; studentId?: string }>();
  const [classId, setDefaultClass] = useClassId(true);
  const class_ = useAppSelector((state) => (classId && state.classList.data ? state.classList.data[classId] : null));
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();
  const [selectedGroupingId] = useGroupingId();

  const handleUpdateClass = (classId: string | undefined) => {
    if (onClassUpdated) {
      onClassUpdated(classId);
    }
  };

  const handleAddStudents = () => {
    if (match.params.classId) {
      const newUrl = generatePath(`/${slugs.teacher}/${slugs.class}/${slugs.classId}/${slugs.students}/${slugs.add}`, {
        classId: match.params.classId,
      });
      history.push(newUrl);
    }
  };

  const handleDeleteClass = async () => {
    if (class_) {
      setModalInfo({
        title: (
          <>
            Delete <span style={{ color: colors.primary }}>{class_.name}</span>?
          </>
        ),
        body: [
          `Are you sure you want to delete your class named "${class_.name}"?`,
          `This will delete the class forever, and students and results will no longer be able to be viewed.`,
        ],
        confirmButtonVariant: "danger",
        onModalConfirm: async () => {
          await dispatch(deleteClass(class_.id));
          setDefaultClass(undefined);
          history.push(generatePath(match.path, { classId: undefined }));
        },
        confirmTitle: "Delete",
      });
      setModalVisible(true);
    }
  };

  const handleResetPasswords = async () => {
    if (class_) {
      setModalInfo({
        title: (
          <>
            Reset all passwords in <span style={{ color: colors.primary }}>{class_.name}</span>?
          </>
        ),
        body: [
          `This will reset the passwords for all students in class "${class_.name}".`,
          `Are you sure you want to do this?`,
        ],
        onModalConfirm: async () => {
          await dispatch(resetAllStudentPasswords(class_.id));
          if (onStudentPasswordsRefreshed) {
            onStudentPasswordsRefreshed();
          }
        },
      });
      setModalVisible(true);
    }
  };

  const addStudentsAction: UserAction = {
    id: "addStudents",
    name: "Add Student(s)",
    action: async () => handleAddStudents(),
    isEnabled: () => class_ != null,
    icon: Add,
    iconColor: "white",
  };

  const refreshPasswordsAction: UserAction = {
    id: "refresh",
    name: "Refresh All Passwords",
    action: handleResetPasswords,
    isEnabled: () => class_ != null && class_.students?.length > 0,
    icon: Cached,
    iconColor: "white",
  };

  const printCardsAction: UserAction = {
    id: "print",
    name: "Print Login Cards",
    action: async () => history.push(generatePath(`/teacher/class/:classId/print`, { classId: match.params.classId! })),
    isEnabled: () => class_ != null && class_.students?.length > 0,
    icon: Print,
    iconColor: "white",
  };

  return (
    <>
      <StyledColumn className="class-settings">
        <Form>
          <Form.Group>
            <ClassSelector onClassChanged={handleUpdateClass} expand />
            <Spacer size={10} />
            <StyledRow>
              <SmallIconButton icon={actionConstants.edit.icon} onClick={handleEditClass} tooltip={"Edit Class"} />
              <VerticalLine margin={IconButtonDivider} />
              <DeleteButton tooltip={"Delete Class"} onClick={handleDeleteClass} />
            </StyledRow>
          </Form.Group>

          <Form.Group>
            <Form.Label>Choose Partition</Form.Label>
            <PartitionSelector />
            <Spacer size={10} />
            <StyledRow>
              <SmallIconButton icon={AddCircle} onClick={handleCreateGrouping} tooltip={"Create Partition"} />
              <VerticalLine margin={IconButtonDivider} />
              <SmallIconButton
                icon={actionConstants.edit.icon}
                onClick={handleEditGrouping}
                tooltip={"Edit Partition"}
                disabled={!Boolean(selectedGroupingId)}
              />
              <VerticalLine margin={IconButtonDivider} />
              <DeleteButton
                tooltip={"Delete Partition"}
                onClick={handleDeleteGrouping}
                disabled={!Boolean(selectedGroupingId)}
              />
            </StyledRow>
          </Form.Group>

          <Form.Group>
            <Form.Label>Students</Form.Label>
            <StyledRow>
              <ActionButton variant="secondary" action={addStudentsAction} style={{ width: "100%" }}>
                {addStudentsAction.name}
              </ActionButton>
            </StyledRow>
          </Form.Group>
          <Form.Group>
            <StyledRow>
              <ActionButton variant="secondary" action={refreshPasswordsAction} style={{ width: "100%" }}>
                {refreshPasswordsAction.name}
              </ActionButton>
            </StyledRow>
          </Form.Group>
          <Form.Group>
            <StyledRow>
              <ActionButton
                className="print-button"
                variant="secondary"
                action={printCardsAction}
                style={{ width: "100%" }}
              >
                {printCardsAction.name}
              </ActionButton>
            </StyledRow>
          </Form.Group>
        </Form>
      </StyledColumn>
      <Modal />
    </>
  );
};

const StyledColumn = styled(Column)`
  min-width: var(--sidebar-width-sm);
  max-width: var(--sidebar-width-sm);
  padding: 20px;
  border-right: 1px solid ${colors.primaryDivider};
`;

const StyledRow = styled(Row)`
  align-items: center;
  margin: 0.2em;
`;

export { ClassSettings };
