import pluralize from "pluralize";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { CustomGame, QuestionSet, Result, Question } from "types";
import { MiniGameQuestionSetPreview } from "./MiniGameQuestionSetPreview";
import classNames from "classnames";
import { GameIcons } from "icons/Games";
import { SmallIcon } from "primitives/icons";
import {
  BestResult,
  getCorrectCount,
  getCorrectPercentage,
  getTotalCustomGameQuestionCount,
  getTotalQuestions,
} from "utils";
import { ResultIndicator } from "pages/progress/reviewClass/ReviewStudentInfoItem";
import { GetCardStyleForResult } from "components";
import { Row } from "primitives";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";

export const MissingQuestionDataId = "deleted";

interface Props {
  miniGame: CustomGame;
  QuestionSetComponent?: FC<{ questionSet: QuestionSet; bestResult?: BestResult }>;
  bestResult?: BestResult;
  hideHeader?: boolean;
  className?: string;
  extraInfo?: string[];
  onClickQuestionSet?: (questionSet: QuestionSet) => void;
  hideTimeInfo?: boolean;
}

const ResultsSummaryComponent: FC<{ result: Result }> = ({ result }) => {
  const totalAnswers = getTotalQuestions(result);
  const correctAnswers = getCorrectCount(result);
  const percentageCorrect = Math.round(getCorrectPercentage(correctAnswers, totalAnswers) * 100);

  return (
    <ResultSummaryRow>
      <ResultIndicator
        style={{ margin: "0px var(--sp-2)" }}
        colour={
          GetCardStyleForResult(getCorrectPercentage(correctAnswers, totalAnswers), totalAnswers, true)
            .inactivePrimaryColor
        }
      />
      {correctAnswers}/{totalAnswers} ({percentageCorrect}%)
    </ResultSummaryRow>
  );
};

export const MiniGameCard: FC<Props> = ({
  className,
  extraInfo,
  miniGame,
  QuestionSetComponent,
  hideHeader,
  bestResult,
  onClickQuestionSet,
  hideTimeInfo,
}) => {
  const QuestionSetDisplay = QuestionSetComponent || MiniGameQuestionSetPreview;
  const questionsComponent = bestResult ? (
    <>
      Questions: <ResultsSummaryComponent result={bestResult.bestResult} />
    </>
  ) : (
    <>Questions: {getTotalCustomGameQuestionCount(miniGame)}</>
  );

  const customGameQuestionSets = useAppSelector((state: RootState) =>
    miniGame.questionSets.map(
      (questionSet) => (state.questionSets.data && state.questionSets.data[questionSet.id]) || null
    )
  );
  const [deletedQuestionSet, setDeletedQuestionSet] = useState<QuestionSet | undefined>();

  useEffect(() => {
    let loadingDone =
      customGameQuestionSets.filter((questionSet) => Boolean(questionSet)).length === miniGame.questionSets.length;
    for (let questionSet of customGameQuestionSets) {
      if (questionSet && !Boolean(questionSet.questions)) {
        loadingDone = false;
      }
    }

    const allQuestionIds = customGameQuestionSets
      .map((questionSet) => questionSet?.questions || [])
      .flat()
      .map((questionSet) => questionSet.id);

    const deletedQuestions =
      bestResult?.bestResult?.answers
        .filter((answer) => !allQuestionIds.includes(answer.question.id || ""))
        .map((answer) => answer.question) || [];

    const newQuestionSet: QuestionSet = {
      questions: deletedQuestions.map((question) => question as Question),
      id: MissingQuestionDataId,
      createdAt: new Date(),
      modifiedAt: new Date(),
      title: "Deleted",
      estimatedLength: 0,
      description: "",
      numQuestions: deletedQuestions.length,
      isFavourite: false,
      tags: [],
    };

    if (loadingDone && !deletedQuestionSet && Boolean(deletedQuestions.length)) {
      setDeletedQuestionSet(newQuestionSet);
    }
  }, [customGameQuestionSets, bestResult?.bestResult?.answers, deletedQuestionSet, miniGame.questionSets.length]);

  const questionSets = deletedQuestionSet ? [...miniGame.questionSets, deletedQuestionSet] : miniGame.questionSets;

  return (
    <CardContainer className={classNames(className)}>
      <CardTitleRow>
        {!hideHeader && (
          <CardTitleHeader>
            {GameIcons[miniGame.gameTemplate.name] && (
              <SmallIcon icon={GameIcons[miniGame.gameTemplate.name]} className="mr-2 mb-1" />
            )}
            {miniGame.gameTemplate.name}
          </CardTitleHeader>
        )}

        <CardTitleItem>
          <ValidatableStat number={miniGame.questionSets.length} errorValue={0}>
            Question Sets: {miniGame.questionSets.length}
          </ValidatableStat>
        </CardTitleItem>

        <CardTitleItem>{questionsComponent}</CardTitleItem>
        {extraInfo && extraInfo.map((info, index) => <CardTitleItem key={index}>{info}</CardTitleItem>)}
        {(miniGame.settings?.gameTimeLimit || 0) > 0 && !hideTimeInfo && (
          <CardTitleItem>
            Time: {miniGame.settings?.gameTimeLimit} {pluralize("min", miniGame.settings?.gameTimeLimit)}
          </CardTitleItem>
        )}
        {miniGame.settings?.questionTimeLimit && !hideTimeInfo && (
          <CardTitleItem>Time: {miniGame.settings?.questionTimeLimit} sec</CardTitleItem>
        )}
      </CardTitleRow>
      {Boolean(questionSets?.length) ? (
        questionSets.map((questionSet) => (
          <QuestionSetDisplay
            questionSet={questionSet}
            key={questionSet.id}
            bestResult={bestResult}
            onClick={() => onClickQuestionSet && onClickQuestionSet(questionSet)}
          />
        ))
      ) : (
        <p>No Question Sets</p>
      )}
    </CardContainer>
  );
};

const ResultSummaryRow = styled(Row)`
  align-items: center;
`;

const CardContainer = styled.div`
  background: ${colors.light};
`;

const CardTitleRow = styled.div`
  padding-bottom: var(--sp-2);
  margin-bottom: var(--sp-2);
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${colors.primaryDivider};
`;

const CardTitleItem = styled(Row)`
  align-items: center;
  padding: 0 var(--sp-2);
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${colors.primaryDivider};

  &:last-child {
    border-right-width: 0;
  }

  &:first-child {
    padding-left: 0;
  }
`;

const CardTitleHeader = styled(CardTitleItem)`
  font-weight: 600;
`;

const ValidatableStat = styled.p<{ number: number; errorValue: number }>`
  color: ${(props) => (props.number === props.errorValue ? colors.danger : "black")};
`;
