import { LoadingContent } from "components";
import { Column, FilterButton, Row } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { Class, DateRange, Session, defaultRequestState, Tag } from "types";
import { FC } from "types/FunctionalComponentWithStandardProps";
import { ReviewSessionList } from "./ReviewSessionList";
import { ReviewSessionsSidebarInfo } from "./ReviewSessionsSidebarInfo";
import { DateRangeType, ReviewClassSearchModal } from "./ReviewClassSearchModal";
import { useEffect, useState } from "react";
import {
  formatLongDate,
  formatLongDateRange,
  getFilteredSelectedSubjectTags,
  getFilteredSessionList,
  getSelectedFilteredSessionList,
  isSameDate,
} from "utils";
import { Names } from "textConstants";
import { useQueryParams } from "hooks";

interface Props {
  sessionLoading: boolean;
  selectedDateRange: DateRange;
  setDateChanged: (newSelectedDateRange: DateRange) => void;
  selectedSubjectTags: { tag: Tag; selected: boolean }[];
  setSelectedSubjectTags: (selectedTags: { tag: Tag; selected: boolean }[]) => void;
  sessions?: Session[];
  selectedClass?: Class;
  selectedSessionIds?: string[];
  onSessionPressed?: (sessionId: string) => void;
  onAllSessionsPressed?: (selectAll: boolean) => void;
  onTagsChanged?: (filteredSessions: Session[]) => void;
  focusedSessionId?: string;
}

const getDateRangeTypeFromString = (range?: string): DateRangeType => {
  if (range === "month" || range === undefined) {
    return "Last Month";
  } else if (range === "week") {
    return "Last Week";
  }
  return "Custom";
};

const ReviewSessionsSidebar: FC<Props> = ({
  sessionLoading,
  selectedDateRange,
  setDateChanged,
  selectedSubjectTags,
  setSelectedSubjectTags,
  sessions,
  selectedClass,
  selectedSessionIds,
  onSessionPressed,
  onAllSessionsPressed,
  focusedSessionId,
}) => {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const {
    params: { dateRange },
  } = useQueryParams<{ dateRange?: string }>();
  const [selectedDateRangeType, setSelectedDateRangeType] = useState<DateRangeType>(
    getDateRangeTypeFromString(dateRange)
  );
  const [{ loading: sessionsLoading }] = useState(defaultRequestState);

  const handleOnEditFiltersButton = () => {
    setSearchModalVisible(true);
  };

  useEffect(() => {
    setSelectedDateRangeType(getDateRangeTypeFromString(dateRange));
  }, [dateRange, setSelectedDateRangeType]);

  const onReviewClassSearchHidden = () => {
    setSearchModalVisible(false);
  };

  const getLessonShowingText = () => {
    let timePeriod = "";

    switch (selectedDateRangeType) {
      case "Last Week":
        timePeriod = "in the last week";
        break;
      case "Last Month":
        timePeriod = "in the last month";
        break;
      case "Custom":
        if (isSameDate(selectedDateRange.startDate, selectedDateRange.endDate)) {
          timePeriod = `on ${formatLongDate(selectedDateRange.startDate, false, true)}`;
        } else {
          timePeriod = `between ${formatLongDateRange(
            selectedDateRange.startDate,
            selectedDateRange.endDate,
            false,
            true,
            "and"
          )}`;
        }
        break;
    }

    const filteredSelectedSubjectTags = getFilteredSelectedSubjectTags(selectedSubjectTags, true);

    const filterText =
      filteredSelectedSubjectTags && getLessonShowingText.length > 0
        ? `matching ${filteredSelectedSubjectTags?.length}`
        : "";

    return `Showing ${Names.sessions} ${timePeriod} ${filterText}`;
  };

  return (
    <StyledColumn className="review-session-sidebar">
      <HeaderRow gap="1em">
        <FilterButton
          onClick={handleOnEditFiltersButton}
          activeFilters={getFilteredSelectedSubjectTags(selectedSubjectTags, false)?.length | 0}
        >
          Filter Games
        </FilterButton>
        <p>{getLessonShowingText()}</p>
      </HeaderRow>
      <ReviewSessionsSidebarInfo
        sessionsLoading={sessionsLoading}
        onSelectAll={() => {
          onAllSessionsPressed && onAllSessionsPressed(true);
        }}
        onDeselectAll={() => {
          onAllSessionsPressed && onAllSessionsPressed(false);
        }}
        totalLessons={getFilteredSessionList(sessions, selectedSubjectTags).length}
        sessions={getSelectedFilteredSessionList(selectedSessionIds, sessions, selectedSubjectTags)}
        selectedClass={selectedClass}
      />
      <LoadingContent loading={sessionLoading}>
        {sessions && (
          <ReviewSessionList
            sessions={getFilteredSessionList(sessions, selectedSubjectTags)}
            selectedSessionIds={selectedSessionIds || []}
            onSessionPressed={onSessionPressed}
            focusedSessionId={focusedSessionId}
          />
        )}
      </LoadingContent>
      <ReviewClassSearchModal
        visible={searchModalVisible}
        onHidden={onReviewClassSearchHidden}
        selectedDateRange={selectedDateRange}
        selectedDateRangeType={selectedDateRangeType}
        setDateChanged={setDateChanged}
        setDateRangeType={setSelectedDateRangeType}
        selectedSubjectTags={selectedSubjectTags}
        setSelectedSubjectTags={setSelectedSubjectTags}
      />
    </StyledColumn>
  );
};

const HeaderRow = styled(Row)`
  padding: 20px 20px 0px 20px;
`;

const StyledColumn = styled(Column)`
  min-width: var(--sidebar-width-lg);
  max-width: var(--sidebar-width-lg);
  border-right: 1px solid ${colors.primaryDivider};
`;

export { ReviewSessionsSidebar };
