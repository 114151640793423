import { slugs } from "textConstants";
import { Names, PlatformTitle } from "textConstants/main";
import { Tour } from "types";

const welcome: Tour = {
  id: "welcome",
  name: "Welcome",
  steps: [
    {
      target: ".played-logo",
      title: `Welcome to ${PlatformTitle}!`,
      content: `To start you on your ${PlatformTitle} journey, we're going to guide you through each of the main parts of the platform`,
    },
    {
      target: `.dashboard-item.${slugs.gamelibrary}`,
      content: `We'll start by getting you to set up and play a ${Names.customGame}. Click this button to get started`,
      hideBack: true,
      autoProgressCheck: (location) => location.pathname.includes(slugs.myGames),
      isEnd: true,
    },
  ],
  runAutomatically: (location, { login: { userInfo } }) => {
    if (
      userInfo === null ||
      userInfo.subscriptionInfo.subscriptionStatus === null ||
      userInfo.subscriptionInfo.subscriptionStatus === "None"
    ) {
      return false;
    }

    return true;
  },
};
export const DashboardTours: Tour[] = [welcome];
