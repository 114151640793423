import { Column, Row } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Question } from "types";
import { AnswerDisplay, QuestionDisplay } from "components";
import { reduce } from "lodash";

interface Props {
  questionNumber: number;
  question: Question;
}

export const QuestionSetQuestion: FC<Props> = ({ questionNumber, question }) => {
  const displayedAnswerIndices = reduce(
    question.answers,
    (prev: number[], val, index) => {
      if ((!question.answers || !question.answers[index]) && (!question.answerImages || !question.answerImages[index]))
        return prev;
      return [...prev, index];
    },
    []
  );
  return (
    <OuterContainer>
      <QuestionContainer>
        <QuestionDisplay
          questionNumber={questionNumber}
          question={question.question}
          questionImage={question.questionImage}
          questionType={question.type}
        />
      </QuestionContainer>
      <AnswersContainer>
        {displayedAnswerIndices.map((index: number) => {
          return (
            <AnswerContainer key={index} isCorrect={question.type === "multichoice" ? index === 0 : true}>
              <AnswerDisplay
                answerIndex={index}
                answers={question.answers}
                answerImages={question.answerImages}
                isCorrect={question.type === "multichoice" ? index === 0 : true}
              />
            </AnswerContainer>
          );
        })}
      </AnswersContainer>
    </OuterContainer>
  );
};

const AnswerContainer = styled.div.attrs(({ className }) => ({
  className: `p-2 ${className || ""}`,
}))<{ isCorrect: boolean }>`
  :not(:last-child) {
    border-bottom: 1px solid ${colors.darkText};
  }
  background-color: ${(props) => (props.isCorrect ? colors.brandDarkGreen : "transparent")};
`;

const AnswersContainer = styled(Column)`
  background-color: ${colors.white};
  border: 1px solid ${colors.darkText};
  width: 50%;
  font-size: 12px;
  --correct-font-weight: 700;
`;

const OuterContainer = styled(Row)`
  max-width: 100%;
`;

const QuestionContainer = styled(Column)`
  background-color: ${colors.white};
  border: 1px solid ${colors.darkText};
  border-right: 0px;
  width: 50%;
  padding var(--sp-2);
  justify-content: center;
  position: relative;
`;
