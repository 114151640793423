import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGradeTags } from "slices";

interface UseGradeTagsParams {
  fetch?: boolean;
}

const useGradeTags = ({ fetch }: UseGradeTagsParams) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.gradeTags);

  useEffect(() => {
    if (fetch && !state.loading && !state.success) {
      dispatch(fetchGradeTags());
    }
  }, [state, fetch, dispatch]);

  const functions = {
    fetchGradeTags: () => dispatch(fetchGradeTags()),
  };

  return { ...state, ...functions };
};

export { useGradeTags };
