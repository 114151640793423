import dayjs from "dayjs";
import { Column, HeaderText, Row, SecondaryText } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Session } from "types";
import { ReviewSessionListItem } from "./ReviewSessionListItem";

interface Props {
  sessions: Session[];
  selectedSessionIds: string[];
  onSessionPressed?: (sessionId: string) => void;
  focusedSessionId?: string;
}

const ReviewSessionDateContainer: FC<Props> = ({
  sessions,
  selectedSessionIds,
  onSessionPressed,
  focusedSessionId,
}) => {
  const containerDate = dayjs(sessions[0].startTime!);

  return (
    <OuterContainer>
      <InnerContainer>
        <SessionDateContainer>
          <SecondaryText color={colors.darkText}>
            <b>{containerDate.format("ddd").toUpperCase()}</b>
          </SecondaryText>
          <HeaderText className="p-0">{containerDate.date()}</HeaderText>
          <SecondaryText>{containerDate.format("MMM").toUpperCase()}</SecondaryText>
        </SessionDateContainer>
        <SessionListContainer>
          {sessions &&
            sessions.map((session) => (
              <ReviewSessionListItem
                isFocused={session.id === focusedSessionId}
                session={session}
                isSelected={selectedSessionIds.includes(session.id)}
                key={session.id}
                onSessionPressed={onSessionPressed}
              />
            ))}
        </SessionListContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)``;

const InnerContainer = styled(Row)`
  width: 100%;
`;

const SessionDateContainer = styled(Column)`
  min-width: 80px;
  align-items: center;
`;

const SessionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export { ReviewSessionDateContainer, OuterContainer as ReviewSessionDateContainerStyle };
