import { Class } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  classId: string;
}

const deleteClass = async ({ token, classId }: Params): Promise<Class> => {
  const response = await api.delete(`/classes/${classId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const class_ = response.data;

  if (class_) {
    return class_;
  } else {
    throw new NetworkError();
  }
};

export { deleteClass };
