import { updateGrouping } from "slices";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import { EmptyPanelContainer, LoadingContent, StudentGrid, StudentGroupingGrid } from "components";
import { useClass, useGroupingId } from "hooks";
import { isObject, keyBy, mapValues } from "lodash";
import { Column, Row } from "primitives";
import { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { Grouping, Student } from "types";
import { PartitionToolProps } from "./StudentGroupingPartition";

const EmptyStudentGrid: FC = () => {
  return <EmptyPanelContainer title="A brand new class!" message={<>No students in this class.</>} />;
};

export interface StudentCardProps {
  student: Student;
  disabled?: boolean;
  data?: any;
}

interface Props {
  sortStudents?: (student1: Student, student2: Student) => number;
  StudentCard: FC<StudentCardProps>;
  isLoading?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  hideGroupCount?: boolean;
  allowEditing?: boolean;
  PartitionTools?: FC<PartitionToolProps>;
  setUngroupedToBottom?: boolean;
  cardData?: any;
}

export const GroupedStudentGrid: FC<Props> = ({
  header,
  footer,
  StudentCard,
  sortStudents,
  isLoading,
  hideGroupCount,
  allowEditing,
  PartitionTools,
  setUngroupedToBottom,
  cardData,
}) => {
  const class_ = useClass();
  const [groupingId] = useGroupingId();

  const isGroupingLoaded = useAppSelector((state: RootState) => isObject(state.groupings.data));
  const grouping = useAppSelector((state: RootState) =>
    state.groupings.data && groupingId ? state.groupings.data[groupingId] : undefined
  );
  const screenLoading = !class_?.students || isLoading === true;

  const [draftGrouping, setDraftGrouping] = useState<Grouping | undefined>(grouping);

  useEffect(() => {
    setDraftGrouping(grouping);
  }, [grouping]);

  const dispatch = useAppDispatch();

  const onGroupingUpdated = (newGrouping: Partial<Grouping>) => {
    const fullGrouping: Grouping = { ...grouping!, ...newGrouping };

    setDraftGrouping(fullGrouping);
    // Note: We update the grouping after every change. Would it be
    // smarter to batch these and send them together?
    applyChanges(fullGrouping);
  };

  const applyChanges = (newGrouping: Grouping) => {
    if (grouping && newGrouping.partitions) {
      dispatch(updateGrouping(grouping.id, newGrouping));
    }
  };

  let students = [...(class_?.students || [])];
  students.sort(sortStudents);
  const studentCards = mapValues(keyBy(students || [], "id"), (student) => (
    <StudentCard key={student.id} student={student} data={cardData || {}} />
  ));

  return (
    <LoadingContent loading={screenLoading || !isGroupingLoaded || (Boolean(groupingId) && !Boolean(draftGrouping))}>
      {class_?.students?.length === 0 ? (
        <EmptyStudentGrid />
      ) : (
        <>
          {header}
          {draftGrouping ? (
            <StudentGroupingGrid
              grouping={draftGrouping}
              students={class_?.students}
              studentCards={studentCards}
              editingDisabled={!Boolean(allowEditing)}
              onGroupingUpdated={onGroupingUpdated}
              sortStudents={sortStudents}
              hideGroupCount={hideGroupCount}
              PartitionTools={PartitionTools}
              setUngroupedToBottom={setUngroupedToBottom}
            />
          ) : (
            <StudentGrid>{Object.values(studentCards)}</StudentGrid>
          )}
          {footer}
        </>
      )}
    </LoadingContent>
  );
};

export const StudentGridKeyContainer = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${colors.primaryDivider};
  font-size: 11px;
  margin-top: auto;
  padding-top: var(--sp-2);
`;

export const StudentGridKey = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: var(--sp-4);
`;

export const StudentGridKeyItem = styled(Row)`
  align-items: center;
  gap: var(--sp-1);
`;

export const StudentGridKeyColour = styled.div<{ fill: string }>`
  border-radius: 50%;
  background-color: ${(props) => props.fill};
  width: 10px;
  height: 10px;
`;
