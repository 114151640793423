import { useAppDispatch, useAppSelector } from "app/hooks";
import { InfiniteScrollList } from "components";
import { quickGameActionsHooks, SessionActionHook, useQueryParams } from "hooks";
import { LessonPlanPreviewModal } from "pages/my-games";
import pluralize from "pluralize";
import { Column, PrimaryButton, Row } from "primitives";
import { MedIcon } from "primitives/icons";
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import {
  fetchPremadeSessions as fetchPremadeSessionsSlice,
  resetSessionListPageData,
  resetSessionListTotals,
} from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { actionConstants, Names, paths, slugs } from "textConstants";
import { FC } from "types";
import { QuickGameListItem } from "./QuickGameListItem";

interface Props {
  actionHooks?: SessionActionHook[];
  showHeaderButton?: boolean;
}

const QuickGameList: FC<Props> = ({ actionHooks = quickGameActionsHooks, showHeaderButton = true }) => {
  const dispatch = useAppDispatch();
  const { data, paginationData, totals } = useAppSelector((state) => state.sessionList);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const { path, url } = useRouteMatch();
  const {
    params: { search },
    setParams,
  } = useQueryParams<{ search: string }>();
  const [searchText, setSearchText] = useState(search || "");

  const sessions = (data && paginationData.orderedIds.map((id) => data[id])) || [];
  const totalSessions = totals ? totals["premade"] : 0;
  const moreToFetch = sessions.length < totalSessions;

  const fetchPremadeSessions = useCallback(() => {
    const take = 14;
    const skip = currentPage * take;
    setParams({ search: searchText });
    dispatch(fetchPremadeSessionsSlice({ search: searchText, take, skip, isPreviewFetch: false }));
  }, [currentPage, dispatch, searchText, setParams]);

  useEffect(() => {
    dispatch(resetSessionListPageData());
    fetchPremadeSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(resetSessionListTotals());
      dispatch(resetSessionListPageData());
      setCurrentPage(0);
      fetchPremadeSessions();
    }, 500);

    return () => clearTimeout(delayDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, dispatch]);

  useEffect(() => {
    fetchPremadeSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleNewGamePressed = () => {
    history.push(paths.teacher.myGames.miniGame.new.path);
  };

  const fetchMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const previewModalHidden = () => {
    history.push(url);
  };

  return (
    <>
      <OuterContainer>
        <HeaderRow>
          <HeaderText>Choose from one of our premade games</HeaderText>
          {showHeaderButton && (
            <PrimaryButton className="mb-2 play-game-now-button" variant="primary" onClick={handleNewGamePressed}>
              <MedIcon icon={actionConstants.play.icon} />
              Pick Your Own Questions
            </PrimaryButton>
          )}
        </HeaderRow>
        <Form.Control
          placeholder={`Search for ${Names.CustomGame}`}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <p className="my-2">
          Showing {totalSessions} {pluralize(Names.customGame, totalSessions)} that match your search
        </p>
        <InfiniteScrollList
          currentDataLength={sessions?.length || 0}
          fetchMoreData={fetchMore}
          hasMoreToFetch={moreToFetch}
          style={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            marginTop: "20px",
          }}
          infiniteScrollStyle={{
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
            overflowY: "hidden",
            gap: "10px",
          }}
        >
          {sessions &&
            Object.values(sessions).map((session) => (
              <QuickGameListItem key={session.id} session={session} actionHooks={actionHooks} />
            ))}
        </InfiniteScrollList>
      </OuterContainer>
      <Switch>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}`} exact>
          <LessonPlanPreviewModal onHidden={previewModalHidden} isPublicView={true} />
        </Route>
      </Switch>
    </>
  );
};

const OuterContainer = styled(Column)`
  width: 100%;
  padding: 30px 120px 10px 120px;
  height: 100%;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  min-height: 53px;
`;

const HeaderText = styled.p`
  color: ${colors.darkText};
  font-size: 20px;
  font-weight: 600;
`;

export { QuickGameList };
