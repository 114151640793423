import { useAppDispatch, useAppSelector } from "app/hooks";
import { FC, ReactNode, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fetchNewsItems } from "slices/newsItemSlice";
import { dispatchAsync } from "utils";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { PrimaryButton } from "primitives/button";
import styled from "styled-components";
import { colors } from "styles";
import { LargeIcon } from "primitives/icons";
import { useLogin } from "hooks";
import { Config } from "react-player";
import ReactPlayer from "react-player";
import { Column, Row } from "primitives";
import { newsItemConstants } from "textConstants";

interface Props {
  visible: boolean;
  hide: () => void;
}

const WhatsNewModal: FC<Props> = ({ visible, hide }) => {
  const [isClosing, setIsClosing] = useState(false);
  const dispatch = useAppDispatch();
  const newsItemList = useAppSelector((state) => state.newsItems.data);
  const [curNewsItemIndex, setCurNewsItemIndex] = useState<number | undefined>(0);
  const { userInfo, updateLastReadNewsItem } = useLogin();

  useEffect(() => {
    if (!newsItemList) {
      dispatchAsync(dispatch(fetchNewsItems()), async () => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsItemList]);

  //reset the carousel to the start
  useEffect(() => {
    if (visible) {
      setCurNewsItemIndex(0);
    }
  }, [visible]);

  const onDone = () => {
    if (newsItemList && userInfo) {
      //get the latest news item
      let latestNews = newsItemList[0];
      newsItemList.forEach((item, index) => {
        if (item.modifiedAt && latestNews.modifiedAt) {
          latestNews = item.modifiedAt > latestNews.modifiedAt ? item : latestNews;
        }
      });
      updateLastReadNewsItem(userInfo.id, latestNews);
    }
    onModalClosed();
  };

  const onHide = () => {
    setIsClosing(true);
  };

  const onModalClosed = () => {
    setIsClosing(false);
    hide();
  };

  const onCarouselChange = (index: number, item: ReactNode) => {
    setCurNewsItemIndex(index);
  };

  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    return (
      <IndicatorCircle
        onClick={clickHandler}
        style={{
          backgroundColor: isSelected ? colors.primary : colors.lightActive,
        }}
      />
    );
  };

  const onButtonNext = () => {
    if (curNewsItemIndex !== undefined) {
      setCurNewsItemIndex(curNewsItemIndex + 1);
    }
  };

  const onButtonPrevious = () => {
    if (curNewsItemIndex !== undefined) {
      setCurNewsItemIndex(curNewsItemIndex - 1);
    }
  };

  const videoPlayerConfig: Config = {
    youtube: {
      playerVars: {
        showinfo: "1",
      },
    },
  };

  return (
    <Modal
      show={visible && !isClosing}
      dialogClassName="width-700px-modal"
      onHide={onHide}
      onExited={onModalClosed}
      backdrop="static"
    >
      <Modal.Body className="p-0">
        <Carousel
          showArrows={false}
          showThumbs={false}
          showIndicators={true}
          showStatus={false}
          renderIndicator={renderIndicator}
          onChange={onCarouselChange}
          selectedItem={curNewsItemIndex}
        >
          {newsItemList?.map((newsItem, index) => {
            return (
              <NewsItemContainer key={newsItem.id}>
                <Modal.Header className="p-0">
                  <Row>
                    <Column className="mr-3 justify-content-center">
                      <CenteredLargeIcon icon={newsItemConstants.icon} color={colors.danger} />
                    </Column>
                    <Modal.Title className="py-1">{newsItem.header}</Modal.Title>
                  </Row>
                </Modal.Header>
                {(newsItem.videoUrl && (
                  <NewsItemMediaContainer className="mt-3">
                    <NewsItemMedia
                      controls={true}
                      config={videoPlayerConfig}
                      url={newsItem.videoUrl}
                      width={`100%`}
                      height={`100%`}
                    />
                  </NewsItemMediaContainer>
                )) ||
                  (newsItem.imageUrl && (
                    <NewsItemMediaContainer className="mt-3">
                      <NewsItemImage src={newsItem.imageUrl} alt={"new feature"} />{" "}
                    </NewsItemMediaContainer>
                  ))}
                <NewsItemContentContainer>
                  <NewsItemBody className="my-2" dangerouslySetInnerHTML={{ __html: newsItem.body }} />
                </NewsItemContentContainer>
              </NewsItemContainer>
            );
          })}
        </Carousel>
        <NewsItemFooter>
          <PrimaryButton
            variant="secondary"
            disabled={!Boolean(curNewsItemIndex)}
            className="m-1"
            onClick={onButtonPrevious}
          >
            Previous
          </PrimaryButton>
          {newsItemList && curNewsItemIndex !== newsItemList.length - 1 ? (
            <PrimaryButton className="m-1" onClick={onButtonNext}>
              Next
            </PrimaryButton>
          ) : (
            <PrimaryButton className="m-1" onClick={onDone}>
              Done
            </PrimaryButton>
          )}
        </NewsItemFooter>
      </Modal.Body>
    </Modal>
  );
};

const NewsItemFooter = styled(Row)`
  justify-content: center;
  padding-bottom: 55px;
`;

const NewsItemContainer = styled(Column)`
  height: 100%;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
`;

const NewsItemContentContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 1em;
  margin-bottom: 4em;
`;

const NewsItemMedia = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 20em;
  margin: 0;
`;

//Padding thing is to force media player to resize
//More info here - https://stackoverflow.com/questions/49393838/how-to-make-reactplayer-scale-with-height-and-width
const NewsItemMediaContainer = styled(Column)`
  position: relative;
  min-height: 16em;
  display: flex;
  padding-top: 56.25%;
  justify-items: center;
  align-items: center;
  background-color: ${colors.black};
`;

const NewsItemBody = styled.div`
  text-align: left;
`;

const NewsItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const IndicatorCircle = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transform: translate(20%, -20%);
  display: inline-block;
  margin: 10px;
  cursor: pointer;
`;

const CenteredLargeIcon = styled(LargeIcon)`
  display: flex,
  height: 100%   
`;

export { WhatsNewModal };
