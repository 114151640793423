import { Center, Column, HorizontalCenter, LoginBackgroundContainer, OnboardingLocation, Spacer } from "primitives";
import { OnboardingElementContainer } from "pages";
import { colors } from "styles";
import { FC } from "types";
import { DescriptionText } from "components";
import { PrimaryButton } from "primitives/button";
import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState, useCallback } from "react";
import { useLogin } from "hooks";
import { updatePaymentMethod as updatePaymentMethodApi } from "api/payment";
import { refreshTeacherData } from "slices";
import { useAppDispatch } from "app/hooks";
import { sleep } from "utils";

interface Props {
  clientSecret: string;
  onTryAgainPressed: () => void;
  onContinuePressed: () => void;
}

// Mostly taken from: https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-submit-payment
export const SubscriptionPaymentComplete: FC<Props> = ({ clientSecret, onTryAgainPressed, onContinuePressed }) => {
  const stripe = useStripe();
  const { userInfo } = useLogin();
  const [screenInfo, setScreenInfo] = useState<{ message: string; title: string; proceedAction: null | (() => void) }>({
    message: "",
    title: "",
    proceedAction: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useLogin();
  const dispatch = useAppDispatch();

  const waitForIncompleteSubscription = useCallback(async () => {
    if (userInfo?.subscriptionInfo) {
      for (let i = 0; i < 5; i++) {
        await sleep(2000);
        const newData = await dispatch(refreshTeacherData());
        if (newData?.subscriptionInfo.subscriptionStatus !== "incomplete") {
          return newData?.subscriptionInfo.subscriptionStatus;
        }
      }

      return userInfo?.subscriptionInfo?.subscriptionStatus;
    }
  }, [userInfo, dispatch]);

  const setPaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      setLoading(true);
      if (token) {
        setScreenInfo({
          message: `Processing. We'll update you when everything is ready!`,
          title: "Please Wait",
          proceedAction: () => {},
        });
        await updatePaymentMethodApi(token, paymentMethodId);

        // Wait for 10 total seconds, checking the status every 2 seconds
        // We need to allow for stripe to update the backend with the new
        // status, otherwise it may look like the payment didn't complete
        let finalStatus = await waitForIncompleteSubscription();

        // Update the message to the user, and repeat the above if
        // nothing has changed yet.
        if (finalStatus === "incomplete") {
          setScreenInfo({
            message: `This is taking a while but we're still trying.`,
            title: "Please Wait",
            proceedAction: () => {},
          });

          finalStatus = await waitForIncompleteSubscription();
        }

        // Show the outcome of the screen
        if (finalStatus === "incomplete") {
          setScreenInfo({
            message: `Payment failed. Please try another payment method.`,
            title: "Payment Failed",
            proceedAction: onTryAgainPressed,
          });
        } else {
          setScreenInfo({
            message: `All done!`,
            title: "Complete",
            proceedAction: onContinuePressed,
          });
        }
      }
      setLoading(false);
    },
    [token, onContinuePressed, onTryAgainPressed, waitForIncompleteSubscription]
  );

  useEffect(() => {
    if (stripe) {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        switch (setupIntent?.status) {
          case "succeeded":
            if (setupIntent.payment_method) {
              setPaymentMethod(setupIntent.payment_method);
            }
            break;
          case "processing":
            setScreenInfo({
              message: `Payment processing. We'll update you when payment is received.`,
              title: "Please Wait",
              proceedAction: () => {},
            });
            break;
          case "requires_payment_method":
            setScreenInfo({
              message: `Payment failed. Please try another payment method.`,
              title: "Payment Failed",
              proceedAction: onTryAgainPressed,
            });
            break;
          default:
            setScreenInfo({
              message: `Something went wrong.`,
              title: "Payment Failed",
              proceedAction: onTryAgainPressed,
            });
            break;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  return (
    <LoginBackgroundContainer backgroundColour={colors.brandPurple} location={OnboardingLocation.register}>
      <HorizontalCenter>
        <OnboardingElementContainer title={screenInfo.title} size={"S"}>
          <Column>
            <DescriptionText>{screenInfo.message}</DescriptionText>
            <Spacer size={20} />
            <Center>
              <PrimaryButton disabled={!screenInfo.proceedAction || loading} onClick={screenInfo.proceedAction!}>
                Continue
              </PrimaryButton>
            </Center>
          </Column>
        </OnboardingElementContainer>
      </HorizontalCenter>
    </LoginBackgroundContainer>
  );
};
