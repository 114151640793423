import { findIndex } from "lodash";
import { useLocation } from "react-router-dom";
import { NavbarItemProperties } from "./Navbar";

type Location = ReturnType<typeof useLocation>;

const getActiveNavbarIndexFromCurrentUrl = (
  location: Location,
  navbarBaseUrl: string,
  navbarItems: Array<NavbarItemProperties>
) => {
  const currentNavbarRoute = getCurrentNavbarRoute(location, navbarBaseUrl);
  return findIndex(navbarItems, ({ route }) => currentNavbarRoute === route);
};

const getCurrentNavbarRoute = (location: Location, sidebarBaseUrl: string) => {
  const separatedPath = location.pathname.split("/");
  const indexOfSidebarItemNameInPath = separatedPath.indexOf(sidebarBaseUrl.replace("/", "")) + 1;
  return separatedPath[indexOfSidebarItemNameInPath];
};

export { getCurrentNavbarRoute, getActiveNavbarIndexFromCurrentUrl };
