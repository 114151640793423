import { Alert } from "react-bootstrap";
import { Row, Column } from "../../../primitives";

export const CustomTTSOverrideAlert = () => {
  return (
    <Alert variant={"primary"}>
      <Row>
        <Column>
          <p className="pb-3">
            <i>
              Pronunciations will replace <b>any</b> matching text in questions <b>and</b> answers. Be careful when
              creating pronunciations, as it can lead to words being mashed together or text being mistakenly replaced.{" "}
            </i>
          </p>
          <p>
            <i>
              This is most common with smaller pieces of text, like “Pronounce <b>x</b> as <b>times</b>”, as this would
              be match with any <b>x</b> in your questions or answers. E.g. “e<b>x</b>ample” becomes “e
              <b>times</b>ample”. Instead, surround your words with spaces, such as “Pronounce ' x ' as ' times'”.
            </i>
          </p>
        </Column>
      </Row>
    </Alert>
  );
};
