import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TutorialState {
  isPlayingTutorial: boolean;
}

const initialState: TutorialState = {
  isPlayingTutorial: false,
};

export const tutorialStateSlice = createSlice({
  name: "tutorialState",
  initialState: initialState,
  reducers: {
    setIsPlayingTutorial: (state, action: PayloadAction<boolean>) => {
      state.isPlayingTutorial = action.payload;
    },
  },
});

export const { setIsPlayingTutorial } = tutorialStateSlice.actions;
export const tutorialStateReducer = tutorialStateSlice.reducer;
