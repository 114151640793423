import { StudentName } from "types";

const splitCondition = "\n";

const getUnstructredStudentList = (namesText: string): string[][] => {
  const rows = namesText.split(splitCondition);
  const matchWhitespaceOrComma = /[\s,]+/g;
  return rows.map((row) => row.split(matchWhitespaceOrComma).filter((name) => name));
};

const getStudentNamesFromText = (namesText: string): Array<StudentName> => {
  const unstructuredStudentNames = getUnstructredStudentList(namesText);

  const studentNames: Array<StudentName> = [];

  unstructuredStudentNames.forEach((namesList) => {
    if (!namesList.length) return;

    const firstName = namesList.shift()!;
    const lastName = namesList[0] ?? "";

    studentNames.push({ firstName, lastName });
  });

  return studentNames;
};

const getStudentCountFromText = (namesText: string): number => {
  return getStudentNamesFromText(namesText).length;
};

export { getStudentNamesFromText, getStudentCountFromText };
