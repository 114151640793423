import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { createGrouping } from "api";
import { GroupingActions } from "./groupingsSlice";
import { Partition } from "types";

export interface CreateGroupingParams {
  name: string;
  classId: string;
  partitions: Partition[];
}

const dispatchCreateGrouping = (
  { name, classId, partitions }: CreateGroupingParams,
  actions: GroupingActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const grouping = await createGrouping({ token, classId, name, partitions });
    dispatch(
      actions.addSingle({
        groupingId: grouping.id,
        grouping: { ...grouping, partitions, partitionCount: partitions.length },
      })
    );
    return grouping;
  }, dispatch);

export { dispatchCreateGrouping };
