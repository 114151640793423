import { QuestionSet } from "types";

export const getQuestionsCount = (questionSets: QuestionSet[]) => {
  let sum = 0;
  questionSets.forEach((questionSet) => {
    if (questionSet && questionSet.questions) {
      sum += questionSet.questions.length;
    }
  });

  return sum;
};
