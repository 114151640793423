export const template = `<html>
  <head>
    ::StyledComponents::

    <style>
      ::GlobalStyles::
    </style>

    <style>
      @font-face {
        font-family: "LEMON MILK Pro";
        src: url(data:font/truetype;charset=utf-8;base64,::Font::);
      }
    </style>
  </head>
  <body>
    ::Content::
  </body>
</html>`;
