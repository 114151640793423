import {
  register,
  requestVerify,
  verify,
  login,
  twoFactorLogin,
  requestResetPassword,
  updateTeacher,
  updateOnboardingProgress,
  updateLastReadNewsItem,
  resetPassword,
  updateTwoFactorAuth,
  refreshTeacherData,
} from "slices";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { NewsItem, StringMap } from "types";
import { useHistory } from "react-router";
import { stringify } from "querystring";

const useLogin = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.login);
  const history = useHistory();

  const processLogout = (postLogoutDestination?: string, searchParams?: StringMap, dontClear?: boolean) => {
    let query = "";

    if (postLogoutDestination !== undefined) {
      query += "loc=" + postLogoutDestination;
    }
    if (searchParams !== undefined) {
      if (query) {
        query += "&";
      }
      query += "search=" + encodeURIComponent(stringify(searchParams));
    }
    if (dontClear) {
      if (query) {
        query += "&";
      }
      query += "dontClear=true";
    }
    const target = query ? "/logout?" + query : "/logout";
    history.push(target);
  };

  const functions = {
    register: (firstName: string, lastName: string, email: string, password: string) =>
      dispatch(register(firstName, lastName, email, password)),
    requestVerifyEmail: (teacherId: string) => dispatch(requestVerify(teacherId)),
    verify: (token: string) => dispatch(verify(token)),
    login: (email: string, password: string, rememberMe: boolean) => dispatch(login(email, password, rememberMe)),
    twoFactorLogin: (teacherId: string, emailToken: string, rememberMe: boolean) =>
      dispatch(twoFactorLogin(teacherId, emailToken, rememberMe)),
    logout: (postLogoutDestination?: string, searchParams?: StringMap, dontClear?: boolean) =>
      processLogout(postLogoutDestination, searchParams, dontClear),
    isAdmin: () => state.userInfo?.role.name === "admin",
    updateTeacher: (id: string, firstName: string, lastName: string) =>
      dispatch(updateTeacher(id, firstName, lastName)),
    updateOnboardingProgress: (id: string, onboardingProgress: string) =>
      dispatch(updateOnboardingProgress(id, onboardingProgress)),
    updateLastReadNewsItem: (id: string, lastReadNewsItem: NewsItem) =>
      dispatch(updateLastReadNewsItem(id, lastReadNewsItem)),
    requestResetPassword: (email: string) => dispatch(requestResetPassword(email)),
    resetPassword: (token: string, email: string, password: string) => dispatch(resetPassword(token, email, password)),
    updateTwoFactorAuth: (teacherId: string, enabled: boolean) => dispatch(updateTwoFactorAuth(teacherId, enabled)),
    refreshTeacherData: () => dispatch(refreshTeacherData()),
  };

  return { ...state, ...functions };
};

export { useLogin };
