import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { updateGrouping } from "api";
import { Grouping } from "../../types";
import { GroupingActions } from "./groupingsSlice";

export interface UpdateGroupingParams {
  groupingId: string;
  updateData: Grouping;
}

const dispatchUpdateGrouping = ({ groupingId, updateData }: UpdateGroupingParams, actions: GroupingActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const grouping = await updateGrouping({ token, groupingId, updateData });
    dispatch(actions.addSingle({ groupingId: grouping.id, grouping, forceMerge: true }));
    return grouping;
  }, dispatch);

export { dispatchUpdateGrouping };
