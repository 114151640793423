import { useState, useEffect, FC, useCallback } from "react";
import { Form } from "react-bootstrap";

const Default_Character_Limit = 240;

interface CharacterLimitedNotEmptyInputArgs {
  label: string;
  warningMessage?: string;
  initialTextValue?: string;
  isTextArea?: boolean;
  characterLimit?: number;
  disabled?: boolean;
  additionalChecks?: (text: string) => boolean;
}

interface ComponentProps {
  text: string;
  setText: (text: string) => void;
  valid: boolean;
  setValid: (valid: boolean) => void;
  warnIfInvalid: boolean;
  disabled?: boolean;
  additionalChecks?: (text: string) => boolean;
}

const useCharacterLimitedNonEmptyInput = ({
  label,
  warningMessage,
  initialTextValue,
  isTextArea,
  characterLimit,
  disabled,
  additionalChecks,
}: CharacterLimitedNotEmptyInputArgs): [
  component: React.FC<ComponentProps>,
  state: { text: string; valid: boolean; warnIfInvalid: boolean },
  setState: {
    setText: (text: string) => void;
    setValid: (valid: boolean) => void;
    forceValidate: () => void;
    resetState: () => void;
  }
] => {
  const [text, setText] = useState(initialTextValue || "");
  const [valid, setValid] = useState(false);
  const [warnIfInvalid, setWarnIfInvalid] = useState(false);

  characterLimit = characterLimit || Default_Character_Limit;

  const isTextValid = useCallback(() => {
    let newValidState = Boolean(!!text.trim().length);
    if (additionalChecks) {
      newValidState = newValidState && additionalChecks(text);
    }
    return newValidState;
  }, [text, additionalChecks]);

  // Used in forced validation by owner of object, e.g. when using a submit button - forces the warning to show even if the field is empty
  const forceValidate = useCallback(() => {
    setValid(isTextValid());
    if (warningMessage && !warnIfInvalid) {
      setWarnIfInvalid(true);
    }
  }, [warnIfInvalid, warningMessage, isTextValid]);

  const resetState = () => {
    setText(initialTextValue || "");
    setValid(false);
    setWarnIfInvalid(false);
  };

  // Only show warning if user has entered a valid input and then removed it
  useEffect(() => {
    const textValid = isTextValid();
    setValid(textValid);
    if (textValid && warningMessage && !warnIfInvalid) {
      setWarnIfInvalid(true);
    }
  }, [warningMessage, warnIfInvalid, isTextValid]);

  const Component: FC<ComponentProps> = useCallback(
    ({ text, setText, valid, warnIfInvalid }) => (
      <Form.Group style={{ minHeight: 95 }}>
        <Form.Label className="text-secondary">{`${label}${warningMessage ? " *" : ""}`}</Form.Label>
        <Form.Control
          key={label}
          placeholder={label}
          disabled={disabled}
          value={text}
          as={isTextArea ? "textarea" : undefined}
          onChange={(e) => {
            e.preventDefault();
            const newText = e.target.value.replace("\n", "");
            setText(newText.substr(0, characterLimit));
          }}
          isValid={valid}
          isInvalid={!valid && warnIfInvalid}
        />
        <Form.Control.Feedback type="invalid">{warningMessage}</Form.Control.Feedback>
        <Form.Text className="text-muted" style={{ width: "100%", textAlign: "right" }}>
          {`${text.length}/${characterLimit}`}
        </Form.Text>
      </Form.Group>
    ),
    [label, warningMessage, characterLimit, isTextArea, disabled]
  );

  return [Component, { text, valid, warnIfInvalid }, { setText, setValid, forceValidate, resetState }];
};

export { useCharacterLimitedNonEmptyInput };
