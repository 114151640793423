import { Student } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  studentId: string;
}

const regenerateStudentPassword = async ({ token, studentId }: Params): Promise<Student> => {
  const response = await api.post(
    `/students/${studentId}/resetpassword`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  const fetchedStudent = response.data;

  if (fetchedStudent) {
    return fetchedStudent;
  } else {
    throw new NetworkError();
  }
};

export { regenerateStudentPassword };
