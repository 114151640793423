import { FC } from "react";
import { AnswerTuple, FreetextQuestion, MultichoiceQuestion, Question } from "types";
import styled from "styled-components";
import { colors } from "styles";
import { DraggableItem, QuestionSetQuestionImage, DragItemHandle, ItemAlignment, MoveDirection } from "components";
import { Row, ToolTip, VerticalLine } from "primitives";
import { DeleteButton } from "primitives/button";
import { isQuestionValid } from "../questionSetValidation/generateErrorFeedbackForAnswerIndex";
import { Restore } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { CorrectAnswerCircle } from "../CorrectAnswerCircle";
import { MediaConstants } from "utils/mediaConstants";
import { questionConstants } from "textConstants";

interface Props {
  question: Question;
  index: number;
  alignment: ItemAlignment;
  activeQuestionIndex: number | null;
  onQuestionPressed: (index: number) => void;
  onQuestionMoved?: (index: number, direction: MoveDirection) => void;
  onQuestionDeleted: (questionId: string, isDeleted: boolean) => void;
}

interface QuestionStyleProps {
  hasOneElement: boolean;
  toBeDeleted?: boolean;
}

const QuestionListItem: FC<Props> = ({
  question,
  index,
  alignment,
  activeQuestionIndex,
  onQuestionPressed,
  onQuestionMoved,
  onQuestionDeleted,
}) => {
  const getBackgroundColor = (questionIsActive: boolean) => {
    if (questionIsActive) {
      return colors.transparentPrimary;
    } else {
      return colors.white;
    }
  };

  const getBorder = (question: Question) => {
    if (!isQuestionValid(question) || question.toBeDeleted) {
      return `2px solid ${colors.danger}`;
    } else if (question.displayAsUnsaved) {
      return `2px solid ${colors.warning}`;
    } else {
      return `1px solid ${colors.primaryDivider}`;
    }
  };

  let questionText: string;
  let questionImage: string | null;
  let answers: AnswerTuple;
  let answerImages: AnswerTuple;
  let correctAnswerIndex: number;

  // TODO: fix this when we have a proper design for the QuestionListItem
  if (question.type === "multichoice") {
    const multichoiceQuestion = question as MultichoiceQuestion;

    questionText = multichoiceQuestion.question;
    questionImage = multichoiceQuestion.questionImage;
    answers = multichoiceQuestion.answers;
    answerImages = multichoiceQuestion.answerImages;
    correctAnswerIndex = multichoiceQuestion.correctAnswerIndex;
  } else {
    const multichoiceQuestion = question as FreetextQuestion;

    questionText = multichoiceQuestion.question;
    questionImage = multichoiceQuestion.questionImage;
    answers = multichoiceQuestion.answers;
    answerImages = [];
    correctAnswerIndex = 0;
  }

  const { id, toBeDeleted } = question;

  const backgroundColor = getBackgroundColor(index === activeQuestionIndex);
  const opacity = toBeDeleted ? 0.5 : 1;
  const border = getBorder(question);

  const questionHasOneElement = !(Boolean(questionText) && Boolean(questionImage));
  const answerHasOneElement = !(Boolean(answers[correctAnswerIndex]) && Boolean(answerImages[correctAnswerIndex]));

  const listItem = (
    <Row>
      <OuterContainer
        className="question-list-item"
        style={{ backgroundColor, opacity, border }}
        onClick={() => onQuestionPressed(index)}
      >
        <DragHandleContainer>
          {!toBeDeleted && (
            <TopLeftContainer>
              <QuestionNumberText> {index + 1}.</QuestionNumberText>
            </TopLeftContainer>
          )}
          <DragItemHandle index={index} alignment={alignment} onMovePressed={onQuestionMoved} />
          <VerticalLine margin={"0.25em 1em"} />
        </DragHandleContainer>
        <DataContainer>
          <QuestionAnswerContainer>
            {questionText && <QuestionText hasOneElement={questionHasOneElement}>{questionText}</QuestionText>}
            <QuestionSetQuestionImage imageUrl={questionImage} />
            <TopRightContainer>
              <MedIcon icon={questionConstants.questionTypes[question.type].icon} />
            </TopRightContainer>
          </QuestionAnswerContainer>
          <VerticalLine margin={"0.25em 0em"} />
          <QuestionAnswerContainer>
            <TopLeftContainer>
              <CorrectAnswerCircle />
            </TopLeftContainer>
            {answers[correctAnswerIndex] && (
              <AnswerText toBeDeleted={toBeDeleted} hasOneElement={answerHasOneElement}>
                {answers[correctAnswerIndex]}
              </AnswerText>
            )}
            <QuestionSetQuestionImage imageUrl={answerImages[correctAnswerIndex]} />
          </QuestionAnswerContainer>
        </DataContainer>
        <DeleteContainer>
          {!toBeDeleted && (
            <>
              <VerticalLine margin={"0px"} />
              <DeleteButton
                style={{ marginLeft: "8px" }}
                tooltip={"Delete"}
                onClick={() => onQuestionDeleted(id, true)}
              />
            </>
          )}
        </DeleteContainer>
      </OuterContainer>
      {toBeDeleted && (
        <RestoreContainer>
          <RestoreButton onClick={() => onQuestionDeleted(id, false)} />
        </RestoreContainer>
      )}
    </Row>
  );

  return question.toBeDeleted ? <>{listItem}</> : <DraggableItem index={index}>{listItem}</DraggableItem>;
};

const dragHandleWidth = "60px";
const deleteRestoreWidth = "50px";

const OuterContainer = styled(Row)`
  flex: 1;
  height: 100px;
  margin: 10px 0px;
  padding: 10px 5px;
  border: 1px solid ${colors.inactiveButton};
  align-items: center;
  transition: all 0.25s ease-in-out;
  position: relative;
`;

const DragHandleContainer = styled(Row)`
  height: 100%;
  padding-left: 10px;
  min-width: ${dragHandleWidth};
  max-width: ${dragHandleWidth};
`;

const TopLeftContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 7px;
`;

const TopRightContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 7px;
`;

const QuestionNumberText = styled.p`
  color: ${colors.inactiveButton};
  font-size: 10px;
`;

const DataContainer = styled(Row)`
  flex: 1;
  height: 100%;
  cursor: pointer;
`;

const QuestionAnswerContainer = styled(Row)`
  width: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 10px;
  position: relative;
  padding: 0em 1em;
`;

const RestoreContainer = styled(Row)`
  min-width: ${deleteRestoreWidth};
  max-width: ${deleteRestoreWidth};
  align-items: center;
  justify-content: center;
`;

const DeleteContainer = styled(RestoreContainer)`
  height: 100%;
`;

const TruncatedOverflowText = styled.p<QuestionStyleProps>`
  width: ${(props) => (props.hasOneElement ? "30ch" : "20ch")};
  @media ${MediaConstants.laptopL} {
    width: ${(props) => (props.hasOneElement ? "15ch" : "10ch")};
  }

  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

const QuestionText = styled(TruncatedOverflowText)<QuestionStyleProps>`
  font-weight: 600;
  justify-self: flex-start;
  flex: 1;
`;

const AnswerText = styled(TruncatedOverflowText)<QuestionStyleProps>`
  color: ${(props) => (props.toBeDeleted ? colors.darkText : colors.brandGreen)};
  display: inline-flex;
  justify-content: center;
`;

const RestoreButton: FC<{ onClick: () => void }> = ({ ...props }) => (
  <RestoreButtonContainer {...props}>
    <ToolTip tooltip={"Restore Deleted Question"}>
      <MedIcon icon={Restore} />
    </ToolTip>
  </RestoreButtonContainer>
);

const RestoreButtonContainer = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s all ease-in-out;
`;

export { QuestionListItem };
