import { ClassParams } from "..";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BulkAddStudentsModal } from "./BulkAddStudentsModal";
import { UnsavedChangesComponent } from "components";
import { ToolTip } from "primitives";
import { Breadcrumb } from "components/breadcrumb";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { generatePath, Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { createStudentsForClass, fetchClassList } from "slices/classSlice/classListSlice";
import styled from "styled-components";
import { SwapHoriz } from "@styled-icons/material";
import { colors } from "styles";
import { defaultRequestState, FC, StudentName } from "types";
import { dispatchAsync, removeItemAtIndexFromArray, replaceOneElementInArray } from "utils";
import { Column, Row, SecondaryText, TitleText, HorizontalLine, VerticalLine } from "primitives";
import { MedIcon } from "primitives/icons";
import { CreateButtonLarge, DeleteButton, PrimaryButton } from "primitives/button";
import { helpMessages, slugs } from "textConstants";

const AddStudentsToClass: FC = () => {
  const [studentNames, setStudentNames] = useState<StudentName[]>([]);
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const { classId } = useParams<ClassParams>();
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const teacher = useAppSelector((state) => state.login.userInfo);
  const classList = useAppSelector((state) => state.classList.data);
  const classData = useMemo(() => (classList ? Object.values(classList) : []), [classList]);
  const class_ = useAppSelector((state) => (classId && state.classList.data ? state.classList.data[classId] : null));
  const [numStudents, setNumStudents] = useState(-1); //-1 to not allow students to be added before callback
  const [maxStudents, setMaxStudents] = useState(0);

  useEffect(() => {
    if (classList === null) {
      dispatchAsync(dispatch(fetchClassList()), async () => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classList]);

  useEffect(() => {
    if (teacher?.maxStudents) {
      setMaxStudents(teacher?.maxStudents);
    }
  }, [teacher]);

  useEffect(() => {
    let count = 0;
    if (classList) {
      for (const _class of classData) {
        if (_class.studentCount) {
          count += _class.studentCount;
        } else {
          //for newly created classes
          if (_class.students) {
            count += _class.students.length;
          }
        }
      }
      setNumStudents(count + studentNames.length);
    } else {
      setNumStudents(-1);
    }
  }, [classList, studentNames, classData]);

  const redirectToClassList = () => {
    history.push(generatePath(path.replace(`/${slugs.students}`, ""), { classId }));
  };

  const swapFirstAndLastNames = () =>
    setStudentNames(
      studentNames.map(({ firstName, lastName }) => ({
        firstName: lastName,
        lastName: firstName,
      }))
    );

  const updateStudentName = (index: number, update: Partial<StudentName>) => {
    const newName = { ...studentNames[index], ...update };
    setStudentNames(replaceOneElementInArray(studentNames, index, newName));
  };

  const handleSubmit = async () => {
    const { success } = await dispatchAsync(dispatch(createStudentsForClass(studentNames, classId)), setRequestState);

    if (success) {
      redirectToClassList();
    } else {
      alert("Sorry, something went wrong adding these students to the class!");
    }
  };

  const isStudentNumberValid = () => {
    //Yet to fetch total students
    if (numStudents < 0) {
      return false;
    }
    if (maxStudents) {
      return numStudents <= maxStudents;
    }
    return false;
  };

  const headerRow = (
    <TableHeader>
      <Row style={{ flex: 1, alignItems: "center" }}>
        <TableHeaderTitle style={{ flex: 1 }}>First name</TableHeaderTitle>
        <VerticalLine margin={"0em"} color={colors.darkText} />
        <TableHeaderTitle style={{ flex: 1 }}>Last name</TableHeaderTitle>
      </Row>
      <SwapFirstAndLastNamesButton className="swap-names-button" onClick={swapFirstAndLastNames} />
    </TableHeader>
  );

  const editStudentList = (
    <div style={{ height: "100%" }}>
      <StudentAddContainer>
        <HeaderContainer>
          <TitleText style={{ color: colors.darkText }}>Add Students to {class_?.name}</TitleText>
          <PrimaryButton className="import-button" onClick={() => history.push(`${url}/${slugs.add}/${slugs.import}`)}>
            Import Class List
          </PrimaryButton>
        </HeaderContainer>
        <HorizontalLine color={colors.darkText} />
        <SecondaryText style={{ marginBottom: "20px" }}>{helpMessages.addStudents.title}</SecondaryText>
        <ClassListContainer>
          <TableContainer className="student-table">
            {headerRow}
            <StudentNamesContainer className="student-import-table">
              {studentNames.map(({ firstName, lastName }, index) => {
                return (
                  <div key={index}>
                    <RowDivider color={colors.darkText} />
                    <TableRow key={index}>
                      <Row style={{ flex: 1, height: "100%" }}>
                        <NameInput
                          placeholder="First name"
                          value={firstName}
                          onChange={(e) =>
                            updateStudentName(index, {
                              firstName: e.target.value,
                            })
                          }
                        />
                        <VerticalLine margin={"0em"} color={colors.darkText} />

                        <NameInput
                          required={false}
                          placeholder="Last name"
                          value={lastName}
                          onChange={(e) =>
                            updateStudentName(index, {
                              lastName: e.target.value,
                            })
                          }
                        />
                      </Row>
                      <DeleteButton
                        tooltip={"Remove Student"}
                        onClick={() => setStudentNames(removeItemAtIndexFromArray(studentNames, index))}
                        style={{ margin: "9px" }}
                      />
                    </TableRow>
                  </div>
                );
              })}
            </StudentNamesContainer>
            <RowDivider color={colors.darkText} />
            <TableRow>
              <CreateButtonLarge
                tooltip={"Add Student"}
                onClick={() => setStudentNames([...studentNames, { firstName: "", lastName: "" }])}
                style={{ margin: "10px" }}
              />
            </TableRow>
          </TableContainer>
        </ClassListContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <ToolTip
            tooltip={
              `Your account has a maximum of ${maxStudents} students. ` + helpMessages.addStudents.maxStudentsTooltip
            }
          >
            <SecondaryText
              style={{
                marginBottom: "20px",
                width: "300px",
                color: `${isStudentNumberValid() ? colors.secondary : colors.danger}`,
                textAlign: "right",
              }}
            >
              {numStudents < 0 ? "-" : numStudents}/{maxStudents} Total Students Allowed
            </SecondaryText>
          </ToolTip>
        </div>
      </StudentAddContainer>
      <UnsavedChangesComponent
        hasUnsavedChanges={studentNames.length !== 0}
        canApplyChanges={
          isStudentNumberValid() &&
          (studentNames.every(({ firstName }) => firstName.trim()) || studentNames.length === 0)
        }
        applyChanges={() => handleSubmit()}
        loadingChanges={loading}
        revertChanges={() => setStudentNames([])}
      />
    </div>
  );

  if (classList && !class_) {
    return (
      <Route path={url}>
        <Redirect to={`/${slugs.teacher}/${slugs.class}`} />
      </Route>
    );
  }

  return (
    <>
      <Breadcrumb to={url.replace(`/${slugs.students}`, "")}>
        <BreadcrumbContent>
          <p>{class_?.name}</p>
        </BreadcrumbContent>
      </Breadcrumb>
      <Breadcrumb to={url}>
        <BreadcrumbContent>
          <p>Add Students</p>
        </BreadcrumbContent>
      </Breadcrumb>
      <OuterContainer>
        <Switch>
          <Route path={`${url}/${slugs.add}/${slugs.import}`}>
            <BulkAddStudentsModal
              studentNames={studentNames.map((student) => `${student.firstName} ${student.lastName}`)}
              setStudentNames={setStudentNames}
              onHidden={() => history.push(`${url}/${slugs.add}`)}
            />
          </Route>
          <Route path={`${url}/${slugs.add}`}>{editStudentList}</Route>
          <Route path={url}>
            <Redirect to={`${url}/${slugs.add}`} />
          </Route>
        </Switch>
      </OuterContainer>
    </>
  );
};

const OuterContainer = styled(Column)`
  width: 100%;
  height: 100%;
  align-items: center;
`;

const StudentAddContainer = styled.div`
  width: 60em;
  flex: 100%;
  height: 90%;
  padding-top: 50px;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwapFirstAndLastNamesButtonContainer = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s all ease-in-out;
  :hover {
    background-color: ${colors.brandDarkGreen};
  }
`;

const SwapFirstAndLastNamesButton: FC = (props) => (
  <ToolTip tooltip={"Swap First and Last Names"}>
    <SwapFirstAndLastNamesButtonContainer {...props}>
      <MedIcon icon={SwapHoriz} color={colors.white} />
    </SwapFirstAndLastNamesButtonContainer>
  </ToolTip>
);

const ClassListContainer = styled(Column)`
  width: 100%;
  overflow: auto;
`;

const TableContainer = styled.div`
  border: 1px solid ${colors.darkText};
`;

const TableHeader = styled.div`
  background-color: ${colors.brandGreen};
  display: flex;
  align-items: center;
`;

const TableHeaderTitle = styled.p`
  flex: 1;
  color: white;
  font-weight: 600;
  height: 4em;
  display: flex;
  padding: 0em 1em;
  align-items: center;
`;

const TableRow = styled(Row)`
  height: 4em;
  align-items: center;
`;

const RowDivider = styled(HorizontalLine)`
  margin: 0px !important;
`;

const StudentNamesContainer = styled.div`
  max-height: 100%;
`;

interface NameInputProps {
  value: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  required?: boolean;
}

const NameInput: FC<NameInputProps> = ({ value, onChange, placeholder, required = true }) => (
  <div style={{ flex: 1 }}>
    <Form.Control
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isInvalid={
        (value && value?.length > 256) ||
        (required && !Boolean(value?.trim())) || //must have value
        (Boolean(value) && !Boolean(value?.trim())) //Check for just whitespace
      }
      style={{
        flex: 1,
        height: "100%",
        border: "transparent",
      }}
    />
  </div>
);

const BreadcrumbContent = styled.div`
  p {
    max-width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export { AddStudentsToClass };
