import { Center, Spinner } from "primitives";
import styled from "styled-components";
import { FC, ImageSize } from "types";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface Props {
  imageUrl: string;
}

const SelectableImage: FC<Props> = ({ imageUrl, ...rest }) => {
  return (
    <OuterContainer {...rest}>
      <LazyLoadImage
        src={imageUrl + ImageSize.Small}
        alt={imageUrl}
        placeholder={<Spinner />}
        height="80%"
        width="80%"
      />
    </OuterContainer>
  );
};

const OuterContainer = styled(Center)`
  width: 215px;
  height: 215px;
  margin: 10px;
  border-radius: 10px;
  border: 2px solid #dae0e5;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
  > * {
    transition: 0.1s all ease-in-out;
  }
  :hover {
    > * {
      width: 90%;
      height: 90%;
      filter: drop-shadow(0 0 0.2rem yellow) drop-shadow(0 0 0.4rem orange);
    }
  }
`;

export { SelectableImage };
