import { ConfirmModalInfo, useConfirmModal, useExitPrompt, PromptMessageProp } from "hooks";
import { isFunction } from "lodash";
import { FC, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  when: boolean;
  message?: PromptMessageProp;
  modalInfo?: Partial<ConfirmModalInfo>;
  blockSearchChanges?: boolean;
}

const defaultInfo: ConfirmModalInfo = {
  body: ["You have unsaved changes, are you sure you want to leave?"],
};

// Similar to the React Router <Prompt /> but uses our Confirm Modal,
// and works when the page refreshes (which Prompt does not)
export const PageNavigationPrompt: FC<Props> = ({ when, message, modalInfo, blockSearchChanges }) => {
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();
  const history = useHistory();
  const unblockRef = useRef<ReturnType<typeof history.block>>();
  const { pathname } = useLocation();
  useExitPrompt(when, message);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when && (blockSearchChanges || location.pathname !== pathname)) {
        const myMessage = isFunction(message) ? message(location) : message;
        if (myMessage !== true) {
          setModalInfo({
            ...defaultInfo,
            body: [myMessage || ""],
            onModalConfirm: async () => {
              history.block(() => {});
              history.push(location);
            },
            ...modalInfo,
          });

          setModalVisible(true);
          return false;
        }
      }

      unblockRef.current && unblockRef.current();
      history.push(location);
      return;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
    // eslint-disable-next-line
  }, [when]);

  return <Modal />;
};
