import { FC } from "react";
import styled from "styled-components";
import { Column, Row, Spacer, ToolTip } from "primitives";
import { MedIcon, SmallIcon } from "primitives/icons";
import { colors } from "styles";
import { Class, Session } from "types";
import { LoadingContent, StudentGridKeyColour } from "components";
import { Names, ReviewConstants } from "textConstants";
import { getPreviewTotalQuestions, getPreviewResultsForSessions } from "utils/sessionUtils";

interface Props {
  sessionsLoading: boolean;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  totalLessons: number;
  sessions?: Session[];
  selectedClass?: Class;
}

const ReviewSessionsSidebarInfo: FC<Props> = ({
  sessionsLoading,
  onSelectAll,
  onDeselectAll,
  totalLessons,
  sessions,
  selectedClass,
}) => {
  const sessionResults = getPreviewResultsForSessions(selectedClass, sessions);
  const sessionAverage = sessionResults.participationCount
    ? sessionResults.totalScore / sessionResults.participationCount
    : 0;

  return (
    <Column>
      <HeaderContainer>
        <Row style={{ alignItems: "center" }}>
          <MedIcon icon={ReviewConstants.lessonsIcon} />
          <HeaderText>{`${Names.Sessions} (${totalLessons})`}</HeaderText>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <LinkText onClick={onSelectAll}>Select All</LinkText>
          <Spacer size={10} />
          <LinkText onClick={onDeselectAll}>Deselect All</LinkText>
        </Row>
      </HeaderContainer>
      <InfoContainer>
        <LoadingContent loading={sessionsLoading}>
          <SpacedRows>
            <Row>
              <p>Selected {Names.Sessions}:</p>
              <Spacer size={5} />
              <ValueText>{sessions ? sessions.length : 0}</ValueText>
            </Row>
            <Row>
              <p>{Names.Session} Completion:</p>
              <Spacer size={5} />
              <ValueText>{`${sessionResults.participationCount}/${sessionResults.totalPossibleParticipation}`}</ValueText>
            </Row>
            <Row>
              <p>Total Questions:</p>
              <Spacer size={5} />
              <ValueText>{getPreviewTotalQuestions(sessions)}</ValueText>
            </Row>
            <Row>
              <ToolTip tooltip={ReviewConstants.averageScoreTooltip}>
                <>
                  <p>Average Score:</p>
                  <Spacer size={5} />
                  <ScoreArea>
                    <StudentGridKeyColour fill={ReviewConstants.GetColorForResult(sessionAverage)} />
                    <ValueText>{`${Math.floor(sessionAverage * 100)}%`}</ValueText>
                  </ScoreArea>
                  <Column className="pl-1 justify-content-center">
                    <SmallIcon icon={ReviewConstants.infoIcon}></SmallIcon>
                  </Column>
                </>
              </ToolTip>
            </Row>
          </SpacedRows>
        </LoadingContent>
      </InfoContainer>
    </Column>
  );
};

const ScoreArea = styled(Row)`
  align-items: center;
  gap: 5px;
  margin-left: auto;
`;

const SpacedRows = styled(Column)`
  gap: var(--sp-1);
`;

const HeaderContainer = styled(Row)`
  justify-content: space-between;
  height: 50px;
  padding: 0px 20px;
`;

const InfoContainer = styled(Column)`
  background-color: ${colors.sessionSelect};
  padding: 10px 20px;
`;

const HeaderText = styled.p`
  font-size: 14pt;
  font-weight: 600;
  line-height: 21px;
`;

const ValueText = styled.p`
  opacity: 60%;
  margin-left: auto;
`;

const LinkText = styled(Column)`
  cursor: pointer;
  color: ${colors.primary};
  user-select: none;
`;

export { ReviewSessionsSidebarInfo };
