import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ArticleMenuPortalState {
  portalReady: boolean;
  portalOpen: boolean;
  showToggle: boolean;
}

const initialState: ArticleMenuPortalState = { portalReady: false, portalOpen: false, showToggle: false };

export const articleMenuPortalSlice = createSlice({
  name: "articleMenuPortal",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<boolean>) => {
      state.portalReady = action.payload;
    },
    setPortalOpen: (state, action: PayloadAction<boolean>) => {
      state.portalOpen = action.payload;
    },
    setShowToggle: (state, action: PayloadAction<boolean>) => {
      state.showToggle = action.payload;
    },
  },
});

export const {
  set: setArticleMenuPortalNode,
  setPortalOpen: setArticleMenuState,
  setShowToggle: setArticleToggleMenuState,
} = articleMenuPortalSlice.actions;

export const articleMenuPortalReducer = articleMenuPortalSlice.reducer;
