import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { ServerPronunciationSet } from "types";

export const deletePronunciationSet = async (pronunciationSetId: string, token: string) => {
  const response = await api.delete(`/pronunciationsets/${pronunciationSetId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data) {
    return response.data as ServerPronunciationSet;
  } else {
    throw new NetworkError();
  }
};
