import { Spinner } from "primitives";
import { FC } from "react";

interface Props {
  loading: boolean;
  success?: boolean;
  error?: string | null;
}

const LoadingContent: FC<Props> = ({ loading, error, success = true, children }) => {
  if (loading) {
    return <Spinner />;
  }
  if (error || !success) {
    return <p>Sorry, something went wrong!</p>;
  }

  return <>{children}</>;
};

export { LoadingContent };
