import { FC } from "types";
import { useArticleMenu } from "hooks";
import { articleInfoIcon, PlatformTitle } from "textConstants";
import { MedIcon } from "primitives/icons";
import { BreadcrumbMenu } from "./BreadcrumbMenu";

interface Props {
  id: string;
}

export const ArticleMenu: FC<Props> = ({ id }) => {
  const { portalOpen, showToggle, setPortalOpen } = useArticleMenu();

  return (
    <BreadcrumbMenu
      className="article-button"
      id={id}
      portalOpen={portalOpen}
      setPortalOpen={setPortalOpen}
      toggleChildren={<MedIcon icon={articleInfoIcon} />}
      toggleShowing={showToggle}
      dropdownMenuTitle={`${PlatformTitle} Academy`}
      dropdownMenuChildren={<></>}
    />
  );
};
