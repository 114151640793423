import { Class, Session, Tag, Teacher } from "types";
import { ProcessCustomGame, UnprocessedCustomGame } from "./customGame";

export interface UnprocessedSession {
  id: string;
  name: string;
  sessionCode: string;
  startTime: string | null;
  endTime: string | null;
  publicAccess: boolean;
  maxAttempts: number | null;
  createdBy: Teacher;
  class: Class;
  isPublic: boolean;
  customGames: UnprocessedCustomGame[] | null;
  totalQuestions?: number;
  totalScore?: number;
  participationCount?: number;
  tags?: Tag[];
}

export const ProcessSession = (session: UnprocessedSession): Session => {
  const {
    id,
    name,
    sessionCode,
    startTime,
    endTime,
    publicAccess,
    maxAttempts,
    createdBy,
    class: class_,
    isPublic,
    customGames,
    totalQuestions,
    totalScore,
    participationCount,
    tags,
  } = session;

  return {
    id,
    name,
    sessionCode,
    startTime,
    endTime,
    publicAccess,
    maxAttempts,
    createdBy,
    class: class_,
    isPublic,
    customGames: customGames ? customGames.map((customGame) => ProcessCustomGame(customGame)) : null,
    totalQuestions,
    totalScore,
    participationCount,
    tags,
  };
};
