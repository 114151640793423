import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Article, defaultRequestState, ImageSize } from "types";
import { fetchArticles, fetchAllPreviewArticles } from "slices";
import { dispatchAsync } from "utils";
import { ImagePreview, LoadingContent } from "components";
import { Column, HorizontalLine, Row, Spacer } from "primitives";
import { useParams } from "react-router-dom";
import { AcademyParams } from "../AcademyRoutes";
import styled from "styled-components";
import { colors } from "styles";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";
import Grid from "assets/Grid.png";
import { Breadcrumb } from "components/breadcrumb";
import { LargeIcon } from "primitives/icons";
import { breadcrumbConstants, paths } from "textConstants";

const ArticleOverview: FC = () => {
  const [{ loading, error, success }, setRequestState] = useState(defaultRequestState);
  const articleList = useAppSelector((state) => (state.articleList.data ? Object.values(state.articleList.data) : []));
  const hasFetchedArticles = useAppSelector((state) => state.articleList.fetchedPreviews);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { articleId } = useParams<AcademyParams>();

  const rootArticle =
    articleList &&
    articleList.find((article) => {
      return article.id === articleId;
    });

  useEffect(() => {
    if (!hasFetchedArticles) {
      dispatchAsync(dispatch(fetchAllPreviewArticles()), setRequestState);
    } else {
      setRequestState({ loading: false, error: null, success: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!rootArticle || !rootArticle.body) {
      dispatchAsync(dispatch(fetchArticles({ id: articleId })), setRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId]);

  const shownRecommendedArticles = 3;
  const relatedArticles: Partial<Article>[] | undefined =
    articleList &&
    rootArticle &&
    articleList
      .filter((checkArticle) => {
        return checkArticle?.category?.name === rootArticle?.category?.name && checkArticle?.id !== rootArticle?.id;
      })
      .slice(0, shownRecommendedArticles);

  const onRecommendArticleClick = (article: Partial<Article>) => {
    if (article.id) {
      history.push(paths.teacher.academy.article.generate(article));
    }
  };

  return (
    <LoadingContent loading={loading} error={error} success={success}>
      <Breadcrumb>
        <b>{rootArticle?.title}</b>
      </Breadcrumb>
      {rootArticle ? (
        <GridContainer>
          <ArticleContainer>
            <VideoArea>
              {rootArticle.videoURL ? (
                <ReactPlayer style={{ height: "100%", width: "100%", margin: "0" }} url={rootArticle.videoURL} />
              ) : (
                rootArticle.imageURL && (
                  <ImagePreview
                    imageUrl={rootArticle.imageURL}
                    loadedImageSize={ImageSize.Raw}
                    style={{ width: "auto", height: "100%", border: "0" }}
                    imageSize={"100%"}
                  />
                )
              )}
            </VideoArea>
            <TextArea>
              <TitleText>{rootArticle?.title && rootArticle?.title}</TitleText>
              {rootArticle?.body && <BodyArea dangerouslySetInnerHTML={{ __html: rootArticle?.body }} />}
              <Spacer size={30} />
            </TextArea>
            <HorizontalLine />
            <RecommendedTitleText>{"What's Next?"}</RecommendedTitleText>
            <RecommendedContainer>
              {relatedArticles &&
                relatedArticles.map((article) => {
                  return (
                    <ArticlePreviewContainer
                      key={article.id}
                      onClick={() => {
                        onRecommendArticleClick(article);
                      }}
                    >
                      <RecommendedImageContainer style={{ border: !article.imageURL ? "1px solid black" : "" }}>
                        {article.imageURL ? (
                          <ImagePreview
                            imageUrl={article.imageURL}
                            loadedImageSize={ImageSize.Raw}
                            style={{ width: "100%", height: "100%", border: "0" }}
                            imageSize={"100%"}
                          />
                        ) : (
                          <LargeIcon icon={breadcrumbConstants.academy.icon} />
                        )}
                      </RecommendedImageContainer>
                      <RecommendedTitle>{article.title}</RecommendedTitle>
                    </ArticlePreviewContainer>
                  );
                })}
            </RecommendedContainer>
            <Spacer size={30} />
          </ArticleContainer>
        </GridContainer>
      ) : (
        <p>Something seems to have gone wrong</p>
      )}
    </LoadingContent>
  );
};

const GridContainer = styled(Row)`
  width: 100%;
  background-image: url(${Grid});
  justify-content: center;
`;

const VideoArea = styled(Row)`
  justify-content: center;
  background-color: ${colors.white};
`;

const TextArea = styled(Column)`
  width: 100%;
  height: 100%;
  padding: 0px 35px;
  background-color: ${colors.white};
`;

const BodyArea = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 1000px;
  overflow-y: clip;
`;

const ArticleContainer = styled(Column)`
  height: 100%;
  margin-bottom: 100px;
  margin-left: 100px;
  margin-right: 100px;
  overflow-y: auto;
  max-width: 1160px;
  min-width: 800px;
`;

const RecommendedContainer = styled(Row)`
  max-height: 250px;
  justify-content: space-around;
  background-color: ${colors.white};
`;

const ArticlePreviewContainer = styled(Column)`
  width: 200px;
  cursor: pointer;
`;

const TitleText = styled(Row)`
  font-size: 24px;
  font-weight: 600;
  line-height: 33.59px;
  padding: 30px 0px;
`;

const RecommendedImageContainer = styled(Row)`
  justify-content: center;
  width: 100%;
  height: 150px;
`;

const RecommendedTitleText = styled(TitleText)`
  color: ${colors.tabSelectedGrey};
  background-color: ${colors.white};
`;

const RecommendedTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
`;

export { ArticleOverview };
