import { useGradeTags, useQueryParams } from "hooks";
import { DropdownText, PrimaryDropdown, StyledDropdownItem } from "primitives";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { QuestionSet, Tag, FC } from "types";

export interface Props {
  gradeTags: Array<{ tag: Tag; selected: boolean }>;
  setGradeTags: (tags: Array<{ tag: Tag; selected: boolean }>) => void;
  useShortNames?: boolean;
  singleSelect?: boolean;
}

const GradeTagsSelector: FC<Props> = ({ gradeTags, setGradeTags, useShortNames, singleSelect, ...rest }) => {
  const toggleGradeTag = (tagId: string) => {
    setGradeTags(
      gradeTags.map(({ tag, selected }) => {
        if (tag.id === tagId) {
          return { tag, selected: !selected };
        } else if (singleSelect) {
          return { tag, selected: false };
        }
        return { tag, selected };
      })
    );
  };

  const clearGradeTags = () => {
    setGradeTags(
      gradeTags.map((tag) => {
        return { ...tag, selected: false };
      })
    );
  };

  const selectedTags = gradeTags.filter((tag) => tag.selected);

  return (
    <PrimaryDropdown toggleText={selectedTags.map((tag) => tag.tag.name).join(", ") || "None"}>
      <Scrollable>
        <StyledDropdownItem active={selectedTags.length === 0} className="borderless-dropdown" onClick={clearGradeTags}>
          <DropdownText>None</DropdownText>
        </StyledDropdownItem>
        {gradeTags &&
          gradeTags.map((tag, index) => (
            <StyledDropdownItem
              className="borderless-dropdown"
              onClick={() => toggleGradeTag(tag.tag.id)}
              key={index}
              active={tag.selected}
            >
              <DropdownText>{tag.tag.name}</DropdownText>
            </StyledDropdownItem>
          ))}
      </Scrollable>
    </PrimaryDropdown>
  );
};

interface UseGradeTagSelectorParams {
  onChange?: (gradeTags: Tag[]) => void;
  questionSet?: QuestionSet | undefined;
}

const useGradeTagsSelector = ({ onChange, questionSet }: UseGradeTagSelectorParams) => {
  const didMount = useRef(false);
  const { data: gradeTags, success: gradeTagsSuccess } = useGradeTags({
    fetch: true,
  });
  const [selectedGradeTagsStatus, setSelectedGradeTagsStatus] = useState<Array<{ tag: Tag; selected: boolean }>>([]);

  const {
    params: { gradeTags: queryGradeTags },
  } = useQueryParams<{ gradeTags: string }>();

  // Temp - resets buttons if query string has no tags (undefined)
  // TODO: Make this actually update to the selected tags, not clear tags
  useEffect(() => {
    if (gradeTags && !queryGradeTags && !questionSet) {
      setSelectedGradeTagsStatus(
        gradeTags.map((tag) => ({
          tag,
          selected: false,
        }))
      );
    }
  }, [queryGradeTags, gradeTags, questionSet]);

  useEffect(() => {
    if (gradeTags && gradeTagsSuccess && !selectedGradeTagsStatus.length) {
      setSelectedGradeTagsStatus(
        gradeTags.map((tag) => ({
          tag,
          selected: questionSet ? Boolean(questionSet.tags.find((t) => t.id === tag.id)) : false,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeTags, gradeTagsSuccess]);

  const gradeSelectorProps = {
    gradeTags: selectedGradeTagsStatus,
    setGradeTags: setSelectedGradeTagsStatus,
  };

  const selectedGradeTags = selectedGradeTagsStatus.filter(({ selected }) => selected).map(({ tag }) => tag);
  const selectedGradeTagIds = selectedGradeTags.map((t) => t.id);

  const setSelectedGradeTagIds = (gradeTags: Array<string>) => {
    setSelectedGradeTagsStatus(
      selectedGradeTagsStatus.map(({ tag }) => ({
        tag,
        selected: gradeTags.includes(tag.id),
      }))
    );
  };

  useEffect(
    () => {
      if (didMount.current) {
        onChange && onChange(selectedGradeTags);
      } else {
        didMount.current = true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGradeTags]
  );

  return {
    allGradeTags: gradeTags,
    GradeTagsSelector,
    gradeSelectorProps,
    selectedGradeTagsStatus,
    setSelectedGradeTagIds,
    selectedGradeTagIds,
    selectedGradeTags,
  };
};

const Scrollable = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

export { useGradeTagsSelector };
