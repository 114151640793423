import { CustomGame, Session } from "types";

const getTotalCustomGameQuestionCount = (customGame: CustomGame) => {
  let total = 0;
  if (customGame.questionSets) {
    total = customGame.questionSets.reduce((a, b) => a + b.numQuestions || 0, total);
  }

  // if (customGame.settings?.multiplayerSettings?.maxPlayers) {
  //   if (customGame.settings.questionCount) {
  //     return customGame.settings.questionCount;
  //   } else {
  //     return Math.floor(total / customGame.settings.multiplayerSettings.maxPlayers);
  //   }
  // }

  return total;
};

const getPlayerCountRange = (session: Session) => {
  let min = 10;
  let max = 0;

  if (session.customGames) {
    for (let customGame of session.customGames) {
      if (customGame?.settings?.multiplayerSettings) {
        if (customGame.settings.multiplayerSettings.minPlayers < min) {
          min = customGame.settings.multiplayerSettings.minPlayers;
        }
        if (customGame.settings.multiplayerSettings.maxPlayers > max) {
          max = customGame.settings.multiplayerSettings.maxPlayers;
        }
      }
    }
  }

  if (min === 10) {
    return "1";
  } else if (min === max) {
    return `${min}`;
  } else {
    return `${min}-${max}`;
  }
}

export { getTotalCustomGameQuestionCount, getPlayerCountRange };
