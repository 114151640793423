import { FC } from "types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { useBreadcrumbs } from "hooks";
import { colors, layoutConstants } from "styles";
import { Row } from "primitives";
import { MedIcon } from "primitives/icons";
import { ChevronRight } from "@styled-icons/material";
import { Colorable } from "utils/Props";
import { MediaConstants } from "utils/mediaConstants";

interface Props {
  to?: string;
  description?: string;
  root?: boolean;
  color?: string;
}

interface IsRoot {
  isRoot: boolean;
}

const Breadcrumb: FC<Props> = ({ to, description, root = false, color, children, ...rest }) => {
  const { portalNode, accentColor, highlightColor } = useBreadcrumbs();

  return portalNode
    ? ReactDOM.createPortal(
        <OuterContainer {...rest}>
          <InnerContainer className="breadcrumb-title">
            <ActiveBreadcrumb isRoot={root} color={color || accentColor}>
              {children}
            </ActiveBreadcrumb>
            <InactiveBreadcrumb color={color || accentColor}>
              {to ? (
                <ItemLink to={to} $color={accentColor}>
                  {children}
                </ItemLink>
              ) : (
                children
              )}
            </InactiveBreadcrumb>
            <DescriptionText>{Boolean(description) && description}</DescriptionText>
          </InnerContainer>
          <Divider $color={highlightColor}>
            <MedIcon icon={ChevronRight} color={highlightColor} />
          </Divider>
        </OuterContainer>,
        portalNode
      )
    : null;
};

const Divider = styled.div<Colorable>`
  margin 10px;
  color: ${(props) => props.$color};
`;

const DescriptionText = styled.p`
  color: ${colors.secondary};
  white-space: nowrap;
  margin-left: 2.5em;
`;

const StyledBreadcrumb = styled.div`
  align-items: center;
  padding: 5px 10px;
  p {
    font-weight: 600;
  }
`;

const ActiveBreadcrumb = styled(StyledBreadcrumb)<IsRoot>`
  border-radius: 5px;
  background-color: ${(props) => (props.isRoot ? "unset" : props.color)};
  color: ${(props) => (props.isRoot ? props.color : colors.white)};
`;

const InactiveBreadcrumb = styled(StyledBreadcrumb)`
  color: ${(props) => props.color};
`;

const InnerContainer = styled(Row)`
  align-items: center;
`;

// This class uses css selectors to hide
// elements in the breadcrumb that we don't want to see.
// This means: Don't show the first and last Divider, and
// only show the last Description.
const OuterContainer = styled(Row)`
  height: ${layoutConstants.breadcrumbHeight};
  align-items: center;
  justify-content: center;
  @media ${MediaConstants.tablet} {
    font-size: 8pt;
  }

  ${Divider} {
    display: flex;
  }
  ${InactiveBreadcrumb} {
    display: flex;
  }

  ${DescriptionText} {
    display: none;
  }
  ${ActiveBreadcrumb} {
    display: none;
  }

  :last-child {
    ${Divider} {
      display: none;
    }
    ${InactiveBreadcrumb} {
      display: none;
    }

    ${DescriptionText} {
      display: flex;
    }
    ${ActiveBreadcrumb} {
      display: flex;
    }
  }

  :first-child {
    ${Divider} {
      display: none;
    }
  }
`;

const TitleText = styled.p<Colorable>`
  color: ${(props) => props.color};
  margin: auto 5px;
`;

const ItemLink = styled(NavLink).attrs({ exact: true })<Colorable>`
  color: ${(props) => props.$color};
  :hover {
    color: ${(props) => props.$color};
  }
`;

export {
  Breadcrumb,
  ActiveBreadcrumb,
  InactiveBreadcrumb,
  ItemLink as BreadcrumbItemLink,
  TitleText as BreadcrumbTitleText,
};
