import { generatePath } from "react-router-dom";
import { Session, Class, PronunciationSet, Article } from "types";
import { uuidRegexString } from "utils/regexStrings";

const slugs = {
  teacher: "teacher",
  myGames: "my-games",
  progress: "results",
  class: "class",
  classId: `:classId(${uuidRegexString})`,
  session: "session",
  sessionId: `:sessionId(${uuidRegexString})`,
  sessionKey: `:sessionKey`,
  questionSetId: `:questionSetId(${uuidRegexString})`,
  pronunciationSetId: ":pronunciationSetId",
  showQR: "show-pin",
  preview: "preview",
  duplicate: "duplicate",
  add: "add",
  import: "import",
  edit: "edit",
  adjust: "adjust",
  settings: "settings",
  miniGame: "custom-game",
  miniGameId: `:customGameId(${uuidRegexString})`,
  miniGameIdParam: "customGameId",
  new: "new",
  ttsSettings: "ttsSettings",
  academy: "academy",
  articleId: `:articleId(${uuidRegexString})`,
  share: "share",
  questionSet: "question-set",
  public: "public",
  questions: "questions",
  game: "game",
  grouping: "partition",
  groupingId: `:groupingId(${uuidRegexString})?`,
  delete: "delete",
  student: "student",
  students: "students",
  studentId: `:studentId(${uuidRegexString})`,
  login: "login",
  rules: "rules",
  review: "review",
  start: "start",
  register: "register",
  print: "print",
  gamelibrary: "game-library",
};

const teacherPath = `/${slugs.teacher}`;

const loginPath = `/${slugs.login}`;
const studentLoginPath = `${loginPath}/${slugs.student}`;
const teacherLoginPath = `${loginPath}/${slugs.teacher}`;

//Questions
const questionsPath = `${teacherPath}/${slugs.questions}`;
const previewQuestionSetPath = `${questionsPath}/${slugs.questionSet}/${slugs.questionSetId}`;
const editQuestionSetPath = `${questionsPath}/${slugs.questionSet}/${slugs.questionSetId}/${slugs.edit}`;
const addQuestionSetToNewGamePath = `${questionsPath}/${slugs.questionSet}/${slugs.questionSetId}/${slugs.session}/${slugs.new}`;
const useQuestionSetInNewMiniGamePath = `${questionsPath}/${slugs.questionSet}/${slugs.questionSetId}/${slugs.miniGame}/${slugs.new}/${slugs.game}`;

//My Games
const myGamesPath = `${teacherPath}/${slugs.myGames}`;
const newMinigamePath = `${myGamesPath}/${slugs.miniGame}/${slugs.new}`;
const miniGameQRPath = `${myGamesPath}/${slugs.miniGame}/${slugs.miniGameId}/${slugs.showQR}`;
const newSessionPath = `${myGamesPath}/${slugs.class}/${slugs.classId}/${slugs.session}/${slugs.new}`;
const sessionPath = `${myGamesPath}/${slugs.class}/${slugs.classId}/${slugs.session}/${slugs.sessionId}`;
const sessionPreviewPath = `${sessionPath}`;
const sessionDuplicatePath = `${sessionPath}/${slugs.duplicate}`;
const sessionQRPath = `${sessionPath}/${slugs.showQR}`;
const sessionEditPath = `${sessionPath}/${slugs.edit}`;
const sessionEditSettingsPath = `${sessionEditPath}/${slugs.settings}`;
const sessionEditAddMinigamePath = `${sessionEditPath}/${slugs.miniGame}`;
const sessionAdjustPath = `${sessionPath}/${slugs.adjust}`;
const sessionSettingsPath = `${sessionPath}/${slugs.settings}`;

//Progress
const progressPath = `${teacherPath}/${slugs.progress}`;
const progressSessionPath = `${progressPath}/${slugs.class}/${slugs.classId}`;

//Class
const newClassPath = `${teacherPath}/${slugs.class}/${slugs.new}`;

//Grouping
const newGroupingPath = `${teacherPath}/${slugs.class}/${slugs.classId}/${slugs.grouping}`;
const editGroupingPath = `${teacherPath}/${slugs.class}/${slugs.classId}/${slugs.grouping}/${slugs.groupingId}`;
const deleteGroupingPath = `${teacherPath}/${slugs.class}/${slugs.classId}/${slugs.grouping}/${slugs.groupingId}/${slugs.delete}`;

//Settings
const teacherSettingsPath = `${teacherPath}/${slugs.settings}`;
const teacherTTSSettingsPath = `${teacherSettingsPath}/${slugs.ttsSettings}`;
const newPronunciationPath = `${teacherTTSSettingsPath}/${slugs.new}`;
const duplicatePronunciationPath = `${teacherTTSSettingsPath}/${slugs.new}/${slugs.pronunciationSetId}`;
const editPronunciationPath = `${teacherTTSSettingsPath}/${slugs.edit}/${slugs.pronunciationSetId}`;
const previewPronunciationPath = `${teacherTTSSettingsPath}/${slugs.preview}/${slugs.pronunciationSetId}`;

//Academy
const teacherAcademyPath = `${teacherPath}/${slugs.academy}`;
const teacherAcademyArticlePath = `${teacherAcademyPath}/${slugs.articleId}`;

//Public
const publicQuestionSetPreviewPath = `/${slugs.public}/${slugs.questionSet}/${slugs.questionSetId}/${slugs.preview}`;

export function pathUntil(path: string, slug: string, pathIfNotFound?: string) {
  if (path.includes(slug)) {
    return path.substring(0, path.lastIndexOf(slug) + slug.length);
  }
  return pathIfNotFound || path;
}

export function urlUntil(path: string, slug: string, params: {}) {
  return generatePath(pathUntil(path, slug), params);
}

const generateQuestionSetPath = (path: string) => {
  return (questionSetId: string) => {
    return generatePath(path, { questionSetId: questionSetId });
  };
};

const generateSessionPath = (path: string) => {
  return (session: Session) => {
    return generatePath(path, { classId: session.class.id, sessionId: session.id });
  };
};

const generateClassPath = (path: string) => {
  return (aClass: Class) => {
    return generatePath(path, { classId: aClass.id });
  };
};

const generateGroupingPath = (path: string) => {
  return (groupingId: string, classId: string) => {
    return generatePath(path, { groupingId, classId });
  };
};

const generatePronunciationPath = (path: string) => {
  return (pronunciation?: PronunciationSet) => {
    return generatePath(path, { pronunciationSetId: pronunciation?.id || "" });
  };
};

const generateArticlePath = (path: string) => {
  return (article: Partial<Article>) => {
    return generatePath(path, { articleId: article.id || "" });
  };
};

const paths = {
  public: {
    questionSets: {
      preview: {
        path: publicQuestionSetPreviewPath,
        generate: generateQuestionSetPath(publicQuestionSetPreviewPath),
      },
    },
  },
  teacher: {
    questions: {
      path: questionsPath,
      questionSets: {
        preview: {
          path: previewQuestionSetPath,
          generate: generateQuestionSetPath(previewQuestionSetPath),
        },
        addToGame: {
          path: addQuestionSetToNewGamePath,
          generate: generateQuestionSetPath(addQuestionSetToNewGamePath),
        },
        newMiniGame: {
          path: useQuestionSetInNewMiniGamePath,
          generate: generateQuestionSetPath(useQuestionSetInNewMiniGamePath),
        },
        edit: {
          path: editQuestionSetPath,
          generate: generateQuestionSetPath(editQuestionSetPath),
        },
      },
    },
    myGames: {
      path: myGamesPath,
      miniGame: {
        new: {
          path: newMinigamePath,
        },
        showQR: {
          path: miniGameQRPath,
          generate: (customGameId: string) => generatePath(miniGameQRPath, { customGameId }),
        },
      },
      session: {
        new: {
          path: newSessionPath,
          generate: generateClassPath(newSessionPath),
        },
        duplicate: {
          path: sessionDuplicatePath,
          generate: generateSessionPath(sessionDuplicatePath),
        },
        preview: {
          path: sessionPreviewPath,
          generate: generateSessionPath(sessionPreviewPath),
        },
        settings: {
          path: sessionSettingsPath,
          generate: generateSessionPath(sessionSettingsPath),
        },
        showQR: {
          path: sessionQRPath,
          generate: generateSessionPath(sessionQRPath),
        },
        edit: {
          path: sessionEditPath,
          generate: generateSessionPath(sessionEditPath),
          settings: {
            path: sessionEditSettingsPath,
            generate: generateSessionPath(sessionEditSettingsPath),
          },
          miniGame: {
            new: {
              path: sessionEditAddMinigamePath,
              generate: generateSessionPath(sessionEditAddMinigamePath),
            },
          },
        },
        adjust: {
          path: sessionAdjustPath,
          generate: generateSessionPath(sessionAdjustPath),
        },
        start: {
          generate: generateSessionPath(`${sessionPath}/${slugs.start}`),
        },
      },
    },
    progress: {
      session: {
        path: progressSessionPath,
        generate: (session: Session) => `${progressSessionPath}?withSession=${session.id}`,
      },
    },
    class: {
      new: {
        path: newClassPath,
      },
      grouping: {
        new: {
          path: newGroupingPath,
          generate: generateClassPath(newGroupingPath),
        },
        edit: {
          path: editGroupingPath,
          generate: generateGroupingPath(editGroupingPath),
        },
        delete: {
          path: deleteGroupingPath,
          generate: generateGroupingPath(deleteGroupingPath),
        },
      },
    },
    settings: {
      ttsSettings: {
        new: {
          path: newPronunciationPath,
        },
        duplicate: {
          path: duplicatePronunciationPath,
          generate: generatePronunciationPath(duplicatePronunciationPath),
        },
        edit: {
          path: editPronunciationPath,
          generate: generatePronunciationPath(editPronunciationPath),
        },
        preview: {
          path: previewPronunciationPath,
          generate: generatePronunciationPath(previewPronunciationPath),
        },
      },
    },
    academy: {
      home: {
        path: teacherAcademyPath,
      },
      article: {
        path: teacherAcademyArticlePath,
        generate: generateArticlePath(teacherAcademyArticlePath),
      },
    },
  },
  login: {
    student: {
      path: studentLoginPath,
    },
    teacher: {
      path: teacherLoginPath,
    },
  },
};

export { paths, slugs };
