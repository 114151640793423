import { MedIcon } from "primitives/icons";
import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Search } from "@styled-icons/material";
import { colors } from "styles";

const Auto_Search_Delay_In_Milliseconds = 250;

interface Props {
  query: string;
  setQuery: (query: string) => void;
}

const TextSearch: FC<Props> = ({ query, setQuery }) => {
  useEffect(() => setText(query), [query]);

  const [text, setText] = useState(query);
  const [timerId, setTimerId] = useState(0);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    clearTimeout(timerId);
    updateQueryParamsAfterDelay(e.target.value);
  };

  const updateQueryParamsAfterDelay = (newText: string) =>
    setTimerId(window.setTimeout(() => setQuery(newText), Auto_Search_Delay_In_Milliseconds));

  return (
    <OuterContainer>
      <Form.Control placeholder="Search for question set" value={text} onChange={onTextChange} />
      <SearchIcon>
        <MedIcon icon={Search} color={colors.primary} />
      </SearchIcon>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchIcon = styled.div`
  margin-left: -30px;
`;

export { TextSearch };
