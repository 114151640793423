import pluralize from "pluralize";
import { Price } from "types";

export const getMonthlyPrice = (price: Price, discount: number = 0): number => {
  if (price.paymentInterval === "month") {
    return roundResult((price.amount / price.paymentIntervalCount / 100) * (1 - discount / 100));
  } else {
    return roundResult((price.amount / (12 * price.paymentIntervalCount) / 100) * (1 - discount / 100));
  }
};

export const getBillPrice = (price: Price, discount: number = 0): number => {
  return roundResult((price.amount / 100) * (1 - discount / 100));
};

export const getPriceHeader = (price: Price) => {
  let intervalCount = price.paymentIntervalCount;
  let interval = price.paymentInterval;

  if (interval === "year") {
    interval = "month";
    intervalCount = 12;
  }

  if (intervalCount !== 1) {
    return `${intervalCount} ${capitalise(interval)}`;
  } else {
    return `Monthly`;
  }
};

export const getBillingCycle = (price: Price) => {
  let intervalCount = price.paymentIntervalCount;
  let interval = price.paymentInterval;

  if (interval === "year") {
    interval = "month";
    intervalCount = 12;
  }

  if (intervalCount !== 1) {
    return `${intervalCount} ${pluralize(interval, intervalCount)}`;
  } else {
    return `month`;
  }
};

const capitalise = (word: string) => {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};

// This is kinda dumb https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
const roundResult = (result: number) => {
  return Math.round(result * 100) / 100;
};
