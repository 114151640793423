import { isNumber } from "lodash";

type RangeValidatorFunction = ((val: number, [min, max]: [number | null, number | null]) => string) | (() => string);

//TODO this should be merged with the useValidatableState hooks (which are more generic, but don't allow for custom messages)

//A type that allows users to specify messages for too high, too low and default (usually error state) messages
interface RangeValidatorMessages {
  high?: RangeValidatorFunction;
  low?: RangeValidatorFunction;
  def?: RangeValidatorFunction;
}

//Default messages for those types. Will be used automatically if the custom values aren't specified
const defaultRangeValidatorMessage: RangeValidatorMessages = {
  high: (_, [, max]) => `Cannot be higher than ${max}.`,
  low: (_, [min]) => `Cannot be lower than ${min}.`,
  def: () => `Input is invalid`,
};

const InputIsWithinRangeValidator = (
  number: number,
  [min, max]: [number | null, number | null],
  messages?: RangeValidatorMessages
) => {
  if (!isIntegerWithinRange(number, [min, max])) {
    const { high, low, def } = messages || defaultRangeValidatorMessage;
    if (isNumber(min) && number < min) {
      if (low) return low(number, [min, max]);
    } else if (isNumber(max) && number > max) {
      if (high) return high(number, [min, max]);
    } else {
      if (def) return def(number, [min, max]);
    }
  }
  return null;
};

const isIntegerWithinRange = (number: number, [min, max]: [number | null, number | null]) => {
  if (!Number.isInteger(number)) return false;
  if (isNumber(min) && number < min) return false;
  if (isNumber(max) && number > max) return false;
  return true;
};

export { InputIsWithinRangeValidator };
