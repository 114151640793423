import { api } from "../api";
import { NetworkError } from "api/networkError";

const getCustomGameUrlFromCode = async (gameCode: string): Promise<string> => {
  const response = await api.get(`/customgames/code/${gameCode}`);
  if (response?.data?.link) {
    return response.data.link;
  } else {
    throw new NetworkError();
  }
};

export { getCustomGameUrlFromCode };
