import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { getNameForConstruct, Student } from "types";
import { dispatchAsyncFunction } from "./sliceHelpers";
import { loginStudent as loginStudentApi } from "api";
import { clearLoginToken } from "./teacherLoginSlice";
import { LocalToken } from "./appLoadingSlice";

type StudentLoginState = {
  token: string | null;
  student: Student | null;
};

const initialState: StudentLoginState = {
  token: null,
  student: null,
};

// --- Slice Definition

const reducers = {
  reset(state: StudentLoginState) {
    state = {
      token: null,
      student: null,
    };
  },
  success(state: StudentLoginState, action: PayloadAction<{ token: string; student: Student }>) {
    state.token = action.payload.token;
    state.student = action.payload.student;
  },
};

const slice = createSlice<StudentLoginState, typeof reducers>({
  name: "studentLogin",
  initialState,
  reducers,
});

// --- Async Action Wrappers

const loginStudent = (password: string, isConstruct: boolean = true) => async (dispatch: Dispatch<any>) =>
  await dispatchAsyncFunction(async () => {
    const loginResponse = await loginStudentApi(password);

    if (isConstruct) {
      // Defines the data emitted from the iframe after login. Used by Construct
      window.parent?.postMessage(
        {
          message: "loginSuccess",
          value: {
            id: loginResponse.userInfo.id,
            token: loginResponse.token,
            name: getNameForConstruct(loginResponse.userInfo),
          },
        },
        "*"
      );
    } else {
      dispatch(
        slice.actions.success({
          token: loginResponse.token,
          student: loginResponse.userInfo,
        })
      );

      const localData: LocalToken = {
        token: loginResponse.token,
        tokenType: "student",
        allowRefresh: false,
      };
      localStorage.setItem("token", JSON.stringify(localData));

      dispatch(clearLoginToken());
    }
  }, dispatch);

// --- Exports
const { reset: resetStudentLogin, success: studentLoginSuccess } = slice.actions;

const studentLoginReducer = slice.reducer;
export { studentLoginReducer, loginStudent, resetStudentLogin, studentLoginSuccess };
