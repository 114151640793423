import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGameTemplates, refreshGameTemplates } from "slices";

const useGameTemplates = (fetch?: boolean) => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.gameTemplates);

  useEffect(() => {
    if (fetch && !state.loading && !state.data) {
      dispatch(fetchGameTemplates());
    }
  }, [state, fetch, dispatch]);

  const functions = {
    fetchGameTemplates: () => dispatch(fetchGameTemplates()),
    refreshGameTemplates: () => dispatch(refreshGameTemplates()),
  };

  return { ...state, ...functions };
};

export { useGameTemplates };
