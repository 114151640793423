import styled from "styled-components";

const Input = styled.input`
  border-radius: 30px;
  background-color: #e3e3e3;
  outline: none;
  padding: 7px 20px;
  border: none;
  font-size: 16px;
`;

export { Input };
