import { useAppDispatch, useAppSelector } from "app/hooks";
import { setHelpMenuPortalNode, setHelpMenuState } from "slices";

interface HelpMenuData {
  portalNode: HTMLElement | null;
  portalReady: boolean;
  portalOpen: boolean;
  setPortalReady: () => void;
  setPortalOpen: (state: boolean) => void;
}

export const useHelpMenu = (): HelpMenuData => {
  const dispatch = useAppDispatch();
  const portalNode = document.getElementById("helpMenuPortal");

  const portalReady = useAppSelector((state) => state.helpMenuPortal.portalReady);
  const portalOpen = useAppSelector((state) => state.helpMenuPortal.portalOpen);

  const setPortalReady = () => dispatch(setHelpMenuPortalNode(true));
  const setPortalOpen = (state: boolean) => dispatch(setHelpMenuState(state));

  return { portalNode, portalReady, portalOpen, setPortalReady, setPortalOpen };
};
