import { Badge, Row } from "primitives";
import { createRef, FC, useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { Tag } from "types";

interface Props {
  tags: Array<Tag>;
  showAllTags?: boolean;
}

export const TagRow: FC<Props> = ({ tags, showAllTags }) => {
  const gradeTags = tags.filter((tag) => tag.type === "Grade");
  const subjectTags = tags.filter((tag) => tag.type === "Subject");
  const ref = createRef<HTMLDivElement>();
  const [isShowingMoreTags, setIsShowingMoreTags] = useState(false);

  const Minimised_Tags_Shown = 5;

  const GetTags = () => {
    let tags: Tag[] = [];
    gradeTags.forEach((tag) => {
      if (tags.length < Minimised_Tags_Shown || isShowingMoreTags || showAllTags) {
        tags.push(tag);
      }
    });
    subjectTags.forEach((tag) => {
      if (tags.length < Minimised_Tags_Shown || isShowingMoreTags || showAllTags) {
        tags.push(tag);
      }
    });

    return tags;
  };

  const toggleShowingTags = () => {
    setIsShowingMoreTags(!isShowingMoreTags);
  };

  return (
    <OuterContainer ref={ref}>
      <FlexRow>
        {tags.length ? (
          <>
            {GetTags().map((tag) => (
              <Badge key={tag.id} color={tag.type === "Grade" ? colors.warning : colors.tabSelectedGrey}>
                {tag.name}
              </Badge>
            ))}
            {tags.length > Minimised_Tags_Shown || showAllTags ? (
              <HasMore onClick={toggleShowingTags}>{isShowingMoreTags ? "Less..." : "More..."}</HasMore>
            ) : (
              ""
            )}
          </>
        ) : (
          <Badge key={"No Tags"} color={colors.checkboxBgGrey}>
            <p style={{ color: colors.tabSelectedGrey }}>No Tags</p>
          </Badge>
        )}
      </FlexRow>
    </OuterContainer>
  );
};

const HasMore = styled.div`
  display: flex;
  min-width: 50px;
  color: ${colors.primary};
  align-items: flex-end;
  cursor: pointer;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const FlexRow = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;
