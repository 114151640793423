import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getStudentTokenFromState } from "../sliceHelpers";
import { getStudentSessionByCode } from "api";

export interface FetchSessionByCodeParams {
  code: string;
}

const dispatchFetchSessionByCode = ({ code }: FetchSessionByCodeParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getStudentTokenFromState(getState);
    const session = await getStudentSessionByCode(token, code);
    dispatch(actions.addSingle({ id: session.id, session }));
    return session;
  }, dispatch);

export { dispatchFetchSessionByCode };
