import { Student } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  studentId: string;
}

const fetchStudent = async ({ token, studentId }: Params): Promise<Student> => {
  const response = await api.get(`/students/${studentId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const fetchedStudent = response.data;

  if (fetchedStudent) {
    return fetchedStudent;
  } else {
    throw new NetworkError();
  }
};

export { fetchStudent };
