import { api } from "api/api";

export const updatePaymentMethod = async (token: string, paymentMethodId: string) => {
  await api.put(
    `/payments/method`,
    {
      paymentMethodId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
