import styled from "styled-components";
import { colors } from "styles";

const LinkText = styled.a`
  color: ${colors.primary};
  margin: 0;
  cursor: pointer;
  font-size: 12px;
`;

export { LinkText };
