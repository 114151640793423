import { ReactComponent as Edit } from "./Edit.svg";
import { ReactComponent as Delete } from "./Delete.svg";
import { ReactComponent as Add } from "./Add.svg";
import { ReactComponent as Chat } from "./Chat.svg";
import { ReactComponent as Class } from "./Class.svg";
import { ReactComponent as Duplicate } from "./Duplicate.svg";
import { ReactComponent as Help } from "./Help.svg";
import { ReactComponent as Info } from "./Info.svg";
import { ReactComponent as Logout } from "./Logout.svg";
import { ReactComponent as MultipleChoice } from "./Multiple_Choice.svg";
import { ReactComponent as Options } from "./Options.svg";
import { ReactComponent as People } from "./People.svg";
import { ReactComponent as Person } from "./Person.svg";
import { ReactComponent as Preview } from "./Preview.svg";
import { ReactComponent as Publish } from "./Publish.svg";
import { ReactComponent as Question } from "./Question.svg";
import { ReactComponent as Search } from "./Search.svg";
import { ReactComponent as Session } from "./Session.svg";
import { ReactComponent as SessionPlay } from "./SessionPlay.svg";
import { ReactComponent as Share } from "./Share.svg";
import { ReactComponent as StarOutline } from "./Star_Outline.svg";
import { ReactComponent as Star } from "./Star.svg";
import { ReactComponent as Subtract } from "./Subtract.svg";
import { ReactComponent as Text } from "./Text.svg";
import { ReactComponent as Progress } from "./Progress.svg";
import { ReactComponent as AddImage } from "./AddImage.svg";
import { ReactComponent as Home } from "./Home.svg";
import { ReactComponent as Clock } from "./Clock.svg";

import { createCustomIcon } from "primitives/icons";

export const HomeIcon = createCustomIcon(Home, "Home");
export const EditIcon = createCustomIcon(Edit, "Edit", "0.15em");
export const DeleteIcon = createCustomIcon(Delete, "Archive");
export const AddImageIcon = createCustomIcon(AddImage, "Add Image");
export const ProgressIcon = createCustomIcon(Progress, "Progress");
export const ClockIcon = createCustomIcon(Clock, "Clock");
export const AddIcon = createCustomIcon(Add, "Add");
export const ChatIcon = createCustomIcon(Chat, "Chat");
export const ClassIcon = createCustomIcon(Class, "Class");
export const DuplicateIcon = createCustomIcon(Duplicate, "Duplicate");
export const HelpIcon = createCustomIcon(Help, "Help");
export const InfoIcon = createCustomIcon(Info, "Info", "0.3em");
export const LogoutIcon = createCustomIcon(Logout, "Logout");
export const MultipleChoiceIcon = createCustomIcon(MultipleChoice, "Multiple Choice", "0.25em");
export const OptionsIcon = createCustomIcon(Options, "Options");
export const PeopleIcon = createCustomIcon(People, "People");
export const PersonIcon = createCustomIcon(Person, "Person");
export const PreviewIcon = createCustomIcon(Preview, "Preview");
export const PublishIcon = createCustomIcon(Publish, "Publish");
export const QuestionIcon = createCustomIcon(Question, "Question");
export const SearchIcon = createCustomIcon(Search, "Search");
export const SessionIcon = createCustomIcon(Session, "Session");
export const SessionPlayIcon = createCustomIcon(SessionPlay, "Session Play");
export const ShareIcon = createCustomIcon(Share, "Share");
export const StarOutlineIcon = createCustomIcon(StarOutline, "Star Outline");
export const StarIcon = createCustomIcon(Star, "Star");
export const SubtractIcon = createCustomIcon(Subtract, "Subtract");
export const TextIcon = createCustomIcon(Text, "Text", "0.25em");
