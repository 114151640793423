import { Center, Row, TruncatedText } from "primitives";
import { FC, useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { defaultRequestState, DateRange, Session } from "types";
import styled from "styled-components";
import { colors } from "styles";
import { updateSession, fetchSession } from "slices";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { addDefaultSessionLength, dispatchAsync, parseDateString } from "utils";
import { useParams } from "react-router-dom";
import { endOnlyDateRangeValidator, useValidatableState } from "hooks/useValidatableState";
import { DateTimeRangeInput, ModalButtonFooter } from "components";

interface Props {
  onHidden: (newTab?: string) => void;
  onItemEdited?: (range: DateRange) => void;
  title: string;
  subtitle?: string;
  disableStartTime?: boolean;
  disableEndTime?: boolean;
}

function getDefaultDateRange(session?: Session): Partial<DateRange> {
  return {
    startDate: session?.startTime ? parseDateString(session.startTime) : new Date(),
    endDate: session?.endTime ? parseDateString(session.endTime) : addDefaultSessionLength(new Date()),
  };
}

const AdjustSessionTimeModal: FC<Props> = ({
  subtitle,
  onHidden,
  onItemEdited,
  title,
  disableStartTime = false,
  disableEndTime = false,
}) => {
  const dispatch = useAppDispatch();

  const [{ loading: pageLoading, error: pageError }, setRequestState] = useState(defaultRequestState);
  const { sessionId } = useParams<{ sessionId: string }>();
  const session = useAppSelector((state) =>
    state.sessionList.data && sessionId ? state.sessionList.data[sessionId] : undefined
  );

  const [closing, setClosing] = useState(false);

  const [dateRange, setDateRange, dateRangeError] = useValidatableState<Partial<DateRange>>(
    getDefaultDateRange(session),
    [endOnlyDateRangeValidator]
  );

  const onDateRangeChanged = (range: Partial<DateRange>) => {
    setDateRange({
      startDate: range.startDate,
      endDate: range.endDate,
    });
  };

  const onModalHidden = (newTab?: string) => {
    setClosing(false);
    onHidden(newTab);
  };

  const fetchSessionFromId = useCallback(async () => {
    const { success, result: fetchedSession } = await dispatchAsync(dispatch(fetchSession(sessionId)), setRequestState);

    if (success && fetchedSession) {
      setDateRange({
        startDate: fetchedSession.startTime ? new Date(fetchedSession.startTime) : undefined,
        endDate: fetchedSession.endTime ? new Date(fetchedSession.endTime) : addDefaultSessionLength(new Date()),
      });
    }
  }, [dispatch, sessionId, setDateRange]);

  useEffect(() => {
    if (sessionId && !session) {
      fetchSessionFromId();
    }
  }, [sessionId, session, fetchSessionFromId]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const fullRange: DateRange = {
      startDate: dateRange.startDate || new Date(),
      endDate: dateRange.endDate || addDefaultSessionLength(dateRange.startDate || new Date()),
    };
    const sessionFields = {
      startTime: fullRange.startDate,
      endTime: fullRange.endDate,
    };

    if (session) {
      let success = false;
      const { success: updateSuccess } = await dispatchAsync(
        dispatch(updateSession(session.id, sessionFields)),
        setRequestState
      );
      success = updateSuccess;
      if (onItemEdited) {
        onItemEdited(fullRange);
      }

      if (success) {
        onModalHidden("current");
      }
    }
  };

  const modalBody = (
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        {subtitle && <Form.Label>{subtitle}</Form.Label>}
        <DateTimeRangeInput
          error={dateRangeError}
          dateRange={dateRange}
          onChange={onDateRangeChanged}
          disabled={[true, disableStartTime, pageLoading || disableEndTime]}
          hasTime={true}
          nullLabels={{ start: "Now" }}
        />
        <SubmitContainer>
          {pageError && <ErrorText>Something went wrong! Please try again.</ErrorText>}
          <ModalButtonFooter
            isEditing={false}
            cancelDisabled={Boolean(pageLoading)}
            onCancel={() => setClosing(true)}
            cancelLoading={pageLoading}
            confirmDisabled={Boolean(pageLoading || dateRangeError)}
            confirmType={"submit"}
            confirmLoading={pageLoading}
          />
        </SubmitContainer>
      </Form>
    </Modal.Body>
  );
  return (
    <Modal
      show={!closing}
      onHide={() => setClosing(true)}
      onExited={onModalHidden}
      dialogClassName="width-1000px-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <Row gap={"5px"}>
            {title}: <PrimaryText>{session?.name || ""}</PrimaryText>
          </Row>
        </Modal.Title>
      </Modal.Header>
      {modalBody}
    </Modal>
  );
};

const PrimaryText = styled(TruncatedText)`
  color: ${colors.primary};
`;

const SubmitContainer = styled(Center)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
`;

const ErrorText = styled.p`
  color: ${colors.danger};
`;

export { AdjustSessionTimeModal };
