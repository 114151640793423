import { Add } from "@styled-icons/material";
import { PrimaryButton } from "primitives";
import { ButtonProps } from "react-bootstrap";
import { FC } from "types";
import { LargeIconButton, SmallIconButton } from "./IconButton";

interface CreateButtonProps extends ButtonProps {
  tooltip?: string;
  iconColor?: string;
}

const CreateButton: FC<CreateButtonProps> = ({ onClick, iconColor, children, style, ...rest }) => (
  <PrimaryButton style={style} onClick={onClick} {...rest}>
    <Add color={iconColor} style={{ width: "2em", height: "100%" }} />
    {children}
  </PrimaryButton>
);

const CreateButtonLarge: FC<CreateButtonProps> = ({ tooltip, onClick, ...rest }) => (
  <LargeIconButton tooltip={tooltip} icon={Add} variant="primary" onClick={onClick} {...rest} />
);

const CreateButtonSmall: FC<CreateButtonProps> = ({ tooltip, onClick, ...rest }) => (
  <SmallIconButton tooltip={tooltip} icon={Add} variant="primary" onClick={onClick} {...rest} />
);
export { CreateButton, CreateButtonLarge, CreateButtonSmall };
