import { Droppable } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { defaultPartitionId, DraggableItem, DragItemHandle, InlineEditableLabel, MoveDirection, StudentGrid } from "..";
import { Center, Column, DeleteButton, HorizontalLine, Row } from "primitives";
import { SmallIcon } from "primitives/icons";
import { colors } from "styles";
import { FC, Student } from "types";
import { getItemAlignment } from "utils";
import { PeopleIcon } from "icons";

export interface PartitionToolProps {
  partitionId: string;
  students: Student[];
}

interface Props {
  partitionName: string;
  partitionId: string;
  students: Student[];
  studentCards: { [studentId: string]: JSX.Element };
  index: number;
  totalCount: number;
  allPartitions: { id: string; name: string }[];
  editingDisabled?: boolean;
  onClickDeletePartition?: (index: number) => void;
  onMoveItemPressed?: (sourceIndex: number, direction: MoveDirection) => void;
  onNameChanged?: (partitionId: string, newName: string) => void;
  onTriggerBulkMove?: (sourceId: string, destinationId: string, students: Student[]) => void;
  sortStudents?: (student1: Student, student2: Student) => number;
  PartitionTools?: FC<PartitionToolProps>;
}

export const StudentPartition: FC<Props> = ({
  partitionName,
  partitionId,
  students,
  studentCards,
  index,
  totalCount,
  allPartitions,
  editingDisabled,
  onClickDeletePartition,
  onMoveItemPressed,
  onNameChanged,
  onTriggerBulkMove,
  sortStudents,
  PartitionTools,
}) => {
  const isDefault = partitionId === defaultPartitionId;
  // Takes into account the ungroupedSection
  const uniqueIndex = isDefault ? 0 : index + 1;

  const nameChanged = (newName: string) => {
    if (onNameChanged && Boolean(newName)) {
      onNameChanged(partitionId, newName.trim());
    }
  };

  const moveAllSelected = (event: any) => {
    if (onTriggerBulkMove) {
      onTriggerBulkMove(partitionId, event.target.value, students);
    }
  };

  const component = (
    <Column>
      <OuterContainer>
        <LeftColumn>
          <PartitionName>
            {!isDefault && !editingDisabled && (
              <DragItemHandle
                index={index}
                alignment={getItemAlignment(index, totalCount)}
                onMovePressed={onMoveItemPressed}
              />
            )}
            <Center>
              {isDefault ? (
                <p>{partitionName}</p>
              ) : (
                <InlineEditableLabel
                  value={partitionName}
                  onChange={nameChanged}
                  placeholder="Group Name"
                  inputClasses={"inline-editable-edit"}
                  labelClasses={"inline-editable-view"}
                />
              )}
            </Center>
          </PartitionName>
          <Row>
            <Center>
              <HorizontalLine />
              <SmallIcon icon={PeopleIcon} color={colors.secondary} className="mx-1" />
              {students.length}
            </Center>
          </Row>
          {PartitionTools && <PartitionTools partitionId={partitionId} students={students} />}
          {!editingDisabled && (
            <Form.Control as="select" value={0} onChange={moveAllSelected}>
              <option value={0} key={"0"} hidden>
                Move All Students
              </option>
              {allPartitions
                .filter((partition) => partition.id !== partitionId)
                .map((partition) => (
                  <option value={partition.id} key={partition.id}>
                    Move Students to {partition.name}
                  </option>
                ))}
            </Form.Control>
          )}
        </LeftColumn>
        <RightColumn>
          <Droppable
            droppableId={partitionId}
            type="STUDENTCARD"
            ignoreContainerClipping={true}
            direction="horizontal"
            isDropDisabled={editingDisabled}
          >
            {(provided, snapshot) => (
              <StudentDropContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
                isEmpty={!Boolean(students?.length)}
              >
                {Boolean(students?.length) ? (
                  <StudentGrid margin="var(--sp-1) 0" scroll={false}>
                    {[...students].sort(sortStudents).map((student, index) => {
                      return (
                        <DraggableItem
                          index={index}
                          draggableId={student.id}
                          key={student.id}
                          isDragDisabled={editingDisabled}
                        >
                          {studentCards[student.id]}
                        </DraggableItem>
                      );
                    })}
                    {provided.placeholder}
                  </StudentGrid>
                ) : (
                  <>
                    <Center className="p-3">
                      {!editingDisabled ? <h5>Drag students here, to add them to this group</h5> : <h5>Empty Group</h5>}
                    </Center>
                    {provided.placeholder}
                  </>
                )}
              </StudentDropContainer>
            )}
          </Droppable>
        </RightColumn>
        {!editingDisabled && (
          <DeleteColumn className="py-3 ml-auto">
            {
              <DeleteButton
                style={{ visibility: isDefault ? "hidden" : "visible" }}
                tooltip="Delete Group"
                onClick={() => onClickDeletePartition?.(index)}
              />
            }
          </DeleteColumn>
        )}
      </OuterContainer>
    </Column>
  );

  return isDefault ? (
    <>{component}</>
  ) : (
    <DraggableItem
      index={uniqueIndex}
      draggableId={`PartitionDrag${isDefault ? "Default" : index}`}
      isDragDisabled={editingDisabled}
    >
      {component}
    </DraggableItem>
  );
};

const DeleteColumn = styled(Column)`
  width: var(--student-partition-delete-width);
  max-width: var(--student-partition-delete-width);
`;

const OuterContainer = styled(Row)`
  border-bottom: 1px solid ${colors.primaryDivider};
  padding-bottom: 20px;
  background-color: white;
  gap: var(--student-card-gap);
`;

const LeftColumn = styled(Column)`
  min-width: var(--student-partition-header-width);
  max-width: var(--student-partition-header-width);
  padding: 16px 0px;
  flex-grow: 0;
`;

const RightColumn = styled(Column)`
  padding-top: 10px;
  flex-grow: 1;
`;

const PartitionName = styled(Row)`
  border: 1px solid ${colors.primaryDivider};
  background-color: ${colors.light};
  justify-content: space-between;
  width: 180px;
  height: 50px;
  p {
    color: ${colors.darkText};
    font-weight: 600;
  }
`;

const StudentDropContainer = styled.div<{ isDraggingOver: boolean; isEmpty: boolean }>`
  min-height: 128px;
  background-color: ${(props) => (props.isDraggingOver ? colors.light : "white")};
  transition: background-color 0.25s linear;
  border-radius: 5px;
  border: ${(props) =>
    props.isDraggingOver || props.isEmpty ? `1px dashed ${colors.primaryDivider}` : `1px dashed transparent`};
  height: 100%;
  flex: 100%;
  h5 {
    font-weight: 600;
  }
`;
