import { Dispatch } from "react";
import { fetchGameTemplates as fetchGameTemplatesApi } from "api";
import { RootState } from "app/rootReducer";
import { GameTemplate } from "types";
import { getTokenFromState, createAsyncSlice } from "./sliceHelpers";

const { slice, dispatchAsyncFunction } = createAsyncSlice<
  Array<GameTemplate>,
  {}
>({
  name: "gameTemplates",
});

export const {
  loading: fetchGameTemplatesLoading,
  success: fetchGameTemplatesSuccess,
  error: fetchGameTemplatesError,
} = slice.actions;

const fetchGameTemplates = () => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const data = await fetchGameTemplatesApi(token);
    dispatch(fetchGameTemplatesSuccess(data));
  }, dispatch);

const refreshGameTemplates = () => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  const { data: gameTemplates } = getState().gameTemplates;
  if (!gameTemplates) {
    return;
  }
  return dispatch(fetchGameTemplates());
};

const gameTemplatesReducer = slice.reducer;
export { gameTemplatesReducer, fetchGameTemplates, refreshGameTemplates };
