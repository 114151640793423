import { quickGameActionsHooks } from "hooks";
import { QuickGameList } from "pages/gameLibrary";
import { Column } from "primitives";
import styled from "styled-components";
import { FC } from "types";

export const PublicGameLibrary: FC = () => {
  const publicActions = quickGameActionsHooks;

  return (
    <OuterContainer>
      <QuickGameList actionHooks={publicActions} showHeaderButton={false} />
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  width: 100vw;
  height: 100vh;
`;
