import { fetchQuestionImages, questionImagesReset } from "slices";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";

const useQuestionImages = (fetch?: boolean) => {
  useEffect(() => {
    if (fetch) {
      dispatch(fetchQuestionImages());
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.questionImages);

  const functions = {
    fetchQuestionImages: (...params: Parameters<typeof fetchQuestionImages>) =>
      dispatch(fetchQuestionImages(...params)),
    resetQuestionImages: () => dispatch(questionImagesReset()),
  };

  return { ...state, ...functions };
};

export { useQuestionImages };
