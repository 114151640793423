import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { favouriteQuestionSet as favouriteQuestionSetApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSet } from "types";

const dispatchFavouriteQuestionSet = (
  questionSetId: string,
  isFavourite: boolean,
  actions: QuestionSetActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedQuestionSet = await favouriteQuestionSetApi(
      questionSetId,
      { isFavourite },
      token
    );
    dispatch(
      actions.addSingle({
        id: updatedQuestionSet.id!,
        questionSet: updatedQuestionSet as QuestionSet,
      })
    );
  }, dispatch);

export { dispatchFavouriteQuestionSet };
