import { colors } from "styles/colors";
import { ReactComponent as QuarterEllipse } from "assets/QuarterEllipse.svg";
import { ReactComponent as QuarterEllipseLarger } from "assets/QuarterEllipseLarger.svg";
import { createCustomIcon } from "primitives/icons";

export const LoadingConstants = {
  spinnerBaseColor: colors.brandDarkPurple,
  spinnerColors: [colors.warning, colors.brandOrange, colors.brandBlue, colors.brandPink],
  spinTime: 16,
  spinnerIcon: createCustomIcon(QuarterEllipse, "ellipse"),
  largerSpinnerIcon: createCustomIcon(QuarterEllipseLarger, "elliseLarger"),
};
