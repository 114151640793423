import { MoreVert } from "@styled-icons/material";
import { useEffect } from "react";
import { FC, forwardRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { UserAction } from "types";
import { ToolTip } from "primitives";

interface Props {
  parentElementId?: string;
  triggerClose?: number;
  items: Array<UserAction>;
  alignRight?: boolean;
}

const CustomToggle: FC<{ onClick: (e: any) => void }> = forwardRef(({ onClick }, ref) => (
  <div ref={ref as any} onClick={onClick} style={{ color: "black", cursor: "pointer" }}>
    <MoreVert />
  </div>
));

const HotDog: FC<Props> = ({ items, parentElementId, triggerClose, alignRight = false }) => {
  const [show, setShow] = useState(false);

  const onToggle = (show: boolean) => {
    setShow(show);
  };

  useEffect(() => {
    setShow(false);
  }, [triggerClose]);

  return (
    <HotDogContainer>
      <ToolTip tooltip="More Actions">
        <Dropdown show={show} onToggle={onToggle} className="hotdog-menu-button">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
          {createPortal(
            <Dropdown.Menu align={alignRight ? "right" : "left"}>
              {items.map(({ id, name, action, isEnabled }) => (
                <Dropdown.Item
                  onClick={(e) => e.stopPropagation()}
                  onSelect={action}
                  disabled={isEnabled && !isEnabled()}
                  key={id}
                >
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>,
            (parentElementId && document.getElementById(parentElementId)) || document.getElementById("root")!
          )}
        </Dropdown>
      </ToolTip>
    </HotDogContainer>
  );
};

const HotDogContainer = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 1.25em;
`;

export { HotDog };
