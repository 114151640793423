import { Session } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";
import { CreateSessionParams } from "./createSession";

const updateSession = async (
  token: string,
  sessionId: string,
  { name, publicAccess, classId, startTime, endTime, maxAttempts }: Partial<CreateSessionParams>
): Promise<Session> => {
  const response = await api.put(
    `/sessions/${sessionId}`,
    {
      name,
      publicAccess,
      classId,
      startTime,
      endTime,
      maxAttempts,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const session = response.data;

  if (session) {
    return session;
  } else {
    throw new NetworkError();
  }
};

export { updateSession };
