import { useAppDispatch, useAppSelector } from "app/hooks";
import { Column, Row } from "primitives";
import styled from "styled-components";
import { FC } from "types";
import { fetchPremadeSessions as fetchPremadeSessionsSlice } from "slices";
import { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { slugs } from "textConstants";
import { quickGameActionsHooks } from "hooks";
import { QuickGameListItem } from "pages/gameLibrary";

export const QuickGamePreview: FC = () => {
  const dispatch = useAppDispatch();
  const { data, premadeGamePreviews } = useAppSelector((state) => state.sessionList);

  const sessions = (data && premadeGamePreviews.map((id) => data[id])) || [];

  const fetchPremadeSessions = useCallback(() => {
    const take = 6;
    dispatch(fetchPremadeSessionsSlice({ search: "", take, skip: 0, isPreviewFetch: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!sessions.length) {
      fetchPremadeSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OuterContainer>
      <HeaderText>Don’t want to make your own? Choose one of these premade games we love.</HeaderText>
      <HeaderText>
        <Link to={`/${slugs.teacher}/${slugs.gamelibrary}`}>View All</Link>
      </HeaderText>
      <Row style={{ gap: "18px", flexWrap: "wrap" }}>
        {sessions &&
          sessions.every((session) => Boolean(session)) &&
          sessions.map((session, index) =>
            index >= 4 ? (
              <CardWrapper key={session.id}>
                <QuickGameListItem session={session} actionHooks={quickGameActionsHooks} />
              </CardWrapper>
            ) : (
              <QuickGameListItem key={session.id} session={session} actionHooks={quickGameActionsHooks} />
            )
          )}
      </Row>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
`;

const CardWrapper = styled(Column)`
  @media (max-width: 1300px) {
    display: none;
  }
`;
