import { Dispatch, SetStateAction } from "react";
import { AsyncRequestState } from "types";

export const dispatchAsync = async <T>(
  asyncFunc: Promise<T>,
  setStateFunction?: Dispatch<SetStateAction<AsyncRequestState>>,
  setStateOnSuccess: boolean = true
): Promise<{ result: T | null; success: boolean; error: string | null }> => {
  setStateFunction &&
    setStateFunction({
      loading: true,
      success: false,
      error: null,
    });
  try {
    const result = await asyncFunc;
    const state = {
      loading: false,
      success: true,
      error: null,
    };
    if (setStateOnSuccess) {
      setStateFunction && setStateFunction(state);
    }
    return {
      result,
      success: state.success,
      error: state.error,
    };
  } catch (err: any) {
    const state = {
      loading: false,
      success: false,
      error: err,
    };
    if (err instanceof Error) {
      state.error = err.message;
    }
    setStateFunction && setStateFunction(state);
    return {
      result: null,
      success: state.success,
      error: state.error,
    };
  }
};
