import { colors, layoutConstants } from "styles";
import { ReactComponent as PlayEdLogo } from "assets/playEdLogo.svg";
import styled from "styled-components";
import { Navbar, NavbarItemProperties } from "../navbar";
import { Row, Spacer } from "primitives";
import { Link } from "react-router-dom";
import { MediaConstants } from "utils/mediaConstants";
import { FC } from "react";

interface Props {
  navbarItems: Array<NavbarItemProperties>;
  profileElement: JSX.Element;
  children?: React.ReactNode;
}

const Header: FC<Props> = ({ navbarItems, profileElement, children }) => {
  return (
    <HeaderContainer className="d-print-none">
      <FullHeightRow>
        <LogoContainer to="/teacher">
          <StyledLogo className="played-logo" />
        </LogoContainer>
        <Spacer size={MediaConstants.IsTablet() ? 8 : 20} />
        <Navbar navbarItems={navbarItems} />
      </FullHeightRow>
      <FullHeightRow>
        {children}
        {profileElement}
        <Spacer size={20} />
      </FullHeightRow>
    </HeaderContainer>
  );
};

const FullHeightRow = styled(Row)`
  height: 100%;
`;

const HeaderContainer = styled.div`
  background-color: ${colors.darkText};
  height: ${layoutConstants.navBarHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  display: flex;
  background-color: ${colors.white};
  padding: 0px 30px 0px 30px;
  margin: 0 0 0 -20px;
  transform: skew(-20deg);
  overflow: hidden;
  position: relative;
  @media ${MediaConstants.tablet} {
    padding: 0px 10px 0px 30px;
  }
`;

const StyledLogo = styled(PlayEdLogo)`
  transform: skew(20deg);
  cursor: "pointer";
  align-self: center;
  width: 120px;
  @media ${MediaConstants.tablet} {
    width: 70px;
  }
`;

export { Header };
