import { ActionButtonBar, Row } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { CustomGame, FC, QuestionSet, UserAction } from "types";
import { DragItemHandle, ItemAlignment, MiniGameCard, MiniGameQuestionSetBox, MoveDirection } from "components";
import { useHistory, useRouteMatch } from "react-router-dom";
import pluralize from "pluralize";
import { actionConstants, slugs } from "textConstants";
import { MiniGameTemplateInfo } from "pages/customGames/Components";

interface Props {
  customGame: CustomGame;
  studentsInClass: number;
  isSelected: boolean;
  index: number;
  alignment: ItemAlignment;
  onCustomGamePressed?: (id: string) => void;
  onMovePressed: (index: number, direction: MoveDirection) => void;
  onDeleteCustomGamePressed: (customGameId: string) => void;
  isDragging: boolean;
  studentsAssigned: number;
}

export const SessionCustomGame: FC<Props> = ({
  customGame,
  studentsAssigned,
  studentsInClass,
  isSelected,
  index,
  alignment,
  onCustomGamePressed,
  onDeleteCustomGamePressed,
  onMovePressed,
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const onClick = () => {
    if (onCustomGamePressed) {
      onCustomGamePressed(customGame.id);
    }
  };

  const editAction: UserAction = {
    ...actionConstants.edit,
    id: "edit",
    iconColor: isSelected ? "white" : colors.primary,
    action: async () => {
      history.push(`${url}/${slugs.miniGame}/${customGame.id}`);
    },
  };

  const deleteAction: UserAction = {
    id: "delete",
    ...actionConstants.delete,
    iconColor: isSelected ? "white" : colors.danger,
    action: async () => onDeleteCustomGamePressed(customGame.id),
  };

  const testAction: UserAction = {
    id: "test",
    name: "Test",
    ...actionConstants.play,
    iconColor: isSelected ? "white" : colors.primary,
    action: async () => window.open(customGame.gameLink, "_blank", "noopener,noreferrer"),
  };

  const onQuestionSetClicked = (questionSet: QuestionSet) => {
    history.push(`${url}/${slugs.questionSet}/${questionSet.id}`);
  };

  return (
    <>
      <OuterContainer>
        <DragContainer>
          <DragItemHandle index={index} alignment={alignment} onMovePressed={onMovePressed} />
        </DragContainer>
        <MiniGameTemplateInfo gameTemplate={customGame.gameTemplate} onClick={onClick} isSelected={isSelected}>
          <CustomGameInfoContainer>
            <ActionButtonBar
              color={isSelected ? colors.white : undefined}
              hoverColor={isSelected ? colors.white : undefined}
              hoverBackground={isSelected ? colors.primaryHover : undefined}
              actions={[{ action: editAction }, { action: deleteAction }, { action: testAction }]}
            ></ActionButtonBar>
          </CustomGameInfoContainer>
        </MiniGameTemplateInfo>
      </OuterContainer>
      <StyledMiniGameCard
        className="mt-2 p-2"
        hideHeader={true}
        miniGame={customGame}
        QuestionSetComponent={MiniGameQuestionSetBox}
        hideTimeInfo={true}
        extraInfo={[`${studentsAssigned} / ${studentsInClass} ${pluralize("Student", studentsInClass)}`]}
        onClickQuestionSet={onQuestionSetClicked}
      />
    </>
  );
};

const StyledMiniGameCard = styled(MiniGameCard)`
  margin-left: calc(1em + 22px);
  font-size: 0.8em;
`;

const OuterContainer = styled(Row)`
  background-color: ${colors.sessionSelect};
  justify-content: flex-start;
  border: 1px solid ${colors.primary};
`;

const CustomGameInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const DragContainer = styled.div`
  border-right: 2px solid ${colors.primary};
  display: flex;
  justify-content: center;
  background-color: white;
`;
