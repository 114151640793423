import { InputGroup, Form } from "react-bootstrap";
import { getExampleTTSOverrideQuestion } from "utils";
import { FC, QuestionSet, TTSOverridePair } from "types";
import { SmallIconButton } from "primitives/button/IconButton";
import { colors } from "styles";
import styled from "styled-components";
import { Column, Row, SecondaryText, Spacer, VerticalLine } from "primitives";
import { actionConstants, pronunciationConstants } from "textConstants";

const FireSpeechAudio = (override: TTSOverridePair | null, selectedQuestionSets?: QuestionSet[]) => {
  if (override) {
    let speechText = override?.output;
    if (selectedQuestionSets) {
      const question = getExampleTTSOverrideQuestion(selectedQuestionSets, override);
      if (question) {
        speechText = `${question?.questionText} ${question?.answers}`.replaceAll(override?.input, override?.output);
      }
    }

    const speech = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(speechText);
    speech.speak(utterance);
  }
};

interface Props {
  override: TTSOverridePair | null;
  index: number;
  selectedQuestionSets: QuestionSet[];
  disabled?: boolean;
  onChanged: (value: string, index: number, field: "input" | "output") => void;
  removeOverride: (index: number) => void;
  isOverrideValid: (pair: TTSOverridePair | null) => boolean;
}

const TTSOverrideRow: FC<Props> = ({
  override,
  index,
  selectedQuestionSets,
  disabled,
  onChanged,
  removeOverride,
  isOverrideValid,
}) => {
  return (
    <FormRow className="p-1">
      <CenterAlignedColumn className="mx-2">
        <SecondaryText color={colors.darkText}>Pronounce</SecondaryText>
      </CenterAlignedColumn>
      <InputGroup style={{ width: "8em" }}>
        <Form.Control
          type="text"
          size="sm"
          placeholder="Symbol"
          value={override?.input}
          disabled={disabled}
          onChange={(e: any) => onChanged(`${e.target.value}`, index, "input")}
          style={{
            borderColor: colors.darkText,
            height: "2em",
          }}
        />
      </InputGroup>
      <CenterAlignedColumn className="mx-2">
        <SecondaryText color={colors.darkText}>as</SecondaryText>
      </CenterAlignedColumn>
      <InputGroup style={{ width: "20em" }}>
        <Form.Control
          type="text"
          size="sm"
          placeholder="Pronunciation"
          value={override?.output}
          disabled={disabled}
          onChange={(e: any) => onChanged(`${e.target.value}`, index, "output")}
          style={{ borderColor: colors.darkText, height: "2em" }}
        />
      </InputGroup>
      <Spacer size={5} />
      <CenterAlignedColumn className="mr-1">
        <SmallIconButton
          color={colors.darkText}
          icon={pronunciationConstants.icon}
          tooltip={"Speak Aloud"}
          disabled={override?.output === ""}
          onClick={() => {
            FireSpeechAudio(override, selectedQuestionSets);
          }}
        />
      </CenterAlignedColumn>
      {!disabled && (
        <>
          <VerticalLine className="m-2" />
          <CenterAlignedColumn>
            <SmallIconButton
              icon={actionConstants.delete.icon}
              color={actionConstants.delete.iconColor}
              tooltip={"Remove Override"}
              onClick={(e: any) => {
                removeOverride(index);
              }}
            />
          </CenterAlignedColumn>
        </>
      )}

      {!isOverrideValid(override) ? (
        <>
          <VerticalLine className="m-2" />
          <ErrorTextRow className="p-1">Invalid or duplicate override</ErrorTextRow>
        </>
      ) : (
        <>
          {TTSExampleQuestionText(selectedQuestionSets, override)}
          {TTSExampleQuestionAnswer(selectedQuestionSets, override)}
        </>
      )}
    </FormRow>
  );
};

const TTSExampleQuestionText = (selectedQuestionSets: QuestionSet[], override: TTSOverridePair | null) => {
  const question = getExampleTTSOverrideQuestion(selectedQuestionSets, override);

  return (
    question && (
      <>
        <VerticalLine className="m-2" />
        <ExampleTextArea>{`Q. ${question?.questionText}`}</ExampleTextArea>
      </>
    )
  );
};

const TTSExampleQuestionAnswer = (selectedQuestionSets: QuestionSet[], override: TTSOverridePair | null) => {
  const question = getExampleTTSOverrideQuestion(selectedQuestionSets, override);

  return (
    question && (
      <>
        <VerticalLine className="m-2" />
        <ExampleTextArea>{`A. ${question?.answers}`}</ExampleTextArea>
      </>
    )
  );
};

const ErrorTextRow = styled(Column)`
  flex-grow: 1;
  justify-content: center;
  background-color: ${colors.danger};
`;

const CenterAlignedColumn = styled(Column)`
  justify-content: center;
`;

const FormRow = styled(Row)`
  background-color: ${colors.inactiveButton};
`;

const ExampleTextArea = styled.div`
  width: 9em;
  font-size: 10pt;
  font-weight: 300;
`;

export { TTSOverrideRow };
