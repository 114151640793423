import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { CustomGameSettings, PartialNullable } from "types";
import { createCustomGame as createCustomGameApi } from "api";
import { CustomGameActions } from "./customGameSlice";
import { minutesToSeconds } from "utils";

export interface CreateCustomGameFromQuestionSetParams {
  gameTemplateId: string;
  questionSetIds: string[];
  sessionId?: string;
  customGameSettings?: PartialNullable<CustomGameSettings>;
}

export const dispatchCreateCustomGame = (
  { gameTemplateId, questionSetIds, sessionId, customGameSettings }: CreateCustomGameFromQuestionSetParams,
  actions: CustomGameActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const customGame = await createCustomGameApi({
      token,
      gameTemplateId,
      questionSetIds,
      sessionId,
      settings: {
        ...customGameSettings,
        gameTimeLimit: customGameSettings?.gameTimeLimit ? minutesToSeconds(customGameSettings.gameTimeLimit) : null,
      },
    });

    dispatch(actions.addSingle({ id: customGame.id, customGame }));

    return customGame;
  }, dispatch);
