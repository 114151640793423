import { OnboardingElementContainer } from "pages";
import {
  Center,
  Column,
  LoginBackgroundContainer,
  OnboardingLocation,
  PrimaryButton,
  Spacer,
  VerticalCenter,
} from "primitives";
import { Link } from "react-router-dom";
import { colors } from "styles";
import { FC } from "types";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { EmailInput, InputControlWithError } from "components";
import { Validator, characterLimitedNonEmptyValidator, useValidatableState } from "hooks";
import { useState } from "react";
import { isValidEmail } from "utils";
import { mailchimpRegister } from "api";
import { PlatformTitle, paths } from "textConstants";

interface Props {}

const nameValidator: Validator = [characterLimitedNonEmptyValidator];

export const MailchimpRegisterPage: FC<Props> = () => {
  const [firstName, setFirstName, firstNameError] = useValidatableState("", nameValidator, "First Name");
  const [lastName, setLastName, lastNameError] = useValidatableState("", nameValidator, "Last Name");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState(false);

  const isValid = firstName && !firstNameError && lastName && !lastNameError && isValidEmail(email);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isValid) {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      const response = await mailchimpRegister(firstName, lastName, email);

      if (response === "Success") {
        setSuccess(true);
      } else if (response === "MemberExists") {
        setError("Looks like you're already registered!");
      } else {
        setError("Something went wrong. Please try again.");
      }
      setLoading(false);
    }
  };

  return (
    <LoginBackgroundContainer
      backgroundColour={colors.brandPurple}
      location={OnboardingLocation.register}
      includeLogo={false}
    >
      <VerticalCenter>
        <OnboardingElementContainer title={"Sign Up"} size={"M"}>
          {!success ? (
            <>
              <p>
                Hey there! <br /> We have a temporary pause on user sign-ups while we renovate our site. If you would
                like to be notified when our Sign Up portal is ready, please enter your details below.
              </p>
              <Spacer />
              <Form>
                <Form.Label>Name*</Form.Label>
                <InputControlWithError
                  placeholder="First Name"
                  initialInput={firstName}
                  onInputUpdated={setFirstName}
                  errorFeedback={firstNameError}
                />
                <Spacer size={10} />
                <InputControlWithError
                  placeholder="Last Name"
                  initialInput={lastName}
                  onInputUpdated={setLastName}
                  errorFeedback={lastNameError}
                />
                <Spacer />
                <Form.Label>Email*</Form.Label>
                <EmailInput setEmail={setEmail} />
                <Spacer />
                <Center>
                  <ConfirmContainer>
                    {error && <ErrorText>{error}</ErrorText>}
                    <PrimaryButton
                      type="submit"
                      disabled={!isValid || loading}
                      loading={loading}
                      onClick={handleSubmit}
                    >
                      Confirm
                    </PrimaryButton>
                    <Spacer size={5} />
                    <Link to={paths.login.teacher.path}>Have a {PlatformTitle} account? Log In here</Link>
                  </ConfirmContainer>
                </Center>
              </Form>
            </>
          ) : (
            <ConfirmContainer>
              <SuccessText>Thanks! Look out for any PlayEd emails for the latest updates on our progress.</SuccessText>
              <Link to={"/teacher"}>Return Home</Link>
            </ConfirmContainer>
          )}
        </OnboardingElementContainer>
      </VerticalCenter>
    </LoginBackgroundContainer>
  );
};

const ConfirmContainer = styled(Column)`
  align-items: center;
  gap: 5px;
`;

const SuccessText = styled.p`
  font-size: 14pt;
`;

const ErrorText = styled.p`
  color: var(--danger);
`;
