import { FC, useCallback, useEffect, useState } from "react";
import { Column, HeaderText, HorizontalLine, Row, SecondaryText } from "primitives";
import { StudentRecordModal } from "components";
import {
  GroupedStudentGrid,
  StudentCard,
  StudentCardProps,
  StudentGridKey,
  StudentGridKeyContainer,
  StudentGridKeyItem,
} from "components/studentCard";
import { Student } from "types";
import { useAppSelector } from "app/hooks";
import styled from "styled-components";
import { useClassId, useGroupingId } from "hooks";
import { EventNote, Group, OpenWith } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { breadcrumbConstants, Names, slugs } from "textConstants";
import { colors } from "styles";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { LessonPlanPreviewModal } from "pages/my-games/lessonPlans/LessonPlanPreviewModal";

interface Props {
  sortStudents?: (student1: Student, student2: Student) => number;
  onClickStudent?: (studentId: string) => void;
  onDeleteStudent?: (studentId: string) => void;
}

const getSummaryStats = (student: Student) => {
  const upcomingAssignments = student.upcomingSessions || 0;
  const completedLessons = student.completedSessions || 0;

  return { upcomingAssignments, completedLessons };
};

const ClassStudentGrid: FC<Props> = ({ sortStudents, onClickStudent, onDeleteStudent }) => {
  const [classId] = useClassId();
  const class_ = useAppSelector((state) => (classId && state.classList.data ? state.classList.data[classId] : null));
  const [className, setClassName] = useState(class_?.name || "");
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const [selectedGroupingId] = useGroupingId();

  const revertTitleChanged = useCallback(() => {
    setClassName(class_?.name || "");
  }, [class_]);

  useEffect(() => {
    revertTitleChanged();
  }, [class_, revertTitleChanged]);

  const returnToClassGrid = () => {
    history.push(url);
  };

  const ClassStudentCard: FC<StudentCardProps> = ({ student, disabled }) => {
    const { completedLessons, upcomingAssignments } = getSummaryStats(student);
    return (
      <StudentCard
        {...student}
        onStudentPressed={disabled ? undefined : onClickStudent}
        topRowElement={{ icon: breadcrumbConstants.myGames.icon, text: completedLessons.toString() }}
        bottomRowElement={{ icon: EventNote, text: upcomingAssignments.toString() }}
        badge={
          Boolean(selectedGroupingId) ? (
            <MoveBadge>
              <MedIcon icon={OpenWith} color="white" />
            </MoveBadge>
          ) : undefined
        }
      />
    );
  };

  const header = (
    <TitleContainer>
      <FlexibleHorizontalLine />
      <HeaderText>{className}</HeaderText>
      <SecondaryText style={{ fontWeight: 600, minWidth: "70px" }} className="pr-2">
        ( <MedIcon icon={Group} /> {class_?.studentCount || class_?.students?.length || "-"})
      </SecondaryText>
      <FlexibleHorizontalLine />
    </TitleContainer>
  );

  const footer = (
    <StudentGridKeyContainer>
      <StudentGridKey>
        <StudentGridKeyItem>
          <MedIcon icon={breadcrumbConstants.myGames.icon} color={colors.primary} />
          Completed {Names.Sessions}
        </StudentGridKeyItem>
        <StudentGridKeyItem>
          <MedIcon icon={EventNote} color={colors.primary} />
          Upcoming {Names.Sessions}
        </StudentGridKeyItem>
      </StudentGridKey>
    </StudentGridKeyContainer>
  );

  return (
    <StretchedColumn>
      <GroupedStudentGrid
        sortStudents={sortStudents}
        StudentCard={ClassStudentCard}
        allowEditing={true}
        header={header}
        footer={footer}
      />
      <Route path={`${path}/${slugs.student}/${slugs.studentId}`}>
        <StudentRecordModal
          StudentCard={ClassStudentCard}
          onModalHidden={returnToClassGrid}
          onDeleteStudent={onDeleteStudent}
        />
      </Route>
      <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.preview}`}>
        <LessonPlanPreviewModal onHidden={returnToClassGrid} />
      </Route>
    </StretchedColumn>
  );
};

const StretchedColumn = styled(Column)`
  flex: 1;
  align-items: center;
  padding-bottom: var(--sp-2);
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 100%;
  padding: var(--sp-1) var(--sp-3);
`;

const FlexibleHorizontalLine = styled(HorizontalLine)`
  flex: 1;
`;

const MoveBadge = styled(Row)`
  border-radius: 50px;
  background-color: ${colors.primary};
  border: 1px solid white;
  width: 29px;
  height 29px;
  justify-content: center;
  align-items: center;
`;

export { ClassStudentGrid };
