import classNames from "classnames";
import { SmallIcon } from "primitives/icons";
import styled from "styled-components";
import { questionConstants } from "textConstants";
import { FC, QuestionType } from "types";
import { QuestionSetQuestionImage } from "./QuestionSetQuestionImage";

interface Props {
  questionNumber: number;
  question: string;
  questionImage: string | null;
  questionType: QuestionType;
  className?: string;
}

export const QuestionDisplay: FC<Props> = ({ className, questionNumber, question, questionImage, questionType }) => {
  return (
    <OuterContainer className={classNames(className)}>
      <QuestionText>
        {questionNumber}. {question || ""}
      </QuestionText>
      <QuestionSetQuestionImage imageUrl={questionImage} size={"lg"} />
      <QuestionTypeIcon icon={questionConstants.questionTypes[questionType].icon} />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-wrap: break-word;
`;

const QuestionText = styled.div`
  width: 95%;
  align-items: center;
  whitespace: initial;
`;

const QuestionTypeIcon = styled(SmallIcon)`
  position: absolute;
  top: 0;
  right 0;
`;
