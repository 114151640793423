import { CustomGame, CustomGameSettings, GameTemplate } from "types";
import { secondsToMinutes } from "utils";
import { ProcessAssignment, UnprocessedAssignment } from "./assignment";
import { ProcessQuestionSet, UnprocessedQuestionSet } from "./questionSet";

export interface UnprocessedCustomGame {
  id: string;
  name: string;
  description: string;
  gameTemplate: GameTemplate;
  visibility: VisibilityState;
  questionSets: Array<UnprocessedQuestionSet>;
  shortCode: string;
  gameLink: string;
  assignments: Array<UnprocessedAssignment>;
  settings?: UnprocessedCustomGameSettings;
}

export const ProcessCustomGame = (customGame: UnprocessedCustomGame): CustomGame => {
  const {
    id,
    name,
    description,
    gameTemplate,
    visibility,
    questionSets,
    shortCode,
    gameLink,
    assignments,
    settings,
  } = customGame;
  return {
    id,
    name,
    description,
    gameTemplate,
    visibility,
    questionSets:
      questionSets &&
      questionSets
        .filter((questionSet) => {
          return Boolean(questionSet);
        })
        .map((unprocessedQuestionSet) => ProcessQuestionSet(unprocessedQuestionSet)),
    shortCode,
    gameLink,
    assignments: assignments && assignments.map((assignment) => ProcessAssignment(assignment)),
    settings: ProcessCustomGameSettings(settings),
  };
};

export interface UnprocessedCustomGameSettings {
  gameTimeLimit: number;
  questionCount: number;
  questionOrder: "ordered" | "random";
  questionDistributionType: "divideBetweenPlayers" | "specifyPerPlayer";
  questionTimeLimit: number;
  soundEnabled: boolean;
  maxAnswers: number;
  ttsMode: "button_prompted" | "none";
  ttsOverrides: string;
  multiplayerSettings: string | null;
  copySettingsFromPrev: boolean;
  showQR: boolean;
}

export const ProcessCustomGameSettings = (
  customGameSettings: UnprocessedCustomGameSettings | undefined
): CustomGameSettings | undefined => {
  if (!Boolean(customGameSettings)) {
    return undefined;
  }

  const {
    gameTimeLimit,
    questionCount,
    questionOrder,
    questionTimeLimit,
    questionDistributionType,
    soundEnabled,
    maxAnswers,
    ttsMode,
    ttsOverrides,
    multiplayerSettings,
    copySettingsFromPrev,
    showQR,
  } = customGameSettings!;

  return {
    gameTimeLimit: secondsToMinutes(gameTimeLimit),
    questionCount,
    questionOrder,
    questionTimeLimit,
    questionDistributionType,
    soundEnabled,
    maxAnswers,
    ttsMode,
    ttsOverrides,
    multiplayerSettings: multiplayerSettings ? JSON.parse(multiplayerSettings) : null,
    copySettingsFromPrev,
    showQR,
  };
};
