import { ProcessQuestionSet } from "api/deserialisation";
import { QuestionSet } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface FavouriteQuestionSetParams {
  isFavourite: boolean;
}

const favouriteQuestionSet = async (
  id: string,
  params: FavouriteQuestionSetParams,
  token: string
): Promise<Partial<QuestionSet>> => {
  const response = await api.put(`/questionsets/${id}/favourite`, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response?.data) {
    const questionSet: QuestionSet = ProcessQuestionSet(response.data);
    return questionSet;
  } else {
    throw new NetworkError();
  }
};

export { favouriteQuestionSet };
