import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { ClassActions } from "./classListSlice";
import { resetAllStudentPasswords as resetAllStudentPasswordsApi } from "api";

const dispatchResetAllStudentPasswords = ({ classId }: { classId: string }, actions: ClassActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const class_ = await resetAllStudentPasswordsApi({
      token,
      classId,
    });
    dispatch(actions.addSingle({ classId: class_.id, class: class_ }));
  }, dispatch);

export { dispatchResetAllStudentPasswords };
