import { ProcessSession, UnprocessedSession } from "api/deserialisation";
import { Session } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  search: string;
  take: number;
  skip: number;
  order?: string;
  orderby?: string;
}

const fetchPremadeSessions = async ({
  search,
  take,
  skip,
  order = "createdAt",
  orderby = "dec",
}: Params): Promise<{ data: Session[]; total: number }> => {
  const url = `/sessions/premade?search=${search}&take=${take}&skip=${skip}&order=${order}&orderby=${orderby}`;
  const response = await api.get(url);

  const { data: sessionData, total } = response.data;

  const sessions: Session[] = sessionData.map((session: UnprocessedSession) => ProcessSession(session));

  if (sessions) {
    return {
      data: sessions,
      total,
    };
  } else {
    throw new NetworkError();
  }
};

export { fetchPremadeSessions };
