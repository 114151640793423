import { useAppDispatch } from "app/hooks";
import dayjs from "dayjs";
import { Row, WideActionButton } from "primitives";
import { createReviewSubmission } from "slices";
import { colors } from "styles";
import { Names, PlatformTitle } from "textConstants";
import { FC, QuestionSet, UserAction } from "types";
import { ReviewState, ReviewSubmission } from "types/ReviewSubmission";

const useReviewActions = (
  questionSet: QuestionSet,
  latestSubmission: ReviewSubmission | null,
  saveQuestionSet: () => Promise<void>,
  enabled: () => boolean
): UserAction[] => {
  const dispatch = useAppDispatch();

  return [
    {
      id: "unpublished",
      name: "Add To Public Library",
      isAvailable: () => !latestSubmission,
      isEnabled: enabled,
      action: async () => {
        await saveQuestionSet();
        await dispatch(createReviewSubmission(questionSet.id));
      },
      confirmationDetails: {
        title: (
          <>
            Submit <span style={{ color: colors.primary }}>Question Set</span> For Review?
          </>
        ),
        body: [
          `Public ${Names.questionset} can be used by other teachers, but they need to go through a review process first. A member of the ${PlatformTitle} team will review your ${Names.questionset} within the next few days and you will receive an update when your ${Names.questionset} is made available to others.`,
        ],
        confirmTitle: "Submit",
      },
    },
    {
      id: "resubmit",
      name: "Save & Resubmit",
      isAvailable: () =>
        Boolean(latestSubmission) &&
        latestSubmission!.status === ReviewState.Rejected &&
        dayjs(latestSubmission!.modifiedAt) < dayjs(questionSet.modifiedAt),
      isEnabled: enabled,
      action: async () => {
        await saveQuestionSet();
        await dispatch(createReviewSubmission(questionSet.id));
      },
    },
    {
      id: "update",
      name: "Save & Update Public",
      isAvailable: () =>
        Boolean(latestSubmission) &&
        latestSubmission!.status === ReviewState.Resolved &&
        dayjs(latestSubmission!.modifiedAt) < dayjs(questionSet.modifiedAt),
      isEnabled: enabled,
      action: async () => {
        await saveQuestionSet();
        await dispatch(createReviewSubmission(questionSet.id));
      },
    },
  ];
};

interface Props {
  questionSet: QuestionSet;
  latestSubmission: ReviewSubmission | null;
  saveQuestionSet: () => Promise<void>;
  enabled?: () => boolean;
}

export const GetReviewActionButton: FC<Props> = ({
  questionSet,
  latestSubmission,
  saveQuestionSet,
  enabled = () => true,
}) => {
  const reviewActions = useReviewActions(questionSet, latestSubmission, saveQuestionSet, enabled);

  return (
    <Row>
      {reviewActions.map((action, index) => {
        return (
          <WideActionButton key={index} action={action} icon={action.icon} iconSize="M">
            {action.name}
          </WideActionButton>
        );
      })}
    </Row>
  );
};
