import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { ArticleQueryParams } from "../../api";
import { Article } from "../../types";
import { dispatchFetchAllPreviewArticles } from "./fetchAllPreviewArticles";
import { dispatchFetchArticles } from "./fetchArticles";

type ArticlesListState = {
  data: { [id: string]: Partial<Article> } | null;
  fetchedPreviews: Boolean;
};

const initialState: ArticlesListState = {
  data: null,
  fetchedPreviews: false,
};

// --- Slice Definition

const reducers = {
  reset(state: ArticlesListState) {
    state.data = null;
    state.fetchedPreviews = false;
  },
  addSingle(state: ArticlesListState, action: PayloadAction<Partial<Article>>) {
    if (action.payload.id) {
      if (state.data) {
        state.data[action.payload.id] = merge(state.data[action.payload.id], action.payload);
      } else {
        state.data = {
          [action.payload.id]: action.payload,
        };
      }
    }
  },
  addMany(state: ArticlesListState, action: PayloadAction<Partial<Article>[]>) {
    if (!state.data) {
      state.data = {};
    }

    for (const article of action.payload) {
      if (article.id) {
        if (!(article.id in state.data)) {
          state.data[article.id] = article;
        } else {
          state.data[article.id] = merge(state.data[article.id], article);
        }
      }
    }
  },
  setFetchedPreviews(state: ArticlesListState, action: PayloadAction<Boolean>) {
    state.fetchedPreviews = action.payload;
  },
};

const slice = createSlice<ArticlesListState, typeof reducers>({
  name: "articles",
  initialState,
  reducers,
});

// --- Async Action Wrappers
const fetchArticles = (query: ArticleQueryParams) => dispatchFetchArticles(query, slice.actions);
const fetchAllPreviewArticles = () => dispatchFetchAllPreviewArticles(slice.actions);

// --- Exports
export type ArticleActions = typeof slice.actions;

const articlesReducer = slice.reducer;
export { articlesReducer, fetchArticles, fetchAllPreviewArticles };
