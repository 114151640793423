import classNames from "classnames";
import { Center, Spinner } from "primitives";
import { createRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSSProperties } from "styled-components";
import { FC } from "types";

interface Props {
  currentDataLength: number;
  hasMoreToFetch: boolean;
  fetchMoreData: () => void;
  hasData?: boolean;
  onScroll?: () => void;
  infiniteScrollStyle?: CSSProperties;
}

const defaultInfiniteScrollStyle: CSSProperties = {
  height: "100%",
  overflowY: "hidden",
};

export const InfiniteScrollList: FC<Props> = ({
  currentDataLength,
  hasMoreToFetch,
  fetchMoreData,
  onScroll,
  style,
  children,
  hasData = true,
  className,
  infiniteScrollStyle = defaultInfiniteScrollStyle,
}) => {
  const scrollerRef = createRef<HTMLDivElement>();
  return (
    <div ref={scrollerRef} style={style} className={classNames(className)} id="scroll-wrap">
      {hasData && (
        <InfiniteScroll
          dataLength={currentDataLength}
          next={fetchMoreData}
          hasMore={hasMoreToFetch}
          loader={
            <Center style={{ height: "100%" }}>
              <Spinner />
            </Center>
          }
          scrollableTarget={"scroll-wrap"}
          style={infiniteScrollStyle}
          onScroll={onScroll}
        >
          {children}
        </InfiniteScroll>
      )}
    </div>
  );
};
