import { CustomGameSettingsWithEnabledAndValidValues, UpdateGameSettingsParams } from "components";
import { Column, Row, Spacer } from "primitives";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import { Names } from "textConstants";
import { CustomGameMultiplayerSettings, FC } from "types";

const multiplayerSetting = "multiplayerSettings";

interface Props {
  gameSettings: CustomGameSettingsWithEnabledAndValidValues;
  updateGameSettings: (update: UpdateGameSettingsParams) => void;
  multiplayerEnabled?: () => void;
  readonly: boolean;
}

export const MultiplayerSettingsController: FC<Props> = ({
  gameSettings,
  multiplayerEnabled,
  updateGameSettings,
  readonly,
}) => {
  const { enabled, value, error } = gameSettings.multiplayerSettings;

  const toggleSetting = () => {
    if (!enabled && multiplayerEnabled) {
      multiplayerEnabled();
    }

    updateGameSettings({
      [multiplayerSetting]: {
        enabled: !enabled,
      },
    });
  };

  const settingsChanged = (updatedSettings: Partial<CustomGameMultiplayerSettings>) => {
    const multiplayerSettings = { ...gameSettings.multiplayerSettings.value, ...updatedSettings };

    updateGameSettings({
      [multiplayerSetting]: {
        value: multiplayerSettings,
      },
    });
  };

  return (
    <OuterContainer>
      <Row>
        <HalfWidthColumn>
          <TopRow>
            <SquareInput type="checkbox" checked={enabled} onChange={toggleSetting} disabled={readonly} />
            <label>Will Students play this {Names.customGame} in groups?</label>
          </TopRow>
        </HalfWidthColumn>
        {enabled && (
          <HalfWidthColumn>
            <TopRow>
              <SquareInput
                type="checkbox"
                checked={value.allowGuests}
                onChange={() => {
                  settingsChanged({ allowGuests: !value.allowGuests });
                }}
                disabled={readonly}
              />
              <Column>
                <label>Does this {Names.customGame} allow guests?</label>
                <small>Guests do not need to log in to play, but do not record any answers.</small>
              </Column>
            </TopRow>
          </HalfWidthColumn>
        )}
      </Row>
      {enabled && (
        <>
          <Spacer size={10} />
          <Row>
            <HalfWidthColumn>
              <label>Minimum Players</label>
              <FormControl
                isInvalid={Boolean(error)}
                value={value.minPlayers}
                type="number"
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue < 0) return;

                  settingsChanged({
                    minPlayers: newValue,
                    maxPlayers: Math.max(newValue, value.maxPlayers),
                  });
                }}
                style={{
                  border: "1px solid black",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  maxWidth: 225,
                }}
                min={1}
                max={6}
                disabled={readonly}
              />
            </HalfWidthColumn>
            <HalfWidthColumn>
              <label>Maximum Players</label>
              <FormControl
                isInvalid={Boolean(error)}
                value={value.maxPlayers}
                type="number"
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (newValue < 0) return;

                  settingsChanged({
                    maxPlayers: newValue,
                    minPlayers: Math.min(newValue, value.minPlayers),
                  });
                }}
                style={{
                  border: "1px solid black",
                  borderRadius: "0px",
                  backgroundColor: "white",
                  maxWidth: 225,
                }}
                min={2}
                max={6}
                disabled={readonly}
              />
            </HalfWidthColumn>
          </Row>
        </>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  padding-left: 5px;
  width: 100%;
`;

const TopRow = styled(Row)`
  gap: 11px;
`;

const HalfWidthColumn = styled(Column)`
  width: 50%;
`;

const SquareInput = styled.input`
  border-radius: 0px;
  width: 20px;
  height: 23px;
`;
