import { ExpandLess, ExpandMore } from "@styled-icons/material";
import { FC } from "react";
import { colors } from "styles";
import { OrderBy } from "types";
import { MedIcon } from "primitives/icons";
import styled from "styled-components";

interface Props {
  orderBy: OrderBy;
  setOrderBy: (orderBy: OrderBy) => void;
}

const QuestionSetOrderButton: FC<Props> = ({ orderBy, setOrderBy }) => {
  const toggleOrderBy = () => {
    const newOrderBy = orderBy === OrderBy.Newest ? OrderBy.Oldest : OrderBy.Newest;
    setOrderBy(newOrderBy);
  };

  return (
    <OuterContainer
      color={colors.info}
      style={{
        fontSize: 14,
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: 90,
        cursor: "pointer",
      }}
      onClick={toggleOrderBy}
    >
      {orderBy === OrderBy.Newest ? (
        <>
          Newest <MedIcon icon={ExpandMore} style={{ marginLeft: 5 }} />
        </>
      ) : (
        <>
          Oldest <MedIcon icon={ExpandLess} style={{ marginLeft: 5 }} />
        </>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  border: 1px solid ${colors.darkText};
  height: 100%;
`;

export { QuestionSetOrderButton };
