import { Dispatch } from "react";
import { logout } from "slices";
import { ApiError } from "types";
import { tryApiCall } from "./tryApiCall";

export const dispatchAsyncFunction = async <T>(
  businessLogic: (token: string) => Promise<T>,
  dispatch: Dispatch<any>
) => {
  return await tryApiCall<T>({
    onTry: businessLogic,
    onError: (error) => {
      throw new Error(ApiError[error]);
    },
    onNotLoggedIn: () => {
      dispatch(logout());
    },
  });
};
