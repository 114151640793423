import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ClassActions } from "./classListSlice";
import { deleteClass as deleteClassApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";

export interface DeleteClassParams {
  classId: string;
}

const dispatchDeleteClass = ({ classId }: DeleteClassParams, actions: ClassActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    await deleteClassApi({ token, classId });

    dispatch(actions.remove({ classId }));
  }, dispatch);

export { dispatchDeleteClass };
