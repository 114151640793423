import styled from "styled-components";

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const VerticalCenter = styled(Center)`
  flex-direction: column;
`;

const HorizontalCenter = styled(Center)`
  flex-direction: row;
`;

export { Center, VerticalCenter, HorizontalCenter };
