import { getCustomGameUrlFromCode } from "api";
import { useBlurOverlay } from "hooks";
import { useParams } from "react-router-dom";

const GameCodeRedirect = () => {
  const { blurApp } = useBlurOverlay();

  blurApp();

  const { gameCode } = useParams<{ gameCode: string }>();

  getCustomGameUrlFromCode(gameCode)
    .then((link) => window.location.replace(link))
    .catch(() => window.location.replace("http://played.com.au"));

  return null;
};

export { GameCodeRedirect };
