import { Center, Spinner } from "primitives";
import styled from "styled-components";
import { FC, ImageSize } from "types";
import { useState } from "react";
import { colors } from "styles";
import { DeleteButton } from "primitives/button";

interface Props {
  imageUrl: string | null;
  onPressDelete?: () => void;
  loadedImageSize?: ImageSize;
  imageSize?: string;
}

const ImagePreview: FC<Props> = ({ imageUrl, onPressDelete, loadedImageSize, imageSize, children, ...rest }) => {
  const [loading, setLoading] = useState(true);
  if (imageUrl) {
    return (
      <OuterContainer {...rest}>
        <img
          src={imageUrl + (loadedImageSize || loadedImageSize === ImageSize.Raw ? loadedImageSize : ImageSize.Tiny)}
          alt={imageUrl}
          height={loading ? 0 : imageSize ? imageSize : "90%"}
          width={loading ? 0 : imageSize ? imageSize : "90%"}
          onLoad={() => setLoading(false)}
        />
        {onPressDelete && (
          <DeleteContainer>
            <DeleteButton tooltip={"Delete"} altIcon={true} onClick={onPressDelete} />
          </DeleteContainer>
        )}
        <Spinner style={{ display: loading ? "flex" : "none", flex: 1 }} />
        {children}
      </OuterContainer>
    );
  } else {
    return null;
  }
};

const OuterContainer = styled(Center)`
  width: 130px;
  height: 130px;
  border: 1.5px solid ${colors.darkText};
  overflow: hidden;
  position: relative;
  background-color: ${colors.white};
`;

const DeleteContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export { ImagePreview };
