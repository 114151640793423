import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import { GetCardStyleForResult, InfiniteScrollList, ListTabProps, ListTabRow, LoadingContent } from "components";
import { SessionActionHook } from "hooks";
import { PreviewIcon } from "icons";
import { has } from "lodash";
import { LessonPlanListItem } from "pages/my-games/lessonPlans/LessonPlanListItem";
import { ResultIndicator } from "pages/progress/reviewClass/ReviewStudentInfoItem";
import { Center, HorizontalLine, Row, Spacer, VerticalLine } from "primitives";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { fetchSessionsForStudentReport, StudentSessionQuery } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { actionConstants, Names, pathUntil, slugs } from "textConstants";
import { FC, getSessionType, PaginatedSessionReport, Session, SessionType } from "types";
import {
  getCorrectCount,
  getCorrectPercentage,
  getDidParticipate,
  getResultForStudent,
  getResultsForStudent,
  getTotalQuestions,
} from "utils";
import { StudentRecordSessionReview } from "./StudentRecordSessionReview";

interface Props {
  studentId: string;
  upcomingSessionsCount: number;
  completedSessionsCount: number;
  selectedSessionIds?: string[];
}

interface SessionListProps {
  paginatedDataTotal: number;
  paginatedSessions: Session[];
  paginatedSummary?: PaginatedSessionReport["summary"];
  selectedTab: string;
  omitActionsList: string[];
  actions: SessionActionHook[];
  studentId: string;
  emptyMessage?: string;
  fetchMoreSessions: () => void;
}

const pageSize = 10;

const InfiniteSessionList: FC<SessionListProps> = ({
  paginatedDataTotal,
  paginatedSessions,
  paginatedSummary,
  omitActionsList,
  actions,
  emptyMessage,
  fetchMoreSessions,
}) => {
  return Boolean(paginatedSessions?.length) ? (
    <SessionContainer>
      {paginatedSummary && (
        <ResultsSummaryRow style={{ color: colors.secondary }}>
          <b>Correct Answers:</b>
          <ResultIndicator
            colour={
              GetCardStyleForResult(
                getCorrectPercentage(paginatedSummary.correctAnswers, paginatedSummary.totalAnswers),
                paginatedSummary.totalAnswers,
                true
              ).inactivePrimaryColor
            }
          />
          {paginatedSummary.correctAnswers}/{paginatedSummary.totalAnswers} (
          {Math.round(getCorrectPercentage(paginatedSummary.correctAnswers, paginatedSummary.totalAnswers) * 100)}
          %)
        </ResultsSummaryRow>
      )}
      <SessionListContainer
        hasData={Boolean(paginatedDataTotal)}
        currentDataLength={paginatedSessions.length}
        fetchMoreData={fetchMoreSessions}
        hasMoreToFetch={paginatedSessions.length < paginatedDataTotal}
      >
        {paginatedSessions.map((session) => (
          <LessonPlanListItem
            key={session.id}
            session={session}
            sessionType={getSessionType(session)}
            omitActions={omitActionsList}
            includeActions={actions}
          />
        ))}
      </SessionListContainer>
    </SessionContainer>
  ) : (
    <Center>
      <p>{emptyMessage || `No ${Names.Sessions} Found`}</p>
    </Center>
  );
};

const SessionListContainer = styled(InfiniteScrollList)`
  flex: 1 1 0;
  overflow-y: auto;
`;

const SessionResultsSummary: FC<{ session: Session; studentId: string }> = ({ session, studentId }) => {
  const results = getResultForStudent(session, studentId);

  const correct = session.customGames?.reduce((a, b) => a + getCorrectCount(results[b.id].bestResult), 0) || 0;
  const total = session.customGames?.reduce((a, b) => a + getTotalQuestions(results[b.id].bestResult), 0) || 0;

  return (
    <Row style={{ gap: "5px", alignItems: "center", color: colors.secondary }}>
      <VerticalLine />
      <b>Results:</b>
      <ResultIndicator
        colour={GetCardStyleForResult(getCorrectPercentage(correct, total), total, true).inactivePrimaryColor}
      />
      {correct}/{total} ({Math.round(getCorrectPercentage(correct, total) * 100)}%)
    </Row>
  );
};

const SelectedScrollList: FC<SessionListProps> = ({ paginatedSessions, actions, omitActionsList, studentId }) => {
  const completed = paginatedSessions.filter((session) => {
    const results = getResultForStudent(session, studentId);
    return getDidParticipate(
      results,
      session.customGames!.map((customGame) => customGame.id)
    );
  });
  const incomplete = paginatedSessions.filter((session) => !completed.includes(session));

  const completedResults = getResultsForStudent(completed, studentId)
    .filter((result) => Boolean(result))
    .map((result) => Object.values(result!))
    .flat();
  const completedCorrect = completedResults.reduce((a, b) => a + getCorrectCount(b.bestResult), 0);
  const completedTotal = completedResults.reduce((a, b) => a + getTotalQuestions(b.bestResult), 0);

  return Boolean(paginatedSessions?.length) ? (
    <SelectedSessionListContainer>
      <ResultsSummaryRow>
        <p>
          <b>
            Completed {Names.Sessions} ({completed.length})
          </b>
        </p>
        <VerticalLine margin="3px 3px" />
        <Row style={{ color: colors.secondary, alignItems: "center", gap: "var(--sp-2)" }}>
          <b>Correct Answers:</b>
          <ResultIndicator
            colour={
              GetCardStyleForResult(getCorrectPercentage(completedCorrect, completedTotal), completedTotal, true)
                .inactivePrimaryColor
            }
          />
          {completedCorrect}/{completedTotal} (
          {Math.round(getCorrectPercentage(completedCorrect, completedTotal) * 100)}%)
        </Row>
        <HorizontalLine margin="3px 0px" />
      </ResultsSummaryRow>
      {completed.map((session) => (
        <LessonPlanListItem
          key={session.id}
          session={session}
          sessionType={getSessionType(session)}
          omitActions={omitActionsList}
          includeActions={actions}
          sessionResultsSummary={<SessionResultsSummary session={session} studentId={studentId} />}
        />
      ))}
      <Spacer />
      <Row style={{ alignItems: "center", gap: "var(--sp-2)" }}>
        <p style={{ whiteSpace: "nowrap" }}>
          <b>
            Incomplete {Names.Sessions} ({incomplete.length})
          </b>
        </p>
        <HorizontalLine />
      </Row>
      {incomplete.map((session) => (
        <LessonPlanListItem
          key={session.id}
          session={session}
          sessionType={getSessionType(session)}
          omitActions={["delete", "duplicate", "preview", "start", "edit", "viewResults"]}
          includeActions={actions}
        />
      ))}
    </SelectedSessionListContainer>
  ) : (
    <Center>
      <p>
        No {Names.Sessions} Selected. Return to the previous screen and select some {Names.Sessions} from the list.
      </p>
    </Center>
  );
};

const sessionTabs: ListTabProps[] = [
  {
    key: "upcoming",
    text: `Upcoming`,
  },
  {
    key: "inProgress",
    text: `In Progress`,
  },
  {
    key: "completed",
    text: `Completed`,
  },
];

const sessionQueries: {
  [tabKey: string]: StudentSessionQuery;
} = {
  upcoming: {
    sessionType: SessionType.Upcoming,
    orderby: "dec",
    order: "startTime",
  },
  inProgress: {
    sessionType: SessionType.Current,
    completionRequirement: "SomeIncomplete",
    orderby: "dec",
    order: "endTime",
  },
  completed: {
    sessionType: SessionType.Started,
    completionRequirement: "AllCompleteOrEnded",
    orderby: "dec",
    order: "endTime",
  },
  selected: {
    sessionType: undefined,
    completionRequirement: "SomeComplete",
    orderby: "dec",
    order: "endTime",
  },
};

export const StudentRecordSessions: FC<Props> = ({ studentId, selectedSessionIds }) => {
  const { sessions, paginationData } = useAppSelector((state) => state.student);
  const { data: teacherSessions } = useAppSelector((state: RootState) => state.sessionList);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const { path } = useRouteMatch();
  const { classId } = useParams<{ classId: string }>();
  const [reviewSessionId, setReviewSessionId] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(selectedSessionIds ? "selected" : "upcoming");

  let selectedSessions =
    selectedSessionIds && teacherSessions
      ? selectedSessionIds
          .map((id) => teacherSessions[id])
          .filter((session) =>
            session.customGames?.some((customGame) =>
              customGame.assignments?.some((assignment) => assignment.student.id === studentId)
            )
          )
      : [];
  let paginatedSessions = (paginationData.orderedSessionIds[selectedTab] || []).map((sessionId) => sessions[sessionId]);

  let tabs = [...sessionTabs];
  if (selectedSessionIds) {
    tabs = [
      {
        key: "selected",
        text: `Selected`,
      },
      ...tabs,
    ];
  }

  const emptyTabMessages: { [tab: string]: string } = {
    upcoming: `No upcoming ${Names.Sessions} are assigned to this student`,
    completed: `This student has not completed any ${Names.Sessions} yet`,
  };

  const reviewActionHook: SessionActionHook = (session: Session) => {
    return [
      {
        id: "review",
        name: "See Student Results",
        action: async () => {
          setReviewSessionId(session.id);
        },
        priority: 0,
        icon: PreviewIcon,
        isAvailable: () =>
          session.customGames?.some((customgame) =>
            customgame.assignments?.some((assignment) => Boolean(assignment.results.length))
          ) || false,
      },
    ];
  };

  const previewActionHook: SessionActionHook = (session: Session) => {
    return [
      {
        ...actionConstants.preview,
        id: "preview",
        action: async () => {
          const basePath = generatePath(pathUntil(path, slugs.classId), { classId });
          history.push(basePath + `/${slugs.session}/${session.id}/${slugs.preview}`);
        },
        isAvailable: () => selectedTab !== "completed",
        priority: 10,
      },
    ];
  };

  const dispatchFetchSessions = useCallback(() => {
    if (currentPage && sessionQueries[selectedTab] && studentId) {
      dispatch(fetchSessionsForStudentReport(studentId, sessionQueries, selectedTab, pageSize, currentPage));
    }
  }, [dispatch, currentPage, selectedTab, studentId]);

  useEffect(() => {
    loadSessions(selectedTab, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      dispatchFetchSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const loadSessions = (tab: string, loadCounts: boolean = false) => {
    if (studentId) {
      if (has(sessionQueries, tab)) {
        dispatch(fetchSessionsForStudentReport(studentId, sessionQueries, tab, pageSize, 0, loadCounts));
      } else if (loadCounts) {
        dispatch(fetchSessionsForStudentReport(studentId, sessionQueries, sessionTabs[0].key, 1, 0, loadCounts));
      }
    }
  };

  const tabSelected = (tab: string) => {
    setCurrentPage(0);
    setReviewSessionId(undefined);
    setSelectedTab(tab);
  };

  const fetchMoreSessions = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleReviewBackPressed = () => {
    setReviewSessionId(undefined);
  };

  const getTabTotals = () => ({
    ...paginationData.totals,
    selected: selectedSessions.length,
  });

  return (
    <>
      <ListTabRow tabs={tabs} selectedTab={selectedTab} setSelectedTab={tabSelected} totalsSelector={getTabTotals} />
      {reviewSessionId ? (
        <StudentRecordSessionReview selectedSessionId={reviewSessionId} onBackPressed={handleReviewBackPressed} />
      ) : (
        <>
          {selectedTab === "selected" ? (
            <SelectedScrollList
              paginatedDataTotal={selectedSessions.length}
              paginatedSessions={selectedSessions}
              selectedTab={selectedTab}
              omitActionsList={["delete", "duplicate", "preview", "start", "edit", "viewResults"]}
              actions={[reviewActionHook]}
              studentId={studentId}
              fetchMoreSessions={fetchMoreSessions}
            />
          ) : (
            <LoadingContent loading={paginationData.totals[selectedTab] === undefined}>
              <InfiniteSessionList
                paginatedDataTotal={paginationData.totals[selectedTab]}
                paginatedSessions={paginatedSessions}
                paginatedSummary={
                  sessionQueries[selectedTab].completionRequirement ? paginationData.summaries[selectedTab] : undefined
                }
                selectedTab={selectedTab}
                omitActionsList={["delete", "duplicate"]}
                actions={[reviewActionHook, previewActionHook]}
                studentId={studentId}
                fetchMoreSessions={fetchMoreSessions}
                emptyMessage={emptyTabMessages[selectedTab]}
              />
            </LoadingContent>
          )}
        </>
      )}
    </>
  );
};

const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--sp-3) 0px;
  flex: 1 1 0;
  padding-top: var(--sp-2);
`;

const SelectedSessionListContainer = styled(SessionContainer)`
  flex: 1 1 0;
  overflow-y: auto;
  padding-top: var(--sp-2);
`;

const ResultsSummaryRow = styled(Row)`
  align-items: center;
  gap: var(--sp-2);
  white-space: nowrap;
`;
