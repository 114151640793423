import { ProcessGrouping } from "api/deserialisation";
import { Grouping } from "types";
import { api } from "api/api";
import { NetworkError } from "api/networkError";

interface GetGroupingParams {
  token: string;
  groupingId: string;
}

export const getGrouping = async ({ token, groupingId }: GetGroupingParams): Promise<Grouping> => {
  const response = await api.get(`/groupings/${groupingId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const grouping = ProcessGrouping(response.data);

  if (grouping) {
    return grouping;
  } else {
    throw new NetworkError();
  }
};
