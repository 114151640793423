import { getAPIVersion } from "slices";
import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useVersion = () => {
  useEffect(() => {
    dispatch(getAPIVersion());
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.version);

  const functions = {
    getAPIVersion: () => dispatch(getAPIVersion()),
  };

  return { ...state, ...functions };
};

export { useVersion };
