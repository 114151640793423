import { sortBy } from "lodash";
import { Result, ResultAnswer } from "types";
import { UnprocessedQuestion } from "./question";

export interface UnprocessedResult {
  id: string;
  createdAt: string | null;
  deletedAt: string | null;
  modifiedAt: string | null;
  answers: Array<UnprocessesResultAnswer>;
}

interface UnprocessesResultAnswer {
  id: string;
  correct: boolean;
  answer: string;
  question: Partial<UnprocessedQuestion>;
  order?: number;
}

export const ProcessResult = (result: UnprocessedResult): Result => {
  const { id, createdAt, deletedAt, modifiedAt, answers } = result;

  return {
    id,
    createdAt,
    deletedAt,
    modifiedAt,
    answers: answers
      ? sortBy(
          answers.map((answer) => ProcessResultAnswer(answer)),
          (answer) => answer.order || 0
        )
      : [],
  };
};

const ProcessResultAnswer = (resultAnswer: UnprocessesResultAnswer): ResultAnswer => {
  const { id, correct, answer, question, order } = resultAnswer;

  return {
    id,
    wasCorrect: correct,
    selectedAnswer: answer,
    question,
    order,
  };
};
