const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

const documentIsFullScreen = () => {
  const isFullScreen = Boolean(document.fullscreenElement);
  return isFullScreen;
};

export { toggleFullScreen, documentIsFullScreen };
