import { Button } from "primitives/button";
import styled from "styled-components";

const SelectorOuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const SelectorContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const ConfirmButton = styled(Button)`
  width: 150px;
`;

export { SelectorOuterContainer, SelectorContent, ConfirmButton };
