import { api } from "./api";
import { NetworkError } from "./networkError";

const getAPIVersion = async (): Promise<{ version: string }> => {
  const response = await api.get("/common/version");
  if (response?.data?.version) {
    return { version: response.data.version };
  } else {
    throw new NetworkError();
  }
};

export { getAPIVersion };
