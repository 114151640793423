import { fetchQuestionSets as fetchQuestionSetsApi } from "api";
import { OrderBy } from "types";
import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { dispatchAsyncFunction } from "slices/sliceHelpers";

export interface FetchQuestionSetListParams {
  orderBy: OrderBy;
  resultsPerPage: number;
  requestedPage: number;
  filterText?: string;
  archived?: boolean;
  visibility?: "public" | "private" | "draft";
  featured?: boolean;
  gradeTags?: string;
  subjectTags?: string;
  isFavourite?: boolean;
  onlyMyOwn: boolean;
}

const dispatchFetchQuestionSetList = (fetchParams: FetchQuestionSetListParams, actions: QuestionSetActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));
    const data = await fetchQuestionSetListInternal(fetchParams, getState);
    dispatch(actions.addMany({ data, requestToken }));
  }, dispatch);

const fetchQuestionSetListInternal = async (
  {
    orderBy,
    resultsPerPage,
    requestedPage,
    filterText,
    archived,
    visibility,
    featured,
    gradeTags,
    subjectTags,
    isFavourite,
    onlyMyOwn,
  }: FetchQuestionSetListParams,
  getState: () => RootState
) => {
  const { token, userInfo } = getState().login;
  const data = await fetchQuestionSetsApi(
    {
      orderBy,
      order: "modifiedAt",
      resultsPerPage,
      requestedPage,
      teacherId: userInfo?.id,
      filterText,
      archived,
      featured,
      visibility,
      gradeTags,
      subjectTags,
      isFavourite,
      onlyMyOwn,
    },
    token
  );
  return data;
};

export { dispatchFetchQuestionSetList };
