import { Close, Delete, Done } from "@styled-icons/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import classNames from "classnames";
import { AnswerDisplay, MissingQuestionDataId, QuestionDisplay, QuestionSetQuestionImage } from "components";
import pluralize from "pluralize";
import { Center, SecondaryText, Spinner } from "primitives";
import { LargeIcon, MedIcon } from "primitives/icons";
import { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { fetchQuestionSetNew } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { breadcrumbConstants } from "textConstants";
import { defaultRequestState, QuestionSet } from "types";
import { BestResult, dispatchAsync } from "utils";

interface Props {
  questionSet: QuestionSet;
  className?: string;
  bestResult?: BestResult;
  onClick?: () => void;
}

const correctCellStyle = {
  background: colors.brandDarkGreen,
  color: colors.white,
};

export const MiniGameResultsPreview: FC<Props> = ({ questionSet, bestResult, className }) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const dispatch = useAppDispatch();
  const myQuestionSet = useAppSelector(
    (state) => state.questionSets.data && questionSet && state.questionSets.data[questionSet.id]
  );
  useEffect(() => {
    if (questionSet?.id && !myQuestionSet?.questions) {
      dispatchAsync(dispatch(fetchQuestionSetNew(questionSet.id)), setRequestState);
    }
  }, [dispatch, myQuestionSet, questionSet?.id]);

  const isDeleted = questionSet.id === MissingQuestionDataId;

  const questionSetResults = isDeleted
    ? bestResult?.bestResult &&
      bestResult.bestResult.answers.filter((answer) =>
        questionSet!.questions!.find((question) => question.id === answer.question.id)
      )
    : bestResult?.bestResult &&
      myQuestionSet?.questions &&
      bestResult.bestResult.answers.filter((answer) =>
        myQuestionSet!.questions!.find((question) => question.id === answer.question.id)
      );

  const questionDefault = isDeleted ? "Question was deleted" : "";
  const questionImageDefault = isDeleted ? ["Question was deleted"] : [];

  return (
    <>
      <MiniGameQuestionSetBox questionSet={questionSet} className="mb-2" />

      {loading ? (
        <Spinner />
      ) : Boolean(questionSetResults?.length) ? (
        <QuestionBox>
          <QuestionHeaderRow>
            <tr>
              <th style={{ width: "40%" }}>Question</th>
              <th style={{ width: "30%" }}>Answer Given</th>
              <th style={{ ...correctCellStyle, width: "30%" }}>Correct Answer</th>
              <th style={{ width: "6%" }} />
            </tr>
          </QuestionHeaderRow>
          <QuestionBodyRow>
            {questionSetResults &&
              questionSetResults.map((answer, index) => {
                const questionSet = myQuestionSet?.questions?.find((question) => question.id === answer.question.id);
                const question = questionSet?.question || questionDefault;
                const questionImage = questionSet?.questionImage || null;
                const questionType = questionSet?.type || "multichoice";
                const answers = questionSet?.answers || questionImageDefault;
                const answerImages = questionSet?.answerImages || [];
                return (
                  <tr key={index}>
                    <td>
                      <QuestionDisplay
                        questionNumber={index + 1}
                        question={question}
                        questionImage={questionImage}
                        questionType={questionType}
                      />
                    </td>
                    <td>
                      {answer.selectedAnswer.startsWith("https://") ? (
                        <div className="text-right">
                          <QuestionSetQuestionImage imageUrl={answer.selectedAnswer} />
                        </div>
                      ) : (
                        answer.selectedAnswer || <SecondaryText>No Answer</SecondaryText>
                      )}
                    </td>
                    <td style={correctCellStyle}>
                      <AnswerDisplay answers={answers} answerImages={answerImages} answerIndex={0} isCorrect={true} />
                    </td>
                    <td>
                      <Center>
                        {answer.wasCorrect ? (
                          <LargeIcon icon={Done} color={colors.success} />
                        ) : (
                          <LargeIcon icon={Close} color={colors.danger} />
                        )}
                      </Center>
                    </td>
                  </tr>
                );
              })}
          </QuestionBodyRow>
        </QuestionBox>
      ) : (
        <p>No Questions in this Set were Answered</p>
      )}
    </>
  );
};

export const MiniGameQuestionSetBox: FC<Props> = ({ onClick, questionSet, className }) => {
  return (
    <TitleRow className={classNames(className)}>
      <TitleHeader>
        <MedIcon
          className="mr-2"
          icon={questionSet.id === MissingQuestionDataId ? Delete : breadcrumbConstants.questions.icon}
        />
        {onClick ? (
          <QuestionSetTitleButton onClick={onClick}>{questionSet.title}</QuestionSetTitleButton>
        ) : (
          questionSet.title
        )}
      </TitleHeader>
      <TitleItem>
        {questionSet.numQuestions} {pluralize("question", questionSet.numQuestions)}
      </TitleItem>
    </TitleRow>
  );
};

const QuestionSetTitleButton = styled(Button).attrs({ variant: "link", size: "sm" })`
  padding: 0;
  font-size: 1em;
  font-weight: 600;
`;

export const MiniGameQuestionSetPreview: FC<Props> = ({ questionSet }) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const dispatch = useAppDispatch();
  const myQuestionSet = useAppSelector((state) => state.questionSets.data && state.questionSets.data[questionSet.id]);
  useEffect(() => {
    if (questionSet.id && !myQuestionSet?.questions) {
      dispatchAsync(dispatch(fetchQuestionSetNew(questionSet.id)), setRequestState);
    }
  }, [dispatch, myQuestionSet, questionSet.id]);

  return (
    <>
      <MiniGameQuestionSetBox questionSet={questionSet} className="mb-2" />

      {loading ? (
        <Spinner />
      ) : (
        <QuestionBox>
          <QuestionHeaderRow>
            <tr>
              <th>Question</th>
              <th style={correctCellStyle}>Correct Answer</th>
            </tr>
          </QuestionHeaderRow>
          <QuestionBodyRow>
            {myQuestionSet?.questions?.map((question, index) => (
              <tr key={index}>
                <td>
                  <QuestionDisplay
                    questionNumber={index + 1}
                    question={question.question}
                    questionImage={question.questionImage}
                    questionType={question.type}
                  />
                </td>
                <td style={correctCellStyle}>
                  <AnswerDisplay
                    answers={question.answers}
                    answerImages={question.answerImages}
                    answerIndex={0}
                    isCorrect={true}
                  />
                </td>
              </tr>
            ))}
          </QuestionBodyRow>
        </QuestionBox>
      )}
    </>
  );
};

const QuestionHeaderRow = styled.thead`
  background: ${colors.transparentTertiary};
  font-weight: 600;
`;

const QuestionBodyRow = styled.tbody`
  background-color: white;
`;

const QuestionBox = styled.table.attrs(({ className }) => ({
  className: `p-0 ${className || ""}`,
}))`
  font-size: "0.8em";
  border 1px solid ${colors.primaryDivider};
  width: 100%;
  table-layout: fixed;

  th, td {
    border 1px solid ${colors.primaryDivider};
    padding: var(--sp-1) var(--sp-2);
  }
`;

const TitleRow = styled.div.attrs(({ className }) => ({
  className: `p-2 mt-2 ${className || ""}`,
}))`
  display: flex;
  flex-direction: row;
  background: ${colors.transparentPrimary};
`;

const TitleItem = styled.div.attrs(({ className }) => ({
  className: `px-2 ${className || ""}`,
}))`
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${colors.primaryDivider};

  &:last-child {
    border-right-width: 0;
  }

  &:first-child {
    padding-left: 0 !important;
  }
`;

const TitleHeader = styled(TitleItem)`
  font-weight: 600;
  color: ${colors.primary};
`;
