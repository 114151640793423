import dayjs from "dayjs";
import { Teacher } from "types";

// If the subscription is either active or trialing
export const hasActiveSubscription = (teacher: Teacher | null) => {
  return (
    teacher?.subscriptionInfo?.subscriptionStatus === "active" ||
    teacher?.subscriptionInfo?.subscriptionStatus === "trialing" ||
    teacher?.subscriptionInfo?.subscriptionStatus === "managed"
  );
};

// If the subscription end date is in the future (regardless of status)
export const hasValidSubscription = (teacher: Teacher | null) => {
  if (teacher?.subscriptionInfo?.subscriptionPeriodEnd) {
    const subscriptionEnd = dayjs(teacher.subscriptionInfo.subscriptionPeriodEnd!);

    if (subscriptionEnd > dayjs()) {
      return true;
    }
  }

  return false;
};
