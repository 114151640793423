import { useQuestionImages } from "hooks/redux/useQuestionImages";
import { Button } from "primitives/button";
import { Row, ToolTip } from "primitives";
import { FC } from "types";
import styled from "styled-components";
import { colors } from "styles";

interface Props {
  imageUrl: string | null;
  onSelectImage: () => void;
  disabled?: boolean | undefined;
}

const ImageSelector: FC<Props> = ({ imageUrl, onSelectImage, disabled, ...rest }) => {
  const { fetchQuestionImages, loading } = useQuestionImages();
  return (
    <Row className="add-image-button" {...rest}>
      <ToolTip tooltip={imageUrl ? "Change Image" : "Add Image"}>
        <Container
          disabled={disabled}
          onClick={() => {
            if (!loading) {
              fetchQuestionImages();
            }
            onSelectImage();
          }}
        >
          <ButtonText>Add Image</ButtonText>
        </Container>
      </ToolTip>
    </Row>
  );
};

const Container = styled(Button)`
  background-color: ${colors.darkText};
  border-color: ${colors.darkText};
  border-radius: 0%;
`;

const ButtonText = styled.p`
  min-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { ImageSelector };
