import { useEffect, useState } from "react";
import { FC } from "react";
import styled, { CSSProperties } from "styled-components";
import { RadioButton } from "./RadioButton";

type Props = React.ComponentProps<typeof RadioButtonContainer> & {
  buttonNames: string[];
  defaultIndex: number;
  selectedIndex: number;
  onChange: (index: number) => void;
  buttonStyle?: CSSProperties;
};

const RadioButtonGroup: FC<Props> = ({
  buttonNames,
  defaultIndex,
  selectedIndex,
  onChange,
  buttonStyle,
  style,
  ...rest
}) => {
  const startingButtonState = () => {
    const newStates = [false].fill(false, 0, buttonNames.length);
    newStates[selectedIndex] = true;
    return newStates;
  };
  const [buttonStates, setButtonStates] = useState<boolean[]>(startingButtonState());

  const SetRadioIndexState = (index: number) => {
    const newStates = [...buttonStates].fill(false);
    newStates[index] = true;
    return newStates;
  };

  useEffect(() => {
    setButtonStates(SetRadioIndexState(selectedIndex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const onButtonChange = (state: boolean, index: number) => {
    if (state) {
      setButtonStates(SetRadioIndexState(index));
      onChange(index);
    } else {
      setButtonStates(SetRadioIndexState(defaultIndex));
      onChange(defaultIndex);
    }
  };

  return (
    <RadioButtonContainer style={style}>
      {buttonNames.map((name, index) => {
        return (
          <RadioButton
            key={name}
            state={buttonStates[index] || false}
            onChange={(state) => {
              onButtonChange(state, index);
            }}
            style={buttonStyle}
          >
            {name}
          </RadioButton>
        );
      })}
    </RadioButtonContainer>
  );
};

const RadioButtonContainer = styled.div`
  display: flex;
  flex-directions: row;
  gap: 1em;
`;

export { RadioButtonGroup };
