import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { updateQuestionSet as updateQuestionSetApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSet } from "types";

const dispatchArchiveQuestionSet = (questionSetId: string, isArchived: boolean, actions: QuestionSetActions) => (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedQuestionSet = await updateQuestionSetApi(questionSetId, { archive: isArchived }, token);
    dispatch(
      actions.updateSingle({
        id: updatedQuestionSet.id!,
        questionSet: updatedQuestionSet as QuestionSet,
      })
    );
    return updatedQuestionSet;
  }, dispatch);

export { dispatchArchiveQuestionSet };
