export const runOnElements = (querySelector: string, func: (el: Element) => void) => {
  const interval = setInterval(() => {
    const elements = document.querySelectorAll(querySelector);
    if (elements.length > 0) {
      clearInterval(interval);
      for (let i = 0; i !== elements.length; ++i) {
        func(elements[i]);
      }
    }
  }, 100);
};

export const disableElement = (element: Element) => {
  element.setAttribute("disabled", "true");
  element.setAttribute("style", "pointer-events: none");
};

export const enableElement = (element: Element) => {
  element.removeAttribute("disabled");
  element.removeAttribute("style");
};

export const hideElement = (element: Element) => {
  element.setAttribute("style", "display: none");
};
