import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { CustomGameSettings, PartialNullable } from "types";
import { editCustomGame as editCustomGameApi } from "api";
import { CustomGameActions } from "./customGameSlice";
import { minutesToSeconds } from "utils";

export interface EditCustomGameParams {
  customGameId: string;
  gameTemplateId: string;
  questionSetIds: string[];
  customGameSettings: PartialNullable<CustomGameSettings>;
}

export const dispatchEditCustomGame = (
  { customGameId, gameTemplateId, questionSetIds, customGameSettings }: EditCustomGameParams,
  actions: CustomGameActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const customGame = await editCustomGameApi({
      token,
      customGameId,
      gameTemplateId,
      questionSetIds,
      settings: {
        ...customGameSettings,
        gameTimeLimit: customGameSettings?.gameTimeLimit ? minutesToSeconds(customGameSettings.gameTimeLimit) : null,
      },
    });

    dispatch(actions.addSingle({ id: customGame.id, customGame }));

    return customGame;
  }, dispatch);
