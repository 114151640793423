import { colors } from "styles";
import { FC } from "types";

interface Props {
  margin?: string;
  thickness?: number;
  color?: string;
}

const VerticalLine: FC<Props> = ({ thickness = 0.5, color = colors.lightActive, margin = "0.25em 1em", ...rest }) => (
  <div
    {...rest}
    style={{
      alignSelf: "stretch",
      borderStyle: "solid",
      borderWidth: 0,
      borderLeftWidth: thickness,
      borderColor: color,
      backgroundColor: color,
      margin: margin,
      ...rest.style,
    }}
  />
);
export { VerticalLine };
