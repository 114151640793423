import { BrowserRouter, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  Home,
  NotFound,
  AppLoading,
  PlayGame,
  GameCodeRedirect,
  StudentLoginPage,
  TeacherLoginPage,
  TeacherVerifyPage,
  RegisterRoutes,
  Logout,
  PlayQuickGame,
} from "pages";
import { BlurOverlay, PrivateStudentRoute, PrivateTeacherRoute } from "components";
import { useAppLoading, useCrisp, useRemoveTrailingSlash, useRestoreToken } from "hooks";
import { useAnalytics } from "analytics";
import { StudentHome } from "pages/student";
import { SubscriptionRoutes } from "pages/subscription";
import { PublicGameLibrary, PublicQuestionSetList } from "pages/public";
import { paths, slugs } from "textConstants";

const defaultRoute = "/login";

function Routes() {
  useRestoreToken(false);
  return (
    <BlurOverlay>
      <BrowserRouter getUserConfirmation={() => {}}>
        <Route path="" component={MainApp} />
      </BrowserRouter>
    </BlurOverlay>
  );
}

const MainApp = () => {
  const { loading } = useAppLoading();

  useRemoveTrailingSlash();
  useAnalytics();

  return loading ? (
    <AppLoading />
  ) : (
    <Switch>
      {publicRoutes}
      <Route path="/logout" component={Logout} />
      <Route path="/verify" component={TeacherVerifyPage} />
      <Route path="/register" component={RegisterRoutes} />
      <Route path="/login" component={LoginRoutes} />
      <Route path="/subscribe" component={SubscriptionRoutes} />

      <PrivateTeacherRoute path="/teacher" component={TeacherRoutes} />
      <PrivateStudentRoute path="/student" component={StudentRoutes} />
      <Route path="/" exact>
        <Redirect to={defaultRoute} />
      </Route>
      <Route path="/">
        <NotFound />
      </Route>
    </Switch>
  );
};

const LoginRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/teacher`} component={TeacherLoginPage} />
      <Route path={`${url}/student`} component={StudentLoginPage} />
      <Route path="/">
        <Redirect to={`${url}/teacher`} />
      </Route>
    </Switch>
  );
};

declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: string;
  }
}

const TeacherRoutes = () => {
  const { url } = useRouteMatch();
  useCrisp();

  return (
    <Switch>
      <Route path={`${paths.teacher.myGames.session.showQR.path}`} component={PlayGame} />
      <Route path={`${paths.teacher.myGames.miniGame.showQR.path}`} component={PlayQuickGame} />
      <Route path={url} component={Home} />
    </Switch>
  );
};

const StudentRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={url} component={StudentHome} />
    </Switch>
  );
};

const publicRoutes = [
  <Route key="runGameCode" path="/run/:gameCode" component={GameCodeRedirect} />,
  <Route key="publicQuestionSets" path={`/${slugs.public}/${slugs.questionSet}`} component={PublicQuestionSetList} />,
  <Route key="publicGameLibrary" path={`/${slugs.public}/${slugs.gamelibrary}`} component={PublicGameLibrary} />,
];

export default Routes;
