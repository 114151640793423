import { ArrowRight } from "@styled-icons/material";
import { SecondaryText, Spacer } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, QuestionSet } from "types";
import { ReviewState, ReviewSubmission } from "types/ReviewSubmission";
import { dateWithoutTime, questionSetIsFeatured } from "utils";

interface Props {
  latestSubmission: ReviewSubmission | null;
  questionSet: QuestionSet;
}

interface PublicProps {
  isFeatured: boolean;
  date?: Date | null;
}

const Public: FC<PublicProps> = ({ isFeatured, date }) => {
  return (
    <SpacedRow backgroundColour={isFeatured ? colors.primaryActive : colors.primary}>
      <Notch />
      <SecondaryText color={colors.white}>{isFeatured ? "Featured" : "Public"}</SecondaryText>
      {date && (
        <>
          <Spacer size={3} />
          <SecondaryText>{dateWithoutTime(date)}</SecondaryText>
        </>
      )}
    </SpacedRow>
  );
};

interface PendingRejectedProps {
  isUpdate?: boolean;
}

const Pending: FC<PendingRejectedProps> = ({ isUpdate }) => {
  return (
    <SpacedRow backgroundColour={colors.transparentPrimary}>
      <Notch />
      <SecondaryText color={colors.primary}>{isUpdate ? "Update " : ""}Under Review...</SecondaryText>
    </SpacedRow>
  );
};

const Rejected: FC<PendingRejectedProps> = ({ isUpdate }) => {
  return (
    <SpacedRow backgroundColour={colors.danger}>
      <Notch />
      <SecondaryText color={colors.white}>{isUpdate ? "Update " : ""}Rejected</SecondaryText>
    </SpacedRow>
  );
};

export const QuestionSetReviewStatus: FC<Props> = ({ questionSet, latestSubmission }) => {
  if (questionSet.publicQuestionSet) {
    if (latestSubmission) {
      switch (latestSubmission.status) {
        case ReviewState.Rejected:
          return <Rejected isUpdate={true} />;
        case ReviewState.Pending:
          return <Pending isUpdate={true} />;
      }
    }

    return <Public isFeatured={Boolean(questionSet.publicQuestionSet.isFeatured)} />;
  }

  if (latestSubmission) {
    const isFeatured = questionSetIsFeatured(questionSet);
    switch (latestSubmission.status) {
      case ReviewState.Rejected:
        return <Rejected />;
      case ReviewState.Pending:
        return <Pending />;
      case ReviewState.Resolved:
        return <Public isFeatured={isFeatured} />;
    }
  }

  return null;
};

const SpacedRow = styled.div<{ backgroundColour?: string }>`
  padding: 5px;
  background-color: ${(props) => props.backgroundColour || colors.primary};
  position: relative;
  padding-left: 20px;
  border-radius: 3px;
  overflow: hidden;
`;

const Notch = styled(ArrowRight)`
  color: ${colors.white};
  position: absolute;
  left: -30px;
  top: -17px;
  height: 65px;
`;
