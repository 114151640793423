import { OpenInNew } from "@styled-icons/material";
import { useAppSelector } from "app/hooks";
import { LoadingContent } from "components";
import { useGameTemplates, useQuestionSets } from "hooks";
import { Column, HorizontalLine, PrimaryButton, Row, VerticalLine } from "primitives";
import { MedIcon } from "primitives/icons";
import styled from "styled-components";
import { colors } from "styles";
import { actionConstants, Names, paths } from "textConstants";
import { CustomGame, FC, TTSOverridePair } from "types";
import { CurrentCustomGameData, MiniGameStepMaxWidthContainer } from ".";
import { MiniGameInfo, MiniGameSessionInfo } from "./Components";
import { MiniGameStepOuterContainer, MiniGameStepSidebarContainer } from "./CustomGameEditor";

interface Props {
  currentCustomGame: CurrentCustomGameData;
  customGame?: CustomGame;
}

const CustomGameReview: FC<Props> = ({ currentCustomGame, customGame }) => {
  const { gameTemplateId, questionSetIds, gameSettings } = currentCustomGame;
  const questionSets = useQuestionSets(questionSetIds);
  useGameTemplates(true);
  const gameTemplate = useAppSelector(
    (state) =>
      state.gameTemplates.data && state.gameTemplates.data.find((gameTemplate) => gameTemplate.id === gameTemplateId)!
  );

  let pairs: TTSOverridePair[] = [];
  if (gameSettings.ttsOverrides) {
    pairs = JSON.parse(gameSettings.ttsOverrides);
  }

  return (
    <LoadingContent loading={!gameTemplate || !questionSets?.length || !gameSettings}>
      <MiniGameStepOuterContainer>
        <MiniGameStepMaxWidthContainer>
          <h4 className="mb-3">Review your quick Mini-Game</h4>
          <MiniGameStepSidebarContainer style={{ overflowY: "visible" }} className="border-0">
            <MiniGameSessionInfo />
            <MiniGameInfo currentCustomGame={currentCustomGame} />
          </MiniGameStepSidebarContainer>
          {customGame && (
            <span className="game-play-panel d-inline-block">
              <PrimaryButton className="button play-game mr-2" href={customGame.gameLink} target="_blank">
                Play Now
                <MedIcon icon={OpenInNew} className="ml-2" />
              </PrimaryButton>
              <PrimaryButton className="button" href={paths.teacher.myGames.miniGame.showQR.generate(customGame.id)}>
                QR Code
                <MedIcon icon={actionConstants.qr.icon} className="ml-2" />
              </PrimaryButton>
            </span>
          )}
          <SettingsContainer className="mt-3">
            <b>Time</b>
            <Row>
              <small>Time limit on this {Names.customGame}?</small>
              {gameSettings.gameTimeLimit ? `${gameSettings.gameTimeLimit} minutes` : "None"} <VerticalLine />
              <small>Time for each question?</small>
              {gameSettings.questionTimeLimit ? `${gameSettings.questionTimeLimit} seconds` : "None"}
            </Row>
            <HorizontalLine />
            <b>Questions</b>
            <Row>
              <small>How many questions per player?</small>
              {gameSettings.questionCount}
              <VerticalLine /> <small>Order of questions?</small>
              {gameSettings.questionOrder
                ? gameSettings.questionOrder!.substring(0, 1).toUpperCase() + gameSettings.questionOrder!.substring(1)
                : ""}
            </Row>
            <HorizontalLine />
            <b>Players</b>
            <Row>
              <small>Max players in this {Names.customGame}?</small>
              {gameSettings.multiplayerSettings?.maxPlayers || 1}
            </Row>
            <HorizontalLine />
            <b>Sounds</b>
            <Row>
              <small>Sound effects</small>
              <p>{gameSettings.soundEnabled ? "On" : "Off"}</p>
            </Row>
            <HorizontalLine />
            <b>Text to Speech</b>
            <Row>
              <small>Text to Speech</small>
              {gameSettings.ttsMode === "button_prompted" ? <p>On</p> : <p>Off</p>}
            </Row>
            {gameSettings.ttsMode === "button_prompted" && (
              <Row>
                <small>Pronunciations</small>
                <div>
                  {pairs.map((pair, index) => (
                    <p key={index}>
                      <PronunciationInput>{pair.input}</PronunciationInput>
                      <PronunciationOutput>{pair.output}</PronunciationOutput>
                    </p>
                  ))}
                </div>
              </Row>
            )}

            <HorizontalLine />
          </SettingsContainer>
        </MiniGameStepMaxWidthContainer>
      </MiniGameStepOuterContainer>
    </LoadingContent>
  );
};

const PronunciationInput = styled.span`
  background: ${colors.primaryDivider};
  padding: 0 var(--sp-1) 0 var(--sp-2);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid ${colors.primaryDivider};
`;

const PronunciationOutput = styled.span`
  padding: 0 var(--sp-1) 0 var(--sp-2);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid ${colors.primaryDivider};
`;

const SettingsContainer = styled(Column)`
  small {
    color: ${colors.secondary};
    font-size: 10pt;
    margin-right: 10px;
  }
  p {
    color: ${colors.darkText};
  }
`;

export { CustomGameReview };
