import { Row } from "primitives";
import { CloseButton } from "primitives/button";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";

const useSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const sidebarProps = {
    isVisible: showSidebar,
    hide: useCallback(() => setShowSidebar(false), []),
  };

  return {
    Sidebar,
    sidebarProps,
    showSidebar: () => setShowSidebar(true),
    hideSidebar: () => setShowSidebar(false),
  };
};

const Sidebar: FC<{ isVisible: Boolean; hide: () => void }> = ({ children, isVisible, hide }) => (
  <SidebarContainer
    style={{
      minWidth: isVisible ? "250px" : "0px",
      maxWidth: isVisible ? "250px" : "0px",
    }}
  >
    <div style={{ width: 250 }}>
      <Row style={{ width: "100%", height: 20, justifyContent: "flex-end" }}>
        <CloseButton onClick={hide} style={CloseButtonPosition} />
      </Row>
      {children}
    </div>
  </SidebarContainer>
);

const SidebarContainer = styled.div`
  height: 100%;
  background-color: ${colors.white};
  overflow-x: hidden;
  transition: 0.25s all ease-in-out;
  position: static;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-left: 1px solid ${colors.primaryDivider};
  border-right: 1px solid ${colors.primaryDivider};
`;

const CloseButtonPosition = {
  margin: "1em",
};

export { useSidebar };
