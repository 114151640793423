import styled from "styled-components";
import { NavbarItemProperties } from "../navbar";
import { FC } from "types";
import { Header } from "./Header";
import { useAppSelector } from "app/hooks";
import { useLogin } from "hooks";
import { Spacer } from "primitives";
import { Button } from "primitives/button";
import { MedIcon } from "primitives/icons";
import { Logout } from "@styled-icons/material";
import { colors } from "styles";
import { paths } from "textConstants";
import { isTokenInLocalStorage } from "utils";

interface Props {
  navbarItems: Array<NavbarItemProperties>;
}

const StudentProfileElement = () => {
  const { logout } = useLogin();
  const student = useAppSelector((state) => state.studentLogin.student);
  const studentToken = useAppSelector((state) => state.studentLogin.token);
  let nameText = student ? `${student.initials}` : "";

  return (
    <>
      <NameText>{nameText}</NameText>
      <Spacer size={10}></Spacer>
      <Button
        onClick={() =>
          logout(paths.login.student.path, undefined, !Boolean(studentToken && isTokenInLocalStorage(studentToken)))
        }
      >
        <MedIcon icon={Logout} style={{ color: colors.white, alignSelf: "center" }} />
      </Button>
    </>
  );
};

const StudentHeader: FC<Props> = ({ navbarItems }) => (
  <Header navbarItems={navbarItems} profileElement={<StudentProfileElement />} />
);

const NameText = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin: auto;
`;

export { StudentHeader };
