import { CustomGameSettings } from "types";
import { CustomGameSettingsWithEnabledAndValidValues } from "components";
import { InputIsWithinRangeValidator } from "components/Validators/InputIsWithinRangeValidator";
import { Names, customGameConstants } from "textConstants";
import pluralize from "pluralize";

export const customGameSettingsUtils = {
  MaxAllowedQuestionsPerPlayer: (totalQuestions: number, playerCount: number): number => {
    return Math.floor(totalQuestions / playerCount);
  },

  GetMaxPlayers: (multiplayerEnabled: boolean, multiplayerSettingMaxPlayers: number) => {
    if (multiplayerEnabled) {
      return multiplayerSettingMaxPlayers;
    } else {
      return customGameConstants.minNumPlayers;
    }
  },

  InitialCustomGameSettings: (
    initialGameSettings: Partial<CustomGameSettings> | undefined,
    totalQuestions: number
  ): CustomGameSettingsWithEnabledAndValidValues => {
    return {
      copySettingsFromPrev: {
        enabled: true,
        error: undefined,
        value:
          initialGameSettings?.copySettingsFromPrev === undefined ? true : initialGameSettings.copySettingsFromPrev,
        warning: undefined,
      },
      gameTimeLimit: {
        enabled: Boolean(initialGameSettings?.gameTimeLimit),
        error: undefined,
        value: initialGameSettings?.gameTimeLimit || customGameConstants.maxGameTimeLimitInMinutes / 2,
        warning: undefined,
      },
      questionTimeLimit: {
        enabled: Boolean(initialGameSettings?.questionTimeLimit),
        error: undefined,
        value: initialGameSettings?.questionTimeLimit || customGameConstants.maxQuestionTimeLimitInSeconds / 2,
        warning: undefined,
      },
      questionDistributionType: {
        enabled: true,
        error: undefined,
        value: initialGameSettings?.questionDistributionType || "divideBetweenPlayers",
        warning: undefined,
      },
      questionOrder: {
        enabled: true,
        error: undefined,
        value: initialGameSettings?.questionOrder || "ordered",
        warning: undefined,
      },
      questionCount: {
        enabled: true,
        error: undefined,
        value:
          initialGameSettings?.questionCount ||
          customGameSettingsUtils.MaxAllowedQuestionsPerPlayer(
            totalQuestions,
            initialGameSettings?.multiplayerSettings?.maxPlayers || customGameConstants.minNumPlayers
          ),
        warning: undefined,
      },
      soundEnabled: {
        enabled: true,
        error: undefined,
        value: initialGameSettings ? initialGameSettings.soundEnabled! : true, // Value cannot be disabled, so it is never null
        warning: undefined,
      },
      maxAnswers: {
        enabled: Boolean(initialGameSettings?.maxAnswers),
        error: undefined,
        value: initialGameSettings?.maxAnswers || customGameConstants.maxNumAnswers,
        warning: undefined,
      },
      ttsMode: {
        enabled: Boolean(initialGameSettings?.ttsMode) && initialGameSettings?.ttsMode !== "none",
        error: undefined,
        value: initialGameSettings?.ttsMode || "none",
        warning: undefined,
      },
      ttsOverrides: {
        enabled: true,
        error: undefined,
        value: initialGameSettings?.ttsOverrides || "",
        warning: undefined,
      },
      multiplayerSettings: {
        enabled: Boolean(initialGameSettings) ? Boolean(initialGameSettings!.multiplayerSettings) : true,
        error: undefined,
        value: initialGameSettings?.multiplayerSettings || {
          allowGuests: true,
          minPlayers: customGameConstants.minNumPlayers,
          maxPlayers: customGameConstants.maxNumPlayers,
        },
        warning: undefined,
      },
      showQR: {
        enabled: true,
        error: undefined,
        value: initialGameSettings ? initialGameSettings.showQR! : true,
        warning: undefined,
      },
    };
  },

  ValidateCustomGameSettings: (
    newGameSettings: CustomGameSettingsWithEnabledAndValidValues,
    totalQuestions: number
  ) => {
    const maxPlayers = customGameSettingsUtils.GetMaxPlayers(
      newGameSettings.multiplayerSettings && newGameSettings.multiplayerSettings.enabled,
      newGameSettings.multiplayerSettings?.value?.maxPlayers || 1
    );
    const maxAllowedQuestionsPerPlayer = customGameSettingsUtils.MaxAllowedQuestionsPerPlayer(
      totalQuestions,
      maxPlayers
    );

    return {
      gameTimeLimit: {
        error: !newGameSettings.gameTimeLimit.enabled
          ? undefined
          : InputIsWithinRangeValidator(
              newGameSettings.gameTimeLimit.value,
              [customGameConstants.minGameTimeLimitInMinutes, customGameConstants.maxGameTimeLimitInMinutes],
              {
                high: () =>
                  `${Names.CustomGames} cannot be longer than ${customGameConstants.maxGameTimeLimitInMinutes} minutes`,
                low: () =>
                  `${Names.CustomGames} cannot be shorter than ${customGameConstants.minGameTimeLimitInMinutes} minutes`,
              }
            ),
      },
      questionTimeLimit: {
        error: !newGameSettings.questionTimeLimit.enabled
          ? null
          : InputIsWithinRangeValidator(
              newGameSettings.questionTimeLimit.value,
              [customGameConstants.minQuestionTimeLimitInSeconds, customGameConstants.maxQuestionTimeLimitInSeconds],
              {
                high: () =>
                  `Each question cannot be longer than ${customGameConstants.maxQuestionTimeLimitInSeconds} seconds`,
                low: () =>
                  `Each question cannot be shorter than ${customGameConstants.minQuestionTimeLimitInSeconds} seconds`,
              }
            ),
      },
      questionCount: {
        error:
          newGameSettings.questionDistributionType.value === "specifyPerPlayer"
            ? InputIsWithinRangeValidator(newGameSettings.questionCount.value, [1, totalQuestions], {
                high: () =>
                  `Questions Per Player (${newGameSettings.questionCount.value}) cannot be higher than available questions (${totalQuestions})`,
                low: () => `Questions Per Player must be at least 1`,
              })
            : null,
        warning:
          newGameSettings.questionDistributionType.value === "specifyPerPlayer"
            ? InputIsWithinRangeValidator(newGameSettings.questionCount.value, [1, maxAllowedQuestionsPerPlayer], {
                high: () =>
                  `There are only enough questions for ${maxAllowedQuestionsPerPlayer} per player. Questions will repeat in order to ask ${
                    newGameSettings.questionCount.value
                  } questions to ${maxPlayers} ${pluralize("players", maxPlayers)}.`,
                low: () => `You must have at least 1 question per player`,
              })
            : null,
      },
      maxAnswers: {
        error: !newGameSettings.maxAnswers.enabled
          ? null
          : InputIsWithinRangeValidator(newGameSettings.maxAnswers.value, [
              customGameConstants.minNumAnswers,
              customGameConstants.maxNumAnswers,
            ]),
      },
      multiplayerSettings: {
        error: newGameSettings.multiplayerSettings.enabled
          ? InputIsWithinRangeValidator(newGameSettings.multiplayerSettings.value.minPlayers, [
              customGameConstants.minNumPlayers,
              customGameConstants.maxNumPlayers,
            ]) ||
            InputIsWithinRangeValidator(newGameSettings.multiplayerSettings.value.maxPlayers, [
              customGameConstants.minNumPlayers + 1,
              customGameConstants.maxNumPlayers,
            ])
          : null,
      },
    };
  },

  CopyGameSettings: (
    prevGameSettings: Partial<CustomGameSettings>,
    totalQuestions: number,
    copySettingsFromPrev: boolean
  ) => {
    const maxPlayers = Math.min(
      customGameSettingsUtils.GetMaxPlayers(
        Boolean(prevGameSettings.multiplayerSettings),
        prevGameSettings.multiplayerSettings?.maxPlayers || 1
      ),
      totalQuestions || 1
    );

    const maxAllowedQuestionsPerPlayer = customGameSettingsUtils.MaxAllowedQuestionsPerPlayer(
      totalQuestions,
      maxPlayers
    );

    return {
      copySettingsFromPrev: { value: copySettingsFromPrev },
      gameTimeLimit: { value: prevGameSettings.gameTimeLimit, enabled: Boolean(prevGameSettings.gameTimeLimit) },
      questionTimeLimit: {
        value: prevGameSettings.questionTimeLimit,
        enabled: Boolean(prevGameSettings.questionTimeLimit),
      },
      questionOrder: { value: prevGameSettings.questionOrder, enabled: Boolean(prevGameSettings.questionOrder) },
      soundEnabled: { value: prevGameSettings.soundEnabled, enabled: Boolean(prevGameSettings.soundEnabled) },
      maxAnswers: { value: prevGameSettings.maxAnswers, enabled: Boolean(prevGameSettings.maxAnswers) },
      questionCount: {
        value: prevGameSettings.questionCount
          ? Math.min(prevGameSettings.questionCount, totalQuestions)
          : maxAllowedQuestionsPerPlayer,
      },
      ttsMode: { value: prevGameSettings.ttsMode, enabled: prevGameSettings.ttsMode === "button_prompted" },
      ttsOverrides: { value: prevGameSettings.ttsOverrides },
      multiplayerSettings: {
        value: prevGameSettings.multiplayerSettings
          ? {
              maxPlayers: maxPlayers,
              minPlayers: prevGameSettings.multiplayerSettings.minPlayers,
              allowGuests: prevGameSettings.multiplayerSettings.allowGuests,
            }
          : undefined,
        enabled: Boolean(prevGameSettings.multiplayerSettings),
      },
    };
  },
};
