import { ListCard, ListCardTitle } from "components";
import { CSSProperties } from "styled-components";
import { FC, PronunciationSet } from "types";
import { PronunciationSetActionHook, usePronunciationActions } from "hooks";
import pluralize from "pluralize";
import { SecondaryText, VerticalLine } from "../primitives";
import { pronunciationConstants } from "textConstants";

interface Props {
  pronunicationSet: PronunciationSet;
  style?: CSSProperties;
  onClick?: () => void;
  actionHooks?: Array<PronunciationSetActionHook>;
  scroll?: number;
  parentElementId?: string;
}

export const PronunciationSetCard: FC<Props> = ({
  pronunicationSet,
  style,
  onClick,
  actionHooks,
  scroll,
  parentElementId,
}) => {
  const actions = usePronunciationActions(pronunicationSet, actionHooks);

  const { name, ttsOverridePairs } = pronunicationSet;

  return (
    <ListCard actions={actions} onClick={onClick} scroll={scroll} parentElementId={parentElementId} style={style}>
      <ListCardTitle name={name} icon={pronunciationConstants.icon}>
        <VerticalLine margin={"0.2em 1em"} />
        <SecondaryText>
          {ttsOverridePairs.length} {pluralize("pronunciation", ttsOverridePairs.length)}
        </SecondaryText>
      </ListCardTitle>
    </ListCard>
  );
};
