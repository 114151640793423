import { useAppDispatch, useAppSelector } from "app/hooks";
import { Breadcrumb } from "components/breadcrumb";
import { UnsavedChangesComponent } from "components/UnsavedChangesComponent";
import { difference, isEqual, union, xor } from "lodash";
import { CustomGameEditor } from "pages/customGames";
import { Column, TruncatedText, Row, Spinner, NoOverflowText } from "primitives";
import { useState, useEffect, useCallback } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  blurApp,
  bulkUpdateCustomGames,
  deleteCustomGame,
  fetchSession,
  fetchClass,
  setCustomGameAssignments,
  unBlurApp,
} from "slices";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { helpMessages, Names, paths, slugs } from "textConstants";
import { AsyncRequestState, CustomGame, FC, getSessionType, SessionType } from "types";
import { dispatchAsync, getTotalCustomGameQuestionCount } from "utils";
import { CustomGameState, SessionDetails } from "./SessionDetails";
import { StudentAssignments, StudentAssignmentsState } from "./StudentAssignments";
import { allQuestionSetActionHooks, useClass, useConfirmModal } from "hooks";
import { EditSessionModal, PageNavigationPrompt, QuestionSetActionRoutes } from "components";

interface ManageSessionPrams {
  sessionId: string;
}

interface PopupFooter {
  $isActive: boolean;
}

export const ManageSessionPage: FC = () => {
  const { sessionId } = useParams<ManageSessionPrams>();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const session = useAppSelector((state) => state.sessionList.data && state.sessionList.data[sessionId]);
  const class_ = useClass();
  const [{ loading }, setRequestState] = useState<AsyncRequestState>({
    loading: true,
    success: false,
    error: null,
  });

  const getCustomGame = useCallback(
    (customGameId?: string) => {
      if (!customGameId) return undefined;
      return session?.customGames?.find((customGame) => customGame.id === customGameId);
    },
    [session?.customGames]
  );
  const hasCustomGames = (session?.customGames?.length || 0) > 0;

  const canManageSession = () => {
    return sessionType === SessionType.Upcoming || sessionType === SessionType.Unscheduled;
  };

  // ----- Child component state -----

  // Custom Game Selector state
  const defaultCustomGameState: CustomGameState = {
    orderedCustomGameIds: session?.customGames?.map((customGame) => customGame.id) || [],
    selectedCustomGameId: undefined,
  };
  const [customGameState, setCustomGameState] = useState<CustomGameState>(defaultCustomGameState);

  // Student Assignments state
  const selectedCustomGame = getCustomGame(customGameState.selectedCustomGameId);
  const getStudentsAssignedToAllGames = useCallback(() => {
    const students = class_?.students?.filter((student) =>
      session?.customGames?.every((customGame) => customGame.assignments?.some((ass) => ass.student.id === student.id))
    );
    return (students || []).map((student) => student.id);
  }, [session?.customGames, class_?.students]);
  const getStudentsAssignedToNoGames = useCallback(() => {
    const students = class_?.students?.filter(
      (student) =>
        !session?.customGames?.some((customGame) =>
          customGame.assignments?.some((ass) => ass.student.id === student.id)
        )
    );
    return (students || []).map((student) => student.id);
  }, [session?.customGames, class_?.students]);
  const defaultStudentAssignmentsState: StudentAssignmentsState = {
    selectedStudentIds: getStudentsAssignedToAllGames(),
    deselectedStudentIds: getStudentsAssignedToNoGames(),
  };
  const [studentAssignmentState, setStudentAssignmentState] = useState<StudentAssignmentsState>(
    defaultStudentAssignmentsState
  );
  const [initialStudentAssignmentState, setInitialStudentAssignmentState] = useState<StudentAssignmentsState>(
    defaultStudentAssignmentsState
  );

  const dispatch = useAppDispatch();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  //make sure we've fetched the current class' students
  useEffect(() => {
    if (class_?.id) {
      dispatch(fetchClass(class_?.id));
    }
  }, [class_?.id, dispatch]);

  useEffect(() => {
    if (class_?.students) {
      const studentState = {
        selectedStudentIds: getStudentsAssignedToAllGames(),
        deselectedStudentIds: getStudentsAssignedToNoGames(),
      };
      setInitialStudentAssignmentState(studentState);
      setStudentAssignmentState(studentState);
    }
    // eslint-disable-next-line
  }, [class_?.students]);

  const hasSelectedStudentsChanged = () => {
    return (
      xor(initialStudentAssignmentState.deselectedStudentIds, studentAssignmentState.deselectedStudentIds).length !==
        0 ||
      xor(initialStudentAssignmentState.selectedStudentIds, studentAssignmentState.selectedStudentIds).length !== 0
    );
  };

  const hasCustomGamesChanged = () => {
    if (session) {
      if (
        !isEqual(
          customGameState.orderedCustomGameIds,
          session.customGames?.map((customGame) => customGame.id)
        )
      ) {
        return true;
      }
    }

    return false;
  };

  const hasUnsavedChanges = () => {
    return hasCustomGamesChanged() || hasSelectedStudentsChanged();
  };

  const getAssignmentCounts = useCallback(() => {
    let assignmentCounts: { [studentId: string]: number } = {};
    let questionCounts: { [studentId: string]: number } = {};

    function setCountsForAssignment(studentId: string, gameQuestionCount: number) {
      //assignment counts
      if (studentId in assignmentCounts) {
        assignmentCounts[studentId]++;
      } else {
        assignmentCounts[studentId] = 1;
      }

      //question counts
      if (studentId in questionCounts) {
        questionCounts[studentId] += gameQuestionCount;
      } else {
        questionCounts[studentId] = gameQuestionCount;
      }
    }

    if (session?.customGames?.length) {
      // Aggregate the number of assignments each student has
      // in a dictionary
      session.customGames.forEach((customGame, i) => {
        if (customGame) {
          const gameQuestionCount = getTotalCustomGameQuestionCount(customGame);
          let assignedStudents = customGame.assignments?.map((assignment) => assignment.student.id) || [];

          if (selectedCustomGame) {
            if (selectedCustomGame.id === customGame.id) {
              assignedStudents = studentAssignmentState.selectedStudentIds;
            }
          } else {
            assignedStudents = union(
              difference(assignedStudents, studentAssignmentState.deselectedStudentIds),
              studentAssignmentState.selectedStudentIds
            );
          }

          assignedStudents.forEach((studentId) => setCountsForAssignment(studentId, gameQuestionCount));
        }
      });
    }

    return { studentAssignmentCounts: assignmentCounts, studentQuestionCounts: questionCounts };
  }, [
    session?.customGames,
    selectedCustomGame,
    studentAssignmentState.selectedStudentIds,
    studentAssignmentState.deselectedStudentIds,
  ]);

  const getLastSelectedCustomGame = (customGames: CustomGame[], prevSelectedId?: string) => {
    return prevSelectedId && customGames.some((customGame) => customGame.id === prevSelectedId)
      ? prevSelectedId
      : undefined;
  };

  const fetchSessionFromParam = useCallback(async () => {
    const { success, result: session } = await dispatchAsync(dispatch(fetchSession(sessionId)), setRequestState);

    if (success && session?.customGames?.length) {
      setCustomGameState((prev) => ({
        selectedCustomGameId: getLastSelectedCustomGame(session.customGames!, prev.selectedCustomGameId),
        orderedCustomGameIds: session.customGames!.map((customGame) => customGame.id),
      }));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (sessionId) {
      fetchSessionFromParam();
    }
  }, [sessionId, fetchSessionFromParam]);

  useEffect(() => {
    const customGame = getCustomGame(customGameState.selectedCustomGameId);
    if (customGame) {
      const newState = {
        selectedStudentIds: customGame.assignments?.map((assignment) => assignment?.student?.id) || [],
        deselectedStudentIds: [],
      };
      setStudentAssignmentState(newState);
      setInitialStudentAssignmentState(newState);
    } else {
      const newState = {
        selectedStudentIds: getStudentsAssignedToAllGames(),
        deselectedStudentIds: getStudentsAssignedToNoGames(),
      };
      setStudentAssignmentState(newState);
      setInitialStudentAssignmentState(newState);
    }
  }, [
    customGameState.selectedCustomGameId,
    getCustomGame,
    getStudentsAssignedToAllGames,
    getStudentsAssignedToNoGames,
  ]);

  const onDeleteCustomGamePressed = async (customGameId: string) => {
    setModalInfo({
      body: [helpMessages.deleteObject(Names.customGame)],
      onModalConfirm: async () => {
        dispatch(blurApp());
        try {
          await dispatch(deleteCustomGame(customGameId));
          setCustomGameState((prev) => ({ ...prev, orderedCustomGameIds: [] }));
        } catch (ex) {
          alert(`There was an error deleting the ${Names.customGame}. Please try again.`);
        } finally {
          fetchSessionFromParam();
          dispatch(unBlurApp());
        }
      },
      confirmButtonVariant: "danger",
      confirmTitle: "Delete",
    });
    setModalVisible(true);
  };

  const onCreateCustomGamePressed = () => {
    history.push(`${url}/${slugs.miniGame}`);
  };

  const applyAllChanges = async () => {
    try {
      if (hasUnsavedChanges()) {
        dispatch(blurApp());

        if (customGameState.selectedCustomGameId) {
          await dispatch(
            setCustomGameAssignments({
              customGameId: customGameState.selectedCustomGameId,
              studentIds: studentAssignmentState.selectedStudentIds,
            })
          );
        } else {
          if (session?.customGames) {
            for (const customGame of session.customGames) {
              const origStudentIds = (customGame.assignments || []).map((assignment) => assignment.student.id);
              const studentIds = union(
                difference(origStudentIds, studentAssignmentState.deselectedStudentIds),
                studentAssignmentState.selectedStudentIds
              );
              if (!isEqual(origStudentIds, studentIds)) {
                await dispatch(
                  setCustomGameAssignments({
                    customGameId: customGame.id,
                    studentIds,
                  })
                );
              }
            }
          }
        }

        await dispatch(
          bulkUpdateCustomGames({
            customGames: customGameState.orderedCustomGameIds.map((customGameId, index) => ({
              id: customGameId,
              order: index,
            })),
          })
        );
      }
    } catch (ex) {
      alert(`There was a problem saving the ${Names.session}. Please try again.`);
    } finally {
      fetchSessionFromParam();
      dispatch(unBlurApp());
    }
  };

  const revertAllChanges = () => {
    setCustomGameState((prev) => ({ ...prev, orderedCustomGameIds: defaultCustomGameState.orderedCustomGameIds }));
    setStudentAssignmentState(initialStudentAssignmentState);
  };

  const customGame = getCustomGame(customGameState.selectedCustomGameId)!;
  const sessionType = session ? getSessionType(session) : null;

  useEffect(() => {
    if (sessionType && !canManageSession()) {
      history.push(paths.teacher.myGames.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType]);

  const onModalHidden = () => {
    history.push(url);
  };

  if (loading) {
    return <Spinner />;
  } else if (session) {
    return (
      <>
        <Breadcrumb>
          <TruncatedText>
            {Names.Session}: {session.name}
          </TruncatedText>
        </Breadcrumb>
        <Breadcrumb to={url}>
          <NoOverflowText>Edit {Names.Session}</NoOverflowText>
        </Breadcrumb>
        <Switch>
          <Route path={`${path}/${slugs.miniGame}/${slugs.miniGameId}?`}>
            <CustomGameEditor
              assignedStudents={hasCustomGames ? studentAssignmentState.selectedStudentIds : []}
              sessionEditUrlOnComplete={url}
              onComplete={fetchSessionFromParam}
            />
          </Route>
          <Route path={`${path}`}>
            <OuterContainer>
              <PageNavigationPrompt
                when={hasUnsavedChanges() && canManageSession()}
                message="Are you sure you want to leave this page? All unsaved changes will be lost."
              />
              <InnerContainer $isActive={hasUnsavedChanges()}>
                <SessionDetailsContainer>
                  <SessionDetails
                    session={session}
                    onDeleteCustomGamePressed={onDeleteCustomGamePressed}
                    onCreateCustomGamePressed={onCreateCustomGamePressed}
                    state={customGameState}
                    setState={setCustomGameState}
                    assignmentState={studentAssignmentState}
                  />
                </SessionDetailsContainer>
                <StudentsContainer>
                  <StudentAssignments
                    customGame={customGame}
                    session={session}
                    {...getAssignmentCounts()}
                    state={studentAssignmentState}
                    initialState={initialStudentAssignmentState}
                    setState={setStudentAssignmentState}
                  />
                </StudentsContainer>
              </InnerContainer>
              {hasUnsavedChanges() && (
                <UnsavedChangesComponent
                  hasUnsavedChanges={hasUnsavedChanges()}
                  canApplyChanges={true}
                  applyChanges={applyAllChanges}
                  revertChanges={revertAllChanges}
                  loadingChanges={loading}
                />
              )}
            </OuterContainer>
            <QuestionSetActionRoutes pathSuffix={`/${slugs.questionSet}`} actionHooks={allQuestionSetActionHooks}>
              <Route path={`${path}/${slugs.duplicate}`}>
                <EditSessionModal shouldDuplicate={true} onHidden={onModalHidden} />
              </Route>
            </QuestionSetActionRoutes>
          </Route>
        </Switch>
        <Modal />
      </>
    );
  } else {
    return <p>There was an error loading the session</p>;
  }
};

const OuterContainer = styled(Column)`
  flex: 1;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
`;

const InnerContainer = styled(Row)<PopupFooter>`
  flex: ${(props) => (props.$isActive ? "90%" : "100%")};
  overflow-y: hidden;
  transition: ${layoutConstants.defaultTransition};
`;

const SessionDetailsContainer = styled.div`
  min-width: var(--sidebar-width-lg);
  max-width: var(--sidebar-width-lg);
`;

const StudentsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-left: solid 1px ${colors.primaryDivider};
`;
