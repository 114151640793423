import { NetworkError } from "api/networkError";
import { api } from "../api";

interface DeleteCustomGameParams {
  token: string;
  customGameId: string;
}

export const deleteCustomGame = async ({ token, customGameId }: DeleteCustomGameParams) => {
  const response = await api.delete(`/customgames/${customGameId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const customGame = response.data;

  if (customGame) {
    return customGame;
  } else {
    throw new NetworkError();
  }
};
