import { FC, GameTemplate } from "types";
import { useQuestionSets } from "hooks";
import { GameTemplatesList } from "components";
import { useState } from "react";
import { MiniGameInfo, MiniGameSessionInfo, SelectorContent, SelectorOuterContainer } from "./Components";
import { getQuestionsCount } from "utils";
import {
  CurrentCustomGameData,
  MiniGameStepMainContainer,
  MiniGameStepOuterContainer,
  MiniGameStepSidebarContainer,
  MiniGameStepSidebarSection,
} from "./CustomGameEditor";
import { Names } from "textConstants";
import { useRouteMatch } from "react-router-dom";

interface Props {
  initialSelectedGameTemplateId: string;
  selectedQuestionSetIds: string[];
  onSelectedGameTemplateChanged: (gameTemplateId: string) => void;
  onFinishedStage: () => void;
  currentCustomGame: CurrentCustomGameData;
}

const GameTemplateSelector: FC<Props> = ({
  selectedQuestionSetIds,
  initialSelectedGameTemplateId,
  onSelectedGameTemplateChanged,
  onFinishedStage,
  currentCustomGame,
}) => {
  const questionSets = useQuestionSets(selectedQuestionSetIds);
  const [, setSelectedGameTemplateId] = useState(initialSelectedGameTemplateId);
  const { params } = useRouteMatch<{ sessionId?: string }>();
  const hasSession = Boolean(params.sessionId);

  const selectedGameTemplateChanged = (template: GameTemplate) => {
    setSelectedGameTemplateId(template.id);
    onSelectedGameTemplateChanged(template.id);
    if (!hasSession && template.id !== initialSelectedGameTemplateId) onFinishedStage();
  };

  return (
    <MiniGameStepOuterContainer>
      {hasSession && (
        <MiniGameStepSidebarContainer>
          <MiniGameStepSidebarSection>
            <MiniGameSessionInfo />
          </MiniGameStepSidebarSection>
          <MiniGameStepSidebarSection>
            <MiniGameInfo currentCustomGame={currentCustomGame} />
          </MiniGameStepSidebarSection>
        </MiniGameStepSidebarContainer>
      )}
      <MiniGameStepMainContainer>
        <h4 className="mb-5">Choose from one of the {Names.customGame} templates below:</h4>
        <SelectorOuterContainer>
          <SelectorContent className="game-template-list">
            <GameTemplatesList
              initialSelectedId={initialSelectedGameTemplateId}
              onSelectedGameChanged={selectedGameTemplateChanged}
              questionNumber={getQuestionsCount(questionSets)}
            />
          </SelectorContent>
        </SelectorOuterContainer>
      </MiniGameStepMainContainer>
    </MiniGameStepOuterContainer>
  );
};

export { GameTemplateSelector };
