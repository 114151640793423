import { DescriptionText, ErrorText, LoadingContent } from "components";
import { useLogin, useQueryParams } from "hooks";
import { Center, Column, LoginBackgroundContainer, OnboardingLocation, Spacer } from "primitives";
import { PrimaryButton } from "primitives/button";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { colors } from "styles";
import { StringMap } from "types";
import { OnboardingElementContainer } from "pages";
import { paths } from "textConstants";

interface QueryParams extends StringMap {
  token: string;
}

const TeacherVerifyElement = () => {
  const { verify, logout, loading, error, errorMessage } = useLogin();
  const { params: queryParams } = useQueryParams<QueryParams>();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (!queryParams.token) {
      //no token - cannot verify, redirect to base teacher page
      history.push(paths.login.teacher.path);
    } else if (!hasSubmitted) {
      verify(queryParams.token);
      setHasSubmitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToLogin = () => {
    logout();
  };

  return (
    <LoadingContent loading={loading || !hasSubmitted}>
      <Column>
        {error ? (
          <ErrorText>
            There was an error verifying your account: "{errorMessage}" - Please try again using the link in your email.
          </ErrorText>
        ) : (
          <DescriptionText>Your account has been verified.</DescriptionText>
        )}
        <Spacer />
        <Center>
          <PrimaryButton onClick={handleBackToLogin}>Back To Log In</PrimaryButton>
        </Center>
      </Column>
    </LoadingContent>
  );
};

export const TeacherVerifyPage = () => {
  return (
    <LoginBackgroundContainer backgroundColour={colors.secondary} location={OnboardingLocation.register}>
      <OnboardingElementContainer title={"Verify Email"}>
        <TeacherVerifyElement />
      </OnboardingElementContainer>
    </LoginBackgroundContainer>
  );
};
