import { Class } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface CreateClassParams {
  token: string;
  name: string;
}

const createClass = async ({ token, name }: CreateClassParams): Promise<Class> => {
  const response = await api.post(
    `/classes`,
    {
      name,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const newClass = response.data;

  if (newClass) {
    return { students: [], ...newClass };
  } else {
    throw new NetworkError();
  }
};

export { createClass };
