import { CreateButtonLarge, PrimaryButton } from "primitives/button";
import { Column, Row } from "primitives";
import styled from "styled-components";
import { FC, Session } from "types";
import { SessionMainDetails } from "./sessionDetailsComponents/SessionMainDetails";
import { SessionCustomGame } from "./sessionDetailsComponents/SessionCustomGame";
import {
  DraggableItem,
  EditableComponent,
  EmptyPanelContainer,
  MoveDirection,
  SingleListDropContext,
} from "components";
import { DropResult } from "react-beautiful-dnd";
import { changeItemPositionInArray, clamp, getItemAlignment } from "utils";
import { Dispatch, Fragment, SetStateAction, useCallback, useState } from "react";
import { MedIcon } from "primitives/icons";
import { actionConstants, Names } from "textConstants";
import { StudentAssignmentsState, ToolsLink } from "./StudentAssignments";
import { difference, union } from "lodash";
import { useClass } from "hooks";

interface Props {
  session: Session;
  onDeleteCustomGamePressed: (customGameId: string) => void;
  onCreateCustomGamePressed: () => void;

  state: ComponentState;
  setState: Dispatch<SetStateAction<ComponentState>>;
  assignmentState: StudentAssignmentsState;
}

interface ComponentState {
  orderedCustomGameIds: string[];
  selectedCustomGameId?: string;
}

export type { ComponentState as CustomGameState };

export const SessionDetails: FC<Props> = ({
  session,
  onDeleteCustomGamePressed,
  onCreateCustomGamePressed,
  state,
  setState,
  assignmentState,
}) => {
  const getCustomGame = useCallback(
    (customGameId: string) => session?.customGames?.find((customGame) => customGame.id === customGameId),
    [session?.customGames]
  );
  const [isDragging, setIsDragging] = useState(false);
  const class_ = useClass();

  const customGames = state.orderedCustomGameIds.map((customGameId) => getCustomGame(customGameId)!);

  const customGamePressed = (customGameId: string) => {
    if ((session?.customGames?.length || 0) > 1) {
      setState((prev) => ({
        ...prev,
        selectedCustomGameId: customGameId,
      }));
    }
  };

  const onItemMovePressed = (sourceIndex: number, direction: MoveDirection) => {
    const destinationIndex = clamp(sourceIndex + direction, 0, customGames!.length - 1);
    const newOrder = changeItemPositionInArray(state.orderedCustomGameIds, sourceIndex, destinationIndex);
    setState((prev) => ({
      ...prev,
      orderedCustomGameIds: newOrder,
    }));
  };

  const onBeforeCapture = () => {
    setIsDragging(true);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    setIsDragging(false);
    if (!destination || destination.index === source.index) {
      return;
    }

    const newOrder = changeItemPositionInArray(state.orderedCustomGameIds, source.index, destination?.index);
    setState((prev) => ({
      ...prev,
      orderedCustomGameIds: newOrder,
    }));
  };

  const onToggleAssignAllGames = () => {
    setState((prev) => ({
      ...prev,
      selectedCustomGameId: state.selectedCustomGameId === undefined ? customGames[0].id : undefined,
    }));
  };

  return (
    <OuterContainer>
      <SessionMainDetails session={session} />
      <InnerContainer>
        <Row className="mt-2 mx-3">
          <PrimaryButton
            fontSize="0.9em"
            height="initial"
            variant="dark"
            className="new-game-button"
            onClick={onCreateCustomGamePressed}
          >
            <MedIcon icon={actionConstants.add.icon} className="mr-1" />
            Add {Names.CustomGame}
          </PrimaryButton>
        </Row>
        {(session?.customGames?.length || 0) >= 2 && (
          <Row className="mx-3">
            <ToolsLink
              className="mt-2 p-0 ml-1 mb-0"
              onClick={onToggleAssignAllGames}
              disabled={state.selectedCustomGameId === undefined}
              size="sm"
            >
              Select All
            </ToolsLink>
          </Row>
        )}
        {!customGames || !customGames.length ? (
          <EmptyPanelContainer
            className="custom-games-list"
            title={Names.CustomGames}
            message={
              <EmptyContent>
                <p>Click</p>
                <CreateButtonLarge
                  className="mx-2"
                  tooltip={`New ${Names.CustomGame}`}
                  onClick={onCreateCustomGamePressed}
                />
                <p>to add your first one!</p>
              </EmptyContent>
            }
          />
        ) : (
          <ScrollableDragContainer className="custom-games-list px-3">
            <SingleListDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
              <CustomGameContainer>
                {customGames &&
                  customGames.map((customGame, index) => {
                    if (customGame === undefined) return <Fragment key={index}></Fragment>;
                    const hasChanged = Boolean(session.customGames) && customGame.id !== session.customGames![index].id;
                    let studentsAssigned = 0;
                    if (state.selectedCustomGameId === undefined) {
                      studentsAssigned = union(
                        difference(
                          (customGame.assignments || []).map((assignment) => assignment.student.id),
                          assignmentState.deselectedStudentIds
                        ),
                        assignmentState.selectedStudentIds
                      ).length;
                    } else if (state.selectedCustomGameId === customGame.id) {
                      studentsAssigned = assignmentState.selectedStudentIds.length;
                    } else if (customGame.assignments) {
                      studentsAssigned = customGame.assignments.length;
                    }
                    return (
                      <DraggableItem index={index} draggableId={customGame.id} key={customGame.id}>
                        <EditableComponent isEdited={hasChanged}>
                          <SessionCustomGame
                            key={customGame.id}
                            isDragging={isDragging}
                            customGame={customGame}
                            index={index}
                            alignment={getItemAlignment(index, customGames.length)}
                            studentsInClass={class_?.students?.length || 0}
                            isSelected={
                              state.selectedCustomGameId === undefined || customGame.id === state.selectedCustomGameId
                            }
                            studentsAssigned={studentsAssigned}
                            onCustomGamePressed={customGamePressed}
                            onMovePressed={onItemMovePressed}
                            onDeleteCustomGamePressed={onDeleteCustomGamePressed}
                          />
                        </EditableComponent>
                      </DraggableItem>
                    );
                  })}
              </CustomGameContainer>
            </SingleListDropContext>
          </ScrollableDragContainer>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  height: 100%;
`;

const InnerContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CustomGameContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmptyContent = styled(Row)``;

const ScrollableDragContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;
`;
