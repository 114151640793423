import { StyledIcon } from "@styled-icons/styled-icon";
import styled from "styled-components";
import { CardStyle, defaultStudentCardColourScheme } from ".";
import { SmallIcon } from "primitives/icons";
import { Row } from "primitives";

interface BadgeContainerProps {
  $isSelected: boolean;
  $cardStyle: CardStyle;
}

const CreateStudentBadge = (
  isSelected: boolean,
  label: string,
  icon?: StyledIcon,
  cardStyle: CardStyle = defaultStudentCardColourScheme
) => (
  <StudentBadgeContainer $isSelected={isSelected} $cardStyle={cardStyle}>
    {icon && <SmallIcon icon={icon} color="white" />}
    <p>{label}</p>
  </StudentBadgeContainer>
);

const StudentBadgeContainer = styled(Row)<BadgeContainerProps>`
  border-radius: 25px;
  background-color: ${(props) =>
    props.$isSelected ? props.$cardStyle.activePrimaryColor : props.$cardStyle.inactivePrimaryColor};
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 25px;
  gap: 5px;
  & p {
    color: white;
    font-size: 12px;
    font-weight: 600;
  }
`;

export { CreateStudentBadge };
