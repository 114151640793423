import { Spacer } from "primitives";
import styled from "styled-components";
import { FC } from "types";
import { QuestionSetSelectorSidebarItem } from "./QuestionSetSelectorSidebarItem";
import { DraggableItem, SingleListDropContext, MoveDirection } from "components";
import { DropResult } from "react-beautiful-dnd";
import { useQuestionSets, QuestionSetActionHook } from "hooks";
import { clamp, getItemAlignment } from "utils";
import { MiniGameStats, MiniGameTemplateInfo } from "../Components";
import { CurrentCustomGameData } from "../CustomGameEditor";

interface Props {
  selectedQuestionSetIds: string[];
  onItemsReordered: (sourceIndex: number, destinationIndex: number) => void;
  onConfirmPressed: () => void;
  actionHooks?: Array<QuestionSetActionHook>;
  currentCustomGame: CurrentCustomGameData;
}

const QuestionSetSelectorSidebar: FC<Props> = ({
  selectedQuestionSetIds,
  onItemsReordered,
  onConfirmPressed,
  actionHooks,
  currentCustomGame,
}) => {
  const questionSets = useQuestionSets(selectedQuestionSetIds);

  const onItemMovePressed = (sourceIndex: number, direction: MoveDirection) => {
    const destinationIndex = clamp(sourceIndex + direction, 0, questionSets.length - 1);

    onItemsReordered(sourceIndex, destinationIndex);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || destination.index === source.index) {
      return;
    }

    onItemsReordered(source.index, destination?.index);
  };

  const getSelectorItems = () => {
    let items: JSX.Element[] = [];

    questionSets.forEach((questionSet, index) => {
      if (questionSet) {
        items.push(
          <DraggableItem index={index} draggableId={questionSet.id} key={questionSet.id}>
            <QuestionSetSelectorSidebarItem
              key={questionSet.id}
              questionSet={questionSet}
              index={index}
              alignment={getItemAlignment(index, questionSets.length)}
              onMovePressed={onItemMovePressed}
              actionHooks={actionHooks}
            />
          </DraggableItem>
        );
        index++;
      }
    });

    return items;
  };

  return (
    <OuterContainer className="selected-question-set-list">
      <MiniGameTemplateInfo gameTemplateId={currentCustomGame.gameTemplateId} isSelected={true} />
      <MiniGameStats questionSets={questionSets} />
      <ScrollableContainer>
        <SingleListDropContext onDragEnd={onDragEnd}>
          <ItemContainer>
            {questionSets && questionSets.length > 0 ? (
              getSelectorItems().map((item) => item)
            ) : (
              <p>No Question Sets Selected</p>
            )}
          </ItemContainer>
        </SingleListDropContext>
        <Spacer />
      </ScrollableContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
`;

export { QuestionSetSelectorSidebar };
