import { ProcessQuestionSet } from "../deserialisation";
import { QuestionSet } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

const fetchQuestionSet = async (questionSetId: string, token: string | null): Promise<QuestionSet> => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const response = await api.get(`/questionsets/${questionSetId}`, {
    headers,
  });

  if (response.data) {
    const questionSet: QuestionSet = ProcessQuestionSet(response.data);
    return questionSet;
  } else {
    throw new NetworkError();
  }
};

export { fetchQuestionSet };
