import { CharacterLimitedInputControlWithError, ImagePreview } from "components";
import { Column, CreateButton, DeleteButton, HorizontalLine, Spacer } from "primitives";
import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { FC, FreetextQuestion, Question } from "types";
import { replaceOneElementInArray } from "utils";
import { CorrectAnswerCircle } from "../CorrectAnswerCircle";
import {
  generateErrorFeedbackForFreetextAnswer,
  generateErrorFeedbackForQuestion,
} from "../questionSetValidation/generateErrorFeedbackForAnswerIndex";
import { ImageSelector } from "./ImageSelector";
import { Character_Limit, InputContainer, SectionContainer } from "./QuestionEditor";
import { useSelectImageModal } from "./selectImageModal";

interface Props {
  questionData: FreetextQuestion;
  showInvalidFeedback: boolean;
  handleQuestionChanged: (question: Partial<Question>) => void;
  onScrollToBottom: () => void;
}

export const FreetextQuestionForm: FC<Props> = ({
  questionData,
  showInvalidFeedback,
  handleQuestionChanged,
  onScrollToBottom,
}) => {
  const {
    SelectImageModal,
    setSelectImageModalVisible,
    selectImageModalProps,
    setOnSubmitImage,
  } = useSelectImageModal();

  let { question, questionImage, answers } = questionData;
  const notInitialRender = useRef(false);
  const answerRefs = useRef<HTMLInputElement[]>([]);

  const addAnswer = () => {
    const newAnswers = [...answers];
    newAnswers.push("");
    const freetextQuestion: Partial<FreetextQuestion> = {
      answers: newAnswers,
    };
    handleQuestionChanged(freetextQuestion);
  };

  const removeAnswer = (index: number) => {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    const freetextQuestion: Partial<FreetextQuestion> = {
      answers: newAnswers,
    };
    handleQuestionChanged(freetextQuestion);
  };

  useEffect(() => {
    if (notInitialRender.current) {
      answerRefs.current = answerRefs.current.slice(0, answers.length);
      if (answers[answers.length - 1] === "") {
        answerRefs.current[answerRefs.current.length - 1].focus();
      }
      onScrollToBottom();
    } else {
      notInitialRender.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return (
    <>
      <Form.Group>
        <Form.Label style={{ fontWeight: 500 }}>Question*</Form.Label>
        <HintText>
          <small>Enter the question with the text, an image, or both.</small>
        </HintText>
        <SectionContainer>
          <InputContainer>
            <CharacterLimitedInputControlWithError
              characterLimit={Character_Limit}
              placeholder="Question"
              initialInput={question}
              onInputUpdated={(newValue) => {
                const newQuestion: Partial<FreetextQuestion> = { question: newValue };
                handleQuestionChanged(newQuestion);
              }}
              canShowFeedback={showInvalidFeedback}
              errorFeedback={generateErrorFeedbackForQuestion(question, questionImage)}
              forceSingleLine={!Boolean(questionImage)}
            >
              {questionImage ? (
                <ImagePreview
                  imageUrl={questionImage}
                  onPressDelete={() => {
                    const newQuestion: Partial<FreetextQuestion> = { questionImage: null };
                    handleQuestionChanged(newQuestion);
                  }}
                />
              ) : (
                <ImageSelector
                  imageUrl={questionImage}
                  onSelectImage={() => {
                    setOnSubmitImage(() => (imageUrl: string) => {
                      const newQuestion: Partial<FreetextQuestion> = { questionImage: imageUrl };
                      handleQuestionChanged(newQuestion);
                    });
                    setSelectImageModalVisible(true);
                  }}
                />
              )}
            </CharacterLimitedInputControlWithError>
          </InputContainer>
        </SectionContainer>
      </Form.Group>

      <HorizontalLine />

      <Form.Group>
        <Form.Label
          style={{
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CorrectAnswerCircle />
          <Spacer size={5} />
          Correct Answer*
        </Form.Label>
        <HintText>
          <small>
            Add 1 or more correct answers. If the student’s answer contains any of these, it will be considered correct.
            E.g. “2” and “Two”. Note: Capitalisation is ignored, so “Two” or “two” or “TWO” would be considered correct.
          </small>
        </HintText>
        <Column>
          {answers.map((answer, i) => (
            <SectionContainer key={i}>
              <InputContainer>
                <CharacterLimitedInputControlWithError
                  ref={(el) => el && (answerRefs.current[i] = el)}
                  characterLimit={Character_Limit}
                  placeholder="Answer"
                  initialInput={answers[i] || ""}
                  onInputUpdated={(newValue) => {
                    const newAnswers = replaceOneElementInArray(answers, i, newValue);
                    const newQuestion: Partial<FreetextQuestion> = { answers: newAnswers };
                    handleQuestionChanged(newQuestion);
                  }}
                  onEnterPressed={() => {
                    if (Boolean(answers[i]) && !answers.some((answer) => !Boolean(answer))) {
                      addAnswer();
                    }
                  }}
                  canShowFeedback={showInvalidFeedback}
                  errorFeedback={generateErrorFeedbackForFreetextAnswer({
                    index: i,
                    answers,
                  })}
                  forceSingleLine={true}
                  offsetPadding={true}
                >
                  <DeleteButton
                    style={{ borderRadius: "0px", borderColor: colors.darkText }}
                    tooltip={"Remove Answer"}
                    onClick={() => {
                      removeAnswer(i);
                    }}
                    disabled={i === 0}
                  />
                </CharacterLimitedInputControlWithError>
              </InputContainer>
            </SectionContainer>
          ))}
          <CreateButton
            style={{ width: "11em", height: "2.5em" }}
            onClick={addAnswer}
            disabled={answers.some((answer) => !Boolean(answer))}
          >
            New Answer
          </CreateButton>
        </Column>
      </Form.Group>

      <SelectImageModal {...selectImageModalProps} excludeFromRandom={[]} />
    </>
  );
};

const HintText = styled.p`
  margin-bottom: var(--sp-2);
  color: ${colors.secondary};
`;
