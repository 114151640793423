import { Center, VerticalLine } from "primitives";
import { FC, useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { defaultRequestState, DateRange } from "types";
import styled from "styled-components";
import { colors } from "styles";
import { createSession, updateSession, duplicateSession, fetchSession } from "slices";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { addDefaultSessionLength, dispatchAsync, parseDateString } from "utils";
import { generatePath, Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { dateRangeValidator, futureDateRangeValidator, useValidatableState } from "hooks/useValidatableState";
import { ClassSelector, DateTimeRangeInput, ModalButtonFooter, useCharacterLimitedNonEmptyInput } from "components";
import { useClass, useClassId } from "hooks";
import { isEqual } from "lodash";
import { Names, paths, slugs } from "textConstants";

interface Props {
  shouldDuplicate: boolean;
  createCustomGameStartPath?: string;
  onHidden: () => void;
  onItemEdited?: () => void;
}

const EditSessionModal: FC<Props> = ({ shouldDuplicate, createCustomGameStartPath, onHidden, onItemEdited }) => {
  const { questionSetId } = useParams<{ questionSetId?: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { path } = useRouteMatch();
  const { sessionId: aSessionId } = useParams<{ sessionId: string }>();
  const sessionId = path.endsWith(`${slugs.session}/${slugs.new}`) ? undefined : aSessionId;
  const session = useAppSelector((state) =>
    state.sessionList.data && sessionId ? state.sessionList.data[sessionId] : undefined
  );
  const [currentClassId, setCurrentClassId] = useClassId();
  const class_ = useClass();
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>(currentClassId);

  const getCurrentFieldValues = () => ({
    name: nameInputState.text,
    publicAccess: false, // Always false by client request
    classId: selectedClassId || null,
    startTime: dateRange.startDate && hasTime ? dateRange.startDate : null,
    endTime: dateRange.endDate && hasTime ? dateRange.endDate : null,
    maxAttempts: maxAttempts,
  });

  const originalFieldValues = {
    name: session?.name,
    publicAccess: false,
    classId: class_?.id,
    startTime: !shouldDuplicate && session?.startTime ? parseDateString(session.startTime) : null,
    endTime: !shouldDuplicate && session?.endTime ? parseDateString(session.endTime) : null,
  };

  const isEditing = Boolean(session);
  const isDuplicating = isEditing && shouldDuplicate;

  const [{ loading: pageLoading, error: pageError }, setRequestState] = useState(defaultRequestState);
  const questionSet = useAppSelector((state) =>
    state.questionSets.data && questionSetId ? state.questionSets.data[questionSetId] : undefined
  );

  const [NameInput, nameInputState, setNameState] = useCharacterLimitedNonEmptyInput({
    label: "Name",
    warningMessage: "You must enter a name",
    initialTextValue: session?.name || questionSet?.title || "",
  });

  const [closing, setClosing] = useState(false);
  const [hasTime, setHasTime] = useState(isDuplicating ? false : Boolean(session?.startTime && session?.endTime));
  const [maxAttempts, setMaxAttempts] = useState(isDuplicating ? null : session ? session.maxAttempts : null);

  const hasMaxAttempts = Boolean(maxAttempts);

  const dateValidator = isEditing ? dateRangeValidator : futureDateRangeValidator;

  const [dateRange, setDateRange, dateRangeError] = useValidatableState<DateRange>(
    {
      startDate: originalFieldValues.startTime || new Date(),
      endDate: originalFieldValues.endTime || addDefaultSessionLength(new Date()),
    },
    [dateValidator]
  );

  const onDateRangeChanged = (range: Partial<DateRange>, hasTime: boolean) => {
    setDateRange({ ...dateRange, ...range });
    setHasTime(hasTime);
  };

  useEffect(() => {
    if (hasMaxAttempts && !Boolean(maxAttempts)) {
      setMaxAttempts(1);
    }
  }, [hasMaxAttempts, maxAttempts]);

  const onModalHidden = () => {
    setClosing(false);
    onHidden();
  };

  const goToCreateGame = (sessionId: string, questionSetId?: string) => {
    if (selectedClassId) {
      setCurrentClassId(selectedClassId);
    }

    let path = generatePath(paths.teacher.myGames.session.edit.miniGame.new.path, {
      sessionId,
      classId: selectedClassId,
    });

    if (createCustomGameStartPath) {
      path += `/${createCustomGameStartPath}`;
    }

    history.push({
      pathname: path,
      search: questionSetId ? `?questionSetId=${questionSetId}` : undefined,
    });
  };

  const goToEditSession = (sessionId: string) => {
    if (selectedClassId) {
      setCurrentClassId(selectedClassId);
    }
    history.push(
      generatePath(paths.teacher.myGames.session.edit.path, {
        sessionId,
        classId: selectedClassId,
      })
    );
  };

  const fetchSessionFromId = useCallback(async () => {
    const { success, result: fetchedSession } = await dispatchAsync(
      dispatch(fetchSession(sessionId || "")),
      setRequestState
    );

    if (success && fetchedSession) {
      setNameState.setText(fetchedSession.name);
      setHasTime(Boolean(fetchedSession.startTime && fetchedSession.endTime));
      setDateRange({
        startDate: !shouldDuplicate && fetchedSession.startTime ? new Date(fetchedSession.startTime) : new Date(),
        endDate:
          !shouldDuplicate && fetchedSession.endTime
            ? new Date(fetchedSession.endTime)
            : addDefaultSessionLength(new Date()),
      });
      setMaxAttempts(fetchedSession.maxAttempts);
    }
  }, [dispatch, sessionId, setDateRange, setNameState, shouldDuplicate]);

  useEffect(() => {
    if (sessionId && !session) {
      fetchSessionFromId();
    }
  }, [sessionId, session, fetchSessionFromId]);

  const hasChanged = !isEqual(getCurrentFieldValues(), originalFieldValues);
  const canEditClass = !Boolean(sessionId);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!nameInputState.valid || !selectedClassId) {
      setNameState.forceValidate();
    } else {
      const sessionFields = getCurrentFieldValues();

      if (isEditing && session) {
        let success = false;
        if (shouldDuplicate) {
          const { success: duplicateSuccess, result } = await dispatchAsync(
            dispatch(duplicateSession(session.id, sessionFields)),
            setRequestState
          );
          success = duplicateSuccess;
          if (duplicateSuccess && result) {
            goToEditSession(result.id);
            return;
          }
        } else {
          const { success: updateSuccess } = await dispatchAsync(
            dispatch(updateSession(session.id, sessionFields)),
            setRequestState
          );
          success = updateSuccess;
          if (onItemEdited) {
            onItemEdited();
          }
        }

        if (success) {
          onModalHidden();
        }
      } else {
        const { success, result } = await dispatchAsync(dispatch(createSession(sessionFields)), setRequestState);

        if (success && result) {
          goToCreateGame(result.id, questionSetId);
        }
      }
    }
  };

  const modalBody = (
    <Modal.Body className="create-lesson-modal">
      <Form onSubmit={handleSubmit}>
        <NameInput {...nameInputState} {...setNameState} />
        <Form.Group style={{ minHeight: "95px" }} controlId="classSelect">
          <Form.Label>Class *</Form.Label>
          <ClassSelector
            maintainSelectedClass={true}
            disabled={!canEditClass}
            onClassChanged={setSelectedClassId}
            onClassesLoaded={setSelectedClassId}
            expand
          />
          {!currentClassId && (
            <ErrorText>
              You have no <Link to={"/teacher/class"}>classes</Link> yet!
            </ErrorText>
          )}
        </Form.Group>
        <VerticalLine />
        <Form.Label>When</Form.Label>
        <DateTimeRangeInput
          dateRange={dateRange}
          disabled={pageLoading}
          label={"Use start and end time"}
          onChange={onDateRangeChanged}
          error={dateRangeError}
          hasTime={hasTime}
        />
        <Form.Label>Attempts</Form.Label>
        <Form.Check
          type="checkbox"
          label="Only 1 attempt permitted"
          checked={hasMaxAttempts}
          onChange={() => setMaxAttempts(hasMaxAttempts ? null : 1)}
          readOnly={pageLoading}
        />
        <SubmitContainer>
          {pageError && <ErrorText>Something went wrong! Please try again.</ErrorText>}
          <ModalButtonFooter
            isEditing={isEditing && !shouldDuplicate}
            onCancel={() => {
              onModalHidden();
            }}
            confirmDisabled={Boolean(
              (!hasChanged && !shouldDuplicate) ||
                pageLoading ||
                dateRangeError ||
                !nameInputState.valid ||
                !selectedClassId
            )}
            confirmLoading={pageLoading}
            confirmType={"submit"}
          />
        </SubmitContainer>
      </Form>
    </Modal.Body>
  );

  return (
    <Modal
      show={!closing}
      onHide={() => setClosing(true)}
      onExited={onModalHidden}
      dialogClassName="width-1000px-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {isEditing ? (shouldDuplicate ? "Duplicate " : "Edit ") : "Create "}
          {Names.Session}
        </Modal.Title>
      </Modal.Header>
      {modalBody}
    </Modal>
  );
};

const SubmitContainer = styled(Center)`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 10px;
`;

const ErrorText = styled.p`
  color: ${colors.danger};
`;

export { EditSessionModal };
