import { StyledIcon, StyledIconProps } from "@styled-icons/styled-icon";
import { CSSProperties } from "styled-components";
import { FC } from "types";

interface PrimitiveIconProps extends StyledIconProps {
  icon: StyledIcon;
}

const getStyleForSize = (size: string, style?: CSSProperties): CSSProperties => ({
  minWidth: size,
  maxWidth: size,
  minHeight: size,
  maxHeight: size,
  ...style,
});
const ExtraSmallIcon: FC<PrimitiveIconProps> = ({ icon: Icon, style, ...rest }) => {
  return <Icon style={getStyleForSize("0.5em", style)} {...rest} />;
};

const SmallIcon: FC<PrimitiveIconProps> = ({ icon: Icon, style, ...rest }) => {
  return <Icon style={getStyleForSize("1em", style)} {...rest} />;
};

const MedIcon: FC<PrimitiveIconProps> = ({ icon: Icon, style, ...rest }) => {
  return <Icon style={getStyleForSize("1.5em", style)} {...rest} />;
};

const LargeIcon: FC<PrimitiveIconProps> = ({ icon: Icon, style, ...rest }) => {
  return <Icon style={getStyleForSize("1.8em", style)} {...rest} />;
};

export { ExtraSmallIcon, SmallIcon, MedIcon, LargeIcon };
