import { Done } from "@styled-icons/material";
import { useLogin } from "hooks";
import { PrimaryButton } from "primitives/button";
import { Center, Row, Spacer } from "primitives";
import { Form, FormCheck } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { MedIcon } from "primitives/icons";

export const SecuritySettings = () => {
  const { userInfo, isAdmin, loading, requestResetPassword, updateTwoFactorAuth } = useLogin();
  const userInfo2FAEnabled = Boolean(userInfo!.twoFactorAuth);
  const [is2FAEnabled, set2FAEnabled] = useState(userInfo2FAEnabled);
  const [hasResetPassword, setHasResetPassword] = useState(false);

  const has2FAChanged = userInfo2FAEnabled !== is2FAEnabled;

  const onPressResetPassword = () => {
    setHasResetPassword(true);
    requestResetPassword(userInfo!.email);
  };

  const handleApply = (event: React.SyntheticEvent) => {
    if (has2FAChanged) {
      event.preventDefault();
      updateTwoFactorAuth(userInfo!.id, is2FAEnabled);
    }
  };

  const handleRevert = () => {
    set2FAEnabled(userInfo2FAEnabled);
  };

  return (
    <Form style={{ height: "100%", width: "100%" }}>
      <Container>
        <Item>
          <StyledFormLabel>Password*</StyledFormLabel>
          <Spacer size={2} />
          <PrimaryButton onClick={onPressResetPassword}>Reset Password</PrimaryButton>
          <Spacer />
          {!hasResetPassword ? (
            <DescriptionText>
              Press "Reset Password" to have an email sent to you, then follow the steps to reset your password.
            </DescriptionText>
          ) : (
            <Row>
              <MedIcon icon={Done} color={colors.success} />
              <Spacer size={2} />
              <ConfirmedDescriptionText>You have been sent an email to reset your password.</ConfirmedDescriptionText>
            </Row>
          )}

          <Spacer size={35} />

          <StyledFormLabel>Two-Factor Authentication</StyledFormLabel>
          <StyledFormCheck>
            <FormCheck.Input
              type="checkbox"
              disabled={isAdmin() && userInfo2FAEnabled}
              checked={is2FAEnabled}
              onChange={() => set2FAEnabled(!is2FAEnabled)}
            />
            <StyledFormCheckLabel>Enable two-factor authentication</StyledFormCheckLabel>
          </StyledFormCheck>
          <Spacer size={5} />
          {isAdmin() && (
            <DescriptionText>You cannot disable 2FA because you are using an admin account.</DescriptionText>
          )}
        </Item>
        <Row>
          <Center>
            <PrimaryButton variant="secondary" disabled={loading || !has2FAChanged} onClick={handleRevert}>
              Revert
            </PrimaryButton>
            <Spacer />
            <PrimaryButton disabled={loading || !has2FAChanged} loading={loading} onClick={handleApply} type="submit">
              Apply
            </PrimaryButton>
          </Center>
        </Row>
      </Container>
    </Form>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
`;

const Item = styled.div`
  width: 100%;
`;

const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
`;

const StyledFormCheck = styled(FormCheck)`
  display: flex;
`;

const StyledFormCheckLabel = styled(FormCheck.Label)`
  font-size: 70%;
  color: ${colors.secondary};
  align-self: center;
  justify-self: center;
`;

const DescriptionText = styled.p`
  font-size: 70%;
  color: ${colors.secondary};
  align-self: center;
  justify-self: center;
`;

const ConfirmedDescriptionText = styled(DescriptionText)`
  color: ${colors.success};
`;
