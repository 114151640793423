import { CustomGame } from "types";
import { merge } from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dispatchFetchCustomGame } from "./fetchCustomGame";
import { CreateCustomGameFromQuestionSetParams, dispatchCreateCustomGame } from "./createCustomGame";
import { dispatchEditCustomGame, EditCustomGameParams } from "./editCustomGame";
import { dispatchSetCustomGameAssignments, SetCustomGameAssignmentsParams } from "./setCustomGameAssignments";
import { BulkUpdateCustomGamesParams, dispatchBulkUpdateCustomGames } from "./bulkUpdateCustomGames";
import { dispatchDeleteCustomGame } from "./deleteCustomGame";

type CustomGameState = {
  data: { [id: string]: CustomGame } | null;
};

const initialState: CustomGameState = {
  data: null,
};

const reducers = {
  reset(state: CustomGameState) {
    state.data = null;
  },
  addSingle(state: CustomGameState, action: PayloadAction<{ id: string; customGame: CustomGame }>) {
    if (state.data) {
      state.data[action.payload.id] = merge(state.data[action.payload.id], action.payload.customGame);
    } else {
      state.data = {
        [action.payload.id]: action.payload.customGame,
      };
    }
  },
  addMany(state: CustomGameState, action: PayloadAction<CustomGame[]>) {
    if (!state.data) {
      state.data = {};
    }
    for (const customGame of action.payload) {
      if (customGame.id in state.data) {
        state.data[customGame.id] = merge(state.data[customGame.id], customGame);
      } else {
        state.data[customGame.id] = customGame;
      }
    }
  },
  removeSingle(state: CustomGameState, action: PayloadAction<{ customGameId: string }>) {
    if (state.data && action.payload.customGameId in state.data) {
      delete state.data[action.payload.customGameId];
    }
  },
};

const slice = createSlice<CustomGameState, typeof reducers>({
  name: "customGame",
  initialState,
  reducers,
});

const fetchCustomGame = (customGameId: string) => dispatchFetchCustomGame(customGameId, slice.actions);

const createCustomGame = (params: CreateCustomGameFromQuestionSetParams) =>
  dispatchCreateCustomGame(params, slice.actions);

const editCustomGame = (params: EditCustomGameParams) => dispatchEditCustomGame(params, slice.actions);

const setCustomGameAssignments = (params: SetCustomGameAssignmentsParams) =>
  dispatchSetCustomGameAssignments(params, slice.actions);

const bulkUpdateCustomGames = (params: BulkUpdateCustomGamesParams) =>
  dispatchBulkUpdateCustomGames(params, slice.actions);

const deleteCustomGame = (customGameId: string) => dispatchDeleteCustomGame({ customGameId }, slice.actions);

const { addSingle: addSingleCustomGame } = slice.actions;

export type CustomGameActions = typeof slice.actions;

const customGameReducer = slice.reducer;
export {
  customGameReducer,
  createCustomGame,
  fetchCustomGame,
  editCustomGame,
  setCustomGameAssignments,
  bulkUpdateCustomGames,
  deleteCustomGame,
  addSingleCustomGame,
};
