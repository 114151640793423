import { Add } from "@styled-icons/material";
import { Row } from "primitives";
import { Button } from "primitives/button";
import { SmallIcon } from "primitives/icons";
import { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { Tag } from "types";
import { SwapModeButton } from "./SwapModeButton";

const Number_Suggestions_To_Display = 4;

interface SubjectTagsTextSearchProps {
  subjectTags: Array<{ tag: Tag; selected: boolean }>;
  addSubjectTag: (tagId: string) => void;
  toggleMode: () => void;
  mode: "dropdown" | "search";
}

const SubjectTagsTextSearch: FC<SubjectTagsTextSearchProps> = ({ subjectTags, mode, addSubjectTag, toggleMode }) => {
  const [searchText, setSearchText] = useState("");

  const matchingTags = subjectTags
    .filter(({ tag }) => searchMatch(tag.name, searchText))
    .map(({ tag }) => tag)
    .splice(0, Number_Suggestions_To_Display);
  return (
    <>
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form.Control
          placeholder="Search for tag"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 350 }}
        />
        <SwapModeButton toggleMode={toggleMode} mode={mode} />
      </Row>
      <Row
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 54,
        }}
      >
        {searchText === "" ? (
          <p>Please search for a tag in the box above</p>
        ) : (
          matchingTags.map((t) => (
            <div key={t.id}>
              <Button variant="success" className="m-2" onClick={() => addSubjectTag(t.id)}>
                <SmallIcon icon={Add} />
                {t.name}
              </Button>

              {!matchingTags.length ? <p>No tags found.</p> : null}
            </div>
          ))
        )}
      </Row>
    </>
  );
};

const searchMatch = (string1: string, string2: string) => string1.toLowerCase().search(string2.toLowerCase()) >= 0;

export { SubjectTagsTextSearch };
