import { colors } from "../styles";
import { TextDocumentInverted } from "@styled-icons/entypo";
import { InfoCircle } from "@styled-icons/bootstrap";
import { Names } from "./main";

const ReviewConstants = {
  lessonsIcon: TextDocumentInverted,
  infoIcon: InfoCircle,
  averageScoreTooltip: "Average score does not include any students that did not participate",
  noLessonsSelected: `<< Select a ${Names.session} on the left to see your class' results`,
  noAttemptColor: colors.secondary,
  notAssignedColor: colors.inactiveButton,

  GetColorForResult: (value: number) => {
    if (value > 0.75) {
      return colors.success;
    } else if (value > 0.49) {
      return colors.warning;
    } else {
      return colors.danger;
    }
  },
};

export { ReviewConstants };
