import { useAppDispatch, useAppSelector } from "app/hooks";
import { OnboardingElementContainer } from "pages";
import { Column, LoginBackgroundContainer, OnboardingLocation, Spacer } from "primitives";
import { colors } from "styles";
import { FC } from "types";
import { useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Button } from "primitives/button";
import styled from "styled-components";
import dayjs from "dayjs";
import { useLogin } from "hooks";
import { getBillPrice } from "utils";
import { getSubscriptionInfo } from "slices";
import { PlatformTitle } from "textConstants";

interface Props {
  returnUrl: string;
  selectedPriceId?: string;
}

export const SubscriptionPaymentInfo: FC<Props> = ({ returnUrl, selectedPriceId }) => {
  const { product, promo } = useAppSelector((state) => state.product);
  const { info: subscriptionInfo } = useAppSelector((state) => state.subscription);
  const price = selectedPriceId ? product?.prices.find((price) => price.id === selectedPriceId) : undefined;
  const { userInfo } = useLogin();
  const [paymentError, setPaymentError] = useState<string>("");
  const dispatch = useAppDispatch();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    dispatch(getSubscriptionInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onformSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const { error } = await stripe!.confirmSetup({
      elements: elements!,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error?.message) {
      setPaymentError(error.message);
    }
  };

  const formatTrial = (trialLength: number) => {
    const trialEnd = dayjs().add(Number(trialLength), "day");
    return (
      <>
        This subscription begins with a <b>{trialLength} DAY FREE-TRIAL</b> and you will not be charged until{" "}
        <b>{trialEnd.format("DD/MM/YYYY")}</b>.
      </>
    );
  };

  const getTrialText = () => {
    if (userInfo?.subscriptionInfo.trialAllowed) {
      return formatTrial(Number(product?.metadata["trial_period_days"]));
    } else if (subscriptionInfo?.trialRollover && Math.ceil(Number(subscriptionInfo.trialRollover)) > 0) {
      return formatTrial(Math.ceil(Number(subscriptionInfo?.trialRollover)));
    } else {
      return null;
    }
  };

  return (
    <LoginBackgroundContainer
      backgroundColour={colors.brandPurple}
      location={OnboardingLocation.register}
      includeLogo={false}
    >
      <OuterContainer>
        {userInfo?.subscriptionInfo?.trialAllowed && (
          <LightText>
            <b>Step 3 of 3</b>
          </LightText>
        )}
        <Spacer />
        <OnboardingElementContainer title="Payment Information">
          {price && (
            <p>
              {getTrialText()} Your first bill will be <b>${getBillPrice(price, promo?.coupon.percentOff)}</b> for a{" "}
              <b>
                {price.paymentIntervalCount} {price.paymentInterval} subscription
              </b>{" "}
              to {PlatformTitle}. You can cancel anytime.
            </p>
          )}
          <Spacer />
          <form onSubmit={onformSubmit}>
            <PaymentElement />
            <Spacer />
            <WideButton type="submit" disabled={!stripe || !elements}>
              Confirm
            </WideButton>
            {paymentError && <ErrorText>{paymentError}</ErrorText>}
          </form>
        </OnboardingElementContainer>
      </OuterContainer>
    </LoginBackgroundContainer>
  );
};

const OuterContainer = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const LightText = styled.p`
  color: white;
`;

const WideButton = styled(Button)`
  width: 100%;
`;

const ErrorText = styled.p`
  color: ${colors.danger};
`;
