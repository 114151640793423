import { Assignment, Student } from "types";
import { ProcessResult, UnprocessedResult } from "./result";

export interface UnprocessedAssignment {
  id: string;
  createdAt: string | null;
  deletedAt: string | null;
  modifiedAt: string | null;
  results: Array<UnprocessedResult>;
  student: Student;
}

export const ProcessAssignment = (assignment: UnprocessedAssignment): Assignment => {
  const { id, createdAt, deletedAt, modifiedAt, results, student } = assignment;

  return {
    id,
    createdAt,
    deletedAt,
    modifiedAt,
    results: results ? results.map((result) => ProcessResult(result)) : [],
    student,
  };
};
