import { combineReducers, PayloadAction } from "@reduxjs/toolkit";

import {
  loginReducer as login,
  appLoadingReducer as appLoading,
  gameTemplatesReducer as gameTemplates,
  questionSetsReducer as questionSets,
  customGameReducer as customGame,
  blurOverlayReducer as blurOverlay,
  gradeTagsReducer as gradeTags,
  subjectTagsReducer as subjectTags,
  classListReducer as classList,
  studentReducer as student,
  questionImagesReducer as questionImages,
  versionReducer as version,
  sessionListReducer as sessionList,
  studentLoginReducer as studentLogin,
  defaultClassReducer as defaultClass,
  breadcrumbPortalReducer as breadcrumbPortal,
  productReducer as product,
  subscriptionReducer as subscription,
  paymentHistoryReducer as paymentHistory,
  helpMenuPortalReducer as helpMenuPortal,
  newsItemsReducer as newsItems,
  articlesReducer as articleList,
  articleMenuPortalReducer as articleMenuPortal,
  pronunciationSetsReducer as pronunciationSets,
  groupingsReducer as groupings,
  tutorialStateReducer as tutorialState,
} from "slices";

const appReducer = combineReducers({
  login,
  appLoading,
  gameTemplates,
  customGame,
  blurOverlay,
  gradeTags,
  subjectTags,
  classList,
  student,
  questionImages,
  version,
  questionSets,
  sessionList,
  studentLogin,
  defaultClass,
  breadcrumbPortal,
  product,
  subscription,
  paymentHistory,
  helpMenuPortal,
  newsItems,
  articleList,
  articleMenuPortal,
  pronunciationSets,
  groupings,
  tutorialState,
});

const rootReducer = ((state: ReturnType<typeof appReducer>, action: PayloadAction<any>) => {
  // The logout action from loginSlice erases the whole store, so must be handled at the root reducer level
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === "login/clearState") {
    return undefined; // Cause all reducers to reset to initial state
  }
  return appReducer(state, action);
}) as typeof appReducer;

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
