import { FC } from "types";
import { useHelpMenu } from "hooks";
import { BreadcrumbMenuHeader, BreadcrumbMenuItem } from "./BreadcrumbMenuItem";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

interface MenuProps {
  title: string;
}

export const HelpMenuHeader: FC<MenuProps> = ({ title }) => {
  const { portalNode } = useHelpMenu();

  return <BreadcrumbMenuHeader title={title} portalNode={portalNode} />;
};

export const HelpMenuItem: FC<Props> = ({ title, onClick, disabled }) => {
  const { portalNode } = useHelpMenu();

  return <BreadcrumbMenuItem title={title} onClick={onClick} disabled={disabled} portalNode={portalNode} />;
};
