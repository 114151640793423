// import { SubscriptionRoutes } from "pages/subscription";
// import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
// import { MailchimpRegisterPage, TeacherRegisterPage } from ".";
// import { paths } from "textConstants";
import { MailchimpRegisterPage } from "./MailchimpRegisterPage";

export const RegisterRoutes = () => {
  // const { url } = useRouteMatch();
  // const history = useHistory();

  // const registrationComplete = () => {
  //   history.push(url + "/subscribe");
  // };

  return <MailchimpRegisterPage />;

  // Used when the register form was active. Currently disabled
  // return (
  //   <Switch>
  //     <Route path={`${url}/subscribe`}>
  //       <SubscriptionRoutes paymentCompleteUrl={paths.login.teacher.path} />
  //     </Route>
  //     <Route path={`${url}/teacher`}>
  //       <TeacherRegisterPage onRegisterComplete={registrationComplete} />
  //     </Route>
  //     <Route path="/">
  //       <Redirect to={`${url}/teacher`} />
  //     </Route>
  //   </Switch>
  // );
};
