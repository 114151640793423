import { FC } from "types";
import ReactDOM from "react-dom";
import { colors } from "styles";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  portalNode: HTMLElement | null;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

interface MenuProps {
  portalNode: HTMLElement | null;
  title: string;
}

export const BreadcrumbMenuHeader: FC<MenuProps> = ({ title, portalNode }) => {
  return portalNode ? ReactDOM.createPortal(<MenuHeader>{title}</MenuHeader>, portalNode) : null;
};

export const BreadcrumbMenuItem: FC<Props> = ({ title, onClick, disabled, portalNode }) => {
  return portalNode
    ? ReactDOM.createPortal(
        <StyledDropdownItem className="borderless-dropdown" onClick={onClick} disabled={disabled}>
          {title}
        </StyledDropdownItem>,
        portalNode
      )
    : null;
};

const MenuHeader = styled.div`
  height: 30px;
  background-color: ${colors.nameLabel};
  color: ${colors.white};
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  margin: -2px -1px 0px -1px;
  padding: 3px;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  text-align: center;
  font-size: 14px;
  height: 35px;
  border-bottom: 0.1px solid ${colors.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
