import { api } from "api/api";
import { NetworkError } from "api/networkError";

export const createSetupIntent = async (token: string) => {
  const response = await api.post(
    `/payments/setupintent`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response?.data?.clientSecret) {
    return response.data.clientSecret as string;
  } else {
    throw new NetworkError();
  }
};
