import dayjs from "dayjs";
import { Result, Session, Student } from "types";
import { sortBy } from "lodash";

export interface BestResult {
  bestResult: Result;
  attempts: number;
}

export const getResultForStudent = (session: Session, studentId: string): { [customGameId: string]: BestResult } => {
  const results: { [customGameId: string]: BestResult } = {};
  session?.customGames?.forEach((customGame) => {
    const assignments = customGame.assignments;

    if (assignments) {
      const studentIndex = assignments.findIndex(
        (assignment) => assignment.student && assignment.student.id === studentId
      );

      if (studentIndex >= 0) {
        const sortedResults = sortBy(assignments[studentIndex].results, (result) => dayjs(result.createdAt!));
        const lastResult = sortedResults[assignments[studentIndex].results.length - 1];
        // At the moment, we take the most recent as the best.
        results[customGame.id] = { bestResult: lastResult, attempts: assignments[studentIndex].results.length };
      }
    }
  });
  return results;
};

export const getResultsForStudent = (sessions: (Session | null)[], studentId: string) => {
  if (sessions) {
    return sessions.map((session) => (session && getResultForStudent(session, studentId)) || null);
  }
  return [];
};

export const getStudentAgregateInfo = (student: Student, sessionList: (Session | null)[]) => {
  const resultMaps = getResultsForStudent(sessionList, student.id);

  let totalCorrect = 0;
  let totalAnswered = 0;
  let participationCount = 0;
  let isAssigned = false;

  resultMaps.forEach((resultMap) => {
    if (resultMap) {
      let results = Object.keys(resultMap).map((id) => {
        return { id, ...resultMap[id] };
      });

      if (results.length > 0) {
        isAssigned = true;
      }
      results.forEach((result) => {
        if (result.attempts > 0) {
          totalCorrect += result.bestResult.answers.filter((answer) => answer.wasCorrect).length;
          totalAnswered += result.bestResult.answers.length;
          participationCount++;
        }
      });
    }
  });

  return {
    totalCorrect,
    totalAnswered,
    participationCount: participationCount,
    isAssigned,
  };
};

export const getSessionsAgregateInfo = (sessionList: (Session | null)[]) => {
  let totalPossibleScore = 0;
  let totalPossibleParticipation = 0;

  if (sessionList) {
    sessionList.forEach((session) => {
      if (session) {
        totalPossibleScore += session?.totalQuestions || 0;
        totalPossibleParticipation += session?.customGames?.length || 0;
      }
    });
  }

  return {
    totalPossibleScore: totalPossibleScore,
    totalPossibleParticipation: totalPossibleParticipation,
  };
};

export const getAverageResult = (results: { [customGameId: string]: BestResult }, customGameIds: string[]) => {
  let score = 0;
  let count = 0;

  customGameIds.forEach((customGameId) => {
    if (results[customGameId]) {
      const result = results[customGameId].bestResult;
      if (result && result.answers) {
        const totalQuestions = getTotalQuestions(result);
        if (totalQuestions > 0) {
          score += getCorrectCount(result) / totalQuestions;
        }
        count++;
      }
    }
  });

  return count > 0 ? score / count : undefined;
};

export const getDidParticipate = (results: { [customGameId: string]: BestResult }, customGameIds: string[]) => {
  let foundValue = false;
  customGameIds.forEach((customGameId) => {
    if (results[customGameId]) {
      const result = results[customGameId].bestResult;
      if (result && result.answers) {
        foundValue = true;
      }
    }
  });
  return foundValue;
};

export const getCorrectCount = (result: Result): number => {
  if (result && result.answers) {
    return result.answers.reduce((a, b) => a + (b.wasCorrect ? 1 : 0), 0);
  } else {
    return 0;
  }
};

export const getTotalQuestions = (result: Result): number => {
  if (result && result.answers) {
    return result.answers.length;
  } else {
    return 0;
  }
};

export const getCorrectPercentage = (correct: number, total: number) => {
  // Avoid divide by zero errors
  if (!total) {
    return 0;
  } else {
    return correct / total;
  }
};
