import axios, { AxiosError } from "axios";
import { ApiError } from "types";
import { getErrorFromResponse } from "utils";
import { v4 as uuidv4 } from "uuid";

const tryApiCall = async <T>({
  onTry,
  onError,
  onNotLoggedIn,
}: {
  onTry: (token: string) => Promise<T>;
  onError: (error: ApiError) => void;
  onNotLoggedIn: () => void;
}) => {
  try {
    return await onTry(uuidv4());
  } catch (error) {
    let apiError: ApiError;
    logAxiosError(error);

    if (error instanceof Error && error.message === "Not logged in") {
      apiError = ApiError.authError;
      onNotLoggedIn();
    } else if (axios.isAxiosError(error)) {
      apiError = getErrorFromResponse(error as AxiosError);
    } else {
      apiError = ApiError.serverError;
    }

    onError(apiError);
  }
};

const logAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
    console.error(axiosError.response?.data);
  } else {
    console.error(error);
  }
}

export { tryApiCall };
