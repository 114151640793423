import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { Product, Promo } from "types";
import {
  getProduct as getProductApi,
  createSetupIntent as createSetupIntentApi,
  getValidCoupon as getValidCouponApi,
} from "api/payment";
import { RootState } from "app/rootReducer";
import { getTokenFromState } from "./sliceHelpers";

type ProductState = {
  product: Product | null;
  clientSecret: string;
  promo: Promo | null;
};

const initialState: ProductState = {
  product: null,
  clientSecret: "",
  promo: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productLoading(state) {
      state.product = null;
    },
    productSuccess(state, action: PayloadAction<Product>) {
      state.product = action.payload;
    },
    productNetworkError(state) {
      state.product = null;
    },
    setupIntentLoading(state) {
      state.clientSecret = "";
    },
    setupIntentSuccess(state, action: PayloadAction<string>) {
      state.clientSecret = action.payload;
    },
    clearPromo(state) {
      state.promo = null;
    },
    promoSuccess(state, action: PayloadAction<Promo>) {
      state.promo = action.payload;
    },
  },
});

const {
  productLoading,
  productSuccess,
  productNetworkError,
  setupIntentLoading,
  setupIntentSuccess,
  clearPromo,
  promoSuccess,
} = productSlice.actions;

const getProduct = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(productLoading());

    try {
      const product = await getProductApi();
      dispatch(productSuccess(product));
    } catch (error) {
      return dispatch(productNetworkError());
    }
  };
};

const createSetupIntent = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setupIntentLoading());

    try {
      const { token } = getTokenFromState(getState);
      const clientSecret = await createSetupIntentApi(token);
      dispatch(setupIntentSuccess(clientSecret));
    } catch (error) {
      return dispatch(productNetworkError());
    }
  };
};

const getValidCoupon = (promoCode: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  const { token } = getTokenFromState(getState);
  const promo = await getValidCouponApi(token, promoCode);
  if (promoCode) {
    dispatch(promoSuccess(promo));
  }
};

const productReducer = productSlice.reducer;
export { getProduct, createSetupIntent, productReducer, getValidCoupon, clearPromo };
