import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Product } from "types";

export const getProduct = async () => {
  const response = await api.get(`/payments/product`);

  if (response.data) {
    return response.data as Product;
  } else {
    throw new NetworkError();
  }
};
