import { KeyboardArrowDown, KeyboardArrowUp } from "@styled-icons/material";
import { StyledIcon } from "@styled-icons/styled-icon";
import { Spacer, Row } from "primitives";
import { LargeIcon, MedIcon } from "primitives/icons";
import { forwardRef, ReactNode, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { FC } from "types";
import { MediaConstants } from "utils/mediaConstants";

interface Openable {
  isOpen: boolean;
}

interface Colorable {
  accentColor: string;
  highlightColor: string;
}

interface BarProps {
  active?: boolean;
  selectedColor: string;
}

interface NavbarTabProps extends Colorable {
  active?: boolean;
  subtitle?: React.ReactNode;
}

interface NavbarTabDropdownProps extends NavbarTabProps {
  icon: StyledIcon;
  tabName: string;
  defaultDropdownItem?: NavbarDropdownItemProperties;
  dropdownItems: Array<NavbarDropdownItemProperties>;
  route: string;
  hoverColor: string;
  notifIcon?: StyledIcon;
  notifText?: string;
}

export interface NavbarDropdownItemProperties {
  name: string;
  route?: string;
  onClick?: () => void;
  additionalContent?: ReactNode;
}

const NavbarTabItem: FC<NavbarTabProps> = ({
  accentColor: hoverColor,
  highlightColor: selectedColor,
  active,
  children,
  onClick,
  style,
  className,
  subtitle,
}) => {
  return (
    <NavbarContainer className={className} onClick={onClick} active={active} selectedColor={hoverColor} style={style}>
      <SelectedBar active={false} selectedColor={selectedColor} />
      <NavbarTabContainer>
        <Subtitle></Subtitle>
        <NavbarTab style={{ flex: 1 }} active={active} accentColor={hoverColor} highlightColor={hoverColor}>
          {children}
        </NavbarTab>
        <Subtitle>{subtitle}</Subtitle>
      </NavbarTabContainer>
      <SelectedBar active={active} selectedColor={selectedColor} />
    </NavbarContainer>
  );
};

const TabItemWithArrow: FC<{ onClick: () => void } & NavbarTabDropdownProps & Openable> = forwardRef(
  (
    {
      onClick,
      isOpen,
      active,
      accentColor,
      highlightColor,
      icon,
      tabName,
      defaultDropdownItem,
      route,
      notifIcon,
      notifText,
    },
    ref
  ) => {
    const history = useHistory();
    return (
      <NavbarTabWithArrow
        ref={ref as any}
        onClick={(e) => {
          if (defaultDropdownItem) {
            history.push(`/${route}/${defaultDropdownItem.route}`);
          } else {
            if (onClick) {
              onClick(e);
            }
          }
        }}
      >
        <NavbarTabItem key={0} active={active || isOpen} accentColor={accentColor} highlightColor={highlightColor}>
          <Spacer size={10} />
          <LargeIcon icon={icon} />
          <Spacer size={4} />
          <NameText>{tabName}</NameText>
          <Spacer size={4} />
          <HoverIcon
            icon={isOpen ? KeyboardArrowUp : KeyboardArrowDown}
            onClick={(e: any) => {
              e.stopPropagation();
              onClick(e);
            }}
            highlightColor={accentColor}
          />
          {notifIcon && (
            <NotifIconContainer className="justify-content-center">
              <LargeIcon color={`${colors.danger}`} icon={notifIcon} />
              <IconText style={{ fontWeight: 400 }}>{notifText}</IconText>
            </NotifIconContainer>
          )}
          <Spacer size={10} />
        </NavbarTabItem>
      </NavbarTabWithArrow>
    );
  }
);

const NavbarTabItemDropdown: FC<NavbarTabDropdownProps> = ({
  style,
  defaultDropdownItem,
  dropdownItems,
  className,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const handleClickItem = (dropdownItem: NavbarDropdownItemProperties) => {
    if (dropdownItem.onClick) {
      dropdownItem.onClick();
    } else if (dropdownItem.route) {
      history.push(`${url}/${dropdownItem.route}`);
    }
  };

  return (
    <Container className={className}>
      <Dropdown
        style={style}
        onToggle={(isOpen) => {
          setIsOpen(isOpen);
        }}
      >
        <Dropdown.Toggle
          as={TabItemWithArrow}
          id="dropdown-custom-components"
          isOpen={isOpen}
          defaultDropdownItem={defaultDropdownItem}
          dropdownItems={dropdownItems}
          {...rest}
        />
        <StyledDropdownMenu className="borderless-dropdown" $accentColor={rest.accentColor}>
          {dropdownItems?.map((item, index) => {
            return (
              <StyledDropdownItem
                key={index}
                onClick={() => handleClickItem(item)}
                $hoverColor={rest.hoverColor}
                $dividerColor={rest.highlightColor}
              >
                <ItemText>{item.name}</ItemText>
                {item.additionalContent}
              </StyledDropdownItem>
            );
          })}
          {children}
        </StyledDropdownMenu>
      </Dropdown>
    </Container>
  );
};

const NavbarTabContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0px var(--sp-3);
  white-space: nowrap;
  @media ${MediaConstants.laptopL} {
    margin: 0px var(--sp-2);
  }
`;

const Subtitle = styled.div`
  flex-grow: 1;
  height: 0;
  color: ${colors.navbarSubitle};
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  top: -5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12em;
  margin: 0 auto;
  padding: 0 0.5em;
`;

const HoverIcon = styled(MedIcon)<{ highlightColor: string }>`
  :hover {
    background-color: ${(props) => props.highlightColor};
    border-radius: 3px;
  }
`;

const NavbarTab = styled.div<NavbarTabProps>`
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin: auto 0px;
  @media ${MediaConstants.tablet} {
    min-width: ${layoutConstants.navBarItemMinWidthTablet};
  }

  p {
    font-size: 16px;
    @media ${MediaConstants.tablet} {
      font-size: 10px;
    }
    font-weight: 600;
    text-decoration: none;
    margin: 0;
  }
`;

const NavbarTabWithArrow = styled.div`
  height: 100%;
  cursor: pointer;
  align-items: center;
  min-width: ${layoutConstants.navBarItemMinWidth};
  @media ${MediaConstants.tablet} {
    min-width: ${layoutConstants.navBarItemMinWidthTablet};
  }
`;

const SelectedBar = styled.div<BarProps>`
  height: 10%;
  background-color: ${(props) => (props.active ? props.selectedColor : "transparent")};
`;

const NavbarContainer = styled.div<BarProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => (props.active ? props.selectedColor : "transparent")};
  &:hover {
    cursor: pointer;
    background: ${(props) => props.selectedColor};
  }
  border-radius: 0px;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

const NameText = styled.p`
  flex: 1;
  max-width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemText = styled.p`
  font-size: 18px;
  @media ${MediaConstants.tablet} {
    font-size: 12px;
  }

  font-weight: 600;
  color: ${colors.white};
  text-align: center;
  width: 100%;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)<{ $accentColor: string }>`
  background-color: ${(props) => props.$accentColor};
  & p {
    color: ${colors.white};
  }
  min-width: 100%;
  border: 0px;
`;

const StyledDropdownItem = styled(Dropdown.Item)<{ $hoverColor: string; $dividerColor: string }>`
  display: flex;
  align-items: center;
  height: 55px;
  :hover {
    background-color: ${(props) => props.$hoverColor};
    border-radius: 0px;
  }
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.$dividerColor};
`;

const NotifIconContainer = styled(Row)`
  position: absolute;
  right: 5px;
  top: 15px;
`;

const IconText = styled.p`
  position: absolute;
`;

export { NavbarTabItem, NavbarTabItemDropdown };
