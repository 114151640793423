import { useAppDispatch, useAppSelector } from "app/hooks";
import dayjs from "dayjs";
import { Spinner } from "primitives";
import { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getPaymentHistory } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { AsyncRequestState, defaultRequestState } from "types";
import { dispatchAsync } from "utils";

export const PaymentSettings = () => {
  const [{ loading, error }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);
  const dispatch = useAppDispatch();
  const { history } = useAppSelector((state) => state.paymentHistory);

  const fetchPaymentHistory = useCallback(async () => {
    dispatchAsync(dispatch(getPaymentHistory()), setRequestState);
  }, [dispatch]);

  useEffect(() => {
    fetchPaymentHistory();
  }, [fetchPaymentHistory]);

  if (loading) {
    return <Spinner />;
  } else if (error) {
    return <p>Unable to fetch payment history.</p>;
  } else if (!history?.length) {
    return <p>We've yet to charge you for anything!</p>;
  } else {
    return (
      <OuterContainer>
        <PaymentTable bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Purchase</th>
              <th>Total</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {history.map((paymentItem, index) => (
              <tr key={index}>
                <td>{dayjs(paymentItem.createdAt * 1000).format("DD/MM/YYYY")}</td>
                <td>{paymentItem.description}</td>
                <td>${(paymentItem.amount / 100).toFixed(2)}</td>
                <td>
                  <a href={paymentItem.invoiceLink} target="_blank" rel="noreferrer">
                    Download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </PaymentTable>
      </OuterContainer>
    );
  }
};

const OuterContainer = styled.div`
  overflow-x: auto;
`;

const PaymentTable = styled(Table)`
  thead {
    background-color: ${colors.brandGreen};
  }
  th,
  td {
    border-color: black;
  }
  thead th {
    border-bottom: 0;
  }
`;
