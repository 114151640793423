import { TutorialsProvider } from "components";
import { RootBreadcrumb } from "components/breadcrumb";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { breadcrumbConstants } from "textConstants";
import { ProgressTours } from "textConstants/tours";
import { uuidRegexString } from "utils/regexStrings";
import { ArticleProvider } from "components/articles";
import { ReviewClass } from "./reviewClass";

export interface ReviewParams {
  classId: string;
  studentId: string;
}

const ReviewRoutes = () => {
  const { url } = useRouteMatch(); // should be /teacher/progress
  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.progress.title}
        description={breadcrumbConstants.progress.breadcrumbDescription}
        icon={breadcrumbConstants.progress.icon}
      />
      <TutorialsProvider section="progress" tours={ProgressTours} />
      <ArticleProvider />
      <Switch>
        <Route path={`${url}/class/:classId(${uuidRegexString})?`} component={ReviewClass} />
        <Route path={`${url}`}>
          <Redirect to={`${url}/class`} />
        </Route>
      </Switch>
    </>
  );
};

export { ReviewRoutes as Review };
