import { FC } from "react";
import styled from "styled-components";
import { colors } from "styles";

type BadgeProps = React.ComponentProps<typeof BadgeText> & { color?: string };

const Badge: FC<BadgeProps> = ({ style, color, children, ...rest }) => (
  <BadgeText style={{ backgroundColor: color || colors.secondary, ...style }} {...rest}>
    {children}
  </BadgeText>
);

const BadgeText = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: white;
  padding: 0px 9px;
  font-weight: 600;
  font-size: 10px;
`;
export { Badge };
