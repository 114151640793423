import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { StudentActions } from "./studentSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { StudentName } from "types";
import { updateStudent } from "api";

const dispatchUpdateStudent = (studentId: string, names: StudentName, actions: StudentActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const data = await updateStudent({ token, studentId, names });

    dispatch(actions.mergeStudent(data));
  }, dispatch);

export { dispatchUpdateStudent };
