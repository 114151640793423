import { Step } from "react-joyride";
import { Location } from "history";
import { RootState } from "app/rootReducer";
import { match } from "react-router";

type TourCallback = (location: Location<unknown>, state: RootState, match?: match<any> | null) => boolean;

export type Tour = {
  id: string;
  name: string;
  steps: TourStep[];
  runAutomatically?: boolean | TourCallback;
  hideFromHelpMenu?: boolean | TourCallback;
  disableInHelpMenu?: boolean | TourCallback;
  startUrl?: string;
  scrollOffset?: number;
  disableScrolling?: boolean;
};

export const DefaultTourEmptyStepAutoProgressTimeout = 10;

export interface TourStep extends Step {
  disableClickthrough?: boolean;
  autoProgressCheck?: TourCallback;
  autoProgressTimeout?: number;
  isEmpty?: boolean;
  waitToProceed?: number;
  hideNext?: boolean | TourCallback;
  hideBack?: boolean | TourCallback;
  route?: string;
  isEnd?: boolean;
  top?: number;
  left?: number;
  onStart?: () => void;
  proceedWithButton?: boolean;
}

export function resolveTourCallback(
  callback: boolean | TourCallback | undefined,
  location: Location<unknown>,
  state: RootState,
  match?: match<any> | null
) {
  if (callback === undefined) return false;
  if (callback instanceof Function) {
    return callback(location, state, match);
  }
  return callback;
}
