import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { NavbarTabItem } from "./NavbarTab";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { getActiveNavbarIndexFromCurrentUrl } from "./getActiveNavbarIndexFromCurrentUrl";
import { StyledIcon } from "@styled-icons/styled-icon";
import { Spacer } from "primitives";
import { LargeIcon } from "primitives/icons";
import { useBreadcrumbs, useClass } from "hooks";

export interface NavbarItemProperties {
  icon: StyledIcon;
  name: string;
  route: string;
  routeParams: Array<string>;
  accentColor: string;
  highlightColor: string;
  component: React.ComponentType<any>;
  dontShowInNavbar?: boolean;
  showClass?: true;
}

export interface NavbarProps {
  navbarItems: Array<NavbarItemProperties>;
}

const Navbar: FC<NavbarProps> = ({ navbarItems }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const selectNavbarItem = (index: number) => {
    setActiveIndex(index);
  };

  const { url: navbarBaseUrl } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { setBreadcrumbColor } = useBreadcrumbs();

  useEffect(() => {
    const newActiveIndex = getActiveNavbarIndexFromCurrentUrl(location, navbarBaseUrl, navbarItems);
    setActiveIndex(newActiveIndex);
    if (navbarItems[newActiveIndex]) {
      setBreadcrumbColor(navbarItems[newActiveIndex].accentColor, navbarItems[newActiveIndex].highlightColor);
    }
  }, [location, navbarBaseUrl, navbarItems, setBreadcrumbColor]);

  return (
    <NavbarContainer className="navbar-container">
      {navbarItems.map(
        ({ icon, name, route, routeParams, accentColor, highlightColor, showClass, dontShowInNavbar }, index) => {
          // Every route param defaults to value of 1

          const defaultRouteParams = routeParams.reduce((acc, _param) => acc + "/1", "");
          if (dontShowInNavbar) {
            return <div key={index} />;
          }

          return (
            <NavbarTabItem
              className={route}
              key={index}
              active={index === activeIndex}
              onClick={() => {
                selectNavbarItem(index);
                history.push(`${navbarBaseUrl}/${route}${defaultRouteParams}`);
                setBreadcrumbColor(accentColor, highlightColor);
              }}
              accentColor={accentColor}
              highlightColor={highlightColor}
              subtitle={showClass && <SelectedClass />}
            >
              <LargeIcon icon={icon} />
              <Spacer size={4} />
              <p>{name}</p>
            </NavbarTabItem>
          );
        }
      )}
    </NavbarContainer>
  );
};

const SelectedClass: FC = () => {
  const selectedClass = useClass();

  return selectedClass ? <>{selectedClass.name}</> : <></>;
};

const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export { Navbar };
