import { ProcessSession, UnprocessedSession } from "api/deserialisation";
import { Session, SessionType } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  teacherId: string;
  sessionType: SessionType;
  classId?: string;
  startDate?: string;
  endDate?: string;
  take?: number;
  skip?: number;
  order?: string;
  orderby?: string;
}

const fetchSessions = async (
  { teacherId, sessionType, classId, startDate, endDate, take, skip, order, orderby }: Params,
  token: string
): Promise<Array<Session>> => {
  const url = `/teachers/${teacherId}/sessions`;
  const response = await api.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      classId,
      sessionType: SessionType[sessionType],
      startDate,
      endDate,
      take,
      skip,
      order,
      orderby,
    },
  });

  const sessions: Session[] = response.data.map((session: UnprocessedSession) => ProcessSession(session));

  if (sessions) {
    return sessions;
  } else {
    throw new NetworkError();
  }
};

export { fetchSessions };
