import { Column, ConfirmModal, Row, Spinner } from "primitives";
import { useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { QuestionList } from "./questionList";
import { QuestionEditor, UpdateQuestionData } from "./questionEditor";
import { MalformedQuestionsWarningBanner } from "./MalformedQuestionsWarningBanner";
import { fetchQuestionSetNew, updateQuestions } from "slices";
import { defaultRequestState, FC, MultichoiceQuestion, Question, QuestionSet, UserAction } from "types";
import { isQuestionValid } from "./questionSetValidation/generateErrorFeedbackForAnswerIndex";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  dispatchAsync,
  getQuestionSetLatestReviewSubmission,
  moveItemToBackOfArray,
  replaceOneElementInArray,
} from "utils";
import { v4 as uuid } from "uuid";
import { EditQuestionSetModal, PageNavigationPrompt, QuestionSetCard, UnsavedChangesComponent } from "components";
import { Breadcrumb, BreadcrumbTitleText } from "components/breadcrumb";
import { EditSessionModal } from "components";
import {
  useAddQuestionSetToNewSessionAction,
  useBreadcrumbs,
  useConfirmModal,
  useTestQuestionSetActions,
  useToggleFavouriteQuestionSetActions,
} from "hooks";
import { actionConstants, helpMessages, slugs } from "textConstants";
import { colors } from "styles";
import { GetReviewActionButton } from "components/questionSet/questionSetInfo/GetReviewActionButton";
import { generateDistractorsForAllAnswers } from "../Utils";

interface Props {
  canPlay?: boolean;
}

const QuestionSetEditor: FC<Props> = ({ canPlay }) => {
  const params = useParams<{ questionSetId: string }>();
  const questionSetId = params?.questionSetId;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const questionSet = useAppSelector((state) => state.questionSets.data && state.questionSets.data[questionSetId]);
  const [draftQuestionSet, setDraftQuestionSet] = useState(questionSet ? questionSet : null);

  const [{ loading }, setRequestState] = useState(defaultRequestState);

  const [newQuestionJustCreated, setNewQuestionJustCreated] = useState(false);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number | null>(null);
  const [isEditInfoModalVisible, setEditInfoModalVisible] = useState(false);
  const [isRandomiseInfoModalVisible, setRandomiseInfoModalVisible] = useState(false);
  const [questions, setQuestionSet] = useState(draftQuestionSet?.questions || []);

  const latestSubmission = questionSet ? getQuestionSetLatestReviewSubmission(questionSet) : null;

  const { accentColor } = useBreadcrumbs();

  const goBackToQuestionSetsPage = () => {
    history.push(url.replace(`/${slugs.edit}`, ""));
  };

  if (!questionSetId) {
    goBackToQuestionSetsPage();
  }

  const fetchQuestionSetFromPathParam = useCallback(async () => {
    if (!loading) {
      const { result: updatedQuestionSet } = await dispatchAsync(
        dispatch(fetchQuestionSetNew(questionSetId)),
        setRequestState
      );
      if (updatedQuestionSet) {
        setDraftQuestionSet(updatedQuestionSet);
      }
    }
  }, [dispatch, questionSetId, loading]);

  useEffect(() => {
    fetchQuestionSetFromPathParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!questionSet || questionSet.id !== questionSetId) {
      fetchQuestionSetFromPathParam();
    }
  }, [dispatch, questionSet, questionSetId, fetchQuestionSetFromPathParam]);

  useEffect(() => {
    if (draftQuestionSet?.reviewSubmissions?.length !== questionSet?.reviewSubmissions?.length) {
      fetchQuestionSetFromPathParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionSet?.reviewSubmissions]);

  useEffect(() => {
    setQuestionSet(draftQuestionSet?.questions || []);
  }, [draftQuestionSet]);

  const questionSetUpdated = (questionSetDetails: Partial<QuestionSet>) => {
    setDraftQuestionSet((prev) => ({ ...prev!, ...questionSetDetails }));
  };

  const replaceLast = (string: string, search: string, replaceWith: string) => {
    return string.replace(new RegExp(search + "([^" + search + "]*)$"), replaceWith + "$1");
  };

  const breadcrumb = (
    <>
      <Breadcrumb to={replaceLast(url, `/${slugs.edit}`, "")}>
        <BreadcrumbContent>
          <Row>
            <BreadcrumbTitleText $color={accentColor}>Question Set:</BreadcrumbTitleText>
            <p>{questionSet?.title}</p>
          </Row>
        </BreadcrumbContent>
      </Breadcrumb>
      <Breadcrumb to={url}>
        <BreadcrumbContent>
          <p>Edit Question Set</p>
        </BreadcrumbContent>
      </Breadcrumb>
    </>
  );

  if (loading || !draftQuestionSet || draftQuestionSet.id !== questionSetId) {
    return (
      <>
        {breadcrumb}
        <Spinner />
      </>
    );
  }

  const questionDeleted = (questionId: string, isDeleted: boolean) => {
    const questionsDetails = draftQuestionSet;
    const questions = draftQuestionSet.questions!;
    if (isDeleted) {
      const deletedQuestionIndex = questions.findIndex((question) => question.id === questionId);

      const deletedQuestion = questions[deletedQuestionIndex];

      const newQuestions = replaceOneElementInArray(questions, deletedQuestionIndex, {
        ...deletedQuestion,
        toBeDeleted: true,
        toBeUpdated: false,
      });

      setDraftQuestionSet({
        ...questionsDetails,
        questions: moveItemToBackOfArray(newQuestions, deletedQuestionIndex),
      });
    } else {
      const deletedQuestionIndex = questions.findIndex((question) => question.id === questionId);

      const deletedQuestion = questions[deletedQuestionIndex];

      const newQuestions = replaceOneElementInArray(questions, deletedQuestionIndex, {
        ...deletedQuestion,
        toBeDeleted: false,
        toBeUpdated: !deletedQuestion.toBeCreated,
      }).sort((q1, q2) => q1.order - q2.order);

      setDraftQuestionSet({ ...questionsDetails, questions: newQuestions });
    }
  };

  const commitUpdatedQuestions = async () => {
    const { success } = await dispatchAsync(dispatch(updateQuestions({ draftQuestionSet })), setRequestState);
    if (success) {
      setActiveQuestionIndex(null);
      fetchQuestionSetFromPathParam();
    } else {
      alert("Sorry, there was an issue with saving your changes. Please try again later.");
    }
  };

  const revertChanges = () => {
    setModalInfo({
      body: [helpMessages.questionSets.questions.revertAll],
      onModalConfirm: async () => {
        setActiveQuestionIndex(null);
        setDraftQuestionSet(questionSet);
      },
    });
    setModalVisible(true);
  };

  const addQuestion = (addQuestionData: Partial<Question>) => {
    const questionsDetails = draftQuestionSet;
    const questions = draftQuestionSet.questions || [];

    const maxQuestionOrder = questions.reduce((acc, question) => Math.max(acc, question.order), Number.MIN_VALUE);

    const newQuestion: Question = {
      id: uuid(),
      order: maxQuestionOrder + 1,
      toBeUpdated: false,
      toBeDeleted: false,
      toBeCreated: true,
      displayAsUnsaved: true,
      type: addQuestionData.type!,
      question: "",
      questionImage: null,
      ...addQuestionData,
    };

    setDraftQuestionSet({
      ...questionsDetails,
      questions: [...questions, newQuestion],
    });
  };

  const updateQuestion = (updateQuestionData: UpdateQuestionData) => {
    const questionsDetails = draftQuestionSet;
    const questions = draftQuestionSet.questions!;
    const oldActiveQuestion = questions[updateQuestionData.index];

    const updatedActiveQuestion = {
      ...oldActiveQuestion,
      ...updateQuestionData.question,
      toBeUpdated: !oldActiveQuestion.toBeCreated,
      displayAsUnsaved: true,
    };

    let newDraft: QuestionSet = {
      ...questionsDetails,
      questions: replaceOneElementInArray(questions, updateQuestionData.index, updatedActiveQuestion),
    };
    setDraftQuestionSet(newDraft);
  };

  const randomizeAllQuestionDistractors = () => {
    const multichoiceQuestions = draftQuestionSet.questions!.filter(
      (question) => question.type === "multichoice"
    ) as MultichoiceQuestion[];
    const questions = draftQuestionSet.questions!;
    let newQuestions = draftQuestionSet.questions!;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].type === "multichoice") {
        const oldQuestion = questions[i];
        const multichoiceQuestion = questions[i] as MultichoiceQuestion;

        const newAnswers = generateDistractorsForAllAnswers(
          multichoiceQuestions,
          multichoiceQuestion,
          multichoiceQuestion.answers
        );
        let newQuestion: Partial<MultichoiceQuestion> = { answers: newAnswers };
        const updatedActiveQuestion = {
          ...oldQuestion,
          ...newQuestion,
          toBeUpdated: !oldQuestion.toBeCreated,
          displayAsUnsaved: true,
        };
        newQuestions = replaceOneElementInArray(newQuestions, i, updatedActiveQuestion);
      }
    }

    setDraftQuestionSet({ ...draftQuestionSet, questions: newQuestions });
  };

  const anyUnsavedChanges = questions.some(
    (question) => question.toBeUpdated || question.toBeCreated || question.toBeDeleted
  );

  const anyMalformedQuestions = questions.some(
    (question) => !isQuestionValid(question) && !question.toBeDeleted && (question.toBeCreated || question.toBeUpdated)
  );

  const anyValidQuestions = questions.some((question) => !question.toBeDeleted);

  const footerActionsEnabled = () => !anyMalformedQuestions && anyValidQuestions;

  const editAction: UserAction = {
    id: "editDetails",
    name: "Edit",
    action: async () => setEditInfoModalVisible(true),
    isEnabled: () => !anyUnsavedChanges,
    icon: actionConstants.edit.icon,
  };

  // Build actions
  const cardActions = [
    () => {
      return [editAction];
    },
    useTestQuestionSetActions,
    useAddQuestionSetToNewSessionAction,
    useToggleFavouriteQuestionSetActions,
  ];

  return (
    <OuterContainer>
      <EditorContainer>
        {breadcrumb}
        <PageNavigationPrompt
          when={anyUnsavedChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Row style={{ overflow: "hidden", flex: 1 }}>
          <Column
            style={{
              width: "50%",
              padding: "0px 25px",
              backgroundColor: "white",
              borderRight: `1px solid ${colors.primaryDivider}`,
            }}
          >
            <QuestionSetCard questionSet={draftQuestionSet} actionHooks={cardActions} />
            <QuestionList
              questionSet={draftQuestionSet}
              activeQuestionIndex={activeQuestionIndex}
              newQuestionJustCreated={newQuestionJustCreated}
              randomiseAllDistractors={() => {
                setRandomiseInfoModalVisible(true);
              }}
              setNewQuestionJustCreated={setNewQuestionJustCreated}
              onActiveQuestionIndexChanged={setActiveQuestionIndex}
              onQuestionSetUpdated={questionSetUpdated}
              onQuestionDeleted={questionDeleted}
            />
            {anyMalformedQuestions && <MalformedQuestionsWarningBanner />}
          </Column>
          <QuestionEditor
            style={{ width: "50%" }}
            questionSet={draftQuestionSet}
            activeQuestionIndex={activeQuestionIndex}
            onNewQuestionCreated={() => setNewQuestionJustCreated(true)}
            onAddQuestion={addQuestion}
            onUpdateQuestion={updateQuestion}
          />
        </Row>

        {isEditInfoModalVisible ? (
          <EditQuestionSetModal
            visible={isEditInfoModalVisible}
            hide={() => {
              setEditInfoModalVisible(false);
            }}
            onComplete={(changes) => setDraftQuestionSet({ ...draftQuestionSet, ...changes })}
          />
        ) : null}
        {isRandomiseInfoModalVisible ? (
          <ConfirmModal
            title={<>Randomise All Distractors</>}
            body={[
              "Are you sure you want to randomise all distractors?",
              "This will replace the current distractors you have written with random options from the other correct answers in this question set.",
            ]}
            secondaryText={"Note: This only applies to Multiple Choice questions"}
            visible={isRandomiseInfoModalVisible}
            hide={() => {
              setRandomiseInfoModalVisible(false);
            }}
            onConfirm={() => {
              randomizeAllQuestionDistractors();
              setRandomiseInfoModalVisible(false);
            }}
          />
        ) : null}
      </EditorContainer>
      <UnsavedChangesComponent
        hasUnsavedChanges={anyUnsavedChanges}
        canApplyChanges={!anyMalformedQuestions && anyValidQuestions}
        applyChanges={commitUpdatedQuestions}
        revertChanges={revertChanges}
      >
        {canPlay && questionSet && (
          <RightButtons>
            <GetReviewActionButton
              questionSet={questionSet}
              latestSubmission={latestSubmission}
              saveQuestionSet={commitUpdatedQuestions}
              enabled={footerActionsEnabled}
            />
          </RightButtons>
        )}
      </UnsavedChangesComponent>

      <Switch>
        <Route path={`${path}/${slugs.session}/${slugs.new}`}>
          <EditSessionModal shouldDuplicate={false} onHidden={() => history.push({ pathname: url })} />
        </Route>
      </Switch>
      <Modal />
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

const EditorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`;

const BreadcrumbContent = styled.div`
  p {
    max-width: 25vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const RightButtons = styled.div`
  margin-left: auto;
`;

export { QuestionSetEditor };
