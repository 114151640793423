import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { merge } from "lodash";
import { Grouping, Partition } from "types";
import { dispatchFetchClassGroupings } from "./fetchClassGroupings";
import { dispatchCreateGrouping } from "./createGrouping";
import { dispatchUpdateGrouping } from "./updateGrouping";
import { dispatchDeleteGrouping } from "./deleteGrouping";
import { dispatchFetchGrouping } from "./fetchGrouping";

type GroupingsState = {
  data: { [id: string]: Grouping } | null;
};

const initialState: GroupingsState = {
  data: null,
};

// --- Slice Definition

const reducers = {
  reset(state: GroupingsState) {
    state.data = null;
  },
  addSingle(
    state: GroupingsState,
    action: PayloadAction<{ groupingId: string; grouping: Grouping; forceMerge?: boolean }>
  ) {
    if (state.data) {
      if (action.payload.forceMerge) {
        state.data[action.payload.groupingId] = action.payload.grouping;
      } else {
        state.data[action.payload.groupingId] = merge(state.data[action.payload.groupingId], action.payload.grouping);
      }
    } else {
      state.data = {
        [action.payload.groupingId]: action.payload.grouping,
      };
    }
  },
  addMany(state: GroupingsState, action: PayloadAction<Grouping[]>) {
    if (!state.data) {
      state.data = {};
    }
    for (const session of action.payload) {
      if (!(session.id in state.data)) {
        state.data[session.id] = session;
      }
    }
  },
  remove(state: GroupingsState, action: PayloadAction<{ groupingId: string }>) {
    if (state.data && state.data[action.payload.groupingId]) {
      delete state.data[action.payload.groupingId];
    }
  },
};
const slice = createSlice<GroupingsState, typeof reducers>({
  name: "groupings",
  initialState,
  reducers,
});

// --- Async Action Wrappers
const fetchClassGroupings = (classId: string) => dispatchFetchClassGroupings({ classId }, slice.actions);
const fetchGrouping = (groupingId: string) => dispatchFetchGrouping({ groupingId }, slice.actions);
const createGrouping = (classId: string, groupingName: string, partitions: Partition[]) =>
  dispatchCreateGrouping({ classId, name: groupingName, partitions }, slice.actions);
const updateGrouping = (groupingId: string, grouping: Grouping) =>
  dispatchUpdateGrouping({ groupingId, updateData: grouping }, slice.actions);
const deleteGrouping = (groupingId: string) => dispatchDeleteGrouping({ groupingId }, slice.actions);
// --- Exports
export type GroupingActions = typeof slice.actions;

const groupingsReducer = slice.reducer;
export { groupingsReducer, fetchClassGroupings, fetchGrouping, createGrouping, updateGrouping, deleteGrouping };
