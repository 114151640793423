import { FC } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { Article, ArticleCategory } from "types";
import { Column, HorizontalLine, Row, Spacer } from "primitives";
import { Button } from "react-bootstrap";
import { LargeIcon } from "primitives/icons";
import Accordion from "react-bootstrap/Accordion";
import { GetArticleCategoryIcon } from "textConstants";
interface Props {
  articles: Partial<Article>[];
  onArticleClick: (article: Partial<Article>) => void;
}

type SortedArticles = {
  category: ArticleCategory;
  articles: Partial<Article>[];
};

const ArticleCategoryList: FC<Props> = ({ articles, onArticleClick }) => {
  const sortArticlesToCategories = () => {
    let sortedArticles: SortedArticles[] = [];

    articles.forEach((article) => {
      let index = -1;
      for (let i = 0; i < sortedArticles.length; i++) {
        if (article.category) {
          if (article.category.name === sortedArticles[i].category.name) {
            index = i;
            break;
          }
        }
      }

      if (index === -1 && article.category) {
        index = sortedArticles.length;
        sortedArticles.push({ category: article.category, articles: [] });
      }

      if (article.category) {
        sortedArticles[index].articles.push(article);
      }
    });

    return sortedArticles;
  };

  const sortedArticles = sortArticlesToCategories();

  return (
    <ArticleCategoryListContainer>
      <HorizontalLine />
      {sortedArticles.map((sortedArticle) => {
        const categoryName = sortedArticle.category.name;
        return (
          <ArticleAccordian key={categoryName}>
            <ArticleAccordionToggle eventKey={`${categoryName}`} variant="link">
              <Row style={{ alignItems: "center" }}>
                <LargeIcon style={{ height: "100%" }} icon={GetArticleCategoryIcon(categoryName)} />
              </Row>
              <Row style={{ alignItems: "center" }}>
                <p>{sortedArticle.category.name}</p>
              </Row>
            </ArticleAccordionToggle>
            <Accordion.Collapse eventKey={`${sortedArticle.category.name}`}>
              <ArticleContainer>
                {sortedArticle.articles &&
                  sortedArticle.articles.map((article) => {
                    return (
                      <ArticleButton
                        key={article.id}
                        variant="light"
                        onClick={() => {
                          onArticleClick(article);
                        }}
                      >
                        <ArticleButtonText>{`${article.title}`}</ArticleButtonText>
                      </ArticleButton>
                    );
                  })}
              </ArticleContainer>
            </Accordion.Collapse>
          </ArticleAccordian>
        );
      })}
      <Spacer size={60} />
    </ArticleCategoryListContainer>
  );
};

const ArticleButton = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background-color: ${colors.white};
  border-color: ${colors.white};
  border-top: 1px solid black;
  min-height: 3em;
  padding-left: 60px;
`;

const ArticleButtonText = styled.p`
  font-color: ${colors.darkText};
  height: 100%;
`;

const ArticleAccordian = styled(Accordion)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ArticleContainer = styled(Column)`
  width: 100%;
`;

const ArticleAccordionToggle = styled(Accordion.Toggle)`
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const ArticleCategoryListContainer = styled.div`
  max-width: 800px;
  min-width: 800px;
`;

export { ArticleCategoryList };
