import styled from "styled-components";
import { FC } from "types";
import { MoveDownButton, MoveUpButton } from "primitives/button/MoveButton";
import {  SmallIcon } from "primitives/icons";

import { Menu } from "@styled-icons/material";
import { Column } from "primitives";

export type ItemAlignment = "start" | "middle" | "end" | "none";
export type MoveDirection = -1 | 1;

interface Props {
  index: number;
  alignment: ItemAlignment;
  onMovePressed?: (index: number, direction: MoveDirection) => void;
}

const DragItemHandle: FC<Props> = ({ index, alignment, onMovePressed }) => {
  const isFirst = alignment === "start" || alignment === "none";
  const isLast = alignment === "end" || alignment === "none";
  return (
    <OuterContainer>
      <MoveUpButton onClick={() => onMovePressed && onMovePressed(index, -1)} isEnabled={!isFirst} />
      <SmallIcon icon={Menu} />
      <MoveDownButton onClick={() => onMovePressed && onMovePressed(index, 1)} isEnabled={!isLast} />
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  width: 20px;
  justify-content: center;
  align-items: center;
  margin: 0px 0.5em;
`;

export { DragItemHandle };
