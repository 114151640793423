import { MultipleChoiceIcon, TextIcon } from "icons";

export const questionConstants = {
  questionTypes: {
    multichoice: {
      copy: "Multiple Choice",
      icon: MultipleChoiceIcon,
    },
    freetext: {
      copy: "Free Text",
      icon: TextIcon,
    },
  },
};
