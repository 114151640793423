import { QuestionSetCard, EmptyPanelContainer, InfiniteScrollList } from "components";
import { createRef, FC, useEffect, useState, ReactNode } from "react";
import { QuestionSet } from "types";
import { Spinner } from "primitives";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { QuestionSetActionHook } from "hooks";
import styled from "styled-components";

interface ResultMessage {
  title: string;
  message: ReactNode;
}

interface Props {
  navigateToPage: (newPage: number) => void;
  loading: boolean;
  page: number;
  selectedQuestionSetIds: string[];
  focusedQuestionSetId: string;
  onQuestionSetSelected: (id: string) => void;
  actionHooks?: Array<QuestionSetActionHook>;
  emptyResultMessage?: ResultMessage;
  showQuestionSetCount?: boolean;
}

const isFirstPage = (page: number) => {
  return page === 1;
};

const QuestionSetListContent: FC<Props> = ({
  navigateToPage,
  loading,
  page,
  selectedQuestionSetIds,
  onQuestionSetSelected,
  actionHooks,
  emptyResultMessage,
}) => {
  const { orderedIds, total } = useSelector((state: RootState) => state.questionSets.paginationData);
  const { data: allQuestionSets } = useSelector((state: RootState) => state.questionSets);

  const [infQuestionSets, setInfQuestionSets] = useState<QuestionSet[]>([]);
  const scrollerRef = createRef<HTMLDivElement>();

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if (allQuestionSets) {
      let orderedQuestionSets: QuestionSet[] = [];
      for (const id of orderedIds) {
        orderedQuestionSets.push(allQuestionSets[id]);
      }
      setInfQuestionSets(orderedQuestionSets);
    } else if (orderedIds) {
      // If there are no question sets, but there are ids
      // we're in an invalid state, and should trigger a
      // refresh
      navigateToPage(1);
    }
  }, [allQuestionSets, orderedIds, navigateToPage]);

  // Used to reset the scroll bar when swapping between tabs
  useEffect(() => {
    if (isFirstPage(page) && scrollerRef && scrollerRef.current) {
      scrollerRef.current.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchMoreData = () => {
    navigateToPage(page + 1);
  };

  const onScroll = () => {
    setScroll((prev) => (prev + 1) % 2);
  };

  return (
    <Container className="question-set-list" $loading={loading}>
      {(loading && isFirstPage(page)) || !allQuestionSets ? (
        <Spinner />
      ) : infQuestionSets.length === 0 ? (
        emptyResultMessage ? (
          <EmptyPanelContainer {...emptyResultMessage} />
        ) : (
          <EmptyPanelContainer title="No Question Sets found" />
        )
      ) : (
        <InfiniteScrollList
          hasData={Boolean(total)}
          currentDataLength={infQuestionSets.length}
          fetchMoreData={fetchMoreData}
          hasMoreToFetch={infQuestionSets.length < total}
          onScroll={onScroll}
          style={{
            flex: 1,
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            pointerEvents: loading ? "none" : "auto",
          }}
        >
          {infQuestionSets.map((questionSet, index) => (
            <QuestionSetCard
              key={index}
              questionSet={questionSet}
              selected={selectedQuestionSetIds.includes(questionSet.id)}
              focused={false}
              onClick={() => onQuestionSetSelected(questionSet.id)}
              actionHooks={actionHooks}
              scroll={scroll}
            />
          ))}
        </InfiniteScrollList>
      )}
    </Container>
  );
};

const Container = styled.div<{ $loading: boolean }>`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export { QuestionSetListContent };
