import { FC } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { ToolTip } from "primitives";
import { MedIcon } from "primitives/icons";
import { Star, StarBorder } from "@styled-icons/material";

interface Props {
  isFavourite: boolean;
  isInteractive: boolean;
  name: string;
  onClick?: () => void;
}

const QuestionSetFavouriteStar: FC<Props> = ({ isFavourite, isInteractive, name, onClick }) => {
  return (
    <ToolTip tooltip={name}>
      <OuterContainer isInteractive={isInteractive} onClick={onClick}>
        {isFavourite ? (
          <MedIcon icon={Star} color={colors.warning} />
        ) : (
          <MedIcon icon={StarBorder} color={colors.secondary} />
        )}
      </OuterContainer>
    </ToolTip>
  );
};

const OuterContainer = styled.div<Partial<Props>>`
  cursor: ${(props) => (props.isInteractive ? "pointer" : "default")};
  display: flex;
`;

export { QuestionSetFavouriteStar };
