import styled from "styled-components";
import { colors } from "styles";

const MalformedQuestionsWarningBanner = () => {
  return <OuterContainer>You can't save your changes since some questions are incomplete.</OuterContainer>;
};

const OuterContainer = styled.div`
   background-color: ${colors.paleDanger};
  border: 2px solid ${colors.danger};

  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  margin-top: 10px;
  padding: 5px 0;
`;

export { MalformedQuestionsWarningBanner };
