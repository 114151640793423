import styled from "styled-components";
import { FC, getSessionType, Session } from "types";
import { actionConstants, Names } from "textConstants";
import { LessonPlanListItem } from "../../LessonPlanListItem";
import classNames from "classnames";
import { colors } from "styles";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { paths, slugs } from "textConstants/paths";
import { EditSessionModal } from "components";
import { SessionActionHook } from "hooks";
import { AdjustSessionTimeModal } from "../../editSessionModal/AdjustSessionTimeModal";

interface Props {
  session: Session;
}

export const SessionMainDetails: FC<Props> = ({ session }) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const onEditModalHidden = () => {
    history.push(url);
  };

  const actionHooks: SessionActionHook[] = [
    (session: Session) => {
      return [
        {
          ...actionConstants.edit,
          id: "settings",
          action: async () => history.push(paths.teacher.myGames.session.edit.settings.generate(session)),
          priority: 1,
        },
      ];
    },
  ];

  return (
    <OuterContainer className="session-details p-3 mb-2">
      <StyledLessonPlanItem
        timeDisplay="inline"
        omitActions={["edit", "changeEndTime", "preview"]}
        includeActions={actionHooks}
        session={session}
        sessionType={getSessionType(session)}
      />

      <Route path={paths.teacher.myGames.session.edit.settings.path}>
        <EditSessionModal shouldDuplicate={false} onHidden={onEditModalHidden} />
      </Route>
      <Route path={`${path}/${slugs.start}`}>
        <AdjustSessionTimeModal
          title={`Start ${Names.Session}`}
          subtitle={`Set when this ${Names.Session} will end`}
          onHidden={onEditModalHidden}
        />
      </Route>
    </OuterContainer>
  );
};

const StyledLessonPlanItem = styled(LessonPlanListItem).attrs(({ className }) => ({
  className: classNames(className, "p-0", "mx-2", "border-0"),
}))``;

const OuterContainer = styled.div`
  border-bottom: 1px solid ${colors.primaryDivider};
`;
