import { NoOverflowText } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { AnswerTuple, FC } from "types";
import { QuestionSetQuestionImage } from "components";
import classNames from "classnames";

interface AnswerProps {
  answers?: AnswerTuple | string[];
  answerImages?: AnswerTuple;
  answerIndex: number;
  isCorrect: boolean;
  className?: string;
}

export const AnswerDisplay: FC<AnswerProps> = ({ className, answers, answerImages, answerIndex, isCorrect }) => {
  if (!answers) {
    return null;
  }
  const isEmptyAnswer = !answers[answerIndex] && (!answerImages || !answerImages[answerIndex]);
  if (isEmptyAnswer) {
    return null;
  }

  const content = (
    <>
      <NoOverflowText>{answers[answerIndex]}</NoOverflowText>
      {answerImages && <QuestionSetQuestionImage imageUrl={answerImages[answerIndex]} />}
    </>
  );

  return isCorrect ? (
    <CorrectAnswerContainer
      className={classNames(className)}
      key={answerIndex}
      hasImages={Boolean(answerImages && answerImages[answerIndex])}
    >
      {content}
    </CorrectAnswerContainer>
  ) : (
    <WrongAnswerContainer
      className={classNames(className)}
      key={answerIndex}
      hasImages={Boolean(answerImages && answerImages[answerIndex])}
    >
      {content}
    </WrongAnswerContainer>
  );
};

const AnswerContainer = styled.div<{ hasImages: boolean }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${(props) => (props.hasImages ? "70px" : "30px")};
`;

const WrongAnswerContainer = styled(AnswerContainer)`
  color: ${colors.secondary};
`;

const CorrectAnswerContainer = styled(AnswerContainer)`
  color: white;
  font-weight: var(--correct-font-weight);
`;
