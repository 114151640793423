import React from "react";
import styled from "styled-components";
import { QuestionSetListTab, SingleQuery } from "pages/questions/questionSetList";
import { QuestionSetShareLinkModal } from "pages/questions/questionSetDetails/QuestionSetShareLinkModal";
import { allQuestionSetActionHooks, usePreviewAction } from "hooks";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { resetQuestionSets } from "slices";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { slugs } from "textConstants";
import { QuestionSetDetailsModal } from "pages/questions/questionSetDetails/QuestionSetDetailsModal";
import { Column } from "primitives";
import { layoutConstants } from "styles";
import { MediaConstants } from "utils/mediaConstants";

const filterParams: SingleQuery = {
  archived: false,
  featured: true,
  isFavourite: undefined,
  onlyMyOwn: false,
};

export const PublicQuestionSetList: React.FC<{}> = () => {
  const { search } = useLocation();

  const { url, path } = useRouteMatch();
  const history = useHistory();

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(resetQuestionSets());
  }, [dispatch]);

  return (
    <>
      <OuterContainer>
        <QuestionSetListContainer className="px-5 py-4 question-set-selector">
          <QuestionSetListTab
            filterParams={filterParams}
            selectedQuestionSetIds={[]}
            onQuestionSetSelected={() => {}}
            actionHooks={[...allQuestionSetActionHooks, usePreviewAction]}
          />
        </QuestionSetListContainer>

        <Switch>
          <Route path={`${path}/${slugs.questionSetId}`} exact>
            <QuestionSetDetailsModal
              visible={true}
              onClose={() => history.push({ pathname: url, search })}
              actionHooks={allQuestionSetActionHooks}
            />
          </Route>
          <Route path={`${path}/${slugs.questionSetId}/${slugs.share}`}>
            <QuestionSetShareLinkModal visible={true} onClose={() => history.push({ pathname: url, search })} />
          </Route>
        </Switch>
      </OuterContainer>
    </>
  );
};

const QuestionSetListContainer = styled(Column)`
  height: 100%;
  width: 100%;
  min-width: ${layoutConstants.questionSets.listWidth};
  @media ${MediaConstants.laptopL} {
    min-width: ${layoutConstants.questionSets.smallListWidth};
  }
`;

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
`;
