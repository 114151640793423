import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { DateRange } from "types/DateRange";
import dayjs from "dayjs";
import { numbersAndLettersRegexString } from "utils/regexStrings";
import { isValidEmail } from "utils";

export type Validator = [(state: any, fieldName?: string) => string | undefined];

// Uses validation rules to check if a field is valid or not
// Returns true if it passes the validation checks, otherwise false.
const validate = <T>(value: T, validationRules: Validator): boolean => {
  for (let i = 0; i < validationRules.length; ++i) {
    const ruleError = validationRules[i](value);
    if (Boolean(ruleError)) {
      return false;
    }
  }
  return true;
};

const useValidatableState = <T>(
  initialState: T,
  validationRules: Validator,
  fieldName?: string
): [T, Dispatch<SetStateAction<T>>, string | undefined] => {
  const [obj, setObj] = useState(initialState);
  const [error, setError] = useState<string | undefined>();
  const validatedSetObj: Dispatch<SetStateAction<T>> = useCallback(
    (newState: SetStateAction<T>) => {
      setObj(newState);
      for (let i = 0; i < validationRules.length; ++i) {
        const ruleError = validationRules[i](newState, fieldName);
        setError(ruleError);
      }
    },
    [fieldName, validationRules]
  );
  return [obj, validatedSetObj, error];
};

const nonEmptyInputValidator = (state: string | undefined, fieldName?: string) => {
  if (!state || state.trim() === "") {
    if (fieldName) {
      return `${fieldName} cannot be empty`;
    }
    return "Field cannot be empty";
  }
};

const characterLimitedValidator = (state: string | undefined, fieldName?: string, characterLimit?: number) => {
  const defaultCharacterLimit = 240;
  const limit = characterLimit || defaultCharacterLimit;
  if (state && state.trim().length > limit) {
    if (fieldName) {
      return `${fieldName} must be less than ${limit} characters`;
    }
    return `Field must be less than ${limit} characters`;
  }
};

const characterLimitedNonEmptyValidator = (state: string | undefined, fieldName?: string, characterLimit?: number) => {
  const characterValidation = characterLimitedValidator(state, fieldName, characterLimit);
  return characterValidation || nonEmptyInputValidator(state, fieldName);
};

const endOnlyDateRangeValidator = (state: Partial<DateRange>, fieldName?: string) => {
  const emptyDateErrorMessage = "Specify an end date.";
  const endDateFutureMessage = "End date must be in the future";

  if (!state || !state.endDate) {
    return emptyDateErrorMessage;
  } else {
    const endDayJs = dayjs(state.endDate);
    const now = dayjs();

    if (endDayJs.isBefore(now)) {
      return endDateFutureMessage;
    }
  }
};

const dateRangeValidator = (state: DateRange | undefined, fieldName?: string, skipEndDateFutureCheck?: boolean) => {
  const invalidDateErrorMessage = fieldName
    ? `${fieldName} start date must be before the end date.`
    : `Start date must be before the end date.`;
  const emptyDateErrorMessage = "Specify a start and end date.";
  const endDateFutureMessage = "End date must be in the future";

  if (!state || !state.startDate || !state.endDate) {
    return emptyDateErrorMessage;
  } else {
    const startDayJs = dayjs(state.startDate);
    const endDayJs = dayjs(state.endDate);
    const now = dayjs();

    if (startDayJs.isAfter(endDayJs) || startDayJs.isSame(endDayJs, "minute")) {
      return invalidDateErrorMessage;
    }
    if (endDayJs.isBefore(now) && !skipEndDateFutureCheck) {
      return endDateFutureMessage;
    }
  }
};

const futureDateRangeValidator = (state: DateRange | undefined, fieldName?: string) => {
  const validateDateRange = dateRangeValidator(state, fieldName);

  if (validateDateRange) {
    return validateDateRange;
  }

  const noTimeRemainsMessage = "End date must be in the future";
  const endDayJs = dayjs(state!.endDate);
  if (endDayJs < dayjs()) {
    return noTimeRemainsMessage;
  }
};

const passwordValidator = (state: string | undefined, fieldName?: string) => {
  const invalidRegexErrorMessage = fieldName
    ? `${fieldName} must contain both letters and numbers.`
    : `Must contain both letters and numbers.`;
  const invalidLengthErrorMessage = fieldName
    ? `${fieldName} must be a minimum of 8 characters.`
    : `Must be a minimum of 8 characters.`;

  if (state) {
    if (state.match(numbersAndLettersRegexString) === null) {
      return invalidRegexErrorMessage;
    } else if (state.length < 8) {
      return invalidLengthErrorMessage;
    }
  }
};

const emailValidator = (state: string | undefined, fieldName?: string) => {
  if (!state) {
    return "Please enter your email address.";
  } else if (!isValidEmail(state)) {
    return "Please enter a valid email address";
  }
};

export {
  validate,
  useValidatableState,
  nonEmptyInputValidator,
  dateRangeValidator,
  endOnlyDateRangeValidator,
  futureDateRangeValidator,
  passwordValidator,
  characterLimitedValidator,
  characterLimitedNonEmptyValidator,
  emailValidator,
};
