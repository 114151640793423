import { Spinner } from "primitives";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { FC, ImageSize } from "types";

interface Props {
  imageUrl: string | null;
  size?: "sm" | "lg";
}
export const QuestionSetQuestionImage: FC<Props> = ({ imageUrl, size = "sm" }) => {
  const imageSize = size === "sm" ? "60px" : "120px";

  return imageUrl ? (
    <Container>
      <LazyLoadImage
        src={imageUrl + ImageSize.Small}
        alt={imageUrl}
        placeholder={<Spinner />}
        height={imageSize}
        width={imageSize}
      />
    </Container>
  ) : null;
};

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
