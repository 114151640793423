import { createSlice } from "@reduxjs/toolkit";

type BlurOverlayState = {
  appIsBlurred: boolean;
};

const initialState: BlurOverlayState = { appIsBlurred: false };

const blurOverlaySlice = createSlice({
  name: "blurOverlay",
  initialState,
  reducers: {
    blurApp(state) {
      state.appIsBlurred = true;
    },
    unBlurApp(state) {
      state.appIsBlurred = false;
    },
  },
});

export const { blurApp, unBlurApp } = blurOverlaySlice.actions;

const blurOverlayReducer = blurOverlaySlice.reducer;
export { blurOverlayReducer };
