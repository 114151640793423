import { useAppSelector } from "app/hooks";
import { useLogin } from "hooks";
import { FC } from "react";
import { Route, RouteProps } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { paths } from "textConstants";

interface Props extends RouteProps {
  component: React.ComponentType<any>;
}

type BaseProps = Props & {
  loginPageUrl: string;
  loginToken: string | null;
  requiresValidation: boolean;
  isValidated?: boolean;
};

const PrivateTeacherRoute: FC<Props> = ({ component, ...rest }) => {
  const { token, userInfo } = useLogin();

  return (
    <PrivateRoute
      component={component}
      loginPageUrl={paths.login.teacher.path}
      loginToken={token}
      requiresValidation={true}
      isValidated={userInfo?.emailVerified}
      {...rest}
    />
  );
};

const PrivateStudentRoute: FC<Props> = ({ component, ...rest }) => {
  const { token } = useAppSelector((state) => state.studentLogin);

  return (
    <PrivateRoute
      component={component}
      loginPageUrl={paths.login.student.path}
      loginToken={token}
      requiresValidation={false}
      {...rest}
    />
  );
};

const PrivateRoute: FC<BaseProps> = ({
  component: Component,
  loginPageUrl,
  loginToken,
  requiresValidation,
  isValidated,
  ...rest
}) => {
  const postLoginDestination = encodeURIComponent(window.location.pathname + window.location.search);

  const search = postLoginDestination ? `?postLoginDestination=${postLoginDestination}` : "";
  const isLoggedIn = Boolean(loginToken);

  if (!isLoggedIn) {
    return <Redirect to={{ pathname: loginPageUrl, search }} />;
  } else if (requiresValidation && !isValidated) {
    return <Redirect to={{ pathname: `${loginPageUrl}/validate`, search }} />;
  } else {
    return (
      <Route {...rest}>
        <Component />
      </Route>
    );
  }
};

export { PrivateTeacherRoute, PrivateStudentRoute };
