import { Close } from "@styled-icons/material";
import { SmallIcon } from "primitives/icons";
import styled from "styled-components";
import { colors } from "styles";

const CloseButton = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <Container {...props}>
    <SmallIcon icon={Close} />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.25s all ease-in-out;
  :hover {
    background-color: ${colors.lightHover};
  }
`;

export { CloseButton };
