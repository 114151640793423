import styled, { CSSProperties } from "styled-components";
import { FC } from "react";
import { FaCheck } from "react-icons/fa";

interface Props {
  checked: boolean;
  setChecked: (newState: boolean) => void;
  style?: CSSProperties;
}

const CHECKBOX_SIZE = 30;

const Container = styled.div`
  width: ${CHECKBOX_SIZE}px;
  min-width: ${CHECKBOX_SIZE}px;
  height: ${CHECKBOX_SIZE}px;
  min-height: ${CHECKBOX_SIZE}px;
  border-radius: 10px;
  border: solid black 1px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox: FC<Props> = ({ checked, setChecked, style }) => {
  return (
    <Container
      style={style}
      onClick={(e) => {
        e.preventDefault();
        setChecked(!checked);
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <FaCheck
        style={{
          display: checked ? "inline" : "none",
          color: "black",
          width: 0.7 * CHECKBOX_SIZE,
          height: 0.7 * CHECKBOX_SIZE,
        }}
      />
    </Container>
  );
};

export { Checkbox };
export type { Props as CheckboxProps };
