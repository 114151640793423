import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";

interface DefaultClassState {
  id?: string;
  groupingId?: string;
}

const initialState: DefaultClassState = {};

export const defaultClassSlice = createSlice({
  name: "defaultClass",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
    setGrouping: (state, action: PayloadAction<string | undefined>) => {
      state.groupingId = action.payload;
    },
  },
});

export const { set: setDefaultClass, setGrouping: setDefaultGrouping } = defaultClassSlice.actions;
export const selectDefaultClass = (state: RootState) => state.defaultClass.id;
export const selectDefaultGrouping = (state: RootState) => state.defaultClass.groupingId;

export const defaultClassReducer = defaultClassSlice.reducer;
