import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { createQuestionSet as createQuestionSetApi } from "api";

export interface CreateQuestionSetParams {
  title: string;
  description: string;
  estimatedLength: number;
  tagIds: Array<string>;
}

const dispatchCreateQuestionSet = (
  { title, description, estimatedLength, tagIds }: CreateQuestionSetParams,
  actions: QuestionSetActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const newQuestionSet = await createQuestionSetApi({ title, description, estimatedLength, tags: tagIds }, token);
    dispatch(
      actions.addSingle({
        id: newQuestionSet.id,
        questionSet: newQuestionSet,
      })
    );
    return newQuestionSet;
  }, dispatch);

export { dispatchCreateQuestionSet };
