import styled from "styled-components";
import { colors } from "styles";
import { FC, Session } from "types";
import { humaniseDateFromNowForSessionType, getRelevantDateForSession } from "utils";
import { getSessionType } from "types/Session";

interface Props {
  session: Session;
}

const SessionTimeLabel: FC<Props> = ({ session }) => {
  const sessionType = getSessionType(session);
  return (
    <>
      <TimeLabel>
        {humaniseDateFromNowForSessionType(getRelevantDateForSession(session, sessionType), sessionType)}
      </TimeLabel>
    </>
  );
};

const TimeLabel = styled.p`
  color: ${colors.secondary};
  margin: auto;
  padding: 0px 15px;
  font-weight: 550;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
`;

export { SessionTimeLabel };
