import { DescriptionText, ErrorText, LoadingContent } from "components";
import { useLogin, useQueryParams } from "hooks";
import { PrimaryButton } from "primitives/button";
import { Center, Column, Row, Spacer } from "primitives";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "app/hooks";
import { refreshTeacherData } from "slices";
import { useHistory } from "react-router-dom";
import { getTeacherRedirectOnLogin } from "utils";

export const RequestValidateEmailElement = () => {
  const { logout, requestVerifyEmail, userInfo, error, loading } = useLogin();
  const [hasSentRequest, setHasSentRequest] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { params: queryParams } = useQueryParams<{ postLoginDestination: string }>({ postLoginDestination: "" });

  const handleContinue = async () => {
    const newUserData = await dispatch(refreshTeacherData());

    if (newUserData?.emailVerified) {
      history.push(getTeacherRedirectOnLogin(queryParams.postLoginDestination));
    }
  };

  const handleBackToLogin = () => {
    logout();
  };

  const handleValidateEmail = () => {
    requestVerifyEmail(userInfo!.id);
    setHasSentRequest(true);
  };

  const RequestCompleteElement = () => (
    <>
      <Column>
        <Center>
          {error ? (
            <ErrorText>There was an error requesting email verification. Please try again.</ErrorText>
          ) : (
            <DescriptionText>
              An email has been sent to your email address. Follow the instructions listed in the email to verify your
              account. If you don't recieve an email in a few minutes, please check the email address you entered.
            </DescriptionText>
          )}
        </Center>
      </Column>
      <Spacer size={20} />
      <Center>
        <PrimaryButton onClick={handleBackToLogin}>Back to Log In</PrimaryButton>
      </Center>
    </>
  );

  return (
    <LoadingContent loading={loading}>
      {hasSentRequest ? (
        <RequestCompleteElement />
      ) : (
        <>
          <Item>
            <DescriptionText>
              Your email {userInfo ? userInfo!.email : ""} is not verified. Follow the instructions in the verification
              email to verify your email. If you did not receive an email, you may request another below.
            </DescriptionText>
          </Item>
          <Spacer size={20} />
          {userInfo ? (
            <Row>
              <Center>
                <PrimaryButton onClick={handleValidateEmail}>Request verification email</PrimaryButton>
              </Center>
            </Row>
          ) : (
            ""
          )}
          <Spacer size={20} />
          <Center>
            <PrimaryButton size="sm" onClick={handleContinue}>
              Continue
            </PrimaryButton>
          </Center>
          <Spacer size={10} />
          <Center>
            <Button variant="link" onClick={handleBackToLogin}>
              <small>Return to Log In</small>
            </Button>
          </Center>
        </>
      )}
    </LoadingContent>
  );
};

const Item = styled.div`
  width: 100%;
`;
