import { CSSProperties, InputHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import { Input } from "primitives";

const Label = styled.p`
  font-weight: 800;
  font-size: 22px;
  margin: 0 0 5px 20px;
`;

interface Props {
  label: string | ReactNode;
  labelStyle?: CSSProperties;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  style?: CSSProperties;
}

const InputWithLabel = ({ label, labelStyle, inputProps, style }: Props) => (
  <div style={style}>
    <Label style={labelStyle}>{label}</Label>
    <Input {...inputProps}></Input>
  </div>
);

export { InputWithLabel };
export type { Props as InputWithLabelProps };
