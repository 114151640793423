import { ErrorOutline } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { Row, Spacer } from "primitives";
import { FC } from "react";
import { Alert } from "react-bootstrap";
import { colors } from "styles";
import { ApiError } from "types";
import { PlatformTitle } from "textConstants";
import { StyledIcon } from "@styled-icons/styled-icon";

interface Props {
  errorMessage?: string;
  colour?: string;
  icon?: StyledIcon;
}

interface ApiProps {
  error: ApiError | null;
  errorMessages?: Partial<Record<ApiError, string>>;
  defaultErrorMessage?: string;
}

const standardErrors = {
  [ApiError.networkError]: `We could not connect to ${PlatformTitle} servers.`,
};

const APIFormErrorFeedback: FC<ApiProps> = ({ error, errorMessages, defaultErrorMessage }) => {
  const combinedErrorMessages: Partial<Record<ApiError, string>> = {
    ...errorMessages,
    ...standardErrors,
  };

  return error ? (
    <ErrorComponent errorMessage={combinedErrorMessages[error] || defaultErrorMessage || "Something went wrong."} />
  ) : null;
};

const FormErrorFeedback: FC<Props> = ({ errorMessage }) => {
  return errorMessage ? <ErrorComponent errorMessage={errorMessage} /> : null;
};

const FormWarningFeedback: FC<Props> = ({ errorMessage }) => {
  return errorMessage ? <ErrorComponent errorMessage={errorMessage} colour={colors.warning} /> : null;
};

const ErrorComponent: FC<Props> = ({ errorMessage, icon = ErrorOutline, colour = colors.danger }) => (
  <Row style={{ alignItems: "center", padding: "0.1em 0em 0.2em 0em" }}>
    <MedIcon icon={icon} color={colour} />
    <Spacer size={5} />
    <Alert style={{ color: colour, margin: "0px", padding: "0px" }}>{errorMessage}</Alert>
  </Row>
);

export { APIFormErrorFeedback, FormErrorFeedback, FormWarningFeedback };
