import { serverEnvironment } from "env";
import { useLogin } from "hooks";
import { useEffect } from "react";
import { StringMap } from "types";

export const useCrisp = () => {
  const { userInfo } = useLogin();

  // Map the server environments to the forest admin names
  const forestAdminEnvs: StringMap = {
    dev: "staging",
    stage: "staging",
    prerelease: "",
    production: "production",
  };

  const forestProfileLink = `https://app.forestadmin.com/PlayEd/${forestAdminEnvs[serverEnvironment]}/Operations/data/teacher/index/record/teacher/${userInfo?.id}/summary`;

  const onCrispChatboxClosed = () =>{
    if (window.$crisp) {    
      window.$crisp.push(['do', 'chat:hide']);
    }
  };

  useEffect(() => {
    window.$crisp = [];
    window.$crisp.push(["set", "user:email", [userInfo?.email, userInfo?.crispSignature]]);
    window.$crisp.push(["set", "user:nickname", [`${userInfo?.firstName} ${userInfo?.lastName}`]]);
    window.$crisp.push(["on", "chat:closed", onCrispChatboxClosed]);
    if (forestAdminEnvs[serverEnvironment]) {
      window.$crisp.push(["set", "session:data", [[["forest-admin-link", forestProfileLink]]]]);
    }
    window.CRISP_WEBSITE_ID = "82a98652-88a1-4347-935a-231e7bde38f3";
    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      (s as any).async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);

      window.$crisp.push(['do', 'chat:hide']);

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const toggleLiveChat = () => {
  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:show']);
    window.$crisp.push(["do", "chat:toggle"]);
  }
};
