import { FC } from "react";
import { useVersion } from "hooks";
import packageInfo from "../../package.json";
import styled, { CSSProperties } from "styled-components";
import { MediaConstants } from "utils/mediaConstants";

interface Props {
  style?: CSSProperties;
}

const VersionNumber: FC<Props> = ({ style }) => {
  const { version: apiVersion } = useVersion();

  return (
    <Container style={style}>
      f{packageInfo.version} : b{apiVersion}
    </Container>
  );
};

const Container = styled.p`
  font-size: 12px;
  @media ${MediaConstants.tablet} {
    font-size: 8px;
  }
`;

export { VersionNumber };
