import { ItemAlignment } from "components";

export const getItemAlignment = (index: number, total: number): ItemAlignment => {
  if (total <= 1) {
    return "none";
  }

  if (index === 0) {
    return "start";
  } else if (index === total - 1) {
    return "end";
  } else {
    return "middle";
  }
};
