import axios from "axios";
import rateLimit from "axios-rate-limit";
import { serverUrl } from "env";

const api = rateLimit(
  axios.create({
    baseURL: serverUrl,
    timeout: 30000,
  }),
  { maxRequests: 10, perMilliseconds: 1000 }
);

export { api };
