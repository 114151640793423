import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { StudentName } from "types";
import { ClassActions } from "./classListSlice";
import { createStudentsForClass as createStudentsForClassApi } from "api";

const dispatchCreateStudentsForClass = (
  { studentNames, classId }: { studentNames: StudentName[]; classId: string },
  actions: ClassActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const class_ = await createStudentsForClassApi({
      token,
      classId,
      studentNames,
    });
    dispatch(actions.addSingle({ classId: class_.id, class: class_ }));
  }, dispatch);

export { dispatchCreateStudentsForClass };
