import { Class, Grouping, Partition, Student } from "types";

export interface UnprocessedGrouping {
  id: string;
  name: string;
  partitions?: UnprocessedPartition[];
  partitionCount?: number;
  class?: Class;
}

interface UnprocessedPartition {
  id: string;
  name: string;
  order: number;
  students: Student[];
}

const processPartition = (partition: UnprocessedPartition): Partition => {
  const { id, name, order, students } = partition;

  return {
    id,
    name,
    order,
    students,
    isDeleted: false,
    isAdded: false,
    isUpdated: false,
  };
};

export const ProcessGrouping = (grouping: UnprocessedGrouping): Grouping => {
  const { id, name, partitions, partitionCount, class: class_ } = grouping;

  return {
    id,
    name,
    partitions: partitions?.map((partition) => processPartition(partition)),
    partitionCount: partitionCount || partitions?.length || 0,
    class: class_,
  };
};
