import { Mail } from "@styled-icons/material";
import { FormErrorFeedback } from "components";
import { emailValidator, useValidatableState, Validator } from "hooks";
import { FC } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputPrependIcon } from "./FormInputItems";

interface Props {
  setEmail: (email: string) => void;
  isInvalid?: boolean;
}

const validator: Validator = [emailValidator];

const EmailInput: FC<Props> = ({ setEmail, isInvalid }) => {
  const [emailInput, setEmailInput, emailError] = useValidatableState("", validator);

  const handleSetEmail = (value: string) => {
    setEmailInput(value);
    setEmail(value);
  };

  return (
    <>
      <InputGroup>
        <InputPrependIcon icon={Mail} />
        <Form.Control
          type="text"
          placeholder="Email"
          value={emailInput}
          onChange={(e) => handleSetEmail(e.target.value)}
          isInvalid={Boolean(emailError) || isInvalid}
        />
      </InputGroup>

      <FormErrorFeedback errorMessage={emailError} />
    </>
  );
};

export { EmailInput };
