import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { StudentActions, StudentSessionQuery } from "./studentSlice";
import { dispatchAsyncFunction } from "../sliceHelpers";
import { fetchSessionsForStudentReport } from "api";
import { keys } from "lodash";

export interface FetchStudentSessionsParams {
  studentId: string;
  queries: { [key: string]: StudentSessionQuery };
  currentQuery: string;
  pageSize: number;
  currentPage: number;
  requestOtherTotals: boolean;
}

const dispatchFetchSessionsForStudentReport = (
  { studentId, queries, currentQuery, pageSize, currentPage, requestOtherTotals }: FetchStudentSessionsParams,
  actions: StudentActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));
    if (currentPage === 0) {
      dispatch(actions.resetSessionsPage());
    }
    if (requestOtherTotals) {
      dispatch(actions.resetSessionsTotal());
    }

    let token = getState().login?.token || getState().studentLogin?.token;
    if (!token) {
      throw new Error("Not logged in");
    }

    const runQuery = async (queryId: string, token: string, currentPage: number) => {
      const query = queries[queryId];
      const { sessions, total, summary } = await fetchSessionsForStudentReport(
        {
          studentId,
          sessionType: query.sessionType,
          completionRequirement: query.completionRequirement,
          skip: pageSize * currentPage,
          take: pageSize,
          orderby: query.orderby,
          order: query.order,
        },
        token
      );

      dispatch(
        actions.mergeSessions({
          query: queryId,
          report: { sessions, total, summary },
          requestToken,
        })
      );
    };

    runQuery(currentQuery, token, currentPage);

    if (requestOtherTotals) {
      for (const queryKey of keys(queries)) {
        if (queryKey === currentQuery) continue;

        runQuery(queryKey, token, 0);
      }
    }
  }, dispatch);

export { dispatchFetchSessionsForStudentReport };
