import { useAppDispatch, useAppSelector } from "app/hooks";
import { useQueryParams } from "hooks";
import { useCallback, useEffect } from "react";
import { FC, StringMap } from "types";
import { createSetupIntent as createSetupIntentSlice } from "slices";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "utils";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router";
import { SubscriptionPaymentComplete } from "./SubscriptionPaymentComplete";
import { SubscriptionPaymentInfo } from "./SubscriptionPaymentInfo";
import { Center, LoginBackgroundContainer, OnboardingLocation } from "primitives";
import { colors } from "styles";
import { OnboardingElementContainer } from "pages";

interface StripeResponseParams extends StringMap {
  setup_intent_client_secret: string;
}

interface Props {
  selectedPriceId?: string;
  onPaymentComplete: () => void;
}

export const SubscriptionPayment: FC<Props> = ({ selectedPriceId, onPaymentComplete }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const {
    params: { setup_intent_client_secret },
  } = useQueryParams<StripeResponseParams>();

  const { clientSecret } = useAppSelector((state) => state.product);
  const stripeOptions = {
    clientSecret: clientSecret || setup_intent_client_secret,
  };
  const dispatch = useAppDispatch();

  const createSetupIntent = useCallback(async () => {
    dispatch(createSetupIntentSlice());
  }, [dispatch]);

  useEffect(() => {
    if (!setup_intent_client_secret) {
      createSetupIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSetupIntent]);

  const paymentComplete = () => {
    onPaymentComplete();
  };

  const tryPaymentAgain = () => {
    history.push(`${url}/details`);
  };

  if (stripeOptions?.clientSecret) {
    return (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <Switch>
          <Route path={`${url}/complete`}>
            <SubscriptionPaymentComplete
              clientSecret={setup_intent_client_secret}
              onContinuePressed={paymentComplete}
              onTryAgainPressed={tryPaymentAgain}
            />
          </Route>
          <Route path={`${url}/details`}>
            <SubscriptionPaymentInfo returnUrl={window.origin + url + `/complete`} selectedPriceId={selectedPriceId} />
          </Route>
          <Route path="/">
            <Redirect to={`${url}/details`} />
          </Route>
        </Switch>
      </Elements>
    );
  } else {
    return (
      <LoginBackgroundContainer backgroundColour={colors.brandPurple} location={OnboardingLocation.register}>
        <OnboardingElementContainer title="Loading">
          <Center>
            <p>One Moment!</p>
          </Center>
        </OnboardingElementContainer>
      </LoginBackgroundContainer>
    );
  }
};
