import { useAppDispatch, useAppSelector } from "app/hooks";
import { LoadingContent, PartitionSelector, useSubjectTagsSelector } from "components";
import dayjs from "dayjs";
import { useClassId, useQueryParams } from "hooks";
import { StudentOrdering, studentOrderings } from "pages/classes/classesOverview/studentOrderings";
import { Column, SimpleSelect } from "primitives";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  fetchClass,
  fetchClassList,
  fetchSessionList,
  fetchSessionListFull,
  resetSessionList,
  resetSessionListPageData,
} from "slices";
import styled from "styled-components";
import { DateRange, defaultRequestState, FC, Session, SessionType, Student, Tag } from "types";
import {
  addMonths,
  addWeeks,
  dispatchAsync,
  getEndOfToday,
  getFilteredSessionList,
  getStudentAgregateInfo,
} from "utils";
import { ReviewStudentGrid } from "./ReviewStudentGrid";
import { ReviewSessionsSidebar } from "./ReviewSessionsSidebar";
import { EmptyReviewClass } from "./ReviewClassEmptyContainers";
import { layoutConstants } from "styles";
import { MediaConstants } from "utils/mediaConstants";
import { Form } from "react-bootstrap";
import { slugs } from "textConstants";
import { LessonPlanPreviewModal } from "pages/my-games";

const getDateRangeForString = (rangeString?: string): DateRange => {
  if (rangeString === "month") {
    return {
      startDate: addMonths(new Date(), -1),
      endDate: getEndOfToday(),
    };
  } else if (rangeString === "week") {
    return {
      startDate: addWeeks(new Date(), -1),
      endDate: getEndOfToday(),
    };
  } else if (rangeString) {
    const [startString, endString] = rangeString.split("-");
    return {
      startDate: dayjs(startString, "YYYYMMDD").toDate(),
      endDate: dayjs(endString, "YYYYMMDD").endOf("day").toDate(),
    };
  }

  return {
    startDate: addMonths(new Date(), -1),
    endDate: getEndOfToday(),
  };
};

const getStringForDateRange = (range: DateRange): string | undefined => {
  const startDateString = dayjs(range.startDate).format("YYYYMMDD");
  const endDateString = dayjs(range.endDate).format("YYYYMMDD");

  const todayDateString = dayjs().format("YYYYMMDD");
  if (todayDateString === endDateString) {
    const monthDateString = dayjs(addMonths(new Date(), -1)).format("YYYYMMDD");
    if (monthDateString === startDateString) return undefined;

    const weekDateString = dayjs(addWeeks(new Date(), -1)).format("YYYYMMDD");
    if (weekDateString === startDateString) return "week";
  }

  return `${startDateString}-${endDateString}`;
};

const ReviewClass: FC = () => {
  const [{ loading: classLoading, error: classError, success: classSuccess }, setClassRequestState] = useState(
    defaultRequestState
  );
  const [{ loading: sessionLoading, error: sessionError, success: sessionSuccess }, setSessionRequestState] = useState(
    defaultRequestState
  );
  const [{ loading: focusLoading }, setFocusRequestState] = useState(defaultRequestState);

  const [classId] = useClassId(true);
  const classes = useAppSelector((state) => state.classList.data);
  const classList = useMemo(() => (classes ? Object.values(classes) : []), [classes]);
  const sessionList = useAppSelector((state) =>
    state.sessionList.data && state.sessionList.paginationData.orderedIds
      ? state.sessionList.paginationData.orderedIds
          .map((sessionId) => state.sessionList.data![sessionId])
          .filter((session) => Boolean(session))
      : []
  );
  const { search } = useLocation();
  const {
    params: { withSession, dateRange },
    updateParams,
  } = useQueryParams<{ withSession?: string; dateRange?: string }>();

  const [focusSessionId, setFocusSessionId] = useState(withSession);
  const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>(withSession ? [withSession] : []);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(getDateRangeForString(dateRange));
  const { selectedSubjectTags, setSelectedSubjectTags } = useSubjectTagsSelector({});
  const [canRedirectOnDateChange, setCanRedirectOnDateChange] = useState(false);

  const [sortedSessionList, setSortedSessionList] = useState<Session[]>([]);

  // needed so useEffect can check if the list has changed - arrays can't be added to a useEffect
  const stringifiedSessionList = JSON.stringify(sessionList);
  const filteredSessionList = getFilteredSessionList(sessionList, selectedSubjectTags);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { url, path, params } = useRouteMatch<{
    classId?: string;
  }>();

  const extendedStudentOrderings = [
    ...studentOrderings,
    {
      id: "Score (High > Low)",
      handleSort: (a: Student, b: Student) => {
        const aScore = getStudentAgregateInfo(a, sessionList);
        const bScore = getStudentAgregateInfo(b, sessionList);

        if (aScore.totalCorrect > bScore.totalCorrect) return -1;
        if (aScore.totalCorrect < bScore.totalCorrect) return 1;

        if (aScore.participationCount > bScore.participationCount) return -1;
        if (aScore.participationCount < bScore.participationCount) return 1;

        return 0;
      },
    },
    {
      id: "Score (Low > High)",
      handleSort: (a: Student, b: Student) => {
        const aScore = getStudentAgregateInfo(a, sessionList);
        const bScore = getStudentAgregateInfo(b, sessionList);

        if (aScore.totalCorrect > bScore.totalCorrect) return 1;
        if (aScore.totalCorrect < bScore.totalCorrect) return -1;

        if (aScore.participationCount > bScore.participationCount) return 1;
        if (aScore.participationCount < bScore.participationCount) return -1;

        return 0;
      },
    },
  ];

  const [studentOrdering, setStudentOrdering] = useState<StudentOrdering>();

  const handleCreateClassClicked = () => {
    history.push({ pathname: `/${slugs.teacher}/${slugs.class}`, search });
  };

  const handleClickStudent = (studentId: string) => {
    history.push({ pathname: `${url}/${slugs.student}/${studentId}`, search });
  };

  const reloadFilteredSessionList = useCallback(() => {
    let allSessionsCopy = [...sessionList];
    setSortedSessionList(
      allSessionsCopy.sort(function compare(s1, s2) {
        return dayjs(s1.startTime!) > dayjs(s2.startTime!) ? -1 : 1;
      })
    );
  }, [sessionList, setSortedSessionList]);

  const loadAllSessions = useCallback(async () => {
    if (classId && selectedDateRange && !sessionLoading) {
      dispatch(resetSessionList());
      dispatch(resetSessionListPageData());
      const startDate = dayjs(selectedDateRange.startDate).toISOString();
      const endDate = dayjs(selectedDateRange.endDate).toISOString();

      if (withSession) {
        await loadSelectedSession();
      } else {
        updateParams({ dateRange: getStringForDateRange(selectedDateRange) });
      }

      await dispatchAsync(
        dispatch(
          fetchSessionList({
            classId,
            sessionType: SessionType.Started,
            startDate: startDate,
            endDate: endDate,
          })
        ),
        setSessionRequestState
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, classId, selectedDateRange]);

  const loadSelectedSession = useCallback(async () => {
    if (withSession) {
      updateParams({ withSession: undefined });

      let fetchedSession = sessionList.find((session) => session.id === withSession);

      if (fetchedSession === undefined) {
        const { result: fetchedSessions } = await dispatchAsync(
          dispatch(fetchSessionListFull([withSession])),
          setFocusRequestState
        );
        if (fetchedSessions) {
          fetchedSession = fetchedSessions[0];
          const dateRange = {
            startDate: dayjs(fetchedSession.startTime!).toDate(),
            endDate: dayjs(fetchedSession.endTime!).toDate(),
          };
          setSelectedDateRange(dateRange);

          updateParams({
            withSession: undefined,
            dateRange: getStringForDateRange(dateRange),
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withSession]);

  useEffect(() => {
    if (withSession) {
      setSelectedSessionIds([withSession]);
      setFocusSessionId(withSession);
      loadSelectedSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withSession]);

  useEffect(() => {
    if (!focusSessionId) {
      setSelectedSessionIds(filteredSessionList.map((session) => session.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedSessionList, selectedSubjectTags]);

  useEffect(() => {
    dispatchAsync(dispatch(fetchClassList()), setClassRequestState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reloadFilteredSessionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedSessionList]);

  useEffect(() => {
    if (params.classId && canRedirectOnDateChange) {
      history.push({ search });
    }

    if (!canRedirectOnDateChange) {
      setCanRedirectOnDateChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    loadAllSessions();
    if (classId) {
      dispatch(fetchClass(classId));
    }
  }, [classId, loadAllSessions, dispatch]);

  const onSessionPressed = (sessionId: string) => {
    if (selectedSessionIds.includes(sessionId)) {
      setSelectedSessionIds(
        selectedSessionIds.filter((_sessionID) => {
          return _sessionID !== sessionId;
        })
      );
    } else {
      setSelectedSessionIds([...selectedSessionIds, sessionId]);
    }
  };

  const onAllSessionsPressed = (selectAll: boolean) => {
    if (selectAll) {
      setSelectedSessionIds(sessionList.map((session) => session.id));
    } else {
      setSelectedSessionIds([]);
    }
  };

  const loading = sessionLoading || (Boolean(focusSessionId) && focusLoading);

  const mainContent = (
    <OuterContainer>
      <ReviewSessionsSidebar
        sessionLoading={loading}
        selectedDateRange={selectedDateRange}
        setDateChanged={(newDateRange: DateRange) => {
          setSelectedDateRange(newDateRange);
        }}
        selectedSubjectTags={selectedSubjectTags}
        setSelectedSubjectTags={(selectedTags: { tag: Tag; selected: boolean }[]) => {
          setSelectedSubjectTags(selectedTags);
        }}
        sessions={sortedSessionList}
        selectedSessionIds={selectedSessionIds}
        onSessionPressed={onSessionPressed}
        onAllSessionsPressed={onAllSessionsPressed}
        selectedClass={classes && classId ? classes[classId] : undefined}
        focusedSessionId={focusSessionId}
      />
      <LoadingContent loading={loading} error={sessionError} success={sessionSuccess}>
        {!loading && (
          <MainContentContainer>
            <FilterContainer className="p-3">
              <Filter>
                <Form.Label>Sort By:</Form.Label>
                <SimpleSelect
                  options={extendedStudentOrderings}
                  getName={(ordering) => ordering?.id}
                  onChange={setStudentOrdering}
                  value={studentOrdering?.id || ""}
                />
              </Filter>
              <Filter>
                <Form.Label>Partition:</Form.Label>
                <PartitionSelector />
              </Filter>
            </FilterContainer>
            <ReviewStudentGrid
              sessionIds={selectedSessionIds}
              sortStudents={studentOrdering?.handleSort}
              onClickStudent={handleClickStudent}
            />
          </MainContentContainer>
        )}
      </LoadingContent>
      <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.preview}`}>
        <LessonPlanPreviewModal onHidden={() => history.push(url)} />
      </Route>
    </OuterContainer>
  );

  return (
    <LoadingContent loading={classLoading} error={classError} success={classSuccess}>
      {!classLoading && classList.length === 0 ? (
        <EmptyReviewClass handleCreateClassClicked={handleCreateClassClicked} />
      ) : (
        <>{mainContent}</>
      )}
    </LoadingContent>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
`;

const MainContentContainer = styled(Column)`
  min-width: ${layoutConstants.questionSets.detailsWidth};
  @media ${MediaConstants.laptopL} {
    min-width: ${layoutConstants.questionSets.smallDetailsWidth};
  }
  max-width: 100%;
  flex: 1;
`;

const FilterContainer = styled(Form)`
  display: flex;
  flex-direction: row;
  gap: 2em;
`;

const Filter = styled(Form.Group)`
  max-width: 20em;
`;

export { ReviewClass };
