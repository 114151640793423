import { useQueryParams, useSubjectTags } from "hooks";
import { useState, useEffect, useRef } from "react";
import { QuestionSet, Tag } from "types";
import { SubjectTagsSelector } from "./SubjectTagsSelector";

interface UseSubjectTagSelectorParams {
  questionSet?: QuestionSet;
  onChange?: (subjectTags: Array<Tag>) => void;
}

const useSubjectTagsSelector = ({ questionSet, onChange }: UseSubjectTagSelectorParams) => {
  const didMount = useRef(false);
  const { data: subjectTags, success: subjectTagsSuccess } = useSubjectTags({
    fetch: true,
  });
  const [selectedSubjectTags, setSelectedSubjectTags] = useState<Array<{ tag: Tag; selected: boolean }>>([]);

  const {
    params: { subjectTags: querySubjectTags },
  } = useQueryParams<{ subjectTags: string }>();

  // Temp - resets buttons if query string has no tags (undefined)
  // TODO: Make this actually update to the selected tags, not clear tags
  useEffect(() => {
    if (subjectTags && !querySubjectTags) {
      setSelectedSubjectTags(
        subjectTags.map((tag) => ({
          tag,
          selected: false,
        }))
      );
    }
  }, [querySubjectTags, subjectTags]);

  const [mode, setMode] = useState<"dropdown" | "search">("dropdown");
  const toggleMode = () => setMode(mode === "dropdown" ? "search" : "dropdown");

  useEffect(() => {
    if (subjectTags && subjectTagsSuccess && !selectedSubjectTags.length) {
      setSelectedSubjectTags(
        subjectTags.map((tag) => ({
          tag,
          selected: questionSet ? Boolean(questionSet.tags.find((t) => t.id === tag.id)) : false,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectTags, subjectTagsSuccess]);

  const subjectTagsSelectorProps = {
    subjectTags: selectedSubjectTags,
    setSubjectTags: setSelectedSubjectTags,
    mode: mode,
    toggleMode: toggleMode,
  };

  const filteredSubjectTags = selectedSubjectTags.filter(({ selected }) => selected).map(({ tag }) => tag);
  const selectedSubjectTagIds = filteredSubjectTags.map((tag) => tag.id);

  const setSelectedSubjectTagIds = (tagIds: Array<string>) => {
    setSelectedSubjectTags(
      selectedSubjectTags.map(({ tag }) => ({
        tag,
        selected: tagIds.includes(tag.id),
      }))
    );
  };

  useEffect(
    () => {
      if (didMount.current) {
        onChange && onChange(filteredSubjectTags);
      } else {
        didMount.current = true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSubjectTagIds.length]
  );

  return {
    allSubjectTags: subjectTags,
    SubjectTagsSelector,
    subjectTagsSelectorProps,
    selectedSubjectTagIds,
    selectedSubjectTags,
    setSelectedSubjectTags,
    setSelectedSubjectTagIds,
  };
};

export { useSubjectTagsSelector };
