import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { StudentActions } from "./studentSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { deleteStudent } from "api";

const dispatchDeleteStudent = (studentId: string, actions: StudentActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const deletedStudent = await deleteStudent({ token, studentId });

    dispatch(actions.deleteStudent(deletedStudent));
    dispatch(actions.reset());
  }, dispatch);

export { dispatchDeleteStudent };
