import { Form } from "react-bootstrap";
import { Tag } from "types";
import React, { FC } from "react";
import { Row, Spacer } from "primitives";

export interface DropdownParams {
  tagList: Tag[];
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: Tag | null;
}

interface Props {
  dropdownList: Array<DropdownParams>;
}

const SubjectTagsDropDowns: FC<Props> = ({ dropdownList }) => (
  <Row style={{ alignItems: "center", justifyContent: "center" }}>
    {dropdownList.map(({ tagList, placeholder, onChange, selected }, index) => (
      <Row key={index}>
        <Form.Group style={{ margin: 0 }}>
          <Form.Control
            style={{ width: 180 }}
            disabled={!tagList.length}
            as="select"
            onChange={onChange}
            value={selected?.name || placeholder}
          >
            <option>{placeholder}</option>
            {tagList.map((tag) => (
              <option key={tag.id}>{tag.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        {index !== dropdownList.length - 1 ? <Spacer size={10} /> : null}
      </Row>
    ))}
  </Row>
);

export { SubjectTagsDropDowns };
