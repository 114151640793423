import { TTSOverridePair } from "types";
import { TTSExampleQuestion } from "pages/customGames/CustomGameTTSOverideEditor";
import { MultichoiceQuestion, QuestionSet, PronunciationSet } from "types";

const defaultMathTTSOverrides: TTSOverridePair[] = [
  { input: " + ", output: " plus " },
  { input: " - ", output: " take away " },
  { input: " / ", output: " divided by " },
  { input: " ÷ ", output: " divided by " },
  { input: " * ", output: " times " },
  { input: " x ", output: " times " },
];

const defaultPunctuationTTSOverrides: TTSOverridePair[] = [
  { input: ".", output: " full stop " },
  { input: ", ", output: " comma" },
  { input: "!", output: " exclamation mark " },
  { input: "?", output: " question mark " },
  { input: "'", output: " apostrophe " },
  { input: ":", output: " colon " },
  { input: ";", output: " semicolon " },
  { input: '"', output: " speech mark " },
];

export const defaultTTSOverrideSets = [defaultMathTTSOverrides, defaultPunctuationTTSOverrides];

const TTSExampleQuestions: TTSExampleQuestion[] = [
  { input: " + ", questionText: "2 + 4 =", answers: "2 | 4  | 6 | 8" },
  { input: " - ", questionText: "4 - 2 =", answers: "2 | 4  | 6 | 8" },
  { input: " x ", questionText: "2 x 4 =", answers: "2 | 4  | 6 | 8" },
  { input: " / ", questionText: "4 / 2 =", answers: "2 | 4  | 6 | 8" },
  { input: " = ", questionText: "4 / 2 =", answers: "2 | 4  | 6 | 8" },
  { input: " No. ", questionText: "Pick the largest No.", answers: "2  |  4  |  6  |  8" },
  { input: ".", questionText: "How do you end a sentence?", answers: ". | , | ? | !" },
  { input: ", ", questionText: "How do you end a sentence?", answers: ". | , | ? | !" },
  { input: "?", questionText: "How do you end a question", answers: ". | , | ? | !" },
  { input: "!", questionText: "How do you end a sentence?", answers: ". | , | ? | !" },
  { input: "'", questionText: "How do you start a list?", answers: "' | : | ; | \"" },
  { input: ":", questionText: "How do you start a list?", answers: "' | : | ; | \"" },
  { input: ";", questionText: "How do you start a list?", answers: "' | : | ; | \"" },
  { input: '"', questionText: "How do you start a list?", answers: "' | : | ; | \"" },
];

export const defaultPronunciationSets: PronunciationSet[] = [
  {
    id: "default",
    name: "Default Maths Operators",
    ttsOverrides: JSON.stringify(defaultMathTTSOverrides),
    ttsOverridePairs: defaultMathTTSOverrides,
    loadByDefault: true,
    defaultSet: true,
  },
  {
    id: "default_punct",
    name: "Default Punctuation",
    ttsOverrides: JSON.stringify(defaultPunctuationTTSOverrides),
    ttsOverridePairs: defaultPunctuationTTSOverrides,
    loadByDefault: true,
    defaultSet: true,
  },
];

export const getExampleTTSOverrideQuestion = (questions: QuestionSet[], override: TTSOverridePair | null) => {
  let returnQuestion: TTSExampleQuestion | undefined;
  if (override?.input) {
    if (override) {
      for (const questionSet of questions) {
        if (questionSet.questions) {
          for (const question of questionSet.questions) {
            // Check the question
            if (question.type === "multichoice") {
              const multichoiceQuestion = question as MultichoiceQuestion;
              if (multichoiceQuestion.question.includes(override.input)) {
                let answerString = "";
                for (const answer of multichoiceQuestion.answers) {
                  answerString += `${answer} | `;
                }
                returnQuestion = {
                  input: override.input,
                  questionText: multichoiceQuestion.question,
                  answers: answerString,
                };
              }
            }
          }
        }
      }

      if (!returnQuestion) {
        //Use example ones
        TTSExampleQuestions.forEach((question) => {
          if (question.input === override.input) {
            returnQuestion = question;
          }
        });
      }
    }
  }
  return returnQuestion;
};
