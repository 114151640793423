import { uniq } from "lodash";
import { AnswerTuple, MultichoiceQuestion } from "types";
import { replaceOneElementInArray } from "utils";

const getRandomCorrectAnswers = (
  referenceQuestions: MultichoiceQuestion[],
  answers: AnswerTuple,
  removeDuplicates: boolean
) => {
  // Get all correct answers
  let otherCorrectAnswers = referenceQuestions
    .filter((question) => Boolean(question.answers[question.correctAnswerIndex]))
    .map((question) => question.answers[question.correctAnswerIndex]!);

  // Ensure there are no duplicates
  otherCorrectAnswers = uniq(otherCorrectAnswers);

  let currentQuestionAnswers = [...answers];

  //remove answers already there
  if (removeDuplicates) {
    if (otherCorrectAnswers.length) {
      for (let answer of currentQuestionAnswers) {
        if (answer) {
          const index = otherCorrectAnswers.indexOf(answer);

          if (index >= 0) {
            otherCorrectAnswers.splice(index, 1);
          }
        }
      }
    }
  }

  return otherCorrectAnswers;
};

const generateDistractors = (
  referenceQuestions: MultichoiceQuestion[],
  answers: AnswerTuple,
  distractorIndex: number
): AnswerTuple | null => {
  const otherCorrectAnswers = getRandomCorrectAnswers(referenceQuestions, answers, true);

  // Randomly generate distractor
  if (otherCorrectAnswers?.length) {
    const index = Math.floor(Math.random() * otherCorrectAnswers.length);
    const newAnswers = replaceOneElementInArray(answers, distractorIndex, otherCorrectAnswers[index]);
    return newAnswers;
  } else {
    console.error("Not enough correct answers to generate distractors.");
    return null;
  }
};

const generateDistractorsForAllAnswers = (
  referenceQuestions: MultichoiceQuestion[],
  targetQuestion: MultichoiceQuestion,
  answers: AnswerTuple
): AnswerTuple => {
  const otherCorrectAnswers = getRandomCorrectAnswers(referenceQuestions, answers, false);
  let newAnswers: AnswerTuple = [];

  //remove just the answer from otherCorrectAnswers
  const correctAnswer = answers[targetQuestion.correctAnswerIndex];
  if (correctAnswer !== null) {
    const index = otherCorrectAnswers.indexOf(correctAnswer);
    if (index >= 0) {
      otherCorrectAnswers.splice(index, 1);
    }
  }

  // Randomly generate distractors
  for (let i = 0; i < answers.length; i++) {
    if (otherCorrectAnswers?.length && i !== targetQuestion.correctAnswerIndex) {
      const otherCorrectAnswerIndex = Math.floor(Math.random() * otherCorrectAnswers.length);
      newAnswers.push(otherCorrectAnswers[otherCorrectAnswerIndex]);
      otherCorrectAnswers.splice(otherCorrectAnswerIndex, 1);
    } else {
      newAnswers.push(answers[i]);
    }
  }
  return newAnswers;
};

export { getRandomCorrectAnswers, generateDistractors, generateDistractorsForAllAnswers };
