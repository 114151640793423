import { AxiosError } from "axios";
import { ApiError } from "types";

const getErrorFromResponse = (error: AxiosError) => {
  if (error.response?.status === 400) return ApiError.badRequest;
  if (error.response?.status === 401) return ApiError.authError;
  if (error.response?.status === 500) return ApiError.serverError;
  if (error.response?.status === 404) return ApiError.notFoundError;
  return ApiError.networkError;
};

export { getErrorFromResponse };
