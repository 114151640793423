import { toggleLiveChat, useLogin } from "hooks";
import { NavbarItemProperties } from "../navbar";
import { FC } from "types";
import { Header } from "./Header";
import { colors } from "styles";
import { useHistory } from "react-router";
import { NavbarDropdownItemProperties, NavbarTabItemDropdown } from "components/navbar/NavbarTab";
import { useLocation } from "react-router-dom";
import { breadcrumbConstants, PlatformTitle } from "textConstants";
import { VersionNumber } from "components/VersionNumber";
import styled from "styled-components";
import { Row, Spacer } from "primitives";
import { LargeIconButton } from "primitives/button/IconButton";
import { newsItemConstants } from "textConstants";
import { LargeIcon } from "primitives/icons";
import { ChatIcon } from "icons";

interface Props {
  navbarItems: Array<NavbarItemProperties>;
  onWhatsNewClicked: () => void;
  whatsNewNewsItemCount?: number;
}

const settingsLocation = "/teacher/settings";
const academyLocation = "/teacher/academy";

interface TeacherProfileElementProps {
  onWhatsNewClicked: () => void;
  whatsNewNewsItemCount?: number;
}

const TeacherProfileElement: FC<TeacherProfileElementProps> = ({ onWhatsNewClicked, whatsNewNewsItemCount }) => {
  const { logout } = useLogin();
  const history = useHistory();

  const loadSettings = () => {
    history.push(settingsLocation);
  };

  const loadAcademy = () => {
    history.push(academyLocation);
  };

  const { userInfo, isAdmin } = useLogin();
  const { firstName, lastName } = userInfo!;
  const location = useLocation();
  const isInSettings = location.pathname.startsWith(settingsLocation);

  let nameText = `${firstName} ${lastName}`;
  if (isAdmin()) {
    nameText += " (Admin)";
  }

  const accountSettingsDropdownItem: NavbarDropdownItemProperties = {
    name: "Account Settings",
    onClick: loadSettings,
    route: breadcrumbConstants.settings.route,
  };

  const AcademyDropdownItem: NavbarDropdownItemProperties = {
    name: `${PlatformTitle} Academy`,
    onClick: loadAcademy,
    route: breadcrumbConstants.academy.route,
  };

  const WhatsNewDropdownItem: NavbarDropdownItemProperties = {
    name: "What's New",
    onClick: onWhatsNewClicked,
    additionalContent: (
      <Row className="justify-content-center">
        <LargeIcon color={`${colors.danger}`} icon={newsItemConstants.emptyIcon} />
        <CenteredAbsoluteText>{whatsNewNewsItemCount}</CenteredAbsoluteText>
      </Row>
    ),
  };

  const LogoutDropdownItem: NavbarDropdownItemProperties = {
    name: "Log Out",
    onClick: logout,
  };

  let navBarItems: NavbarDropdownItemProperties[] = [
    accountSettingsDropdownItem,
    AcademyDropdownItem,
    LogoutDropdownItem,
  ];

  if (Boolean(whatsNewNewsItemCount)) {
    navBarItems.splice(1, 0, WhatsNewDropdownItem);
  }

  return (
    <>
      <NavbarTabItemDropdown
        tabName={nameText}
        route={"teacher"}
        icon={breadcrumbConstants.settings.icon}
        accentColor={breadcrumbConstants.settings.accentColor}
        highlightColor={breadcrumbConstants.settings.highlightColor}
        hoverColor={colors.darkText}
        dropdownItems={navBarItems}
        active={isInSettings}
        notifIcon={whatsNewNewsItemCount ? newsItemConstants.emptyIcon : undefined}
        notifText={`${whatsNewNewsItemCount}`}
      >
        <VersionContainer>
          <VersionNumber style={{ width: "100%", textAlign: "center" }} />
        </VersionContainer>
      </NavbarTabItemDropdown>
    </>
  );
};

const TeacherHeader: FC<Props> = ({ navbarItems, onWhatsNewClicked, whatsNewNewsItemCount }) => {
  const onCrispButtonPressed = () => {
    toggleLiveChat();
  };

  return (
    <Header
      navbarItems={navbarItems}
      profileElement={
        <TeacherProfileElement onWhatsNewClicked={onWhatsNewClicked} whatsNewNewsItemCount={whatsNewNewsItemCount} />
      }
    >
      <Spacer size={10} />
      <ButtonContainer>
        <DarkLargeButton
          icon={ChatIcon}
          placement={"bottom"}
          tooltip={"Live Help Chat"}
          onClick={onCrispButtonPressed}
          variant={"light"}
        />
      </ButtonContainer>
    </Header>
  );
};

const VersionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.lightActive};
  opacity: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DarkLargeButton = styled(LargeIconButton)`
  flexshrink: 0;
  padding: 5px;
  border: 0px;
  background-color: ${colors.darkText};
  border-color: ${colors.darkText};
`;

const CenteredAbsoluteText = styled.p`
  position: absolute;
  padding-top: 0.2em;
`;

export { TeacherHeader };
