import { AnswerTuple, FreetextQuestion, MultichoiceQuestion, Question, QuestionType } from "types";

export interface UnprocessedQuestion {
  id: string;
  order: number;
  type: QuestionType;
}

interface UnprocessedMultichoiceQuestion extends UnprocessedQuestion {
  data: {
    question: string;
    questionImage: string | null;
    answers: AnswerTuple;
    answerImages: AnswerTuple | null;
    correctAnswerId: number;
  };
}

interface UnprocessedFreetextQuestion extends UnprocessedQuestion {
  data: {
    question: string;
    questionImage: string | null;
    answers: string[];
  };
}

export const ProcessQuestion = (question: UnprocessedQuestion): Question => {
  const { id, order, type } = question;

  if (type === "freetext") {
    const { data } = question as UnprocessedFreetextQuestion;

    const freetextQuestion: FreetextQuestion = {
      id,
      order,
      type,
      question: data.question,
      questionImage: data.questionImage || null,
      answers: data.answers,
      toBeUpdated: false,
      toBeDeleted: false,
      toBeCreated: false,
      displayAsUnsaved: false,
    };

    return freetextQuestion;
  } else {
    const { data } = question as UnprocessedMultichoiceQuestion;

    // Some really old question sets have their answers as an object
    const answers = Array.isArray(data.answers)
      ? data.answers.map((answer: string | null) => (answer ? String(answer) : ""))
      : Object.values<string>(data.answers);

    const multichoiceQuestion: MultichoiceQuestion = {
      id,
      order,
      type,
      question: data.question,
      questionImage: data.questionImage || null,
      answers: setTupleToFullLength(answers),
      answerImages: data.answerImages ? setTupleToFullLength(data.answerImages) : [null, null, null, null],
      correctAnswerIndex: data.correctAnswerId,
      toBeUpdated: false,
      toBeDeleted: false,
      toBeCreated: false,
      displayAsUnsaved: false,
    };

    return multichoiceQuestion;
  }
};

const setTupleToFullLength = (tuple: AnswerTuple) => {
  const AnswerTupleLength = 4;
  let fullLengthTuple = tuple;
  if (tuple.length < AnswerTupleLength) {
    fullLengthTuple = tuple.concat([null, null, null, null].slice(0, AnswerTupleLength - tuple.length));
  }

  return fullLengthTuple;
};
