import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getStudentTokenFromState } from "../sliceHelpers";
import { fetchSessionStudent } from "api";

export interface FetchSessionParams {
  sessionId: string;
}

const dispatchFetchSessionForStudent = ({ sessionId }: FetchSessionParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));
    const { token } = getStudentTokenFromState(getState);

    const session = await fetchSessionStudent({ sessionId }, token);

    dispatch(actions.addSingle({ id: session.id, session }));
    return session;
  }, dispatch);

export { dispatchFetchSessionForStudent };
