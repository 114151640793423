import { FC } from "types";
import mascotImage1 from "assets/mascot.png";
import mascotImage2 from "assets/mascot2.png";
import styled from "styled-components";

const Mascot1: FC = ({ style }) => <StyledMascot1 style={style} />;

const Mascot2: FC = ({ style }) => <StyledMascot2 style={style} />;

export { Mascot1, Mascot2 };

const Mascot = styled.div`
  min-width: 235px;
  min-height: 290px;
  background-position: center;
  background-size: cover;
`;

const StyledMascot1 = styled(Mascot)`
  background-image: url(${mascotImage1});
`;

const StyledMascot2 = styled(Mascot)`
  background-image: url(${mascotImage2});
`;
