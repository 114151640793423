import { DescriptionText, ErrorText, LoadingContent, ResetPasswordInputGroup } from "components";
import { useLogin, useQueryParams } from "hooks";
import { PrimaryButton } from "primitives/button";
import { Center, Column, Row, Spacer } from "primitives";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { generatePath, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { StringMap } from "types";
import { paths } from "textConstants";

interface QueryParams extends StringMap {
  postLoginDestination: string;
}

export const ResetPasswordElement = () => {
  const history = useHistory();
  const { params: queryParams } = useQueryParams<QueryParams>();
  const { token: loginToken } = useAppSelector((state) => state.login);
  const { logout, resetPassword, error, loading } = useLogin();

  const [hasResetPassword, setHasResetPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  if (!queryParams.token || !queryParams.email) {
    //query params aren't attached - cannot reset password without these, redirect to base teacher page
    history.push(paths.login.teacher.path);
  }

  useEffect(() => {
    if (loginToken) {
      const logoutDestination = generatePath("/login/teacher/resetPassword");
      logout(logoutDestination, queryParams);
    }
  }, [loginToken, logout, queryParams]);

  const handleApply = () => {
    if (isPasswordValid) {
      resetPassword(queryParams.token, queryParams.email, password);
      setHasResetPassword(true);
    }
  };

  const handleBackToLogin = () => {
    history.push(paths.login.teacher.path);
  };

  const ResetPasswordCompleteElement = () => (
    <>
      <Column>
        <Center>
          {error ? (
            <ErrorText>There was an error resetting your password. Please try again.</ErrorText>
          ) : (
            <DescriptionText>
              Your password has successfully been changed. Return to the login screen and enter your new password to log
              in.
            </DescriptionText>
          )}
        </Center>
      </Column>
      <Spacer size={30} />
      <Center>
        <PrimaryButton onClick={handleBackToLogin}>Back to Log In</PrimaryButton>
      </Center>
    </>
  );

  return (
    <LoadingContent loading={loading}>
      {hasResetPassword ? (
        <ResetPasswordCompleteElement />
      ) : (
        <>
          <Item>
            <Form>
              <Center>
                <DescriptionText>Type the new password for {queryParams.email}</DescriptionText>
              </Center>
              <Spacer />
              <ResetPasswordInputGroup
                basePlaceholder={"New Password"}
                setPassword={setPassword}
                setIsValid={setIsPasswordValid}
              />
            </Form>
          </Item>
          <Spacer size={50} />
          <Row>
            <Center>
              <PrimaryButton disabled={!isPasswordValid} onClick={handleApply}>
                Confirm
              </PrimaryButton>
            </Center>
          </Row>
          <Spacer size={20} />
          <Center>
            <Link to="/login/teacher">Return to Log In</Link>
          </Center>
        </>
      )}
    </LoadingContent>
  );
};

const Item = styled.div`
  width: 100%;
`;
