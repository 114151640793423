import { Button, Column, HorizontalLine, Row, Spacer } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Price } from "types";
import { getBillingCycle, getBillPrice, getMonthlyPrice, getPriceHeader } from "utils";

interface Props {
  price: Price;
  popular: boolean;
  imagePath: string;
  canTrial: boolean;
  percentOff?: number;
  isActive: boolean; // Are they already using this subscription?
  onSubscriptionSelected: (priceId: string) => void;
}

export const MobileSubscriptionProductCard: FC<Props> = ({
  price,
  imagePath,
  canTrial,
  percentOff,
  isActive,
  onSubscriptionSelected,
}) => {
  return (
    <OuterContainer $isActive={isActive}>
      <MobileInnerContainer>
        <MobileProductImage src={imagePath} />
        <Spacer size={10} />
        <Column>
          <p>
            <b>{getPriceHeader(price)} Membership</b>
          </p>
          <Row>
            <DiscountPrice color={Boolean(percentOff) ? colors.success : colors.darkText}>
              ${getMonthlyPrice(price, percentOff)}
            </DiscountPrice>
            <BottomAlignText>/per mo</BottomAlignText>
          </Row>
        </Column>
      </MobileInnerContainer>
      <HorizontalLine margin="5px" />

      <Column>
        <small>
          Billed at ${getBillPrice(price, percentOff)} every {getBillingCycle(price)}.
        </small>
        <small>Cancel anytime.</small>
      </Column>
      <MobileSelectButton>
        <Button onClick={() => onSubscriptionSelected(price.id)} disabled={isActive}>
          {isActive ? "Current Subscription" : canTrial ? "Start Free-Trial" : "Start Subscription"}
        </Button>
      </MobileSelectButton>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)<{ $isActive: boolean }>`
  border: 1px solid ${(props) => (props.$isActive ? colors.success : colors.darkText)};
  background-color: white;
  width: 100%;
  color: ${colors.darkText};
  padding: 5px 15px;
  box-shadow: -5px 5px ${colors.darkText};
`;

const MobileInnerContainer = styled(Row)`
  align-items: center;
`;

const MobileProductImage = styled.img`
  border-radius: 50%;
  background-color: ${colors.transparentPrimary};
  width: 55px;
  height: 55px;
  object-fit: cover;
  margin: 10px;
`;

const MobileSelectButton = styled.div`
  margin: 10px auto;
`;

const BottomAlignText = styled.p`
  margin-top: auto;
`;

const DiscountPrice = styled.h3<{ color: string }>`
  color: ${(props) => props.color};
`;
