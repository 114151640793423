import { Grouping } from "types";
import { api } from "api/api";
import { NetworkError } from "api/networkError";

interface DeleteGroupingParams {
  token: string;
  groupingId: string;
}

export const deleteGrouping = async ({ token, groupingId }: DeleteGroupingParams): Promise<Grouping> => {
  const response = await api.delete(`/groupings/${groupingId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const grouping = response.data;

  if (grouping) {
    return grouping;
  } else {
    throw new NetworkError();
  }
};
