import { Student } from "types";

export type StudentOrdering = {
  id: string;
  handleSort: (a: Student, b: Student) => -1 | 0 | 1;
};

export const studentOrderings: StudentOrdering[] = [
  {
    id: "Last name, descending",
    handleSort: (a: Student, b: Student) => {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;

      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;

      return 0;
    },
  },
  {
    id: "Last name, ascending",
    handleSort: (a: Student, b: Student) => {
      if (a.lastName > b.lastName) return -1;
      if (a.lastName < b.lastName) return 1;

      if (a.firstName > b.firstName) return -1;
      if (a.firstName < b.firstName) return 1;

      return 0;
    },
  },
  {
    id: "First name, descending",
    handleSort: (a: Student, b: Student) => {
      if (a.firstName < b.firstName) return -1;
      if (a.firstName > b.firstName) return 1;

      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;

      return 0;
    },
  },
  {
    id: "First name, ascending",
    handleSort: (a: Student, b: Student) => {
      if (a.firstName > b.firstName) return -1;
      if (a.firstName < b.firstName) return 1;

      if (a.lastName > b.lastName) return -1;
      if (a.lastName < b.lastName) return 1;

      return 0;
    },
  },
];
