import { CSSProperties } from "react";
import { FC } from "types";
import styled from "styled-components";

interface Props {
  style?: CSSProperties;
}

const Column: FC<Props> = ({ children, style, ...rest }) => (
  <StyledColumn
    style={{
      ...style,
    }}
    {...rest}
  >
    {children}
  </StyledColumn>
);

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
`;


export { Column };
