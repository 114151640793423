import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Promo } from "types";

export const getValidCoupon = async (token: string, promoCode: string) => {
  const response = await api.get(`/payments/coupon?promoCode=${promoCode}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data) {
    return response.data as Promo;
  } else {
    throw new NetworkError();
  }
};
