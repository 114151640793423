import { useAppDispatch, useAppSelector } from "app/hooks";
import { Column, Row, Spinner } from "primitives";
import { Button } from "primitives/button";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { getSubscriptionInfo as getSubscriptionSlice, deleteSubscription } from "slices";
import dayjs from "dayjs";
import { useConfirmModal, useLogin } from "hooks";
import { AsyncRequestState, defaultRequestState, SubscriptionInfo } from "types";
import { useHistory } from "react-router";
import { dateWithoutTime, dispatchAsync, getBillPrice, hasActiveSubscription, hasValidSubscription } from "utils";
import { colors } from "styles";
import { helpMessages } from "textConstants";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatStatus = (status: string) => {
  const parts = status.split("_").map((part) => capitalizeFirstLetter(part));
  return parts.join(" ");
};

export const MembershipSettings = () => {
  const { info: subscriptionInfo } = useAppSelector((state) => state.subscription);
  const dispatch = useAppDispatch();
  const { userInfo, refreshTeacherData } = useLogin();
  const history = useHistory();
  const [{ loading, error, success }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const getSubscriptionInfo = useCallback(async () => {
    dispatchAsync(dispatch(getSubscriptionSlice()), setRequestState);
  }, [dispatch]);

  useEffect(() => {
    refreshTeacherData();
    getSubscriptionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubscriptionInfo]);

  useEffect(() => {
    if (!userInfo?.subscriptionInfo) {
      refreshTeacherData();
    }
  }, [userInfo, refreshTeacherData]);

  const cancelSubscription = async () => {
    const endDate = userInfo!.subscriptionInfo.subscriptionPeriodEnd!;
    setModalInfo({
      body: [helpMessages.subscriptions.cancel(dateWithoutTime(endDate))],
      confirmButtonVariant: "danger",
      cancelTitle: "Don't Cancel",
      confirmTitle: "Cancel Subscription",
      onModalConfirm: async () => {
        setRequestState({ loading: true, error, success });
        await dispatch(deleteSubscription());
        history.go(0);
      },
    });
    setModalVisible(true);
  };

  const managedSubscription = (status: string, subscriptionEnd?: string | null) => {
    let statusText = formatStatus(status);
    if (subscriptionEnd) {
      statusText += `- You will continue to have access until ${dateWithoutTime(subscriptionEnd)}`;
    }
    return (
      <>
        <Section>
          <Header>Current Plan</Header>
          <TextField>{statusText}</TextField>
        </Section>
      </>
    );
  };

  const activeSubscription = () => {
    if (userInfo?.subscriptionInfo.subscriptionStatus === "managed") {
      return managedSubscription(
        userInfo?.subscriptionInfo.subscriptionStatus,
        userInfo?.subscriptionInfo.subscriptionPeriodEnd
      );
    } else {
      if (subscriptionInfo) {
        return (
          <>
            <Section>
              <Header>Current Plan</Header>
              <TextField>
                {subscriptionInfo.subscription.price.paymentIntervalCount}{" "}
                {subscriptionInfo.subscription.price.paymentInterval}
              </TextField>
            </Section>
            <Section>
              <ButtonGroup>
                <Button href={`subscribe/update`}>Change Plan</Button>
                <Button onClick={cancelSubscription}>Cancel Subscription</Button>
              </ButtonGroup>
            </Section>
            <Section>
              <Header>Next Billing Date</Header>
              <TextField>{dateWithoutTime(dayjs(subscriptionInfo.subscription.currentPeriodEnd * 1000))}</TextField>
            </Section>
            <Section>
              <Header>Next Bill Amount</Header>
              <TextField>
                ${getBillPrice(subscriptionInfo.subscription.price, subscriptionInfo.subscription.promo?.percentOff)}{" "}
                {subscriptionInfo.subscription.promo && `(${subscriptionInfo.subscription.promo.percentOff}% off)`}
              </TextField>
            </Section>
            <Modal />
          </>
        );
      } else {
        return <Spinner />;
      }
    }
  };

  const inactiveSubscriptionElement = (status: string) => (
    <>
      <Section>
        <Header>Current Plan</Header>
        <TextField>{formatStatus(status)}</TextField>
      </Section>
      <NoExpand>
        <Button href={`/subscribe`}>Select New Plan</Button>
      </NoExpand>
    </>
  );

  const inactiveSubscription = () => {
    if (userInfo?.subscriptionInfo?.subscriptionPeriodEnd) {
      if (hasValidSubscription(userInfo)) {
        const subscriptionEnd = dayjs(userInfo.subscriptionInfo.subscriptionPeriodEnd!);
        return inactiveSubscriptionElement(
          `${
            userInfo?.subscriptionInfo.subscriptionStatus || "None"
          } - You will continue to have access until ${dateWithoutTime(subscriptionEnd)}`
        );
      } else {
        return inactiveSubscriptionElement(userInfo?.subscriptionInfo?.subscriptionStatus || "None");
      }
    } else if (userInfo) {
      return inactiveSubscriptionElement(userInfo?.subscriptionInfo?.subscriptionStatus || "None");
    } else {
      <Spinner />;
    }
  };

  const isPaymentMethodValid = (subscriptionInfo: SubscriptionInfo | null) => {
    if (!subscriptionInfo?.paymentMethod) {
      return false;
    }

    const expiryDate = dayjs(
      new Date(subscriptionInfo.paymentMethod.expiryYear, subscriptionInfo.paymentMethod.expiryMonth, 1)
    );

    if (expiryDate < dayjs()) {
      return false;
    }

    return true;
  };

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <OuterContainer>
        {hasActiveSubscription(userInfo) ? activeSubscription() : inactiveSubscription()}
        {subscriptionInfo?.paymentMethod && hasActiveSubscription(userInfo) ? (
          <>
            <Section>
              <Header>Payment Method</Header>
              <TextField>
                <Row>
                  <p>**** **** **** {subscriptionInfo?.paymentMethod.lastFour}</p>
                  <Splitter />
                  <p>{subscriptionInfo?.paymentMethod.expiryMonth.toString().padStart(2, "0")}</p>-
                  <p>{subscriptionInfo?.paymentMethod.expiryYear}</p>
                </Row>
              </TextField>
            </Section>
            <NoExpand>
              <Button href={`/subscribe/payment`}>Update Payment Method</Button>
            </NoExpand>
          </>
        ) : !isPaymentMethodValid(subscriptionInfo) &&
          userInfo?.subscriptionInfo.subscriptionStatus !== "managed" &&
          (hasActiveSubscription(userInfo) || userInfo?.subscriptionInfo.subscriptionStatus === "past_due") ? (
          <>
            <DangerText>No valid payment Method</DangerText>
            <NoExpand>
              <Button href={`/subscribe/payment`}>Add Payment Method</Button>
            </NoExpand>
          </>
        ) : (
          <p>No current payment method</p>
        )}
      </OuterContainer>
    );
  }
};

const DangerText = styled.p`
  color: ${colors.danger};
`;

const NoExpand = styled.div`
  display: flex;
`;

const ButtonGroup = styled(Row)`
  gap: 20px;
`;

const Splitter = styled.p`
  margin: auto;
`;

const TextField = styled.div`
  width: 100%;
  background-color: ${colors.checkboxBgGrey};
  color: ${colors.inactiveButton};
  padding: 5px;
`;

const Header = styled.p`
  color: black;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const OuterContainer = styled(Column)`
  width: 100%;
  gap: 10px;
`;
