import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types";
import { isValidResponse } from "utils/isValidResponse";

export const requestResetTeacherPassword = async (email: string): Promise<Teacher> => {
  const response = await api.post(`/admin/requestresetpassword`, { email: email });
  if (response?.data && isValidResponse(response)) {
    return response?.data;
  } else {
    throw new NetworkError();
  }
};
