import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "app/rootReducer";
import { Tag } from "types";
import { createAsyncSlice } from "./sliceHelpers";
import { fetchTags } from "api";

const { slice, dispatchAsyncFunction } = createAsyncSlice<Array<Tag>, {}>({
  name: "gradeTags",
});

export const {
  loading: gradeTagsLoading,
  success: gradeTagsSuccess,
  error: gradeTagsError,
  reset: gradeTagsReset,
} = slice.actions;

const fetchGradeTags = () => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const data = await fetchTags({
      type: "Grade",
    });
    dispatch(gradeTagsSuccess(data));
  }, dispatch);

const gradeTagsReducer = slice.reducer;
export { gradeTagsReducer, fetchGradeTags };
