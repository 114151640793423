import { Column, Row } from "primitives";
import QRCode from "qrcode.react";
import styled from "styled-components";
import { colors } from "styles";

import { FC, Student } from "types";
import { paths } from "textConstants";
interface Props {
  student: Student;
}

const StudentPasswordCard: FC<Props> = ({ student }) => {
  return (
    <CardContainer key={student.id}>
      <LeftContent>
        <NameContainer>
          <NameFormat>{student.firstName}</NameFormat>
          <NameFormat>{student.lastName}</NameFormat>
        </NameContainer>
        <PasswordContainer>
          <TitleFormat>Password: </TitleFormat>
          <TextPasswordFormat>{student.textPassword} </TextPasswordFormat>
        </PasswordContainer>
      </LeftContent>
      <QRContent>
        <QRCode
          value={`${window.location.origin}${paths.login.student.path}?password=${student.textPassword}`}
          renderAs="svg"
          fgColor={colors.secondary}
          style={{ width: 120, height: 120 }}
        />
      </QRContent>
    </CardContainer>
  );
};

const CardContainer = styled(Row)`
  width: 50%;
  min-height: 250px;
  max-height: 250px;
  gap: 5px;
  padding: 0px 35px;

  border: 1px solid ${colors.innerBorder};

  @media print {
    page-break-inside: avoid;
    display: flex !important;
    float: left;
  }
`;

const LeftContent = styled(Column)`
  flex-grow: 1;
  gap: 25px;
  justify-content: center;
`;

const QRContent = styled(Column)`
  justify-content: center;
`;

const NameContainer = styled(Column)`
  gap: 5px;
  max-width: 22em;
`;

const PasswordContainer = styled(Column)`
  gap: 5px;
`;

const TitleFormat = styled.p`
  color: ${colors.innerBorder};
  font-weight: 500;
`;

const NameFormat = styled.p`
  color: ${colors.darkText};
  font-weight: 500;
  word-break: break-word;
  font-size: 18px;
`;

const TextPasswordFormat = styled.p`
  color: ${colors.darkText};
  font-weight: 500;
  font-style: italic;
  word-break: break-word;
  font-size: 16px;
`;

export { StudentPasswordCard };
