import { FC } from "react";
import { DropResult, DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface DragDropProps {
  onDragEnd: (result: DropResult) => void;
  onBeforeCapture?: () => void;
}

// Parent to all the DraggableItems
const SingleListDropContext: FC<DragDropProps> = ({ onDragEnd, onBeforeCapture, children }) => (
  <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
    <Droppable droppableId="1">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

// Child of a SingleListDropContext
const DraggableItem: FC<{
  index: number;
  draggableId?: string;
  isDragDisabled?: boolean;
}> = ({ index, draggableId, isDragDisabled, children }) => {
  if (!children) return null;
  return (
    <Draggable draggableId={draggableId || `${index}`} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {children}
        </div>
      )}
    </Draggable>
  );
};

export { SingleListDropContext, DraggableItem };
