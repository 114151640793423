import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { Session, SessionType } from "types";
import { FetchSessionListParams, dispatchFetchSessionList } from "./fetchSessionsList";
import { CreateSessionParams, dispatchCreateSession } from "./createSession";
import { dispatchUpdateSession } from "./updateSession";
import { dispatchDuplicateSession } from "./duplicateSession";
import { dispatchFetchSessionByCode } from "./fetchSessionByCode";
import { dispatchFetchSession } from "./fetchSession";
import { dispatchFetchSessionsForStudent } from "./fetchSessionsForStudent";
import { dispatchDeleteSession } from "./deleteSession";
import { dispatchFetchSessionListCount, SessionListCount, FetchSessionListCountParams } from "./fetchSessionListCount";
import { assign, pickBy, toLower } from "lodash";
import { dispatchFetchSessionListFull } from "./fetchSessionListFull";
import { dispatchFetchSessionForStudent } from "./fetchSessionForStudent";
import { dispatchFetchPremadeSessions, FetchPremadeSessionsParams } from "./fetchPremadeSessions";

type SessionListState = {
  data: { [id: string]: Session } | null;
  totals: { [sessionType: string]: number } | null;
  paginationData: {
    orderedIds: string[];
    currentTotalKey?: string;
  };
  premadeGamePreviews: string[];
  lastRequestToken: string;
};

const initialState: SessionListState = {
  data: null,
  totals: null,
  paginationData: {
    orderedIds: [],
  },
  premadeGamePreviews: [],
  lastRequestToken: "",
};

// --- Slice Definition

const reducers = {
  reset(state: SessionListState) {
    state.data = null;
    state.premadeGamePreviews = [];
  },
  resetPageData(state: SessionListState) {
    state.paginationData = {
      orderedIds: [],
    };
  },
  setRequestToken(state: SessionListState, action: PayloadAction<string>) {
    state.lastRequestToken = action.payload;
  },
  addSingle(state: SessionListState, action: PayloadAction<{ id: string; session: Session }>) {
    if (state.data) {
      if (state.data[action.payload.id]) {
        const cleanedObject = pickBy(
          action.payload.session,
          (value, key) => value !== undefined && (key !== "class" || state.data![action.payload.id].class === undefined) // only replace the class if the existing class didn't exist
        );
        state.data[action.payload.id] = assign(state.data[action.payload.id], cleanedObject);
      } else {
        state.data[action.payload.id] = action.payload.session;
      }
    } else {
      state.data = {
        [action.payload.id]: action.payload.session,
      };
    }
  },
  addMany(
    state: SessionListState,
    action: PayloadAction<{ data: Session[]; type?: SessionType; requestToken: string }>
  ) {
    if (state.lastRequestToken === action.payload.requestToken) {
      if (!state.data) {
        state.data = {};
      }

      for (const session of action.payload.data) {
        if (state.data[session.id]) {
          state.data[session.id] = assign(state.data[session.id], session);
        } else {
          state.data[session.id] = session;
        }

        if (!state.paginationData.orderedIds.includes(session.id)) {
          state.paginationData.orderedIds.push(session.id);
        }
      }
      state.paginationData.currentTotalKey = action.payload.type
        ? toLower(SessionType[action.payload.type])
        : undefined;
    } else {
      console.warn(
        "[SessionListSlice:addMany] was fired after the request token was changed. If you didn't expect this, be sure to investigate the issue."
      );
    }
  },
  addDataOnly(
    state: SessionListState,
    action: PayloadAction<{ data: Session[]; requestToken: string }>
  ) {
    if (state.lastRequestToken === action.payload.requestToken) {
      if (!state.data) {
        state.data = {};
      }

      for (const session of action.payload.data) {
        if (state.data[session.id]) {
          state.data[session.id] = assign(state.data[session.id], session);
        } else {
          state.data[session.id] = session;
        }
      }
    } else {
      console.warn(
        "[SessionListSlice:addDataOnly] was fired after the request token was changed. If you didn't expect this, be sure to investigate the issue."
      );
    }
  },
  remove(state: SessionListState, action: PayloadAction<string>) {
    if (state.data && Boolean(state.data[action.payload])) {
      delete state.data[action.payload];
    }

    if (state.paginationData && state.paginationData.orderedIds) {
      const index = state.paginationData.orderedIds.findIndex((sessionId) => sessionId === action.payload);

      if (index >= 0) {
        state.paginationData.orderedIds.splice(index, 1);
      }
    }
  },
  resetTotals(state: SessionListState) {
    state.totals = {};
  },
  updateTotal(state: SessionListState, action: PayloadAction<SessionListCount>) {
    if (!state.totals) {
      state.totals = { [action.payload.sessionType]: action.payload.totalCount };
    } else {
      let curStateTotals = current(state).totals;
      if (curStateTotals && curStateTotals[action.payload.sessionType] !== action.payload.totalCount) {
        state.totals[action.payload.sessionType] = action.payload.totalCount;
      }
    }
  },
  addPremadeGamePreviews(state: SessionListState, action: PayloadAction<{ids: string[]}>) {
    state.premadeGamePreviews = action.payload.ids;
  }
};

const slice = createSlice<SessionListState, typeof reducers>({
  name: "sessionList",
  initialState,
  reducers,
});

// --- Async Action Wrappers

const fetchSessionList = (params: FetchSessionListParams) => dispatchFetchSessionList(params, slice.actions);
const fetchSessionListCount = (params: FetchSessionListCountParams) =>
  dispatchFetchSessionListCount(params, slice.actions);

const fetchSessionByCode = (code: string) => dispatchFetchSessionByCode({ code }, slice.actions);

const fetchSession = (id: string) => dispatchFetchSession({ id }, slice.actions);
const fetchSessionStudent = (sessionId: string) => dispatchFetchSessionForStudent({ sessionId }, slice.actions);
const fetchSessionListFull = (ids: string[]) => dispatchFetchSessionListFull({ ids }, slice.actions);

const createSession = (params: CreateSessionParams) => dispatchCreateSession(params, slice.actions);

const updateSession = (sessionId: string, sessionParams: Partial<CreateSessionParams>) =>
  dispatchUpdateSession(sessionId, sessionParams, slice.actions);

const duplicateSession = (sessionId: string, sessionParams: Partial<CreateSessionParams>) =>
  dispatchDuplicateSession(sessionId, sessionParams, slice.actions);

const fetchSessionsForStudent = () => dispatchFetchSessionsForStudent(slice.actions);
const fetchPremadeSessions = (params: FetchPremadeSessionsParams) =>
  dispatchFetchPremadeSessions(params, slice.actions);

const deleteSession = (id: string) => dispatchDeleteSession({ id }, slice.actions);

// --- Exports

export type SessionActions = typeof slice.actions;
const {
  reset: resetSessionList,
  resetTotals: resetSessionListTotals,
  resetPageData: resetSessionListPageData,
} = slice.actions;

const sessionListReducer = slice.reducer;
export const { addSingle: addSingleSession } = slice.actions;
export {
  sessionListReducer,
  fetchSessionList,
  fetchSessionListCount,
  fetchSessionByCode,
  fetchSession,
  fetchSessionStudent,
  fetchSessionListFull,
  createSession,
  updateSession,
  duplicateSession,
  fetchSessionsForStudent,
  fetchPremadeSessions,
  deleteSession,
  resetSessionListTotals,
  resetSessionList,
  resetSessionListPageData,
};
