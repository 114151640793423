import { ReactComponent as LetsAssemble } from "./LetsAssemble.svg";
import { ReactComponent as LetsExplore } from "./LetsExplore.svg";
import { ReactComponent as LetsFarm } from "./LetsFarm.svg";
import { ReactComponent as LetsHike } from "./LetsHike.svg";
import { ReactComponent as LetsQuiz } from "./LetsQuiz.svg";
import { ReactComponent as LetsRace } from "./LetsRace.svg";
import { ReactComponent as LetsRoll } from "./LetsRoll.svg";
import { ReactComponent as LetsSail } from "./LetsSail.svg";
import { ReactComponent as Defenders } from "./Defenders.svg";
import { ReactComponent as TreasureHunters } from "./TreasureHunters.svg";

import { createCustomIcon } from "primitives/icons";
import { StyledIcon } from "@styled-icons/styled-icon";

type GameIconsType = {
  [name: string]: StyledIcon;
};

export const GameIcons: GameIconsType = {
  "Let's Build": createCustomIcon(LetsAssemble, "Let's Build"),
  "Let's Explore": createCustomIcon(LetsExplore, "Let's Explore"),
  "Let's Farm": createCustomIcon(LetsFarm, "Let's Farm"),
  "Let's Hike": createCustomIcon(LetsHike, "Let's Hike"),
  "Let's Quiz": createCustomIcon(LetsQuiz, "Let's Quiz"),
  "Let's Race": createCustomIcon(LetsRace, "Let's Race"),
  "Let's Roll": createCustomIcon(LetsRoll, "Let's Roll"),
  "Let's Sail": createCustomIcon(LetsSail, "Let's Sail"),
  Defenders: createCustomIcon(Defenders, "Defenders"),
  "Treasure Hunters": createCustomIcon(TreasureHunters, "Treasure Hunters"),
};
