import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Column, PrimaryButton, SecondaryText } from "primitives";
import { defaultRequestState, PronunciationSet, QuestionSet, ServerPronunciationSet, TTSOverridePair } from "types";
import { ModalButtonFooter, useCharacterLimitedNonEmptyInput } from "components";
import styled from "styled-components";
import { colors } from "styles";
import { defaultPronunciationSets, dispatchAsync } from "utils";
import { updatePronunciationSet, createPronunciationSet, getPronunciationSets } from "slices";
import { useParams } from "react-router-dom";
import { TTSOverrideRow } from "./TTSOverrideEditorRow";
import { MedIcon } from "primitives/icons";
import { actionConstants } from "textConstants";
import { isEqual } from "lodash";
import { CustomTTSOverrideAlert } from "../../customGames/Components";

interface Props {
  visible: boolean;
  preview?: boolean;
  isEditing?: boolean;
  hide: () => void;
  onComplete?: (updatedSet: PronunciationSet) => void;
}

const maxTextToSpeechName = 120;

export const PronunciationSetEditModal: FC<Props> = ({ visible, preview, isEditing, hide, onComplete }) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const [isClosing, setIsClosing] = useState(false);
  const { data } = useAppSelector((state) => state.pronunciationSets);
  const dispatch = useAppDispatch();

  const { pronunciationSetId } = useParams<{ pronunciationSetId: string }>();

  const pronunciationSet: PronunciationSet | undefined = useMemo(() => {
    for (let i = 0; i < defaultPronunciationSets.length; i++) {
      if (defaultPronunciationSets[i].id === pronunciationSetId) {
        return defaultPronunciationSets[i];
      }
    }
    return (data && data[pronunciationSetId]) || undefined;
  }, [data, pronunciationSetId]);

  const [TitleInput, titleState, setTitleState] = useCharacterLimitedNonEmptyInput({
    label: "Pronunciation Name",
    warningMessage: "You must enter a title",
    initialTextValue: pronunciationSet?.name || "",
    characterLimit: maxTextToSpeechName,
    disabled: preview,
  });

  const [loadByDefault, setLoadByDefault] = useState<boolean>(pronunciationSet?.loadByDefault || false);
  const [overrides, setOverrides] = useState<TTSOverridePair[]>(
    pronunciationSet ? [...pronunciationSet?.ttsOverridePairs] : []
  );

  const fetchSessions = useCallback(async () => {
    const fetchResult = await dispatchAsync(dispatch(getPronunciationSets()), setRequestState);
    const set = fetchResult.result?.find((set) => set.id === pronunciationSetId);
    if (set) {
      setLoadByDefault(set.loadByDefault);
      setOverrides(set.ttsOverridePairs);
      setTitleState.setText(set.name);
    }
  }, [dispatch, pronunciationSetId, setTitleState]);

  useEffect(() => {
    if ((!data || (pronunciationSetId && !pronunciationSet)) && !loading) {
      fetchSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSessions, data, pronunciationSet, pronunciationSetId]);

  const resetStateAndHide = (e?: MouseEvent) => {
    e?.preventDefault();
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    hide();
  };

  const checkAllOverridesValid = () => {
    for (let i = 0; i < overrides.length; i++) {
      if (!handleTTSOverrideIsValid(overrides[i])) {
        return false;
      }
    }
    return true;
  };

  const handleTTSOverridePairChange = (value: string, index: number, field: "input" | "output") => {
    if (index < overrides.length) {
      const newOverrides = overrides.map((override) => {
        return { input: override.input, output: override.output };
      });

      if (field === "input") {
        newOverrides[index].input = value;
      } else {
        newOverrides[index].output = value;
      }

      setOverrides(newOverrides);
    }
  };

  const handleTTSOverrideDelete = (index: number) => {
    const newOverrides = [...overrides];
    newOverrides.splice(index, 1);
    setOverrides(newOverrides);
  };

  const handleTTSOverrideIsValid = (pair: TTSOverridePair | null) => {
    let count = 0;

    if (pair != null) {
      for (let i = 0; i < overrides.length; i++) {
        if (overrides[i].input === pair?.input) count++;
      }
    }

    //force the return to be a boolean
    return (pair && pair.input && pair.output && count === 1) === true;
  };

  const hasOverrideChanged = () => {
    if (!pronunciationSet?.ttsOverridePairs) {
      return true;
    }

    if (overrides.length !== pronunciationSet?.ttsOverridePairs.length) {
      return true;
    }

    for (let i = 0; i < overrides.length; i++) {
      if (!isEqual(overrides[i], pronunciationSet?.ttsOverridePairs[i])) {
        return true;
      }
    }
    return false;
  };

  const loadByDefaultChanged = () => {
    return loadByDefault !== pronunciationSet?.loadByDefault;
  };

  const titleChanged = () => {
    return titleState.text !== pronunciationSet?.name;
  };

  const inputIsInvalid = !titleState.valid || !checkAllOverridesValid() || overrides.length === 0;
  const overridesHaveChanged = !isEditing || hasOverrideChanged() || loadByDefaultChanged() || titleChanged();

  const handleTTSOverrideAdd = () => {
    setOverrides([...overrides, { input: "", output: "" }]);
  };

  const onConfirmButton = async (e?: React.MouseEvent) => {
    if (!preview) {
      e?.preventDefault();

      const pronunciationSetParams: Partial<ServerPronunciationSet> = {
        name: titleState.text,
        ttsOverrides: JSON.stringify(overrides),
        loadByDefault: loadByDefault,
      };

      if (isEditing && pronunciationSetId) {
        const { result } = await dispatchAsync(
          dispatch(updatePronunciationSet(pronunciationSetId, pronunciationSetParams)),
          setRequestState
        );
        onComplete && result && onComplete(result);
      } else {
        const { result } = await dispatchAsync(
          dispatch(createPronunciationSet(pronunciationSetParams)),
          setRequestState
        );
        onComplete && result && onComplete(result);
      }
    }
    resetStateAndHide();
  };

  return (
    <Modal
      show={visible && !isClosing}
      onHide={resetStateAndHide}
      dialogClassName="full-width-modal"
      onExited={onModalHidden}
    >
      <Modal.Header>
        <Modal.Title>{preview ? "Preview" : isEditing ? "Edit" : "Create"} Pronunciation Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {!preview && <CustomTTSOverrideAlert />}
          <Form.Group>
            <TitleInput {...titleState} {...setTitleState} />
          </Form.Group>
          <Form.Group>
            <SecondaryText>Use By Default</SecondaryText>
            <Form.Check
              type="checkbox"
              id="checkboxLoading"
              label="Always use these pronunciations when enabling TTS"
              checked={loadByDefault}
              disabled={preview}
              onChange={() => {
                setLoadByDefault(!loadByDefault);
              }}
            />
          </Form.Group>
          <TTSPairContainer className="m-3 p-3">
            {overrides.map((override, index) => (
              <TTSOverrideRow
                key={index}
                override={override as TTSOverridePair}
                index={index}
                disabled={preview}
                selectedQuestionSets={[] as QuestionSet[]}
                onChanged={handleTTSOverridePairChange}
                removeOverride={handleTTSOverrideDelete}
                isOverrideValid={handleTTSOverrideIsValid}
              />
            ))}
            <div className="py-2 px-3">
              <PrimaryButton
                variant="primary"
                disabled={preview || !checkAllOverridesValid()}
                onClick={handleTTSOverrideAdd}
              >
                <MedIcon icon={actionConstants.add.icon} />
                New Pronunciation
              </PrimaryButton>
            </div>
          </TTSPairContainer>
        </Form>
        <ModalButtonFooter
          isEditing={Boolean(isEditing)}
          isPreview={preview}
          onCancel={() => {
            resetStateAndHide();
          }}
          onConfirm={onConfirmButton}
          confirmDisabled={loading || inputIsInvalid || !overridesHaveChanged}
          confirmType={"submit"}
          cancelClassName={"btn-cancel"}
        />
      </Modal.Body>
    </Modal>
  );
};

const TTSPairContainer = styled(Column)`
  background-color: ${colors.light};
  gap: 0.75em;
  align-items: flex-start;
`;
