import { ConfirmModal } from "primitives";
import { Dispatch, SetStateAction, useState } from "react";
import { ButtonProps } from "react-bootstrap";

export interface ConfirmModalInfo {
  title?: React.ReactNode | string;
  body: string[];
  confirmButtonVariant?: string;
  onModalConfirm?: () => Promise<void>;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmProps?: Partial<ButtonProps>;
  isPreview?: boolean;
}

const defaultModalinfo = {
  body: [""],
  onModalConfirm: async () => {},
};

type useConfirmModalType = [
  Modal: React.ElementType,
  setModalVisible: Dispatch<SetStateAction<boolean>>,
  setModalInfo: Dispatch<SetStateAction<ConfirmModalInfo>>
];

const useConfirmModal = (): useConfirmModalType => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalinfo, setModalInfo] = useState<ConfirmModalInfo>(defaultModalinfo);

  const Modal = () => {
    const [isLoading, setLoading] = useState(false);
    return (
      <>
        {modalVisible && (
          <ConfirmModal
            title={modalinfo.title}
            body={modalinfo.body}
            confirmButtonVariant={modalinfo.confirmButtonVariant}
            visible={modalVisible}
            hide={() => {
              setModalVisible(false);
            }}
            onConfirm={async () => {
              if (modalinfo.onModalConfirm) {
                setLoading(true);
                await modalinfo.onModalConfirm();
                setLoading(false);
              }
              setModalVisible(false);
            }}
            isLoading={isLoading}
            confirmTitle={modalinfo.confirmTitle}
            cancelTitle={modalinfo.cancelTitle}
            confirmProps={modalinfo.confirmProps}
            isPreview={modalinfo.isPreview}
          />
        )}
      </>
    );
  };

  return [Modal, setModalVisible, setModalInfo];
};

export { useConfirmModal };
