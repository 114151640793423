import { Center } from "primitives";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { TeacherHeader, NavbarItemProperties, SubscriptionBanner } from "components";
import { QuestionSetRoutes } from "../questions";
import { Classes } from "../classes";
import { PlayRoutes } from "../my-games";
import { NavBarPortal } from "components/breadcrumb";
import { Review } from "../progress";
import { TeacherSettings } from "../settings";
import { breadcrumbConstants } from "textConstants";
import { TeacherDashboard } from "./teacherDashboard/TeacherDashboard";
import { WhatsNewModal } from "components/whatsNewModal/whatsNewModal";
import { useEffect, useState } from "react";
import { Academy } from "../academy";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { NewsItem } from "types";
import { fetchNewsItems } from "slices";
import { GameLibraryRoutes } from "pages/gameLibrary";

const navBarItems: Array<NavbarItemProperties> = [
  {
    icon: breadcrumbConstants.questions.icon,
    name: breadcrumbConstants.questions.title,
    route: breadcrumbConstants.questions.route,
    routeParams: [],
    accentColor: breadcrumbConstants.questions.accentColor,
    highlightColor: breadcrumbConstants.questions.highlightColor,
    component: QuestionSetRoutes,
  },
  {
    icon: breadcrumbConstants.myGames.icon,
    name: breadcrumbConstants.myGames.title,
    route: breadcrumbConstants.myGames.route,
    routeParams: [],
    accentColor: breadcrumbConstants.myGames.accentColor,
    highlightColor: breadcrumbConstants.myGames.highlightColor,
    component: PlayRoutes,
  },
  {
    icon: breadcrumbConstants.gameLibrary.icon,
    name: breadcrumbConstants.gameLibrary.title,
    route: breadcrumbConstants.gameLibrary.route,
    routeParams: [],
    accentColor: breadcrumbConstants.gameLibrary.accentColor,
    highlightColor: breadcrumbConstants.gameLibrary.highlightColor,
    component: GameLibraryRoutes,
  },
  {
    icon: breadcrumbConstants.progress.icon,
    name: breadcrumbConstants.progress.title,
    route: breadcrumbConstants.progress.route,
    routeParams: [],
    accentColor: breadcrumbConstants.progress.accentColor,
    highlightColor: breadcrumbConstants.progress.highlightColor,
    component: Review,
  },
  {
    icon: breadcrumbConstants.class.icon,
    name: breadcrumbConstants.class.title,
    route: breadcrumbConstants.class.route,
    routeParams: [],
    accentColor: breadcrumbConstants.class.accentColor,
    highlightColor: breadcrumbConstants.class.highlightColor,
    component: Classes,
    showClass: true,
  },
  {
    icon: breadcrumbConstants.academy.icon,
    name: breadcrumbConstants.academy.title,
    route: breadcrumbConstants.academy.route,
    routeParams: [],
    accentColor: breadcrumbConstants.academy.accentColor,
    highlightColor: breadcrumbConstants.academy.highlightColor,
    component: Academy,
    dontShowInNavbar: true,
  },
];

const Home = () => {
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const [showingWhatsNewModal, setShowingWhatsNewModal] = useState(false);

  const newsItemList: NewsItem[] | null = useAppSelector((state) => state.newsItems.data);
  useEffect(() => {
    if (!newsItemList) {
      dispatch(fetchNewsItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newsItemList) {
      if (newsItemList.length > 0) {
        setShowingWhatsNewModal(true);
      }
    }
  }, [newsItemList]);

  return (
    <StyledCenter>
      <TeacherHeader
        navbarItems={navBarItems}
        whatsNewNewsItemCount={newsItemList?.length || 0}
        onWhatsNewClicked={() => {
          setShowingWhatsNewModal(true);
        }}
      />
      <NavBarPortal />
      <ContentContainer>
        <Switch>
          <Route path={`${url}/settings/:tab?`} component={TeacherSettings} />
          {navBarItems.map(({ route, component, routeParams }, index) => {
            return (
              <Route
                path={`${url}/${route}${routeParams.map((p) => `/:${p}`).join("")}`}
                component={component}
                key={index}
              />
            );
          })}
          <Route path={`${url}/`} component={TeacherDashboard} />
          <Route path="/">
            <Redirect to={`${url}/`} />
          </Route>
        </Switch>
        <WhatsNewModal
          visible={showingWhatsNewModal}
          hide={() => {
            setShowingWhatsNewModal(false);
          }}
        />
      </ContentContainer>
      <SubscriptionBanner />
    </StyledCenter>
  );
};

const StyledCenter = styled(Center)`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  overflow-x: hidden;

  @media print {
    display: table;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media print {
    overflow: visible;
  }
`;
export { Home };
