import pluralize from "pluralize";
import { PrimaryButton } from "primitives/button";
import { Center, Row, SecondaryText, Spacer } from "primitives";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { FC, StudentName } from "types";
import { getStudentCountFromText, getStudentNamesFromText } from "./getStudentNamesFromText";

interface Props {
  studentNames: string[];
  setStudentNames: (value: React.SetStateAction<StudentName[]>) => void;
  onHidden: () => void;
}

const BulkAddStudentsModal: FC<Props> = ({ studentNames, onHidden, setStudentNames }) => {
  const [studentNamesText, setStudentNamesText] = useState(studentNames.join("\n"));
  const [closing, setClosing] = useState(false);

  const onModalHidden = () => {
    setClosing(false);
    onHidden();
  };

  const studentCount = getStudentCountFromText(studentNamesText);

  const modalBody = (
    <Modal.Body>
      <TitleText>Student List*</TitleText>
      <Spacer size={5} />
      <SecondaryText>Copy your student names into the field below. Enter 1 name per line.</SecondaryText>
      <Spacer />
      <Row>
        <Form.Control
          type="text"
          as="textarea"
          value={studentNamesText}
          onChange={(e) => setStudentNamesText(e.target.value)}
          style={{ height: "300px" }}
        />
        {!studentNamesText && <PlaceholderTextContainer>{`e.g\nJohn Smiths\nJane Doe`}</PlaceholderTextContainer>}
      </Row>
      <StudentCountContainer>{`${studentCount} ${pluralize("student", studentCount)} detected`}</StudentCountContainer>
      <Row>
        <Center>
          <PrimaryButton
            variant="secondary"
            onClick={() => {
              setClosing(true);
            }}
          >
            Cancel
          </PrimaryButton>
          <Spacer size={10} />
          <PrimaryButton
            onClick={() => {
              setStudentNames(getStudentNamesFromText(studentNamesText));
              setClosing(true);
            }}
          >
            Confirm
          </PrimaryButton>
        </Center>
      </Row>
    </Modal.Body>
  );
  return (
    <Modal
      className="import-students-modal"
      show={!closing}
      onHide={() => setClosing(true)}
      onExited={onModalHidden}
      dialogClassName="width-1000px-modal"
    >
      <Modal.Header>
        <Modal.Title>Paste Student Names in Bulk</Modal.Title>
      </Modal.Header>
      {modalBody}
    </Modal>
  );
};

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.darkText};
`;

const StudentCountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2em;
`;

const PlaceholderTextContainer = styled.div`
  position: absolute;
  top: 85px;
  padding: 0.375rem 0.75rem;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 1rem;
  pointer-events: none;
  opacity: 50%;
`;

export { BulkAddStudentsModal };
