import styled from "styled-components";
import { defaultRequestState, FC, SessionType, StringMap } from "types";
import { HorizontalLine, SimpleSelect, Spinner } from "primitives";
import { Button } from "primitives/button";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { SessionTabRow } from "pages/my-games/lessonPlans/sessionsList";
import { getSearchForParams, useClassId, useQueryParams } from "hooks";
import { dispatchAsync, getSessionTypeForTab } from "utils";
import { InfiniteLessonPlanList } from "./LessonPlanList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { EmptyPanelContainer } from "components";
import { useState, Fragment, useCallback, useEffect } from "react";
import { breadcrumbConstants, Names, paths, slugs } from "textConstants";
import { MedIcon } from "primitives/icons";
import { fetchSessionList, fetchSessionListCount, resetSessionListPageData, resetSessionListTotals } from "slices";
import { EmptySessionMessage } from "pages/my-games/lessonPlans/sessionsList/EmptySessionMessage";
import { EditSessionModal } from "components";
import { useLocation } from "react-router-dom";
import { AdjustSessionTimeModal } from "pages/my-games/lessonPlans/editSessionModal/AdjustSessionTimeModal";
import { LessonPlanPreviewModal } from "./LessonPlanPreviewModal";
import { Form } from "react-bootstrap";
import { includes } from "lodash";
import { QuickGamePreview } from "./QuickGamePreview";

interface QueryParams extends StringMap {
  tab: string;
}

const defaultQueryParams: QueryParams = {
  tab: "upcoming",
};

interface OrderingOption {
  id: string;
  name: string;
  orderBy: "asc" | "dec";
  order: string;
}

const alphabeticalOrderingOptions: OrderingOption[] = [
  {
    id: "alphaAsc",
    name: "Alphabetical (A-Z)",
    order: "name",
    orderBy: "asc",
  },
  {
    id: "alphaDec",
    name: "Alphabetical (Z-A)",
    order: "name",
    orderBy: "dec",
  },
];

const orderingOptions: { [tab: string]: OrderingOption[] } = {
  upcoming: [
    {
      id: "dateAsc",
      name: "Date (Closest First)",
      order: "startTime",
      orderBy: "asc",
    },
    {
      id: "dateDec",
      name: "Date (Farthest First)",
      order: "startTime",
      orderBy: "dec",
    },
    ...alphabeticalOrderingOptions,
  ],
  unscheduled: [
    {
      id: "dateAsc",
      name: "Date (Most Recent First)",
      order: "modifiedAt",
      orderBy: "dec",
    },
    {
      id: "dateDec",
      name: "Date (Oldest First)",
      order: "modifiedAt",
      orderBy: "asc",
    },
    ...alphabeticalOrderingOptions,
  ],
  current: [
    {
      id: "dateAsc",
      name: "Date (Closest First)",
      order: "endTime",
      orderBy: "asc",
    },
    {
      id: "dateDec",
      name: "Date (Farthest First)",
      order: "endTime",
      orderBy: "dec",
    },
    ...alphabeticalOrderingOptions,
  ],
  past: [
    {
      id: "dateAsc",
      name: "Date (Closest First)",
      order: "endTime",
      orderBy: "dec",
    },
    {
      id: "dateDec",
      name: "Date (Farthest First)",
      order: "endTime",
      orderBy: "asc",
    },
    ...alphabeticalOrderingOptions,
  ],
};

let tabSwitchTimeout = 0;

export const LessonPlans: FC = () => {
  const hasClasses = useAppSelector((state) => (state.classList.data ? true : false));
  const [{ loading: sessionLoading, success: sessionSuccess, error: sessionError }, setRequestState] = useState(
    defaultRequestState
  );

  const {
    data: sessionData,
    totals: sessionTotals,
    paginationData: { orderedIds: sessionIds, currentTotalKey },
  } = useAppSelector((state) => state.sessionList);
  const [currentOrdering, setCurrentOrdering] = useState<OrderingOption>();
  const [tabSwitchDelay, setTabSwitchDelay] = useState(false);

  const sessionList = sessionData ? sessionIds.map((sessionId) => sessionData[sessionId]) : [];
  const currentTotal = sessionTotals ? sessionTotals[currentTotalKey || "current"] : 0;

  const { url, path } = useRouteMatch();
  const [classId] = useClassId(true);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const handleNewClassPressed = () => {
    history.push(paths.teacher.class.new.path);
  };

  const handleNewGamePressed = () => {
    history.push(`/${slugs.teacher}/${slugs.gamelibrary}`);
  };

  const handleNewSessionPressed = () => {
    history.push({ pathname: `${url}/${slugs.session}/${slugs.new}`, search });
  };

  const { params: queryParams, updateParams: updateQueryParams } = useQueryParams<QueryParams>(defaultQueryParams);
  const { tab } = queryParams;

  const [page, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const getNextPage = () => {
    setCurrentPage(page + 1);
  };
  const stringifiedCurrentOrdering = JSON.stringify(currentOrdering);

  const loadSessionList = useCallback(async () => {
    if (classId) {
      dispatchAsync(
        dispatch(
          fetchSessionList({
            classId,
            sessionType: getSessionTypeForTab(tab),
            orderby: currentOrdering?.orderBy || "dec",
            take: itemsPerPage,
            skip: page * itemsPerPage,
            order: currentOrdering?.order || "endTime",
            chunkSize: 2,
          })
        ),
        setRequestState
      );
    }
  }, [dispatch, classId, tab, currentOrdering, page]);

  const loadSessionListCount = useCallback(async () => {
    if (classId) {
      // Note: Currently with a ton of data these can time out. We've made a
      // note to look into their performance in the future.
      dispatch(resetSessionListTotals());
      dispatchAsync(dispatch(fetchSessionListCount({ classId, sessionType: SessionType.Current })));
      dispatchAsync(dispatch(fetchSessionListCount({ classId, sessionType: SessionType.Upcoming })));
      dispatchAsync(dispatch(fetchSessionListCount({ classId, sessionType: SessionType.Past })));
      dispatchAsync(dispatch(fetchSessionListCount({ classId, sessionType: SessionType.Unscheduled })));
    }
  }, [dispatch, classId]);

  //Reset and load based on class, tab or ordering change
  useEffect(() => {
    if (classId && tab && currentOrdering && includes(orderingOptions[tab], currentOrdering)) {
      dispatch(resetSessionListPageData());
      loadSessionListCount();
      if (page === 0) {
        loadSessionList();
      } else {
        setCurrentPage(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId, tab, stringifiedCurrentOrdering]);

  //Load new pages
  useEffect(() => {
    if (page !== 0) {
      loadSessionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    loadSessionListCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    setTabSwitchDelay(true);
    clearTimeout(tabSwitchTimeout);

    tabSwitchTimeout = window.setInterval(() => {
      setTabSwitchDelay(false);
      clearTimeout(tabSwitchTimeout);
    }, 1000);
  }, [tab]);

  const onNewTabSelected = (newTab: string) => {
    if (newTab !== tab) {
      updateQueryParams({ tab: newTab });
    }
    setCurrentPage(0);
  };

  const onEditModalHidden = (newTab?: string) => {
    let newSearch = search;
    if (newTab) {
      updateQueryParams({ tab: newTab });
      newSearch = getSearchForParams({ ...queryParams, tab: newTab });
    }
    history.push({ pathname: url, search: newSearch });
  };

  const onItemEdited = () => {
    loadSessionList();
  };

  return (
    <>
      <OuterContainer className="px-5 py-4">
        <Contents>
          <LessonContainer>
            <SessionTabRow
              onCreateSession={handleNewSessionPressed}
              onCreateQuickGame={handleNewGamePressed}
              selectedTab={tab}
              setSelectedTab={onNewTabSelected}
            />
            <Form inline className="mt-3 mb-2">
              <SimpleSelect
                options={orderingOptions[tab]}
                getName={(o) => o.name}
                onChange={setCurrentOrdering}
                value={currentOrdering?.id}
              />
            </Form>
            {(sessionLoading && page === 0) || !hasClasses ? (
              <Spinner />
            ) : sessionError ? (
              <EmptyPanelContainer title="Something went wrong" />
            ) : !classId ? (
              <EmptyPanelContainer
                title={`You need a class to view ${Names.Sessions}`}
                message={
                  <Fragment>
                    Create a new class in the
                    <Button className="mx-2" onClick={handleNewClassPressed} size="sm" variant="outline-primary">
                      <MedIcon icon={breadcrumbConstants.class.icon} /> Class
                    </Button>
                    tab
                  </Fragment>
                }
              />
            ) : !sessionList || (!sessionSuccess && page === 0) ? (
              <EmptyPanelContainer
                title={`You have no ${Names.CustomGames} for this class`}
                message={`Press Create ${Names.Session} to make one`}
              />
            ) : !sessionList.length ? (
              <>
                <EmptyPanelContainer>{EmptySessionMessage(tab, handleNewSessionPressed)}</EmptyPanelContainer>
                <HorizontalLine />
                <QuickGamePreview />
              </>
            ) : (
              <InfiniteLessonPlanList
                sessions={sessionList}
                sessionType={getSessionTypeForTab(tab)}
                totalPerPage={itemsPerPage}
                fetchMore={getNextPage}
                sessionLoading={sessionLoading || tabSwitchDelay}
                emptyMessage={EmptySessionMessage(tab, handleNewSessionPressed)}
                classId={classId}
                switchToTab={onNewTabSelected}
                updateLessonCount={loadSessionListCount}
                total={currentTotal}
              />
            )}
          </LessonContainer>
        </Contents>
      </OuterContainer>
      <Switch>
        <Route path={`${path}/${slugs.session}/${slugs.new}`}>
          <EditSessionModal shouldDuplicate={false} onHidden={onEditModalHidden} onItemEdited={onItemEdited} />
        </Route>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.start}`}>
          <AdjustSessionTimeModal
            title={`Start ${Names.Session}`}
            subtitle={`Set when this ${Names.Session} will end`}
            onHidden={onEditModalHidden}
            onItemEdited={onItemEdited}
          />
        </Route>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}`} exact>
          <LessonPlanPreviewModal onHidden={onEditModalHidden} />
        </Route>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.adjust}`}>
          <AdjustSessionTimeModal
            title="Change End Time"
            onHidden={onEditModalHidden}
            onItemEdited={onItemEdited}
            disableStartTime={true}
          />
        </Route>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.duplicate}`}>
          <EditSessionModal shouldDuplicate={true} onHidden={onEditModalHidden} onItemEdited={onItemEdited} />
        </Route>
        <Route path={`${path}/${slugs.session}/${slugs.sessionId}/${slugs.settings}`}>
          <EditSessionModal shouldDuplicate={false} onHidden={onEditModalHidden} onItemEdited={onItemEdited} />
        </Route>
      </Switch>
    </>
  );
};

const OuterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const Contents = styled.div`
  height: 100%;
`;

const LessonContainer = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;
