import { StyledIcon } from "@styled-icons/styled-icon";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { Row, SecondaryText, TitleText, Spacer } from "primitives";
import { LargeIcon } from "primitives/icons";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { FC } from "types";

interface DashboardItemProps {
  title: string;
  color: string;
  icon: StyledIcon;
  description: string;
  route: string;
  className?: string;
}

export const DashboardItem: FC<DashboardItemProps> = ({ className, title, color, icon, description, route }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleClickItem = (route: string) => {
    history.push(`${url}/${route}`);
  };

  return (
    <Container
      className={classNames(className, kebabCase(route), "dashboard-item")}
      onClick={() => handleClickItem(route)}
    >
      <Row>
        <LargeIcon icon={icon} color={color} />
        <Spacer size={5} />
        <TitleText color={color}>{title}</TitleText>
      </Row>
      <Text style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
};

const Container = styled.div`
  width: 310px;
  height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: ${colors.white};
  border: 2px solid ${colors.darkText};
  border-radius: 0px;
  padding: 2em;
  cursor: pointer;
  transition: ${layoutConstants.defaultTransition};
  box-shadow: -10px 10px ${colors.darkText};
  :hover {
    background-color: ${colors.lightHover};
    box-shadow: -5px 5px ${colors.darkText};
  }
`;

const Text = styled(SecondaryText)`
  white-space: "normal";
  color: ${colors.darkText};
  text-align: center;

  br {
    padding-bottom: 0.75em;
    display: block;
    content: "";
  }
`;
