import { useQuestionImages } from "hooks/redux/useQuestionImages";
import pluralize from "pluralize";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { CharacterLimitedInputControlWithError, ImagePreview } from "components";
import {
  Button,
  Column,
  CreateButton,
  DeleteButton,
  HorizontalLine,
  Row,
  SecondaryText,
  Spacer,
  Spinner,
  ToolTip,
} from "primitives";
import { colors } from "styles";
import { AnswerTuple, FC, MultichoiceQuestion, Question } from "types";
import { replaceOneElementInArray } from "utils";
import { generateDistractors, getRandomCorrectAnswers } from "../../Utils";
import { CorrectAnswerCircle } from "../CorrectAnswerCircle";
import {
  generateErrorFeedbackForCorrectAnswer,
  generateErrorFeedbackForDistractor,
  generateErrorFeedbackForQuestion,
} from "../questionSetValidation/generateErrorFeedbackForAnswerIndex";
import { ImageSelector } from "./ImageSelector";
import { Character_Limit, DistractorTitle, FlexButton, InputContainer, SectionContainer } from "./QuestionEditor";
import { useSelectImageModal } from "./selectImageModal";

interface Props {
  activeQuestionIndex: number | null;
  questions: Question[] | undefined;
  questionData: MultichoiceQuestion;
  showInvalidFeedback: boolean;
  maxDistractorCount: number;
  handleQuestionChanged: (question: Partial<Question>) => void;
  onScrollToBottom: () => void;
}

export const MultichoiceQuestionForm: FC<Props> = ({
  activeQuestionIndex,
  questions,
  questionData,
  showInvalidFeedback,
  maxDistractorCount,
  handleQuestionChanged,
  onScrollToBottom,
}) => {
  const [distractorCount, setDistractorCount] = useState<number>(1);
  const [randomisingAnswerImages, setRandomisingAnswerImages] = useState(false);
  const { data: questionImages, loading: questionsLoading } = useQuestionImages();
  const {
    SelectImageModal,
    setSelectImageModalVisible,
    selectImageModalProps,
    setOnSubmitImage,
  } = useSelectImageModal();

  const { question, questionImage, answers, answerImages, correctAnswerIndex } = questionData;

  const shouldShowDistractorDelete = distractorCount > 1;
  const enableDistractorButton = Boolean(answers[distractorCount] || answerImages[distractorCount]);

  const removeDistractor = (index: number) => {
    let newAnswers: AnswerTuple = [...answers];
    let newAnswerImages: AnswerTuple = [...answerImages];

    newAnswers.splice(index, 1);
    newAnswerImages.splice(index, 1);
    setDistractorCount((prevCount) => {
      return prevCount - 1;
    });

    const newQuestion: Partial<MultichoiceQuestion> = {
      answers: newAnswers,
      answerImages: newAnswerImages,
    };
    handleQuestionChanged(newQuestion);
  };

  const getRandomiseOffset = (hasAnswerImage: boolean, showingDelete: boolean) => {
    let offset = 0;

    const deleteOffset = 30;
    const answerImageOffset = 120;
    const selectAnswerImageOffset = 200;

    if (showingDelete) {
      offset += deleteOffset;
    }

    offset += hasAnswerImage ? answerImageOffset : selectAnswerImageOffset;

    return `${offset}px`;
  };

  const randomiseAllDistractorImages = async () => {
    setRandomisingAnswerImages(true);

    if (questionImages) {
      let newAnswerImages = [...answerImages];

      for (let i = 0; i < distractorCount + 1; i++) {
        if (i !== correctAnswerIndex) {
          const index = Math.floor(Math.random() * questionImages.length);
          newAnswerImages[i] = questionImages[index].imageUrl;
        }
      }

      const newQuestion: Partial<MultichoiceQuestion> = {
        answerImages: newAnswerImages,
      };
      handleQuestionChanged(newQuestion);
      setRandomisingAnswerImages(false);
    }
  };

  useEffect(() => {
    let distractorCount = 1;
    for (let i = answers.length - 1; i > 0; i--) {
      if (answers[i] !== null) {
        distractorCount = Math.max(distractorCount, i);
        break;
      }
    }
    for (let i = answerImages.length - 1; i > 0; i--) {
      if (answerImages[i] !== null) {
        distractorCount = Math.max(distractorCount, i);
        break;
      }
    }

    setDistractorCount(distractorCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionIndex, questionData]);

  const notInitialRender = useRef(false);
  useEffect(() => {
    if (notInitialRender.current) {
      onScrollToBottom();
    } else {
      notInitialRender.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distractorCount]);

  const updateQuestionAnswer = (value: string, index: number) => {
    const newAnswers = replaceOneElementInArray(answers, index, value.length === 0 ? null : value);
    const newQuestion: Partial<MultichoiceQuestion> = {
      answers: newAnswers,
    };

    //Make sure we have equal number of answer images to answers
    if (answerImages.length < newAnswers.length) {
      const newAnswerImages = [...answerImages];
      while (newAnswerImages.length < newAnswers.length) {
        newAnswerImages.push(null);
      }
      newQuestion.answerImages = newAnswerImages;
    }

    handleQuestionChanged(newQuestion);
  };

  const displayDistractorFields = () => {
    let distractors: JSX.Element[] = [];

    const enableRandomisation = questions
      ? getRandomCorrectAnswers(questions as MultichoiceQuestion[], answers, true).length !== 0
      : false;

    for (let i = 1; i < distractorCount + 1; i++) {
      distractors.push(
        <div key={i}>
          <SectionContainer>
            <InputContainer>
              <CharacterLimitedInputControlWithError
                characterLimit={Character_Limit}
                placeholder="Distractor"
                initialInput={answers[i] || ""}
                onInputUpdated={(newValue) => {
                  updateQuestionAnswer(newValue, i);
                }}
                canShowFeedback={showInvalidFeedback}
                errorFeedback={generateErrorFeedbackForDistractor({
                  index: i,
                  answers,
                  answerImages,
                })}
                forceSingleLine={!Boolean(answerImages[i])}
                offsetPadding={true}
              >
                {answerImages[i] ? (
                  <ImagePreview
                    imageUrl={answerImages[i]}
                    onPressDelete={() => {
                      const newQuestion: Partial<MultichoiceQuestion> = {
                        answerImages: replaceOneElementInArray(answerImages, i, null),
                      };
                      handleQuestionChanged(newQuestion);
                    }}
                  />
                ) : (
                  <ImageSelector
                    imageUrl={answerImages[i]}
                    disabled={randomisingAnswerImages}
                    onSelectImage={() => {
                      setOnSubmitImage(() => (imageUrl: string) => {
                        const newQuestion: Partial<MultichoiceQuestion> = {
                          answerImages: replaceOneElementInArray(answerImages, i, imageUrl),
                        };
                        handleQuestionChanged(newQuestion);
                      });
                      setSelectImageModalVisible(true);
                    }}
                  />
                )}

                {shouldShowDistractorDelete ? (
                  <StyledDeleteButtonContainer>
                    <StyledDeleteButton
                      tooltip={"Remove Distractor"}
                      onClick={() => {
                        removeDistractor(i);
                      }}
                    />
                  </StyledDeleteButtonContainer>
                ) : (
                  ""
                )}
                <ToolTip
                  tooltip="Unable to generate random content. Add more Questions to this Question Set to create more content."
                  enabled={!enableRandomisation}
                >
                  <RandomiseButtonWrapper
                    style={{
                      right: getRandomiseOffset(Boolean(answerImages[i]), shouldShowDistractorDelete),
                    }}
                  >
                    <RandomiseButton
                      variant={"link"}
                      onClick={() => {
                        if (questions) {
                          const newAnswers = generateDistractors(questions as MultichoiceQuestion[], answers, i);
                          if (newAnswers) {
                            const newQuestion: Partial<MultichoiceQuestion> = {
                              answers: newAnswers,
                            };
                            handleQuestionChanged(newQuestion);
                          }
                        }
                      }}
                      disabled={!enableRandomisation}
                    >
                      Randomise
                    </RandomiseButton>
                  </RandomiseButtonWrapper>
                </ToolTip>
              </CharacterLimitedInputControlWithError>
            </InputContainer>
          </SectionContainer>
        </div>
      );
    }

    return distractors;
  };

  return (
    <>
      <Form.Group>
        <Form.Label style={{ fontWeight: 500 }}>Question*</Form.Label>
        <HintText>
          <small>Enter the question with the text, an image, or both.</small>
        </HintText>
        <SectionContainer>
          <InputContainer>
            <CharacterLimitedInputControlWithError
              characterLimit={Character_Limit}
              placeholder="Question"
              initialInput={question}
              onInputUpdated={(newValue) => {
                const newQuestion: Partial<MultichoiceQuestion> = {
                  question: newValue,
                };
                handleQuestionChanged(newQuestion);
              }}
              canShowFeedback={showInvalidFeedback}
              errorFeedback={generateErrorFeedbackForQuestion(question, questionImage)}
              forceSingleLine={!Boolean(questionImage)}
            >
              {questionImage ? (
                <ImagePreview
                  imageUrl={questionImage}
                  onPressDelete={() => {
                    const newQuestion: Partial<MultichoiceQuestion> = {
                      questionImage: null,
                    };
                    handleQuestionChanged(newQuestion);
                  }}
                />
              ) : (
                <ImageSelector
                  imageUrl={questionImage}
                  onSelectImage={() => {
                    setOnSubmitImage(() => (imageUrl: string) => {
                      const newQuestion: Partial<MultichoiceQuestion> = {
                        questionImage: imageUrl,
                      };
                      handleQuestionChanged(newQuestion);
                    });
                    setSelectImageModalVisible(true);
                  }}
                />
              )}
            </CharacterLimitedInputControlWithError>
          </InputContainer>
        </SectionContainer>
      </Form.Group>

      <HorizontalLine />
      <Form.Group>
        <Form.Label
          style={{
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CorrectAnswerCircle />
          <Spacer size={5} />
          Correct Answer*
        </Form.Label>
        <HintText>
          <small>Enter the correct answer to the question.</small>
        </HintText>
        <SectionContainer>
          <InputContainer>
            <CharacterLimitedInputControlWithError
              characterLimit={Character_Limit}
              placeholder="Correct Answer"
              initialInput={answers[0] || ""}
              onInputUpdated={(newValue) => {
                const newAnswers = replaceOneElementInArray(answers, 0, newValue);
                const newQuestion: Partial<MultichoiceQuestion> = {
                  answers: newAnswers,
                };
                handleQuestionChanged(newQuestion);
              }}
              canShowFeedback={showInvalidFeedback}
              errorFeedback={generateErrorFeedbackForCorrectAnswer({
                answers,
                answerImages,
              })}
              forceSingleLine={!Boolean(answerImages[0])}
            >
              {answerImages[0] ? (
                <ImagePreview
                  imageUrl={answerImages[0]}
                  onPressDelete={() => {
                    const newQuestion: Partial<MultichoiceQuestion> = {
                      answerImages: replaceOneElementInArray(answerImages, 0, null),
                    };
                    handleQuestionChanged(newQuestion);
                  }}
                />
              ) : (
                <ImageSelector
                  imageUrl={answerImages[0]}
                  onSelectImage={() => {
                    setOnSubmitImage(() => (imageUrl: string) => {
                      const newQuestion: Partial<MultichoiceQuestion> = {
                        answerImages: replaceOneElementInArray(answerImages, 0, imageUrl),
                      };
                      handleQuestionChanged(newQuestion);
                    });
                    setSelectImageModalVisible(true);
                  }}
                  style={{ height: "100%" }}
                />
              )}
            </CharacterLimitedInputControlWithError>
          </InputContainer>
        </SectionContainer>
      </Form.Group>
      <HorizontalLine />
      <Form.Group>
        <Row>
          <DistractorTitle>Distractors (Incorrect Answers)</DistractorTitle>
          <ToolTip tooltip="Replace or add images to all distractors">
            <FlexButton
              variant={"link"}
              onClick={() => {
                randomiseAllDistractorImages();
              }}
              disabled={randomisingAnswerImages || questionsLoading}
            >
              <div style={{ flex: 1, textAlign: "right" }}>
                Pick {distractorCount} {pluralize("random image", distractorCount)}
              </div>
            </FlexButton>
          </ToolTip>
          {(randomisingAnswerImages || questionsLoading) && <Spinner />}
          <Row style={{ alignItems: "center" }}>
            <SecondaryText
              style={{
                color: distractorCount === maxDistractorCount ? colors.brandOrange : colors.dark,
              }}
            >{`${distractorCount}/${maxDistractorCount}`}</SecondaryText>
          </Row>
        </Row>
        <HintText>
          <small>
            Distractors can be randomised
            <b> using the correct answers from other questions in this question set.</b>
          </small>
        </HintText>
        <Column style={{ gap: "25px" }}>{displayDistractorFields().map((x) => x)}</Column>
        {distractorCount < maxDistractorCount && (
          <ToolTip
            tooltip="Add text or an image to the previous distractor to add another"
            enabled={!enableDistractorButton}
          >
            <CreateButtonWrapper>
              <CreateButton onClick={() => setDistractorCount(distractorCount + 1)} disabled={!enableDistractorButton}>
                Add New Distractor
              </CreateButton>
            </CreateButtonWrapper>
          </ToolTip>
        )}
      </Form.Group>

      <SelectImageModal {...selectImageModalProps} excludeFromRandom={answerImages} />
    </>
  );
};

const StyledDeleteButtonContainer = styled(Column)`
  min-width: 2em;
  height: 100%;
  justify-content: center;
`;

const StyledDeleteButton = styled(DeleteButton)`
  border-radius: 0px,
  border-color: ${colors.darkText},
`;

const CreateButtonWrapper = styled.div`
  width: 18em;
  height: 2.5em;
`;

const RandomiseButton = styled(Button)`
  height: 100%;
`;

const RandomiseButtonWrapper = styled.div`
  height: 35px;
  position: absolute;
`;

const HintText = styled.p`
  margin-bottom: var(--sp-2);
  color: ${colors.secondary};
`;
