import { Center } from "../center";
import { FC } from "types";
import { Spinner as BootstrapSpinner } from "react-bootstrap";
import classNames from "classnames";

const Spinner: FC = ({ style, className, ...rest }) => (
  <Center
    className={classNames(className, "active-spinner")}
    style={{ flex: 1, width: "100%", height: "100%", ...style }}
    {...rest}
  >
    <BootstrapSpinner animation="border" />
  </Center>
);

export { Spinner };
