import { NewsItem } from "types/NewsItem";
import { api } from "../api";
import { NetworkError } from "api/networkError";

const getNewsItems = async (token: string): Promise<NewsItem[]> => {
  const response = await api.get(`/news`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data) {
    return response.data;
  } else {
    throw new NetworkError();
  }
};

export { getNewsItems };
