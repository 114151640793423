import { slugs } from "textConstants";
import { Names } from "textConstants/main";
import { Tour } from "types";
import { disableElement, enableElement, runOnElements } from "utils/tourUtils";

const createQuestionSet: Tour = {
  id: "create question set",
  name: "Create a question set",
  startUrl: `/${slugs.teacher}/${slugs.questions}`,
  runAutomatically: (location) =>
    location.pathname.startsWith(`/${slugs.teacher}/${slugs.questions}`) &&
    !location.pathname.includes(slugs.new) &&
    !location.pathname.includes(slugs.edit),
  steps: [
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".create-question-set-button")),
      isEmpty: true,
    },
    {
      target: ".create-question-set-button",
      content: (
        <p>
          Create a question set by <span className="text-primary">clicking the + button</span>.
        </p>
      ),
      autoProgressCheck: (location) => location.pathname.includes("/question-set/new"),
      hideBack: true,
      hideNext: true,
    },
    {
      target: ".create-question-set-modal",
      content: (
        <p>
          Fill out the details and <span className="text-primary">press "Confirm"</span> when you're ready.
        </p>
      ),
      autoProgressCheck: (location) =>
        location.pathname.startsWith(`/${slugs.teacher}/${slugs.questions}`) &&
        !location.pathname.includes(`/${slugs.questionSet}/${slugs.new}`),
      hideBack: true,
      hideNext: true,
      onStart: () => {
        runOnElements(
          ".create-question-set-modal .btn-cancel, .create-question-set-modal .dropdown-toggle, .create-question-set-modal .dropdown",
          disableElement
        );
      },
      spotlightPadding: -10,
    },
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".add-question .inputs")),
      isEmpty: true,
    },
    {
      target: ".add-question div",
      content: `Create a question by filling out the details - question, correct answer and 1-3 distractors (incorrect answers).`,
      hideBack: true,
      placement: "left",
      onStart: () => runOnElements(".add-question div button", disableElement),
    },
    {
      target: ".add-image-button",
      content: `You can add selected images to your questions by pressing this button. Let's skip this for now.`,
      disableClickthrough: true,
      onStart: () => runOnElements(".add-question div button", enableElement),
    },
    {
      target: ".add-question .add-button",
      content: `Click "Add Question" when you are finished creating a question.`,
    },
    {
      target: ".question-list",
      content: `Your question set's questions are located here. You can edit, reorder and delete them.`,
      disableClickthrough: true,
    },
    {
      target: ".unsaved-changes-container",
      content: `Once you've made some changes, you can click "Save" to approve them, or "Undo" to revert them. We'll skip this for now.`,
      disableClickthrough: true,
    },
    {
      target: "#test-action-button",
      content: `Once you have some questions saved, you'll be able to click here to play a quick game with this Question Set`,
      disableClickthrough: true,
    },
    {
      target: "#session-action-button",
      content: `You'll also be able to click here to create a ${Names.session} with this Question Set`,
      disableClickthrough: true,
    },
  ],
};

export const QuestionTours: Tour[] = [createQuestionSet];
