import { Grouping } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface FetchClassGroupingsParams {
  token: string;
  classId: string;
}

const fetchClassGroupings = async ({ token, classId }: FetchClassGroupingsParams): Promise<Grouping[]> => {
  const response = await api.get(`/classes/${classId}/groupings`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const class_ = response.data;

  if (class_) {
    return class_;
  } else {
    throw new NetworkError();
  }
};

export { fetchClassGroupings };
