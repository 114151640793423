import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ClassActions } from "./classListSlice";
import { updateClass as updateClassApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";

export interface Params {
  classId: string;
  className: string;
}

const dispatchUpdateClass = ({ classId, className }: Params, actions: ClassActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedClass = await updateClassApi({ token, classId, className });

    dispatch(actions.addSingle({ classId: updatedClass.id, class: updatedClass }));
    return updatedClass;
  }, dispatch);

export { dispatchUpdateClass };
