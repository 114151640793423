import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { createReviewSubmission as createReviewSubmissionApi } from "api";

const dispatchCreateReviewSubmission = (
  questionSetId: string,
  actions: QuestionSetActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) => 
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const reviewSubmission = await createReviewSubmissionApi({questionSetId, comment: ""}, token)!;
    dispatch(
      actions.updateSingle({
        id: reviewSubmission.questionSet.id,
        questionSet: {
          reviewSubmissions: [reviewSubmission]
        }
      })
    );
    return reviewSubmission;
  }, dispatch);

export { dispatchCreateReviewSubmission };
