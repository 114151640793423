import { useAppDispatch } from "app/hooks";
import { useQueryParams } from "hooks";
import { Column, Spinner } from "primitives";
import { useEffect } from "react";
import styled from "styled-components";
import { FC, StringMap } from "types";
import { logout } from "slices";

interface LogoutParams extends StringMap {
  loc: string;
  search: string;
  dontClear: string;
}

export const Logout: FC = () => {
  const {
    params: { loc, search, dontClear },
  } = useQueryParams<LogoutParams>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout(loc, search, Boolean(dontClear)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OuterContainer>
      <p>Logging Out...</p>
      <Spinner />
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
