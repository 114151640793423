import { api } from "../api";
import { NetworkError } from "../networkError";

interface Params {
  token: string;
  classId: string;
  html: string;
}

const createPrintableCards = async ({ token, classId, html }: Params): Promise<string> => {
  const response = await api.post(`/classes/${classId}/printablecards`, html, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "text/plain" },
  });
  const base64PDFContent = response.data;

  if (base64PDFContent) {
    return base64PDFContent;
  } else {
    throw new NetworkError();
  }
};

export { createPrintableCards };
