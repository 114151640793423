import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { actionHooksToActions, QuestionSet, UserAction, UserActionHook } from "types";
import { useLogin } from "./redux";
import {
  favouriteQuestionSetNew,
  duplicateQuestionSetNew,
  archiveQuestionSetNew,
  blurApp,
  unBlurApp,
  resetQuestionSets,
  manuallySetQuestionSetCount,
} from "slices";
import { useAppDispatch } from "app/hooks";
import { useQueryParams, useTutorialState } from "hooks";
import {
  questionSetCanBeEdited as canBeEdited,
  questionSetIsArchived as isArchived,
  questionSetIsArchived,
  questionSetOwnedByLoggedInUser,
} from "utils";
import { actionConstants, breadcrumbConstants, Names, pathUntil, paths, slugs } from "textConstants";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { AddIcon, SessionPlayIcon } from "icons";
import { History } from "history";

export type QuestionSetAction = UserAction;

export type QuestionSetActionHook = UserActionHook<QuestionSet>;

export const urlUntilQuestionId = (path: string, questionSet?: QuestionSet, params?: {}) => {
  return generatePath(pathUntil(path, slugs.questionSetId, `${path}/${slugs.questionSetId}`), {
    ...params,
    questionSetId: questionSet!.id,
  });
};

const goToProtectedUrl = (history: History<unknown>, url: string, search?: string) => {
  if (window.self !== window.top) {
    window.parent.location.href = `${window.location.origin}${url}${search || ""}`;
    return;
  }
  history.push({
    pathname: url,
    search,
  });
};

const useToggleFavouriteAction: QuestionSetActionHook = (questionSet) => {
  const dispatch = useAppDispatch();
  const { totals } = useSelector((state: RootState) => state.questionSets);

  return [
    {
      id: "toggleFavourite",
      ...(questionSet.isFavourite ? actionConstants.unFavourite : actionConstants.favourite),
      action: async () => {
        await dispatch(favouriteQuestionSetNew(questionSet.id, !questionSet.isFavourite));
        if (totals && totals["favourited"] !== undefined) {
          dispatch(
            manuallySetQuestionSetCount({
              id: "favourited",
              total: totals["favourited"] + (!questionSet.isFavourite ? 1 : -1),
            })
          );
        }
      },
      requiresLogin: true,
      priority: 9,
      isAvailable: () => !questionSetIsArchived(questionSet),
    },
  ];
};

const useDuplicateAction: QuestionSetActionHook = (questionSet) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { path, params } = useRouteMatch();

  return [
    {
      id: "duplicate",
      ...actionConstants.duplicate,
      action: async () => {
        const newQuestionSet = await dispatch(duplicateQuestionSetNew(questionSet.id));
        dispatch(resetQuestionSets());
        if (newQuestionSet?.id === undefined) return;
        if (path.includes(slugs.public)) {
          goToProtectedUrl(history, paths.teacher.questions.questionSets.edit.generate(newQuestionSet?.id));
          return;
        }
        const newPath = urlUntilQuestionId(path, newQuestionSet, params);
        history.push(`${newPath}/${slugs.edit}`);
      },
      requiresLogin: true,
      priority: 6,
      isAvailable: () => !questionSetIsArchived(questionSet),
    },
  ];
};

const useShareAction: QuestionSetActionHook = (questionSet) => {
  const history = useHistory();
  const { search } = useLocation();
  const { path, params } = useRouteMatch();

  return [
    {
      id: "share",
      name: "Share",
      action: async () => {
        const newPath = urlUntilQuestionId(path, questionSet, params);
        history.push({ pathname: `${newPath}/${slugs.share}`, search });
      },
      icon: actionConstants.share.icon,
      priority: 8,
      isAvailable: () => !questionSetIsArchived(questionSet),
    },
  ];
};

const useAddToNewSessionAction: QuestionSetActionHook = (questionSet) => {
  const history = useHistory();
  const { search } = useLocation();

  return [
    {
      id: "session",
      name: `Add to a new ${Names.customGame}`,
      action: async () => {
        goToProtectedUrl(history, paths.teacher.questions.questionSets.addToGame.generate(questionSet.id), search);
      },
      isEnabled: () => questionSet.numQuestions > 0,
      requiresLogin: true,
      icon: AddIcon,
      priority: 2,
      isAvailable: () => !questionSetIsArchived(questionSet),
    },
  ];
};

const useTestAction: QuestionSetActionHook = (questionSet) => {
  const history = useHistory();

  return [
    {
      id: "test",
      name: `Play Now`,
      isEnabled: () => questionSet.numQuestions > 0,
      requiresLogin: true,
      action: async () => {
        goToProtectedUrl(history, paths.teacher.myGames.miniGame.new.path, `?questionSetId=${questionSet.id}`);
      },
      icon: SessionPlayIcon,
      iconColor: breadcrumbConstants.myGames.accentColor,
      priority: 4,
      isAvailable: () => !questionSetIsArchived(questionSet),
    },
  ];
};

const useArchiveRestoreActions: QuestionSetActionHook = (questionSet) => {
  const dispatch = useAppDispatch();
  const { updateParams } = useQueryParams();
  const { userInfo } = useLogin();
  const { totals } = useSelector((state: RootState) => state.questionSets);

  return [
    {
      id: "archive",
      ...(isArchived(questionSet) ? actionConstants.restore : actionConstants.archive),
      isAvailable: () => !questionSet.parentQuestionSet && questionSetOwnedByLoggedInUser(questionSet, userInfo),
      action: async () => {
        dispatch(blurApp());
        let archived = isArchived(questionSet);
        await dispatch(archiveQuestionSetNew(questionSet.id, !archived));
        dispatch(unBlurApp());
        updateParams({ tab: archived ? "mySets" : "archived" });

        if (totals && totals["archived"] !== undefined && totals["mySets"] !== undefined) {
          dispatch(manuallySetQuestionSetCount({ id: "archived", total: totals["archived"] + (!archived ? 1 : -1) }));
          dispatch(manuallySetQuestionSetCount({ id: "mySets", total: totals["mySets"] + (archived ? 1 : -1) }));
        }
      },
      priority: 10,
    },
  ];
};

const useEditAction: QuestionSetActionHook = (questionSet) => {
  const history = useHistory();
  const { userInfo } = useLogin();
  const { path, params } = useRouteMatch();

  return [
    {
      id: "edit",
      name: "Edit",
      isAvailable: () => canBeEdited(questionSet, userInfo) && !isArchived(questionSet),
      action: async () => {
        if (path.includes(`${slugs.miniGame}`)) {
          const newPath = urlUntilQuestionId(path, questionSet, params);
          history.push(`${newPath}/${slugs.edit}`);
        } else {
          history.push(paths.teacher.questions.questionSets.edit.generate(questionSet.id));
        }
      },
      icon: actionConstants.edit.icon,
      requiresLogin: true,
      priority: 6,
    },
  ];
};

export const usePreviewAction: QuestionSetActionHook = (questionSet) => {
  const history = useHistory();
  const { path, params } = useRouteMatch();
  const { search } = useLocation();
  const { isPlayingTutorial } = useTutorialState();

  return [
    {
      id: "preview",
      name: "Preview",
      action: async () => {
        const newPath = urlUntilQuestionId(path, questionSet, params);
        history.push({ pathname: newPath, search });
      },
      icon: actionConstants.preview.icon,
      priority: 3,
      isAvailable: () => !questionSetIsArchived(questionSet) && !isPlayingTutorial,
    },
  ];
};

const useFeatureActions: QuestionSetActionHook = () => {
  return [];
};

const allQuestionSetActionHooks = [
  useShareAction,
  useEditAction,
  useDuplicateAction,
  useFeatureActions,
  useArchiveRestoreActions,
  useToggleFavouriteAction,
  useTestAction,
  useAddToNewSessionAction,
];

const useQuestionSetActions = (
  questionSet: QuestionSet,
  actionHooks?: Array<QuestionSetActionHook>
): { [id: string]: QuestionSetAction } => actionHooksToActions(questionSet, actionHooks || allQuestionSetActionHooks);

export {
  useDuplicateAction as useDuplicateQuestionSetAction,
  useArchiveRestoreActions as useArchivingQuestionSetActions,
  useEditAction as useEditQuestionSetAction,
  useFeatureActions as useFeatureQuestionSetActions,
  useToggleFavouriteAction as useToggleFavouriteQuestionSetActions,
  useTestAction as useTestQuestionSetActions,
  useAddToNewSessionAction as useAddQuestionSetToNewSessionAction,
  useShareAction as useQuestionSetShareAction,
  allQuestionSetActionHooks,
  useQuestionSetActions,
};
