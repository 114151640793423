import { slugs } from "textConstants";
import { Names } from "textConstants/main";
import { Tour } from "types";
import { disableElement, enableElement, runOnElements } from "utils/tourUtils";
import { TourStepContainer } from "./tourComponents";

const comeBackLater: Tour = {
  id: "no progress",
  name: "No progress yet",
  startUrl: `/${slugs.teacher}/${slugs.progress}`,
  hideFromHelpMenu: true,
  runAutomatically: (location, state) =>
    location.pathname.startsWith(`/${slugs.teacher}/${slugs.progress}/${slugs.class}`) &&
    Boolean(state.sessionList && state.sessionList.data) &&
    Object.keys(state.sessionList.data || {}).length === 0 &&
    Object.keys(state.classList.data || {}).length !== 0,
  steps: [
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".review-session-sidebar")),
      isEmpty: true,
    },
    {
      target: ".review-session-sidebar",
      title: "No Progress Yet!",
      content: (
        <TourStepContainer>
          <p className="mb-3">You don't have any {Names.Sessions} to review!</p>
          <p>
            Try checking other times for ongoing/completed {Names.sessions} by clicking "Filter {Names.Sessions}".
          </p>
        </TourStepContainer>
      ),
      disableClickthrough: true,
      hideBack: true,
    },
  ],
};

const studentProgress: Tour = {
  id: "progress",
  name: "Your Students' Results",
  startUrl: `/${slugs.teacher}/${slugs.progress}/${slugs.class}`,
  runAutomatically: (location, state) =>
    location.pathname.startsWith(`/${slugs.teacher}/${slugs.progress}`) &&
    Boolean(state.sessionList && state.sessionList.data) &&
    Object.keys(state.sessionList.data || {}).length > 0,
  disableInHelpMenu: (location, state) =>
    !state.classList || !state.classList.data || Object.values(state.classList.data).length === 0,
  steps: [
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".review-session-sidebar")),
      isEmpty: true,
    },
    {
      target: ".review-session-sidebar",
      title: "View your students' Results!",
      onStart: () => runOnElements(".btn-primary", disableElement),
      content: `Your students' results are rolling in! Check on their progress by clicking an ongoing/past ${Names.session} in this list.`,
      autoProgressCheck: (location) => location.pathname.includes("/session"),
      hideBack: true,
      placement: "right",
    },
    {
      target: "#empty-tour-target",
      onStart: () => runOnElements(".btn-primary", enableElement),
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".game-selector")),
      isEmpty: true,
    },
    {
      target: ".game-selector",
      title: "View your students' Results!",
      content: `You can change which ${Names.sessions} results are shown by checking/unchecking them here.`,
      hideBack: true,
    },
    {
      target: ".student-grid",
      title: "View your students' Result!",
      content: (
        <p>
          Here you can see a list of all your students and their current overall score.{" "}
          <span className="text-primary">Click a student card</span> to show an individual's results.
        </p>
      ),
      autoProgressCheck: (location) => location.pathname.includes("/student"),
      hideNext: true,
      hideBack: true,
    },
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".student-record")),
      isEmpty: true,
    },
    {
      target: ".student-record",
      title: "View your students' Results!",
      spotlightPadding: -5,
      content: "Here is the student's results for the selected games.",
      placement: "auto",
      offset: -100,
    },
  ],
};

export const ProgressTours: Tour[] = [comeBackLater, studentProgress];
