import { useAppSelector } from "app/hooks";
import { QuestionSet } from "types";

export const useQuestionSets = (questionSetIds: string[]) => {
  return useAppSelector((state) => {
    if (state.questionSets.data) {
      let fetched: QuestionSet[] = [];
      questionSetIds.forEach((questionSetId) => {
        fetched.push(state.questionSets.data![questionSetId]);
      });
      return fetched;
    } else {
      return [];
    }
  });
};
