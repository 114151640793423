import styled from "styled-components";
import { colors } from "styles";

const InfoText = styled.p`
  color: ${colors.info};
  margin: 0;
  font-size: 12px;
`;

export { InfoText };
