import { api } from "../api";
import { CustomGame } from "types";
import { NetworkError } from "api/networkError";
import { ProcessCustomGame, UnprocessedCustomGame } from "../deserialisation";

interface BulkUpdateCustomGameParams {
  token: string;
  customGames: { id: string & Partial<CustomGame> }[];
}

export const bulkUpdateCustomGames = async ({
  token,
  customGames,
}: BulkUpdateCustomGameParams): Promise<CustomGame[]> => {
  const response = await api.put(
    `/customgames/bulkupdate`,
    {
      customGames,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  const updatedCustomGames = response.data.map((unprocessedCustomGame: UnprocessedCustomGame) =>
    ProcessCustomGame(unprocessedCustomGame)
  );

  if (updatedCustomGames) {
    return updatedCustomGames;
  } else {
    throw new NetworkError();
  }
};
