import { TutorialsProvider } from "components";
import { RootBreadcrumb } from "components/breadcrumb";
import { CustomGameEditor } from "pages/customGames";
import { ManageSessionPage } from "pages/my-games/lessonPlans/manageSessionPage/ManageSessionPage";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { breadcrumbConstants, slugs } from "textConstants";
import { MyGameTours } from "textConstants/tours";
import { ArticleProvider } from "components/articles";
import { LessonPlans } from "./lessonPlans";

const PlayRoutes = () => {
  const { path } = useRouteMatch();
  const { search } = useLocation();
  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.myGames.title}
        description={breadcrumbConstants.myGames.breadcrumbDescription}
        icon={breadcrumbConstants.myGames.icon}
      />
      <ArticleProvider />
      <TutorialsProvider section="play" tours={MyGameTours} />

      <Switch>
        <Route path={`${path}/${slugs.miniGame}/${slugs.new}`}>
          <CustomGameEditor />
        </Route>
        <Route path={`${path}/${slugs.miniGame}/${slugs.miniGameId}`}>
          <CustomGameEditor />
        </Route>
        <Route
          path={`${path}/${slugs.class}/${slugs.classId}/${slugs.session}/${slugs.sessionId}/${slugs.edit}`}
          component={ManageSessionPage}
        />
        <Route path={`${path}/${slugs.class}/${slugs.classId}`} component={LessonPlans} />
        <Route path={`${path}/${slugs.class}`} component={LessonPlans} />
        <Route path="/">
          <Redirect to={`${path}/${slugs.class}${search}`} />
        </Route>
      </Switch>
    </>
  );
};

export { PlayRoutes };
