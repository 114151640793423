import { api } from "api/api";
import { NetworkError } from "api/networkError";

export const deleteSubscription = async (token: string) => {
  const response = await api.delete(`/payments/subscription`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response?.data) {
    throw new NetworkError();
  }
};
