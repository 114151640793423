import dayjs from "dayjs";
import { isEmpty, sortBy, reverse, sum } from "lodash";
import { Class, getSessionType, Session, SessionType, Tag } from "types";
import { getFilteredSelectedSubjectTagIds, getTotalCustomGameQuestionCount, parseDateString } from "utils";

const isValidSession = (session: Session) => !isEmpty(session.customGames) && session.customGames!.length > 0;

const getSessionsByType = (sessions: Session[], sessionType: SessionType) => {
  const unsortedSessions = sessions.filter((session) => getSessionType(session) === sessionType);
  if (sessionType === SessionType.Unscheduled) return unsortedSessions;
  const sortedSessions = sortBy(unsortedSessions, (session) => {
    switch (sessionType) {
      case SessionType.Current:
        return session.endTime;
      case SessionType.Upcoming:
        return session.startTime;
      case SessionType.Past:
        return session.endTime;
    }
  });

  if (sessionType === SessionType.Past) return reverse(sortedSessions);
  return sortedSessions;
};

const getRelevantDateForSession = (session: Session, sessionType: SessionType) => {
  if (sessionType === SessionType.Current) {
    return session.endTime ? parseDateString(session.endTime) : null;
  } else if (sessionType === SessionType.Upcoming) {
    return session.startTime ? parseDateString(session.startTime) : null;
  } else {
    return session.endTime ? parseDateString(session.endTime) : null;
  }
};

const getTimePrefixForSession = (sessionType: SessionType) => {
  const prefixes = {
    [SessionType.Current]: "Ends",
    [SessionType.Upcoming]: "Starts",
    [SessionType.Past]: "Ended",
    [SessionType.Unscheduled]: "Starts",
    [SessionType.Started]: "Started",
    [SessionType.Ended]: "Ended",
    [SessionType.NotStarted]: "Starts",
    [SessionType.NotEnded]: "Ends",
  };

  return prefixes[sessionType];
};

const humaniseDateFromNowForSessionType = (date: Date | null, sessionType: SessionType) => {
  if (date) {
    const dayDate = dayjs(date);
    const now = dayjs();
    const duration = dayjs.duration(dayDate.diff(now));
    return `${getTimePrefixForSession(sessionType)} ${duration.humanize(true)}`;
  } else {
    return "No set time";
  }
};

const getTotalSessionQuestionCount = (session: Session) => {
  let total = 0;
  if (session.customGames) {
    for (const customGame of session.customGames) {
      total += getTotalCustomGameQuestionCount(customGame);
    }
  }

  return total;
};

const getSessionTypeForTab = (tab: string) => {
  switch (tab) {
    case "current":
      return SessionType.Current;
    case "upcoming":
      return SessionType.Upcoming;
    case "past":
      return SessionType.Past;
    case "unscheduled":
      return SessionType.Unscheduled;
    default:
      return SessionType.Current;
  }
};

const getSessionTypeStringForTab = (tab: string) => {
  let sessionType = getSessionTypeForTab(tab);
  return SessionType[sessionType];
};

const getSessionsForTab = (sessions: Session[], tab: string) => {
  const sessionType = getSessionTypeForTab(tab);
  return getSessionsByType(sessions, sessionType);
};

const doesTabUseInfiniteScroll = (tab: string) => {
  // Currently only using infinite scroll with the Past session type
  const sessionType = getSessionTypeForTab(tab);
  return sessionType === SessionType.Past;
};

const getPreviewTotalQuestions = (sessions?: (Session | null)[]) => {
  return sum(sessions?.map((session) => (session ? session.totalQuestions : 0))) | 0;
};

const getPreviewResultsForSession = (session: Session) => {
  return {
    participationCount: session.participationCount || 0,
    totalScore: session.totalScore || 0,
  };
};

const getPreviewResultsForSessions = (selectedClass?: Class, sessions?: Session[]) => {
  const totalPossibleScore =
    selectedClass && sessions && selectedClass.students
      ? selectedClass.students.length * sum(sessions?.map((session) => session.customGames?.length))
      : 0;

  const totalPossibleParticipation = totalPossibleScore;
  let participationCount = 0;
  let totalScore = 0;

  sessions?.forEach((session) => {
    const result = getPreviewResultsForSession(session);
    participationCount += result.participationCount;
    totalScore += result.totalScore;
  });

  return {
    participationCount: participationCount,
    totalScore: totalScore,
    totalPossibleScore: totalPossibleScore,
    totalPossibleParticipation: totalPossibleParticipation,
  };
};

const sessionContainTags = (session: Session, selectedTags: { tag: Tag; selected: boolean }[]) => {
  const filteredSelectedSubjectTagIds = getFilteredSelectedSubjectTagIds(selectedTags, true);

  if (session.tags) {
    const sessionTagIds = session.tags.map((tag) => tag.id);
    for (let i = 0; i < sessionTagIds.length; i++) {
      if (filteredSelectedSubjectTagIds.includes(sessionTagIds[i])) {
        return true;
      }
    }
  }
  return false;
};

const getFilteredSessionList = (sessions: Session[] | undefined, selectedTags: { tag: Tag; selected: boolean }[]) => {
  const filteredSelectedSubjectTagIds = getFilteredSelectedSubjectTagIds(selectedTags, true);

  return (
    (filteredSelectedSubjectTagIds && filteredSelectedSubjectTagIds.length > 0
      ? sessions?.filter((session) => {
          return sessionContainTags(session, selectedTags);
        })
      : sessions) || []
  );
};

const getSelectedFilteredSessionList = (
  selectedSessionIds: string[] | undefined,
  sessions: Session[] | undefined,
  selectedTags: { tag: Tag; selected: boolean }[]
) => {
  return getFilteredSessionList(sessions, selectedTags).filter((session) => {
    return selectedSessionIds?.includes(session.id);
  });
};

export {
  getSessionsByType,
  getRelevantDateForSession,
  getTimePrefixForSession,
  humaniseDateFromNowForSessionType,
  getTotalSessionQuestionCount,
  getSessionsForTab,
  getSessionTypeForTab,
  getSessionTypeStringForTab,
  doesTabUseInfiniteScroll,
  isValidSession,
  getPreviewTotalQuestions,
  getPreviewResultsForSession,
  getPreviewResultsForSessions,
  sessionContainTags,
  getFilteredSessionList,
  getSelectedFilteredSessionList,
};
