import { Column } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { Dropdown } from "react-bootstrap";
import { PrimaryButton } from "primitives/button";
import { FC, forwardRef } from "react";

interface Props {
  className: string;
  id: string;
  portalOpen: boolean;
  setPortalOpen: (state: boolean) => void;
  toggleShowing: boolean;
  toggleChildren: JSX.Element;
  dropdownMenuTitle: string;
  dropdownMenuChildren: JSX.Element;
}

const BreadcrumbMenu: FC<Props> = ({
  className,
  id,
  portalOpen,
  setPortalOpen,
  toggleShowing,
  toggleChildren,
  dropdownMenuTitle,
  dropdownMenuChildren,
}) => {
  const CustomToggle: FC<{ onClick: (e: any) => void }> = forwardRef(({ onClick }, ref) => (
    <div ref={ref as any} onClick={onClick} style={{ height: "100%", cursor: "pointer", padding: "0em 0.5em" }}>
      <MenuButton>{toggleChildren}</MenuButton>
    </div>
  ));

  return (
    <Container className={className}>
      {portalOpen && (
        <DropDownContainer>
          <ButtonOutline />
        </DropDownContainer>
      )}
      <Dropdown
        onToggle={(show) => {
          setPortalOpen(show);
        }}
        show={portalOpen}
      >
        {toggleShowing && <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />}
        <StyledDropdownMenu renderOnMount={true} className="borderless-dropdown" id={id}>
          <MenuHeader>{dropdownMenuTitle}</MenuHeader>
          {dropdownMenuChildren}
        </StyledDropdownMenu>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  align-self: center;
  padding: 0px 10px;
  position: relative;
`;

const DropDownContainer = styled(Column)`
  position: absolute;
  top: -4.5px;
  right: 13.5px;
  z-index: 0;
  align-items: flex-end;
`;

const ButtonOutline = styled.div`
  width: 45px;
  height: 45px;
  background-color: ${colors.nameLabel};
  border-radius: 25px 25px 0px 0px;
`;

const MenuHeader = styled.div`
  height: 30px;
  background-color: ${colors.nameLabel};
  color: ${colors.white};
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  margin: -2px -1px 0px -1px;
  padding: 3px;
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  min-width: 200px;
  max-width: 200px;
  transform: translate(-151px, 40px) !important;
`;

const StyledHelpMenuItem = styled(Dropdown.Item)`
  text-align: center;
  font-size: 14px;
  height: 35px;
`;

const MenuButton = styled(PrimaryButton)`
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  background-color: ${colors.primaryActive};
  font-size: 24px;
  position: relative;
  z-index: 1;
`;

export { BreadcrumbMenu, StyledHelpMenuItem };
