import { RootState } from "app/rootReducer";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { updateQuestions } from "api";
import { Dispatch } from "react";
import { QuestionSet } from "types";

export interface UpdateQuestionsParams {
  draftQuestionSet: QuestionSet;
}

const dispatchUpdateQuestions = ({ draftQuestionSet }: UpdateQuestionsParams, actions: QuestionSetActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const questionsDetails = draftQuestionSet;

    const { id: questionSetId } = questionsDetails;
    const questions = questionsDetails.questions!;

    const questionsToBeUpdated = questions.filter((question) => question.toBeUpdated && !question.toBeCreated);

    const questionsToBeCreated = questions.filter((question) => question.toBeCreated && !question.toBeDeleted);

    const questionsToBeDeleted = questions.filter(
      (question) => question.toBeDeleted && !question.toBeCreated
    ) as Array<{ id: string }>;

    await updateQuestions(
      {
        questionSetId,
        questionsToBeCreated,
        questionsToBeUpdated,
        questionsToBeDeleted,
      },
      token
    );

    dispatch(
      actions.addSingle({
        id: questionsDetails.id,
        questionSet: questionsDetails,
      })
    );
  }, dispatch);

export { dispatchUpdateQuestions };
