import { createSlice, PayloadAction, current, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { QuestionSetListDetails } from "types";
import { QuestionSet } from "types";
import { dispatchFetchQuestionSetList, FetchQuestionSetListParams } from "./fetchQuestionSets";
import { dispatchFetchQuestionSet } from "./fetchQuestionSet";
import { dispatchCreateQuestionSet, CreateQuestionSetParams } from "./createQuestionSet";
import { dispatchUpdateQuestionSet, UpdateQuestionSetParams } from "./updateQuestionSet";
import { assign, has } from "lodash";
import { dispatchFavouriteQuestionSet } from "./favouriteQuestionSet";
import { dispatchDuplicateQuestionSet } from "./duplicateQuestionSet";
import { dispatchArchiveQuestionSet } from "./archiveQuestionSet";
import { dispatchUpdateQuestions, UpdateQuestionsParams } from "./updateQuestions";
import { dispatchFeatureQuestionSet } from "./featureQuestionSet";
import { dispatchCreateReviewSubmission } from "./createReviewSubmission";
import { dispatchFetchQuestionSetListCount, FetchQuestionSetListCountParams } from "./fetchQuestionSetCount";
import { addSingleCustomGame } from "slices/customGameSlice/customGameSlice";

type QuestionSetsState = {
  data: { [id: string]: QuestionSet } | null;
  paginationData: {
    orderedIds: string[];
    total: number;
  };
  totals: { [id: string]: number } | null;
  lastRequestToken: string;
};

const initialState: QuestionSetsState = {
  data: null,
  totals: null,
  paginationData: {
    orderedIds: [],
    total: 0,
  },
  lastRequestToken: "",
};

// --- Slice Definition

const reducers = {
  reset(state: QuestionSetsState) {
    state.data = null;
    state.totals = null;
    state.paginationData = {
      orderedIds: [],
      total: 0,
    };
  },
  resetPageData(state: QuestionSetsState) {
    state.paginationData = {
      orderedIds: [],
      total: 0,
    };
  },
  setRequestToken(state: QuestionSetsState, action: PayloadAction<string>) {
    state.lastRequestToken = action.payload;
  },
  manuallySetQuestionSetCount(state: QuestionSetsState, action: PayloadAction<{ id: string; total: number }>) {
    if (!state.totals) {
      state.totals = { [action.payload.id]: action.payload.total };
    } else {
      let curStateTotals = current(state).totals;
      if (curStateTotals !== null && curStateTotals[action.payload.id] !== action.payload.total) {
        state.totals[action.payload.id] = action.payload.total;
      }
    }
  },
  updateSingle(state: QuestionSetsState, action: PayloadAction<{ id: string; questionSet: Partial<QuestionSet> }>) {
    if (state.data && state.data[action.payload.id]) {
      state.data[action.payload.id] = assign(state.data[action.payload.id], {
        ...state.data[action.payload.id],
        ...action.payload.questionSet,
      });
    }
  },
  addSingle(state: QuestionSetsState, action: PayloadAction<{ id: string; questionSet: QuestionSet }>) {
    if (state.data) {
      state.data[action.payload.id] = assign(state.data[action.payload.id], action.payload.questionSet);
    } else {
      state.data = {
        [action.payload.id]: action.payload.questionSet,
      };
    }
  },
  addMany(state: QuestionSetsState, action: PayloadAction<{ data: QuestionSetListDetails; requestToken: string }>) {
    if (state.lastRequestToken === action.payload.requestToken) {
      if (!state.data) {
        state.data = {};
      }
      for (const questionSet of action.payload.data.questionSets) {
        if (!(questionSet.id in state.data)) {
          state.data[questionSet.id] = questionSet;
        }
        if (!state.paginationData.orderedIds.includes(questionSet.id)) {
          state.paginationData.orderedIds.push(questionSet.id);
        }
      }
      state.paginationData.total = action.payload.data.total || 0;
    } else {
      console.warn(
        "[QuestionSetSlice:addMany] was fired after the request token was changed. If you didn't expect this, be sure to investigate the issue."
      );
    }
  },
  removeSingle(state: QuestionSetsState, action: PayloadAction<{ id: string }>) {
    if (state.data) {
      delete state.data[action.payload.id];
    }
    if (state.paginationData && state.paginationData.orderedIds) {
      const index = state.paginationData.orderedIds.findIndex((questionSetId) => questionSetId === action.payload.id);

      if (index > 0) {
        state.paginationData.orderedIds.splice(index, 1);
      }
    }
  },

  //
  //https://redux-toolkit.js.org/api/other-exports#current
  updateTotals(state: QuestionSetsState, action: PayloadAction<{ id: string; total: number }>) {
    if (!state.totals) {
      state.totals = { [action.payload.id]: action.payload.total };
    } else {
      let curStateTotals = current(state).totals;
      if (curStateTotals !== null && curStateTotals[action.payload.id] !== action.payload.total) {
        state.totals[action.payload.id] = action.payload.total;
      }
    }
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<QuestionSetsState>) => {
  builder.addCase(addSingleCustomGame, (state, action) => {
    action.payload.customGame.questionSets?.forEach((questionSet) => {
      if (!state.data) {
        state.data = { [questionSet.id]: questionSet };
      } else if (!has(state.data, questionSet.id)) {
        state.data[questionSet.id] = questionSet;
      }
    });
  });
};

const slice = createSlice<QuestionSetsState, typeof reducers>({
  name: "questionSets",
  initialState,
  reducers,
  extraReducers,
});

// --- Async Action Wrappers

const fetchQuestionSetList = (params: FetchQuestionSetListParams) =>
  dispatchFetchQuestionSetList(params, slice.actions);

const fetchQuestionSetListCount = (params: FetchQuestionSetListCountParams) =>
  dispatchFetchQuestionSetListCount(params, slice.actions);

const fetchQuestionSet = (id: string) => dispatchFetchQuestionSet(id, slice.actions);

const createQuestionSet = (params: CreateQuestionSetParams) => dispatchCreateQuestionSet(params, slice.actions);

const updateQuestionSet = (id: string, params: Partial<UpdateQuestionSetParams>) =>
  dispatchUpdateQuestionSet(id, params, slice.actions);

const favouriteQuestionSet = (id: string, isFavourite: boolean) =>
  dispatchFavouriteQuestionSet(id, isFavourite, slice.actions);

const duplicateQuestionSet = (id: string) => dispatchDuplicateQuestionSet(id, slice.actions);

const archiveQuestionSet = (id: string, isArchived: boolean) =>
  dispatchArchiveQuestionSet(id, isArchived, slice.actions);

const updateQuestions = (updateQuestionsData: UpdateQuestionsParams) =>
  dispatchUpdateQuestions(updateQuestionsData, slice.actions);

const featureQuestionSet = (id: string, isFeatured: boolean) =>
  dispatchFeatureQuestionSet(isFeatured, id, slice.actions);

const createReviewSubmission = (id: string) => dispatchCreateReviewSubmission(id, slice.actions);

// --- Exports

const questionSetsReducer = slice.reducer;
const {
  reset: resetQuestionSets,
  resetPageData: resetQuestionSetPageData,
  manuallySetQuestionSetCount,
} = slice.actions;

export type QuestionSetActions = typeof slice.actions;

export {
  questionSetsReducer,
  // Async Actions
  fetchQuestionSetList as fetchQuestionSetListNew,
  fetchQuestionSet as fetchQuestionSetNew,
  createQuestionSet as createQuestionSetNew,
  updateQuestionSet as updateQuestionSetNew,
  favouriteQuestionSet as favouriteQuestionSetNew,
  duplicateQuestionSet as duplicateQuestionSetNew,
  archiveQuestionSet as archiveQuestionSetNew,
  updateQuestions,
  featureQuestionSet as featureQuestionSetNew,
  createReviewSubmission,
  fetchQuestionSetListCount,
  // Sync Actions
  resetQuestionSets,
  resetQuestionSetPageData,
  manuallySetQuestionSetCount,
};
