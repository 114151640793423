// This function is O(n log(n)). Be careful using in loops when arrays are large.
const arraysHaveSameElements = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length !== arr2.length) return false;

  const [sorted1, sorted2] = [[...arr1].sort(), [...arr2].sort()];

  for (let i = 0; i < sorted1.length; ++i) {
    if (sorted1[i] !== sorted2[i]) return false;
  }
  return true;
};

export { arraysHaveSameElements };
