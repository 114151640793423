import { RootBreadcrumb } from "components/breadcrumb";
import { useBreadcrumbs } from "hooks";
import { Center, Column, Spacer } from "primitives";
import { useEffect } from "react";
import { generatePath, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router";
import styled from "styled-components";
import { breadcrumbConstants } from "textConstants";
import { ArticleProvider } from "components/articles";
import { AccountSettings } from "./AccountSettings";
import { MembershipSettings } from "./MembershipSettings";
import { PaymentSettings } from "./PaymentSettings";
import { SecuritySettings } from "./SecuritySettings";
import { SettingsTabName, SettingsTabRow } from "./SettingsTabRow";
import { TTSSettings } from "./TTSSettings";

export const TeacherSettings = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { tab } = useParams<{ tab?: SettingsTabName }>();
  const { setBreadcrumbColor } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbColor(breadcrumbConstants.settings.accentColor, breadcrumbConstants.settings.highlightColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNewTabSelected = (newTab: SettingsTabName) => {
    if (newTab !== tab) {
      const url = generatePath(path, { tab: newTab });
      history.push(url);
    }
  };

  return (
    <OuterContainer>
      <RootBreadcrumb
        title={breadcrumbConstants.settings.title}
        description={breadcrumbConstants.settings.breadcrumbDescription}
        icon={breadcrumbConstants.settings.icon}
      />
      <ArticleProvider />
      <InnerContainer>
        <SettingsTabRow selectedTab={tab} setSelectedTab={(newTab: SettingsTabName) => onNewTabSelected(newTab)} />
        <Spacer />
        <Switch>
          <Route path={generatePath(path, { tab: "accountDetails" })} component={AccountSettings} />
          <Route path={generatePath(path, { tab: "membership" })} component={MembershipSettings} />
          <Route path={generatePath(path, { tab: "security" })} component={SecuritySettings} />
          <Route path={generatePath(path, { tab: "paymentHistory" })} component={PaymentSettings} />
          <Route path={generatePath(path, { tab: "ttsSettings" })} component={TTSSettings} />
          <Route path={generatePath(path, { tab: undefined })}>
            <Redirect to={generatePath(path, { tab: "accountDetails" })} />
          </Route>
        </Switch>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Center)`
  height: 100%;
  width: 100%;
`;

const InnerContainer = styled(Column)`
  align-items: center;
  height: 95%;
  max-width: 900px;
  margin-top: 100px;
  padding: 40px 100px;
  border: 2px solid black;
  overflow-y: auto;
`;
