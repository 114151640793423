import { RootState } from "app/rootReducer";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { updateQuestionSet as updateQuestionSetApi } from "api";
import { Dispatch } from "react";
import { QuestionSet } from "types";

export interface UpdateQuestionSetParams {
  title: string;
  description: string;
  estimatedLength: number;
  tagIds: Array<string>;
}

const dispatchUpdateQuestionSet = (
  questionSetId: string,
  { title, description, estimatedLength, tagIds }: Partial<UpdateQuestionSetParams>,
  actions: QuestionSetActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedQuestionSet = await updateQuestionSetApi(
      questionSetId,
      { title, description, estimatedLength, tags: tagIds },
      token
    );
    dispatch(
      actions.addSingle({
        id: updatedQuestionSet.id!,
        questionSet: updatedQuestionSet as QuestionSet,
      })
    );
    return updatedQuestionSet;
  }, dispatch);

export { dispatchUpdateQuestionSet };
