import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction } from "../sliceHelpers";
import { fetchPremadeSessions } from "api";

export interface FetchPremadeSessionsParams {
  search: string;
  take: number;
  skip: number;
  isPreviewFetch: boolean;
}

const dispatchFetchPremadeSessions = (
  { search, take, skip, isPreviewFetch }: FetchPremadeSessionsParams,
  actions: SessionActions
) => async (dispatch: Dispatch<any>, _: () => RootState) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));

    const { data, total } = await fetchPremadeSessions({ search, take, skip });

    // console.log(data);
    if (isPreviewFetch) {
      dispatch(actions.addDataOnly({ data, requestToken }))
      dispatch(actions.addPremadeGamePreviews({ids: data.map(session =>session.id)}));
    } else {
      dispatch(actions.updateTotal({ sessionType: "premade", totalCount: total }));
      dispatch(actions.addMany({ data, requestToken }));
    }
    return data;
  }, dispatch);

export { dispatchFetchPremadeSessions };
