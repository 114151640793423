import { StyledIcon } from "@styled-icons/styled-icon";
import { useBreadcrumbs } from "hooks";
import { Row } from "primitives";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";
import { Breadcrumb } from "./Breadcrumb";
import { SmallIcon } from "primitives/icons";

interface Props {
  title: string;
  description: string;
  icon: StyledIcon;
}

interface Color {
  color: string;
}

export const RootBreadcrumb: FC<Props> = ({ title, description, icon }) => {
  const { url } = useRouteMatch();
  const { accentColor } = useBreadcrumbs();
  return (
    <StyledBreadcrumb to={url} description={description} root={false}>
      <BreadcrumbContent color={accentColor}>
        <SmallIcon icon={icon} />
        <p>{title}</p>
      </BreadcrumbContent>
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 0px;
  margin-right: 3em;
  margin-left: 1em;
  padding: 0px;
`;

const BreadcrumbContent = styled(Row)<Color>`
  align-items: center;
  gap: 5px;
  margin-right: 1em;
  &::after {
    content: " ";
    align-self: stretch;
    border-style: solid;
    border-width: 0;
    border-left-width: 0.5px;
    border-color: ${colors.innerBorder};
    background-color: ${colors.innerBorder};
    margin: -0.5em 1.5em;
    margin-right: -3em;
  }
`;
