import { EditQuestionSetModal } from "components/editQuestionSetModal";
import { QuestionSetActionHook, useLogin } from "hooks";
import { QuestionSetDetailsModal } from "pages/questions/questionSetDetails/QuestionSetDetailsModal";
import { QuestionSetShareLinkModal } from "pages/questions/questionSetDetails/QuestionSetShareLinkModal";
import { FC, ReactNode } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { slugs } from "textConstants";
import { EditSessionModal } from "components";

interface Props {
  actionHooks: Array<QuestionSetActionHook>;
  pathSuffix?: string;
  children?: ReactNode;
}

export const QuestionSetActionRoutes: FC<Props> = ({ pathSuffix, actionHooks, children }) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();
  const { userInfo } = useLogin();

  const onModalHidden = () => {
    history.push({ pathname: url, search });
  };

  const myPath = `${path}${pathSuffix || ""}`;

  return (
    <Switch>
      {children}
      <Route path={`${myPath}/${slugs.new}`}>
        <EditQuestionSetModal visible={true} hide={onModalHidden} />
      </Route>
      <Route path={`${myPath}/${slugs.questionSetId}/${slugs.settings}`}>
        <EditQuestionSetModal visible={true} hide={onModalHidden} />
      </Route>
      <Route path={`${myPath}/${slugs.questionSetId}/${slugs.share}`}>
        <QuestionSetShareLinkModal visible={true} onClose={onModalHidden} />
      </Route>
      <Route path={`${myPath}/${slugs.questionSetId}/${slugs.session}/${slugs.new}`}>
        <EditSessionModal shouldDuplicate={false} createCustomGameStartPath={slugs.game} onHidden={onModalHidden} />
      </Route>
      <Route path={`${myPath}/${slugs.questionSetId}`}>
        <QuestionSetDetailsModal visible={true} onClose={onModalHidden} userInfo={userInfo} actionHooks={actionHooks} />
      </Route>
    </Switch>
  );
};
