import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchSession } from "api";

export interface FetchSessionParams {
  id: string;
}

const dispatchFetchSession = ({ id }: FetchSessionParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const session = await fetchSession({ sessionId: id }, token);
    dispatch(actions.addSingle({ id: session.id, session }));
    return session;
  }, dispatch);

export { dispatchFetchSession };
