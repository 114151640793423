import { ActionButton, ActionButtonBar, Column, HorizontalLine, Row, SecondaryText } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Session } from "types";
import pluralize from "pluralize";
import { SessionActionHook, useRequiresLoginModal, useSessionActions } from "hooks";

interface Params {
  session: Session;
  actionHooks: SessionActionHook[];
}

const QuickGameListItem: FC<Params> = ({ session, actionHooks }) => {
  const actions = useSessionActions(session, actionHooks);
  const requiresLoginMessage = [`Please log in or sign up to play this game.`];
  const [displayModal, Modal] = useRequiresLoginModal({ modalBody: requiresLoginMessage });

  if (!session.customGames?.length) {
    console.error(`Tried to load QuickGame with no Custom Games. Name: ${session.name} Id: (${session.id})`);
    return null;
  }

  const customGame = session.customGames[0];
  const questionCount = customGame.questionSets.reduce((a, b) => a + b.numQuestions, 0);

  return (
    <>
      <OuterContainer>
        <HeaderImg src={customGame.gameTemplate.previewImage} />
        <InnerContainer>
          <BodyText>
            <SessionNameContainer>
              <SessionNameText title={session.name}>{session.name}</SessionNameText>
            </SessionNameContainer>
            <SecondaryText className="mt-2">
              {questionCount} {pluralize("question", questionCount)}
            </SecondaryText>
          </BodyText>
          <Column>
            <HorizontalLine margin="0.75em 0em" />
            <Footerbar>
              <ActionButton action={actions["play"]} variant="link" onActionNotAllowed={displayModal}>
                {actions["play"].name}
              </ActionButton>
              <Row style={{ justifyContent: "center" }} className="my-1">
                <ActionButtonBar
                  actions={Object.values(actions)
                    .slice(1, 3)
                    .map((action) => ({ action }))}
                  requiresLoginMessage={requiresLoginMessage}
                />
              </Row>
            </Footerbar>
          </Column>
        </InnerContainer>
      </OuterContainer>
      <Modal />
    </>
  );
};

const OuterContainer = styled(Column)`
  border: 1px solid ${colors.primary};
  width: 250px;
  height: 350px;
`;

const InnerContainer = styled(Column)`
  padding: 0px var(--sp-2);
  flex: 1;
`;

const HeaderImg = styled.img`
  width: 100%;
  height: 175px;
  object-fit: cover;
`;

const BodyText = styled(Column)`
  align-items: center;
  margin: auto 0px;
  padding: var(--sp-1) 0px;
`;

const Footerbar = styled(Column)`
  justify-content: center;
  align-items: stretch;
`;

const SessionNameContainer = styled(Row)`
  justify-content: center;
  width: 100%;
`;

const SessionNameText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.primary};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  max-height: 3em;
`;

export { QuickGameListItem };
