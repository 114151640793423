import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { ServerPronunciationSet } from "types";

export const getPronunciationSets = async (token: string) => {
  const response = await api.get(`/pronunciationsets`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data) {
    return response.data as ServerPronunciationSet[];
  } else {
    throw new NetworkError();
  }
};
