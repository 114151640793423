import styled from "styled-components";
import { colors } from "styles";

export const DescriptionText = styled.p`
  color: ${colors.darkText};
  text-align: center;
`;

export const ErrorText = styled.p`
  color: ${colors.danger};
  text-align: center;
`;
