import { api } from "../api";
import { NetworkError } from "../networkError";

interface FetchQuestionSetCountParams {
  teacherId: string;
  filterText?: string;
  archived?: boolean;
  isFavourite?: boolean;
  featured?: boolean;
  visibility?: "public" | "private" | "draft";
  gradeTags?: string;
  subjectTags?: string;
  total?: number;
  onlyMyOwn: boolean;
}

const fetchQuestionSetCount = async (params: FetchQuestionSetCountParams, token: string): Promise<number> => {
  const url = params.onlyMyOwn ? `/teachers/${params.teacherId}/questionSetCount` : `/questionsets/count`;
  const response = await api.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });

  if (response.data) {
    return response.data.questionSetCount;
  } else {
    throw new NetworkError();
  }
};

export { fetchQuestionSetCount };
