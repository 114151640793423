import { RootState } from "app/rootReducer";
import { QuestionSet } from "types";
import { fetchQuestionSet as fetchQuestionSetApi } from "api";
import { dispatchAsyncFunction } from "../sliceHelpers";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { Dispatch } from "react";

const dispatchFetchQuestionSet = (id: string, actions: QuestionSetActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const questionSet = await fetchQuestionSetInternal(id, getState);
    dispatch(actions.addSingle({ id: questionSet.id, questionSet }));
    return questionSet;
  }, dispatch);

const fetchQuestionSetInternal = async (id: string, getState: () => RootState): Promise<QuestionSet> => {
  const { token } = getState().login;
  const questionSet = await fetchQuestionSetApi(id, token)!;
  const questions = questionSet.questions || [];
  const sortedQuestions = questions.sort((questionA, questionB) => questionA.order - questionB.order);

  const questionSetWithSortedQuestions = {
    ...questionSet,
    questions: sortedQuestions,
  } as QuestionSet;

  return questionSetWithSortedQuestions;
};

export { dispatchFetchQuestionSet };
