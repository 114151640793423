import ReactDOM from "react-dom";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const envIsDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};

if (!envIsDevelopment()) {
  Sentry.init({
    dsn: "https://e808ee4cc9a14df0a4121c5d5fdf387c@o516093.ingest.sentry.io/5854324",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SERVER_ENV,
    tracesSampleRate: process.env.REACT_APP_SERVER_ENV === "production" ? 0.5 : 1.0,
  });
}

function render() {
  const App = require("./app/App").default;
  ReactDOM.render(<App />, document.getElementById("root"));
}

render();

if (envIsDevelopment() && module.hot) {
  module.hot.accept("./app/App", render);
}
