import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types/Teacher";
import { isValidResponse } from "utils/isValidResponse";

const requestVerify = async (teacherId: string): Promise<Teacher> => {
  const response = await api.get(`/teachers/${teacherId}/requestverification`);
  if (response?.data && isValidResponse(response)) {
    return response?.data;
  } else {
    throw new NetworkError();
  }
};

export { requestVerify };
