import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { bulkUpdateCustomGames as bulkUpdateCustomGamesApi } from "api";
import { CustomGameActions } from "./customGameSlice";
import { CustomGame } from "types";

export interface BulkUpdateCustomGamesParams {
  customGames: { id: string & Partial<CustomGame> }[];
}

export const dispatchBulkUpdateCustomGames = (
  { customGames }: BulkUpdateCustomGamesParams,
  actions: CustomGameActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const updatedCustomGames = await bulkUpdateCustomGamesApi({
      token,
      customGames,
    });

    dispatch(actions.addMany(updatedCustomGames));

    return updatedCustomGames;
  }, dispatch);
