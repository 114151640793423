import { Button } from "primitives/button";
import { FC, Fragment } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { EmptyPanelContainer } from "components";
import { AddCircleOutline } from "@styled-icons/material";
import { slugs } from "textConstants";

interface Props {}

const EmptyStudentGrid: FC<Props> = () => {
  const { url } = useRouteMatch();
  return (
    <EmptyPanelContainer
      title="A brand new class!"
      style={{ flex: "100%" }}
      message={
        <Fragment>
          It needs some students - click
          <Link to={`${url}/${slugs.students}/${slugs.add}`}>
            <Button className="mx-2" variant="outline-primary">
              <AddCircleOutline size="1.2em" className="mr-1" />
              Add New Students
            </Button>
          </Link>
          to add some!
        </Fragment>
      }
    />
  );
};

export { EmptyStudentGrid };
