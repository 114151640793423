import { PrimaryButton } from "primitives/button";
import { Center, InfoText, Row, Spacer, Column } from "primitives";
import { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import styled from "styled-components";
import { EmailInput, PasswordInput, APIFormErrorFeedback } from "components";
import { ApiError, defaultRequestState, FC } from "types";
import { isValidEmail, dispatchAsync, getTeacherRedirectOnLogin } from "utils";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { login } from "slices";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useQueryParams } from "hooks";
import { StringMap } from "types";
import { toLower } from "lodash";
import { TabletOrSmaller } from "primitives/MediaQueries";
import { paths, PlatformTitle } from "textConstants";

interface QueryParams extends StringMap {
  postLoginDestination: string;
}

interface Props {
  rememberMe: boolean;
  setRememberMe: (isActive: boolean) => void;
}

export const TeacherLoginElement: FC<Props> = ({ rememberMe, setRememberMe }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [failedAttempt, setFailedAttempt] = useState(false);

  const [{ loading, error }, setRequestState] = useState(defaultRequestState);
  const { token, userInfo } = useAppSelector((state) => state.login);

  const { params: queryParams } = useQueryParams<QueryParams>({ postLoginDestination: "" });
  const { search } = useLocation();

  const [showMobileAlert, setShowMobileAlert] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (token) {
      history.push(getTeacherRedirectOnLogin(queryParams.postLoginDestination));
    } else if (userInfo) {
      history.push({ pathname: `/login/teacher/auth`, search });
    }
  }, [token, userInfo, url, history, queryParams.postLoginDestination, search]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const lowerEmail = toLower(email);
    if (!isValidEmail(lowerEmail) || !password) {
      setFailedAttempt(true);
    } else {
      await dispatchAsync(dispatch(login(lowerEmail, password, rememberMe)), setRequestState);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TabletOrSmaller>
        {showMobileAlert && (
          <Alert variant="danger" onClose={() => setShowMobileAlert(false)} dismissible>
            <p>{PlatformTitle} recommends logging in with a desktop browser. User experience on mobile may vary.</p>
          </Alert>
        )}
      </TabletOrSmaller>
      <EmailInput setEmail={setEmail} isInvalid={Boolean(error)} />
      <Spacer size={30} />
      <PasswordInput
        password={password}
        setPassword={setPassword}
        showPassword={showPassword}
        toggleShowPassword={() => setShowPassword(!showPassword)}
        failedAttempt={Boolean(failedAttempt) || Boolean(error)}
      />
      <Spacer size={10} />
      <APIFormErrorFeedback
        error={error ? ApiError.authError : null}
        errorMessages={{
          [ApiError.authError]: "The email or password you have entered is incorrect.",
        }}
      />
      <Row style={{ justifyContent: "space-between" }}>
        <Form.Check
          type="checkbox"
          label="Remember me"
          checked={rememberMe}
          onChange={() => setRememberMe(!rememberMe)}
        />
        <Link to="/login/teacher/requestResetPassword">Forgot Password?</Link>
      </Row>
      <Spacer size={5} />
      <InfoText>* Do not select this if you are using a public computer</InfoText>
      <Spacer size={33} />
      <VerticalCenter>
        <PrimaryButton disabled={!isValidEmail(email) || loading} type={"submit"} loading={loading}>
          Log In
        </PrimaryButton>
        <Spacer size={5} />
        <Column style={{ textAlign: "center" }}>
          <Link to="/register/teacher">Don't have a {PlatformTitle} account? Sign Up</Link>
          <Spacer size={5} />
          <Link to={paths.login.student.path}>Switch to Student Log In</Link>
        </Column>
      </VerticalCenter>
    </Form>
  );
};

const VerticalCenter = styled(Center)`
  flex-direction: column;
  gap: 10px;
`;
