import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import {
  register as apiRegister,
  requestVerify as apiRequestVerify,
  verify as apiVerify,
  login as apiLogin,
  twoFactorAuthLogin as apiTwoFactorLogin,
  UserInfo,
  updateTeacher as updateTeacherApi,
  requestResetTeacherPassword as requestResetPasswordApi,
  resetTeacherPassword as resetPasswordApi,
  updateTwoFactorAuth as updateTwoFactorAuthApi,
  updateOnboardingProgress as updateOnboardingProgressApi,
  updateLastNewsItemRead as updateLastNewsItemReadApi,
  getTeacherByToken,
} from "api";

import { ApiError } from "types/ApiError";

import { appLoaded, LocalToken } from "./appLoadingSlice";
import { resetStudentLogin } from "./studentLoginSlice";
import { dispatchAsyncFunction, getTokenFromState } from "./sliceHelpers";
import { RootState } from "app/rootReducer";
import { NewsItem } from "types";

type LoginState = {
  loading: boolean;
  error: ApiError | null;
  token: string | null;
  userInfo: UserInfo | null;
  errorMessage?: string;
};

const initialState: LoginState = {
  loading: false,
  error: null,
  token: null,
  userInfo: null,
};

const handleError = (onError: (errorType: ApiError, message: string) => void, error: any) => {
  const errorType = error?.response?.status === 401 ? ApiError.authError : ApiError.networkError;
  const message = error?.response?.data?.message;
  onError(errorType, message);
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginLoading(state) {
      state.loading = true;
      state.error = null;
      state.token = null;
      state.userInfo = null;
      state.errorMessage = undefined;
    },
    loginSuccess(state, action: PayloadAction<{ token: string; userInfo: UserInfo }>) {
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.userInfo = action.payload.userInfo;
      state.errorMessage = undefined;
    },
    twoFactorLoginSuccess(state, action: PayloadAction<{ token: string }>) {
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.errorMessage = undefined;
    },
    updateSuccess(state, action: PayloadAction<{ userInfo: UserInfo }>) {
      state.loading = false;
      state.error = null;
      state.userInfo = action.payload.userInfo;
      state.errorMessage = undefined;
    },
    loginNetworkError(state) {
      state.loading = false;
      state.token = null;
      state.error = ApiError.networkError;
      state.userInfo = null;
      state.errorMessage = undefined;
    },
    loginAuthError(state) {
      state.loading = false;
      state.error = ApiError.authError;
      state.token = null;
      state.userInfo = null;
      state.errorMessage = undefined;
    },
    clearToken(state) {
      state.loading = false;
      state.error = null;
      state.token = null;
      state.userInfo = null;
    },
    clearState() {
      throw new Error("This action affects all slices so should have been intercepted by root reducer when dispatched");
    },
    basicLoading(state) {
      state.loading = true;
      state.error = null;
      state.errorMessage = undefined;
    },
    basicError(state, action: PayloadAction<{ error: ApiError; message: string }>) {
      state.loading = false;
      state.error = action.payload.error;
      state.errorMessage = action.payload.message;
    },
    basicSuccess(state) {
      state.loading = false;
      state.error = null;
      state.errorMessage = undefined;
    },
    updateTeacherSuccess(state, action: PayloadAction<{ token: string; userInfo: UserInfo }>) {
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.userInfo = action.payload.userInfo;
      state.errorMessage = undefined;
    },
  },
});

const register = (firstName: string, lastName: string, email: string, password: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(basicLoading());

    try {
      const userInfo = await apiRegister(firstName, lastName, email, password);
      dispatch(login(email, password, false));
      dispatch(basicSuccess());
      return { userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  };
};

const requestVerify = (teacherId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(basicLoading());
    try {
      await apiRequestVerify(teacherId);
      dispatch(basicSuccess());
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  };
};

const verify = (token: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(basicLoading());
    try {
      const userInfo = await apiVerify(token);
      dispatch(basicSuccess());
      return { userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  };
};

const login = (email: string, password: string, rememberMe: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(loginLoading());

    try {
      const { token, userInfo } = await apiLogin(email, password);
      if (token !== null) {
        const localData: LocalToken = {
          token,
          tokenType: "teacher",
          allowRefresh: rememberMe,
        };
        localStorage.setItem("token", JSON.stringify(localData));
      }

      dispatch(loginSuccess({ token, userInfo }));
      dispatch(resetStudentLogin());
      return { token, userInfo };
    } catch (error) {
      handleError((errorType) => {
        if (errorType === ApiError.authError) {
          dispatch(loginAuthError());
        } else {
          dispatch(loginNetworkError());
        }
        throw errorType;
      }, error);
    }
  };
};

const twoFactorLogin = (teacherId: string, emailToken: string, rememberMe: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(basicLoading());

    try {
      const { token } = await apiTwoFactorLogin(teacherId, emailToken);
      const localData: LocalToken = {
        token,
        tokenType: "teacher",
        allowRefresh: rememberMe,
      };
      localStorage.setItem("token", JSON.stringify(localData));
      dispatch(twoFactorLoginSuccess({ token }));
      return { token };
    } catch (error) {
      handleError((errorType) => {
        if (errorType === ApiError.authError) {
          dispatch(loginAuthError());
        } else {
          dispatch(loginNetworkError());
        }
        throw errorType;
      }, error);
    }
  };
};

const clearUserData = async () => {
  return (dispatch: Dispatch<any>) => {
    localStorage.clear();
    dispatch(clearState());
  };
};

const logout = (postLogoutLocation?: string, searchParams?: string, dontClearLocalStorage?: boolean) => {
  return (dispatch: Dispatch<any>) => {
    if (!dontClearLocalStorage) {
      localStorage.clear();
    }

    dispatch(clearState());
    dispatch(appLoaded());

    if (postLogoutLocation !== undefined) {
      if (searchParams !== undefined) {
        window.location.href = window.location.origin + postLogoutLocation + "?" + searchParams;
      } else {
        window.location.href = window.location.origin + postLogoutLocation;
      }
    } else {
      window.location.href = window.location.origin;
    }
  };
};

const updateOnboardingProgress = (teacherId: string, onboardingProgress: string) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());

    const { token } = getTokenFromState(getState);
    try {
      const userInfo = await updateOnboardingProgressApi(token, teacherId, onboardingProgress);
      dispatch(loginSuccess({ token, userInfo }));
      return { token, userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const updateLastReadNewsItem = (teacherId: string, lastReadNews: NewsItem) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());

    const { token } = getTokenFromState(getState);
    try {
      const userInfo = await updateLastNewsItemReadApi(token, teacherId, lastReadNews);
      dispatch(loginSuccess({ token, userInfo }));
      return { token, userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const updateTeacher = (teacherId: string, firstName: string, lastName: string) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());

    const { token } = getTokenFromState(getState);
    try {
      const userInfo = await updateTeacherApi(token, teacherId, firstName, lastName);
      dispatch(loginSuccess({ token, userInfo }));
      return { token, userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const requestResetPassword = (email: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());
    try {
      const userInfo = await requestResetPasswordApi(email);
      dispatch(basicSuccess());
      return { userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const resetPassword = (token: string, email: string, password: string) => async (dispatch: Dispatch<any>) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());
    try {
      const userInfo = await resetPasswordApi(token, email, password);
      dispatch(basicSuccess());
      return { token, userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const updateTwoFactorAuth = (teacherId: string, enabled: boolean) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());
    try {
      const { token } = getTokenFromState(getState);
      const userInfo = await updateTwoFactorAuthApi(token, teacherId, enabled);
      dispatch(updateSuccess({ userInfo }));
      return { token, userInfo };
    } catch (error) {
      handleError((error, message) => {
        dispatch(basicError({ error, message }));
      }, error);
    }
  }, dispatch);
};

const refreshTeacherData = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  return dispatchAsyncFunction(async () => {
    dispatch(basicLoading());
    try {
      const { token } = getTokenFromState(getState);
      const userInfo = await getTeacherByToken(token);
      dispatch(updateSuccess({ userInfo }));
      return userInfo;
    } catch (error) {}
  }, dispatch);
};

export const {
  loginLoading,
  loginSuccess,
  loginAuthError,
  loginNetworkError,
  twoFactorLoginSuccess,
  updateSuccess,
  clearToken,
  clearState,
  basicLoading,
  basicError,
  basicSuccess,
  updateTeacherSuccess,
} = loginSlice.actions;

const loginReducer = loginSlice.reducer;
export {
  loginReducer,
  register,
  requestVerify,
  verify,
  login,
  twoFactorLogin,
  clearUserData,
  logout,
  clearToken as clearLoginToken,
  clearState as clearAppState,
  updateTeacher,
  updateOnboardingProgress,
  updateLastReadNewsItem,
  requestResetPassword,
  resetPassword,
  updateTwoFactorAuth,
  refreshTeacherData,
};
