import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Teacher } from "types";
import { isValidResponse } from "utils/isValidResponse";

export const getTeacherByToken = async (token: string): Promise<Teacher> => {
  const response = await api.get("/teachers/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data && isValidResponse(response)) {
    return response?.data as Teacher;
  } else {
    throw new NetworkError();
  }
};
