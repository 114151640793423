import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchSessions as fetchSessionListApi } from "api";
import { Session, SessionType } from "types";

export interface FetchSessionListParams {
  classId?: string;
  sessionType: SessionType;
  startDate?: string;
  endDate?: string;
  take?: number;
  skip?: number;
  order?: string;
  orderby?: string;
  chunkSize?: number;
}

const dispatchFetchSessionList = (
  { classId, sessionType, startDate, endDate, take, skip, order, orderby, chunkSize }: FetchSessionListParams,
  actions: SessionActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));
    const { token, userInfo } = getTokenFromState(getState);
    let data: Session[] = [];
    // We have some issues with this endpoint where pulling a full page of data causes the 
    // endpoint to timeout. Given that APIGateway can only go up to 30s, we can choose to
    // chunk the request to avoid the limitation. This is only a patch at the moment.
    if (take !== undefined && skip !== undefined) {
      const chunk = chunkSize || take;
      for (let i = skip; i < skip + take; i += chunk) {
        const chunkData = await fetchSessionListApi(
          { teacherId: userInfo!.id, classId, sessionType, startDate, endDate, take: chunk, skip: i, order, orderby },
          token
        );
        data.push(...chunkData);
      }
    } else {
      data = await fetchSessionListApi(
        { teacherId: userInfo!.id, classId, sessionType, startDate, endDate, take, skip, order, orderby },
        token
      );
    }
    dispatch(actions.addMany({ data, type: sessionType, requestToken }));
    return { data, type: sessionType };
  }, dispatch);

export { dispatchFetchSessionList };
