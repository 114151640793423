import { colors } from "styles";
import { FC, TTSOverridePair, QuestionSet, PronunciationSet } from "types";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Column, PrimaryButton, Row, Spacer } from "primitives";
import { defaultPronunciationSets, defaultTTSOverrideSets } from "utils";
import { TTSOverrideRow } from "../settings";
import { MedIcon } from "primitives/icons";
import { actionConstants, helpMessages } from "textConstants";
import { isEqual } from "lodash";
import { ModalButtonFooter, PronunciationSetSelect } from "components";
import { useConfirmModal } from "hooks";
import { CustomTTSOverrideAlert } from "./Components";

export type TTSExampleQuestion = {
  input: string;
  questionText: string;
  answers: string;
};

interface Props {
  ttsOverrideJson: string | undefined;
  selectedQuestionSets: QuestionSet[];
  pronunciationSets: PronunciationSet[];
  hide: () => void;
  commit: (ttsOverrideJson: string) => void;
}

const CustomGameTTSOverrideEditor: FC<Props> = ({
  ttsOverrideJson,
  selectedQuestionSets,
  pronunciationSets,
  hide,
  commit,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [overrides, setOverrides] = useState<TTSOverridePair[]>([]);
  const [originalOrderrideJson] = useState(ttsOverrideJson);

  const [selectedPronunciationSetIds, setSelectedPronunciationSetIds] = useState<string[]>(
    defaultPronunciationSets.map((set) => set.id)
  );
  const [ConfirmModal, setConfirmModalVisible, setConfirmModalInfo] = useConfirmModal();

  const pronunciationSetsWDefaults = [...defaultPronunciationSets, ...pronunciationSets];

  useEffect(() => {
    const initialOverides = parseTTSOverrides(ttsOverrideJson);
    setOverrides(initialOverides);
    // eslint-disable-next-line
  }, []);

  const parseTTSOverrides = (ttsOveride: string | undefined) => {
    let pairs: TTSOverridePair[] = [];
    if (ttsOveride) {
      pairs = JSON.parse(ttsOveride);
    }

    return pairs;
  };

  const commitChanges = () => {
    const newOverrides = JSON.stringify(overrides);
    commit(newOverrides);
    startClosing(newOverrides);
  };

  const resetStateAndHide = (e?: MouseEvent) => {
    e?.preventDefault();
    if ((e?.currentTarget as HTMLSelectElement)?.disabled) return;
    startClosing();
  };

  const startClosing = (savedValue: string | undefined = undefined) => {
    const unsavedChanges = (savedValue || originalOrderrideJson) !== JSON.stringify(overrides);
    if (unsavedChanges) {
      setConfirmModalInfo({
        body: [helpMessages.unsavedChanges],
        onModalConfirm: async () => setIsClosing(true),
      });
      setConfirmModalVisible(true);
    } else {
      setIsClosing(true);
    }
  };

  const onModalHidden = () => {
    setIsClosing(false);
    hide();
  };

  const overrideChanged = (value: string, index: number, field: "input" | "output") => {
    if (index < overrides.length) {
      const newOverrides = [...overrides];

      newOverrides[index][field] = value;

      setOverrides(newOverrides);
    }
  };

  const refreshOverrides = () => {
    setSelectedPronunciationSetIds(defaultPronunciationSets.map((set) => set.id));
    setOverrides(defaultTTSOverrideSets.flat());
  };

  const updateOverridesWithSelectedIds = (pronunciationSetIds: string[]) => {
    const newOverrides: TTSOverridePair[] = overrides.map((override) => {
      return { input: override.input, output: override.output };
    });

    //remove all the previously added stuff
    pronunciationSetsWDefaults.forEach((set) => {
      const pairs = set.ttsOverridePairs;
      for (let i = 0; i < pairs.length; i++) {
        const overrideIndex = newOverrides.findIndex((override) => isEqual(override, pairs[i]));

        if (overrideIndex >= 0) {
          newOverrides.splice(overrideIndex, 1);
        }
      }
    });

    //add new ones
    pronunciationSetIds.forEach((pronunciationSetId) => {
      const pronunciationSet = pronunciationSetsWDefaults.find((set) => set.id === pronunciationSetId);
      if (pronunciationSet) {
        pronunciationSet.ttsOverridePairs.forEach((pair) => {
          const overrideIndex = newOverrides.findIndex((override) => override.input === pair.input);

          if (overrideIndex >= 0) {
            newOverrides[overrideIndex] = pair;
          } else {
            newOverrides.push(pair);
          }
        });
      }
    });

    setSelectedPronunciationSetIds(pronunciationSetIds);
    setOverrides(newOverrides);
  };

  const addOverride = () => {
    const newOverrides = [...overrides];

    newOverrides.push({ input: "", output: "" });

    setOverrides(newOverrides);
  };

  const removeOverride = (index: number) => {
    if (index < overrides.length) {
      const newOverrides = [...overrides];

      newOverrides.splice(index, 1);

      setOverrides(newOverrides);
    }
  };

  const isOverrideValid = (pair: TTSOverridePair | null) => {
    //check for doubles
    let count = 0;

    if (pair != null) {
      for (let i = 0; i < overrides.length; i++) {
        if (overrides[i].input === pair?.input) count++;
      }
    }

    //force the return to be a boolean
    return (pair && pair.input && pair.output && count === 1) === true;
  };

  const isOverridesValid = () => {
    for (let i = 0; i < overrides.length; i++) {
      if (!isOverrideValid(overrides[i])) {
        return false;
      }
    }
    return true;
  };
  return (
    <Modal show={!isClosing} onHide={resetStateAndHide} dialogClassName="full-width-modal" onExited={onModalHidden}>
      <Modal.Header>
        <Modal.Title>Text to Speech Custom Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="pb-2">{"Add Pronunciation Set(s)"}</Row>
        <PronunciationSetSelect
          pronunciationSets={pronunciationSetsWDefaults}
          pronunciationSetIds={selectedPronunciationSetIds}
          setPronunciationSetIds={(pronunciationSetIds: string[]) => {
            updateOverridesWithSelectedIds(pronunciationSetIds);
            setSelectedPronunciationSetIds(pronunciationSetIds);
          }}
        />
        <CustomTTSOverrideAlert />
        <Spacer />
        <TTSModalBody className="create-question-set-modal">
          <SelectionSummary>
            {overrides?.length ? (
              overrides.map((override, index) => (
                <TTSOverrideRow
                  key={index}
                  override={override}
                  index={index}
                  selectedQuestionSets={selectedQuestionSets}
                  onChanged={overrideChanged}
                  removeOverride={removeOverride}
                  isOverrideValid={isOverrideValid}
                />
              ))
            ) : (
              <p>No symbols found in selected question sets.</p>
            )}
          </SelectionSummary>
          <VerticalBreak />
          <Row gap="10px">
            <StyledPrimaryButton variant="secondary" onClick={refreshOverrides}>
              <MedIcon icon={actionConstants.refresh.icon} />
              <p>Reset overrides to defaults</p>
            </StyledPrimaryButton>
            <StyledPrimaryButton onClick={addOverride}>
              <MedIcon icon={actionConstants.add.icon} />
              <p>Add Pronunciation</p>
            </StyledPrimaryButton>
          </Row>
        </TTSModalBody>

        <ModalButtonFooter
          className="pt-3"
          isEditing={false}
          onCancel={() => startClosing()}
          confirmDisabled={!isOverridesValid()}
          onConfirm={commitChanges}
        />
      </Modal.Body>
      <ConfirmModal />
    </Modal>
  );
};

const VerticalBreak = styled.div`
  height: 10px;
`;

const TTSModalBody = styled.div`
  margin: 0.1em;
  padding: 1em;
  background-color: ${colors.secondaryPale};
`;

const SelectionSummary = styled(Column)`
  align-items: flex-start;
  gap: 0.75em;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  height: 2.5em;
`;

export { CustomGameTTSOverrideEditor };
