import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { SubscriptionInfo } from "types";

export const getSubscriptionInfo = async (token: string): Promise<SubscriptionInfo> => {
  const result = await api.get("/payments/subscription", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (result.data) {
    return result.data as SubscriptionInfo;
  } else {
    throw new NetworkError();
  }
};
