import { useLogin } from "hooks";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useHistory } from "react-router";
import { googleAnalyticsTrackingId, googleTagManagerId } from "env";
import TagManager from "react-gtm-module";

const useAnalytics = () => {
  useGA();
  useTagManager();
};

const useGA = () => {
  const history = useHistory();
  ReactGA.initialize(googleAnalyticsTrackingId);
  ReactGA.set({ anonymizeIp: true });
  const { userInfo } = useLogin();
  useEffect(() => {
    ReactGA.set({
      userId: userInfo?.id || "Not logged in",
      subscription: userInfo?.subscriptionInfo?.subscriptionStatus || "None",
    });
  }, [userInfo]);

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  });
};

const useTagManager = () => {
  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  };

  TagManager.initialize(tagManagerArgs);
};

export { useAnalytics };
