import { ReactNode } from "react";
import { FC } from "types";
import styled from "styled-components";
import { colors } from "styles";

interface Props {
  title?: string;
  message?: ReactNode;
}

const EmptyPanelContainer: FC<Props> = ({ title, message, children, style, ...props }) => {
  return (
    <EmptyContainer style={style} {...props}>
      {title && <h5>{title}</h5>}
      {message && <Message>{message}</Message>}
      {children}
    </EmptyContainer>
  );
};

const Message = styled.div`
  color: ${colors.secondary};
  text-align: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: top;
  margin: 7em 0em;
`;

export { EmptyPanelContainer };
