import { StyledIcon } from "@styled-icons/styled-icon";
import { ConfirmModalInfo } from "hooks";
import { keyBy } from "lodash";

export interface UserAction {
  id: string;
  name: string;
  action: () => Promise<any>;
  isAvailable?: () => boolean;
  isEnabled?: () => boolean;
  priority?: number;
  icon?: StyledIcon;
  iconColor?: string;
  color?: string;
  backgroundColor?: string;
  hoverColor?: string;
  hoverBackground?: string;
  requiresLogin?: boolean;
  confirmationDetails?: ConfirmModalInfo;
}

export type UserActionHook<T> = (obj: T) => Array<UserAction>;

export const actionHooksToActions = <T>(
  obj: T,
  actionHooks?: Array<UserActionHook<T>>
): { [id: string]: UserAction } => {
  if (actionHooks) {
    const actions = actionHooks.reduce((curr, actionHook) => {
      let actions = actionHook(obj);
      actions = actions.map((action, i) => ({
        ...action,
        priority: action.priority || i,
      }));
      return [...curr, ...actions];
    }, [] as Array<UserAction>);

    return keyBy(actions, "id");
  }

  return {};
};
