export interface AsyncRequestState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

export const defaultRequestState: AsyncRequestState = {
  loading: false,
  success: false,
  error: null,
};
