import { RootState } from "app/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { blurApp, unBlurApp } from "slices";

const useBlurOverlay = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.blurOverlay);

  const functions = {
    blurApp: () => dispatch(blurApp()),
    unBlurApp: () => dispatch(unBlurApp()),
  };

  return { ...state, ...functions };
};

export { useBlurOverlay };
