import { useHistory, useLocation } from "react-router-dom";

export const useRemoveTrailingSlash = () => {
  const location = useLocation();
  const history = useHistory();

  // If the last character of the url is '/'
  if (location && location.pathname.match("/.*/$")) {
    history.replace({ pathname: location.pathname.replace(/\/+$/, ""), search: location.search });
  }
};
