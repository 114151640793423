import { useEffect, useState, useCallback } from "react";
import { GameTemplateSelector } from "./GameTemplateSelector";
import { QuestionSetMultiSelector } from "./QuestionSetMultiSelector";
import { CustomGameSettingsSelector } from "./CustomGameSettingsSelector";
import {
  createCustomGame,
  editCustomGame,
  fetchCustomGame,
  fetchQuestionSetNew,
  resetQuestionSets,
  setCustomGameAssignments,
} from "slices";
import { useLocation } from "react-router";
import styled from "styled-components";
import { AsyncRequestState, CustomGame, CustomGameSettings, FC } from "types";
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { dispatchAsync } from "utils";
import { Breadcrumb } from "components/breadcrumb";
import { QuestionSetEditor } from "pages/questions/questionSetEditor";
import { CustomGameProgress } from "./CustomGameProgress";
import { CustomGameReview } from "./CustomGameReview";
import { colors, layoutConstants } from "styles";
import { Names, slugs } from "textConstants";
import { customGameSettingsUtils as settingsUtils } from "./CustomGameSettingsSelectorUtils";
import { merge, mapValues } from "lodash";
import { LoadingContent, PageNavigationPrompt } from "components";
import { useQueryParams } from "hooks";

export interface CustomGameEditorParams {
  customGameId?: string;
}

interface Props {
  sessionEditUrlOnComplete?: string;
  assignedStudents?: string[];
  onComplete?: () => void;
}

export interface CurrentCustomGameData {
  gameSettings: Partial<CustomGameSettings>;
  gameTemplateId: string;
  questionSetIds: string[];
}

type StepName = "SelectQuestions" | "SelectGameTemplate" | "SelectRules" | "Review";

const Steps: { [step in StepName as string]: string } = {
  SelectQuestions: slugs.questions,
  SelectGameTemplate: slugs.game,
  SelectRules: slugs.rules,
  Review: slugs.review,
};

const customGameSteps = [Steps.SelectQuestions, Steps.SelectGameTemplate, Steps.SelectRules, Steps.Review];

const stepIsIndex = (stepSlug: string, stepIndex: number): boolean => {
  return customGameSteps[stepIndex] === stepSlug;
};

const getStepIndexFromPathname = (pathname: string) => {
  const parts = pathname.split("/");
  return customGameSteps.indexOf(parts[parts.length - 1]);
};

const CustomGameEditor: FC<Props> = ({ sessionEditUrlOnComplete, onComplete, assignedStudents }) => {
  const { customGameId } = useParams<CustomGameEditorParams>();
  const { url, path } = useRouteMatch<{ classId: string; sessionId: string }>();
  const { search, pathname } = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    params: { questionSetId },
    setParams,
  } = useQueryParams<{ questionSetId?: string }>();

  const [currentStepIndex, setCurrentStepIndex] = useState(getStepIndexFromPathname(pathname));
  // Building blocks for a custom game
  const [selectedQuestionSetIds, setSelectedQuestionSetIds] = useState<string[]>([]);
  const [gameTemplateId, setGameTemplateId] = useState("");
  const [customGameSettings, setCustomGameSettings] = useState<Partial<CustomGameSettings>>();
  const [customGameSettingsValid, setCustomGameSettingsValid] = useState<boolean>(true);
  const [{ loading, error }, setRequestState] = useState<AsyncRequestState>({
    loading: false,
    success: true,
    error: null,
  });
  const [existingCustomGame, setExistingCustomGame] = useState<CustomGame>();
  const [overrideComplete, setOverrideComplete] = useState(false);

  const { sessionId } = useParams<{ sessionId?: string }>();
  const session = useAppSelector(
    (state) => (sessionId && state.sessionList.data && state.sessionList.data[sessionId]) || null
  );

  useEffect(() => {
    //If a question set id is sent, add it to the selected ids list and remove it
    // from the search parameters
    if (questionSetId) {
      setSelectedQuestionSetIds([questionSetId]);
      dispatch(fetchQuestionSetNew(questionSetId!));
      setParams({});
    }
  }, [questionSetId, dispatch, selectedQuestionSetIds, setParams]);

  const currentCustomGame: CurrentCustomGameData = {
    gameSettings: customGameSettings || {},
    gameTemplateId: gameTemplateId,
    questionSetIds: selectedQuestionSetIds,
  };

  const isEditing = Boolean(customGameId);

  const loadCustomGameFromId = useCallback(
    async (customGameId: string) => {
      setRequestState({ loading: true, error: null, success: false });
      const customGame = await dispatch(fetchCustomGame(customGameId));

      if (customGame) {
        const questionSetIds = customGame.questionSets.map((questionSet) => questionSet.id);
        setSelectedQuestionSetIds(questionSetIds);
        setGameTemplateId(customGame.gameTemplate.id);
        setCustomGameSettings(customGame.settings);

        questionSetIds.forEach((questionSetId) => {
          dispatch(fetchQuestionSetNew(questionSetId));
        });
        setExistingCustomGame(customGame);
      }

      setRequestState({ loading: false, error: null, success: Boolean(customGame) });
    },
    [dispatch]
  );

  useEffect(() => {
    if (customGameId) {
      loadCustomGameFromId(customGameId);
    }
  }, [customGameId, loadCustomGameFromId]);

  useEffect(() => {
    dispatch(resetQuestionSets());

    if (currentStepIndex !== 0 && !selectedQuestionSetIds.length) {
      if (!customGameId || existingCustomGame) {
        if (!questionSetId && selectedQuestionSetIds.length === 0) {
          setCurrentStep(0);
        } else {
          setCurrentStep(1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCustomGame]);

  const onSelectedQuestionSetsChanged = (selectedIds: string[]) => {
    setSelectedQuestionSetIds(selectedIds);
  };

  const onSelectedGameTemplateChanged = (selectedId: string) => {
    setGameTemplateId(selectedId);
  };

  const onGameSettingsChanged = (settings: Partial<CustomGameSettings>, settingsValid: boolean) => {
    setCustomGameSettings(settings);
    setCustomGameSettingsValid(settingsValid);
  };

  const isFinalStep = () => {
    return stepIsIndex(Steps.SelectRules, currentStepIndex);
  };

  const isComplete = () => {
    return stepIsIndex(Steps.Review, currentStepIndex) || overrideComplete;
  };

  const completeWithSession = async () => {
    let actionSuccess = true;
    if (isEditing) {
      let customGamesToCopySettings: CustomGame[] = [];

      //check to see how we should propagate custom game settings
      if (session && session.customGames) {
        const customGameIndex = session.customGames.findIndex((customGame) => customGame.id === customGameId);
        if (session.customGames.length > 1 && customGameIndex !== session.customGames.length - 1) {
          for (let index = customGameIndex; index < session.customGames.length; index++) {
            if (session.customGames[customGameIndex + 1].settings?.copySettingsFromPrev) {
              customGamesToCopySettings.push(session.customGames[customGameIndex + 1]);
            } else {
              break;
            }
          }
        }
      }

      //submit the og
      const { success } = await dispatchAsync(
        dispatch(
          editCustomGame({
            customGameId: customGameId!,
            gameTemplateId,
            questionSetIds: selectedQuestionSetIds,
            customGameSettings: customGameSettings!,
          })
        ),
        setRequestState
      );

      //submit copies
      let awaitingActions: boolean[] = [];
      if (customGamesToCopySettings && customGameSettings) {
        //propogate copied settings
        for (let i = 0; i < customGamesToCopySettings.length; i++) {
          const customGame = customGamesToCopySettings[i];
          const totalQuestions = customGame.questionSets.reduce((a, b) => a + b.numQuestions, 0);

          let copiedSettings = mapValues(
            settingsUtils.CopyGameSettings(customGameSettings, totalQuestions, true),
            ({ value }) => value
          );

          const mergedSettings = merge({ ...customGame.settings }, { ...copiedSettings });
          const newSettings = mapValues(mergedSettings);

          const { success } = await dispatchAsync(
            dispatch(
              editCustomGame({
                customGameId: customGame.id!,
                gameTemplateId: customGame.gameTemplate.id,
                questionSetIds: customGame.questionSets.map((questionSet) => questionSet.id),
                customGameSettings: newSettings,
              })
            ),
            setRequestState
          );
          awaitingActions.push(success);
        }
      }

      actionSuccess = success && awaitingActions.every(Boolean);
    } else {
      const { success, result } = await dispatchAsync(
        dispatch(
          createCustomGame({
            gameTemplateId,
            questionSetIds: selectedQuestionSetIds,
            customGameSettings,
            sessionId: sessionId!,
          })
        ),
        setRequestState
      );

      actionSuccess = success;
      if (success && result && assignedStudents) {
        const { success: setAssignmentsSuccess } = await dispatchAsync(
          dispatch(
            setCustomGameAssignments({
              customGameId: result.id,
              studentIds: assignedStudents,
            })
          ),
          setRequestState
        );
        actionSuccess = setAssignmentsSuccess;
      }
    }

    if (actionSuccess) {
      setOverrideComplete(true);
      if (onComplete) {
        onComplete();
      }
      // Open the sessions edit screen
      if (sessionEditUrlOnComplete) {
        history.push(sessionEditUrlOnComplete);
      } else {
        console.error(
          `No URL passed on complete. This is only optional when creating an orphaned ${Names.customGame}.`
        );
      }
    }
  };

  const completeWithoutSession = async () => {
    const { success, result: customGame } = await dispatchAsync(
      dispatch(
        createCustomGame({
          gameTemplateId,
          questionSetIds: selectedQuestionSetIds,
          customGameSettings,
          sessionId: sessionId!,
        })
      ),
      setRequestState
    );

    if (success && customGame) {
      setCurrentStep(currentStepIndex + 1, customGame);
    }
  };

  const onFinishedStage = async () => {
    if (!isFinalStep()) {
      setCurrentStep(currentStepIndex + 1);
    } else {
      if (sessionId) {
        await completeWithSession();
      } else {
        await completeWithoutSession();
      }
    }
  };

  const setCurrentStep = (stepIndex: number, newCustomGame?: CustomGame) => {
    const step = customGameSteps[stepIndex];
    setCurrentStepIndex(stepIndex);
    if (newCustomGame) {
      history.push({
        pathname: `${url.substring(0, url.indexOf(`/${slugs.new}`))}/${newCustomGame.id}/${step}`,
        search,
      });
    } else {
      history.push({ pathname: `${url}/${step}`, search });
    }
  };

  const getHighestStepIndex = () => {
    if (gameTemplateId !== "" && selectedQuestionSetIds?.length) {
      return 2;
    } else if (selectedQuestionSetIds?.length) {
      return 1;
    } else {
      return 0;
    }
  };

  const canProceed = () => {
    if (stepIsIndex(Steps.SelectQuestions, currentStepIndex)) {
      return selectedQuestionSetIds?.length > 0;
    } else if (stepIsIndex(Steps.SelectGameTemplate, currentStepIndex)) {
      return selectedQuestionSetIds?.length > 0 && Boolean(gameTemplateId);
    } else {
      return selectedQuestionSetIds?.length > 0 && Boolean(gameTemplateId) && customGameSettingsValid;
    }
  };

  const returnToParent = () => {
    const parentPath = pathname.substring(0, pathname.lastIndexOf(`/${slugs.miniGame}`));
    history.push(parentPath);
  };

  return (
    <OuterContainer className="custom-game-editor">
      <LoadingContent loading={(loading || !existingCustomGame) && Boolean(customGameId)}>
        <PageNavigationPrompt
          when={!isComplete()}
          message={(location, isRefresh) => {
            if (!isRefresh && location.pathname.includes(slugs.miniGame)) {
              return true;
            } else {
              return "You have unsaved changes, are you sure you want to leave?";
            }
          }}
        />
        <MainContainer>
          <Breadcrumb>
            <b>
              {isEditing ? "Edit" : "Create"} {Names.CustomGame}:
            </b>
          </Breadcrumb>
          <Switch>
            <Route
              path={`${path}/${Steps.SelectQuestions}/${slugs.questionSetId}/${slugs.edit}`}
              component={QuestionSetEditor}
            />
            <Route path={`${path}/${Steps.SelectQuestions}`}>
              <Breadcrumb>Choose Question Set(s)</Breadcrumb>
              <QuestionSetMultiSelector
                currentCustomGame={currentCustomGame}
                initialSelectedQuestionSetIds={selectedQuestionSetIds}
                onSelectedSetsChanged={onSelectedQuestionSetsChanged}
                onFinishedStage={onFinishedStage}
              />
            </Route>
            <Route path={`${path}/${Steps.SelectGameTemplate}`}>
              <Breadcrumb>Choose {Names.GameTemplate}</Breadcrumb>
              <GameTemplateSelector
                initialSelectedGameTemplateId={gameTemplateId}
                selectedQuestionSetIds={selectedQuestionSetIds}
                onSelectedGameTemplateChanged={onSelectedGameTemplateChanged}
                onFinishedStage={onFinishedStage}
                currentCustomGame={currentCustomGame}
              />
            </Route>
            <Route path={`${path}/${Steps.SelectRules}`}>
              <Breadcrumb>Set Rules</Breadcrumb>
              <CustomGameSettingsSelector
                initialGameSettings={customGameSettings}
                selectedQuestionSetIds={selectedQuestionSetIds}
                selectedGameTemplate={gameTemplateId}
                isParentLoading={loading}
                createCustomGameError={error && "There was an error. Please try again!"}
                onSettingsChanged={onGameSettingsChanged}
                onFinishedStage={onFinishedStage}
                isNewCustomGame={!isEditing}
                currentCustomGame={currentCustomGame}
                session={session}
                customGameId={customGameId}
              />
            </Route>
            <Route path={`${path}/${Steps.Review}`}>
              <Breadcrumb>Review</Breadcrumb>
              <CustomGameReview customGame={existingCustomGame} currentCustomGame={currentCustomGame} />
            </Route>
            {sessionId || (!questionSetId && selectedQuestionSetIds.length === 0) ? (
              <Redirect to={`${url}/${Steps.SelectQuestions}`} />
            ) : (
              <Redirect to={`${url}/${Steps.SelectGameTemplate}`} />
            )}
          </Switch>
        </MainContainer>
      </LoadingContent>
      <CustomGameProgress
        currentStepNumber={currentStepIndex}
        highestStepNumber={getHighestStepIndex()}
        canProceed={canProceed()}
        isLoading={loading}
        playGameLink={
          stepIsIndex(Steps.Review, currentStepIndex) && existingCustomGame ? existingCustomGame.gameLink : undefined
        }
        onNextPressed={onFinishedStage}
        onStepPressed={setCurrentStep}
        onReturnPressed={returnToParent}
      />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const MainContainer = styled.div`
  width: 100%;
  height: calc(100% - ${layoutConstants.customGameProgressHeight});
`;

export const MiniGameStepOuterContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

export const MiniGameStepMainContainer = styled.div`
  overflow-x: auto;
  flex: 1 1 0;
  padding: var(--sp-3) var(--sp-3) 0 var(--sp-3);
`;

export const MiniGameStepMaxWidthContainer = styled.div`
  overflow-x: auto;
  width: calc(100vw - var(--sidebar-width-lg));
  padding: var(--sp-3) var(--sp-3) 0 var(--sp-3);
`;

export const MiniGameStepSidebarContainer = styled.div`
  width: var(--sidebar-width-lg);
  z-index: 10;
  border-right: solid 1px ${colors.primaryDivider};
  overflow-y: auto;
`;

export const MiniGameStepSidebarSection = styled.div`
  width: 100%;
  border-bottom: solid 1px ${colors.primaryDivider};
  padding: var(--sp-3);

  &:last-child {
    border-bottom: 0;
  }
`;

export { CustomGameEditor };
