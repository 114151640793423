import { Question } from "types";
import { changeItemPositionInArray } from "utils";

const reorderQuestion = (questions: Question[], indexA: number, indexB: number) => {
  const newQuestions = changeItemPositionInArray(questions, indexA, indexB);

  const newQuestionsWithCorrectOrder = newQuestions.map((question, index) => {
    const offsetIndex = index + 1; // backend doesn't like it when order=0

    if (question.order === offsetIndex) {
      return question;
    } else {
      return {
        ...question,
        order: offsetIndex,
        toBeUpdated: true,
        displayAsUnsaved: question.displayAsUnsaved || index === indexA || index === indexB,
      };
    }
  });

  return newQuestionsWithCorrectOrder;
};

export { reorderQuestion };
