import styled from "styled-components";
import signupBackgroundImage from "assets/signupbackground.png";
import teacherBackgroundImage from "assets/teacherloginbackground.png";
import studentBackgroundImage from "assets/studentloginbackground.png";
import { FC } from "types";
import { PlayEdLogo } from "./PlayEdLogo";

interface Props {
  backgroundColour: string;
  location: OnboardingLocation;
  includeLogo?: boolean;
}

export enum OnboardingLocation {
  register = 1,
  teacherlogin = 2,
  studentlogin = 3,
}

const LoginBackgroundContainer: FC<Props> = ({ backgroundColour, location, children, includeLogo = true }) => (
  <OuterContainer backgroundColour={backgroundColour} location={location}>
    <InnerContainer>
      {includeLogo && <PlayEdLogo />}
      <ChildContainer>{children}</ChildContainer>
    </InnerContainer>
  </OuterContainer>
);

const getBackground = (location: OnboardingLocation) => {
  if (location === OnboardingLocation.register) {
    return signupBackgroundImage;
  }
  if (location === OnboardingLocation.teacherlogin) {
    return teacherBackgroundImage;
  }
  if (location === OnboardingLocation.studentlogin) {
    return studentBackgroundImage;
  }
};

const OuterContainer = styled.div<Props>`
  height: 100%;
  & {
    background-color: ${(props) => props.backgroundColour};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: 100vh;
    background-image: url(${(props) => getBackground(props.location)});
    background-size: cover;
  }
`;

const InnerContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 50px;
`;

const ChildContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export { LoginBackgroundContainer };
