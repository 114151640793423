import { QuestionSetCard } from "components";
import { QuestionSetQuestionList } from "./QuestionSetQuestionList";
import { Column, Spacer, Spinner } from "primitives";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useEffect, useState, ReactNode } from "react";
import { fetchQuestionSetNew } from "slices";
import { defaultRequestState, QuestionSet } from "types";
import { useAppDispatch } from "app/hooks";
import { dispatchAsync } from "utils";
import { QuestionSetActionHook } from "hooks";
import { layoutConstants } from "styles";
import { MediaConstants } from "utils/mediaConstants";
import { UserInfo } from "api";

interface Props {
  questionSetId: string;
  actionHooks?: Array<QuestionSetActionHook>;
  noSetSelectedMessage?: ReactNode;
  userInfo?: UserInfo | null;
  parentElementId?: string;
}

interface ViewProps {
  questionSet: QuestionSet;
  actionHooks?: Array<QuestionSetActionHook>;
  userInfo?: UserInfo | null;
  parentElementId?: string;
}

const QuestionSetDetailView = ({ questionSet, actionHooks, userInfo, parentElementId }: ViewProps) => {
  const [scroll, setScroll] = useState(0);

  const onScroll = () => {
    setScroll((prev) => (prev + 1) % 2);
  };

  return (
    <OuterContainer>
      <ScrollContainer onScroll={onScroll}>
        <QuestionSetCard
          questionSet={questionSet}
          scroll={scroll}
          actionHooks={actionHooks}
          parentElementId={parentElementId}
        />

        <Spacer size={20} />
        <QuestionSetListContainer>
          <QuestionSetQuestionList questionSet={questionSet} />
        </QuestionSetListContainer>
        <Spacer size={1} />
      </ScrollContainer>
    </OuterContainer>
  );
};

const QuestionSetDetails = ({ noSetSelectedMessage, questionSetId, actionHooks, userInfo, parentElementId }: Props) => {
  const [{ loading }, setRequestState] = useState(defaultRequestState);
  const questionDetails = useSelector((state: RootState) =>
    state.questionSets.data ? state.questionSets.data[questionSetId] : null
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (questionSetId) {
      dispatchAsync(dispatch(fetchQuestionSetNew(questionSetId)), setRequestState);
    }
  }, [dispatch, questionSetId]);

  return loading ? (
    <Spinner />
  ) : questionDetails ? (
    <QuestionSetDetailView
      questionSet={questionDetails}
      actionHooks={actionHooks}
      userInfo={userInfo}
      parentElementId={parentElementId}
    />
  ) : (
    <OuterContainer>{noSetSelectedMessage}</OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  height: 100%;
  width: 100%;
  min-width: ${layoutConstants.questionSets.listMinWidth};
  @media ${MediaConstants.laptopL} {
    min-width: ${layoutConstants.questionSets.smallListMinWidth};
  }
`;

const QuestionSetListContainer = styled.div`
  margin-right: 5px;
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export { QuestionSetDetails };
