import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { blurApp, unBlurApp } from "slices";
import { QuestionSet } from "types";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { duplicateQuestionSet as duplicateQuestionSetApi } from "api";

const dispatchDuplicateQuestionSet = (questionSetId: string, actions: QuestionSetActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    dispatch(blurApp());
    const { token } = getTokenFromState(getState);
    const questionSet = await duplicateQuestionSetApi(questionSetId, token)!;
    const questions = questionSet.questions!;
    const sortedQuestions = questions.sort((questionA, questionB) => questionA.order - questionB.order);

    const questionSetWithSortedQuestions = {
      ...questionSet,
      questions: sortedQuestions,
    } as QuestionSet;

    dispatch(
      actions.addSingle({
        id: questionSet.id,
        questionSet: questionSetWithSortedQuestions!,
      })
    );

    return questionSet;
  }, dispatch).finally(() => dispatch(unBlurApp()));

export { dispatchDuplicateQuestionSet };
