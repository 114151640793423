import { DescriptionText, EmailInput, ErrorText, LoadingContent } from "components";
import { useLogin } from "hooks";
import { PrimaryButton } from "primitives/button";
import { Center, Column, Row, Spacer } from "primitives";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useState } from "react";
import { Link } from "react-router-dom";
import { isValidEmail } from "utils";
import { paths } from "textConstants";

export const RequestResetPasswordElement = () => {
  const history = useHistory();
  const { requestResetPassword, error, loading } = useLogin();
  const [hasSentRequest, setHasSentRequest] = useState(false);
  const [email, setEmail] = useState("");

  const handleResetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      setHasSentRequest(true);
      requestResetPassword(email);
    }
  };

  const handleBackToLogin = () => {
    history.push("/login/teacher");
  };

  const RequestCompleteElement = () => (
    <>
      <Column>
        <Center>
          {error ? (
            <ErrorText>There was an error resetting your password. Please try again.</ErrorText>
          ) : (
            <DescriptionText>
              An email has been sent to your email address. Follow the instructions listed in the email to reset your
              account password. If you don't recieve an email in a few minutes, please check the email address you
              entered.
            </DescriptionText>
          )}
        </Center>
      </Column>
      <Spacer size={20} />
      <Center>
        <PrimaryButton onClick={handleBackToLogin}>Back to Log In</PrimaryButton>
      </Center>
    </>
  );

  return (
    <LoadingContent loading={loading}>
      {hasSentRequest ? (
        <RequestCompleteElement />
      ) : (
        <Form>
          <Item>
            <DescriptionText>Enter your email address below to reset password</DescriptionText>
            <Spacer size={20} />
            <EmailInput setEmail={setEmail} />
            <Spacer />
          </Item>
          <Spacer size={20} />
          <Row>
            <Center>
              <PrimaryButton type="submit" disabled={!isValidEmail(email)} onClick={handleResetPassword}>
                Confirm
              </PrimaryButton>
            </Center>
          </Row>
          <Spacer size={20} />
          <Center>
            <Link to={paths.login.teacher.path}>Return to Log In</Link>
          </Center>
        </Form>
      )}
    </LoadingContent>
  );
};

const Item = styled.div`
  width: 100%;
`;
