import { useQueryParams, QuestionSetActionHook } from "hooks";
import { Spacer, Column } from "primitives";
import { FC, useEffect, useState } from "react";
import { resetQuestionSetPageData, fetchQuestionSetListCount } from "slices";
import { defaultRequestState } from "types";
import { QuestionSetTabRow } from "./QuestionSetTabRow";
import { useAppDispatch } from "app/hooks";
import { dispatchAsync } from "utils";
import { layoutConstants } from "styles";
import styled from "styled-components";
import { StringMap } from "types";
import { MediaConstants } from "utils/mediaConstants";
import { QuestionSetListTab } from "./QuestionSetListTab";

interface Props {
  selectedQuestionSetIds: string[];
  focusedQuestionSetId?: string;
  onCreateQuestionSet?: () => void;
  onQuestionSetSelected: (id: string) => void;
  actionHooks?: Array<QuestionSetActionHook>;
}

export type QuestionSetTabName = "mySets" | "archived" | "public" | "favourited";

interface QueryParams extends StringMap {
  tab: string;
}

interface SingleQuery {
  archived?: boolean;
  featured?: boolean;
  isFavourite?: boolean;
  onlyMyOwn: boolean;
}

interface QueryParamsList {
  [id: string]: SingleQuery;
}

const queryParams: QueryParamsList = {
  mySets: {
    archived: false,
    featured: false,
    isFavourite: undefined,
    onlyMyOwn: true,
  },
  archived: {
    archived: true,
    featured: undefined,
    isFavourite: undefined,
    onlyMyOwn: true,
  },
  public: {
    archived: false,
    featured: true,
    isFavourite: undefined,
    onlyMyOwn: false,
  },
  favourited: {
    archived: false,
    featured: undefined,
    isFavourite: true,
    onlyMyOwn: false,
  },
};

const tabToFilterParams = (tab: string) => {
  return queryParams[tab];
};

const QuestionSetList: FC<Props> = ({
  selectedQuestionSetIds,
  focusedQuestionSetId,
  onCreateQuestionSet,
  onQuestionSetSelected,
  actionHooks,
}) => {
  const [questionCountRequestState, setQuestionCountRequestState] = useState(defaultRequestState);

  const defaultQueryParams: QueryParams = {
    tab: "mySets",
  };

  const { params: queryParams, updateParams: updateQueryParams } = useQueryParams<QueryParams>(defaultQueryParams);

  const { tab } = queryParams;

  const dispatch = useAppDispatch();

  const fetchQuestionCounts = async () => {
    dispatch(
      fetchQuestionSetListCount({
        id: "archived",
        ...tabToFilterParams("archived"),
      })
    );

    dispatch(
      fetchQuestionSetListCount({
        id: "favourited",
        ...tabToFilterParams("favourited"),
      })
    );

    dispatch(
      fetchQuestionSetListCount({
        id: "public",
        ...tabToFilterParams("public"),
      })
    );

    dispatch(
      fetchQuestionSetListCount({
        id: "mySets",
        ...tabToFilterParams("mySets"),
      })
    );
  };

  //fetch all question set totals
  useEffect(() => {
    if (!questionCountRequestState.loading) {
      dispatchAsync(fetchQuestionCounts(), setQuestionCountRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tab]);

  const onNewTabSelected = (newTab: string) => {
    if (newTab !== tab) {
      dispatch(resetQuestionSetPageData());
      updateQueryParams({ tab: newTab });
    }
  };

  return (
    <QuestionSetListContainer className="question-set-selector">
      <QuestionSetTabRow
        onCreateQuestionSet={onCreateQuestionSet}
        selectedTab={tab}
        setSelectedTab={onNewTabSelected}
      />
      <Spacer size={5} />
      <QuestionSetListTab
        selectedQuestionSetIds={selectedQuestionSetIds}
        focusedQuestionSetId={focusedQuestionSetId}
        onCreateQuestionSet={onCreateQuestionSet}
        onQuestionSetSelected={onQuestionSetSelected}
        actionHooks={actionHooks}
        filterParams={tabToFilterParams(tab)}
      />
    </QuestionSetListContainer>
  );
};

const QuestionSetListContainer = styled(Column)`
  height: 100%;
  width: 100%;
  min-width: ${layoutConstants.questionSets.listWidth};
  @media ${MediaConstants.laptopL} {
    min-width: ${layoutConstants.questionSets.smallListWidth};
  }
`;

export { QuestionSetList };
