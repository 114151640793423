import { CustomGame, Session } from "types";
import { LocalToken } from "slices";

const doesCustomGameHaveResult = (customGame: CustomGame, studentId: string): boolean => {
  if (!customGame.assignments || !customGame.assignments[0]) {
    return false;
  }

  const studentAssignmentIndex = customGame.assignments.findIndex((assignment) => assignment.student.id === studentId);
  if (studentAssignmentIndex >= 0) {
    return customGame.assignments[studentAssignmentIndex].results.length > 0;
  } else {
    console.error(`No assignment found for student ${studentId} in customGame ${customGame.id}`);
    return false;
  }
};

const hasStudentCompletedSession = (session: Session, studentId: string): boolean => {
  if (!session.customGames || session.customGames.length === 0) {
    return false;
  }

  let hasCompletedSession = true;
  for (let customGame of session.customGames) {
    if (!doesCustomGameHaveResult(customGame, studentId)) {
      hasCompletedSession = false;
    }
  }

  return hasCompletedSession;
};

const getNextIncompleteCustomGame = (session: Session, studentId: string): CustomGame | null => {
  if (!session.customGames || session.customGames.length === 0) {
    return null;
  }

  for (let customGame of session.customGames) {
    if (!doesCustomGameHaveResult(customGame, studentId)) {
      return customGame;
    }
  }

  // Note: After all games have been attempted, we just return the first one.
  // This could be improved to figure out which one has the least attempts
  // and is next, but that's currently not implemented.
  return session.customGames[0];
};

const hasCustomGameAttemptsRemaining = (session: Session, customGameId: string, studentId: string): boolean => {
  if (session.maxAttempts && session.customGames) {
    const customGame = session.customGames.find((customGame) => customGame.id === customGameId);
    if (customGame?.assignments) {
      const assignment = customGame.assignments.find((assignment) => assignment.student.id === studentId);
      if (assignment) {
        const resultsCount = assignment.results.length;

        return resultsCount < session.maxAttempts;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const hasSessionAttemptsRemaining = (session: Session, studentId: string): boolean => {
  if (session.maxAttempts && session.customGames) {
    const resultsCount = session.customGames.filter((customGame) => doesCustomGameHaveResult(customGame, studentId))
      .length;
    const completeAttempts = Math.floor(resultsCount / session.customGames.length);

    return completeAttempts < session.maxAttempts;
  } else {
    return true;
  }
};

const isTokenInLocalStorage = (token: string): boolean => {
  const storedToken = localStorage.getItem("token");
  if (storedToken !== null) {
    const tokenData: LocalToken = JSON.parse(storedToken);
    return token === tokenData.token;
  }
  return false;
};

export {
  doesCustomGameHaveResult,
  hasStudentCompletedSession,
  getNextIncompleteCustomGame,
  hasCustomGameAttemptsRemaining,
  hasSessionAttemptsRemaining,
  isTokenInLocalStorage,
};
