import { Student } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface StudentLoginResponse {
  token: string;
  student: Student;
}

const loginStudent = async (password: string): Promise<{ token: string; userInfo: Student }> => {
  const response = await api.post("/students/login", { password });
  const responseData = response?.data as StudentLoginResponse;
  if (responseData.token) {
    return { token: responseData.token, userInfo: responseData.student };
  } else {
    throw new NetworkError();
  }
};

export { loginStudent };
