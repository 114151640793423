import { SelectableImage } from "components";
import { useQuestionImages } from "hooks/redux/useQuestionImages";
import { PrimaryButton } from "primitives/button";
import { Row, Spinner, Column, Spacer } from "primitives";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { ImageSearch } from "./ImageSearch";
import pluralize from "pluralize";

interface Props {
  visible: boolean;
  excludeFromRandom: (string | null)[];
  hide: () => void;
  onSubmit: (imageUrl: string) => void;
}

const SelectImageModal: FC<Props> = ({ visible, excludeFromRandom, hide, onSubmit }) => {
  const { data: questionImages, loading, resetQuestionImages } = useQuestionImages(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const imageRefs: { [url: string]: HTMLDivElement | null } = {};

  const hideInternal = () => {
    setSelectedImage(null);
    resetQuestionImages();
    hide();
  };

  const FetchRandomImageUrl = () => {
    if (questionImages) {
      const index = Math.floor(Math.random() * questionImages.length);
      return questionImages[index].imageUrl;
    }
    return "";
  };

  return (
    <Modal show={visible} onHide={hideInternal} dialogClassName="full-width-modal">
      <Modal.Header>
        <Column style={{ width: "100%" }}>
          <Modal.Title className="mb-2">Choose an image below</Modal.Title>
          <Row>
            <ImageSearch style={{ width: "100%" }} />
            <Spacer />
            {loading ? <Spinner /> : <BlankSpinner>&nbsp;</BlankSpinner>}
          </Row>
        </Column>
      </Modal.Header>
      <Modal.Body>
        {questionImages && (
          <Row>
            {questionImages.length > 0
              ? `Showing ${questionImages.length} ${pluralize("image", questionImages.length)}`
              : `No images found`}
          </Row>
        )}
        {questionImages && (
          <Row
            style={{
              flexWrap: "wrap",
              maxHeight: "calc(100vh - 400px)",
              overflowY: "auto",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "0px 40px",
            }}
          >
            {questionImages.map((questionImage) => (
              <div key={questionImage.id} ref={(element) => (imageRefs[questionImage.imageUrl] = element)}>
                <SelectableImage
                  key={questionImage.id}
                  imageUrl={questionImage.imageUrl}
                  onClick={() => {
                    setSelectedImage(questionImage.imageUrl);
                  }}
                  style={questionImage.imageUrl === selectedImage ? { border: `5px solid ${colors.primary}` } : {}}
                />
              </div>
            ))}
          </Row>
        )}
        <ButtonContainer>
          <PrimaryButton variant="secondary" onClick={hideInternal}>
            Cancel
          </PrimaryButton>
          <Spacer />
          <PrimaryButton
            disabled={!Boolean(questionImages)}
            onClick={() => {
              const url = FetchRandomImageUrl();
              if (Boolean(url)) {
                setSelectedImage(url);
                onSubmit(url);
                hideInternal();
              }
            }}
          >
            Select Random
          </PrimaryButton>
          <Spacer />
          <PrimaryButton
            disabled={!Boolean(questionImages) || !selectedImage}
            onClick={() => {
              onSubmit(selectedImage!);
              hideInternal();
            }}
          >
            Select Image
          </PrimaryButton>
        </ButtonContainer>
      </Modal.Body>
    </Modal>
  );
};

const useSelectImageModal = () => {
  const [selectImageModalVisible, setSelectImageModalVisible] = useState(false);
  const [onSubmit, setOnSubmit] = useState<(imageUrl: string) => void>(() => () => {});

  const hide = () => setSelectImageModalVisible(false);

  const selectImageModalProps = {
    visible: selectImageModalVisible,
    hide,
    onSubmit,
  };

  return {
    SelectImageModal,
    selectImageModalProps,
    setSelectImageModalVisible,
    setOnSubmitImage: setOnSubmit,
  };
};

const ButtonContainer = styled(Row)`
  width: 100%;
  margin: 20px;
  align-items: center;
  justify-content: center;
`;

const BlankSpinner = styled.div`
  width: 35px;
`;

export { SelectImageModal, useSelectImageModal };
