import { CalendarToday } from "@styled-icons/material";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";
import { FC } from "types";
import { SmallIcon } from "primitives/icons";

interface DateOnlyPickerProps {
  selectedDate?: Date | null;
  maxDate?: Date | null;
  onDateChanged: (newDate: Date) => void;
}

export const DateOnlyPicker: FC<DateOnlyPickerProps> = ({ selectedDate, maxDate, onDateChanged }) => {
  return (
    <OuterContainer>
      <ReactDatePicker selected={selectedDate} onChange={onDateChanged} maxDate={maxDate} dateFormat="dd/MM/yyyy" />
      <CalendarIcon>
        <SmallIcon icon={CalendarToday} />
      </CalendarIcon>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const CalendarIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  pointer-events: none;
`;
