import { useAppDispatch } from "app/hooks";
import { useEffect, useState, useCallback } from "react";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { getProduct } from "slices";
import { SelectSubscription } from "./SelectSubscription";
import { FC } from "types";
import { useLogin } from "hooks";
import { SubscriptionPayment } from "./SubscriptionPayment";
import { createSubscription, updateSubscription as updateSubscriptionApi } from "api/payment";

interface Props {
  paymentCompleteUrl?: string;
}

export const SubscriptionRoutes: FC<Props> = ({ paymentCompleteUrl }) => {
  const { url } = useRouteMatch();
  const [selectedPriceId, setSelectedPriceId] = useState<string>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { token } = useLogin();

  const fetchProduct = useCallback(async () => {
    dispatch(getProduct());
  }, [dispatch]);

  useEffect(() => {
    fetchProduct();

    if (!token) {
      history.push("/register/teacher");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProduct]);

  const selectSubscription = (priceId: string, promoCode?: string) => {
    setSelectedPriceId(priceId);
    createSubscription(token!, priceId, promoCode);
    history.push(`${url}/payment`);
  };

  const updateSubscription = async (priceId: string) => {
    updateSubscriptionApi(token!, priceId);
    exitSubscriptionFlow();
  };

  const paymentComplete = () => {
    exitSubscriptionFlow();
  };

  const exitSubscriptionFlow = () => {
    if (paymentCompleteUrl) {
      history.push(paymentCompleteUrl);
    } else {
      history.push("/teacher/settings/membership");
    }
  };

  return (
    <Switch>
      <Route path={`${url}/update`}>
        <SelectSubscription onSubscriptionSelected={updateSubscription} isCreate={false} />
      </Route>
      <Route path={`${url}/payment`}>
        <SubscriptionPayment onPaymentComplete={paymentComplete} selectedPriceId={selectedPriceId} />
      </Route>
      <Route path={`${url}/select`}>
        <SelectSubscription onSubscriptionSelected={selectSubscription} isCreate={true} />
      </Route>
      <Route path="/">
        <Redirect to={`${url}/select`} />
      </Route>
    </Switch>
  );
};
