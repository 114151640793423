import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { deleteCustomGame as deleteCustomGameApi } from "api";
import { CustomGameActions } from "./customGameSlice";

export interface DeleteCustomGameFromQuestionSetParams {
  customGameId: string;
}

export const dispatchDeleteCustomGame = (
  { customGameId }: DeleteCustomGameFromQuestionSetParams,
  actions: CustomGameActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const customGame = await deleteCustomGameApi({
      token,
      customGameId,
    });

    dispatch(actions.removeSingle({ customGameId: customGame.id }));

    return customGame;
  }, dispatch);
