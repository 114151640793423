import { RootBreadcrumb } from "components/breadcrumb";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { breadcrumbConstants, slugs } from "textConstants";
import { ArticleProvider } from "components/articles";
import { QuickGameListContainer } from "./quickGames";
import { TutorialsProvider } from "components";
import { GameLibraryTours } from "textConstants/tours";

const GameLibraryRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.gameLibrary.title}
        description={""}
        icon={breadcrumbConstants.myGames.icon}
      />
      <ArticleProvider />
      <TutorialsProvider section="game-library" tours={GameLibraryTours} />

      <Switch>
        <Route path={`${path}/${slugs.class}`} component={QuickGameListContainer} />
        <Route path="/">
          <Redirect to={`${path}/${slugs.class}`} />
        </Route>
      </Switch>
    </>
  );
};

export { GameLibraryRoutes };
