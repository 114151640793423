import { Session } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

export interface DeleteSessionParams {
  id: string;
}

const deleteSession = async (token: string, { id }: DeleteSessionParams): Promise<Session> => {
  const response = await api.delete(`/sessions/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const session = response.data;

  if (session) {
    return session;
  } else {
    throw new NetworkError();
  }
};

export { deleteSession };
