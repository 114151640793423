import { FC } from "react";
import { Spinner } from "primitives";
import styled from "styled-components";
import { useBlurOverlay } from "hooks";

const BlurOverlay: FC = ({ children }) => {
  const { appIsBlurred } = useBlurOverlay();
  return (
    <div
      style={{
        backgroundColor: "transparent",
        pointerEvents: appIsBlurred ? "none" : "auto",
      }}
    >
      {appIsBlurred ? <CenteredSpinner /> : null}
      <div style={{ filter: appIsBlurred ? "blur(3px)" : "none" }}>
        {children}
      </div>
    </div>
  );
};

const CenteredSpinner = styled(Spinner)`
  position: fixed;
  z-index: 1000000;
`;

export { BlurOverlay };
