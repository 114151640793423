import { useAppDispatch, useAppSelector } from "app/hooks";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { defaultRequestState, FC, getFirstSessionGameLink } from "types";
import { dispatchAsync } from "utils";
import { fetchSessionByCode as fetchSessionByCodeAction } from "slices";

const sessionCodeLength = 5;

interface Props {}

const StudentSession: FC<Props> = () => {
  const [sessionId, setSessionId] = useState("");
  const { sessionKey } = useParams<{ sessionKey: string }>();
  const session = useAppSelector((state) => state.sessionList.data && state.sessionList.data[sessionId]);
  const [{ loading, error }, setRequestState] = useState(defaultRequestState);
  const studentToken = useAppSelector((state) => state.studentLogin.token);

  const dispatch = useAppDispatch();

  const fetchSessionByCode = useCallback(
    async (code: string) => {
      const { result } = await dispatchAsync(dispatch(fetchSessionByCodeAction(code)), setRequestState);
      if (result) {
        setSessionId(result.id);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!session) {
      if (sessionKey.length === sessionCodeLength) {
        fetchSessionByCode(sessionKey);
      } else {
        // TODO: Fetch the session by id
        setSessionId(sessionKey);
      }
    }
  }, [session, sessionKey, fetchSessionByCode]);

  return (
    <OuterContainer>
      <Button
        disabled={!session || loading || !studentToken}
        href={session ? `${getFirstSessionGameLink(session)}&token=${studentToken}` : undefined}
        target="_blank"
      >
        Play
      </Button>
      {error && <ErrorText>Session not found</ErrorText>}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const ErrorText = styled.p`
  color: ${colors.danger};
`;

export { StudentSession };
