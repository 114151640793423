import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { fetchSession } from "api";

export interface FetchSessionListFullParams {
  ids: string[];
}

const dispatchFetchSessionListFull = ({ ids }: FetchSessionListFullParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async (requestToken) => {
    dispatch(actions.setRequestToken(requestToken));
    const { token } = getTokenFromState(getState);

    let data = [];
    for (let i = 0; i < ids.length; i++) {
      data.push(await fetchSession({ sessionId: ids[i] }, token));
    }

    dispatch(actions.addMany({ data, requestToken }));
    return data;
  }, dispatch);

export { dispatchFetchSessionListFull };
