import { CSSProperties, FC } from "react";
import styled from "styled-components";
import { ImagePreview } from "../../../components";
import { Column, Row, Button } from "../../../primitives";
import { colors } from "../../../styles";
import { Article, ImageSize, DifficultyLevel } from "../../../types";
import { dateWithoutTime } from "../../../utils";

interface Props {
  article: Partial<Article>;
  onArticleClick: (article: Partial<Article>) => void;
}

interface TextAreaProps {
  style?: CSSProperties;
}

const ArticleListItem: FC<Props> = ({ article, onArticleClick }) => {
  const getImageBackgroundColor = (difficulty: DifficultyLevel | undefined) => {
    switch (difficulty) {
      case DifficultyLevel.beginner:
        return colors.brandBlue;
      case DifficultyLevel.intermediate:
        return colors.brandGreen;
      case DifficultyLevel.advanced:
        return colors.brandOrange;
    }
    return colors.brandBlue;
  };

  const TextArea: FC<TextAreaProps> = ({ style }) => (
    <TextAreaContainer style={style}>
      <TitleText>{article.title}</TitleText>
      <DescriptionText>{article.articleDescription}</DescriptionText>
      <Column>
        <ShowMeButton
          onClick={() => {
            onArticleClick(article);
          }}
        >
          Show Me!
        </ShowMeButton>
        <Row style={{ opacity: "50%", paddingTop: "1em" }}>
          {article.modifiedAt && dateWithoutTime(article.modifiedAt)}
        </Row>
      </Column>
    </TextAreaContainer>
  );

  const ImageArea = () => (
    <>
      {article.imageURL && (
        <ImageContainer style={{ backgroundColor: `${getImageBackgroundColor(article.difficultyLevel)}` }}>
          <ImagePreview
            imageUrl={article.imageURL}
            loadedImageSize={ImageSize.Raw}
            style={{ width: "100%", height: "100%", border: "0" }}
            imageSize={"100%"}
          >
            <DifficultyContainer>{`${article.difficultyLevel}`}</DifficultyContainer>
          </ImagePreview>
        </ImageContainer>
      )}
    </>
  );

  return (
    <ArticleListItemContainer>
      {article.imageURL ? (
        <>
          <TextArea />
          <ImageArea />
        </>
      ) : (
        <TextArea style={{ width: "100%" }} />
      )}
    </ArticleListItemContainer>
  );
};

const ArticleListItemContainer = styled(Row)`
  height: 250px;
  min-width: 764px;
  max-width: 764px;
  width: 100%;
  justify-content: space-between;
`;

const TextAreaContainer = styled(Column)`
  width: 48%;
  justify-content: space-between;
  align-content: stretch;
`;

const TitleText = styled(Row)`
  font-size: 24px;
  font-weight: 600;
  line-height: 33.59px;
`;

const DescriptionText = styled(Row)`
  overflow: hidden;
  height: 60px;
`;

const ImageContainer = styled.div`
  width: 48%;
  max-height: 246px;
  display: flex;
  flex-direction: center;
`;

const DifficultyContainer = styled.div`
  background-color: ${colors.dark};
  color: ${colors.white};
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 5px;
  padding: 0 10px;
`;

const ShowMeButton = styled(Button)`
  max-width: 162px;
  border-radius: 25px;
`;

export { ArticleListItem };
