// Will not mutate input array
const changeItemPositionInArray = <T>(
  array: Array<T>,
  currentIndex: number,
  newIndex: number
) => {
  const value = array[currentIndex];

  const arrayWithValueRemoved = [
    ...array.slice(0, currentIndex),
    ...array.slice(currentIndex + 1),
  ];

  return [
    ...arrayWithValueRemoved.slice(0, newIndex),
    value,
    ...arrayWithValueRemoved.slice(newIndex),
  ];
};

export { changeItemPositionInArray };
