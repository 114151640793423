import { MedIcon, SmallIcon } from "primitives/icons";
import { ArrowLeft, People } from "@styled-icons/material";
import { EmptyPanelContainer } from "components";
import { Button } from "primitives/button";
import styled from "styled-components";
import { FC } from "types/FunctionalComponentWithStandardProps";

interface EmptyReviewProps {
  handleCreateClassClicked: () => void;
}

export const EmptyReviewClass: FC<EmptyReviewProps> = ({ handleCreateClassClicked }) => {
  return (
    <EmptyOuterContainer>
      <EmptyPanelContainer
        title="You don't have any classes yet"
        message={
          <>
            Head to the
            <Button onClick={handleCreateClassClicked} className="mx-2" variant="outline-primary">
              <SmallIcon icon={People} style={{ marginRight: "0.25em" }} />
              Class
            </Button>
            tab to make your class
          </>
        }
      />
    </EmptyOuterContainer>
  );
};

interface EmptyClassProps {
  title: string;
  body: string;
}

export const EmptyClass: FC<EmptyClassProps> = ({ title, body }) => {
  return (
    <EmptyClassContainer>
      <EmptyPanelContainer
        title={title}
        message={
          <>
            <MedIcon icon={ArrowLeft} /> {body}
          </>
        }
      />
    </EmptyClassContainer>
  );
};

const EmptyOuterContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const EmptyClassContainer = styled.div`
  flex: 1;
`;
