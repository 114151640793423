import { LoadingContent } from "components";
import { FC, useEffect, useMemo } from "react";
import { Article, defaultRequestState } from "types";
import { useState } from "react";
import { dispatchAsync } from "utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchAllPreviewArticles } from "slices";
import { ArticleListHeader } from "./ArticleListHeader";
import { ArticleListItem } from "./ArticleListItem";
import { Column, Row, Spacer } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { ArticleCategoryList } from "./ArticleCategoryList";
import { generatePath, useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { PlatformTitle } from "textConstants";
import { useQueryParams } from "hooks";

const ArticleListOverview: FC = () => {
  const [{ loading, error, success }, setRequestState] = useState(defaultRequestState);
  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const maxArticlesToShow = 10;
  const {
    params: { testEmpty },
  } = useQueryParams<{ testEmpty?: string }>();
  const articles = useAppSelector((state) => (testEmpty ? undefined : state.articleList.data));
  const articleList = useMemo(() => (articles ? Object.values(articles) : []), [articles]);
  const hasFetchedArticles = useAppSelector((state) => state.articleList.fetchedPreviews);

  useEffect(() => {
    if (!hasFetchedArticles) {
      dispatchAsync(dispatch(fetchAllPreviewArticles()), (value) => {
        setRequestState(value);
      });
    } else {
      setRequestState({
        loading: false,
        success: true,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onArticleClick = (article: Partial<Article>) => {
    if (article.id) {
      const newUrl = generatePath(`${url}/:articleID`, { articleID: article.id });
      history.push(newUrl);
    }
  };

  return (
    <LoadingContent loading={loading} error={error} success={success}>
      <Column style={{ width: "100%" }}>
        <OuterScrollContainer>
          <InnerScrollContainer>
            <ArticleListHeader />
            {articleList.length > 0 ? (
              <>
                <ArticleListContainer>
                  <VerticalBreak />
                  <ListHeaderContainer>
                    <TitleText>The latest tutorials</TitleText>
                    <VerticalBreakSmall />
                    <SubtitleText>
                      In depth explanations and video tutorials of all the latest things to come to {PlatformTitle}
                    </SubtitleText>
                  </ListHeaderContainer>
                  <VerticalBreakSmall />
                  {articleList.slice(0, maxArticlesToShow).map((article) => {
                    return (
                      <div key={article.id}>
                        <ArticleListItem article={article} onArticleClick={onArticleClick} />
                        <VerticalBreak />
                      </div>
                    );
                  })}
                </ArticleListContainer>
                <LinedBreak />
                <VerticalBreak />
                <ListHeaderContainer>
                  <TitleText>Find exactly what you're looking for</TitleText>
                  <VerticalBreakSmall />
                  <SubtitleText>
                    Search through the {PlatformTitle} library to find the tutorial for specific features
                  </SubtitleText>
                </ListHeaderContainer>
                <ArticleListContainer>
                  <ArticleCategoryList articles={articleList} onArticleClick={onArticleClick} />
                </ArticleListContainer>
                <VerticalBreak />
              </>
            ) : (
              <>
                <VerticalBreak />
                <ListHeaderContainer>
                  <TitleText>No articles yet. More coming soon!</TitleText>
                </ListHeaderContainer>
              </>
            )}
          </InnerScrollContainer>
        </OuterScrollContainer>
      </Column>
    </LoadingContent>
  );
};

const InnerScrollContainer = styled(Column)`
  height: 100%;
`;

const OuterScrollContainer = styled.div`
  flex: 1;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1em;
`;

const ListHeaderContainer = styled(Column)`
  height 100px;
  width: 100%;  
`;

const ArticleListContainer = styled(Column)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(Row)`
  font-size: 30px;
  font-weight: 600;
  line-height: 41.98px;
  justify-content: center;
`;

const SubtitleText = styled(Row)`
  justify-content: center;
`;

const VerticalBreakSmall = styled(Spacer)`
  height: 20px;
`;

const VerticalBreak = styled(Spacer)`
  height: 60px;
`;

const LinedBreak = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colors.darkText};
  opacity: 50%;
`;

export { ArticleListOverview };
