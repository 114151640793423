import { FC, UserAction } from "types";
import { ActionButton, Props as ActionButtonProps } from "./ActionButton";
import { MoreHoriz } from "@styled-icons/material";
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { toNumber } from "lodash";
import { VerticalLine } from "primitives/VerticalLine";
import classNames from "classnames";
import { useLogin, useRequiresLoginModal } from "hooks";
import { colors } from "styles";
import { SmallIcon } from "primitives/icons";
import { PlatformTitle } from "textConstants";

interface TabContainerProps {
  innerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

export const TabContainer: React.FC<TabContainerProps> = ({ innerRef, children }) => {
  return <TabContainerDiv ref={innerRef}>{children}</TabContainerDiv>;
};

const TabContainerDiv = styled.div`
  display: flex;
  align-items: stretch;
  min-width: 1px;
  & > * {
    flex-shrink: 0;
  }
`;

interface MenuContainerProps {
  innerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

export const MenuContainer: React.FC<MenuContainerProps> = ({ innerRef, children }) => {
  return <MenuContainerDiv ref={innerRef}>{children}</MenuContainerDiv>;
};

const MenuContainerDiv = styled.div`
  display: flex;
  align-items: stretch;
  position: relative;
`;

interface Props {
  actions: ActionButtonProps[];
  scroll?: number; // Used to close the dropdown on scroll
  parentElementId?: string;
  color?: string;
  hoverColor?: string;
  hoverBackground?: string;
  className?: string;
  requiresLoginMessage?: string[];
}

const MoreAction: FC<{ onClick: (e: any) => void }> = forwardRef(({ onClick }, ref) => (
  <SeparatedButton ref={ref as any} onClick={onClick}>
    <VerticalLine className="m-2" />
    <ActionButton
      action={{
        id: "more",
        name: "More",
        action: async () => {},
        icon: MoreHoriz,
      }}
      variant="link"
    >
      More
    </ActionButton>
  </SeparatedButton>
));

export const ActionButtonBar: FC<Props> = ({
  className,
  actions,
  scroll,
  parentElementId,
  requiresLoginMessage,
  ...rest
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [visibleActionIndices, setVisibleActionIndices] = useState<Array<number>>([]);
  const [measuringRender, setMeasuringRender] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const overflowRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = Boolean(useLogin()?.token);

  const defaultLoginMessage = [
    `You need to create a ${PlatformTitle} account in order to favourite question sets, create your own question sets, or create your own games.`,
    "Sign up now!",
  ];
  const [displayModal, Modal] = useRequiresLoginModal({ modalBody: requiresLoginMessage || defaultLoginMessage });

  const onToggle = (show: boolean) => {
    setShowDropdown(show);
  };

  useEffect(() => {
    setShowDropdown(false);
  }, [scroll]);

  // 1. setup
  useEffect(() => {
    setIsMounted(true);
    setMeasuringRender(true);
  }, []);

  // 2. Measure DOM elements
  useLayoutEffect(() => {
    if (measuringRender) {
      // Get the child tab elements
      const tabElements = containerRef.current ? Array.from(containerRef.current.children) : [];

      let stopWidth = 0;
      const visible: Array<number> = [];
      tabElements.forEach((tab, index) => {
        // Don't count the width of the More button unless it will be visible
        if (visible.length === tabElements.length - 1) {
          stopWidth -= buttonRef.current?.offsetWidth || 0;
        }

        stopWidth += toNumber((tab as HTMLElement).offsetWidth);
        if (toNumber(containerRef.current?.offsetWidth) >= stopWidth) {
          visible.push(index);
        }
      });
      setVisibleActionIndices(visible);
      setMeasuringRender(false);
    }
  }, [measuringRender]);

  // ** Trigger a measuringRender when the window is resized
  useEffect(() => {
    function handleResize() {
      setMeasuringRender(true);
      setShowDropdown(false);
    }
    window.addEventListener("resize", handleResize);
    return function cleanUp() {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ** Trigger a measuringRender when the tabs (children) prop changes
  useEffect(() => {
    setMeasuringRender(true);
  }, [actions.length]);

  // Collect the visible and overflow tabs for rendering
  let visibleActions: Array<ActionButtonProps> = [];
  const overflowActions: Array<ActionButtonProps> = [];
  if (!isMounted || measuringRender) {
    visibleActions = [...actions] || [];
  } else {
    actions?.forEach((action, index) => {
      if (visibleActionIndices.includes(index)) {
        visibleActions.push(action);
      } else {
        overflowActions.push(action);
      }
    });
  }

  const doAction = (action: UserAction) => {
    if (action.requiresLogin && !isLoggedIn) {
      displayModal();
    } else {
      action.action();
    }
  };

  return (
    <ActionsContainer className={classNames(className)}>
      <TabContainer innerRef={containerRef}>
        {visibleActions.map(({ action }, index) => (
          <SeparatedActionButton key={index}>
            <ActionButton className="px-2" action={action} onActionNotAllowed={displayModal} variant="link" {...rest}>
              {action.name}
            </ActionButton>
            <VerticalLine className="m-2 vertical-line" />
          </SeparatedActionButton>
        ))}
      </TabContainer>

      {(measuringRender || overflowActions.length > 0) && (
        <MenuContainer innerRef={overflowRef}>
          <Dropdown show={showDropdown} onToggle={onToggle} className="hotdog-menu-button">
            <div ref={buttonRef}>
              <Dropdown.Toggle as={MoreAction} id="dropdown-custom-components" />
            </div>
            {createPortal(
              <Dropdown.Menu align={"left"} style={{ zIndex: 100000 }}>
                {overflowActions.map(({ action }) => (
                  <ActionDropdownButton
                    onClick={(e: Event) => e.stopPropagation()}
                    onSelect={() => doAction(action)}
                    key={action.id}
                  >
                    {action.icon && (
                      <SmallIcon className="mr-2 mt-n1" icon={action.icon} color={action.iconColor || colors.primary} />
                    )}
                    {action.name}
                  </ActionDropdownButton>
                ))}
              </Dropdown.Menu>,
              (parentElementId && document.getElementById(parentElementId)) || document.getElementById("root")!
            )}
          </Dropdown>
        </MenuContainer>
      )}

      <Modal />
    </ActionsContainer>
  );
};

const ActionDropdownButton = styled(Dropdown.Item)`
  font-size: 12px;
  heiight: 28px;
  padding: 1px 0 0 var(--sp-2);
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const SeparatedButton = styled.div`
  display: flex;
  align-items: center;
`;

const SeparatedActionButton = styled(SeparatedButton)`
  display: flex;
  align-items: center;
  &:last-child .vertical-line {
    display: none;
  }
`;
