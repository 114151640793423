import { toggleLiveChat } from "hooks";
import { FC } from "types";
import { useHelpMenu } from "hooks";
import { StyledHelpMenuItem, BreadcrumbMenu } from "./BreadcrumbMenu";
import { SmallIcon } from "primitives/icons";
import { HelpIcon } from "icons";

interface Props {
  id: string;
}

export const HelpMenu: FC<Props> = ({ id }) => {
  const { portalOpen, setPortalOpen } = useHelpMenu();

  const handleClickLiveHelp = () => {
    toggleLiveChat();
  };

  return (
    <BreadcrumbMenu
      className="help-button"
      id={id}
      portalOpen={portalOpen}
      setPortalOpen={setPortalOpen}
      toggleShowing={true}
      toggleChildren={<SmallIcon icon={HelpIcon} />}
      dropdownMenuTitle={"Help & Support"}
      dropdownMenuChildren={
        <StyledHelpMenuItem className="borderless-dropdown" onClick={handleClickLiveHelp}>
          Live Help Chat
        </StyledHelpMenuItem>
      }
    />
  );
};
