import { keys } from "lodash";
import { breadcrumbConstants, Names, slugs } from "textConstants";
import { Tour } from "types";
import { runOnElements, disableElement } from "utils/tourUtils";
import { TourStepContainer } from "./tourComponents";

export const ClassTours: Tour[] = [
  {
    id: "create class",
    name: "Create your class",
    startUrl: `/${slugs.teacher}/${slugs.class}`,
    runAutomatically: (location, state) =>
      location.pathname.startsWith(`/${slugs.teacher}/${slugs.class}`) &&
      !location.pathname.includes(slugs.new) &&
      !location.pathname.includes(slugs.students) &&
      !location.pathname.includes(slugs.student) &&
      !location.pathname.includes(slugs.print) &&
      Object.keys(state.classList.data || {}).length === 0,
    disableInHelpMenu: (_location, state) => keys(state.classList.data).length > 0,
    steps: [
      {
        target: "#empty-tour-target",
        content: "",
        autoProgressCheck: () => document.body.contains(document.querySelector(".create-class-button")),
        isEmpty: true,
      },
      {
        target: ".create-class-button",
        content: (
          <p>
            Let's create your class! <span className="text-primary">Click the + button</span>.
          </p>
        ),
        autoProgressCheck: (location) => location.pathname.includes(`/${slugs.new}`),
        hideNext: true,
        hideBack: true,
      },
      {
        target: ".create-class-modal",
        title: "1. Create a class",
        content: (
          <p>
            Name your class and <span className="text-primary">click "Confirm"</span>.
          </p>
        ),
        onStart: () => runOnElements(".create-class-modal .btn-secondary", disableElement),
        autoProgressCheck: (location) => location.pathname.includes(`/${slugs.students}/${slugs.add}`),
        hideNext: true,
        hideBack: true,
      },
    ],
  },
  {
    id: "add students",
    name: "Add Students",
    startUrl: `/${slugs.teacher}/${slugs.class}`,
    runAutomatically: (location, state) =>
      location.pathname.startsWith(`/${slugs.teacher}/${slugs.class}`) &&
      !location.pathname.includes(slugs.new) &&
      !location.pathname.includes(slugs.print) &&
      state.classList != null &&
      state.classList.data != null &&
      Object.values(state.classList.data).length > 0,
    disableInHelpMenu: (location, state) =>
      !state.classList || !state.classList.data || Object.values(state.classList.data).length === 0,
    steps: [
      {
        target: "#empty-tour-target",
        content: "",
        autoProgressCheck: () =>
          document.body.contains(document.querySelector(".import-button,#addStudents-action-button")),
        isEmpty: true,
      },
      {
        target: "#addStudents-action-button",
        title: "2. Add some students",
        content: (
          <p>
            <span className="text-primary">Click the 'Add Students' button</span> to add students to an existing class.
          </p>
        ),
        autoProgressCheck: (location) => location.pathname.includes(`/${slugs.students}/${slugs.add}`),
        hideNext: true,
        hideBack: true,
      },
      {
        target: ".import-button,#addStudents-action-button",
        title: "2. Add some students",
        content: (
          <p>
            Let's add some students! <span className="text-primary">Click the 'Import' button</span> to add students in
            bulk.
          </p>
        ),
        autoProgressCheck: (location) => location.pathname.includes(`/${slugs.students}/${slugs.add}/${slugs.import}`),
        hideNext: true,
        hideBack: true,
      },
      {
        target: ".import-students-modal",
        title: "2. Add some students",
        content: (
          <TourStepContainer>
            <p>
              Add multiple students at once by typing their names, with each student separated by a line break/enter
            </p>
            <p>You can also copy student names here directly from a spreadsheet</p>
            <p>
              Add a few students and <span className="text-primary">click "Confirm"</span> to continue
            </p>
          </TourStepContainer>
        ),
        onStart: () => runOnElements(".import-students-modal .btn-secondary", disableElement),
        autoProgressCheck: (location) => !location.pathname.includes(`/${slugs.students}/${slugs.add}/${slugs.import}`),
        hideNext: true,
        hideBack: true,
      },
      {
        target: ".swap-names-button",
        title: "3. Finalise your students",
        content: `You can swap the first and last names of the students by pressing this button.`,
        hideBack: true,
      },
      {
        target: ".student-table",
        title: "3. Finalise your students",
        content: `You can edit, add and remove more students before you confirm.`,
      },
      {
        target: ".unsaved-changes-container #apply-action-button",
        title: "3. Finalise your students",
        content: (
          <p>
            <span className="text-primary">Click "Save"</span> to add these students to your class.
          </p>
        ),
        autoProgressCheck: (location) => !location.pathname.includes(`/${slugs.students}/${slugs.add}`),
        waitToProceed: 1000,
        hideNext: true,
      },
    ],
  },
  {
    id: "tourClass",
    name: "View your class",
    startUrl: `/${slugs.teacher}/${slugs.class}`,
    runAutomatically: (location, state) =>
      location.pathname.startsWith(`/${slugs.teacher}/${slugs.class}`) &&
      !location.pathname.includes(slugs.new) &&
      !location.pathname.includes(slugs.students) &&
      !location.pathname.includes(slugs.print) &&
      state.classList != null &&
      state.classList.data != null &&
      Object.values(state.classList.data).length > 0,
    disableInHelpMenu: (location, state) =>
      !state.classList || !state.classList.data || Object.values(state.classList.data).length === 0,
    steps: [
      {
        target: "#empty-tour-target",
        content: "",
        autoProgressCheck: () => document.body.contains(document.querySelector(".classes-overview")),
        isEmpty: true,
      },
      {
        target: ".classes-overview",
        title: "4. View your class",
        content: `Welcome to your class`,
        hideBack: true,
        disableClickthrough: true,
      },
      {
        target: ".class-settings",
        title: "4. View your class",
        content: `Here on the left, you can find information about the class, and take class actions`,
        disableClickthrough: true,
        placement: "right",
      },
      {
        target: ".print-button",
        title: "5. Student Cards",
        content: (
          <p>
            <span className="text-primary">Click "Print Login Cards"</span> to generate a page of your students'
            passwords.
          </p>
        ),
        autoProgressCheck: (location) => location.pathname.includes(`/${slugs.print}`),
        waitToProceed: 300,
        hideNext: true,
        hideBack: true,
      },
      {
        target: "#empty-tour-target",
        content: "",
        autoProgressCheck: () => document.body.contains(document.querySelector(".print-password-sheet")),
        isEmpty: true,
      },
      {
        target: ".print-password-sheet",
        title: "5. Student Cards",
        content: `Here is a list of all your students in this class, along with their passwords and an auto-login QR code.`,
        hideBack: true,
      },
      {
        target: ".print-button",
        title: "5. Student Cards",
        content: `You can click this button to print these off as cards`,
        disableClickthrough: true,
      },
      {
        target: ".class-name-breadcrumb .breadcrumb-title",
        title: "5. Student Cards",
        content: (
          <p>
            <span className="text-primary">Click your class name</span> to return to the class page.
          </p>
        ),
        autoProgressCheck: (location) => !location.pathname.includes("/print"),
        hideNext: true,
        hideBack: true,
      },
      {
        target: "#empty-tour-target",
        content: "",
        autoProgressCheck: () => document.body.contains(document.querySelector(".student-grid-row")),
        isEmpty: true,
      },
      {
        target: ".student-grid-row",
        title: "6. Student details",
        content: (
          <p>
            These are your students. <span className="text-primary">Click on a student</span> to view and edit their
            details
          </p>
        ),
        autoProgressCheck: (location) => location.pathname.includes("student"),
        waitToProceed: 300,
        hideNext: true,
        hideBack: true,
      },
      {
        target: ".student-info",
        title: "6. Student details",
        content: `From here, you can review each student's password, and change their details`,
        disableClickthrough: true,
        hideBack: true,
      },
      {
        target: ".modal-content .modal-footer .btn",
        title: "6. Student details",
        content: (
          <p>
            <span className="text-primary">Click "Close"</span> to continue.
          </p>
        ),
        hideNext: true,
        hideBack: true,
        autoProgressCheck: (location) => !location.pathname.includes(slugs.student),
      },
      {
        target: `.navbar-container .${slugs.myGames}`,
        title: "Tutorial Complete!",
        content: `Move to the ${breadcrumbConstants.myGames.title} tab to create your first ${Names.Session}!`,
        autoProgressCheck: (location) => location.pathname.includes(`${slugs.teacher}/${slugs.myGames}`),
        isEnd: true,
      },
    ],
  },
];
