import { Grouping } from "types";
import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { ProcessGrouping, UnprocessedGrouping } from "api/deserialisation";
import { omit } from "lodash";

interface UpdateGroupingParams {
  token: string;
  groupingId: string;
  updateData: Grouping;
}

export const updateGrouping = async ({ token, groupingId, updateData }: UpdateGroupingParams): Promise<Grouping> => {
  const sanitisedUpdateData = {
    ...updateData,
    partitions: updateData.partitions
      ? updateData.partitions
          .filter((partition) => !partition.isDeleted)
          .map((partition) => (partition.isAdded ? omit(partition, "id") : partition))
          .map((partition) => omit(partition, "isDeleted", "isAdded", "isUpdated"))
      : [],
  };

  const response = await api.put(`/groupings/${groupingId}`, sanitisedUpdateData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const grouping: UnprocessedGrouping = response.data;

  if (grouping) {
    return ProcessGrouping(grouping);
  } else {
    throw new NetworkError();
  }
};
