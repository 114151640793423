import { FC } from "types";
import { Form } from "react-bootstrap";
import { FormErrorFeedback } from "components";
import { Row } from "primitives";
import { forwardRef, RefAttributes } from "react";

interface Props {
  placeholder: string;
  initialInput: string | undefined;
  onInputUpdated: (value: string) => void;
  errorFeedback: any;
  canShowFeedback?: boolean;
  forceSingleLine?: boolean;
  offsetPadding?: boolean;
  onEnterPressed?: () => void;
}

const InputControlWithError: FC<Props> = ({
  placeholder,
  initialInput,
  onInputUpdated,
  errorFeedback,
  canShowFeedback = true,
}) => {
  return (
    <>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={initialInput}
        onChange={(e) => onInputUpdated(e.target.value)}
        isInvalid={canShowFeedback && errorFeedback}
      />

      <FormErrorFeedback errorMessage={canShowFeedback ? errorFeedback : null} />
    </>
  );
};

type CharacterLimitedProps = Props & { characterLimit?: number } & RefAttributes<HTMLInputElement>;

// Note: Only the single line input forwards its ref. There's some strange type issues
// with the multiple types of return that are currently beyond me to figure out.
// If somone needs a ref to the textarea element, they will need to improve the
// system to be more generic.
const CharacterLimitedInputControlWithError: FC<CharacterLimitedProps> = forwardRef<
  HTMLInputElement,
  CharacterLimitedProps
>(
  (
    {
      characterLimit,
      placeholder,
      initialInput,
      onInputUpdated,
      errorFeedback,
      canShowFeedback = true,
      forceSingleLine,
      offsetPadding,
      onEnterPressed,
      children,
    },
    ref
  ) => {
    const defaultCharacterLimit = 240;
    const limit = characterLimit || defaultCharacterLimit;
    const rows = 3;

    const handleOnKeyUp = (event: any) => {
      if (event.key === "Enter" && onEnterPressed) {
        onEnterPressed();
      }
    };

    return (
      <>
        <Row style={{ width: "100%" }}>
          {forceSingleLine ? (
            <Form.Control
              ref={ref}
              type="text"
              value={initialInput}
              onChange={(e) => onInputUpdated(e.target.value)}
              isInvalid={canShowFeedback && errorFeedback}
              style={{ height: "100%", paddingRight: offsetPadding ? "140px" : "0" }}
              placeholder={placeholder}
              onKeyUp={handleOnKeyUp}
            />
          ) : (
            <Form.Control
              type="textarea"
              as="textarea"
              value={initialInput}
              onChange={(e) => onInputUpdated(e.target.value)}
              isInvalid={canShowFeedback && errorFeedback}
              rows={rows}
              style={{ height: "100%", paddingRight: offsetPadding ? "140px" : "0" }}
              placeholder={placeholder}
              onKeyUp={handleOnKeyUp}
            />
          )}
          {children}
        </Row>
        <Form.Text style={{ textAlign: "right" }}>{`${initialInput?.trim().length || "0"}/${limit}`}</Form.Text>
        <FormErrorFeedback errorMessage={canShowFeedback ? errorFeedback : null} />
      </>
    );
  }
);

export { InputControlWithError, CharacterLimitedInputControlWithError };
