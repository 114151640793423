import { FC, ReactNode, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Row } from "primitives";
import styled from "styled-components";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import { MedIcon } from "primitives/icons";
import { StyledIcon } from "@styled-icons/styled-icon";
import classNames from "classnames";

export interface ListTabProps {
  key: string;
  text: string;
  textColor?: string;
  icon?: StyledIcon;
  iconColour?: string;
}

interface Props {
  children?: ReactNode;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  tabs: ListTabProps[];
  totalsSelector: (state: RootState) => { [id: string]: number } | null;
  className?: string;
}

const ListTabRow: FC<Props> = ({ children, tabs, selectedTab, setSelectedTab, totalsSelector, className }) => {
  const totals = useSelector(totalsSelector);
  const [listStrings, SetListStrings] = useState<{ [id: string]: string } | null>(null);

  const maxNumberShown = 99;

  useEffect(() => {
    if (totals) {
      let curListStrings: { [id: string]: string } = {};

      for (const id in totals) {
        if (totals[id] !== undefined) {
          curListStrings[id] = totals[id] <= maxNumberShown ? `(${totals[id]})` : `(${maxNumberShown}+)`;
        }
      }

      SetListStrings(curListStrings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals]);

  return (
    <OuterContainer className={classNames(className)}>
      <TabsContainer
        className="list-tabs"
        defaultActiveKey=""
        activeKey={selectedTab}
        // @ts-ignore
        onSelect={(k: TabNames) => setSelectedTab(k)}
      >
        {tabs.map(({ key, icon, textColor, iconColour, text }) => (
          <Tab
            key={key}
            id={key}
            eventKey={key}
            title={
              <TabContent>
                {icon && <MedIcon icon={icon} color={iconColour} />}
                <p style={{ color: textColor }}>{`${text} ${
                  listStrings && listStrings[key] !== undefined ? listStrings[key] : "(...)"
                }`}</p>
              </TabContent>
            }
          />
        ))}
      </TabsContainer>
      {children}
    </OuterContainer>
  );
};

const OuterContainer = styled(Row)`
  border-bottom: 1px solid black;
`;

const TabContent = styled(Row)`
  justify-content: center;
  align-items: center;
  gap 5px;
`;

const TabsContainer = styled(Tabs)`
  flex: 1;
  align-content: flex-end;
  border-bottom: 0px;
`;

export { ListTabRow };
