import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { SubjectTagBadges, TagSearch } from "components";
import { Center, Column, PrimaryButton, Row, Spacer } from "primitives";
import { FC, Tag } from "types";
import { Props as GradeTagsSelectorProps } from "components/editQuestionSetModal/gradeTagsSelector";

interface TagSelection {
  tag: Tag;
  selected: boolean;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  selectedSubjectTags: TagSelection[];
  setSelectedSubjectTags: (selectedTags: TagSelection[]) => void;
  gradeTagsFilter: FC<GradeTagsSelectorProps>;
  gradeTagsFilterProps: any;
}

export const QuestionSetListFilterModal: FC<Props> = ({
  visible,
  onClose,
  selectedSubjectTags,
  setSelectedSubjectTags,
  gradeTagsFilter: GradeTagsFilter,
  gradeTagsFilterProps,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const resetStateAndHide = (e?: MouseEvent) => {
    e?.preventDefault();
    setIsClosing(true);
  };

  const onModalHidden = () => {
    setIsClosing(false);
    onClose();
  };

  return (
    <Modal
      show={visible && !isClosing}
      onHide={resetStateAndHide}
      onExited={onModalHidden}
      size="lg"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Edit Question Set Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OuterContainer className="form-column-space">
          <div>
            <Form.Label>Year Level</Form.Label>
            <Row>
              <GradeTagsFilter {...gradeTagsFilterProps} singleSelect={true} useShortNames={true} />
            </Row>
          </div>
          <div>
            <Form.Label>Filter by Subject</Form.Label>
            <TagSearch tags={selectedSubjectTags} setTags={setSelectedSubjectTags} highlightChildren={true} />
            <Row>
              <SubjectTagBadges subjectTags={selectedSubjectTags} setSubjectTags={setSelectedSubjectTags} />
            </Row>
          </div>
        </OuterContainer>
      </Modal.Body>
      <Modal.Footer>
        <Center>
          <PrimaryButton variant="secondary" onClick={() => resetStateAndHide()}>
            Close
          </PrimaryButton>
          <Spacer />
          <PrimaryButton variant="primary" onClick={() => resetStateAndHide()}>
            Confirm
          </PrimaryButton>
        </Center>
      </Modal.Footer>
    </Modal>
  );
};

const OuterContainer = styled(Column)`
  min-height: 400px;
`;
