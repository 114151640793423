import { FC } from "types";
import { useArticleMenu } from "hooks";
import { BreadcrumbMenuHeader, BreadcrumbMenuItem } from "./BreadcrumbMenuItem";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

interface MenuProps {
  title: string;
}

export const ArticleMenuHeader: FC<MenuProps> = ({ title }) => {
  const { portalNode } = useArticleMenu();

  return <BreadcrumbMenuHeader title={title} portalNode={portalNode} />;
};

export const ArticleMenuItem: FC<Props> = ({ title, onClick, disabled }) => {
  const { portalNode } = useArticleMenu();

  return <BreadcrumbMenuItem title={title} onClick={onClick} disabled={disabled} portalNode={portalNode} />;
};
