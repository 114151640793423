import { FC } from "react";
import { CSSProperties } from "styled-components";

interface Props {
  size?: number;
  style?: CSSProperties;
}

const Spacer: FC<Props> = ({ size, style }) => (
  <div style={{ margin: size ? size : 20, ...style }} />
);

export { Spacer };
