import { OpenInNew } from "@styled-icons/material";
import { HorizontalLine, Row } from "primitives";
import { PrimaryButton } from "primitives/button";
import { MedIcon } from "primitives/icons";
import styled from "styled-components";
import { colors, layoutConstants } from "styles";
import { Names } from "textConstants";
import { FC } from "types";
import { MediaConstants } from "utils/mediaConstants";
import { useTutorialState } from "hooks";

interface Props {
  currentStepNumber: number;
  highestStepNumber: number;
  canProceed: boolean;
  isLoading: boolean;
  playGameLink?: string | null;
  onReturnPressed?: () => void;
  onNextPressed?: () => void;
  onStepPressed?: (stepNumber: number) => void;
}

interface Step {
  $stepNumber: number;
  $currentStepNumber: number;
  $isInteractive: boolean;
}

interface Interactive {
  $isInteractive: boolean;
}

const getColour = (stepNumber: number, currentStepNumber: number) => {
  if (currentStepNumber >= stepNumber) return colors.primary;
  return colors.secondary;
};

interface StepProps {
  highestStepNumber: number;
  interactable: boolean;
  currentStep: number;
  stepNumber: number;
  stepText: string;
  stepPressed: (step: number) => void;
}

const ProgressStep: FC<StepProps> = ({
  highestStepNumber,
  interactable,
  currentStep,
  stepNumber,
  stepText,
  stepPressed,
}) => {
  const isInteractive = stepNumber <= Math.max(highestStepNumber, currentStep) && interactable;
  return (
    <StepContainer $isInteractive={isInteractive} onClick={() => stepPressed(stepNumber)}>
      <StepNumber $isInteractive={isInteractive} $stepNumber={stepNumber} $currentStepNumber={currentStep}>
        {stepNumber + 1}
      </StepNumber>
      <StepText $isInteractive={isInteractive} $stepNumber={stepNumber} $currentStepNumber={currentStep}>
        {stepText}
      </StepText>
    </StepContainer>
  );
};

const CustomGameProgress: FC<Props> = ({
  currentStepNumber,
  highestStepNumber,
  canProceed,
  isLoading,
  playGameLink,
  onReturnPressed,
  onNextPressed,
  onStepPressed,
}) => {
  const { isPlayingTutorial } = useTutorialState();
  const currentStep = currentStepNumber;
  // Used to prevent interaction when on the Review step
  const interactable = currentStep <= 2 && !isLoading;

  const stepPressed = (stepNumber: number) => {
    if (onStepPressed && stepNumber <= highestStepNumber && interactable) {
      onStepPressed(stepNumber);
    }
  };

  return (
    <OuterContainer className="custom-game-progress">
      <ProgressContainer>
        <ProgressStep
          highestStepNumber={highestStepNumber}
          interactable={interactable && !isPlayingTutorial}
          currentStep={currentStep}
          stepNumber={0}
          stepText={"Choose Questions"}
          stepPressed={stepPressed}
        />
        <HorizontalLine color={getColour(1, currentStep)} />
        <ProgressStep
          highestStepNumber={highestStepNumber}
          interactable={interactable && !isPlayingTutorial}
          currentStep={currentStep}
          stepNumber={1}
          stepText={`Choose ${Names.GameTemplate}`}
          stepPressed={stepPressed}
        />
        <HorizontalLine color={getColour(2, currentStep)} />
        <ProgressStep
          highestStepNumber={highestStepNumber}
          interactable={interactable && !isPlayingTutorial}
          currentStep={currentStep}
          stepNumber={2}
          stepText={"Settings"}
          stepPressed={stepPressed}
        />
      </ProgressContainer>
      <ButtonOuterContainer>
        <SmallPrimaryButton variant="secondary" disabled={isLoading || isPlayingTutorial} onClick={onReturnPressed}>
          Return
        </SmallPrimaryButton>
        {playGameLink ? (
          <SmallPrimaryButton className="button play-game" href={playGameLink} target="_blank">
            Play
            <MedIcon icon={OpenInNew} />
          </SmallPrimaryButton>
        ) : (
          <SmallPrimaryButton
            className="next-finish-button"
            disabled={!canProceed || isLoading}
            onClick={onNextPressed}
          >
            {currentStepNumber === 2 ? "Finish" : "Next"}
          </SmallPrimaryButton>
        )}
      </ButtonOuterContainer>
    </OuterContainer>
  );
};

const SmallPrimaryButton = styled(PrimaryButton).attrs({
  $minWidth: "140px",
  height: "40px",
  fontSize: "14px",
})``;

const ProgressContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sp-3);
`;

const ButtonOuterContainer = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: var(--sp-2);
`;

const OuterContainer = styled(Row)`
  border-top: 1px solid ${colors.innerBorder};
  width: 100%;
  height: ${layoutConstants.customGameProgressHeight};
  align-items: center;
  gap: var(--sp-3);

  padding: 0 var(--sp-4);
  background-color: white;
  min-height: 60px;
`;

const StepContainer = styled(Row)<Interactive>`
  cursor: ${(props) => (props.$isInteractive ? "pointer" : "default")};
  align-items: center;
  gap: 10px;
  padding: var(--sp-2);
  border-radius: 5px;
  @media ${MediaConstants.tablet} {
    width: 120px;
  }

  :hover {
    background-color: ${(props) => (props.$isInteractive ? colors.transparentPrimary : "none")};
  }
`;

const StepNumber = styled.p<Step>`
  color: ${(props) =>
    props.$stepNumber <= props.$currentStepNumber ? "white" : props.$isInteractive ? colors.primary : colors.secondary};

  font-size: 14pt;
  min-width: 40px;

  @media ${MediaConstants.tablet} {
    font-size: 10pt;
    min-width: 30px;
    max-height: 30px;
    padding: 0px;
  }

  font-weight: 600;
  border: 3px solid ${(props) => (props.$isInteractive ? colors.primary : colors.secondary)};
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  background-color: ${(props) =>
    props.$stepNumber === props.$currentStepNumber
      ? colors.primaryActive
      : props.$stepNumber < props.$currentStepNumber
      ? colors.primary
      : "transparent"};
`;

const StepText = styled.p<Step>`
  color: ${(props) => (props.$isInteractive ? colors.primary : colors.secondary)};
  white-space: nowrap;
  font-size: 12pt;

  @media ${MediaConstants.tablet} {
    font-size: 8pt;
  }
`;

export { CustomGameProgress };
