import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { ServerPronunciationSet } from "types";

export const updatePronunciationSet = async (
  pronunciationSetId: string,
  pronunciationSet: Partial<ServerPronunciationSet>,
  token: string
) => {
  const response = await api.put(
    `/pronunciationsets/${pronunciationSetId}`,
    {
      name: pronunciationSet.name,
      ttsOverrides: pronunciationSet.ttsOverrides,
      loadByDefault: pronunciationSet.loadByDefault,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  if (response.data) {
    return response.data as ServerPronunciationSet;
  } else {
    throw new NetworkError();
  }
};
