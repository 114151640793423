import { Names, PlatformTitle } from "./main";

const helpMessages = {
  lessons: {
    time: {
      disabledLabel: `Enable this to set a start and end time, or leave this blank to start the ${Names.session} manually`,
    },
    maxAttempts: {
      disabledLabel: "Enable this to limit how many times a student can attempt a lesson",
    },
  },
  studentCustomGame: {
    customGameIssues: {
      title: "Something went wrong!",
      generic: "Something went wrong! Try refreshing the page and trying again!",
      maxAttempts: "This game has already been played. Try refreshing the page",
    },
  },
  customGame: {
    settings: {
      gameTimeLimit: {
        title: `${Names.CustomGame} Time Limit`,
        unitsLabel: "Mins",
        disabledLabel: `Click to set a time limit for the whole ${Names.customGame}`,
      },
      questionTimeLimit: {
        title: "Question Time Limit",
        unitsLabel: "Secs",
        disabledLabel: "Click to set a time limit for each question",
      },
      playerCount: {
        title: "The number of players who'll be playing together on each laptop or tablet.",
        multiplePlayers: `Note: When viewing results for ${Names.customGames} with more than 1 player, only the first player's results will be shown`,
      },
      questionOrder: {
        title: "What order are the questions asked?",
      },
      questionDistributionType: {
        title: "How are questions split between players?",
      },
      questionsPerPlayer: {
        title: "Questions per Player",
        disabledLabelFunc: (def: any) => `Click to set the number of questions per player (default: ${def})`,
      },
      soundEffects: "Sound Effects",
      totalQuestionsText: `Total Questions in this ${Names.customGame}: `,
      maxAnswers: {
        title: "Maximum Answers per Question",
        disabledLabel: "Click to set the maximum answers per question (default: 4)",
      },
      ttsMode: {
        title: "Text-To-Speech Settings",
        titleEnable: "Enable text-to-speech",
        titleOverride: "Text-To-Speech Overrides",
        disabledLabel: "Set TTS Mode to 'On Button Prompt'",
      },
      showQR: {
        title: "Show QR code in game lobby",
        controlLabel: "QR code is",
      },
    },
  },
  questionSets: {
    questions: {
      revertAll: `Are you sure you want to revert all changes to this ${Names.questionset}?`,
      revertNew: `Are you sure you want to clear all changes to this new question?`,
    },
  },
  onboarding: {
    reset:
      "This will reset your progress through the tutorials, and immediately bring you to the start of those tutorials. Are you sure you want to reset your tutorials?",
  },
  addStudents: {
    title: "Press the plus + at the bottom of the chart to add an individual student",
    maxStudentsTooltip: `If this is not enough, try deleting old classes or ask to have this extended by ${PlatformTitle}`,
  },
  class: {
    regeneratePassword: "Are you sure you want to regenerate this student's password?",
  },
  subscriptions: {
    cancel: (endDate: string) =>
      `Are you sure you want to cancel your subscription? If you do, you will continue to have access until ${endDate}.`,
  },
  unsavedChanges: "You have unsaved changes. Are you sure you want to exit this window?",
  deleteObject: (type: string) => `Are you sure you want to delete this ${type}?`,
};

export { helpMessages };
