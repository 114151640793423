import { CSSProperties, FC, useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { GameTemplate } from "types";
import { Colorable } from "utils/Props";
import { ToolTip } from "primitives";
import { GameIcons } from "icons/Games";
import { MedIcon } from "primitives/icons";

interface Props {
  gameTemplate: GameTemplate;
  isSelected?: boolean;
  disabledMessage?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const GameTemplateCard: FC<Props> = ({ onClick, gameTemplate, isSelected, disabledMessage, style }) => {
  const [isHovered, setHovered] = useState(false);

  const showSelected = isSelected || isHovered;

  return (
    <ToolTip enabled={Boolean(disabledMessage)} tooltip={disabledMessage ? disabledMessage : ""}>
      <GameTemplateCardElement
        bg={Boolean(disabledMessage) ? "secondary" : showSelected ? "primary" : "light"}
        border="primary"
        onClick={() => onClick && !disabledMessage && onClick()}
        disabled={Boolean(disabledMessage)}
        onMouseEnter={() => !Boolean(disabledMessage) && setHovered(true)}
        onMouseLeave={() => !Boolean(disabledMessage) && setHovered(false)}
        style={{
          opacity: Boolean(disabledMessage) ? "50%" : "100%",
          cursor: "pointer",
          ...style,
        }}
      >
        <CardImage src={gameTemplate.previewImage} variant="top" />
        <Card.Body>
          <CardTitle $color={showSelected || Boolean(disabledMessage) ? colors.white : colors.primary}>
            {GameIcons[gameTemplate.name] && <MedIcon icon={GameIcons[gameTemplate.name]} className="mr-1 pt-1 pb-2" />}
            {gameTemplate.name}
          </CardTitle>
          <CardText $color={showSelected || Boolean(disabledMessage) ? colors.white : colors.darkText}>
            <small>{gameTemplate.description}</small>
          </CardText>
        </Card.Body>
      </GameTemplateCardElement>
    </ToolTip>
  );
};

const ReadonlyGameTemplateCard: FC<Props> = ({ gameTemplate }) => {
  return (
    <GameTemplateCardElement bg="light" border="dark">
      <CardImage src={gameTemplate.previewImage} variant="top" />
      <Card.Body>
        <CardTitle $color={colors.darkText}>{gameTemplate.name}</CardTitle>
        <CardText $color={colors.darkText}>
          <small>{gameTemplate.description}</small>
        </CardText>
      </Card.Body>
    </GameTemplateCardElement>
  );
};

const GameTemplateCardElement = styled(Card)`
  max-width: 18em;
  min-width: 18em;
  text-align: center;
  margin: 0px;
  border-radius: 0px;
`;

const CardImage = styled(Card.Img)`
  height: 10em;
  border-radius: 0px;
`;
const CardTitle = styled(Card.Title)<Colorable>`
  color: ${(props) => props.$color};
  font-weight: 700;
`;
const CardText = styled(Card.Text)<Colorable>`
  font-size: 10pt;
  line-height: 1.5;
  color: ${(props) => props.$color};
`;

export { GameTemplateCard, ReadonlyGameTemplateCard };
