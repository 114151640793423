import { ReactNode } from "react";
import ReactDOMServer from "react-dom/server";
import { ServerStyleSheet } from "styled-components";
import { template } from "./PrintingTemplate";

export const prepPrintContent = (printableComponent: ReactNode) => {
  const sheet = new ServerStyleSheet();
  const collectedStyles = sheet.collectStyles(printableComponent);
  const body = ReactDOMServer.renderToStaticMarkup(collectedStyles);
  const styleTags = sheet.getStyleTags();
  sheet.seal();

  const styles = document.styleSheets;

  let globalStyles = "";

  for (var i = 0; i < styles.length; i++) {
    if (!styles[i].href || styles[i].href?.startsWith(window.location.origin)) {
      for (let j = 0; j < styles[i].cssRules.length; j++) {
        globalStyles += styles[i].cssRules[j].cssText;
      }
    }
  }

  let html: string = template;

  html = html.replace("::StyledComponents::", styleTags);
  html = html.replace("::GlobalStyles::", globalStyles);
  html = html.replace("::Content::", body);

  return html;
};
