import { useStateWithUpdate } from "hooks";
import { sortBy } from "lodash";
import { Button } from "primitives/button";
import { Row, Spacer } from "primitives";
import React, { FC } from "react";
import { Tag } from "types";
import { SwapModeButton } from "../SwapModeButton";
import { SubjectTagsDropDowns } from "./SubjectTagsDropDowns";

interface Props {
  addSubjectTag: (tagId: string) => void;
  toggleMode: () => void;
  subjectTagsWithSelectedStatus: Array<{ tag: Tag; selected: boolean }>;
}

const SubjectTagsDropdownSearch: FC<Props> = ({ addSubjectTag, subjectTagsWithSelectedStatus, toggleMode }) => {
  const [selectedDropdownTags, updateSelectedDropdownTags] = useStateWithUpdate<{
    topLevel: Tag | null;
    secondLevel: Tag | null;
    thirdLevel: Tag | null;
  }>({
    topLevel: null,
    secondLevel: null,
    thirdLevel: null,
  });

  const subjectTags = subjectTagsWithSelectedStatus.map(({ tag }) => tag);

  const selectedTag =
    selectedDropdownTags.thirdLevel ?? selectedDropdownTags.secondLevel ?? selectedDropdownTags.topLevel!;

  const topLevelTagOptions = filterByParentConditionAndSort(subjectTags, (parent) => parent === null);

  const secondLevelTagOptions = filterByParentConditionAndSort(
    subjectTags,
    (parent) => !!selectedDropdownTags.topLevel && parent?.id === selectedDropdownTags.topLevel.id
  );

  const thirdLevelTagOptions = filterByParentConditionAndSort(
    subjectTags,
    (parent) => !!selectedDropdownTags.secondLevel && parent?.id === selectedDropdownTags.secondLevel.id
  );

  const dropdownList = [
    {
      placeholder: "Top Level Tag",
      tagList: topLevelTagOptions,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        updateSelectedDropdownTags({
          topLevel: topLevelTagOptions.find((tag) => tag.name === e.target.value) ?? null,
          secondLevel: null,
          thirdLevel: null,
        }),
      selected: selectedDropdownTags.topLevel,
    },
    {
      placeholder: "Second Level Tag",
      tagList: secondLevelTagOptions,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        updateSelectedDropdownTags({
          secondLevel: secondLevelTagOptions.find((tag) => tag.name === e.target.value) ?? null,
          thirdLevel: null,
        }),
      selected: selectedDropdownTags.secondLevel,
    },
    {
      placeholder: "Third Level Tag",
      tagList: thirdLevelTagOptions,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        updateSelectedDropdownTags({
          thirdLevel: thirdLevelTagOptions.find((tag) => tag.name === e.target.value) ?? null,
        }),
      selected: selectedDropdownTags.thirdLevel,
    },
  ];

  const clearLowestLevelTag = () => {
    updateSelectedDropdownTags({
      topLevel: selectedDropdownTags.secondLevel ? selectedDropdownTags.topLevel : null,
      secondLevel: selectedDropdownTags.thirdLevel ? selectedDropdownTags.secondLevel : null,
      thirdLevel: null,
    });
  };

  return (
    <Row
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SubjectTagsDropDowns dropdownList={dropdownList} />
      <Spacer size={10} />
      <Button
        variant="success"
        disabled={!selectedDropdownTags.topLevel}
        onClick={() => {
          addSubjectTag(selectedTag.id);
          clearLowestLevelTag();
        }}
      >
        Add Tag
      </Button>
      <SwapModeButton toggleMode={toggleMode} mode="dropdown" />
    </Row>
  );
};

const filterByParentConditionAndSort = (tags: Array<Tag>, parentCondition: (parent: Tag | null) => boolean) =>
  sortBy(
    tags.filter((tag) => parentCondition(tag.parent)),
    (tag) => tag.name
  );

export { SubjectTagsDropdownSearch };
