import { deleteStudent, fetchStudent, updateStudent, regenerateStudentPassword } from "slices";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";

const useStudent = (studentId?: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (studentId) {
      dispatch(fetchStudent(studentId));
    }
  }, [dispatch, studentId]);

  const state = useAppSelector((state) => state.student);

  const functions = {
    deleteStudent: (...params: Parameters<typeof deleteStudent>) => dispatch(deleteStudent(...params)),
    fetchStudent: (...params: Parameters<typeof fetchStudent>) => dispatch(fetchStudent(...params)),
    updateStudent: (...params: Parameters<typeof updateStudent>) => dispatch(updateStudent(...params)),
    regenerateStudentPassword: (...params: Parameters<typeof regenerateStudentPassword>) =>
      dispatch(regenerateStudentPassword(...params)),
  };

  return { ...state, ...functions };
};

export { useStudent };
