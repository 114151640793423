import dayjs from "dayjs";

type dayjsDate = string | Date | dayjs.Dayjs | null;

export const datesEqual = (dateA: dayjsDate, dateB: dayjsDate, scope: dayjs.OpUnitType = "minute"): boolean => {
  if (dateA === null || dateB === null) {
    return false;
  }
  return dayjs(dateA).isSame(dateB, scope);
};

export const dateWithoutTime = (date: string | dayjs.Dayjs | Date): string => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const isoDateWithoutTime = (date: string | dayjs.Dayjs | Date): string => {
  return dayjs(date).hour(0).minute(0).second(0).millisecond(0).toISOString();
};

export const addHours = (date: Date, hours: number) => {
  return dayjs(date).add(hours, "hour").toDate();
};

export const addDays = (date: Date, days: number) => {
  return dayjs(date).add(days, "day").toDate();
};

export const addWeeks = (date: Date, weeks: number) => {
  return dayjs(date).add(weeks, "week").toDate();
};

export const addDefaultSessionLength = (date: Date) => addWeeks(date, 1);

export const addMonths = (date: Date, months: number) => {
  return dayjs(date).add(months, "month").toDate();
};

export const getEndOfToday = () => {
  return dayjs().endOf("day").toDate();
};

export const isSameDate = (d1: string | dayjs.Dayjs | Date | null, d2: string | dayjs.Dayjs | Date | null) => {
  const date1 = dayjs(d1 || 0);
  const date2 = dayjs(d2 || 0);
  return Math.abs(date1.diff(date2, "day")) < 1;
};

export const formatLongDate = (
  date: string | dayjs.Dayjs | Date | null,
  shortenMonth?: boolean,
  hideTime?: boolean
): string => {
  const myDate = dayjs(date || 0);
  const monthFormat = shortenMonth ? "MMM" : "MMMM";
  const timeFormat = hideTime ? "" : ", h:mma";
  if (myDate.year() === dayjs(Date.now()).year()) {
    return myDate.format(`${monthFormat} D` + timeFormat);
  }
  return myDate.format(`${monthFormat} D YYYY` + timeFormat);
};

export const formatLongDateRange = (
  from: string | dayjs.Dayjs | Date | null,
  to: string | dayjs.Dayjs | Date | null,
  shortenMonth?: boolean,
  hideTime?: boolean,
  separator: string = "-"
): string => {
  const fromDate = dayjs(from || 0);
  const toDate = dayjs(to || 0);
  let output = formatLongDate(fromDate, shortenMonth, hideTime);
  const timeFormat = hideTime ? "" : ", h:mma";

  const monthFormat = shortenMonth ? "MMM" : "MMMM";
  if (toDate.diff(fromDate, "day") < 1) {
    output += ` ${separator} ${toDate.format("h:mm a")}`;
  } else if (fromDate.year() === toDate.year()) {
    output += ` ${separator} ${toDate.format(monthFormat + " D" + timeFormat)}`;
  } else {
    output += ` ${separator} ${toDate.format(monthFormat + " D YYYY" + timeFormat)}`;
  }

  return output;
};
