import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setIsPlayingTutorial } from "slices";

interface TutorialStateData {
  isPlayingTutorial: boolean;
  setIsPlayingTutorialState: (state: boolean) => void;
}

export const useTutorialState = (): TutorialStateData => {
  const dispatch = useAppDispatch();

  const isPlayingTutorial = useAppSelector((state) => state.tutorialState.isPlayingTutorial);

  const setIsPlayingTutorialState = (state: boolean) => dispatch(setIsPlayingTutorial(state));

  return { isPlayingTutorial, setIsPlayingTutorialState };
};
