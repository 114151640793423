import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "slices/sliceHelpers";
import { setCustomGameAssignments as setCustomGameAssignmentsApi } from "api";
import { CustomGameActions } from "./customGameSlice";

export interface SetCustomGameAssignmentsParams {
  customGameId: string;
  studentIds: string[];
}

export const dispatchSetCustomGameAssignments = (
  { customGameId, studentIds }: SetCustomGameAssignmentsParams,
  actions: CustomGameActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const customGame = await setCustomGameAssignmentsApi({
      token,
      customGameId,
      studentIds,
    });

    dispatch(actions.addSingle({ id: customGame.id, customGame }));

    return customGame;
  }, dispatch);
