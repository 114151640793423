import { FC } from "types";
import dashboardBackgroundImage from "assets/Dashboard_BG.png";
import styled from "styled-components";
import { RootBreadcrumb } from "components/breadcrumb";
import { breadcrumbConstants } from "textConstants";
import { useBreadcrumbs } from "hooks";
import { useEffect } from "react";
import { colors } from "styles";
import { DashboardItem } from "./DashboardItem";
import { Column, HeaderText, Row, Spacer } from "primitives";
import { Mascot1, TutorialsProvider } from "components";
import { DashboardTours } from "textConstants/tours";
import { MediaConstants } from "utils/mediaConstants";
import { ArticleProvider } from "components/articles";

export const TeacherDashboard: FC = () => {
  const { setBreadcrumbColor } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbColor(colors.darkText, colors.darkText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BackgroundContainer>
      <TutorialsProvider section="dashboard" tours={DashboardTours} />
      <ArticleProvider />
      <RootBreadcrumb
        title={breadcrumbConstants.dashboard.title}
        description={breadcrumbConstants.dashboard.breadcrumbDescription}
        icon={breadcrumbConstants.dashboard.icon}
      />
      <OuterContainer>
        <Mascot1 style={{ position: "absolute", bottom: "80px", left: "50px" }} />
        <InnerContainer>
          <StyledHeaderText>{breadcrumbConstants.dashboard.startInstruction}</StyledHeaderText>
          <Spacer />
          <ItemGrid>
            <DashboardItem
              title={breadcrumbConstants.gameLibrary.title}
              icon={breadcrumbConstants.gameLibrary.icon}
              description={breadcrumbConstants.gameLibrary.dashboardDescription}
              color={breadcrumbConstants.gameLibrary.accentColor}
              route={breadcrumbConstants.gameLibrary.route}
            />
            <DashboardItem
              title={breadcrumbConstants.myGames.title}
              icon={breadcrumbConstants.myGames.icon}
              description={breadcrumbConstants.myGames.dashboardDescription}
              color={breadcrumbConstants.myGames.accentColor}
              route={breadcrumbConstants.myGames.route}
            />
            <DashboardItem
              title={breadcrumbConstants.questions.title}
              icon={breadcrumbConstants.questions.icon}
              description={breadcrumbConstants.questions.dashboardDescription}
              color={breadcrumbConstants.questions.accentColor}
              route={breadcrumbConstants.questions.route}
            />
            <DashboardItem
              title={breadcrumbConstants.progress.title}
              icon={breadcrumbConstants.progress.icon}
              description={breadcrumbConstants.progress.dashboardDescription}
              color={breadcrumbConstants.progress.accentColor}
              route={breadcrumbConstants.progress.route}
            />
            <DashboardItem
              title={breadcrumbConstants.class.title}
              icon={breadcrumbConstants.class.icon}
              description={breadcrumbConstants.class.dashboardDescription}
              color={breadcrumbConstants.class.accentColor}
              route={breadcrumbConstants.class.route}
            />
          </ItemGrid>
        </InnerContainer>
      </OuterContainer>
    </BackgroundContainer>
  );
};

const StyledHeaderText = styled(HeaderText)`
  overflow: visible;
  @media ${MediaConstants.tablet} {
    font-size: 18pt;
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(${dashboardBackgroundImage});
  background-size: cover;
  background-position: 50% 0%;
`;

const OuterContainer = styled(Column)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;
  @media ${MediaConstants.laptopL} {
    justify-content: center;
  }
`;

const InnerContainer = styled(Column)`
  width: 80%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  align-items: center;
  z-index: 1;
  @media ${MediaConstants.laptopL} {
    width: 100%;
    justify-content: flex-start;
  }
`;

const ItemGrid = styled(Row)`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  max-width: 1300px;
`;
