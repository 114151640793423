import { FC, ReactNode } from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "styles";
import { TitleText, Row, HorizontalLine, ActionButtonBar } from "primitives";
import { UserAction } from "types";
import { sortBy, filter } from "lodash";
import { MediaConstants } from "utils/mediaConstants";
import { MedIcon } from "primitives/icons";
import { StyledIcon } from "@styled-icons/styled-icon";
import classnames from "classnames";

interface Selectable {
  isSelected?: boolean;
}

interface Props extends Selectable {
  style?: CSSProperties;
  onClick?: () => void;
  actions?: { [id: string]: UserAction };
  scroll?: number;
  parentElementId?: string;
  children?: ReactNode;
  className?: string;
}

const ListCard: FC<Props> = ({ isSelected, className, children, onClick, actions, scroll, parentElementId }) => {
  const sortedActions = sortBy(
    filter(actions, (action: UserAction) => action.isAvailable === undefined || action.isAvailable()),
    "priority"
  );

  return (
    <OuterContainer isSelected={isSelected} className={classnames(className, "my-2")} onClick={onClick}>
      <InnerContainer>
        {children}
        {Boolean(sortedActions?.length) && (
          <>
            <HorizontalLine className="mx-0 my-1" />
            <ActionButtonBar
              actions={Object.values(sortedActions).map((action) => ({ action }))}
              scroll={scroll}
              parentElementId={parentElementId}
            />
          </>
        )}
      </InnerContainer>
    </OuterContainer>
  );
};

interface StatusProps {
  children?: ReactNode;
  className?: string;
}

const ListCardStatus: FC<StatusProps> = ({ children, className }) => {
  return <StatusContainer className={className}>{children}</StatusContainer>;
};

interface TitleProps {
  name: string;
  icon: StyledIcon;
  children?: ReactNode;
  className?: string;
}

const ListCardTitle: FC<TitleProps> = ({ name, icon, children, className }) => {
  return (
    <ListCardTitleRow className={className}>
      <MedIcon color={colors.primary} className="mr-2" icon={icon} />
      <ListCardTitleText>{name}</ListCardTitleText>
      {children}
    </ListCardTitleRow>
  );
};

const ListCardTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ListCardTitleText = styled(TitleText)`
  font-size: 16px;
  color: ${colors.primary};
  max-width: 30ch;
  @media ${MediaConstants.laptopL} {
    max-width: 20ch;
  }
  @media ${MediaConstants.tablet} {
    max-width: 10ch;
  }
`;

const OuterContainer = styled(Row)<Selectable>`
  flex-shrink: 1;
  padding: 1em 1em 0.5em 1em;
  transition: 0.5s all;
  border: 1px solid;
  border-color: ${(props) => (props.isSelected ? colors.primary : colors.primaryDivider)};
  ${(props) => props.isSelected && "background-color: " + colors.lightPalePrimary + ";"}
  position: relative;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
  width: 100%;
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 10px;
  right 0px;
`;

export { ListCard, ListCardTitle, ListCardStatus };
