import { Button, PrimaryButton, StyledDropdownItem, DropdownText, PrimaryDropdown } from "primitives";
import { Column, HorizontalLine, Row, SecondaryText } from "primitives";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { helpMessages } from "textConstants";
import { AnswerTuple, FreetextQuestion, MultichoiceQuestion, Question, QuestionSet, QuestionType } from "types";
import { isQuestionValid } from "../questionSetValidation/generateErrorFeedbackForAnswerIndex";
import { FreetextQuestionForm } from "./FreetextQuestionForm";

import { MultichoiceQuestionForm } from "./MultichoiceQuestionForm";
import { useConfirmModal } from "hooks";

export const Character_Limit = 180;

export type UpdateQuestionData = { index: number; question: Partial<Question> };

type DraftQuestion = Omit<
  Question,
  "id" | "order" | "toBeUpdated" | "toBeDeleted" | "toBeCreated" | "displayAsUnsaved"
>;

interface Props {
  style?: CSSProperties;
  questionSet: QuestionSet;
  activeQuestionIndex: number | null;
  onNewQuestionCreated: () => void;
  onAddQuestion: (addQuestionData: Partial<Question>) => void;
  onUpdateQuestion: (updateQuestionData: UpdateQuestionData) => void;
}

const blankMultichoiceQuestion: Omit<
  MultichoiceQuestion,
  "id" | "order" | "toBeUpdated" | "toBeDeleted" | "toBeCreated" | "displayAsUnsaved"
> = {
  question: "",
  answers: [null, null] as AnswerTuple,
  correctAnswerIndex: 0,
  questionImage: null,
  answerImages: [null, null] as AnswerTuple,
  type: "multichoice",
};

const blankFreetextQuestion: Omit<
  FreetextQuestion,
  "id" | "order" | "toBeUpdated" | "toBeDeleted" | "toBeCreated" | "displayAsUnsaved"
> = {
  question: "",
  answers: [""],
  questionImage: null,
  type: "freetext",
};

const QuestionEditor: FC<Props> = ({
  style,
  questionSet,
  activeQuestionIndex,
  onNewQuestionCreated,
  onAddQuestion,
  onUpdateQuestion,
}) => {
  const { questions } = questionSet;
  const createMode = activeQuestionIndex === null;
  const questionBeingEdited = activeQuestionIndex === null || !questions ? null : questions[activeQuestionIndex];
  const lastQuestion = questions ? questions[questions.length - 1] : null;
  const [lastEdittedQuestionType, setLastEdittedQuestionType] = useState<QuestionType>(
    lastQuestion?.type || "multichoice"
  );

  const [draftQuestion, setDraftQuestion] = useState<DraftQuestion>(
    lastEdittedQuestionType === "freetext" ? blankFreetextQuestion : blankMultichoiceQuestion
  );

  const [showInvalidFeedback, setShowInvalidFeedback] = useState(false);
  const scrollAreaEnd = useRef<HTMLInputElement>(null);
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const maxDistractorCount: number = 3;
  const questionTypeText: { [key: string]: { selectable: string; description: string; key: QuestionType } } = {
    multichoice: {
      selectable: "Multiple Choice",
      description: `Multiple choice questions support text, image answers and up to ${maxDistractorCount} distractors (Incorrect Answers)`,
      key: "multichoice",
    },
    freetext: {
      selectable: "Free Text",
      description: `Free Text`,
      key: "freetext",
    },
  };

  let questionData: Partial<Question> = createMode ? draftQuestion : questionBeingEdited!;
  const questionType = questionData.type!;

  const firstInputInFormRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let newQuestionType = lastEdittedQuestionType;
    if (!createMode) {
      newQuestionType = questionBeingEdited?.type || "multichoice";
      setLastEdittedQuestionType(newQuestionType);
    } else {
      setBlankQuestion(newQuestionType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionIndex]);

  useEffect(() => {
    if (createMode) {
      setShowInvalidFeedback(false);
      firstInputInFormRef?.current?.focus();
    } else {
      setShowInvalidFeedback(true);
    }
  }, [createMode]);

  const handleQuestionChanged = (question: Partial<Question>) => {
    if (createMode) {
      setDraftQuestion((prev) => ({ ...prev, ...question }));
    } else {
      onUpdateQuestion({ index: activeQuestionIndex!, question });
    }
  };

  const submitCreateQuestion = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (!isQuestionValid(questionData)) {
      setShowInvalidFeedback(true);
    } else {
      setShowInvalidFeedback(false);
      onAddQuestion({
        ...questionData,
        type: questionTypeText[questionType].key,
      });

      setBlankQuestion(questionData.type || "multichoice");
      setLastEdittedQuestionType(questionData.type || "multichoice");
      firstInputInFormRef?.current?.focus();
      onNewQuestionCreated();
    }
  };

  const setBlankQuestion = (questionType: QuestionType) => {
    if (questionType === "multichoice") {
      setDraftQuestion(blankMultichoiceQuestion);
    } else if (questionType === "freetext") {
      setDraftQuestion(blankFreetextQuestion);
    }
  };

  const clearQuestion = () => {
    setModalInfo({
      body: [helpMessages.questionSets.questions.revertNew],
      onModalConfirm: async () => {
        setShowInvalidFeedback(false);
        setBlankQuestion(questionType);
        firstInputInFormRef?.current?.focus();
      },
    });
    setModalVisible(true);
  };

  const handleQuestionTypeChanged = (questionType: QuestionType) => {
    setBlankQuestion(questionType);
    handleQuestionChanged({ type: questionType });
  };

  const editorScrollToBottom = () => {
    if (scrollAreaEnd) {
      scrollAreaEnd.current?.scrollIntoView(false);
    }
  };

  return (
    <OuterContainer className="add-question" style={style}>
      <FormContainer>
        <p style={{ fontWeight: 700, fontSize: 18 }}>{createMode ? `Add Question` : `Edit Question`}</p>
        <Form className="inputs" style={{ flex: 1 }}>
          <Column style={{ height: "100%" }}>
            <Form.Label style={{ fontWeight: 500 }}>Question Type</Form.Label>
            <SecondaryText className={"mb-2"}>
              <small>{questionTypeText[questionType].description}</small>
            </SecondaryText>
            <SectionContainer>
              <QuestionTypeDropdown
                className="borderless-dropdown"
                toggleText={questionTypeText[questionType].selectable}
                expand={true}
              >
                {Object.keys(questionTypeText).map((questionType) => (
                  <StyledDropdownItem
                    className="borderless-dropdown"
                    key={questionType}
                    value={questionTypeText[questionType].selectable}
                    onClick={() => handleQuestionTypeChanged(questionType as QuestionType)}
                  >
                    <DropdownText>{questionTypeText[questionType].selectable}</DropdownText>
                  </StyledDropdownItem>
                ))}
              </QuestionTypeDropdown>
            </SectionContainer>
            <HorizontalLine />
            {questionType === "multichoice" && (
              <MultichoiceQuestionForm
                activeQuestionIndex={activeQuestionIndex}
                questions={questions}
                questionData={questionData as MultichoiceQuestion}
                showInvalidFeedback={showInvalidFeedback}
                maxDistractorCount={maxDistractorCount}
                handleQuestionChanged={handleQuestionChanged}
                onScrollToBottom={editorScrollToBottom}
              />
            )}
            {questionType === "freetext" && (
              <FreetextQuestionForm
                questionData={questionData as FreetextQuestion}
                showInvalidFeedback={showInvalidFeedback}
                handleQuestionChanged={handleQuestionChanged}
                onScrollToBottom={editorScrollToBottom}
              />
            )}
            <div ref={scrollAreaEnd}></div>
          </Column>
        </Form>
      </FormContainer>
      {createMode && (
        <FooterContainer>
          <HorizontalLine style={{ marginTop: "0px", marginBottom: "1em" }} />
          <Row gap="15px">
            <PrimaryButton variant="secondary" onClick={clearQuestion}>
              Clear
            </PrimaryButton>
            <PrimaryButton className="add-button" type="submit" onClick={submitCreateQuestion}>
              Add Question
            </PrimaryButton>
          </Row>
        </FooterContainer>
      )}
      <Modal />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.light};
  justify-content: space-between;
`;

const FormContainer = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 7em;
  max-height: 6em;
  justify-content: flex-start;
  align-items: center;
`;

export const SectionContainer = styled(Row)`
  justify-items: flex-end;
`;

export const InputContainer = styled(Column)`
  flex: 1;
  align-items: flex-end;
  position: relative;
`;

export const FlexButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

export const DistractorTitle = styled(Form.Label)`
  display: flex;
  font-weight: 500;
  min-width: 250px;
  align-items: flex-end;
`;

const QuestionTypeDropdown = styled(PrimaryDropdown)`
  width: 100%;
`;
export { QuestionEditor };
