import { Row, WideActionButton } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { FC, UserAction } from "types";

interface Props {
  hasUnsavedChanges: boolean;
  canApplyChanges: boolean;
  applyChanges: () => void;
  revertChanges: () => void;
  loadingChanges?: boolean;
}

const UnsavedChangesComponent: FC<Props> = ({
  hasUnsavedChanges,
  canApplyChanges,
  applyChanges,
  revertChanges,
  loadingChanges: loading,
  children,
  ...rest
}) => {
  const applyAction: UserAction = {
    id: "apply",
    name: "Save",
    isEnabled: () => !Boolean(loading || !hasUnsavedChanges || !canApplyChanges),
    action: async () => applyChanges(),
  };

  const revertAction: UserAction = {
    id: "revert",
    name: "Undo",
    isEnabled: () => !Boolean(loading || !hasUnsavedChanges),
    action: async () => revertChanges(),
  };

  return (
    <UnsavedChangesContainer {...rest}>
      <UnsavedChangesInner className="unsaved-changes-container">
        <WideActionButton action={revertAction} variant="secondary">
          {revertAction.name}
        </WideActionButton>
        <WideActionButton action={applyAction}>{applyAction.name}</WideActionButton>
      </UnsavedChangesInner>
      <ChildrenContainer>{children}</ChildrenContainer>
    </UnsavedChangesContainer>
  );
};

const UnsavedChangesContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.primaryDivider};
  background-color: ${colors.white};
  position: relative;
  display: flex;
  justify-content: center;
`;

const UnsavedChangesInner = styled.div`
  margin: var(--sp-3) 0px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: fit-content;
`;

const ChildrenContainer = styled(Row)`
  position: absolute;
  right: 0px;
  height: 100%;
  align-items: center;
  margin: 0px var(--sp-4);
`;
export { UnsavedChangesComponent };
