import { Lock, Visibility, VisibilityOff } from "@styled-icons/material";
import { FormErrorFeedback } from "components";
import { CSSProperties } from "react";
import { FC } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputAppendIcon, InputPrependIcon } from "./FormInputItems";

interface Props {
  password: string;
  setPassword: (password: string) => void;
  showPassword: boolean;
  toggleShowPassword: () => void;
  failedAttempt: boolean;
  style?: CSSProperties;
}

const PasswordInput: FC<Props> = ({
  password,
  setPassword,
  showPassword,
  toggleShowPassword,
  failedAttempt,
  style,
}) => (
  <>
    <InputGroup style={{ flex: 1, ...style }}>
      <InputPrependIcon icon={Lock} />
      <Form.Control
        isInvalid={failedAttempt}
        placeholder="Password"
        aria-label="Password"
        aria-describedby="basic-addon1"
        type={showPassword ? undefined : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <InputAppendIcon icon={showPassword ? VisibilityOff : Visibility} onClick={toggleShowPassword} />
    </InputGroup>
    <FormErrorFeedback errorMessage={failedAttempt && !password ? "Please enter your password." : undefined} />
  </>
);

export { PasswordInput };
