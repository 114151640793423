import { fetchQuestionSetCount as fetchQuestionSetCountApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { QuestionSetActions } from "./questionSetsSliceNew";
import { QuestionSetTabName } from "pages/questions/questionSetList";

export interface FetchQuestionSetListCountParams {
  id: QuestionSetTabName;
  filterText?: string;
  archived?: boolean;
  visibility?: "public" | "private" | "draft";
  featured?: boolean;
  gradeTags?: string;
  subjectTags?: string;
  isFavourite?: boolean;
  onlyMyOwn: boolean;
}

const dispatchFetchQuestionSetListCount = (
  fetchParams: FetchQuestionSetListCountParams,
  actions: QuestionSetActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const data = await fetchQuestionSetListInternal(fetchParams, getState);

    dispatch(actions.updateTotals({ id: fetchParams.id, total: data }));
  }, dispatch);

const fetchQuestionSetListInternal = async (params: FetchQuestionSetListCountParams, getState: () => RootState) => {
  const { token, userInfo } = getTokenFromState(getState);
  const data = await fetchQuestionSetCountApi(
    {
      teacherId: userInfo!.id,
      filterText: params.filterText,
      archived: params.archived,
      featured: params.featured,
      visibility: params.visibility,
      gradeTags: params.gradeTags,
      subjectTags: params.subjectTags,
      isFavourite: params.isFavourite,
      onlyMyOwn: params.onlyMyOwn,
    },
    token
  );
  return data;
};

export { dispatchFetchQuestionSetListCount };
