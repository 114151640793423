import { api } from "api/api";
import { NetworkError } from "api/networkError";
import { Session } from "types";

export const getStudentSessionByCode = async (token: string, code: string) => {
  const response = await api.get(`/students/sessions/code/${code}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response?.data) {
    return response?.data as Session;
  } else {
    throw new NetworkError();
  }
};
