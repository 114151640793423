import { ElementType } from "react";
import { useLocation } from "react-router-dom";
import { slugs } from "textConstants";
import { useConfirmModal } from "./useConfirmModal";

interface Props {
  modalBody: string[];
}

export const useRequiresLoginModal = ({ modalBody }: Props): [() => void, ElementType<any>] => {
  const [Modal, setModalVisible, setModalInfo] = useConfirmModal();

  const fullUrl = window.location.href;
  const { pathname } = useLocation();

  const displayModal = () => {
    const rootUrl = fullUrl.substring(0, fullUrl.indexOf(pathname));
    setModalInfo({
      title: <>Create an account</>,
      body: modalBody,
      confirmTitle: "Sign Up",
      confirmProps: {
        href: `${rootUrl}/${slugs.register}`,
        target: "_parent",
      },
    });

    setModalVisible(true);
  };

  return [displayModal, Modal];
};
