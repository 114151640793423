import { Public, Add, PlayArrow, RestoreFromTrash, QrCodeScanner, Stop, Refresh } from "@styled-icons/material";
import {
  ClockIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  PreviewIcon,
  SessionPlayIcon,
  ShareIcon,
  StarIcon,
  StarOutlineIcon,
  AddIcon,
  SubtractIcon,
} from "icons";
import { colors } from "styles";
import { breadcrumbConstants } from "./breadcrumbConstants";
import { Names } from "./main";

export const actionConstants = {
  feature: {
    icon: Public,
  },
  favourite: {
    name: "Favourite",
    icon: StarOutlineIcon,
    iconColor: colors.primary,
  },
  unFavourite: {
    name: "Remove Favourite",
    icon: StarIcon,
    iconColor: colors.warning,
  },
  archive: {
    name: "Archive",
    textColor: colors.danger,
    icon: DeleteIcon,
    iconColor: colors.danger,
  },
  delete: {
    name: "Delete",
    icon: DeleteIcon,
    iconColor: colors.danger,
  },
  add: {
    icon: Add,
  },
  addToMinigame: {
    name: `Add To ${Names.CustomGame}`,
    icon: AddIcon,
    color: colors.white,
    iconColor: colors.white,
    backgroundColor: colors.primary,
    hoverColor: colors.white,
    hoverBackground: colors.primaryHover,
  },
  removeFromMinigame: {
    name: `Remove From ${Names.CustomGame}`,
    icon: SubtractIcon,
    color: colors.white,
    iconColor: colors.white,
    backgroundColor: colors.danger,
    hoverColor: colors.white,
    hoverBackground: colors.dangerHover,
  },
  play: {
    icon: PlayArrow,
  },
  startGame: {
    name: `Schedule ${Names.Session}`,
    icon: SessionPlayIcon,
    iconColor: breadcrumbConstants.myGames.accentColor,
  },
  preview: {
    name: "Preview",
    icon: PreviewIcon,
  },
  share: {
    name: "Share",
    icon: ShareIcon,
  },
  edit: {
    name: "Edit",
    icon: EditIcon,
  },
  duplicate: {
    name: "Duplicate",
    icon: DuplicateIcon,
  },
  restore: {
    name: "Restore",
    icon: RestoreFromTrash,
  },
  viewResults: {
    name: "See Class Results",
    icon: breadcrumbConstants.progress.icon,
    iconColor: breadcrumbConstants.progress.accentColor,
  },
  qr: {
    name: "Show QR Code",
    icon: QrCodeScanner,
  },
  endGame: {
    name: `End ${Names.Session}`,
    icon: Stop,
    iconColor: colors.danger,
  },
  changeEndTime: {
    name: "Change End Time",
    icon: ClockIcon,
  },
  refresh: {
    name: "Refresh",
    icon: Refresh,
  },
};
