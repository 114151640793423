import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { getStudentByToken, refreshToken, getTeacherByToken } from "api";
import { Teacher } from "types";
import { loginSuccess } from "./teacherLoginSlice";
import { studentLoginSuccess } from "./studentLoginSlice";

type AppLoadingState = {
  loading: boolean;
};

export interface LocalToken {
  token: string;
  tokenType: "teacher" | "student";
  allowRefresh: boolean;
}

const initialState: AppLoadingState = { loading: true };

const appLoadingSlice = createSlice({
  name: "appLoading",
  initialState,
  reducers: {
    appLoading(state) {
      state.loading = true;
    },
    appLoaded(state) {
      state.loading = false;
    },
  },
});

const restoreToken = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(appLoading());

    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("No stored token");
      }

      const tokenData: LocalToken = JSON.parse(storedToken);

      if (tokenData.tokenType === "teacher") {
        let token: string = tokenData.token;
        let userInfo: Teacher;
        if (tokenData.allowRefresh) {
          const { token: newToken, userInfo: teacherInfo } = await refreshToken(token);
          token = newToken;
          userInfo = teacherInfo;
        } else {
          userInfo = await getTeacherByToken(token);
        }
        dispatch(loginSuccess({ token, userInfo }));
      } else if (tokenData.tokenType === "student") {
        let token: string = tokenData.token;
        let userInfo = await getStudentByToken(token);
        dispatch(studentLoginSuccess({ token, student: userInfo }));
      }

      dispatch(appLoaded());
    } catch (error) {
      dispatch(appLoaded());
    }
  };
};
export const { appLoading, appLoaded } = appLoadingSlice.actions;

const appLoadingReducer = appLoadingSlice.reducer;
export { appLoadingReducer, restoreToken };
