import { FC, useState, useEffect, useRef } from "react";
import { Button as BootstrapButton, ButtonProps, Spinner } from "react-bootstrap";
import { UserAction } from "types";
import { ToolTip } from "primitives";
import { StyledIcon } from "@styled-icons/styled-icon";
import { LargeIcon, MedIcon, SmallIcon } from "primitives/icons";
import styled from "styled-components";
import { Center } from "primitives/center";
import { Size } from "types/Size";
import { colors } from "styles";
import { useConfirmModal, useLogin } from "hooks";

export interface Props extends ButtonProps {
  action: UserAction;
  loading?: boolean;
  icon?: StyledIcon;
  iconSize?: Size;
  iconPos?: "beforeChildren" | "afterChildren";
  iconColour?: string;
  onComplete?: () => void;
  color?: string;
  hoverColor?: string;
  hoverBackground?: string;
  onActionNotAllowed?: () => void;
}

interface IconProps {
  icon?: StyledIcon;
  iconSize?: Size;
  iconColour?: string;
}

const ActionButton: FC<Props> = ({
  action,
  onActionNotAllowed,
  loading,
  disabled,
  children,
  onClick,
  onComplete,
  onError,
  icon,
  iconSize,
  iconPos,
  iconColour,
  variant,
  color,
  hoverColor,
  hoverBackground,
  ...rest
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const isLoading = isProcessing || loading;
  const isDisabled = isLoading || disabled || (action.isEnabled && !action.isEnabled());
  const isLoggedIn = Boolean(useLogin()?.token);
  const [ConfirmModal, setConfirmModalVisible, setConfirmModalDetails] = useConfirmModal();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const processAction = async (e: any) => {
    if (onClick) onClick(e);

    setIsProcessing(true);
    try {
      await action.action();
      if (onComplete) onComplete();
    } catch (error) {
      console.error(`IconActionButton action failed: ` + error);
      // @ts-ignore
      if (onError) onError(error);
    } finally {
      if (mounted.current) setIsProcessing(false);
    }
  };

  const clickHandler: React.MouseEventHandler<HTMLElement> = async (e) => {
    if (action.requiresLogin && !isLoggedIn) {
      if (onActionNotAllowed) {
        onActionNotAllowed();
      }
      return;
    }

    if (!action.confirmationDetails) {
      processAction(e);
    } else {
      setConfirmModalDetails({
        ...action.confirmationDetails,
        onModalConfirm: async () => {
          await processAction(e);
          setConfirmModalVisible(false);
        },
      });
      setConfirmModalVisible(true);
    }
  };

  if (action.isAvailable && !action.isAvailable()) return <></>;

  const buttonIcon = icon ? icon : action.icon;
  const buttonIconColour = isDisabled ? colors.secondary : iconColour || action.iconColor;
  const buttonHoverBackground = hoverBackground || action.hoverBackground;
  const buttonHoverColor = hoverColor || action.hoverColor;
  const backgroundColor = action.backgroundColor;

  const iconBeforeChildren = !iconPos || iconPos === "beforeChildren";

  const Button = (
    <StyledButton
      id={`${action.id}-action-button`}
      disabled={isDisabled}
      onClick={clickHandler}
      $backgroundColor={backgroundColor}
      $color={color || action.color}
      $hoverColor={buttonHoverColor}
      $hoverBackground={buttonHoverBackground}
      {...rest}
      variant={variant}
    >
      {isLoading ? (
        <Center>
          <Spinner size="sm" animation="border" />
        </Center>
      ) : (
        <ButtonContents>
          {iconBeforeChildren && buttonIcon && (
            <IconContainer>
              <Icon icon={buttonIcon} iconSize={iconSize} iconColour={buttonIconColour} />
            </IconContainer>
          )}
          {children}
          {!iconBeforeChildren && buttonIcon && (
            <IconContainer>
              <Icon icon={buttonIcon} iconSize={iconSize} iconColour={buttonIconColour} />
            </IconContainer>
          )}
        </ButtonContents>
      )}
    </StyledButton>
  );

  if (!children)
    return (
      <>
        <ToolTip enabled={!isProcessing} tooltip={action.name}>
          {Button}
        </ToolTip>
        <ConfirmModal />
      </>
    );

  return (
    <>
      {Button}
      <ConfirmModal />
    </>
  );
};

const Icon: FC<IconProps> = ({ icon, iconSize, iconColour }) => (
  <>
    {icon &&
      (iconSize === "L" ? (
        <LargeIcon icon={icon} color={iconColour} />
      ) : iconSize === "S" ? (
        <SmallIcon icon={icon} color={iconColour} />
      ) : (
        <MedIcon icon={icon} color={iconColour} />
      ))}
  </>
);

interface Colourable {
  $color?: string;
  $backgroundColor?: string;
  $hoverColor?: string;
  $hoverBackground?: string;
}

const StyledButton = styled(BootstrapButton)<Colourable>`
  padding-left: var(--sp-3);
  padding-right: var(--sp-3);
  height: 28px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 3rem;

  &.btn-link {
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
    background-color: ${(props) => props.$backgroundColor || "transparent"};
    border: 1px solid transparent;
    color: ${(props) => props.$color || colors.darkText};
    border-radius: 0.25rem;

    :hover {
      border: 1px solid ${(props) => props.$hoverBackground || colors.lightHover};
      background-color: ${(props) => props.$hoverBackground || colors.lightHover};
      color: ${(props) => props.$hoverColor || colors.black};
    }
    :disabled {
      color: ${colors.secondary};
      border: 1px solid transparent;
      background-color: transparent;
    }
    :active {
      border: 1px solid ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      background-color: ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      color: ${(props) => props.$color || colors.black};
      box-shadow: none;
    }
    :focus {
      border: 1px solid ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      background-color: ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      color: ${(props) => props.$hoverColor || colors.black};
      box-shadow: none;
    }
    :active:focus {
      border: 1px solid ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      background-color: ${(props) => props.$hoverBackground || colors.secondaryHover} !important;
      color: ${(props) => props.$hoverColor || colors.black};
      box-shadow: none !important;
    }
  }
`;

const IconContainer = styled.div`
  color: ${colors.primary};
`;

const ButtonContents = styled(Center)`
  gap: 0.5em;
`;

export const WideActionButton = styled(ActionButton)`
  min-width: 160px;
  height: 40px;
  font-size: 16px;
`;

export { ActionButton };
