import { Search } from "@styled-icons/material";
import { useQuestionImages } from "hooks/redux/useQuestionImages";
import { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";

const Auto_Search_Delay_In_Milliseconds = 250;

interface Props {}

const ImageSearch: FC<Props> = ({ ...rest }) => {
  const [query, setQuery] = useState("");
  const [timerId, setTimerId] = useState(0);

  const { fetchQuestionImages } = useQuestionImages();

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);

    const newQuery = e.target.value.trim();
    if (query.trim() !== newQuery) {
      clearTimeout(timerId);
      updateQueryParamsAfterDelay(newQuery);
    }
  };

  const updateQueryParamsAfterDelay = (newText: string) =>
    setTimerId(window.setTimeout(() => fetchQuestionImages(newText), Auto_Search_Delay_In_Milliseconds));

  return (
    <OuterContainer {...rest}>
      <Form.Control
        placeholder="Search for an image"
        value={query}
        onChange={onTextChange}
        style={{ border: "none" }}
      />
      <Search style={{ width: 30, height: 30, color: colors.primary }} />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid black;
  align-items: center;
`;

export { ImageSearch };
