import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { RootState } from "../app/rootReducer";
import { ServerPronunciationSet, PronunciationSet } from "types";
import { getTokenFromState } from "./sliceHelpers";
import {
  getPronunciationSets as getPronunciationSetsApi,
  updatePronunciationSet as updatePronunciationSetApi,
  deletePronunciationSet as deletePronunciationSetApi,
  createPronunciationSet as createPronunciationSetApi,
} from "api/pronunciationSets";

type PronunciationSetState = {
  data: { [id: string]: PronunciationSet } | null;
};

const initialState: PronunciationSetState = {
  data: {},
};

const parsePronunciationSet = (partialPronunciationSet: ServerPronunciationSet) => {
  return {
    id: partialPronunciationSet.id,
    name: partialPronunciationSet.name,
    ttsOverrides: partialPronunciationSet.ttsOverrides,
    loadByDefault: partialPronunciationSet.loadByDefault,
    defaultSet: false,
    ttsOverridePairs: JSON.parse(partialPronunciationSet.ttsOverrides),
  } as PronunciationSet;
};

const parsePronunciationSets = (partialPronunciationSets: ServerPronunciationSet[]) =>
  partialPronunciationSets.map((set) => parsePronunciationSet(set));

const pronunciationSetSlice = createSlice({
  name: "pronunciationSets",
  initialState,
  reducers: {
    addMany(state, action: PayloadAction<PronunciationSet[]>) {
      if (!state.data) {
        state.data = {};
      }
      for (const session of action.payload) {
        if (!(session.id in state.data)) {
          state.data[session.id] = session;
        }
      }
    },
    updateSingle(state, action: PayloadAction<PronunciationSet>) {},
    addSingle(state, action: PayloadAction<PronunciationSet>) {
      if (state.data) {
        state.data[action.payload.id] = action.payload;
      } else {
        state.data = {
          [action.payload.id]: action.payload,
        };
      }
    },
    deleteSingle(state, action: PayloadAction<string>) {
      if (state.data && state.data[action.payload]) {
        delete state.data[action.payload];
      }
    },
  },
});

const getPronunciationSets = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { token } = getTokenFromState(getState);
    const sets = await getPronunciationSetsApi(token);

    const parsedSets = parsePronunciationSets(sets);
    dispatch(addMany(parsedSets));
    return parsedSets;
  };
};

const updatePronunciationSet = (pronunciationSetId: string, pronunciationSet: Partial<ServerPronunciationSet>) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { token } = getTokenFromState(getState);
    const updatedSet = await updatePronunciationSetApi(pronunciationSetId, pronunciationSet, token);

    const parsedSet = parsePronunciationSet(updatedSet);
    dispatch(addSingle(parsedSet));
    return parsedSet;
  };
};

const deletePronunciationSet = (pronunciationSetId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { token } = getTokenFromState(getState);
    const deletedSet = await deletePronunciationSetApi(pronunciationSetId, token);
    dispatch(deleteSingle(deletedSet.id));
    return deletedSet;
  };
};

const createPronunciationSet = (pronunciationSet: Partial<ServerPronunciationSet>) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { token } = getTokenFromState(getState);
    const updatedSet = await createPronunciationSetApi(pronunciationSet, token);

    const parsedSet = parsePronunciationSet(updatedSet);
    dispatch(addSingle(parsedSet));
    return parsedSet;
  };
};

export const { addMany, addSingle, deleteSingle } = pronunciationSetSlice.actions;

const pronunciationSetsReducer = pronunciationSetSlice.reducer;
export {
  pronunciationSetsReducer,
  getPronunciationSets,
  updatePronunciationSet,
  deletePronunciationSet,
  createPronunciationSet,
};
