import { Close } from "@styled-icons/material";
import { Center } from "primitives";
import { FC } from "react";
import styled from "styled-components";
import { SmallIcon } from "primitives/icons";

interface Props {
  onClick: () => void;
}

const OuterContainer = styled(Center)`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
`;

const DeleteTagButton: FC<Props> = (props) => (
  <OuterContainer {...props}>
    <SmallIcon icon={Close} />
  </OuterContainer>
);

export { DeleteTagButton };
