import { EditClassModal } from "./EditClassModal";
import { Button } from "primitives/button";
import styled from "styled-components";
import { ClassStudentGrid } from "./studentGrid";
import { LoadingContent, EmptyPanelContainer } from "components";
import { ClassSettings } from "./ClassSettings";
import { StudentOrdering } from "./studentOrderings";
import { useState, useEffect, useMemo, useCallback } from "react";
import { defaultRequestState, FC } from "types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { dispatchAsync } from "utils";
import { deleteGrouping, deleteStudent, fetchClass, fetchClassList } from "slices";
import { generatePath, Route, useHistory, Redirect, useRouteMatch, Switch } from "react-router-dom";
import { useClassId, useGroupingId } from "hooks";
import { Center, Spacer } from "primitives";
import { EditGroupingModal } from "./partitions/editGroupingModal";
import { DeleteGroupingModal } from "./partitions/deleteGroupingModal";
import { paths, slugs } from "textConstants";
import { AddIcon } from "icons";

const ClassesOverview: FC = () => {
  const [{ loading, error, success }, setRequestState] = useState(defaultRequestState);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useAppSelector((state) => state.classList.data);
  const classList = useMemo(() => (classes ? Object.values(classes) : []), [classes]);
  const [classId] = useClassId();
  const { url, path } = useRouteMatch();
  const match = useRouteMatch<{ classId?: string }>();
  const [selectedGroupingId, setSelectedGroupingId] = useGroupingId();

  const handleCreateClassClicked = () => {
    history.push(`${url}/new`);
  };

  const handleEditClassClicked = () => {
    history.push(`${url}/edit`);
  };

  const onCloseEditClassModal = () => {
    history.push(url);
  };

  const onCloseStudentDetails = () => {
    history.push(url);
  };

  const onClassCreated = (newClassId: string) => {
    const newPath = generatePath(path, { classId: newClassId });
    history.push(`${newPath}/${slugs.students}/${slugs.add}`);
  };

  const onClassChanged = (classId: string | undefined) => {
    const path = generatePath(match.path, { ...match.params, classId });
    history.push(path);
  };

  const createGroupingClicked = () => {
    if (classes && classId) {
      history.push(paths.teacher.class.grouping.new.generate(classes[classId]));
    }
  };

  const editGroupingClicked = () => {
    if (selectedGroupingId && classId) {
      history.push(paths.teacher.class.grouping.edit.generate(selectedGroupingId, classId));
    }
  };

  const deleteGroupingClicked = () => {
    if (selectedGroupingId && classId) {
      history.push(paths.teacher.class.grouping.delete.generate(selectedGroupingId, classId));
    }
  };

  const confirmDeleteGrouping = () => {
    if (selectedGroupingId) {
      dispatch(deleteGrouping(selectedGroupingId));
      setSelectedGroupingId(undefined);
    }
  };

  const onCloseEditGroupingModal = () => {
    history.push(url);
  };

  const onGroupingCreated = (newGroupingId: string) => {
    setSelectedGroupingId(newGroupingId);
    history.push(url);
  };

  const fetchClassFromId = useCallback(async () => {
    try {
      if (classId) {
        await dispatch(fetchClass(classId));
      }
    } catch (ex) {
      // TODO: display error about incorrect classId
    }
  }, [classId, dispatch]);

  useEffect(() => {
    fetchClassFromId();
  }, [fetchClassFromId]);

  useEffect(() => {
    dispatchAsync(dispatch(fetchClassList()), setRequestState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [studentOrdering, setStudentOrdering] = useState<StudentOrdering>();

  if (classes && classId && !classes[classId]) {
    return (
      <Route path={url}>
        <Redirect to={`/teacher/class`} />
      </Route>
    );
  }

  const handleSelectStudent = (studentId: string) => {
    history.push(`${url}/${slugs.student}/${studentId}`);
  };

  const handleDeleteStudent = async (studentId: string) => {
    history.push(url);
    await dispatchAsync(dispatch(deleteStudent(studentId)), setRequestState);
  };

  const MainPageComponent = (
    <OuterContainer className="classes-overview">
      <ClassSettings
        {...{
          studentOrdering,
          setStudentOrdering,
          onStudentPasswordsRefreshed: onCloseStudentDetails,
          handleCreateClass: handleCreateClassClicked,
          handleEditClass: handleEditClassClicked,
          onClassUpdated: onClassChanged,
          handleCreateGrouping: createGroupingClicked,
          handleEditGrouping: editGroupingClicked,
          handleDeleteGrouping: deleteGroupingClicked,
        }}
      />
      <ClassStudentGrid
        sortStudents={studentOrdering?.handleSort}
        onClickStudent={handleSelectStudent}
        onDeleteStudent={handleDeleteStudent}
      />
    </OuterContainer>
  );

  return (
    <LoadingContent loading={loading} error={error} success={success}>
      {!loading && classList?.length === 0 ? (
        <EmptyOuterContainer>
          <EmptyPanelContainer
            title="You don't have any classes yet"
            message={
              <Center>
                Click
                <Spacer size={5} />
                <Button className="create-class-button" onClick={handleCreateClassClicked} variant="outline-primary">
                  <AddIcon className="mr-1" />
                  Create Class
                </Button>
                <Spacer size={5} />
                to make your class
              </Center>
            }
          />
        </EmptyOuterContainer>
      ) : (
        <>{MainPageComponent}</>
      )}
      <Route path={`${path}/new`}>
        <EditClassModal visible={true} onComplete={onClassCreated} hide={onCloseEditClassModal} />
      </Route>
      <Route path={`${path}/edit`}>
        <EditClassModal
          visible={true}
          onComplete={onCloseEditClassModal}
          hide={onCloseEditClassModal}
          classId={classId}
        />
      </Route>
      <Route path={paths.teacher.class.grouping.edit.path}>
        <Switch>
          <Route path={paths.teacher.class.grouping.edit.path} exact>
            <EditGroupingModal hide={onCloseEditGroupingModal} onComplete={onGroupingCreated} classId={classId} />
          </Route>
          <Route path={paths.teacher.class.grouping.delete.path}>
            <DeleteGroupingModal onModalHidden={onCloseEditGroupingModal} onConfirmDelete={confirmDeleteGrouping} />
          </Route>
        </Switch>
      </Route>
    </LoadingContent>
  );
};

const EmptyOuterContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export { ClassesOverview };
