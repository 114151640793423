import { colors } from "styles";

export interface CardStyle {
  bgColor: string;
  textColor: string;
  inactiveTextColor?: string;
  activePrimaryColor: string;
  inactivePrimaryColor: string;
}

const GetCardStyleForResult = (result: number | undefined, totalAnswers: number, isAssigned: boolean): CardStyle => {
  if (result === undefined || isNaN(result)) {
    return isAssigned ? greyStudentCardColourScheme : inactiveStudentCardColourScheme;
  } else if (result >= 0.75) {
    return greenStudentCardColourScheme;
  } else if (result >= 0.5) {
    return yellowStudentCardColourScheme;
  } else if (result > 0) {
    return redStudentCardColourScheme;
  } else if (result === 0) {
    if (totalAnswers > 0) {
      return redStudentCardColourScheme;
    } else {
      return greyStudentCardColourScheme;
    }
  }

  return greyStudentCardColourScheme;
};

const defaultStudentCardColourScheme: CardStyle = {
  bgColor: colors.palePrimary,
  activePrimaryColor: colors.primary,
  inactivePrimaryColor: colors.darkPalePrimary,
  textColor: colors.primary,
  inactiveTextColor: colors.darkPalePrimary,
};

const greenStudentCardColourScheme: CardStyle = {
  activePrimaryColor: colors.success,
  inactivePrimaryColor: colors.veryPaleSuccess,
  textColor: colors.success,
  bgColor: colors.veryPaleSuccess,
};

const yellowStudentCardColourScheme: CardStyle = {
  activePrimaryColor: colors.warning,
  inactivePrimaryColor: colors.warningNameLabel,
  textColor: colors.warningText,
  bgColor: colors.paleWarning,
};

const redStudentCardColourScheme: CardStyle = {
  activePrimaryColor: colors.paleDanger,
  inactivePrimaryColor: colors.paleDanger,
  textColor: colors.paleDanger,
  bgColor: colors.veryPaleDanger,
};

export const greyStudentCardColourScheme: CardStyle = {
  activePrimaryColor: colors.secondary,
  inactivePrimaryColor: colors.secondaryNameLabel,
  textColor: colors.secondaryNameLabel,
  bgColor: colors.inactiveButton,
};

export const inactiveStudentCardColourScheme: CardStyle = {
  activePrimaryColor: colors.inactiveButton,
  inactivePrimaryColor: colors.innerBorder,
  textColor: colors.innerBorder,
  bgColor: colors.white,
};

export { GetCardStyleForResult, defaultStudentCardColourScheme };
