import { useAppSelector } from "app/hooks";
import { MiniGameCard } from "components";
import { Center, PrimaryButton, Spinner } from "primitives";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchSession } from "slices";
import { getSessionType } from "types";
import { LessonPlanListItem } from "./LessonPlanListItem";

interface Props {
  onHidden: () => void;
  isPublicView?: boolean;
}

const LessonPlanPreviewModal: FC<Props> = ({ onHidden, isPublicView = false }) => {
  const [closing, setClosing] = useState(false);
  const onModalHidden = () => {
    setClosing(false);
    onHidden();
  };
  const { sessionId } = useParams<{ sessionId: string }>();
  const session = useAppSelector((state) =>
    state.sessionList.data && sessionId ? state.sessionList.data[sessionId] : undefined
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId && !session) {
      dispatch(fetchSession(sessionId));
    }
  }, [sessionId, session, dispatch]);

  return (
    <Modal
      show={!closing}
      onHide={() => setClosing(true)}
      onExited={onModalHidden}
      size="lg"
      scrollable={true}
      centered
    >
      <Modal.Body id="session-preview-modal">
        {session ? (
          <>
            <LessonPlanListItem
              parentElementId="session-preview-modal"
              session={session}
              sessionType={getSessionType(session)}
              omitActions={["preview"]}
              isPublicView={isPublicView}
            ></LessonPlanListItem>
            {session?.customGames?.map((miniGame) => (
              <MiniGameCard className="p-3 mb-3" key={miniGame.id} miniGame={miniGame} />
            ))}
          </>
        ) : (
          <Spinner />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Center>
          <PrimaryButton variant="secondary" onClick={onModalHidden}>
            Close
          </PrimaryButton>
        </Center>
      </Modal.Footer>
    </Modal>
  );
};

export { LessonPlanPreviewModal };
