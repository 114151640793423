import { Names } from "textConstants/main";
import { paths, slugs } from "textConstants/paths";
import { Tour } from "types";
import { TourStepContainer } from "./tourComponents";

const createLesson: Tour = {
  id: "create lesson",
  name: `Create a ${Names.session}`,
  startUrl: paths.teacher.myGames.path,
  runAutomatically: (location, state) =>
    location.pathname.startsWith(paths.teacher.myGames.path) &&
    !location.pathname.includes(`/${slugs.session}`) &&
    !location.pathname.includes(`/${slugs.edit}`) &&
    !location.pathname.includes(`/${slugs.review}`) &&
    state.classList != null &&
    state.classList.data != null &&
    Object.values(state.classList.data).length > 0,
  disableInHelpMenu: (_, state) =>
    !state.classList || !state.classList.data || Object.values(state.classList.data).length === 0,
  steps: [
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector(".create-lesson-button")),
      isEmpty: true,
    },
    {
      target: ".create-lesson-button",
      content: (
        <p>
          Create a {Names.session} by <span className="text-primary">clicking the + button</span>.
        </p>
      ),
      autoProgressCheck: (location) => location.pathname.includes(`/${slugs.session}/${slugs.new}`),
      hideNext: true,
      hideBack: true,
    },
    {
      target: ".create-lesson-modal",
      content: (
        <p>
          Fill out the details and <span className="text-primary">press "Confirm"</span> when you're ready.
        </p>
      ),
      autoProgressCheck: (location) => !location.pathname.includes(`/${slugs.session}/${slugs.new}`),
      hideBack: true,
      hideNext: true,
    },
    {
      target: "#empty-tour-target",
      content: ``,
      autoProgressCheck: () => document.body.contains(document.querySelector(".question-set-selector")),
      isEmpty: true,
    },
    {
      target: ".custom-game-progress",
      content: `Create a ${Names.customGame} to add to the ${Names.session}, the tutorial will continue after you finish.`,
      hideBack: true,
    },
  ],
};

const editLesson: Tour = {
  id: "edit lesson",
  name: `Edit ${Names.Session}`,
  runAutomatically: (location) =>
    location.pathname.startsWith(paths.teacher.myGames.path) &&
    location.pathname.includes(`/${slugs.session}`) &&
    !location.pathname.includes(`/${slugs.miniGame}`) &&
    location.pathname.includes(`/${slugs.edit}`),
  disableInHelpMenu: (location) =>
    !location.pathname.startsWith(paths.teacher.myGames.path) ||
    location.pathname.includes(`/${slugs.miniGame}`) ||
    !location.pathname.includes(`/${slugs.session}`) ||
    !location.pathname.includes(`/${slugs.edit}`),
  steps: [
    {
      target: "#empty-tour-target",
      content: ``,
      autoProgressCheck: () => document.body.contains(document.querySelector(".session-details")),
      isEmpty: true,
    },
    {
      target: ".session-details",
      content: (
        <TourStepContainer>
          <p className="mb-3">Here are the settings you can modify for this {Names.session}. </p>
          <p>
            You can rename the {Names.session}, change or add a time that this {Names.session} will automatically start.
          </p>
        </TourStepContainer>
      ),
      hideBack: true,
      spotlightPadding: 5,
      disableClickthrough: true,
    },
    {
      target: ".custom-games-list",
      content: (
        <TourStepContainer>
          <p className="mb-3">
            Here is the list of {Names.customGames} that are a part to this {Names.session}.{" "}
          </p>
        </TourStepContainer>
      ),
      disableClickthrough: true,
    },
    {
      target: ".student-grid",
      content: (
        <TourStepContainer>
          <p className="mb-3">Here you can see a list of all your students assigned to this game.</p>
          <p>Select any students you wish to unassign then click "Save" to remove them.</p>
        </TourStepContainer>
      ),
    },
    {
      target: ".unsaved-changes-container",
      content: `Apply or revert any changes made to your ${Names.session} here.`,
      disableClickthrough: true,
    },
    {
      target: ".new-game-button",
      content: `You can add more ${Names.customGames} to a ${Names.session} by clicking the + button here.`,
      disableClickthrough: true,
    },
    {
      target: "#start-action-button",
      content: `Start a ${Names.session} immediately by pressing the "Schedule ${Names.Session}" button here.`,
      disableClickthrough: true,
    },
  ],
};

export const MyGameTours: Tour[] = [createLesson, editLesson];
