import { FC, useEffect, useState } from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { Center } from "primitives";
import { GameTemplateCard } from "components";
import { GameTemplate } from "types";
import { useGameTemplates } from "hooks";
import { Names } from "textConstants";

interface Props {
  initialSelectedId?: string;
  questionNumber?: number;
  onSelectedGameChanged?: (template: GameTemplate) => void;
}

const GameTemplatesList: FC<Props> = ({ initialSelectedId, questionNumber, onSelectedGameChanged }) => {
  const { loading, success, data: gameTemplates } = useGameTemplates(true);

  useEffect(() => {
    if (gameTemplates && gameTemplates.length > 0 && onSelectedGameChanged && success) {
      let defaultIndex = -1;
      if (initialSelectedId) {
        defaultIndex = gameTemplates.findIndex((gameTemplate) => gameTemplate.id === initialSelectedId);
      }
      if (defaultIndex >= 0) {
        onSelectedGameChanged(gameTemplates[defaultIndex]);
        setSelectedTemplateIndex(defaultIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);

  return (
    <>
      {loading ? (
        <Center style={{ minHeight: 100 }}>
          <Spinner animation="border" />
        </Center>
      ) : gameTemplates ? (
        <Row className="justify-content-md-center" style={{ maxWidth: "1500px" }}>
          {gameTemplates.map((template, i) => (
            <Col md="auto" style={{ marginBottom: "10px" }} key={template.id}>
              <GameTemplateCard
                isSelected={selectedTemplateIndex === i}
                onClick={() => {
                  setSelectedTemplateIndex(i);
                  onSelectedGameChanged && onSelectedGameChanged(template);
                }}
                disabledMessage={
                  template.minQuestions && questionNumber && questionNumber < template.minQuestions
                    ? `You need a minimum of ${template.minQuestions} total questions to choose this ${Names.gameTemplate}`
                    : undefined
                }
                gameTemplate={template}
                style={{ height: "100%" }}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <p>Something went wrong!</p>
      )}
    </>
  );
};

export { GameTemplatesList };
