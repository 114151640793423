import { Cross } from "@styled-icons/entypo";
import { ButtonProps } from "react-bootstrap";
import { CSSProperties } from "styled-components";
import { actionConstants } from "textConstants";
import { FC } from "types";
import { SmallIconButton } from "./IconButton";

interface Props extends ButtonProps {
  tooltip: string;
  onClick?: () => void;
  circular?: boolean;
  style?: CSSProperties;
  altIcon?: boolean;
}

const DeleteButton: FC<Props> = ({ altIcon, ...props }) => (
  <SmallIconButton {...props} icon={altIcon ? Cross : actionConstants.delete.icon} variant={"danger"} />
);

export { DeleteButton };
