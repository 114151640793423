const layoutConstants = {
  navBarHeight: "80px",
  navBarItemMinWidth: "150px",
  navBarItemMinWidthTablet: "100px",
  breadcrumbHeight: "60px",

  questionSets: {
    listWidth: "600px",
    listMinWidth: "525px",
    detailsWidth: "600px",

    smallListWidth: "400px",
    smallListMinWidth: "325px",
    smallDetailsWidth: "400px",
  },

  sessionListWidth: "900px",
  sessionListWidthTablet: "700px",
  classSelectorDefaultWidth: "300px",
  defaultTransition: "all 0.3s ease-out",
  onboarding: {
    smallContainerWidth: "510px",
    medContainerWidth: "646px",
    largeContainerWidth: "716px",
  },
  customGameProgressHeight: "60px",
};

export { layoutConstants };
