import {
  LoginBackgroundContainer,
  SecondaryText,
  Spinner,
  OnboardingLocation,
  VerticalCenter,
  Spacer,
} from "primitives";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles";
import QRCode from "qrcode.react";
import { useHistory, useParams } from "react-router-dom";
import { NotFound } from "./NotFound";
import { toggleFullScreen, documentIsFullScreen, dispatchAsync } from "utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AsyncRequestState, defaultRequestState, FC, UserAction } from "types";
import { fetchSession, fetchCustomGame } from "slices";
import { Fullscreen, FullscreenExit } from "@styled-icons/material";
import { LargeIcon } from "primitives/icons";
import { OnboardingElementContainer } from "pages";
import { paths, slugs } from "textConstants";

interface Props {
  children?: ReactNode;
  loading?: boolean;
  url?: string;
  title: string;
}

const QRCodePage: FC<Props> = ({ children, loading, title, url }) => {
  const history = useHistory();

  const backPressed = async () => {
    history.goBack();
  };

  const backAction: UserAction = {
    id: "back",
    name: "Back",
    action: backPressed,
  };

  const content = loading ? (
    <Spinner />
  ) : (
    url && (
      <>
        <FullScreenToggle />
        <OnboardingElementContainer title={title} size="M" backAction={backAction}>
          <VerticalCenter>
            {children}
            <QRCode value={url} renderAs="svg" fgColor={colors.darkText} style={{ width: 275, height: 275 }} />
            <Spacer size={10} />
            <InfoText>Scan me to play !!!</InfoText>
          </VerticalCenter>
        </OnboardingElementContainer>
      </>
    )
  );

  return (
    <LoginBackgroundContainer
      backgroundColour={colors.brandBlue}
      location={OnboardingLocation.teacherlogin}
      includeLogo={false}
    >
      {content}
    </LoginBackgroundContainer>
  );
};

const PlayGame: FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const session = useAppSelector((state) => (state.sessionList.data ? state.sessionList.data[sessionId] : undefined));
  const [{ loading, error }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!session && !loading && !error && sessionId) {
      dispatchAsync(dispatch(fetchSession(sessionId)), setRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  if (!sessionId) {
    return <NotFound />;
  }

  return (
    <QRCodePage
      url={session ? `${window.location.origin}/${slugs.student}/${slugs.session}/${session!.sessionCode}` : undefined}
      title={session?.name || ""}
      loading={loading}
    >
      <InfoText>Go to</InfoText>
      <a href={paths.login.student.path} target="_blank" rel="noreferrer">
        <LinkText>
          {window.location.origin}
          {paths.login.student.path}
        </LinkText>
      </a>
      <InfoText>or</InfoText>
      <Spacer size={10} />
    </QRCodePage>
  );
};

export const PlayQuickGame: FC = () => {
  const { customGameId } = useParams<{ customGameId: string }>();
  const customGame = useAppSelector((state) =>
    state.customGame.data ? state.customGame.data[customGameId] : undefined
  );
  const [{ loading, error }, setRequestState] = useState<AsyncRequestState>(defaultRequestState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!customGame && !loading && !error && customGameId) {
      dispatchAsync(dispatch(fetchCustomGame(customGameId)), setRequestState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customGameId]);

  if (!customGameId) {
    return <NotFound />;
  }

  return (
    <QRCodePage
      url={customGame?.gameLink || undefined}
      title={customGame?.gameTemplate?.name || ""}
      loading={loading}
    />
  );
};

const InfoText = styled(SecondaryText)`
  font-size: 14px;
`;

const LinkText = styled(SecondaryText)`
  font-size: 24px;
  font-weight: 600;
  padding: 0.5em;
`;

const FullScreenToggle = () => {
  const [isFullScreen, setIsFullScreen] = useState(documentIsFullScreen());
  return (
    <div
      style={{ position: "fixed", top: 30, right: 30, color: "white" }}
      onClick={() => {
        toggleFullScreen();
        setIsFullScreen(!isFullScreen);
      }}
    >
      {isFullScreen ? <LargeIcon icon={FullscreenExit} /> : <LargeIcon icon={Fullscreen} />}
    </div>
  );
};

export { PlayGame };
