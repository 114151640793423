import { Lock, Visibility, VisibilityOff } from "@styled-icons/material";
import { isEqual } from "lodash";
import { Column, Spacer } from "primitives";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Validator, passwordValidator as passwordValidatorHook, useValidatableState } from "hooks/useValidatableState";
import { InputAppendIcon, InputPrependIcon } from "./FormInputItems";
import { APIFormErrorFeedback } from "components";
import { ApiError } from "types";
interface InputProps {
  placeholder: string;
  password: string;
  setPassword: (password: string) => void;
  showPassword: boolean;
  toggleShowPassword: () => void;
  failedAttemptErrorMsg: string | undefined;
  style?: CSSProperties;
}

interface GroupProps {
  basePlaceholder: string;
  setPassword: (password: string) => void;
  setIsValid: (isValid: boolean) => void;
  style?: CSSProperties;
}

const ResetPasswordInput: FC<InputProps> = ({
  placeholder,
  password,
  setPassword,
  showPassword,
  toggleShowPassword,
  failedAttemptErrorMsg,
  style,
}) => (
  <>
    <InputGroup style={{ flex: 1, ...style }}>
      <InputPrependIcon icon={Lock} />
      <Form.Control
        isInvalid={Boolean(failedAttemptErrorMsg)}
        placeholder={placeholder}
        aria-label="Password"
        aria-describedby="basic-addon1"
        type={showPassword ? undefined : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="new-password"
      />
      <InputAppendIcon icon={showPassword ? VisibilityOff : Visibility} onClick={toggleShowPassword} />
    </InputGroup>
    <APIFormErrorFeedback
      error={failedAttemptErrorMsg ? ApiError.authError : null}
      errorMessages={{ [ApiError.authError]: failedAttemptErrorMsg }}
    />
  </>
);

export const ResetPasswordInputGroup: FC<GroupProps> = ({ basePlaceholder, setPassword, setIsValid, style }) => {
  const passwordValidator: Validator = [passwordValidatorHook];

  const [showPassword, setShowPassword] = useState(false);
  const [passwordInput, setNewPasswordInput, passwordInputError] = useValidatableState("", passwordValidator);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");

  const arePasswordsEqualError =
    passwordInput && confirmPasswordInput && !isEqual(passwordInput, confirmPasswordInput)
      ? "Does not match password."
      : undefined;

  const isValid =
    Boolean(passwordInput) &&
    Boolean(confirmPasswordInput) &&
    !Boolean(passwordInputError) &&
    !Boolean(arePasswordsEqualError);

  const handleSetPassword = (password: string) => {
    setPassword(password);
    setNewPasswordInput(password);
  };

  const handleSetConfirmPassword = (password: string) => {
    setConfirmPasswordInput(password);
  };

  const setPasswordValidity = useCallback(() => {
    setIsValid(isValid);
  }, [setIsValid, isValid]);

  useEffect(() => {
    setPasswordValidity();
  }, [passwordInput, confirmPasswordInput, passwordInputError, arePasswordsEqualError, setPasswordValidity]);

  return (
    <Column>
      <Form.Label>{basePlaceholder}*</Form.Label>
      <ResetPasswordInput
        placeholder={basePlaceholder}
        password={passwordInput}
        setPassword={handleSetPassword}
        showPassword={showPassword}
        toggleShowPassword={() => setShowPassword(!showPassword)}
        failedAttemptErrorMsg={passwordInputError}
        style={style}
      />
      <Spacer size={3} />
      <Form.Label>Retype {basePlaceholder}*</Form.Label>
      <ResetPasswordInput
        placeholder={`Retype ${basePlaceholder}`}
        password={confirmPasswordInput}
        setPassword={handleSetConfirmPassword}
        showPassword={showPassword}
        toggleShowPassword={() => setShowPassword(!showPassword)}
        failedAttemptErrorMsg={arePasswordsEqualError}
        style={style}
      />
    </Column>
  );
};
