import { Column, Row, Spacer } from "primitives";
import { Button } from "primitives/button";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Price } from "types";
import { getBillingCycle, getBillPrice, getMonthlyPrice, getPriceHeader } from "utils";

interface Props {
  price: Price;
  popular: boolean;
  imagePath: string;
  canTrial: boolean;
  percentOff?: number;
  isActive: boolean; // Are they already using this subscription?
  onSubscriptionSelected: (priceId: string) => void;
}

export const SubscriptionProductCardDesktop: FC<Props> = ({
  price,
  popular,
  imagePath,
  canTrial,
  percentOff,
  isActive,
  onSubscriptionSelected,
}) => {
  return (
    <OuterContainer $isActive={isActive}>
      <CardImage src={imagePath} />
      <Spacer />
      <SplitRow>
        <h3>{getPriceHeader(price)}</h3>
        <DiscountPrice color={Boolean(percentOff) ? colors.success : colors.darkText}>
          ${getMonthlyPrice(price, percentOff)}
        </DiscountPrice>
      </SplitRow>
      <SplitRow>
        <small>Membership</small>
        <small>/per mo</small>
      </SplitRow>
      {popular ? <PopularTag>Most Popular</PopularTag> : <Spacer />}
      <SplitRow>
        <Column>
          <small>
            Billed at ${getBillPrice(price, percentOff)} every {getBillingCycle(price)}.
          </small>
          <small>Cancel anytime.</small>
        </Column>
      </SplitRow>
      <Spacer />
      <Button onClick={() => onSubscriptionSelected(price.id)} disabled={isActive}>
        {isActive ? "Current Subscription" : canTrial ? "Start Free-Trial" : "Start Subscription"}
      </Button>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)<{ $isActive: boolean }>`
  border: 3px solid ${(props) => (props.$isActive ? colors.success : colors.darkText)};
  background-color: white;
  width: 300px;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 20px;
  color: ${colors.darkText};
  h3 {
    font-weight: 600;
  }
`;

const PopularTag = styled.div`
  background-color: ${colors.palePrimary};
  border-radius: 3px;
  padding: 0px 5px;
  margin: 8px;
  font-size: 8pt;
`;

const CardImage = styled.img`
  height: 150px;
  margin-top: -50px;
`;

const SplitRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding: 5px 15px;
`;

const DiscountPrice = styled.h3<{ color: string }>`
  color: ${(props) => props.color};
`;
