import { TutorialsProvider } from "components";
import { RootBreadcrumb } from "components/breadcrumb";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { breadcrumbConstants, slugs } from "textConstants";
import { ClassTours } from "textConstants/tours";
import { ArticleProvider } from "components/articles";
import { AddStudentsToClass } from "./addStudentsToClass";
import { ClassesOverview } from "./classesOverview";
import { StudentPasswordSheet } from "./printStudentCardSheet";

export interface ClassParams {
  classId: string;
  studentId: string;
}

const ClassesRoutes = () => {
  const { url } = useRouteMatch(); // should be /teacher/class
  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.class.title}
        description={breadcrumbConstants.class.breadcrumbDescription}
        icon={breadcrumbConstants.class.icon}
      />
      <TutorialsProvider section="class" tours={ClassTours} />
      <ArticleProvider />
      <Switch>
        <Route path={`${url}/${slugs.classId}/${slugs.print}`} component={StudentPasswordSheet} />
        <Route path={`${url}/${slugs.classId}/${slugs.students}`} component={AddStudentsToClass} />
        <Route path={`${url}/${slugs.classId}?`} component={ClassesOverview} />
      </Switch>
    </>
  );
};

export { ClassesRoutes as Classes };
