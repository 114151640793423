import { FC } from "react";
import styled, { CSSProperties } from "styled-components";
import { colors } from "styles";
import { Row, VerticalLine } from "primitives";
import { QuestionSet } from "types";
import { ListCard, ListCardStatus, ListCardTitle, TagRow } from "components";
import { useQuestionSetActions, QuestionSetActionHook } from "hooks";
import pluralize from "pluralize";
import { QuestionSetReviewStatus } from "./QuestionSetReviewStatus";
import { getQuestionSetLatestReviewSubmission } from "utils";
import { MedIcon } from "primitives/icons";
import { uniq } from "lodash";
import { breadcrumbConstants, questionConstants } from "textConstants";

interface Props {
  questionSet: QuestionSet;
  style?: CSSProperties;
  selected?: boolean;
  focused?: boolean;
  onClick?: () => void;
  actionHooks?: Array<QuestionSetActionHook>;
  scroll?: number;
  parentElementId?: string;
}

const QuestionSetCard: FC<Props> = ({
  questionSet,
  selected,
  focused,
  onClick,
  actionHooks,
  scroll,
  parentElementId,
  style,
}) => {
  const { description } = questionSet;

  const actions = useQuestionSetActions(questionSet, actionHooks);

  return (
    <ListCard
      className="mr-1"
      actions={actions}
      onClick={onClick}
      scroll={scroll}
      parentElementId={parentElementId}
      style={style}
      isSelected={selected}
    >
      <QuestionSetCardHeader questionSet={questionSet} />
      {description}
    </ListCard>
  );
};

export const QuestionSetCardHeader: FC<{ questionSet: QuestionSet }> = ({ questionSet }) => {
  const { title, numQuestions } = questionSet;

  const questionTypes = uniq(questionSet.questions?.map((question) => question.type));

  return (
    <>
      <ListCardStatus>
        <StatusContainer>
          <QuestionSetReviewStatus
            questionSet={questionSet}
            latestSubmission={getQuestionSetLatestReviewSubmission(questionSet)}
          />
        </StatusContainer>
      </ListCardStatus>
      <ListCardTitle name={title} icon={breadcrumbConstants.questions.icon}>
        <VerticalLine margin={"0.2em 1em"} />
        <ValidatableStat number={numQuestions} errorValue={0}>
          {numQuestions} {pluralize("Question", numQuestions)}
        </ValidatableStat>
      </ListCardTitle>
      <QuestionTypeRow>
        Contains:
        <Row>
          {questionTypes.map((type, index) => (
            <span key={index}>
              <Row>
                <MedIcon icon={questionConstants.questionTypes[type].icon} />
                {questionConstants.questionTypes[type].copy}
                {index !== questionTypes.length - 1 && <VerticalLine />}
              </Row>
            </span>
          ))}
        </Row>
      </QuestionTypeRow>
      <TagRow tags={questionSet.tags} />
    </>
  );
};

const StatusContainer = styled.div`
  position: relative;
  right -5px;
`;

const QuestionTypeRow = styled(Row)`
  gap: 10px;
  span {
    color: ${colors.secondary};
  }
  :first-child {
    margin-left: 10px;
  }
  div {
    align-items: center;
  }
`;

const ValidatableStat = styled.p<{ number: number; errorValue: number }>`
  color: ${(props) => (props.number === props.errorValue ? colors.danger : colors.secondary)};
`;

export { QuestionSetCard };

export type { Props as QuestionSetCardProps };
