import { FC } from "types";
import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useOutsideAlerter } from "../hooks";

interface Props {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder: string;
  labelClasses: string;
  inputClasses: string;
  onBlur?: () => void;
}

export const InlineEditableLabel: FC<Props> = ({
  value,
  onChange,
  disabled = false,
  placeholder,
  labelClasses,
  inputClasses,
  onBlur,
}) => {
  const [editing, setEditing] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);

  const outsideSelected = () => {
    if (editing) {
      toggle();
    }
  };

  useOutsideAlerter(inputEl, outsideSelected);

  const toggle = () => {
    if (!disabled) {
      setEditing(!editing);
      if (editing && inputEl.current) {
        inputEl.current.focus();
      }
    }
  };

  const handleEnter = (e: any) => {
    if (e.keyCode === 13 && inputEl.current) {
      inputEl.current.blur();
    }
  };

  const handleBlur = () => {
    toggle();
    if (onBlur) {
      onBlur();
    }
  };

  const handleInput = (e: any) => {
    emitValue(e.target.value);
  };

  const emitValue = (value: string) => {
    onChange(value);
  };
  const computeLabel = () => {
    return value ? value : placeholder;
  };

  const renderLabel = () => (
    <span className={labelClasses} onClick={toggle}>
      {computeLabel()}
    </span>
  );

  const renderInput = () => (
    <Form.Control
      ref={inputEl}
      defaultValue={value}
      className={inputClasses}
      placeholder={placeholder}
      onBlur={handleBlur}
      onKeyUp={handleEnter}
      onInput={handleInput}
    />
  );

  return editing ? renderInput() : renderLabel();
};
