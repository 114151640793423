import { useAppDispatch, useAppSelector } from "app/hooks";
import { SessionTimeLabel } from "pages/my-games/lessonPlans/LessonTimeLabel";
import pluralize from "pluralize";
import { ActionButton, VerticalLine } from "primitives";
import styled from "styled-components";
import { colors } from "styles";
import { CustomGame, FC, getSessionType, Session, SessionType, UserAction } from "types";
import {
  doesCustomGameHaveResult,
  getNextIncompleteCustomGame,
  hasCustomGameAttemptsRemaining,
  hasSessionAttemptsRemaining,
  isTokenInLocalStorage,
} from "utils";
import { AssignmentImage } from "./StudentAssignmentButton";
import { helpMessages, Names, paths } from "textConstants";
import { useConfirmModal, useLogin } from "hooks";
import { fetchSessionStudent } from "slices";

interface Props {
  session: Session;
}

const StudentSessionListItem: FC<Props> = ({ session }) => {
  const { token: studentToken, student } = useAppSelector((state) => state.studentLogin);
  const sessionType = getSessionType(session);
  const canPlaySession = hasSessionAttemptsRemaining(session, student!.id);
  const dispatch = useAppDispatch();
  const [ConfirmModal, setConfirmModalVisible, setConfirmModalInfo] = useConfirmModal();

  const { logout } = useLogin();
  const gameCount = session.customGames?.length || 0;

  const actions: { [key: string]: UserAction } = {
    playNextInSession: {
      id: "playNext",
      name: "Play",
      action: async () => {
        if (!studentToken || !isTokenInLocalStorage(studentToken)) {
          logout(paths.login.student.path, undefined, true);
        } else {
          const nextCustomGame = getNextIncompleteCustomGame(session, student!.id);
          if (nextCustomGame) {
            await playSpecificCustomGame(nextCustomGame);
          }
        }
      },
      isEnabled: () => sessionType === SessionType.Current && canPlaySession,
    },
  };

  const playSpecificCustomGame = async (customGame: CustomGame) => {
    if (session.maxAttempts) {
      let newWindow = window.open("", "_blank");
      if (newWindow !== null) {
        const updatedSession = await dispatch(fetchSessionStudent(session.id));

        let success = false;
        let errorMessage = helpMessages.studentCustomGame.customGameIssues.generic;
        if (updatedSession) {
          if (hasCustomGameAttemptsRemaining(updatedSession, customGame.id, student!.id)) {
            newWindow.location.href = `${customGame.gameLink}&token=${studentToken}`;
            success = true;
          } else {
            errorMessage = helpMessages.studentCustomGame.customGameIssues.maxAttempts;
          }
        } else {
          errorMessage = helpMessages.studentCustomGame.customGameIssues.maxAttempts;
        }

        if (!success) {
          newWindow.close();
          setConfirmModalInfo({
            title: helpMessages.studentCustomGame.customGameIssues.title,
            body: [errorMessage],
            isPreview: true,
          });
          setConfirmModalVisible(true);
        }
      }
    } else {
      openGameLink(customGame.gameLink);
    }
  };

  const openGameLink = (gameLink: string) => {
    let newWindow = window.open("", "_blank");
    if (newWindow !== null) {
      newWindow.location.href = `${gameLink}&token=${studentToken}`;
    }
  };

  return (
    <OuterContainer>
      <HeaderContainer>
        <LeftHeaderContainer>
          <SessionName>{session.name}</SessionName>
          <VerticalLine />
          <p>
            {gameCount} {pluralize(Names.CustomGame, gameCount)}
          </p>
        </LeftHeaderContainer>
        <RightHeaderContainer>
          <SessionTimeLabel session={session} />
          <PlayActionButton action={actions.playNextInSession}>{actions.playNextInSession.name}</PlayActionButton>
        </RightHeaderContainer>
      </HeaderContainer>
      <AssignmentContainer>
        {session.customGames &&
          session.customGames.map((customGame, index) => (
            <AssignmentImage
              key={index}
              customGame={customGame}
              index={index}
              hasPlayed={doesCustomGameHaveResult(customGame, student!.id)}
              canPlay={
                sessionType === SessionType.Current &&
                hasCustomGameAttemptsRemaining(session, customGame.id, student!.id)
              }
              onPlayClicked={playSpecificCustomGame}
            />
          ))}
      </AssignmentContainer>
      <ConfirmModal />
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.innerBorder};
  border-radius: 5px;
  height: 15em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  align-items: stretch;
  gap: 1em;
  margin-bottom: var(--sp-3);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: space-between;
  p {
    font-size: 12pt;
    font-weight: 600;
    color: ${colors.secondary};
  }
`;

const LeftHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RightHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssignmentContainer = styled.div`
  background-color: ${colors.light};
  border-radius: 5px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  padding: 10px;
`;

const SessionName = styled.p`
  color: ${colors.primary} !important;
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;
`;

const PlayActionButton = styled(ActionButton)`
  width: 85px;
  &:disabled,
  &.disabled {
    background-color: ${colors.secondary};
    border-color: ${colors.secondary};
  }
`;

export { StudentSessionListItem };
