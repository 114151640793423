import { CSSProperties } from "react";
import { FC } from "types";
import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  gap?: string;
  style?: CSSProperties;
}

const Row: FC<Props> = ({ children, style, gap, ...rest }) => (
  <StyledRow
    style={{
      gap: gap,
      ...style,
    }}
    {...rest}
  >
    {children}
  </StyledRow>
);

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export { Row };
