import { Close, Warning } from "@styled-icons/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import dayjs from "dayjs";
import { useLogin, useTutorialState } from "hooks";
import { Center, Row } from "primitives";
import { PrimaryButton } from "primitives/button";
import { LargeIcon, MedIcon } from "primitives/icons";
import { useCallback, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { getSubscriptionInfo } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { PlatformTitle } from "textConstants";
import { AsyncRequestState, defaultRequestState, FC } from "types";
import { dispatchAsync, hasActiveSubscription } from "utils";

interface BannerProps {
  copy: string;
  buttonText: string;
  allowClose: boolean;
  buttonLink: string;
  onClosePressed?: () => void;
}

const BannerContent: FC<BannerProps> = ({ copy, buttonText, allowClose, buttonLink, onClosePressed }) => {
  return (
    <OuterContainer>
      <Center>
        <InnerContainer>
          <LargeIcon icon={Warning} />
          <p>{copy}</p>
          <PrimaryButton href={buttonLink}>{buttonText}</PrimaryButton>
        </InnerContainer>
      </Center>
      {allowClose && (
        <CloseButton onClick={onClosePressed}>
          <MedIcon icon={Close} />
        </CloseButton>
      )}
    </OuterContainer>
  );
};

export const SubscriptionBanner: FC = () => {
  const { userInfo } = useLogin();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { info: subscriptionInfo } = useAppSelector((state) => state.subscription);
  const dispatch = useAppDispatch();
  const [requestState, setRequestState] = useState<AsyncRequestState>({
    ...defaultRequestState,
    loading: true,
  });
  const { isPlayingTutorial } = useTutorialState();

  const closedPressed = () => {
    setIsClosed(true);
  };

  const fetchSubscriptionInfo = useCallback(async () => {
    await dispatchAsync(dispatch(getSubscriptionInfo()), setRequestState);
  }, [dispatch]);

  const getSubscription = useCallback(() => {
    fetchSubscriptionInfo();
  }, [fetchSubscriptionInfo]);

  useEffect(() => {
    getSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !userInfo ||
    requestState.loading ||
    isPlayingTutorial ||
    (hasActiveSubscription(userInfo) && Boolean(subscriptionInfo?.paymentMethod)) ||
    isClosed ||
    pathname === "/teacher/settings/membership" ||
    userInfo.role.name === "admin"
  ) {
    return null;
  }

  if (
    userInfo?.subscriptionInfo?.subscriptionStatus === "trialing" &&
    !Boolean(subscriptionInfo?.paymentMethod) &&
    userInfo.subscriptionInfo.subscriptionPeriodEnd
  ) {
    const subscriptionEnd = dayjs(userInfo.subscriptionInfo.subscriptionPeriodEnd);
    const remainingSubscriptionDays = dayjs.duration(subscriptionEnd.diff(dayjs())).asDays();
    return (
      <BannerContent
        copy={`You haven't added a payment method! You have ${Math.round(
          remainingSubscriptionDays
        )} days of access left.`}
        buttonText="Fix Now"
        allowClose={false}
        buttonLink="/teacher/settings/membership"
      />
    );
  }

  if (
    userInfo?.subscriptionInfo?.subscriptionStatus === "past_due" ||
    userInfo?.subscriptionInfo?.subscriptionStatus === "incomplete"
  ) {
    return (
      <BannerContent
        copy={`Your payment method is broken`}
        buttonText="Fix Now"
        allowClose={false}
        buttonLink="/teacher/settings/membership"
      />
    );
  }

  if (
    userInfo?.subscriptionInfo?.subscriptionPeriodEnd &&
    (userInfo?.subscriptionInfo?.subscriptionStatus === "canceled" ||
      userInfo?.subscriptionInfo?.subscriptionStatus === "managed")
  ) {
    const subscriptionEnd = dayjs(userInfo.subscriptionInfo.subscriptionPeriodEnd);
    const remainingSubscriptionDays = dayjs.duration(subscriptionEnd.diff(dayjs())).asDays();

    if (remainingSubscriptionDays < 14 && remainingSubscriptionDays > 0) {
      return (
        <BannerContent
          copy={`Your access to ${PlatformTitle} ends in ${Math.round(remainingSubscriptionDays)} days.`}
          buttonText="Subscribe Now"
          allowClose={false}
          buttonLink="/teacher/settings/membership"
          onClosePressed={closedPressed}
        />
      );
    } else if (remainingSubscriptionDays > 0) {
      // If you've cancelled but have more than 14 days left, display nothing
      return null;
    }
  }

  return <Redirect to={"/teacher/settings/membership"} />;
};

const OuterContainer = styled(Row)`
  border-top: 1px solid black;
  height: 70px;
  width: 100%;
  align-items: center;
`;

const InnerContainer = styled(Row)`
  align-items: center;
  height: 100%;
  gap: 20px;
  color: ${colors.danger};
  p {
    font-weight: 600;
  }
`;

const CloseButton = styled.div`
  margin-left: auto;
  margin-right: 100px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    border-radius: 50%;
    background-color: ${colors.primary};
  }
`;
