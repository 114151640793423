import { PlaylistAdd } from "@styled-icons/material";
import { SearchIcon } from "icons";
import { ToolTip } from "primitives";
import { Center } from "primitives";
import { LargeIcon } from "primitives/icons";
import { FC } from "react";

interface Props {
  toggleMode: () => void;
  mode: "dropdown" | "search";
}

const SwapModeButton: FC<Props> = ({ toggleMode, mode }) => (
  <ToolTip tooltip={mode === "dropdown" ? "Look for tags with text search" : "Browse tags by category"}>
    <Center style={{ width: 50, height: 50, margin: 10, cursor: "pointer" }} onClick={toggleMode}>
      {mode === "dropdown" ? <LargeIcon icon={SearchIcon} /> : <LargeIcon icon={PlaylistAdd} />}
    </Center>
  </ToolTip>
);

export { SwapModeButton };
