import { useBreadcrumbs, useHelpMenu } from "hooks";
import { Row } from "primitives";
import { useEffect } from "react";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";
import { ArticleMenu } from "./ArticleMenu";
import { HelpMenu } from "./HelpMenu";

export const NavBarPortal: FC = () => {
  const { setPortalReady: setHelpMenuPortalReady } = useHelpMenu();
  const { setPortalReady: setBreadcrumbPortalReady } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbPortalReady();
    setHelpMenuPortalReady();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Nav aria-label="Breadcrumb" className="d-print-none">
      <Container>
        <Items id="breadcrumbPortal" />
        <MenuContainer>
          <ArticleMenu id="articlePortal" />
          <HelpMenu id="helpMenuPortal" />
        </MenuContainer>
      </Container>
    </Nav>
  );
};

const Nav = styled.nav`
  width: 100%;
  border-top: 1px solid ${colors.primaryDivider};
  border-bottom: 1px solid ${colors.primaryDivider};
`;

const Items = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Row)`
  justify-content: space-between;
`;

const MenuContainer = styled(Row)`
  justify-content: space-between;
`;
