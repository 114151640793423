import { FC } from "types";
import { ToolTip } from "primitives";
import { StyledIcon } from "@styled-icons/styled-icon";
import styled, { CSSProperties } from "styled-components";
import { ButtonProps as BootstrapButtonProps } from "react-bootstrap";
import { Button } from ".";
import { Placement } from "react-bootstrap/esm/Overlay";
import { colors } from "styles";

interface ButtonIconProps extends BootstrapButtonProps {
  tooltip?: string;
  placement?: Placement;
  icon: StyledIcon;
  variant?: string;
}

const getStyleForSize = (size: string, style?: CSSProperties): CSSProperties => ({
  width: size,
  height: size,
  ...style,
});

const IconButton: FC<ButtonIconProps> = ({ tooltip, icon: Icon, placement, variant = "primary", style, ...rest }) => {
  const color: string = colors[variant];
  const hoverColor: string = colors[`${variant}Hover`];
  return tooltip ? (
    <ToolTip tooltip={tooltip} placement={placement}>
      <StyledButton variant="link" color={color} $hoverColor={hoverColor} style={style} {...rest}>
        <Icon style={{ width: "100%", height: "100%" }} />
      </StyledButton>
    </ToolTip>
  ) : (
    <StyledButton variant="link" color={color} $hoverColor={hoverColor} style={style} {...rest}>
      <Icon style={{ width: "100%", height: "100%" }} />
    </StyledButton>
  );
};

const SmallIconButton: FC<ButtonIconProps> = ({ style, ...rest }) => {
  return <IconButton style={getStyleForSize("1.5em", style)} {...rest} />;
};

const LargeIconButton: FC<ButtonIconProps> = ({ style, ...rest }) => {
  return <IconButton style={getStyleForSize("2em", style)} {...rest} />;
};

const StyledButton = styled(Button)<{ color: string; $hoverColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  padding: 0;
  color: ${(props) => props.color};
  :hover {
    color: ${(props) => props.$hoverColor};
  }
`;

export { SmallIconButton, LargeIconButton };
