import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import {
  getSubscriptionInfo as getSubscriptionInfoApi,
  deleteSubscription as deleteSubscriptionApi,
} from "api/payment";
import { SubscriptionInfo } from "types";
import { RootState } from "app/rootReducer";
import { getTokenFromState } from "./sliceHelpers";

type SubscriptionState = {
  info: SubscriptionInfo | null;
};

const initialState: SubscriptionState = {
  info: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    subscriptionLoading(state) {
      state.info = null;
    },
    subscriptionSuccess(state, action: PayloadAction<{ info: SubscriptionInfo }>) {
      state.info = action.payload.info;
    },
    subscriptionReset(state) {
      state.info = null;
    },
  },
});

const getSubscriptionInfo = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(subscriptionLoading());

    const { token } = getTokenFromState(getState);
    const info = await getSubscriptionInfoApi(token);
    dispatch(subscriptionSuccess({ info }));
  };
};

const deleteSubscription = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(subscriptionLoading());

    const { token } = getTokenFromState(getState);
    await deleteSubscriptionApi(token);
    dispatch(subscriptionReset());
  };
};

export const { subscriptionLoading, subscriptionSuccess, subscriptionReset } = subscriptionSlice.actions;

const subscriptionReducer = subscriptionSlice.reducer;
export { subscriptionReducer, getSubscriptionInfo, deleteSubscription };
