import { Button, Center, Column, Row, Spacer } from "primitives";
import { Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { FC, Price, Promo, SubscriptionInfo, Teacher } from "types";
import { SubscriptionProductCardDesktop } from "../SubscriptionProductCard/SubscriptionProductCardDesktop";

interface SubpageProps {
  userInfo: Teacher | null;
  productImages: string[];
  sortedPrices: Price[] | null;
  promo: Promo | null;
  subscriptionInfo: SubscriptionInfo | null;
  subscriptionSelected: (id: string) => void;
  isCreate: boolean;
  couponLoading: boolean;
  couponInput: string;
  couponInvalid: boolean | undefined;
  couponInputChanged: (event: React.ChangeEvent<any>) => void;
  onSubmitRevert: () => Promise<void>;
}

export const SelectSubscriptionDesktop: FC<SubpageProps> = ({
  userInfo,
  productImages,
  sortedPrices,
  promo,
  subscriptionInfo,
  subscriptionSelected,
  isCreate,
  couponLoading,
  couponInput,
  couponInvalid,
  couponInputChanged,
  onSubmitRevert,
}) => {
  return (
    <OuterContainer>
      {sortedPrices ? (
        <>
          <CardContainer>
            {sortedPrices.map((price, index) => (
              <SubscriptionProductCardDesktop
                price={price}
                key={price.id}
                popular={index === 1}
                imagePath={productImages[index]}
                canTrial={userInfo!.subscriptionInfo.trialAllowed}
                percentOff={promo?.coupon.percentOff || subscriptionInfo?.subscription.promo?.percentOff}
                isActive={subscriptionInfo ? subscriptionInfo.subscription.price.id === price.id : false}
                onSubscriptionSelected={subscriptionSelected}
              />
            ))}
          </CardContainer>
          <Spacer />
          {isCreate && (
            <CouponContainer>
              <Form as={Row}>
                <Form.Group as={Row} style={{ margin: "0px 5px 0px 0px" }}>
                  <Form.Label column sm="5" style={{ color: colors.darkText }}>
                    Coupon Code
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Enter Code"
                      disabled={Boolean(promo) || couponLoading}
                      value={couponInput}
                      onChange={couponInputChanged}
                    />
                  </Col>
                </Form.Group>
                <Button
                  variant={Boolean(promo) ? "warning" : "primary"}
                  type="submit"
                  disabled={couponLoading}
                  onClick={onSubmitRevert}
                >
                  {Boolean(promo) ? "Remove" : "Apply"}
                </Button>
              </Form>
              <Center>
                {couponInvalid && <p style={{ color: colors.danger }}>Invalid Coupon</p>}
                {Boolean(promo) && (
                  <p style={{ color: colors.success }}>Coupon Active: {promo?.coupon.percentOff}% off!</p>
                )}
              </Center>
            </CouponContainer>
          )}
        </>
      ) : (
        <p>Loading Product Information...</p>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  align-items: center;
  color: white;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 30px 0px;
`;

const CardContainer = styled(Row)`
  gap: 2em;
`;

const CouponContainer = styled(Column)`
  border: 2px solid black;
  background-color: white;
  padding: 10px;
  justify-content: center;
`;
