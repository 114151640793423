import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { ArticleActions } from "./articleSlice";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { getArticles } from "../../api";
import { ArticleQueryParams } from "../../api";

const dispatchFetchArticles = (query: ArticleQueryParams, actions: ArticleActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const data = await getArticles(false, token, query);

    dispatch(actions.addMany(data.articles));
  }, dispatch);

export { dispatchFetchArticles };
