import { Tag } from "types";

export const concatChildSubjectTags = (tagIds: string[], allTags: Tag[]) => {
  let returnTags = [...tagIds];

  for (let i = 0; i < returnTags.length; i++) {
    const rootTag = allTags.find((t) => t.id === returnTags[i]);
    if (rootTag) {
      let childTagIds = rootTag.children.map((tag) => tag.id);

      childTagIds.forEach((childTagId) => {
        if (!returnTags.includes(childTagId)) {
          const stringID = childTagId;
          returnTags.push(stringID);
        }
      });
    }
  }
  return returnTags;
};

export const getFilteredSelectedSubjectTags = (
  selectedTags: { tag: Tag; selected: boolean }[],
  includeChildTags: boolean
) => {
  let filteredTags = selectedTags.filter((tag) => {
    return tag.selected;
  });

  if (includeChildTags) {
    for (let i = 0; i < filteredTags.length; i++) {
      let childrenTagIds = filteredTags[i].tag.children.map((tag) => tag.id);
      childrenTagIds.forEach((childTagId) => {
        let childTag = selectedTags.find((t) => t.tag.id === childTagId);
        if (childTag && !filteredTags.includes(childTag)) {
          filteredTags.push(childTag);
        }
      });
    }
  }

  return filteredTags;
};

export const getFilteredSelectedSubjectTagIds = (
  selectedTags: { tag: Tag; selected: boolean }[],
  includeChildTags: boolean
) =>
  getFilteredSelectedSubjectTags(selectedTags, includeChildTags).map((selectedTag) => {
    return selectedTag.tag.id;
  });
