import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { SessionActions } from "./sessionListSlice";
import { deleteSession as deleteSessionApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";

export interface DeleteSessionParams {
  id: string;
}

const dispatchDeleteSession = (sessionParams: DeleteSessionParams, actions: SessionActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    await deleteSessionApi(token, sessionParams);

    dispatch(actions.remove(sessionParams.id));
  }, dispatch);

export { dispatchDeleteSession };
