import { Mail } from "@styled-icons/material";
import { StyledIcon } from "@styled-icons/styled-icon";
import { lowerCase } from "lodash";
import { InfoIcon, SearchIcon, StarIcon } from "icons";

const articleInfoIcon = InfoIcon;

const articleCategoryIcons: { [name: string]: StyledIcon } = {
  students: Mail,
  games: StarIcon,
};

const GetArticleCategoryIcon = (categoryName: string) => {
  return articleCategoryIcons[lowerCase(categoryName)] || SearchIcon;
};

export { GetArticleCategoryIcon, articleInfoIcon };
