import { slugs } from "textConstants";
import { PlatformTitle } from "textConstants/main";
import { Tour } from "types";
import { TourStepContainer } from "./tourComponents";

const quickGame: Tour = {
  id: "play-game",
  name: "Start a Quick Game",
  startUrl: `/${slugs.teacher}/${slugs.gamelibrary}/${slugs.class}`,
  runAutomatically: true,
  disableScrolling: true,
  steps: [
    {
      target: "#empty-tour-target",
      content: "",
      autoProgressCheck: () => document.body.contains(document.querySelector("#play-action-button")),
      isEmpty: true,
    },
    {
      target: "#play-action-button",
      content: (
        <p>
          Let's start by playing a game that's been created by {PlatformTitle}!{" "}
          <span className="text-primary">Click "Play Now"</span> to open this game in a new tab and return here when
          you're done.
        </p>
      ),
      proceedWithButton: true,
      hideBack: true,
      spotlightPadding: 2,
    },
    {
      target: ".navbar-container .class",
      title: "Tutorial Complete!",
      isEnd: true,
      content: (
        <TourStepContainer>
          <p>Now that you know how to make games, its time to set up your class.</p>
          <p>
            <span className="text-primary">Move to the Class tab</span> to continue.
          </p>
        </TourStepContainer>
      ),
      autoProgressCheck: (location) => location.pathname.includes("teacher/class"),
    },
  ],
};
export const GameLibraryTours: Tour[] = [quickGame];
