import { omit } from "lodash";
import { Question } from "types";
import { api } from "./api";

interface QuestionToBeDeleted {
  id: string;
}

interface UpdateQuestionParams {
  questionsToBeCreated: Array<Question>;
  questionsToBeUpdated: Array<Question>;
  questionsToBeDeleted: Array<QuestionToBeDeleted>;
  questionSetId: string;
}

interface UpdateQuestionReturn {
  success: boolean;
  data: any;
}

const stripExtraData = (question: Partial<Question>) => {
  return omit(question, "toBeUpdated", "toBeDeleted", "toBeCreated", "displayAsUnsaved");
};

const updateQuestions = async (
  { questionsToBeCreated, questionsToBeUpdated, questionsToBeDeleted, questionSetId }: UpdateQuestionParams,
  token: string
): Promise<UpdateQuestionReturn> => {
  const createQuestionBatch = questionsToBeCreated.map(({ order, type, ...rest }) => {
    return {
      method: "POST",
      body: {
        order,
        type,
        ...stripExtraData(rest),
      },
    };
  });

  const updateQuestionsBatch = questionsToBeUpdated.map(({ id, order, type, ...rest }) => ({
    method: "PUT",
    pathParameters: {
      questionId: id,
    },
    body: {
      order,
      type,
      ...stripExtraData(rest),
    },
  }));

  const deleteQuestionsBatch = questionsToBeDeleted.map(({ id }) => ({
    method: "DELETE",
    pathParameters: {
      questionId: id,
    },
  }));

  const response = await api.post(
    `/questionsets/${questionSetId}/questions/batch`,
    {
      requests: [...createQuestionBatch, ...updateQuestionsBatch, ...deleteQuestionsBatch],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  try {
    const arrayOfResponses: Array<{
      error: null | string;
    }> = response.data;
    const allSucceeded = arrayOfResponses.every((response) => response.error === null);
    if (allSucceeded) {
      return { success: true, data: response.data };
    } else {
      return { success: false, data: response.data };
    }
  } catch (e) {
    return { success: false, data: response.data };
  }
};

export { updateQuestions };
