import { FC } from "react";
import { QuestionSet } from "types";
import { EmptyPanelContainer } from "components";
import { QuestionSetQuestion } from "./QuestionSetQuestion";
import { Column } from "primitives";
import styled from "styled-components";

interface Props {
  questionSet: QuestionSet;
}

const QuestionSetQuestionList: FC<Props> = ({ questionSet }) => {
  const questions = questionSet.questions;

  try {
    return !questions || questions.length === 0 ? (
      <EmptyPanelContainer>This question set contains no questions.</EmptyPanelContainer>
    ) : (
      <PaddedColumn>
        {questions.map((question, index) => (
          <QuestionSetQuestion key={index} questionNumber={index + 1} question={question} />
        ))}
      </PaddedColumn>
    );
  } catch {
    // Questions in database are malformed
    return <EmptyPanelContainer>There was an issue displaying the questions in this question set.</EmptyPanelContainer>;
  }
};

const PaddedColumn = styled(Column)`
  gap: 20px;
`;

export { QuestionSetQuestionList };
