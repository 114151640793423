import { Button, CreateButton } from "primitives/button";
import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { MultichoiceQuestion, QuestionSet } from "types";
import { QuestionListItem } from "./QuestionListItem";
import { DropResult } from "react-beautiful-dnd";
import { reorderQuestion } from "./reorderQuestions";
import { MoveDirection, SingleListDropContext, EmptyPanelContainer } from "components";
import { getItemAlignment } from "utils";
import { ArrowRightAlt } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { Row, Column, VerticalCenter } from "primitives";
import { getRandomCorrectAnswers } from "../../Utils";

interface Props {
  style?: CSSProperties;
  questionSet: QuestionSet;
  activeQuestionIndex: number | null;
  newQuestionJustCreated: boolean;
  randomiseAllDistractors: () => void | null;
  setNewQuestionJustCreated: (x: boolean) => void;
  onActiveQuestionIndexChanged: (index: number | null) => void;
  onQuestionSetUpdated: (questionDetails: Partial<QuestionSet>) => void;
  onQuestionDeleted: (questionId: string, isDeleted: boolean) => void;
}

const QuestionList: FC<Props> = ({
  style,
  questionSet,
  activeQuestionIndex,
  newQuestionJustCreated,
  randomiseAllDistractors,
  setNewQuestionJustCreated,
  onActiveQuestionIndexChanged,
  onQuestionSetUpdated,
  onQuestionDeleted,
}) => {
  const [canRandomiseDistractors, setRandomiseDistractors] = useState(false);
  const questions = useMemo(() => questionSet.questions || [], [questionSet.questions]);
  const deletedQuestions = questions.filter((question) => question.toBeDeleted);
  const unDeletedQuestions = questions.filter((question) => !question.toBeDeleted);

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    scrollRef?.current?.scrollTo({ top: Number.MAX_SAFE_INTEGER });
  };

  useEffect(() => {
    if (newQuestionJustCreated) {
      scrollToBottom();
      setNewQuestionJustCreated(false);
    }
  }, [newQuestionJustCreated, setNewQuestionJustCreated]);

  useEffect(() => {
    if (questionSet) {
      let valid = false;
      const multichoiceQuestions = questions.filter(
        (question) => question.type === "multichoice"
      ) as MultichoiceQuestion[];

      for (let i = 0; i < questions.length; i++) {
        const multichoiceQuestion = questions[i] as MultichoiceQuestion;
        const answers = multichoiceQuestion.answers;
        valid = questions ? getRandomCorrectAnswers(multichoiceQuestions, answers, false).length !== 0 : false;
        if (valid) {
          break;
        }
      }
      setRandomiseDistractors(valid);
    }
  }, [questions, questionSet, setRandomiseDistractors]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination || (destination?.droppableId === source?.droppableId && destination?.index === source?.index)) {
      return;
    }

    const newQuestions = reorderQuestion([...questions], source!.index, destination!.index);

    onQuestionSetUpdated({ questions: newQuestions });
  };

  const questionMoved = (index: number, direction: MoveDirection) => {
    const newQuestions = reorderQuestion(questions, index, index + direction);

    onQuestionSetUpdated({ questions: newQuestions });
  };

  const changeActiveQuestionIndex = (index: number | null) => {
    onActiveQuestionIndexChanged(index);
  };

  return (
    <OuterContainer className="question-list">
      {unDeletedQuestions.length === 0 && deletedQuestions.length === 0 ? (
        <EmptyPanelContainer
          title="No Questions... yet!"
          message={
            <>
              Add one over there <MedIcon icon={ArrowRightAlt} />
            </>
          }
        />
      ) : (
        <>
          <HeaderContainer>
            <Column style={{ height: "100%" }}>
              <VerticalCenter>
                <HeaderText>Question List ({unDeletedQuestions.length})</HeaderText>
              </VerticalCenter>
            </Column>
            <HeaderButtonContainer>
              <Button
                variant={"link"}
                onClick={() => {
                  randomiseAllDistractors();
                }}
                disabled={!canRandomiseDistractors}
              >
                <DetailsText>Randomise All Distractors</DetailsText>
              </Button>
              <CreateButton style={{ width: "12em", height: "2.5em" }} onClick={() => changeActiveQuestionIndex(null)}>
                New Question
              </CreateButton>
            </HeaderButtonContainer>
          </HeaderContainer>
          <ScrollContainer style={{ ...style }} ref={scrollRef}>
            <SingleListDropContext onDragEnd={onDragEnd}>
              {unDeletedQuestions.map((question, index) => (
                <QuestionListItem
                  question={question}
                  activeQuestionIndex={activeQuestionIndex}
                  onQuestionPressed={changeActiveQuestionIndex}
                  key={index}
                  index={index}
                  alignment={getItemAlignment(index, unDeletedQuestions.length)}
                  onQuestionMoved={questionMoved}
                  onQuestionDeleted={onQuestionDeleted}
                />
              ))}
            </SingleListDropContext>
            {deletedQuestions.map((question, index) => (
              <QuestionListItem
                question={question}
                key={question.id}
                activeQuestionIndex={activeQuestionIndex}
                onQuestionPressed={changeActiveQuestionIndex}
                index={index + unDeletedQuestions.length}
                alignment={getItemAlignment(index, deletedQuestions.length)}
                onQuestionMoved={questionMoved}
                onQuestionDeleted={onQuestionDeleted}
              />
            ))}
          </ScrollContainer>
        </>
      )}
    </OuterContainer>
  );
};

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const HeaderContainer = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em 0em;
`;

const HeaderButtonContainer = styled(Row)`
  vertical-align: center;
`;

const OuterContainer = styled(Column)`
  flex: 1;
  overflow: hidden;
`;

const DetailsText = styled.p`
  font-size: 12px;
  text-align: left;
`;

const HeaderText = styled.p`
  font-weight: 700;
  font-size: 18px;
`;

export { QuestionList };
