import { api } from "api/api";

export const updateSubscription = async (token: string, priceId: string) => {
  await api.put(
    `/payments/subscription`,
    {
      priceId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
