import { SessionType } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

export interface FetchSessionCountParams {
  teacherId: string;
  sessionType: SessionType;
  classId?: string;
  weekBefore?: string;
}

interface SessionCountResult {
  sessionCount: number;
}

const fetchSessionCount = async (
  { teacherId, sessionType, classId, weekBefore }: FetchSessionCountParams,
  token: string
): Promise<number> => {
  const url = `/teachers/${teacherId}/sessionCount`;
  const response = await api.get(url, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      classId,
      sessionType: SessionType[sessionType],
      weekBefore,
    },
  });

  const sessionCount: SessionCountResult = response.data;

  if (sessionCount) {
    return sessionCount.sessionCount;
  } else {
    throw new NetworkError();
  }
};

export { fetchSessionCount };
