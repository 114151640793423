import { useAppDispatch, useAppSelector } from "app/hooks";
import { useClass, useGroupingId } from "hooks";
import { SimpleSelect } from "primitives";
import { useEffect } from "react";
import { fetchClassGroupings, fetchGrouping } from "slices";
import { FC } from "types";
import { RootState } from "../app/rootReducer";

interface Props {}

export const PartitionSelector: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [selectedGroupingId, setGroupingId] = useGroupingId();

  const class_ = useClass();
  const groupings = useAppSelector(
    (state: RootState) =>
      state.groupings.data &&
      Object.values(state.groupings.data).filter((grouping) => grouping.class?.id === class_?.id)
  );

  useEffect(() => {
    if (class_?.id) {
      dispatch(fetchClassGroupings(class_.id));
    }
  }, [class_?.id, dispatch]);

  useEffect(() => {
    if (!selectedGroupingId) return;
    const grouping = groupings?.find((g) => g.id === selectedGroupingId);
    if (grouping && !grouping?.partitions) {
      dispatch(fetchGrouping(selectedGroupingId));
    }
  }, [selectedGroupingId, groupings, dispatch]);

  const getName = (grouping: { id: string }) => {
    const groupingItem = groupings?.find((groupingItem) => groupingItem.id === grouping.id);
    return groupingItem
      ? `${groupingItem.name} ${groupingItem.partitionCount !== undefined ? `(${groupingItem.partitionCount})` : ""}`
      : "Select a Partition";
  };

  const onChange = (grouping?: { id: string }) => {
    if (grouping) {
      const newGroupingId = grouping.id === "" ? undefined : grouping.id;
      if (newGroupingId === selectedGroupingId) {
        return;
      }
      setGroupingId(newGroupingId);
    }
  };

  const selectOptions = [{ id: "" }, ...(groupings?.map((grouping) => ({ id: grouping.id })) || [])];

  return <SimpleSelect options={selectOptions} value={selectedGroupingId} getName={getName} onChange={onChange} />;
};
