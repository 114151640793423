import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { deleteGrouping } from "api";
import { GroupingActions } from "./groupingsSlice";

export interface DeleteGroupingParams {
  groupingId: string;
}

const dispatchDeleteGrouping = ({ groupingId }: DeleteGroupingParams, actions: GroupingActions) => async (
  dispatch: Dispatch<any>,
  getState: () => RootState
) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const grouping = await deleteGrouping({ token, groupingId });
    dispatch(actions.remove({ groupingId: grouping.id }));
    return grouping;
  }, dispatch);

export { dispatchDeleteGrouping };
