import { FC } from "types";
import { colors } from "styles";
import { ExpandLess, ExpandMore } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { StyledIcon } from "@styled-icons/styled-icon";
import styled from "styled-components";
import classNames from "classnames";

interface MoveButtonProps {
  onClick: () => void;
  isEnabled: boolean;
  className?: string;
}

interface Props extends MoveButtonProps {
  icon: StyledIcon;
}

const MoveButton: FC<Props> = ({ icon, className, onClick, isEnabled, style }) => (
  <MedIcon className={classNames(className)} icon={icon} style={style} onClick={isEnabled ? onClick : undefined} />
);

const HoverableMoveButton = styled(MoveButton)<{ isEnabled: boolean }>`
  ${({ isEnabled }) =>
    isEnabled
      ? `
    cursor: pointer;

    &:hover {
      background-color: ${colors.secondaryPale};
      border-radius: 50%;
    }
  `
      : `
    color: ${colors.inactiveButton};
  `}
`;

const MoveDownButton: FC<MoveButtonProps> = ({ ...props }) => <HoverableMoveButton icon={ExpandMore} {...props} />;

const MoveUpButton: FC<MoveButtonProps> = ({ ...props }) => <HoverableMoveButton icon={ExpandLess} {...props} />;

export { MoveDownButton, MoveUpButton };
