import { ReactComponent as PlayEdLogoSVG } from "assets/playEdLogo.svg";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";

export const PlayEdLogo: FC = () => (
  <Logo>
    <PlayEdLogoSVG style={{ width: 200 }} />
  </Logo>
);

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.darkText};
  height: 81px;
  width: 235px;
`;
