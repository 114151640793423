import { PlayArrow } from "@styled-icons/material";
import styled from "styled-components";
import { colors } from "styles";
import { CustomGame, FC, UserAction } from "types";
import { SmallIcon } from "primitives/icons";
import { ActionButton } from "primitives";
import { Names } from "textConstants";

interface Props {
  customGame: CustomGame;
  index: number;
  hasPlayed: boolean;
  canPlay: boolean;
  onPlayClicked: (customGame: CustomGame) => void;
}

const AssignmentImage: FC<Props> = ({ customGame, index, hasPlayed, canPlay, onPlayClicked }) => {
  const playAction: UserAction = {
    id: "play",
    name: "Play",
    action: async () => {
      onPlayClicked(customGame);
    },
  };

  return (
    <CustomGameImagePreview key={index} style={{ backgroundImage: `url(${customGame.gameTemplate.previewImage})` }}>
      <PlayedStateBubble hasPlayed={hasPlayed} />
      <AssignmentNumContainer>{index + 1}</AssignmentNumContainer>
      {canPlay && (
        <PlayButton action={playAction}>
          <SmallIcon icon={PlayArrow} />
        </PlayButton>
      )}
    </CustomGameImagePreview>
  );
};

interface AssignmentProps {
  hasPlayed: boolean;
}

const PlayedStateBubble: FC<AssignmentProps> = ({ hasPlayed }) => {
  return (
    <PlayedStateContainer style={{ color: hasPlayed ? colors.secondary : colors.primary }}>
      {hasPlayed ? "Played" : `New ${Names.CustomGame}`}
    </PlayedStateContainer>
  );
};

const CustomGameImagePreview = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid ${colors.innerBorder};
  border-radius: 0.5em;
  height: 9em;
  min-width: 15em;
  max-width: 15em;
`;

const AbsolutePositionContainer = styled.p`
  position: absolute;
  padding: 0.3em 0.8em;
  margin: 1em;

  background-color: white;
  border-radius: 2em;
  font-size: 12px;
  font-weight: 500;
`;

const AssignmentNumContainer = styled(AbsolutePositionContainer)`
  color: ${colors.secondary};
  bottom: 0;
`;

const PlayedStateContainer = styled(AbsolutePositionContainer)`
  right: 0;
`;

const PlayButton = styled(ActionButton)`
  position: absolute;
  margin: 0.75em;
  width: 4em;
  border-radius: 25%;
  bottom: 0;
  right: 0;
`;

export { AssignmentImage };
