import { AnswerTuple, FreetextQuestion, MultichoiceQuestion, Question } from "types";

const isQuestionValid = (questionData: Partial<Question>) => {
  if (questionData.type === "multichoice") {
    const multichoiceQuestion = questionData as MultichoiceQuestion;
    return isMultichoiceQuestionValid(
      multichoiceQuestion.question,
      multichoiceQuestion.questionImage,
      multichoiceQuestion.answers,
      multichoiceQuestion.answerImages
    );
  } else if (questionData.type === "freetext") {
    const freetextQuestion = questionData as FreetextQuestion;
    return isFreetextQuestionValid(freetextQuestion.question, freetextQuestion.questionImage, freetextQuestion.answers);
  }

  return false;
};

const isFreetextQuestionValid = (question: string, questionImage: string | null, answers: string[]) => {
  return (
    (Boolean(question) || Boolean(questionImage)) &&
    Boolean(answers.length) &&
    answers.every((answer, index) => generateErrorFeedbackForFreetextAnswer({ index, answers }) === null)
  );
};

// Checks to see if there is any (not null) feedback on any of the question and answers
const isMultichoiceQuestionValid = (
  question: string,
  questionImage: string | null,
  answers: AnswerTuple,
  answerImages: AnswerTuple
) => {
  return (
    !generateErrorFeedbackForQuestion(question, questionImage) &&
    answers
      .map((_, index) => {
        if (index === 0) {
          return generateErrorFeedbackForCorrectAnswer({
            answers,
            answerImages,
          });
        } else {
          return generateErrorFeedbackForDistractor({
            index,
            answers,
            answerImages,
          });
        }
      })
      .filter((x) => x).length === 0
  );
};

const combinedAnswerAndImage = (answer: string | null, answerImage: string | null) => {
  if (!answer && !answerImage) {
    return null;
  }
  let result = "";
  if (answer) {
    result += answer.trim();
  }
  if (answerImage) {
    result += answerImage.trim();
  }
  return result;
};

const generateErrorFeedbackForQuestion = (question: string, questionImage: string | null) => {
  return !isEmptyString(question) || questionImage ? null : "This cannot be blank.";
};

const indexIsDuplicate = (answers: Array<string | null>, index: number, checkCase: boolean) => {
  let uniqueAnswers = [...answers];
  uniqueAnswers.splice(index, 1);

  if (checkCase) {
    return uniqueAnswers.some((answer) => answer && answer.toLowerCase() === answers[index]?.toLowerCase());
  } else {
    return uniqueAnswers.some((answer) => answer && answer === answers[index]);
  }
};

const generateErrorFeedbackForCorrectAnswer = ({
  answers,
  answerImages,
}: {
  answers: AnswerTuple;
  answerImages: AnswerTuple;
}) => {
  const index = 0;
  const answerIsBlank = isEmptyString(answers[index]) && !answerImages[index];

  if (answerIsBlank) {
    return "This cannot be blank.";
  }

  const answerIsADuplicate = indexIsDuplicate(
    answers.map((answer, index) => combinedAnswerAndImage(answer, answerImages[index])),
    index,
    true
  );

  if (answerIsADuplicate) {
    return "All possible answers must be unique.";
  }

  return null;
};

const generateErrorFeedbackForFreetextAnswer = ({ index, answers }: { index: number; answers: string[] }) => {
  if (!Boolean(answers)) {
    return "Must have at least one answer";
  }

  if (!answers[index].trim()) {
    return "Answer cannot be blank";
  }

  const answerIsADuplicate = indexIsDuplicate(answers, index, true);

  if (answerIsADuplicate) {
    return "All possible answers must be unique.";
  }

  return null;
};

const generateErrorFeedbackForDistractor = ({
  index,
  answers,
  answerImages,
}: {
  index: number;
  answers: AnswerTuple;
  answerImages: AnswerTuple;
}) => {
  const ignoreIndex = 0;
  let isAnswersEmpty = true;
  answers.forEach((_, answerIndex) => {
    if (answerIndex !== ignoreIndex) {
      if (!isEmptyString(answers[answerIndex]) || answerImages[answerIndex]) {
        isAnswersEmpty = false;
      }
    }
  });

  if (isAnswersEmpty) {
    return "Must have at least one distractor.";
  }

  // We check to see if there is a blank answer
  // with a valid answer following it. This suggests
  // that there is a blank in the middle of the question,
  // which we do not want
  if (
    index < answers.length - 1 &&
    isEmptyString(answers[index]) &&
    !isEmptyString(answers[index + 1]) &&
    !answerImages[index]
  ) {
    return "Distractor cannot be blank";
  }

  const answerIsADuplicate = indexIsDuplicate(
    answers.map((answer, index) => combinedAnswerAndImage(answer, answerImages[index])),
    index,
    false
  );

  if (answerIsADuplicate) {
    return "All possible answers must be unique.";
  }

  return null;
};

const isEmptyString = (string: string | null) => {
  return !string || string.trim() === "";
};

export {
  isQuestionValid,
  generateErrorFeedbackForQuestion,
  generateErrorFeedbackForCorrectAnswer,
  generateErrorFeedbackForDistractor,
  generateErrorFeedbackForFreetextAnswer,
};
