interface Params {
  firstName: string;
  lastName: string;
}

export const getInitials = ({ firstName, lastName }: Params) =>
  `${firstName[0].toUpperCase() || ""}${lastName?.length > 0 ? lastName[0].toUpperCase() : ""}`;

export const getStudentShortName = ({ firstName, lastName }: Params) =>
  `${firstName} ${lastName?.length > 0 ? lastName[0].toUpperCase() : ""}`;
