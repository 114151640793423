import { colors } from "styles";
import { FC } from "types";

interface Props {
  width?: string;
  thickness?: string;
  color?: string;
  margin?: string;
}

const HorizontalLine: FC<Props> = ({
  width = "100%",
  thickness = "0.25px",
  color = colors.primaryDivider,
  margin = "1.5em 0em",
  ...rest
}) => (
  <div
    {...rest}
    style={{
      width: width,
      borderStyle: "solid",
      borderWidth: 0,
      borderTopWidth: thickness,
      borderColor: color,
      margin: margin,
      ...rest.style,
    }}
  />
);

export { HorizontalLine };
