import { Tag } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface FetchTagsParams {
  type: "Grade" | "Subject";
}

const fetchTags = async (params?: FetchTagsParams): Promise<Array<Tag>> => {
  const response = await api.get(`/tags`, {
    headers: {},
    params,
  });

  if (response.data) {
    const tags: Array<Tag> = response.data;
    return tags;
  } else {
    throw new NetworkError();
  }
};

export { fetchTags };
