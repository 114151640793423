import { useGradeTagsSelector } from "components/editQuestionSetModal/gradeTagsSelector";
import { useQueryParams, QuestionSetActionHook } from "hooks";
import { Spacer, Row, Column, Center } from "primitives";
import { FC, ReactNode, Fragment, useEffect, useState, useRef } from "react";
import { fetchQuestionSetListNew, resetQuestionSetPageData } from "slices";
import { defaultRequestState, OrderBy } from "types";
import { QuestionSetOrderButton } from "./QuestionSetOrderButton";
import { QuestionSetListContent } from "./QuestionSetListContent";
import { TextSearch } from "./TextSearch";
import { useAppDispatch } from "app/hooks";
import { concatChildSubjectTags } from "utils";
import { useSubjectTagsSelector } from "components";
import { useCallback } from "react";
import { Tag } from "types";
import { find } from "lodash";
import { Button } from "react-bootstrap";
import { MedIcon } from "primitives/icons";
import { colors } from "styles";
import styled from "styled-components";
import { StringMap } from "types";
import { CreateButtonSmall, FilterButton } from "primitives/button";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import pluralize from "pluralize";
import { QuestionSetListFilterModal } from "./QuestionSetListFilterModal";
import { actionConstants } from "textConstants";

interface Props {
  selectedQuestionSetIds: string[];
  focusedQuestionSetId?: string;
  onCreateQuestionSet?: () => void;
  onQuestionSetSelected: (id: string) => void;
  actionHooks?: Array<QuestionSetActionHook>;
  filterParams: SingleQuery;
}

interface QueryParams extends StringMap {
  orderBy: OrderBy;
  filterText: string;
  gradeTags: string;
  subjectTags: string;
}

export interface SingleQuery {
  archived?: boolean;
  featured?: boolean;
  isFavourite?: boolean;
  onlyMyOwn: boolean;
}

interface ResultMessage {
  title: string;
  message: ReactNode;
  condition: (params: QueryParams) => boolean;
}

const TagSeparator = "~";
const Question_Set_Count_Max = 99;

export const QuestionSetListTab: FC<Props> = ({
  selectedQuestionSetIds,
  focusedQuestionSetId,
  onCreateQuestionSet,
  onQuestionSetSelected,
  actionHooks,
  filterParams,
}) => {
  const numRows = 8;
  const numColumns = 1;
  const resultsPerPage = numRows * numColumns;
  const [requestState, setRequestState] = useState(defaultRequestState);
  const [requestStateCount, setRequestStateCount] = useState(0);
  const hasLoadedSubjectTags = useRef(false);
  const hasLoadedGradeTags = useRef(false);
  const { total } = useSelector((state: RootState) => state.questionSets.paginationData);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const defaultQueryParams: QueryParams = {
    orderBy: OrderBy.Newest,
    filterText: "",
    gradeTags: "[]",
    subjectTags: "[]",
  };

  const { params: queryParams, updateParams: updateQueryParams } = useQueryParams<QueryParams>(defaultQueryParams);

  const updateGradeTags = (gradeTags: Array<Tag>) =>
    updateQueryParams({
      gradeTags: gradeTags.map((t) => t.name).join(TagSeparator),
    });

  const updateSubjectTags = (subjectTags: Array<Tag>) => {
    updateQueryParams({
      subjectTags: subjectTags.map((t) => t.name).join(TagSeparator),
    });
  };

  const [page, setPage] = useState(1);

  const { allSubjectTags, selectedSubjectTags, setSelectedSubjectTags } = useSubjectTagsSelector({
    onChange: updateSubjectTags,
  });

  const {
    GradeTagsSelector: GradeTagsFilter,
    allGradeTags,
    gradeSelectorProps: gradeTagsFilterProps,
  } = useGradeTagsSelector({
    onChange: updateGradeTags,
  });

  const emptyResultMessages: Array<ResultMessage> = [
    {
      title: "No Question Sets found",
      message: "Try removing some tags",
      condition: ({ gradeTags, subjectTags, filterText }) =>
        filterText?.length > 0 || gradeTags?.length > 2 || subjectTags?.length > 2,
    },
    {
      title: "You have not created any Question Sets",
      message: (
        <Column>
          <Spacer size={5} />
          <Center>
            <Row>
              <p>Click </p>
              <Spacer size={3} />
              <CreateButtonSmall
                tooltip="Create Question Set"
                onClick={onCreateQuestionSet ? onCreateQuestionSet : () => {}}
              />
              <Spacer size={3} />
              <p> to create one; or</p>
            </Row>
          </Center>
          <Spacer size={5} />
          <Center>
            <Row>
              <p>Go to the</p>
              <Spacer size={3} />
              <Button size="sm" variant="outline-primary" onClick={() => updateQueryParams({ tab: "public" })}>
                Public
              </Button>
              <Spacer size={3} />
              <p>tab to see what others have made</p>
            </Row>
          </Center>
        </Column>
      ),
      condition: ({ tab }) => tab === "mySets",
    },
    {
      title: "You've not favourited any Question Sets",
      message: (
        <Fragment>
          Press the <MedIcon icon={actionConstants.favourite.icon} color={colors.warning} /> on any Question Set to
          bring it here
        </Fragment>
      ),
      condition: ({ tab }) => tab === "favourited",
    },
    {
      title: "You've not archived any Question Sets",
      message: "When you do, they'll show up here",
      condition: ({ tab }) => tab === "archived",
    },
  ];

  const { orderBy, filterText, gradeTags, subjectTags } = queryParams;

  const dispatch = useAppDispatch();

  const fetchQuestionSetList = useCallback(() => {
    let processedSubjectTagIds: string[] = [];
    let processedGradeTags: string[] = [];

    if (allSubjectTags != null) {
      processedSubjectTagIds = subjectTags
        ? subjectTags.split(TagSeparator).map((tn) => find(allSubjectTags, (t) => t.name === tn)?.id || "")
        : [];

      if (processedSubjectTagIds.length > 0) {
        processedSubjectTagIds = concatChildSubjectTags(processedSubjectTagIds, allSubjectTags);
      }
    }

    processedGradeTags = gradeTags
      ? gradeTags.split(TagSeparator).map((tn) => find(allGradeTags, (t) => t.name === tn)?.id || "")
      : [];

    return fetchQuestionSetListNew({
      orderBy,
      resultsPerPage,
      requestedPage: page,
      filterText,
      gradeTags: JSON.stringify(processedGradeTags),
      subjectTags: JSON.stringify(processedSubjectTagIds),
      ...filterParams,
    });
  }, [filterText, gradeTags, orderBy, page, resultsPerPage, subjectTags, filterParams, allGradeTags, allSubjectTags]);

  const startFetchQuestionSetList = async () => {
    try {
      setRequestState({ success: false, loading: true, error: null });
      setRequestStateCount((prevCount) => prevCount + 1);

      await dispatch(fetchQuestionSetList());

      setRequestState({ success: true, loading: false, error: null });
      setRequestStateCount((prevCount) => prevCount - 1);
    } catch (err: any) {
      const state = {
        loading: false,
        success: false,
        error: err,
      };
      if (err instanceof Error) {
        state.error = err.message;
      }
      setRequestState(state);
      setRequestStateCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    if (orderBy && filterParams && allGradeTags && allSubjectTags) {
      dispatch(resetQuestionSetPageData());
      setPage(1);
      startFetchQuestionSetList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderBy, filterText, filterParams, gradeTags, subjectTags, allGradeTags, allSubjectTags]);

  useEffect(() => {
    if (allGradeTags && allSubjectTags) {
      dispatchFetchQuestionSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, allGradeTags, allSubjectTags]);

  useEffect(() => {
    if (!hasLoadedGradeTags.current && allGradeTags && gradeTags) {
      let gradeNames = gradeTags.split(TagSeparator);
      gradeTagsFilterProps.setGradeTags(
        allGradeTags.map((t) => ({
          tag: t,
          selected: gradeNames.includes(t.name),
        }))
      );
      hasLoadedGradeTags.current = true;
    }
  }, [allGradeTags, gradeTags, gradeTagsFilterProps]);

  useEffect(() => {
    if (!hasLoadedSubjectTags.current && allSubjectTags && subjectTags) {
      let subjectNames = subjectTags.split(TagSeparator);
      setSelectedSubjectTags(
        allSubjectTags.map((t) => ({
          tag: t,
          selected: subjectNames.includes(t.name),
        }))
      );
      hasLoadedSubjectTags.current = true;
    }
  }, [allSubjectTags, subjectTags, setSelectedSubjectTags, hasLoadedSubjectTags]);

  const dispatchFetchQuestionSet = useCallback(() => {
    if (page && filterParams && allGradeTags && allSubjectTags) {
      dispatch(fetchQuestionSetList());
    }
  }, [dispatch, page, filterParams, fetchQuestionSetList, allGradeTags, allSubjectTags]);

  const onCloseFilters = () => {
    setShowFilterModal(false);
  };

  const onEditFiltersPressed = () => {
    setShowFilterModal(true);
  };

  return (
    <>
      <TextSearch
        query={filterText || ""}
        setQuery={(query) => {
          updateQueryParams({ filterText: query });
          setPage(1);
        }}
      />
      <Spacer size={5} />
      <TagAndFilterRow>
        <FilterButton
          activeFilters={
            gradeTagsFilterProps.gradeTags.filter((tag) => tag.selected).length +
            selectedSubjectTags.filter((tag) => tag.selected).length
          }
          onClick={onEditFiltersPressed}
        >
          Edit Filters
        </FilterButton>
        <QuestionSetOrderButton
          orderBy={orderBy}
          setOrderBy={(orderBy) => {
            updateQueryParams({ orderBy });
            setPage(1);
          }}
        />
        <Row>{`Showing ${total > Question_Set_Count_Max ? `${Question_Set_Count_Max}+` : total} ${pluralize(
          "Question Set",
          total
        )} `}</Row>
      </TagAndFilterRow>
      <Spacer size={5} />
      {requestState.error ? (
        <div>Something went wrong!</div>
      ) : (
        <QuestionSetListContent
          loading={requestState.loading === true || requestStateCount > 0}
          navigateToPage={(newPage) => (page !== newPage ? setPage(newPage) : dispatchFetchQuestionSet())}
          page={page}
          selectedQuestionSetIds={selectedQuestionSetIds}
          focusedQuestionSetId={focusedQuestionSetId || ""}
          onQuestionSetSelected={onQuestionSetSelected}
          actionHooks={actionHooks}
          emptyResultMessage={emptyResultMessages.find((m) => m.condition(queryParams))}
        />
      )}
      <QuestionSetListFilterModal
        visible={showFilterModal}
        onClose={onCloseFilters}
        selectedSubjectTags={selectedSubjectTags}
        setSelectedSubjectTags={setSelectedSubjectTags}
        gradeTagsFilter={GradeTagsFilter}
        gradeTagsFilterProps={gradeTagsFilterProps}
      />
    </>
  );
};

const TagAndFilterRow = styled(Row)`
  align-items: center;
  gap: 20px;
`;
