import { RootState } from "app/rootReducer";
import { Dispatch } from "react";
import { CreateSessionParams } from "./createSession";
import { duplicateSession as duplicateSessionApi } from "api";
import { dispatchAsyncFunction, getTokenFromState } from "../sliceHelpers";
import { SessionActions } from "./sessionListSlice";

const dispatchDuplicateSession = (
  sessionId: string,
  sessionParams: Partial<CreateSessionParams>,
  actions: SessionActions
) => async (dispatch: Dispatch<any>, getState: () => RootState) =>
  await dispatchAsyncFunction(async () => {
    const { token } = getTokenFromState(getState);
    const newSession = await duplicateSessionApi(
      token,
      sessionId,
      sessionParams
    );

    dispatch(actions.addSingle({ id: newSession.id, session: newSession }));
    return newSession;
  }, dispatch);

export { dispatchDuplicateSession };
