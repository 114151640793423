import { api } from "../api";
import { NetworkError } from "api/networkError";
import { QuestionSet } from "types";

const duplicateQuestionSet = async (id: string, token: string): Promise<QuestionSet> => {
  const response = await api.post(
    `/questionsets/${id}/clone`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  if (response?.data) {
    // Note that compared to fetchQuestionSet this is
    // not doing any processing of the response.
    // This could lead to issues with different types of data
    // existing for question sets
    return response.data;
  } else {
    throw new NetworkError();
  }
};

export { duplicateQuestionSet };
