import { ExpandLess, ExpandMore } from "@styled-icons/material";
import { GetCardStyleForResult } from "components/studentCard";
import { Column, Row, SecondaryText } from "primitives";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import { CustomGame, FC, QuestionSet, Result } from "types";
import { getCorrectCount, getCorrectPercentage, getTotalQuestions } from "utils";
import { MedIcon } from "primitives/icons";

interface Props {
  result: Result;
  customGame: CustomGame;
  attempts: number;
}

interface ResultColor {
  colour: string;
}

const CollapseableQuestionSets: FC<{ questionSets: QuestionSet[] }> = ({ questionSets }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <QuestionSetContainer>
      {questionSets?.length > 0 && (
        <QuestionSetsHeader>
          <li>{questionSets[0].title}</li>
          {questionSets?.length > 1 && (
            <AlignRight>
              <MedIcon
                icon={expanded ? ExpandLess : ExpandMore}
                color={colors.primary}
                onClick={() => setExpanded(!expanded)}
              />
            </AlignRight>
          )}
        </QuestionSetsHeader>
      )}
      {questionSets?.length > 1 && (
        <Collapse in={expanded}>
          <QuestionSetList>
            {questionSets.slice(1, questionSets.length).map((questionSet) => (
              <li key={questionSet.id}>{questionSet.title}</li>
            ))}
          </QuestionSetList>
        </Collapse>
      )}
    </QuestionSetContainer>
  );
};

export const ReviewStudentInfoItem: FC<Props> = ({ result, customGame, attempts }) => {
  const correctCount = getCorrectCount(result);
  const totalCount = getTotalQuestions(result);
  return (
    <OuterContainer>
      <SecondaryText>
        <b>{customGame.gameTemplate.name}</b>
      </SecondaryText>
      <CollapseableQuestionSets questionSets={customGame.questionSets} />
      <SecondaryText>Attempts: {attempts}</SecondaryText>
      <InnerContainer>
        <SecondaryText>
          Questions: {correctCount}/{totalCount}
        </SecondaryText>
        <ResultIndicator
          colour={
            GetCardStyleForResult(getCorrectPercentage(correctCount, totalCount), totalCount, true).inactivePrimaryColor
          }
        />
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(Column)`
  gap: 5px;
  p {
    b {
      color: ${colors.darkText};
      font-weight: 600;
    }
  }
`;

const InnerContainer = styled(Row)`
  gap: 10px;
  align-items: center;
`;

const QuestionSetContainer = styled.div`
  li {
    color: ${colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const QuestionSetsHeader = styled(Row)`
  background-color: ${colors.light};
  padding: 0.3em 0.1em 0.3em 1em;
`;

const QuestionSetList = styled.ul`
  background-color: ${colors.light};
  list-style-position: inside;
  padding: 0.3em 0.1em 0.3em 1em;
  margin-bottom: 0;
`;

const AlignRight = styled.div`
  margin-left: auto;
`;

const ResultIndicator = styled.div<ResultColor>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.colour};
`;

export { ResultIndicator, OuterContainer as ReviewItemContainer };
