import styled from "styled-components";
import { Column, Row } from "primitives";
import AcademyBanner from "assets/Academy_Banner.png";
import { Mascot2 } from "components/Mascot";
import { MediaConstants } from "utils/mediaConstants";
import { colors } from "styles";
import { MedIcon } from "primitives/icons";
import { articleInfoIcon, PlatformTitle } from "textConstants";

export const ArticleListHeader = () => {
  return (
    <Header>
      <LeftHeaderContainer>
        <HeaderTitleText>Become an expert at the {PlatformTitle} Academy</HeaderTitleText>
        <HeaderSubtitleText>
          Access our library of tutorials and guides to become a {PlatformTitle} pro.
        </HeaderSubtitleText>
        <HeaderSubtitleText>
          Access specific tutorials around {PlatformTitle} by pressing the <MedIcon icon={articleInfoIcon} /> button
        </HeaderSubtitleText>
      </LeftHeaderContainer>
      <RightHeaderContainer>
        <Mascot2 />
      </RightHeaderContainer>
    </Header>
  );
};

const Header = styled(Row)`
  min-height: 300px;
  max-height: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url(${AcademyBanner});
  background-position: left;
  background-size: cover;
  padding: 0 20px;
`;

const LeftHeaderContainer = styled(Column)`
  height: 100%;
  max-width: 460px;
  @media ${MediaConstants.tablet} {
    min-width: 300px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightHeaderContainer = styled.div`
  height: 100%;
  width: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderTitleText = styled.p`
  color: ${colors.white};
  font-size: 30px;
  font-weight: 600;
  line-height: 41.98px;
`;

const HeaderSubtitleText = styled.p`
  color: ${colors.white};
`;
