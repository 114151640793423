import styled from "styled-components";
import { colors } from "styles";

const NoOverflowText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;

const TruncatedText = styled(NoOverflowText)`
  max-width: 30ch;
`;

const HeaderText = styled(NoOverflowText)`
  color: ${(props) => (props.color ? props.color : colors.darkText)};
  font-weight: 600;
  margin: 0;
  font-size: 26px;
  padding: 0.5em;
`;

const TitleText = styled(NoOverflowText)`
  color: ${(props) => (props.color ? props.color : colors.darkText)};
  font-weight: 600;
  margin: 0;
  font-size: 20px;
`;

const SecondaryText = styled(NoOverflowText)`
  color: ${(props) => (props.color ? props.color : colors.secondary)};
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;

export { NoOverflowText, TruncatedText, HeaderText, TitleText, SecondaryText };
