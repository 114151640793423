import { ProcessQuestionSet } from "../deserialisation";
import { QuestionSet, ReviewSubmission } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

interface QuestionSetEditParams {
  title: string;
  description: string;
  estimatedLength: number;
  archive?: boolean;
  tags?: Array<string>;
}

interface QuestionSetReviewSubmissionParams {
  questionSetId: string,
  comment: string
}

const createQuestionSet = async (params: QuestionSetEditParams, token: string): Promise<QuestionSet> => {
  const response = await api.post("/questionsets", params, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response?.data?.createdAt) {
    return ProcessQuestionSet(response.data);
  } else {
    throw new NetworkError();
  }
};

const createReviewSubmission = async (params: QuestionSetReviewSubmissionParams, token: string): Promise<ReviewSubmission> => {
  const response = await api.post("/reviewsubmissions", params, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response?.data?.createdAt) {
    return response.data; 
  } else {
    throw new NetworkError();
  }
};

const updateQuestionSet = async (
  id: string,
  params: Partial<QuestionSetEditParams>,
  token: string
): Promise<Partial<QuestionSet>> => {
  const response = await api.put(`/questionsets/${id}`, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response?.data?.id) {
    return ProcessQuestionSet(response.data);
  } else {
    throw new NetworkError();
  }
};

export { createQuestionSet, createReviewSubmission, updateQuestionSet };
