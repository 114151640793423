import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { ApiError } from "types";
import { logout } from "../teacherLoginSlice";
import { tryApiCall } from "./tryApiCall";

export type AsyncSliceState<DataType> = {
  loading: boolean;
  success: boolean;
  error: ApiError | null;
  data: DataType | null;
};

interface CreateAsyncSliceArgs<DataType, AdditionalReducersType> {
  name: string;
  additionalReducers?: AdditionalReducersType;
  extraReducers?: (builder: ActionReducerMapBuilder<AsyncSliceState<DataType>>) => void;
}

const createAsyncSlice = <DataType, AdditionalReducersType = {}>({
  name,
  additionalReducers,
  extraReducers,
}: CreateAsyncSliceArgs<DataType, AdditionalReducersType>) => {
  const initialState: AsyncSliceState<DataType> = {
    loading: false,
    error: null,
    success: false,
    data: null,
  };

  const defaultReducers = {
    loading(state: AsyncSliceState<DataType>) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    success: (state: AsyncSliceState<DataType>, action: PayloadAction<DataType>) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      // @ts-ignore
      state.data = action.payload;
    },
    error: (state: AsyncSliceState<DataType>, action: PayloadAction<ApiError>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.data = null;
    },
    reset(state: AsyncSliceState<DataType>) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.data = null;
    },
  };

  const slice = createSlice<
    AsyncSliceState<DataType>,
    // @ts-ignore
    AdditionalReducersType & typeof defaultReducers
  >({
    name,
    initialState,
    reducers: {
      ...defaultReducers,
      ...additionalReducers,
    },
    extraReducers,
  });

  const loadingReducer = slice.actions.loading;
  const errorReducer = slice.actions.error;

  const dispatchAsyncFunction = async <T>(
    businessLogic: () => Promise<T>,
    dispatch: Dispatch<any>,
    onError?: (error: ApiError) => void
  ) => {
    // @ts-ignore
    dispatch(loadingReducer());
    return await tryApiCall<T>({
      onTry: businessLogic,
      onError: (error) => {
        // @ts-ignore
        dispatch(errorReducer(error));
        if (onError) {
          onError(error);
        }
      },
      onNotLoggedIn: () => dispatch(logout()),
    });
  };

  return { slice, dispatchAsyncFunction };
};

export { createAsyncSlice };
