import { PrimaryButton } from "primitives/button";
import { RootState } from "app/rootReducer";
import { MedIcon } from "primitives/icons";
import { actionConstants, breadcrumbConstants, Names } from "textConstants";
import { FC } from "types";
import { ListTabProps, ListTabRow } from "components";

interface Props {
  onCreateQuestionSet?: () => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const tabData: ListTabProps[] = [
  {
    key: "mySets",
    text: "My Question Sets",
    icon: breadcrumbConstants.questions.icon,
  },
  {
    key: "public",
    text: "Public",
    icon: actionConstants.feature.icon,
  },
  {
    key: "favourited",
    text: "Favourites",
    icon: actionConstants.unFavourite.icon, //this one has the full star
  },
  {
    key: "archived",
    text: "Archive",
    textColor: actionConstants.archive.textColor,
    icon: actionConstants.archive.icon,
    iconColour: actionConstants.archive.iconColor,
  },
];

const QuestionSetTabRow: FC<Props> = ({ onCreateQuestionSet, selectedTab, setSelectedTab }) => {
  return (
    <ListTabRow
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabData}
      totalsSelector={(state: RootState) => state.questionSets?.totals}
    >
      {onCreateQuestionSet && (
        <PrimaryButton className="mb-2 create-question-set-button" variant="primary" onClick={onCreateQuestionSet}>
          <MedIcon icon={actionConstants.add.icon} />
          Create {Names.QuestionSet}
        </PrimaryButton>
      )}
    </ListTabRow>
  );
};

export { QuestionSetTabRow };
