import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompletionRequirement } from "api";
import { merge } from "lodash";
import { addSingleSession } from "slices/sessionSlice/sessionListSlice";
import { ApiError, PaginatedSessionReport, Session, SessionType, Student, StudentName } from "types";
import { dispatchDeleteStudent } from "./deleteStudent";
import { dispatchFetchSessionsForStudentReport } from "./fetchSessionsForStudentReport";
import { dispatchFetchStudent } from "./fetchStudent";
import { dispatchRegenerateStudentPassword } from "./regenerateStudentPassword";
import { dispatchUpdateStudent } from "./updateStudent";

export interface StudentSessionQuery {
  sessionType?: SessionType;
  completionRequirement?: CompletionRequirement;
  orderby: "dec" | "asc";
  order: string;
}

type StudentState = {
  student: Student | null;
  sessions: { [id: string]: Session };
  paginationData: {
    orderedSessionIds: { [key: string]: string[] };
    totals: { [key: string]: number };
    summaries: {
      [key: string]: {
        correctAnswers: number;
        totalAnswers: number;
      };
    };
  };
  loading: boolean;
  error: ApiError | null;
  lastRequestToken: string;
};

const initialState: StudentState = {
  student: null,
  sessions: {},
  paginationData: {
    orderedSessionIds: {},
    totals: {},
    summaries: {},
  },
  loading: false,
  error: null,
  lastRequestToken: "",
};

// --- Slice Definition

const reducers = {
  loading(state: StudentState) {
    state.loading = true;
  },
  setRequestToken(state: StudentState, action: PayloadAction<string>) {
    state.lastRequestToken = action.payload;
  },
  reset(state: StudentState) {
    state.student = null;
    state.sessions = {};
    state.paginationData = {
      orderedSessionIds: {},
      totals: {},
      summaries: {},
    };
    state.loading = false;
    state.error = null;
    state.lastRequestToken = "";
  },
  resetSessionsPage(state: StudentState) {
    state.paginationData = {
      ...state.paginationData,
      orderedSessionIds: {},
      summaries: {},
    };
  },
  resetSessionsTotal(state: StudentState) {
    state.paginationData = {
      ...state.paginationData,
      totals: {},
    };
  },
  deleteStudent(state: StudentState, action: PayloadAction<Student>) {
    if (action.payload.id === state.student?.id) {
      state.student = null;
      state.sessions = {};
    }
    state.loading = false;
  },
  mergeStudent(state: StudentState, action: PayloadAction<Partial<Student>>) {
    state.student = merge(state.student, action.payload);
    state.loading = false;
  },
  mergeSessions(
    state: StudentState,
    action: PayloadAction<{ query: string; report: PaginatedSessionReport; requestToken: string }>
  ) {
    const {
      query,
      report: { sessions, total, summary },
    } = action.payload;
    if (state.lastRequestToken === action.payload.requestToken) {
      state.paginationData.totals[query] = total;
      if (summary) {
        state.paginationData.summaries[query] = summary;
      }

      if (!state.paginationData.orderedSessionIds[query]) {
        state.paginationData.orderedSessionIds[query] = [];
      }

      if (sessions) {
        for (let session of sessions) {
          state.sessions[session.id] = session;

          if (!state.paginationData.orderedSessionIds[query].includes(session.id)) {
            state.paginationData.orderedSessionIds[query].push(session.id);
          }
        }
      }
    } else {
      console.warn(
        "[StudentSlice:mergeSessions] was fired after the request token was changed. If you didn't expect this, be sure to investigate the issue."
      );
    }
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<StudentState>) => {
  builder.addCase(addSingleSession, (state, action) => {
    if (state.sessions && state.sessions[action.payload.id]) {
      state.sessions[action.payload.id] = merge(state.sessions[action.payload.id], action.payload.session);
    }
  });
};

const slice = createSlice<StudentState, typeof reducers>({
  name: "student",
  initialState,
  reducers,
  extraReducers,
});

// --- Async Action Wrappers
const fetchStudent = (studentId: string) => dispatchFetchStudent(studentId, slice.actions);
const updateStudent = (studentId: string, names: StudentName) => dispatchUpdateStudent(studentId, names, slice.actions);
const deleteStudent = (studentId: string) => dispatchDeleteStudent(studentId, slice.actions);
const regenerateStudentPassword = (studentId: string) => dispatchRegenerateStudentPassword(studentId, slice.actions);
const fetchSessionsForStudentReport = (
  studentId: string,
  queries: { [key: string]: StudentSessionQuery },
  currentQuery: string,
  pageSize: number,
  currentPage: number = 0,
  requestOtherTotals: boolean = false
) =>
  dispatchFetchSessionsForStudentReport(
    {
      studentId,
      queries,
      currentQuery,
      pageSize,
      currentPage,
      requestOtherTotals,
    },
    slice.actions
  );

// --- Exports
export type StudentActions = typeof slice.actions;

const studentReducer = slice.reducer;
export const { deleteStudent: studentDelete, mergeStudent: studentSuccess } = slice.actions;
export {
  studentReducer,
  fetchStudent,
  updateStudent,
  deleteStudent,
  regenerateStudentPassword,
  fetchSessionsForStudentReport,
};
