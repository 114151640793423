import { merge } from "lodash";
import { useState } from "react";
import { RecursivePartial } from "types";

// Like React.useState except the function returned as the second element of tuple will accept any object which has a deep sub-set of the state's properties,
// leaving any unspecified keys/sub-keys on the state object with the same values as before
const useStateWithUpdate = <T>(
  initialState: T
): [state: T, updateState: (update: RecursivePartial<T>) => void] => {
  const [state, setState] = useState(initialState);
  const updateState = (update: RecursivePartial<T>) =>
    setState(merge({}, state, update));

  return [state, updateState];
};

export { useStateWithUpdate };
