import { FC } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { VerticalLine, HorizontalLine, Row, DatePickerWithButton } from "primitives";
import { Form } from "react-bootstrap";
import { isArray } from "lodash";
import dayjs from "dayjs";
import { DateRange } from "types";
import { colors } from "styles";
import { helpMessages } from "textConstants";

interface Props {
  disabled?: boolean | [boolean, boolean, boolean];
  dateRange: Partial<DateRange>;
  label?: string;
  onChange?: (range: Partial<DateRange>, hasTime: boolean) => void;
  error?: string;
  hasTime: boolean;
  nullLabels?: { start?: string; end?: string };
}

export const DateTimeRangeInput: FC<Props> = ({ nullLabels, hasTime, disabled, label, dateRange, onChange, error }) => {
  const isCheckboxDisabled = isArray(disabled) ? disabled[0] : Boolean(disabled);
  const isStartDisabled = isArray(disabled) ? disabled[1] : Boolean(disabled);
  const isEndDisabled = isArray(disabled) ? disabled[2] : Boolean(disabled);

  const onStartDateChanged = (date: Date) => {
    if (onChange) {
      onChange(
        {
          startDate: date,
          endDate: dateRange.endDate,
        },
        hasTime
      );
    }
  };

  const onEndDateChanged = (date: Date) => {
    if (onChange) {
      onChange(
        {
          startDate: dateRange.startDate,
          endDate: date,
        },
        hasTime
      );
    }
  };

  const setHasTime = (newValue: boolean) => {
    if (onChange) {
      onChange(dateRange, newValue);
    }
  };

  const setStartTimeToNow = () => {
    onStartDateChanged(new Date());
  };

  return (
    <WhenContainer>
      <Form.Check
        type="checkbox"
        label={label || "Time"}
        checked={hasTime}
        onChange={() => setHasTime(!hasTime)}
        className="mt-1"
        disabled={isCheckboxDisabled}
      />
      <VerticalLine />
      {hasTime ? (
        <>
          <p className="mt-1">Start</p>
          <div>
            {dateRange.startDate ? (
              <>
                <DateInputWithButton
                  disabled={isStartDisabled}
                  showTimeSelect
                  selectsStart
                  selected={dateRange.startDate}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onChange={onStartDateChanged}
                  onClick={setStartTimeToNow}
                  buttonLabel={"Set Start time to now"}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <div className="small ml-1">({dayjs(dateRange.startDate).fromNow()})</div>
              </>
            ) : (
              <input disabled={true} value={nullLabels?.start || "None"} />
            )}
          </div>
          <HorizontalLine className="mt-3" thickness="1px" style={{ width: "20px" }} />
          <p className="mt-1">End</p>
          <div>
            <DateInput
              disabled={isEndDisabled}
              showTimeSelect
              selectsEnd
              onChange={onEndDateChanged}
              selected={dateRange.endDate}
              minDate={dateRange.startDate}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
            <div className="small ml-1">({dayjs(dateRange.endDate).fromNow()})</div>
          </div>
          {error && <ErrorText>{error}</ErrorText>}
        </>
      ) : (
        <div>{helpMessages.lessons.time.disabledLabel}</div>
      )}
    </WhenContainer>
  );
};

const WhenContainer = styled(Row)`
  align-items: flex-start;
  justify-items: center;
  gap: var(--sp-3);
  margin: var(--sp-3) 0px;
`;

const DateInput = styled(DatePicker)`
  min-width: 230px;
`;

const DateInputWithButton = styled(DatePickerWithButton)`
  min-width: 230px;
`;

const ErrorText = styled.p`
  color: ${colors.danger};
`;
