import { QuestionImage } from "types";
import { api } from "../api";
import { NetworkError } from "../networkError";

const fetchQuestionImages = async (token: string, searchQuery?: string): Promise<Array<QuestionImage>> => {
  const response = await api.get(`/questionimages`, {
    params: searchQuery ? { searchQuery } : null,
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.data) {
    const questionImages: Array<QuestionImage> = response.data;
    return questionImages;
  } else {
    throw new NetworkError();
  }
};

export { fetchQuestionImages };
