import { api } from "../api";
import { CustomGame } from "types";
import { NetworkError } from "../networkError";
import { ProcessCustomGame } from "../deserialisation";

interface SetAssignmentParams {
  token: string;
  customGameId: string;
  studentIds: string[];
}

export const setCustomGameAssignments = async ({
  token,
  customGameId,
  studentIds,
}: SetAssignmentParams): Promise<CustomGame> => {
  const response = await api.post(
    `/customgames/${customGameId}/assignments`,
    {
      studentIds,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const customGame = ProcessCustomGame(response.data);

  if (customGame) {
    return customGame;
  } else {
    throw new NetworkError();
  }
};
