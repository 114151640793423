import { useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import { StudentCardProps } from "components/studentCard";
import { StudentInfo } from "pages/classes/classesOverview/StudentInfo";
import { Center, Column, PrimaryButton, Row, Spacer } from "primitives";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles";
import { FC } from "types";
import { StudentRecordSessions } from "./StudentRecordSessions";

interface ModalProps {
  onModalHidden: () => void;
}

interface Props {
  StudentCard: FC<StudentCardProps>;
  selectedSessionIds?: string[];
  onDeleteStudent?: (studentId: string) => void;
  cardData?: any;
}

const StudentRecordData: FC<Props> = ({ StudentCard, selectedSessionIds, onDeleteStudent, cardData }) => {
  const { classId, studentId } = useParams<{ classId: string; studentId: string }>();
  // We fetch the student from the class list as that contains the aggregate values
  const student = useAppSelector(
    (state: RootState) =>
      state.classList.data && state.classList.data[classId].students.find((student) => student.id === studentId)
  );

  return (
    <OuterContainer>
      <LeftColumn>
        {student && <StudentCard student={student} disabled={true} data={cardData} />}
        <Spacer size={11} />
        <StudentInfo onDeleteStudent={onDeleteStudent} />
      </LeftColumn>
      <RightColumn>
        <StudentRecordSessions
          studentId={studentId}
          selectedSessionIds={selectedSessionIds}
          upcomingSessionsCount={student?.upcomingSessions || 0}
          completedSessionsCount={student?.completedSessions || 0}
        />
      </RightColumn>
    </OuterContainer>
  );
};

export const StudentRecordModal: FC<Props & ModalProps> = ({ onModalHidden, ...props }) => {
  const [isClosing, setIsClosing] = useState(false);

  const hide = () => {
    setIsClosing(true);
  };

  return (
    <Modal dialogClassName="ultra-wide-modal" show={!isClosing} onHide={hide} onExited={onModalHidden} centered>
      <Modal.Body className="student-record" style={{ padding: "0px" }}>
        <StudentRecordData {...props} />
      </Modal.Body>
      <Modal.Footer>
        <Center>
          <PrimaryButton variant="secondary" onClick={hide}>
            Close
          </PrimaryButton>
        </Center>
      </Modal.Footer>
    </Modal>
  );
};

const OuterContainer = styled(Row)`
  min-height: 600px;
  height: 600px;
`;

const LeftColumn = styled(Column)`
  background-color: ${colors.light};
  padding: var(--sp-3);
  width: var(--sidebar-width-xs);
`;

const RightColumn = styled(Column)`
  height: 100%;
  flex: 1;
  padding: var(--sp-3);
`;
