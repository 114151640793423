import styled from "styled-components";
import { PrimaryButton } from "..";
import { colors } from "../../styles";
import { FC } from "../../types";

interface Props {
  activeFilters?: number;
}

export const FilterButton: FC<Props> = ({ activeFilters, children, onClick }) => {
  return (
    <OuterContainer>
      <PrimaryButton height="100%" fontSize="14px" $minWidth="130px" onClick={onClick}>
        {children}
      </PrimaryButton>
      {activeFilters && activeFilters > 0 ? <FilterCount>{activeFilters}</FilterCount> : null}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
`;

const FilterCount = styled.div`
  background-color: ${colors.primary};
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
