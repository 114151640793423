import { useAppDispatch, useAppSelector } from "app/hooks";
import { InfiniteScrollList, ListTabProps, ListTabRow } from "components";
import { Spinner } from "primitives";
import { useCallback, useEffect, useState } from "react";
import { fetchSessionsForStudentReport, StudentSessionQuery } from "slices";
import styled from "styled-components";
import { colors } from "styles";
import { Names } from "textConstants";
import { SessionType } from "types";
import { StudentSessionListItem } from "./StudentSessionListItem";

const pageSize = 10;

let tabs: ListTabProps[] = [
  {
    key: "todo",
    text: `To do`,
  },
  {
    key: "completed",
    text: `Completed`,
  },
];

const sessionQueries: {
  [tabKey: string]: StudentSessionQuery;
} = {
  todo: {
    sessionType: SessionType.Current,
    completionRequirement: "SomeIncomplete",
    orderby: "dec",
    order: "endTime",
  },
  completed: {
    sessionType: SessionType.Current,
    completionRequirement: "AllComplete",
    orderby: "dec",
    order: "endTime",
  },
};

const StudentSessionList = () => {
  const { sessions, paginationData } = useAppSelector((state) => state.student);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState("todo");
  const student = useAppSelector((state) => state.studentLogin.student);

  let paginatedSessions = (paginationData.orderedSessionIds[selectedTab] || []).map((sessionId) => sessions[sessionId]);

  const loadSessions = (tab: string, loadCounts: boolean = false) => {
    if (student?.id) {
      const query = sessionQueries[tab];
      if (query) {
        dispatch(fetchSessionsForStudentReport(student.id, sessionQueries, tab, pageSize, currentPage, loadCounts));
      }
    }
  };

  const dispatchFetchSessions = useCallback(() => {
    if (currentPage && sessionQueries[selectedTab] && student?.id) {
      dispatch(fetchSessionsForStudentReport(student.id, sessionQueries, selectedTab, pageSize, currentPage));
    }
  }, [dispatch, currentPage, selectedTab, student?.id]);

  useEffect(() => {
    loadSessions(selectedTab, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      dispatchFetchSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const tabSelected = (tab: string) => {
    setCurrentPage(0);
    setSelectedTab(tab);
  };

  const fetchMoreSessions = () => {
    setCurrentPage(currentPage + 1);
  };

  const getTabTotals = () => paginationData.totals;

  return (
    <OuterContainer>
      <ListTabRow
        className="mx-4"
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={tabSelected}
        totalsSelector={getTabTotals}
      />
      {paginationData.totals[selectedTab] === undefined ? (
        <Spinner />
      ) : paginationData.totals[selectedTab] === 0 ? (
        <div className="text-center mt-5">
          You have no {selectedTab === "completed" ? "completed" : "active"} {Names.Sessions}.
        </div>
      ) : (
        <SessionListContainer
          className="px-4"
          hasData={Boolean(paginationData.totals[selectedTab])}
          currentDataLength={paginatedSessions.length}
          fetchMoreData={fetchMoreSessions}
          hasMoreToFetch={paginatedSessions.length < paginationData.totals[selectedTab]}
        >
          {paginatedSessions.map((session) => (
            <StudentSessionListItem key={session.id} session={session} />
          ))}
        </SessionListContainer>
      )}
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: var(--sp-4);
  background-color: ${colors.white};
  width: 100%;
`;

const SessionListContainer = styled(InfiniteScrollList)`
  margin-top: var(--sp-3);
  overflow-y: auto;
`;

export { StudentSessionList };
