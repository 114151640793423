import { CalendarContainer, ReactDatePickerProps } from "react-datepicker";
import DatePicker from "react-datepicker";
import { Row } from "primitives";
import { Button } from "primitives/button";
import styled from "styled-components";

interface DatePickerWithButtonProps extends ReactDatePickerProps {
  onClick?: () => void;
  buttonLabel?: string;
}

const DatePickerWithButton = (props: DatePickerWithButtonProps) => {
  interface DateContainerProps {
    className?: string;
    children?: React.ReactNode;
    showPopperArrow?: boolean;
    arrowProps?: { [propName: string]: any };
  }

  const DatePickerContainer = (containerProps: DateContainerProps) => {
    return (
      <CalendarContainer className={containerProps.className}>
        <CalendarRow>
          <CalendarButton onClick={props.onClick}>{props.buttonLabel}</CalendarButton>
        </CalendarRow>
        <div style={{ position: "relative" }}>{containerProps.children}</div>
      </CalendarContainer>
    );
  };

  return <DatePicker {...props} calendarContainer={DatePickerContainer} />;
};

const CalendarButton = styled(Button)`
  font-family: LEMON MILK Pro, Arial, sans-serif;
  font-size: 14px;
  margin: 15px;
  position: relative;
  width: 100%;
`;

const CalendarRow = styled(Row)`
  width: 90%;
  margin: 0 auto;
`;

export { CalendarButton, CalendarRow, DatePickerWithButton };
