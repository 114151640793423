import { Article, DifficultyLevel } from "../../types";
import { api } from "../api";
import { NetworkError } from "../networkError";

export interface ArticleQueryParams {
  id?: string;
  difficultyLevel?: DifficultyLevel;
  routeAddress?: string;
  categoryID?: string;
  take?: number;
  skip?: number;
  order?: string;
  orderBy?: string;
}

export interface ArticleQueryReturn {
  articles: Partial<Article>[];
  articleCount: number;
}

const getArticles = async (
  preview: boolean,
  token: string,
  query?: ArticleQueryParams
): Promise<ArticleQueryReturn> => {
  const response = await api.get(preview ? `/articles/preview` : `/articles`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      id: query?.id,
      difficultyLevel: query?.categoryID,
      routeAddress: query?.routeAddress,
      categoryID: query?.categoryID,
      take: query?.take,
      skip: query?.skip,
      order: query?.order,
      orderBy: query?.orderBy,
    },
  });

  if (response.data) {
    return response.data;
  } else {
    throw new NetworkError();
  }
};

export { getArticles };
