import { EmptyPanelContainer, InfiniteScrollList } from "components";
import { ReactNode } from "react";
import { FC, Session, SessionType } from "types";
import { LessonPlanListItem } from "./LessonPlanListItem";

interface Props {
  emptyMessage: ReactNode;
  hasMoreToFetch?: boolean;
  sessionLoading?: boolean;
  sessions: Session[];
  sessionType: SessionType;
  classId: string;
  switchToTab: (tab: string) => void;
  updateLessonCount: () => void;
}

interface InfiniteProps extends Props {
  totalPerPage: number;
  total: number;
  fetchMore: () => void;
}

const ListElement: FC<Props> = ({
  emptyMessage,
  hasMoreToFetch,
  sessionLoading,
  sessions,
  sessionType,
  switchToTab,
  updateLessonCount,
}) => {
  const onStarted = async () => {
    switchToTab("current");
  };

  const onEnded = async () => {
    updateLessonCount();
    switchToTab("past");
  };

  const onDeleted = async () => {
    updateLessonCount();
  };

  return (
    <>
      {sessions?.length > 0
        ? sessions.map((session, index) => {
            return (
              <LessonPlanListItem
                key={index}
                session={session}
                sessionType={sessionType}
                onLessonDeleted={onDeleted}
                onLessonStarted={onStarted}
                onLessonEnded={onEnded}
              />
            );
          })
        : !hasMoreToFetch && !sessionLoading && <EmptyPanelContainer>{emptyMessage}</EmptyPanelContainer>}
    </>
  );
};

export const InfiniteLessonPlanList: FC<InfiniteProps> = ({
  sessions,
  sessionType,
  totalPerPage,
  sessionLoading,
  fetchMore,
  emptyMessage,
  classId,
  switchToTab,
  updateLessonCount,
  total,
}) => {
  const moreToFetch = sessions ? sessions.length < total : false;
  return (
    <InfiniteScrollList
      currentDataLength={sessions?.length || 0}
      fetchMoreData={fetchMore}
      hasMoreToFetch={moreToFetch}
      style={{
        flex: 1,
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      infiniteScrollStyle={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "hidden",
      }}
    >
      <ListElement
        emptyMessage={emptyMessage}
        hasMoreToFetch={moreToFetch}
        sessions={sessions}
        sessionType={sessionType}
        classId={classId}
        switchToTab={switchToTab}
        updateLessonCount={updateLessonCount}
        sessionLoading={sessionLoading}
      />
    </InfiniteScrollList>
  );
};
