import { Button } from "primitives/button";
import { Row, Spacer } from "primitives";
import { FC, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles";
import ReactGA from "react-ga4";
import { Link } from "@styled-icons/material";
import { MedIcon } from "primitives/icons";
import { useParams, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { RootState } from "app/rootReducer";
import { QuestionSet } from "types";
import { paths } from "textConstants";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const QuestionSetShareLinkModal: FC<Props> = ({ visible, onClose }) => {
  const { questionSetId } = useParams<{ questionSetId: string }>();
  const questionSet = useAppSelector(
    (state: RootState) => state?.questionSets?.data && state?.questionSets?.data[questionSetId]
  );
  const link = getShareLink(questionSetId, questionSet);
  const { url } = useRouteMatch();

  const linkTextRef = useRef<HTMLInputElement>(null);

  const copyTextToClipboard = () => {
    const linkTextHTMLElement = linkTextRef.current;
    linkTextHTMLElement?.select();
    document.execCommand("copy");
    linkTextHTMLElement?.blur();
    onClose();
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: url });
  }, [visible, questionSetId, url]);

  return (
    <Modal show={visible} onHide={onClose} dialogClassName="question-set-link-share-modal" centered>
      <Modal.Header closeButton>
        <Row style={{ alignItems: "center" }}>
          <MedIcon icon={Link} color={colors.secondary} />
          <Spacer size={5} />
          <Modal.Title>Share Link</Modal.Title>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <LinkText value={link} ref={linkTextRef} readOnly className="clip-text" />
          <Spacer size={5} />
          <Button variant="primary" onClick={copyTextToClipboard}>
            Copy Link
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const getShareLink = (questionSetId: string, questionSet: QuestionSet | null) => {
  const { protocol, host } = window.location;
  if (questionSet && questionSet.isFeatured) {
    return `${protocol}//${host}${paths.public.questionSets.preview.generate(questionSet.id)}`;
  }
  return `${protocol}//${host}${paths.teacher.questions.questionSets.preview.generate(questionSetId)}`;
};
const LinkText = styled.input`
  background-color: ${colors.copyLink};
  color: ${colors.copyLinkText};
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  flex: 1;
  border: none;
`;

export { QuestionSetShareLinkModal };
