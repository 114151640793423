import { QuestionSet } from "types";

export enum ReviewState {
  Pending = "pending", // In the hands of the reviewer
  Updated = "updated", // In the current state it has been rejected, In the hands of the reviewee to resubmit
  Resolved = "resolved", // Reviewed and approved
  Rejected = "rejected", // [Unused for now] Do not allow for resubmission
}

export type ReviewSubmission = {
  createdAt: Date;
  modifiedAt: Date;
  status: ReviewState;
  questionSet: QuestionSet;
  comments: ReviewSubmissionComment[];
};

export type ReviewSubmissionComment = {
  id: string;
  comment: string;
}
