import { Spacer } from "primitives";
import { FC, useState } from "react";
import { ButtonProps, Modal } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import styled from "styled-components";
import { colors } from "styles";
import { ModalButtonFooter } from "../components";

interface ConfirmModalProps {
  title?: React.ReactNode | string;
  body: string[];
  secondaryText?: string;
  confirmButtonVariant?: ButtonVariant;
  visible: boolean;
  hide: () => void;
  onConfirm: () => void;
  isPreview?: boolean;
  confirmTitle?: string;
  cancelTitle?: string;
  confirmProps?: Partial<ButtonProps>;
  isLoading?: boolean;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  body,
  secondaryText,
  confirmButtonVariant,
  visible,
  hide,
  onConfirm,
  isPreview,
  confirmTitle,
  cancelTitle,
  confirmProps,
  isLoading,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const onExit = () => {
    setIsClosing(false);
    hide();
  };

  const onHide = (e?: MouseEvent) => {
    setIsClosing(true);
  };

  return (
    <Modal
      backdropClassName="confirm-modal-backdrop"
      className="confirm-modal"
      show={visible && !isClosing}
      onHide={onHide}
      onExited={onExit}
      centered={true}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>{title || "Are you sure?"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center", padding: "25px 100px" }}>
        {body.map((bodyString, index) => {
          return <p key={`body_${index}`}>{bodyString}</p>;
        })}
        <Spacer />
        {secondaryText && <SecondaryModalText>{secondaryText}</SecondaryModalText>}
        <ModalButtonFooter
          isEditing={false}
          onCancel={() => {
            setIsClosing(true);
          }}
          onConfirm={() => {
            onConfirm();
          }}
          confirmTitle={confirmTitle}
          cancelTitle={cancelTitle}
          confirmVariant={confirmButtonVariant}
          confirmProps={confirmProps}
          isPreview={isPreview}
          confirmLoading={isLoading}
          cancelDisabled={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

const SecondaryModalText = styled.p`
  color: ${colors.secondaryNameLabel};
`;
