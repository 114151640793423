import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { RootBreadcrumb } from "components/breadcrumb";
import { breadcrumbConstants, slugs } from "textConstants";
import { QuestionSetsOverview } from "./QuestionSetsOverview";
import { QuestionTours } from "textConstants/tours";
import { TutorialsProvider, ArticleProvider } from "components";

export const QuestionSetRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <RootBreadcrumb
        title={breadcrumbConstants.questions.title}
        description={breadcrumbConstants.questions.breadcrumbDescription}
        icon={breadcrumbConstants.questions.icon}
      />
      <TutorialsProvider section="plan" tours={QuestionTours} />
      <ArticleProvider />
      <Switch>
        <Route path={`${url}/${slugs.questionSet}`} component={QuestionSetsOverview} />
        <Route path={`${url}`}>
          <Redirect to={`${url}/${slugs.questionSet}`} />
        </Route>
      </Switch>
    </>
  );
};
